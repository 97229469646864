.admin-auswertung {

    #city-result {
        margin-top: 40px;
    }
    #specialdropdown-result {
        margin-top: 40px;
    }
    .alert-warning {
        height: 24px;
        margin-right: 24px;
        margin-top: 5px;
        float: left;
    }
    .alert {
        font-size: 12px;
        line-height: 17px;
        padding: 12px 25px;
        b {
            font-weight: 500;
        }
    }
    .resultlist-cities {
        margin-top: 40px;
    }
    .stats-grid {
        margin-bottom: 100px;
    }
    .stats-grid-headline {
        margin-top: 40px;
    }
    #errorModal {
        .modal-dialog {
            .modal-content {
                border-radius: 4px;
            }
        }
    }
    #errorMessage {
        display: flex;
        color: #454545;
        min-height: 10px;
    }
    a.icon-download {
        font-size: 14px;
        line-height: 22px;
        font-weight: 500;
        padding-left: 30px;
        display: block;
    }
    .icon-download {
        &::before {
            margin-right: 12px;
            font-size: 18px;
            vertical-align: bottom;
            margin-left: -30px;
        }
    }
    @media (min-width: 768px) and (max-width: 992px) {
        .alert {
            font-size: 14px;
            line-height: 17px;
            padding: 13px 25px;
        }
    }
    @media (max-width: 767px) {
        .alert {
            font-size: 14px;
            line-height: 19px;
            padding: 13px 20px;
        }
        .alert-warning {
            height: 36px;
            margin-right: 23px;
            margin-top: 8px;
        }
        .resultlist-cities {
            margin-top: 50px;
        }
        .stats-grid-headline {
            margin-top: 100px;
        }
        #city-result {
            margin-top: 30px;
        }
        #specialdropdown-result {
            margin-top: 30px;
        }
    }

    
}
