.list-group-item-success {
	color: #3c763d;
	background-color: #dff0d8;
}
a.list-group-item-success {
	color: #3c763d;
	.list-group-item-heading {
		color: inherit;
	}
	&:hover {
		color: #3c763d;
		background-color: #d0e9c6;
	}
	&:focus {
		color: #3c763d;
		background-color: #d0e9c6;
	}
}
button.list-group-item-success {
	color: #3c763d;
	.list-group-item-heading {
		color: inherit;
	}
	&:hover {
		color: #3c763d;
		background-color: #d0e9c6;
	}
	&:focus {
		color: #3c763d;
		background-color: #d0e9c6;
	}
}
a.list-group-item-success.active {
	color: #fff;
	background-color: #3c763d;
	border-color: #3c763d;
	&:hover {
		color: #fff;
		background-color: #3c763d;
		border-color: #3c763d;
	}
	&:focus {
		color: #fff;
		background-color: #3c763d;
		border-color: #3c763d;
	}
}
button.list-group-item-success.active {
	color: #fff;
	background-color: #3c763d;
	border-color: #3c763d;
	&:hover {
		color: #fff;
		background-color: #3c763d;
		border-color: #3c763d;
	}
	&:focus {
		color: #fff;
		background-color: #3c763d;
		border-color: #3c763d;
	}
}
.list-group-item-info {
	color: #31708f;
	background-color: #d9edf7;
}
a.list-group-item-info {
	color: #31708f;
	.list-group-item-heading {
		color: inherit;
	}
	&:hover {
		color: #31708f;
		background-color: #c4e3f3;
	}
	&:focus {
		color: #31708f;
		background-color: #c4e3f3;
	}
}
button.list-group-item-info {
	color: #31708f;
	.list-group-item-heading {
		color: inherit;
	}
	&:hover {
		color: #31708f;
		background-color: #c4e3f3;
	}
	&:focus {
		color: #31708f;
		background-color: #c4e3f3;
	}
}
a.list-group-item-info.active {
	color: #fff;
	background-color: #31708f;
	border-color: #31708f;
	&:hover {
		color: #fff;
		background-color: #31708f;
		border-color: #31708f;
	}
	&:focus {
		color: #fff;
		background-color: #31708f;
		border-color: #31708f;
	}
}
button.list-group-item-info.active {
	color: #fff;
	background-color: #31708f;
	border-color: #31708f;
	&:hover {
		color: #fff;
		background-color: #31708f;
		border-color: #31708f;
	}
	&:focus {
		color: #fff;
		background-color: #31708f;
		border-color: #31708f;
	}
}
.list-group-item-warning {
	color: #8a6d3b;
	background-color: #fcf8e3;
}
a.list-group-item-warning {
	color: #8a6d3b;
	.list-group-item-heading {
		color: inherit;
	}
	&:hover {
		color: #8a6d3b;
		background-color: #faf2cc;
	}
	&:focus {
		color: #8a6d3b;
		background-color: #faf2cc;
	}
}
button.list-group-item-warning {
	color: #8a6d3b;
	.list-group-item-heading {
		color: inherit;
	}
	&:hover {
		color: #8a6d3b;
		background-color: #faf2cc;
	}
	&:focus {
		color: #8a6d3b;
		background-color: #faf2cc;
	}
}
a.list-group-item-warning.active {
	color: #fff;
	background-color: #8a6d3b;
	border-color: #8a6d3b;
	&:hover {
		color: #fff;
		background-color: #8a6d3b;
		border-color: #8a6d3b;
	}
	&:focus {
		color: #fff;
		background-color: #8a6d3b;
		border-color: #8a6d3b;
	}
}
button.list-group-item-warning.active {
	color: #fff;
	background-color: #8a6d3b;
	border-color: #8a6d3b;
	&:hover {
		color: #fff;
		background-color: #8a6d3b;
		border-color: #8a6d3b;
	}
	&:focus {
		color: #fff;
		background-color: #8a6d3b;
		border-color: #8a6d3b;
	}
}
.list-group-item-danger {
	color: #a94442;
	background-color: #f2dede;
}
a.list-group-item-danger {
	color: #a94442;
	.list-group-item-heading {
		color: inherit;
	}
	&:hover {
		color: #a94442;
		background-color: #ebcccc;
	}
	&:focus {
		color: #a94442;
		background-color: #ebcccc;
	}
}
button.list-group-item-danger {
	color: #a94442;
	.list-group-item-heading {
		color: inherit;
	}
	&:hover {
		color: #a94442;
		background-color: #ebcccc;
	}
	&:focus {
		color: #a94442;
		background-color: #ebcccc;
	}
}
a.list-group-item-danger.active {
	color: #fff;
	background-color: #a94442;
	border-color: #a94442;
	&:hover {
		color: #fff;
		background-color: #a94442;
		border-color: #a94442;
	}
	&:focus {
		color: #fff;
		background-color: #a94442;
		border-color: #a94442;
	}
}
button.list-group-item-danger.active {
	color: #fff;
	background-color: #a94442;
	border-color: #a94442;
	&:hover {
		color: #fff;
		background-color: #a94442;
		border-color: #a94442;
	}
	&:focus {
		color: #fff;
		background-color: #a94442;
		border-color: #a94442;
	}
}
