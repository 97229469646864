.shop-index__section.blue {
	background-color: #063547;
	color: #fff;
	padding-top: 30px;
	padding-bottom: 30px;
}
.shop-index__section.middleblue {
	background-color: #0E4761;
	color: #fff;
	padding-top: 30px;
	padding-bottom: 30px;
}
.shop-index__section.grey {
	background-color: #FBFBFB;
	padding-top: 30px;
	padding-bottom: 30px;
}
.sr_start_content {
	.Searchbox {
		+ {
			section {
				padding-top: 0;
			}
		}
	}
}
section {
	position: relative;
	padding: 50px 0 80px;
	color: #454545;
	background-color: white;
}
section.no-gutters {
	padding: 0;
}
section.dark-bg {
	color: #fefefe;
	background-color: #063547;
	border-color: currentColor;
}
section.light-bg {
	background-color: #fbfbfb;
}
.has_no-flexbox {
	.full-height {
		display: table;
		padding-bottom: 100px !important;
	}
}
@media (max-width: 767px) {
	section {
		padding: 20px 0 45px;
	}
}
@media (min-width: 992px) {
	.full-height {
		display: flex;
		>[class*='col-'] {
			display: table-cell;
			float: none;
			display: initial;
		}
	}
}
