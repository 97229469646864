// Ergebnisse

#results-total {
    .btn-secondary {
        background-color: transparent;
        border: 1px solid #fff;
        color: #fff;
        &:hover {
            color: #fff;
            opacity: 0.8;
        }
    }
    .blog-teaser {
        padding: 50px 84px;
        background-color: #073B50;
        border-radius: 7px;
        margin-bottom: 48px;
        p {
            font-size: 12px;
            line-height: 17px;
            letter-spacing: 0.33px;
            font-weight: 300;
            span {
                display: block;
            }
        }
        .btn {
            float: right;
        }
    }
}

#results-table-stars {
    .star-name {
        a {
            font-weight: 500;
            color: #0086C5;
        }
    }
    .star-co2 {
        .unit {
            display: none;
        }
    }
}

/* tablet */
@media (min-width: 768px) and (max-width: 991px) {
    #results-total {
        .blog-teaser {
            padding: 30px 24px;
            margin-bottom: 30px;
            p {
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.6px;
                span {
                    display: inline;
                }
            }
            .btn {
                padding: 12px 18px;
                width: 100%;
            }
        }
        .results-filter {
            .dropdown-menu {
                li {
                    > a {
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }
}

/* all mobile */
@media (max-width: 767px) {
    #results-total {
        .blog-teaser {
            padding: 18px;
            margin: 20px 15px;
            p {
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.6px;
            }
            .btn {
                padding: 9px 18px;
                margin-top: 20px;
                width: 100%;
            }
        }
        #sr-ergebnisse-kommunen {
            &.tab-pane {
                padding-top: 0;
            }
        }
        #resultForm .results-filter > div {
            margin: 15px 0;
            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: 10px;
            }
        }
    }

    #results-table-stars {
        thead {
            visibility: collapse;
        }
        .star-name {
            min-width: 200px;
            overflow: visible;
            a {
                display: none;
            }
        }
        .star-city {
            position: absolute;
            left: -12px;
            margin-top: 18px;
            font-size: 11px;
            opacity: 0.7;
            width: 190px;
            a {
                font-size: 11px;
            }
        }
        .star-co2 {
            position: absolute;
            left: -12px;
            margin-top: 38px;
            font-size: 11px;
            opacity: 0.7;
            .unit {
                display: inline-block;
            }
        }
        // km position
        &.data-table tbody > tr .bar_wrapper .bar_text {
            left: 20%;
        }
    }
    #results-table-stars_wrapper {
        &.dataTables_wrapper {
            margin: 0 -45px 0 -45px !important;
        }
        div.dataTables_paginate {
            padding-top: 20px;
            padding-bottom: 75px;
        }
    }
}
