.delete-link {
	display: flex;
	align-items: center;
	text-decoration: none !important;
	line-height: 3 !important;
	color: rgba(255, 255, 255, 0.6);
	cursor: pointer;
	&:hover {
		color: #0086C5;
	}
	&::before {
		display: inline-block;
		margin-right: 10px;
		font-size: 20px;
	}
}
.user-profile-img {
	img {
		height: 110px;
		width: 110px;
	}
}
