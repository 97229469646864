.kommune-radar {

    .small {
        font-size: 11px;
    }
    .bigger {
        font-size: 1.2em;
    }
    .bg-white {
        background-color: #fff;
    }
    .description {
        margin: 0;
        font-size: 0.9em;
    }
    p {
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 300;
        letter-spacing: 0.33px;
        line-height: 17px;
    }
    .recommendation-text {
        font-family: Courier New, Courier, Monospace, Fixed;
    }
    .embed-container {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
        width: 100%;
        height: auto;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .embed-container.ratio4x3 {
        padding-bottom: 80%;
    }
    .radar-icon-list {
        margin-top: 30px;
        padding-left: 0px;
        li {
            padding-left: 40px;
            margin-bottom: 30px;
            list-style: none;
            color: #FFFFFF;
            font-family: Roboto;
            font-size: 12px;
            font-weight: 300;
            letter-spacing: 0.33px;
            line-height: 25px;
            position: relative;
            &:before {
                position: absolute;
                content: "\e941" !important;
                left: 0px;
                top: -4px;
                font-size: 30px;
                height: 10px;
                width: 10px;
                border: none;
                background: none;
                text-align: center;
                line-height: 32px;
                font-family: sr-icons;
                color: white;
                font-weight: 100;
                opacity: 0.6;
            }
        }
        li.clock {
            &:before {
                content: "\e90c" !important;
            }
        }
        li.area {
            &:before {
                content: "\e94c" !important;
            }
        }
        li.search {
            &:before {
                content: "\e91c" !important;
            }
        }
    }
    #mobileInformation {
        background-color: #fff;
        margin-top: 30px;
        margin-bottom: 30px;
        padding-bottom: 70px;
        color: #000;
    }
    @media (max-width: 480px) {
        .radar-icon-list {
            li {
                &:before {
                    position: absolute;
                    content: "\e941" !important;
                    left: 50%;
                    top: -41px;
                    margin-left: -10px;
                    font-size: 23px;
                    height: 10px;
                    width: 10px;
                    border: none;
                    background: none;
                    text-align: center;
                    line-height: 32px;
                    font-family: sr-icons;
                    color: white;
                    font-weight: 100;
                    opacity: 0.6;
                }
                padding-left: 0px;
                margin-top: 50px;
                margin-bottom: 30px;
                list-style: none;
                color: #FFFFFF;
                font-size: 14px;
                letter-spacing: 0.6px;
                line-height: 20px;
                font-weight: 300;
                position: relative;
                text-align: center;
            }
            margin-bottom: 85px;
        }
        h3#so-funktionierts {
            text-align: center;
            font-size: 19px;
        }
    }
    
}