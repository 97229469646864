* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	&:before {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}
	&:after {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}
}
html {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
	font-family: Roboto, Open Sans, Segoe UI, sans-serif;
	color: #454545;
	background-color: #fff;
}
input {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}
button {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}
select {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}
textarea {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}
a {
	color: #0086C5;
	text-decoration: none;
	&:hover {
		color: #005279;
		text-decoration: none;
	}
	&:focus {
		color: #005279;
		text-decoration: none;
	}
}
figure {
	margin: 0;
}
img {
	vertical-align: middle;
}
.img-responsive {
	display: block;
	max-width: 100%;
	height: auto;
}
.img-rounded {
	border-radius: 7px;
}
.img-thumbnail {
	padding: 4px;
	line-height: 1.428571429;
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 4px;
	-webkit-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	display: inline-block;
	max-width: 100%;
	height: auto;
}
.img-circle {
	border-radius: 50%;
}
hr {
	margin-top: 20px;
	margin-bottom: 20px;
	border: 0;
	border-top: 1px solid #e7e7e7;
}
.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}
.sr-only-focusable {
	&:active {
		position: static;
		width: auto;
		height: auto;
		margin: 0;
		overflow: visible;
		clip: auto;
	}
	&:focus {
		position: static;
		width: auto;
		height: auto;
		margin: 0;
		overflow: visible;
		clip: auto;
	}
}
[role="button"] {
	cursor: pointer;
}
