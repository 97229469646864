.datenschutz{
    .checkbox label {
        margin-left:40px;
    }
    .rk-compat-2017 .checkbox label::before, .checkbox label::before {
        position: absolute;
        margin-left:-40px;
    }
    .text-danger {
        margin-left:40px;
    }
}
