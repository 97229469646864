.historie-gesamt {
    &.historie {
        h1 {
            text-align: center;
            margin-top: 40px;
            margin-bottom: 60px;
        }
        .sr-icon-grid {
            margin: 0 15px 30px;
        }
    }
    h4 {
        font-size: 16px;
    }
    .carousel__control {
        display: block;
        position: absolute;
        top: 303px;
        padding-top: 45px;
        left: 10px;
        width: 30px;
        height: 70px;
        opacity: 0;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        -webkit-transition: opacity .3s;
        -o-transition: opacity .3s;
        transition: opacity .3s;
        opacity: 1;
        padding-top: 1px;
        left: -15px;
        img {
            width: 15px !important;
            height: 30px !important;
        }
    }
    .carousel__control[data-slide="next"] {
        left: auto;
        right: -27px;
    }


    @media (max-width: 991px) {
        #historie {
            .sr-icon-grid {
                li {
                    flex-basis: 230px;
                }
            }
        }
        &.historie {
            h1 {
                margin-top: 60px;
                margin-bottom: 35px;
            }
        }
    }
    @media (max-width: 767px) {
        #historie {
            .sr-icon-grid {
                li {
                    flex-basis: 120px;
                }
                margin: 0 30px;
                margin: 10px 10px 30px;
            }
        }
        #cities-chart {
            margin-top: 20px;
            margin-bottom: 40px;
        }
        #radelnde-chart {
            margin-top: 20px;
            margin-bottom: 40px;
        }
    }

    @media (max-width: 767px) {
        .carousel__control {
            top: 50px !important;
            left: 0px;
            img {
                width: 15px !important;
                height: 30px !important;
            }
        }
        .carousel__control[data-slide="next"] {
            left: auto;
            right: -20px;
        }
    }
    .carousel-indicators {
        li {
            display: inline-block;
            height: 6px;
            width: 6px;
            margin: 2px 6px 2px 6px;
            text-indent: -999px;
            cursor: pointer;
            background-color: #fff;
            border-radius: 12px;
        }
        li.active {
            height: 10px;
            width: 10px;
            margin: 2px 6px 0 6px;
        }
    }
    .carousel-fade {
        .carousel-inner {
            .item {
                transition-property: opacity;
                opacity: 0;
            }
            text-align: center;
            .active.left {
                opacity: 0;
            }
            .active.right {
                opacity: 0;
            }
            .active {
                opacity: 1;
            }
            .next.left {
                opacity: 1;
            }
            .prev.right {
                opacity: 1;
            }
        }
    }


    #carousel-historie {
        .carousel-indicators {
            position: absolute;
            bottom: 15px;
            left: 50%;
            z-index: 15;
            width: 60%;
            margin-left: -30%;
            padding-left: 0;
            list-style: none;
            text-align: center;
        }
        .carousel-inner {
            padding-bottom: 100px;
            margin-bottom: 120px;
        }
    }
    .ct-legend {
        position: absolute;
        top: 10px;
        left: 55px;
        min-width: 200px;
        min-height: 200px;
        padding: 20px;
        ul {
            list-style: none;
        }
        li {
            margin-left: 0px;
            margin-botto: 30px;
            padding: 10px 5px;
            text-align: left;
            color: #FFFFFF;
            font-family: Roboto;
            font-size: 14px;
            letter-spacing: 0.21px;
            line-height: 18px;
            &:before {
                content: ' ';
                position: absolute;
                left: 0px;
                height: 18px;
                width: 50px;
                background-color: #3C99C5;
                border: 5px solid #3C99C5;
                border-radius: 11px;
            }
            &:nth-child(2) {
                &:before {
                    background-color: #0086C5;
                    border: 5px solid #0086C5;
                }
            }
            &:nth-child(3) {
                &:before {
                    background-color: #286AA6;
                    border: 5px solid #286AA6;
                }
            }
            &:nth-child(4) {
                &:before {
                    background-color: #0E4761;
                    border: 5px solid #0E4761;
                }
            }
        }
    }
    @media (max-width: 480px) {
        #carousel-historie {
            .carousel-indicators {
                bottom: -19px;
            }
            .carousel-inner {
                padding-bottom: 70px;
            }
        }
        &.historie {
            h1 {
                text-align: left;
                margin-top: 20px;
                margin-bottom: 30px;
            }
        }
    }


    @media (max-width: 768px) {
        .ct-legend {
            padding-left: 0px !important;
            padding-right: 0px !important;
            top: 260px;
            ul {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                padding-left: 0px;
                padding-top: 20px;
                li {
                    position: relative;
                    padding-left: 60px;
                    margin-right: 15px;
                }
            }
        }
        #carousel-historie .carousel-inner {
            margin-bottom: 20px;
        }
    }
    @media (max-width: 480px) {
        .ct-legend {
            ul {
                li {
                    margin-right: 0px;
                }
            }
            li {
                &:nth-child(2) {
                    margin-left: 5px;
                }
                &:nth-child(4) {
                    margin-left: 10px;
                }
            }
        }
    }
    .ct-axis-title {
        fill: #fff;
        opacity: 0.47;
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 15px;
        letter-spacing: 0.31px;
        line-height: 18px;
    }
    .ct-chart-bar {
        text.ct-axis-title {
            &:nth-child(1) {
                transform: rotate(0deg);
            }
        }
    }


    /* Cities Chart */
    #cities-chart {
        min-height: 445px;
        .ct-series-a {
            .ct-bar {
                stroke: #0086C5;
                stroke-width: 40px;
            }
        }
        .ct-series-b {
            .ct-bar {
                stroke: #7EC927;
                stroke-width: 40px;
            }
        }
        .activeYear {
            stroke: #8CB82B;
            stroke-width: 1;
            stroke-dasharray: 5,5 !important; z-index: 99999;
        }
        .activeYearCircle {
            stroke: #8CB82B;
            z-index: 99999;
            fill: #8CB82B;
        }
        .ct-selected {
            stroke: #40C2FF !important;
        }
        .ct-grid.ct-horizontal {
            display: block;
        }
        .ct-grid.ct-vertical {
            display: block;
        }
    }
    .selectedyear-bg {
        display: none;
    }
    .compare-active-year-label {
        font-family: 'roboto';
        font-size: 16px;
        fill: white;
        font-weight: 500;
        fill: #8CB82B;
    }
    @media (max-width: 768px) {
        .ct-series-a {
            .ct-bar {
                stroke: #0086C5;
                stroke-width: 15px !important;
            }
        }
        .ct-label.ct-horizontal {
            font-size: 12px;
        }
        .marks {
            stroke: #335B6D;
            stroke-width: 2px;
            stroke-dasharray: 0px;
        }
        text.ct-axis-title {
            &:nth-child(2) {
                display: none;
            }
        }
        #cities-chart {
            text.ct-axis-title {
                &:nth-child(1) {
                    display: none;
                }
            }
            min-height: 345px;
        }
        .ct-label.ct-vertical.ct-start {
            -webkit-box-align: flex-start;
            -webkit-align-items: flex-start;
            -ms-flex-align: flex-start;
            align-items: flex-start;
            -webkit-box-pack: flex-start;
            -webkit-justify-content: flex-start;
            -ms-flex-pack: flex-start;
            justify-content: flex-start;
            text-align: left;
            text-anchor: start;
            transform: translate(171%,80%);
        }
        .ct-label.ct-horizontal.ct-end {
            transform: translate(0%,5%);
        }
        .the-last-of-the-labels {
            display: none !important;
        }
        .selectedyear {
            font-size: 14px !important;
        }
        .selectedyear-bg {
            display: block;
        }
        .compare-active-year-label {
            font-size: 14px;
        }
    }



    /* Radelnde Chart */
    /* Alter Chart */
    #radelnde-chart {
        min-height: 445px;
        .ct-series-a {
            .ct-bar {
                stroke: #0086C5;
                stroke-width: 40px;
            }
        }
        .activeYear {
            stroke: #8CB82B;
            stroke-width: 1;
            stroke-dasharray: 5,5 !important; z-index: 99999;
        }
        .activeYearCircle {
            stroke: #8CB82B;
            z-index: 99999;
            fill: #8CB82B;
        }
        .ct-selected {
            stroke: #40C2FF !important;
        }
        .ct-grid.ct-horizontal {
            display: block;
        }
        .ct-grid.ct-vertical {
            display: block;
        }
    }
    #alter-chart {
        .ct-series-a {
            .ct-slice-pie {
                fill: #3C99C5;
            }
            .ct-slice-donut-solid {
                fill: #3C99C5;
            }
            .ct-area {
                fill: #3C99C5;
            }
        }
        .ct-series-b {
            .ct-slice-pie {
                fill: #0086C5;
            }
            .ct-slice-donut-solid {
                fill: #0086C5;
            }
            .ct-area {
                fill: #0086C5;
            }
        }
        .ct-series-c {
            .ct-slice-pie {
                fill: #286AA6;
            }
            .ct-slice-donut-solid {
                fill: #286AA6;
            }
            .ct-area {
                fill: #286AA6;
            }
        }
        .ct-series-d {
            .ct-slice-pie {
                fill: #0E4761;
            }
            .ct-slice-donut-solid {
                fill: #0E4761;
            }
            .ct-area {
                fill: #0E4761;
            }
        }
    }
    .ct-donut-centertext {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 250px;
        height: 250px;
        margin-top: -125px;
        margin-left: -125px;
        .bigtext {
            position: absolute;
            bottom: 90px;
            left: 80px;
            color: #FFFFFF;
            font-family: Roboto;
            font-size: 80px;
            letter-spacing: 0;
            line-height: 94px;
            -webkit-transition: all 1s;
            -o-transition: all 1s;
            transition: all 1s;
        }
        .bigtext.reduced {
            position: absolute;
            bottom: 93px;
            left: 90px;
            font-family: Roboto;
            font-size: 60px;
            letter-spacing: 0;
            line-height: 71px;
            -webkit-transition: all 1s;
            -o-transition: all 1s;
            transition: all 1s;
        }
        .bigtext-compare {
            position: absolute;
            opacity: 0;
            bottom: 150px;
            left: 99px;
            color: #8CB82B;
            font-family: Roboto;
            font-size: 50px;
            letter-spacing: 0;
            line-height: 58px;
            -webkit-transition: all 1s;
            -o-transition: all 1s;
            transition: all 1s;
        }
        .smalltext {
            position: absolute;
            top: 150px;
            left: 70px;
            color: #FFFFFF;
            font-family: Roboto;
            font-size: 14px;
            letter-spacing: 0.21px;
            line-height: 20px;
            -webkit-transition: all 1s;
            -o-transition: all 1s;
            transition: all 1s;
        }
    }
    .ct-donut-centertext-inner {
        position: relative;
        width: 100%;
        height: 100%;
    }
    @media (max-width: 768px) {
        #radelnde-chart {
            text.ct-axis-title {
                &:nth-child(2) {
                    display: none;
                }
                &:nth-child(1) {
                    display: none;
                }
            }
        }
        .ct-donut-centertext {
            .bigtext {
                position: absolute;
                bottom: 90px;
                font-size: 60px;
            }
            .smalltext {
                top: 145px;
            }
        }
        #alter-chart {
            margin-bottom: 30px;
        }
    }
    @media (max-width: 480px) {
        #radelnde-chart {
            .ct-series-a {
                .ct-bar {
                    stroke: #0086C5;
                    stroke-width: 20px;
                }
            }
            text.ct-axis-title {
                &:nth-child(2) {
                    display: none;
                }
                &:nth-child(1) {
                    display: none;
                }
            }
            min-height: 345px;
        }
        #alter-chart {
            .ct-legend {
                position: absolute;
                top: 290px;
                left: 7px;
                min-width: 200px;
                min-height: 200px;
                padding: 20px;
            }
            min-height: 430px;
        }
        .ct-donut-centertext {
            .bigtext {
                position: absolute;
                bottom: 90px;
                left: 104px;
                color: #FFFFFF;
                font-family: Roboto;
                font-size: 50px;
                letter-spacing: 0;
                line-height: 94px;
            }
            .bigtext.reduced {
                position: absolute;
                bottom: 95px;
                left: 104px;
                font-family: Roboto;
                font-size: 50px;
            }
            .bigtext-compare {
                position: absolute;
                opacity: 0;
                bottom: 146px;
                left: 110px;
                color: #8CB82B;
                font-family: Roboto;
                font-size: 40px;
                letter-spacing: 0;
                line-height: 58px;
            }
            .smalltext {
                position: absolute;
                top: 143px;
                left: 70px;
                color: #FFFFFF;
                font-family: Roboto;
                font-size: 14px;
                letter-spacing: 0.21px;
                line-height: 20px;
            }
        }
    }


    /* Kilometer Chart */
    /* Fix selected years min max*/
    #kilometer-chart {
        .ct-series-a {
            .ct-slice-pie {
                fill: #0a6190 !important;
            }
            .ct-slice-donut-solid {
                fill: #0a6190 !important;
            }
            .ct-area {
                fill: #0a6190 !important;
            }
        }
        .ct-vertical {
            display: flex !important;
        }
        .activeYear {
            stroke: #8CB82B;
            stroke-width: 1;
            stroke-dasharray: 5,5 !important; z-index: 99999;
        }
        .activeYearCircle {
            stroke: #8CB82B;
            z-index: 99999;
            fill: #8CB82B;
        }
        .ct-chart-line {
            overflow: visible !important;
            .ct-label.ct-horizontal.ct-end {
                margin-left: -14px;
            }
        }
    }
    @media (max-width: 768px) {
        #kilometer-chart {
            text.ct-axis-title {
                &:nth-child(2) {
                    display: none;
                }
                &:nth-child(1) {
                    display: none;
                }
            }
        }
    }
    @media (max-width: 480px) {
        #kilometer-chart {
            text.ct-axis-title {
                &:nth-child(2) {
                    display: none;
                }
                &:nth-child(1) {
                    display: none;
                }
            }
        }
    }


    /* Vermeidung Chart */
    /* Fix selected years min max*/
    #vermeidung-chart {
        .ct-series-a {
            .ct-slice-pie {
                fill: rgba(249,96,10,0.52) !important;
            }
            .ct-slice-donut-solid {
                fill: rgba(249,96,10,0.52) !important;
            }
            .ct-area {
                fill: rgba(249,96,10,0.52) !important;
            }
        }
        .ct-vertical {
            display: flex !important;
        }
        .activeYear {
            stroke: #8CB82B;
            stroke-width: 1;
            stroke-dasharray: 5,5 !important; z-index: 99999;
        }
        .activeYearCircle {
            stroke: #8CB82B;
            z-index: 99999;
            fill: #8CB82B;
        }
        .ct-chart-line {
            overflow: visible !important;
            .ct-label.ct-horizontal.ct-end {
                margin-left: -14px;
            }
        }
    }
    .ct-chart-line {
        text.ct-axis-title {
            &:nth-child(1) {
                transform: rotate(0deg);
            }
        }
    }
    @media (max-width: 768px) {
        #vermeidung-chart {
            text.ct-axis-title {
                &:nth-child(2) {
                    display: none;
                }
                &:nth-child(1) {
                    display: none;
                }
            }
        }
    }
    @media (max-width: 480px) {
        #vermeidung-chart {
            text.ct-axis-title {
                &:nth-child(2) {
                    display: none;
                }
                &:nth-child(1) {
                    display: none;
                }
            }
        }
    }


    /* Geschlecht Chart */
    /* Teams */
    #geschlecht-chart {
        width: 100%;
        min-height: 425px;
        padding: 60px;
        padding-top: 0px;
        padding-bottom: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        .geschlecht-bar-wrapper {
            height: 45px;
            width: 100%;
            background-color: #fff;
            position: relative;
            .weiblich {
                height: 45px;
                background-color: #3C99C5;
                float: left;
                position: relative;
                -webkit-transition: width 2s;
                -o-transition: width 2s;
                transition: width 2s;
            }
            .weiblich-compare {
                height: 8px;
                opacity: 0;
                background-color: #8CB82B;
                float: left;
                position: absolute;
                top: -11px;
                -webkit-transition: all 2s;
                -o-transition: all 2s;
                transition: all 2s;
            }
            .maennlich {
                height: 45px;
                background-color: #286AA6;
                float: right;
                position: relative;
                transition: width 2s;
            }
            .maennlich-compare {
                height: 8px;
                opacity: 0;
                background-color: #8CB82B;
                float: right;
                position: relative;
                transition: width 2s;
                top: -11px;
                right: 0px;
                position: absolute;
                -webkit-transition: all 2s;
                -o-transition: all 2s;
                transition: all 2s;
            }
        }
    }
    .weiblich-icon {
        &:after {
            position: absolute;
            content: "\e949" !important;
            left: 0px;
            top: -94px;
            font-size: 60px;
            border: none;
            background: none;
            font-family: sr-icons;
            color: #3C99C5;
        }
    }
    .weiblich-label {
        position: absolute;
        left: 0px;
        top: 64px;
        font-size: 14px;
        border: none;
        background: none;
        font-family: roboto;
        color: white;
    }
    .weiblich-percent {
        position: absolute;
        right: 19px;
        top: 5px;
        font-size: 26px;
        border: none;
        background: none;
        font-family: roboto;
        color: white;
    }
    .weiblich-compare {
        .weiblich-percent {
            position: absolute;
            right: 19px;
            top: -37px;
            font-size: 26px;
            color: #8CB82B;
        }
    }
    .maennlich-label {
        position: absolute;
        right: 0px;
        top: 64px;
        font-size: 14px;
        border: none;
        background: none;
        font-family: roboto;
        color: white;
    }
    .maennlich-icon {
        &:after {
            position: absolute;
            content: "\e948" !important;
            right: 0px;
            top: -94px;
            font-size: 60px;
            border: none;
            background: none;
            font-family: sr-icons;
            color: #286AA6;
        }
    }
    .maennlich-percent {
        position: absolute;
        left: 19px;
        top: 5px;
        font-size: 26px;
        border: none;
        background: none;
        font-family: roboto;
        color: white;
    }
    .maennlich-compare {
        .maennlich-percent {
            position: absolute;
            left: 19px;
            top: -37px;
            font-size: 26px;
            border: none;
            background: none;
            font-family: roboto;
            color: #8CB82B;
        }
    }
    .keine-angabe-label {
        position: absolute;
        left: 50%;
        width: 200px;
        margin-left: -100px;
        text-align: center;
        top: 64px;
        font-size: 14px;
        border: none;
        background: none;
        font-family: roboto;
        color: white;
    }
    .singleDataInfo-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        padding: 60px;
        padding-top: 0px;
        padding-bottom: 0px;
        min-height: 347px;
        .css-donut-innner {
            font-size: 24px;
        }
        .css-donut-innner-compare {
            font-size: 24px;
            top: 60px;
        }
    }
    .team-chart-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 60px;
        padding-top: 0px;
        padding-bottom: 0px;
        min-height: 425px;
    }
    .css-donut-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .css-donut-innner {
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 40px;
        letter-spacing: 0;
        z-index: 1;
        -webkit-transition: all 1s;
        -o-transition: all 1s;
        transition: all 1s;
    }
    .opacity100 {
        opacity: 1 !important;
    }
    .css-donut-innner.reduced {
        margin-top: 25px;
    }
    .css-donut-innner-compare {
        opacity: 0;
        color: #8CB82B;
        font-family: Roboto;
        font-size: 29px;
        letter-spacing: 0;
        z-index: 1;
        position: absolute;
        top: 54px;
        -webkit-transition: all 1s;
        -o-transition: all 1s;
        transition: all 1s;
    }
    @property --a {
        syntax: '<angle>';
        inherits: false;
        initial-value: 0deg;
    }
    @property --b {
        syntax: '<angle>';
        inherits: false;
        initial-value: 360deg;
    }
    .css-donut {
        --a: 0deg;
        --b: 360deg;
        transition: --a 2s, --b 2s;
        width: 190px;
        height: 190px;
        background: radial-gradient(#063547 48%, transparent 49%), conic-gradient(from var(--a), #063547 var(--b),#0086C5);
        margin: 10px;
        display: inline-block;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    .css-donut.animation {
        --a: 360deg;
        --b: 0deg;
    }
    @-moz-document url-prefix() {
        .css-donut {
            background: radial-gradient(#063547 48%, transparent 49%), conic-gradient(#063547, #0086C5);
        }
        .css-donut-compare {
            background: radial-gradient(#063547 62%, transparent 63%), #5a8735;
        }
    }
    .css-donut-compare {
        opacity: 0;
        width: 132px;
        height: 132px;
        background: radial-gradient(#063547 62%, transparent 63%), conic-gradient(#076493, #5a8735);
        display: inline-block;
        border-radius: 50%;
        position: absolute;
        top: 29px;
        left: 29px;
        -webkit-transition: all 1s;
        -o-transition: all 1s;
        transition: all 1s;
    }

    .css-donut-label {
        position: absolute;
        bottom: -50px;
        width: 290px;
        text-align: center;
        font-size: 14px;
        border: none;
        background: none;
        font-family: roboto;
        color: white;
        z-index: 1;
    }
    .select2-results__group {
        &:after {
            position: absolute;
            content: "\e941" !important;
            transform: rotate(-90deg);
            right: 22px;
            top: 14px;
            font-size: 6px;
            height: 10px;
            width: 10px;
            border: none;
            background: none;
            text-align: center;
            line-height: 32px;
            font-family: sr-icons;
            color: white;
        }
    }
    .select2-results__group.open {
        &:after {
            content: "\e942" !important;
            transform: rotate(180deg);
            right: 12px;
            top: 25px;
        }
    }

    #topgrid {
        overflow-y: hidden;
        -webkit-transition: height 1s;
        -o-transition: height 1s;
        transition: height 1s;
    }
    .noDataInfoGeschlecht {
        min-height: 425px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .noDataInfoAlter {
        min-height: 425px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .noDataInfoKilometer {
        min-height: 425px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .noDataInfoTeams {
        min-height: 425px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .noDataInfoVermeidung {
        min-height: 425px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .section-heading-historie {
        font-size: 20px;
        span {
            position: relative;
        }
    }
    .toggle-text {
        min-width: 150px;
        color: #ffffff78;
        font-family: Roboto;
        font-size: 14px;
        line-height: 16px;
        position: absolute;
        letter-spacing: 0.3px;
        left: 50px;
        top: 0px;
        font-weight: 500;
    }
    .toggle-wrapper {
        position: absolute;
        top: 51px;
        right: 130px;
    }
    .icon-info {
        &:before {
            content: "\e914";
            opacity: 0.5;
            margin-left: 7px;
            margin-top: 5px;
        }
    }
    @media (max-width: 480px) {
        #geschlecht-chart {
            width: 100%;
            min-height: 300px;
            padding: 00px;
            padding-top: 0px;
            .geschlecht-bar-wrapper {
                height: 30px;
                .weiblich {
                    height: 30px;
                }
                .maennlich {
                    height: 30px;
                }
            }
        }
        .weiblich-percent {
            font-size: 18px;
            top: 2px;
        }
        .maennlich-percent {
            font-size: 18px;
            top: 2px;
        }
        .maennlich-compare {
            .maennlich-percent {
                font-size: 16px;
                top: -25px;
            }
        }
        .weiblich-compare {
            .weiblich-percent {
                font-size: 16px;
                top: -25px;
            }
        }
        .weiblich-label {
            top: 40px;
            font-size: 12px;
        }
        .maennlich-label {
            top: 40px;
            font-size: 12px;
        }
        .keine-angabe-label {
            top: 40px;
            font-size: 12px;
        }
        .weiblich-icon {
            &:after {
                top: -68px;
                font-size: 40px;
            }
        }
        .maennlich-icon {
            &:after {
                top: -68px;
                font-size: 40px;
            }
        }
        .css-donut-wrapper {
            margin-bottom: 60px;
            &:nth-child(1) {
                margin-top: 30px !important;
            }
        }
        .singleDataInfo-wrapper {
            .css-donut-innner {
                font-size: 22px;
            }
            padding: 0 75px;
        }
        #topgrid {
            max-height: 453px !important;
            height: 453px;
        }
        .sr-icon-grid {
            margin: 10px 10px 30px;
            li {
                flex-basis: 120px !important;
            }
        }
        .team-chart-wrapper {
            padding: 0 75px;
        }
        .toggle-wrapper {
            position: absolute;
            bottom: 20px;
            top: unset;
            right: 144px;
        }
    }
    @media (max-width: 768px) {
        .team-chart-wrapper {
            min-height: 315px;
        }
        .singleDateInfo-wrapper {
            min-height: 315px;
        }
        .css-donut-innner {
            font-size: 37px;
        }
        .css-donut-label {
            width: 170px;
            top: 162px;
            bottom: auto;
        }
        .css-donut {
            width: 150px;
            height: 150px;
            background: radial-gradient(#063547 48%, transparent 49%), conic-gradient(#063547, #0086C5);
            margin: 10px;
            display: inline-block;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
        }
        .css-donut-compare {
            width: 112px;
            height: 112px;
            background: radial-gradient(#063547 62%, transparent 63%), conic-gradient(#076493, #5a8735);
            display: inline-block;
            border-radius: 50%;
            position: absolute;
            top: 19px;
            left: 19px;
        }
        .css-donut-innner-compare {
            font-size: 25px;
            top: 35px;
        }
        #vermeidung-donut {
            .css-donut-innner-compare {
                font-size: 22px;
                top: 45px;
            }
        }
        #kilometer-donut {
            .css-donut-innner-compare {
                font-size: 22px;
                top: 45px;
            }
        }
        .css-donut-innner.reduced {
            margin-top: 20px;
        }
        .css-donut-wrapper {
            &:nth-child(1) {
                margin-top: 0px;
            }
        }
        // #topgrid {
        //     max-height: 353px !important;
        //     height: 353px;
        // }
    }
    @media (max-width: 767px) {
        .sr-icon-grid {
            li {
                flex-basis: 0;
            }
        }
    }
    @media screen and (min-width: 376px) and (max-width: 480px) {
        .team-chart-wrapper {
            padding: 0 90px;
        }
        .singleDataInfo-wrapper {
            padding: 0 90px;
        }
    }
    @media (max-width: 991px) {
        #historie {
            .sr-icon-grid {
                li {
                    min-width: 15%;
                    flex-basis: 230px !important;
                }
            }
        }
    }


    .results-filter-wrapper {
        display: flex;
        flex-wrap: wrap;
    }
    .results-filter {
        display: flex;
        flex-direction: row;
        width: 100%;
        >div {
            flex: 1;
            text-align: center;
            margin: 0px 50px;
            &:first-child {
                margin-left: 0px;
            }
            &:last-child {
                margin-right: 0px;
            }
        }
    }
    @media (max-width: 767px) {
        .results-filter {
            flex-direction: column;
            margin-right: 0px;
            >div {
                margin: 10px 0px;
            }
        }
    }
    @media (min-width: 768px) {
        .results-filter {
            >div {
                margin: 0px 30px;
            }
            margin-right: 0px;
        }
        .section-heading {
            text-align: center;
        }
    }
    @media (min-width: 1440px) {
        .results-filter {
            >div {
                margin: 0px 50px;
                max-width: 254px;
            }
            margin-right: 0px;
        }
    }

    /* ==== Content Spinner ==== */

    .content-spinner-wrapper {
        width: 100%;
        min-height: 400px;
        text-align: center;
    }
    .content-spinner {
        z-index: 5000;
        width: 200px;
        margin: 0 auto;
        padding: 20px;
        img {
            margin-top: 50%;
            -webkit-animation: rotation 1s linear 0s infinite;
            -moz-animation: rotation 1s linear 0s infinite;
            -o-animation: rotation 1s linear 0s infinite;
            animation: rotation 1s linear 0s infinite;
        }
    }
    @media (min-width: 1440px) {
        .content-spinner-wrapper {
            min-height: 625px;
        }
    }

    @-webkit-keyframes rotation { from { transform: rotate(0deg); } to { transform: rotate(359deg); }  }
    @-moz-keyframes rotation { from { transform: rotate(0deg); } to { transform: rotate(359deg); }  }
    @-o-keyframes rotation { from { transform: rotate(0deg); } to { transform: rotate(359deg); }  }
    @keyframes rotation { from { transform: rotate(0deg); } to { transform: rotate(359deg); }  }

    #selectedFStateDropdown .dropdown-menu {
        max-height: 700px !important;
    }

    /* safari */
    @supports (background: -webkit-named-image(i)) {
        #kilometer-chart {
            text.ct-axis-title {
                &:nth-child(2) {
                    transform: translate(-320px, 20px);
                    font-size: 12px;
                }
                &:nth-child(1) {
                    transform: translate(25px, 60px);
                    font-size: 12px;
                }
            }
        }

        #vermeidung-chart {
            text.ct-axis-title {
                &:nth-child(2) {
                    transform: translate(-300px, 20px);
                    font-size: 12px;
                }
                &:nth-child(1) {
                    transform: translate(5px, 60px);
                    font-size: 12px;
                }
            }
        }

    }

}
