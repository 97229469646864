@media print {
	* {
		background: transparent !important;
		color: #000 !important;
		box-shadow: none !important;
		text-shadow: none !important;
		&:before {
			background: transparent !important;
			color: #000 !important;
			box-shadow: none !important;
			text-shadow: none !important;
		}
		&:after {
			background: transparent !important;
			color: #000 !important;
			box-shadow: none !important;
			text-shadow: none !important;
		}
	}
	a {
		text-decoration: underline;
		&:visited {
			text-decoration: underline;
		}
	}
	a[href] {
		&:after {
			content: " (" attr(href) ")";
		}
	}
	abbr[title] {
		&:after {
			content: " (" attr(title) ")";
		}
	}
	a[href^="#"] {
		&:after {
			content: "";
		}
	}
	a[href^="javascript:"] {
		&:after {
			content: "";
		}
	}
	pre {
		border: 1px solid #999;
		page-break-inside: avoid;
	}
	blockquote {
		border: 1px solid #999;
		page-break-inside: avoid;
	}
	thead {
		display: table-header-group;
	}
	tr {
		page-break-inside: avoid;
	}
	img {
		page-break-inside: avoid;
		max-width: 100% !important;
	}
	p {
		orphans: 3;
		widows: 3;
	}
	h2 {
		orphans: 3;
		widows: 3;
		page-break-after: avoid;
	}
	h3 {
		orphans: 3;
		widows: 3;
		page-break-after: avoid;
	}
	.navbar {
		display: none;
	}
	.btn {
		>.caret {
			border-top-color: #000 !important;
		}
	}
	.dropup {
		>.btn {
			>.caret {
				border-top-color: #000 !important;
			}
		}
	}
	.label {
		border: 1px solid #000;
	}
	.table {
		border-collapse: collapse !important;
		td {
			background-color: #fff !important;
		}
		th {
			background-color: #fff !important;
		}
	}
	.table-bordered {
		th {
			border: 1px solid #ddd !important;
		}
		td {
			border: 1px solid #ddd !important;
		}
	}
}
