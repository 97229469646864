[class^="icon-"]::before, [class*=" icon-"]::before, [class*="fa-"] {
    font-family: 'sr-icons', 'FontAwesome';
}
  
[class^="icon-"], [class*=" icon-"], [class*="fa-"] {
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.text-link {
	display: inline-block;
	display: flex;
	align-items: center;
	&::before {
		margin-right: 7.5px;
		vertical-align: middle;
		font-size: 125%;
		line-height: 1;
	}
}

.icon-arrow_upward {
  &:before {
    content: "\e97d";
  }
}
.icon-arrow_downward {
  &:before {
    content: "\e97e";
  }
}
.icon-logout1 {
  &:before {
    content: "\e97f";
  }
}
.icon-p {
  &:before {
		content: "\e95e";
  }
}
.icon-account_circle {
  &:before {
		content: "\e960";
  }
}
.icon-arrow_back {
  &:before {
    content: "\e961";
  }
}
.icon-arrow_forward {
  &:before {
    content: "\e962";
  }
}
.icon-arrow_outward {
  &:before {
    content: "\e963";
  }
}
.icon-article {
  &:before {
    content: "\e964";
  }
}
.icon-award_star {
  &:before {
    content: "\e965";
  }
}
.icon-beenhere {
  &:before {
    content: "\e966";
  }
}
.icon-brand_awareness {
  &:before {
    content: "\e967";
  }
}
.icon-calendar_today {
  &:before {
    content: "\e968"; 
  }
}
.icon-close1 {
  &:before {
    content: "\e969";
  }
}
.icon-directions_bike {
  &:before {
    content: "\e96a";
  }
}
.icon-distance {
  &:before {
		content: "\e96b"; 
  }
}
.icon-done_outline {
  &:before {
    content: "\e96c";
  }
}
.icon-edit1 {
  &:before {
    content: "\e96d";
  }
}
.icon-family_star {
  &:before {
    content: "\e96e";
  }
}
.icon-file {
  &:before {
    content: "\e96f";
  }
}
.icon-hub {
  &:before {
    content: "\e970";
  }
}
.icon-keyboard_arrow_up {
  &:before {
    content: "\e971";
  }
}
.icon-keyboard_double_arrow_down {
  &:before {
    content: "\e972";
  }
}
.icon-kid_star {
  &:before {
    content: "\e973";
  }
}
.icon-language1 {
  &:before {
    content: "\e974";
  }
}
.icon-mail1 {
  &:before {
    content: "\e975";
  }
}
.icon-menu {
  &:before {
    content: "\e976";
  }
}
.icon-new_releases {
  &:before {
    content: "\e977";
  }
}
.icon-pedal_bike {
  &:before {
    content: "\e978"; 
  }
}
.icon-phone_iphone {
  &:before {
    content: "\e979";
  }
}
.icon-plus {
  &:before {
    content: "\e97a";
  }
}
.icon-rule_settings {
  &:before {
    content: "\e97b";
  }
}
.icon-search1 {
  &:before {
    content: "\e97c";
  }
}

.icon-mastodon {
	&:before {
		content: "\e95d";
	}
}
.icon-social-x {
	&:before {
		content: "\e95f";
	}
}
.search-icon {
	&::before {
		font-family: 'sr-icons', 'FontAwesome';
		content: "\e947";
		font-size: 20px;
	}
}
.icon-ca-logo {
	&:before {
		content: "\e95c";
	}
}
.icon-close {
	&:before {
		content: "\e955";
		content: "\e955";
	}
}
.icon-youtube-alt {
	&:before {
		content: "\e90a";
	}
}
.icon-logo-hebie {
	&:before {
		content: "\e937";
	}
}
.icon-logo-wsm {
	&:before {
		content: "\e938";
	}
}
.icon-logo-abus {
	&:before {
		content: "\e9c3";
	}
}
.icon-logo-ortlieb {
	&:before {
		content: "\e9c7";
	}
}
.icon-logo-bumm {
	&:before {
		content: "\e9cd";
	}
}
.icon-logo-trekkingbike {
	&:before {
		content: "\e9ce";
	}
}
.icon-logo-schwalbe {
	&:before {
		content: "\e9cf";
	}
}
.icon-logo-stevens {
	&:before {
		content: "\e9d0";
	}
}
.icon-arrow-external {
	&:before {
		content: "\e935";
	}
}
.icon-arrow-internal {
	&:before {
		content: "\e936";
	}
}
.icon-bus {
	&:before {
		content: "\e933";
	}
}
.icon-walker {
	&:before {
		content: "\e934";
	}
}
.icon-clock {
	&:before {
		content: "\e90c";
	}
}
.icon-unchecked {
	&:before {
		content: "\e90d";
	}
}
.icon-checkbox {
	&:before {
		content: "\e90d";
	}
}
.icon-team {
	&:before {
		content: "\e924";
	}
}
.icon-map {
	&:before {
		content: "\e925";
	}
}
.icon-ranking {
	&:before {
		content: "\e926";
	}
}
.icon-bars {
	&:before {
		content: "\e926";
	}
}
.icon-pin {
	&:before {
		content: "\e927";
	}
}
.icon-marker {
	&:before {
		content: "\e927";
	}
}
.icon-trash-alt {
	&:before {
		content: "\e928";
	}
}
.icon-trash {
	&:before {
		content: "\e929";
	}
}
.icon-bin {
	&:before {
		content: "\e929";
	}
}
.icon-delete {
	&:before {
		content: "\e929";
	}
}
.icon-logout {
	&:before {
		content: "\e92a";
	}
}
.icon-gears {
	&:before {
		content: "\e92b";
	}
}
.icon-add-commune {
	&:before {
		content: "\e92c";
	}
}
.icon-settings {
	&:before {
		content: "\e92d";
	}
}
.icon-gear {
	&:before {
		content: "\e92d";
	}
}
.icon-gear-check {
	&:before {
		content: "\e95b";
	}
}
.icon-edit {
	&:before {
		content: "\e92e";
	}
}
.icon-pen {
	&:before {
		content: "\e92e";
	}
}
.icon-pencil {
	&:before {
		content: "\e92e";
	}
}
.icon-email {
	&:before {
		content: "\e92f";
	}
}
.icon-mail {
	&:before {
		content: "\e92f";
	}
}
.icon-checked {
	&:before {
		content: "\e930";
	}
}
.icon-checkmark {
	&:before {
		content: "\e930";
	}
}
.icon-send {
	&:before {
		content: "\e931";
	}
}
.icon-sleep {
	&:before {
		content: "\e932";
	}
}
.icon-suspend {
	&:before {
		content: "\e932";
	}
}
.icon-city {
	&:before {
		content: "\e900";
	}
}
.icon-town {
	&:before {
		content: "\e900";
	}
}
.icon-commune {
	&:before {
		content: "\e901";
	}
}
.icon-cycle {
	&:before {
		content: "\e90e";
	}
}
.icon-bike {
	&:before {
		content: "\e90e";
	}
}
.icon-cyclist-star {
	&:before {
		content: "\e90f";
		-webkit-font-smoothing: subpixel-antialiased !important;
		-moz-osx-font-smoothing: auto !important;
	}
}
.icon-biker-star {
	&:before {
		content: "\e90f";
	}
}
.icon-cyclist {
	&:before {
		content: "\e910";
		-webkit-font-smoothing: subpixel-antialiased !important;
		-moz-osx-font-smoothing: auto !important;
	}
}
.icon-biker {
	&:before {
		content: "\e910";
	}
}
.icon-factory {
	&:before {
		content: "\e911";
	}
}
.icon-five-dudes {
	&:before {
		content: "\e912";
	}
}
.icon-flag {
	&:before {
		content: "\e913";
	}
}
.icon-info {
	&:before {
		content: "\e914";
	}
}
.icon-kb-logo {
	&:before {
		content: "\e915";
	}
}
.icon-language {
	&:before {
		content: "\e916";
	}
}
.icon-globe {
	&:before {
		content: "\e916";
	}
}
.icon-many-dudes {
	&:before {
		content: "\e917";
	}
}
.icon-mobile {
	&:before {
		content: "\e918";
		-webkit-font-smoothing: subpixel-antialiased !important;
		-moz-osx-font-smoothing: auto !important;
	}
}
.icon-iphone {
	&:before {
		content: "\e918";
	}
}
.icon-my-sr {
	&:before {
		content: "\e919";
	}
}
.icon-mein-sr {
	&:before {
		content: "\e919";
	}
}
.icon-person {
	&:before {
		content: "\e91a";
		-webkit-font-smoothing: subpixel-antialiased !important;
		-moz-osx-font-smoothing: auto !important;
	}
}
.icon-user {
	&:before {
		content: "\e91a";
	}
}
.icon-road {
	&:before {
		content: "\e91b";
	}
}
.icon-track {
	&:before {
		content: "\e91b";
	}
}
.icon-search {
	&:before {
		content: "\e91c";
	}
}
.icon-shout {
	&:before {
		content: "\e91d";
	}
}
.icon-single-dude {
	&:before {
		content: "\e91e";
	}
}
.icon-thin-angle-left {
	&:before {
		content: "\e91f";
	}
}
.icon-thin-angle-right {
	&:before {
		content: "\e920";
	}
}
.icon-three-dudes {
	&:before {
		content: "\e921";
	}
}
.icon-two-dudes {
	&:before {
		content: "\e922";
	}
}
.icon-warning {
	&:before {
		content: "\e923";
	}
}
.icon-report {
	&:before {
		content: "\e923";
	}
}
.icon-star {
	&:before {
		content: "\e905";
	}
}
.icon-favorite {
	&:before {
		content: "\e905";
	}
}
.icon-download {
	&:before {
		content: "\e902";
	}
}
.icon-youtube {
	&:before {
		content: "\e90b";
	}
}
.icon-flickr {
	&:before {
		content: "\e906";
	}
}
.icon-instagram {
	&:before {
		content: "\e907";
	}
}
.icon-twitter {
	&:before {
		content: "\e908";
	}
}
.icon-facebook {
	&:before {
		content: "\e909";
	}
}
.icon-users {
	&:before {
		content: "\e903";
	}
}
.icon-heart {
	&:before {
		content: "\e904";
	}
}
.icon-arrow-left {
	&:before {
		content: "\e5c4";
	}
}
.icon-arrow-down {
	&:before {
		content: "\e5db";
	}
}
.icon-triangle-down {
	&:before {
		content: "\e5c5";
	}
}
.icon-triangle-down-circle {
	&:before {
		content: "\e5c6";
	}
}
.icon-triangle-up {
	&:before {
		content: "\e5c7";
	}
}
.icon-arrow-right {
	&:before {
		content: "\e5c8";
	}
}
.icon-arrow-up {
	&:before {
		content: "\e5d8";
	}
}
.fa-angle-down {
	&:before {
		content: "\e313";
	}
}
.icon-angle-down {
	&:before {
		content: "\e313";
	}
}
.icon-angle-left {
	&:before {
		content: "\e314";
	}
}
.icon-angle-right {
	&:before {
		content: "\e315";
	}
}
.fa-angle-up {
	&:before {
		content: "\e316";
	}
}
.icon-angle-up {
	&:before {
		content: "\e316";
	}
}
.icon-play {
	&:before {
		content: "\e037";
	}
}
.icon-duplizieren {
	&:before {
		content: "\e939";
	}
}
.icon-anmerkung {
	&:before {
		content: "\e93a";
	}
}
.icon-more {
	&:before {
		content: "\e93b";
	}
}
.icon-many-bikes {
	&:before {
		content: "\e93c";
	}
}
.icon-3-bikes {
	&:before {
		content: "\e93d";
	}
}
.icon-zzz {
	&:before {
		content: "\e93e";
	}
}
.icon-lock {
	&:before {
		content: "\e93f";
	}
}
.icon-key {
	&:before {
		content: "\e940";
	}
}
.icon-arrow-panel-down {
	&:before {
		content: "\e941";
	}
}
.icon-arrow-panel-up {
	&:before {
		content: "\e942";
	}
}
.icon-fussweg {
	&:before {
		content: "\e943";
	}
}
.icon-km {
	&:before {
		content: "\e944";
	}
}
.icon-schulradeln {
	&:before {
		content: "\e945";
	}
}
.icon-radpendler {
	&:before {
		content: "\e946";
	}
}
.icon-search-std {
	&:before {
		content: "\e947";
	}
}
.icon-male {
	&:before {
		content: "\e948";
	}
}
.icon-female {
	&:before {
		content: "\e949";
	}
}
.icon-endorse {
	&:before {
		content: "\e94a";
	}
}
.icon-no-endorse {
	&:before {
		content: "\e94b";
	}
}
.icon-area {
	&:before {
		content: "\e94c";
	}
}
.icon-tag {
	&:before {
		content: "\e94d";
	}
}
.icon-radkultur {
	&:before {
		content: "\e94e";
	}
}
.icon-parlamentarier-liste {
	&:before {
		content: "\e94f";
	}
}
.icon-parlamentarier-kreistag {
	&:before {
		content: "\e950";
	}
}
.icon-movebis {
	&:before {
		content: "\e951";
	}
}
.icon-radar {
	&:before {
		content: "\e952";
	}
}
.icon-regeln {
	&:before {
		content: "\e953";
	}
}
.icon-app {
	&:before {
		content: "\e954";
	}
}
.icon-loading {
	&:before {
		content: "\e956";
	}
}
.icon-four-dudes {
	&:before {
		content: "\e957";
	}
}
.icon-bundeslaender {
	&:before {
		content: "\e958";
	}
}
.icon-benzin {
	&:before {
		content: "\e959";
	}
}
.icon-coins {
	&:before {
		content: "\e95a";
	}
}

.icons {
	display: flex;
}