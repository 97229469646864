
.dashboard {

    .new-teams {
        padding: 0 20px 0 5px;
    }

    .team-locked {
        background-image: url("data:image/svg+xml,%3Csvg height='14' viewBox='0 0 10 14' width='10' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%2391a7ad' fill-rule='evenodd'%3E%3Cpath d='m2.79419355 4.071c.02961129-1.23633333 1.00806451-2.22333333 2.20516129-2.22333333 1.19709677 0 2.17548387.98698333 2.20516129 2.22333333v2.5h1.68032258v-2.5h-.00063016c-.02520161-1.41666667-.77116129-2.71416667-1.96322581-3.41533333-1.19140322-.70051667-2.65177419-.70051667-3.84387097 0-1.19140322.70116666-1.93741935 1.99866666-1.96258064 3.41533333v2.5h1.68032258z'/%3E%3Cpath d='m1.11451613 6.78316667h7.7716129c.57964516 0 1.04964516.59896666 1.04964516 1.08463333v4.9915c0 .5989667-.47001613 1.0846333-1.04964516 1.0846333h-7.7716129c-.57964516 0-1.04964516-.5989666-1.04964516-1.0846333v-4.9915c0-.59896667.47001613-1.08463333 1.04964516-1.08463333z'/%3E%3C/g%3E%3C/svg%3E");
        background-repeat: no-repeat;
        width: 10px;
        height: 14px;
        display: inline-block;
        margin-left: 10px;
    }

    /* NumberOfResults */
    .numberOfResults {
        margin-top: auto;
        font-weight: bold;
        margin-top: -125px;
        margin-bottom: 0;
        display: none;
        padding: 0 15px;
        > div, > span {
            float: left;
            display: inline-table;
            margin-right: 10px;
            color: rgba(255, 255, 255, 0.4);
            font-weight: 500;
            font-size: 12px;
        }
        > div {
            cursor: pointer;
            &.active {
                cursor: pointer;
                color:#0086C5;
            }
        }
    }

    /* Dashboard dataTables: (!) dashboard class is on all ORK sites */
    .data-table {
        thead > tr > th {
            padding: 10px 13px 10px 13px;
            &[class*="sorting"]::after {
                left: unset;
                margin-bottom: 7px;
            }
            &.sorting_asc, 
            &.sorting_desc, 
            &.sorting {
                padding-left: 18px;
                padding-right: 18px;
            }
            /* show sorting icon on all sorting columns */
            &.sorting::after {
                content: "\e5c5";
                opacity: 0.4;
            }
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
            }
        }
        /* remove border from first row */
        tbody > tr
        {
            &:first-child td {
                border: 0;
            }
            td:first-child {
                padding-left: 0;
            }
            th.action {
                text-align: center;
                padding-right: 8px;
            }
            td.action {
                text-align: right;
                font-size: 18px;
                a {
                    color: rgba(255, 255, 255, 0.4);
                }
           }
        }
    }
    #dashboard-teams-table {
        width: 100%!important;
        &.data-table {
            thead > tr > {
                th[class*="sorting"]::after {
                    left: unset;
                    margin-bottom: 7px;
                }
                th {
                    padding: 10px 13px 10px 13px;
                    &.sorting::after {
                        content: "\e5c5";
                        opacity: 0.4;
                    }
                    &:first-child {
                        padding-left: 0!important;
                    }
                    &:last-child {
                        padding-right: 0!important;
                        text-align: right;
                    }
                }
            }
            tbody {
                > tr > td:first-child {
                    padding-left: 0;
                }
            }
        } 
    }
    .sort-buttons {
        .text-xs {
            font-weight: 500;
            font-size: 11px;
            line-height: 13px;
            letter-spacing: 0.3px;
            color: rgb(255, 255, 255);
        }
    }
    
    .tableSortXs {
        .btn {
            padding: 4px 0;
            font-size: 11px;
            cursor: pointer;
            &.btn-default {
                background-color: transparent;
                border-color: rgba(0, 134, 197, 0.4);
                &:hover {
                    background-color: rgba(254, 254, 254, 0.07);
                }
                &.active {
                    background-color: rgba(2, 27, 38, 0.8);
                    border: 1px solid rgba(0, 134, 197, 0.4);
                }
            }
        }
    }
}

/* tablet */
@media (min-width: 768px) and (max-width: 992px) {
    .page-dashboard {
        .data-table {
            padding: 0 0 0 10px;
        }
        .new-teams {
            padding: 0 14px 0 0;
        }
        .numberOfResults {
            padding-left: 14px;
        }
    }
}

/* mobile */
@media (max-width: 767px) {
    .dashboard {
        .hide-on-mobile {
            width: 0 !important;
            overflow: hidden;
            padding: 0;
            visibility: hidden;
        }
        .data-table {
            padding: 0 4px;
            thead {
                visibility: collapse; 
            }
            tbody > tr {
                position: relative;
                td {
                    &.teamname {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        vertical-align: top;
                        padding: 20px 30px 0 30px;
                    }
                    &.category {
                        position: absolute;
                        left: 30px;
                        top: 41px;
                        font-size: 11px;
                        opacity: 0.7;
                        padding-left: 0;
                        margin-left: 0;
                        border: 0;
                    }
                    &.action {
                        left: -35px;
                    }
                }
            }
        }
        #dashboard-teams-table_wrapper {
            &.dataTables_wrapper {
                margin: 0 -45px !important;
                div.dataTables_paginate {

                    padding-bottom: 80px;
                }
            }
        }
        // required for border between table rows on mobile
        #dashboard-teams-table {
            border-collapse: collapse!important;
            &.table > tbody > tr {
                border-top: 1px solid #052836;
                &:first-child {
                    border: 0;
                }
            }
        }
        #dashboard-teams-table.data-table tbody > tr > td:first-child {
            padding-left: 30px;
            max-width: 190px;
            min-width: 190px;
        }
        
        .data-table tbody > tr td.action
        // social media share
        #sr-img-dl-modal.modal .btn {
            width: 100%;
        }
        #sr-img-dl-modal.modal .mobile-btn {
            display: block;
        }

        .new-teams {
            padding: 0;
        }

        .dataTables_wrapper {
            margin: 0 -45px !important;
            div.dataTables_paginate {
                padding-top: 80px;
                padding-bottom: 90px;
            }
        }
        .data-table {
            padding: 0 15px;
            thead {
                visibility: collapse; 
            }
            tbody > tr {
                position: relative;
                td {
                    &:first-child {
                        padding-left: 30px;
                    }
                    &:last-child {
                        padding-right: 30px;
                        padding-left: 0;
                    }
                    &.teamname {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        vertical-align: top;
                        padding: 20px 0 0 30px;
                        /* max-width needed to prevent edit action from being pushed out of the screen */
                        max-width: 160px;
                    }
                    &.category {
                        position: initial;
                        span {
                            position: absolute;
                            left: 30px;
                            top: 45px;
                            font-size: 11px;
                            opacity: 0.7;
                            padding-left: 0;
                            margin-left: 0;
                        }
                    }
                }
            }
        }
        .numberOfResults {
            margin-top: -190px;
            margin-bottom: 5px;
            > div, > span {
                margin-right: 18px;
            }
        }
        .paginate_button {
            &.previous {
                display: inline;
            }
            &.next {
                display: inline;
            }
        }
    }
}