.radelkalender-weiterempfehlen {
    .bigger {
        font-size: 1.2em;
    }

    .description {
        margin: 0;
        font-size: 0.9em;
    }

    .bg-white {
        background-color: #fff !important;
    }
    .well-shadow {
        border-color: rgba(0, 0, 0, 0.14);
        -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
    }
}
