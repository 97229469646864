.kommune-star-fragment {
    .star-item {
        min-height: 100px;
        max-height: 200px;
        vertical-align: top;
        text-align: center;
        display: inline-block;
        img {
            border-radius: 50%;
            width: 100px;
            height: 100px;
            object-fit: cover;
            margin-bottom: 16px;
        }
    }
    .star-name {
        font-weight: 400;
        margin-bottom: 6px;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.51px;
    }
    .star-role {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.51px;
        font-weight: 300;
    }
    .blog-text {
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 0.6px;
        color: #454545;
        font-weight: 300;
        height: auto;
    }
    .further-link {
        margin-top: 20px;
        margin-bottom: 50px;
        color: #0086C5;
        font-size: 14px;
        line-height: 17px;
        span {
            cursor: pointer;
            font-weight: 500;
        }
        a {
            font-weight: 500;
        }
        .arrow-icon {
            margin-left: 5px;
            font-size: 25px;
            position: relative;
            vertical-align: middle;
        }
    }
    .blog-link {
        button {
            width: 210px;
            a {
                color: #454545;
            }
        }
    }
    &.profile-card {
        margin: 30px 0;
        padding: 58px 60px 40px 50px;
        p {
            margin-top: 0;
        }
    }
    .profile-image-wrapper {
        display: flex;
        justify-content: center;
    }
    .profile-image {
        .icon-star {
            font-size: 22px;
            color: #0086C5;
            margin-left: 13px;
            position: absolute;
            margin-top: 40px;
        }
    }
}
/* tablet */
@media (min-width: 768px) and (max-width: 992px) {
    .kommune-star-fragment {
        &.profile-card {
            margin: 20px -70px;
        }
    }
}
@media (max-width: 767px) {
    .kommune-star-fragment {
        .blog-text {
            height: auto;
        }
        &.profile-card {
            padding-left: 15px;
            padding-right: 15px;
        }
        .blog-link button {
            width: 100%;
        }
    }

}
