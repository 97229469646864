.kommunenanmeldung-nachmeldung {
    .column-list {
        column-count: 3;
        column-gap: 20px;
        li {
            font-size: 14px;
            line-height: 22px;
            font-weight: 300;
        }
    }
    .sr_creg_half_fade {
        opacity: 0.35;
    }
    .csc-default {
        .bodytext {
            margin-bottom: 6px;
        }
    }
    .commune-data {
        width: 65%;
        h4 {
            margin-top: 0;
            margin-bottom: 30px;
        }
    }
    .commune-data {
        select {
            background-color: transparent;
            border: 0;
            border-bottom: 1px solid rgba(151,151,151, 0.8);
            line-height: 25px;
            width: 70%;
            box-shadow: none;
        }
        td {
            min-width: 200px;
        }
        input[type=text] {
            width: 100%;
            background-color: transparent;
            border: 0;
            border-bottom: 1px solid rgba(151,151,151, 0.8);
            line-height: 25px;
            font-size: 14px;
            &::placeholder {
                opacity: 0.5;
                color: #454545;
            }
        }
    }
    .chosen-container.chosen-container-single {
        background-color: transparent;
        border: 0;
        border-bottom: 1px solid rgba(151,151,151, 0.8);
        line-height: 25px;
        width: 100%;
    }
    #sr_creg_ags_search_main_chosen {
        width: 100% !important;
        .chosen-single {
            background: none;
            box-shadow: none;
            border: none;
            width: 100%;
        }
    }
    #sr_creg_ags_search_main_1 {
        width: 100% !important;
    }
    #sr_creg_ags_search_main {
        width: 100% !important;
    }
    #sr_creg_ags_sub_commune_chosen {
        background: none;
        box-shadow: none;
        border: none;
        width: 100%;
    }
    span.sr_form_feedback {
        padding-left: 0px;
    }
    .rk-compat-2017 {
        span.sr_form_feedback {
            padding-left: 0px;
        }
    }
    #sr_creg_start_date {
        width: 100%;
        background-color: transparent;
        border: 0;
        border-bottom: 1px solid rgba(151,151,151, 0.8);
        line-height: 25px;
        padding: 0;
    }
    #sr_creg_start_date {
        &::placeholder {
            color: #454545;
            opacity: 1;
        }
    }
    .sr-input-date {
        width: 45%;
        background-color: transparent;
        border: 0;
        border-bottom: 1px solid rgba(151,151,151, 0.8);
        padding-right: 10%;
        line-height: 25px;
    }
    #sr_creg_radar_start_date {
        width: 45%;
        background-color: transparent;
        border: 0;
        border-bottom: 1px solid rgba(151,151,151, 0.8);
        padding-right: 10%;
        line-height: 25px;
    }
    #sr_creg_radar_end_date {
        width: 45%;
        background-color: transparent;
        border: 0;
        border-bottom: 1px solid rgba(151,151,151, 0.8);
        float: right;
        line-height: 25px;
    }

    #sr_creg_country, #sr_creg_fstate, #sr_creg_citytype_preset,
    #sr_creg_ags_search_main_chosen,
    #sr_creg_ags_search_main,
    #sr_creg_ags_search_main_1,
    #sr_creg_contact_salutation,
    #sr_creg_contact_title,
    #sr_creg_ownDescription_article,
    #sr_creg_coordination_title,
    #sr_creg_coordination_salutation,
    #sr_creg_invoice_title,
    #sr_creg_radar_salutation,
    #sr_creg_radar_title {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        position: relative;
        z-index: 10;
    }
    .sr_creg_select_field {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        position: relative;
        z-index: 10;
    }
    .icon {
        font-family: 'sr-icons', 'FontAwesome';
        font-size: 20px;
    }
    .table-checkbox {
        td.check {
            width: 30px;
            max-width: 30px;
            min-width: 30px;
        }
        td {
            padding-top: 0px;
            padding-bottom: 12px;
        }
    }
    .commune-data {
        button {
            background-color: transparent;
            color: #454545;
            border: 1px solid #454545;
            border-radius: 22.5px;
            font-size: 14px;
            padding-top: 9px;
            padding-bottom: 9px;
            width: 100%;
            background-image: none;
            height: auto;
            text-shadow: none;
            box-shadow: none;
            font-weight: normal;
        }
    }
    #sr_creg_send_flyers_extra {
        width: 35%;
    }
    #sr_creg_send_posters_extra {
        width: 35%;
    }
    .final-buttons {
        .button-save {
            float: left;
            background-color: transparent;
            color: #454545;
            border: 1px solid #454545;
            border-radius: 22.5px;
            font-size: 14px;
            padding: 9px 30px 11px 30px;
        }
    }
    .button-submit {
        float: right;
        background-color: #8CB82B;
        color: #FFFFFF;
        border: 1px solid transparent;
        border-radius: 22.5px;
        font-size: 14px;
        padding: 9px 30px 11px 30px;
    }
    .button_big_c {
        float: right;
        background-color: #8CB82B;
        color: #FFFFFF;
        border: 1px solid transparent;
        border-radius: 22.5px;
        font-size: 14px;
        padding: 9px 30px 11px 30px;
    }
    a.button-submit {
        color: #fff;
        &:hover {
            color: #fff;
        }
    }
    .shopping-basket {
        width: 304px;
        min-height: 304px;
        max-height: 60vh;
        border: 1px solid #8cb82b;
        border-radius: 4px;
    }
    .shopping-header {
        h4 {
            color: #4A4A4A;
            font-size: 15px;
        }
    }
    .shopping-content {
        min-height: 210px;
        max-height: 50vh;
        overflow: hidden;
        overflow-y: scroll;
        #attendence-overview {
            .price-label {
                color: #A3A0A0;
            }
        }
        #merchandise-overview {
            .price-label {
                color: #A3A0A0;
            }
        }
        #radar-overview {
            .price-label {
                color: #A3A0A0;
            }
        }
    }
    #merchandise-overview {
        margin-top: 10px;
        td {
            margin-top: 5px;
        }
    }
    #radar-overview {
        margin-top: 10px;
        td {
            margin-top: 5px;
        }
    }
    #attendence-overview {
        td {
            margin-top: 5px;
        }
    }
    #sr_kommunen_nachmeldung_main {
        a {
            font-weight: 500;
        }
        input {
            max-width: 70%;
        }
    }
    .selection {
        max-width: 70%;
    }

    /* external link icon */
    #sr_kommunen_nachmeldung_main {
        a#anchor-link {
            &::after {
                content: '';
            }
        }
        a {
            &::after {
                font-family: 'sr-icons', 'FontAwesome';
                content: " \e935";
                font-size: 9px;
            }
            &.no-link-arrow {
                &::after {
                    content: "";
                }
            }
        }
    }
    #sr_kommunenregistrierung_sub_communes {
        a {
            &::after {
                content: "";
            }
        }
    }
    .shopping-total {
        &::before {
            content: '';
            width: 97%;
            height: 80px;
            position: absolute;
            bottom: -2px;
            background: linear-gradient(rgba(255,255,255,0) 0px, white 40px);
            background-color: transparent;
        }
        position: absolute;
        bottom: 3px;
        margin-right: 10px;
    }
    .shopping-basket {
        table {
            width: 90%;
            margin-left: 24.5px;
            margin-right: 24.5px;
        }
    }
    .table-condensed {
        >tbody {
            >tr {
                >td {
                    padding: 6px 10px;
                }
            }
        }
    }
    #total-overview {
        position: relative;
        .price-label {
            width: 170px;
        }
    }
    #total-price {
        font-weight: bold;
        font-size: 16px;
        float: right;
        padding-right: 10px;
    }
    .shopping-item {
        display: none;
    }
    .commune-pricing {
        float: right;
        max-width: 305px;
        min-width: 305px;
        font-size: 12px;
        position: absolute;
        right: 45px;
    }
    .fixed-top {
        position: absolute;
        right: 45px;
    }
    .fixed-bottom {
        position: absolute;
        bottom: 70px;
        right: 45px;
    }
    .attend-label {
        display: none;
    }
    .price-label {
        color: #454545;
        font-size: 12px;
        width: 195px;
        opacity: 0.6;
    }
    .price-list {
        float: right;
        font-size: 12px;
        font-weight: bold;
        color: #454545;
        margin-right: 5px;
    }
    #shopping-list {
        h4 {
            margin-top: 20px;
        }
    }
    #shopping-list-info {
        display: none;
    }
    .merch-label {
        display: none;
    }
    .radar-label {
        display: none;
    }
    .attendence-green {
        color: #8CB82B;
    }
    .attendence-orange {
        color: #F5A623;
    }
    #attendence-info-price {
        float: right;
    }
    .select-arrow {
        &::after {
            border: solid #757575;
            border-width: 5px 4px 0 4px;
            border-color: #757575 transparent;
            display: inline-block;
            position: relative;
            top: -23px;
            left: 94%;
            content: "";
            z-index: 1;
        }
    }
    .select-arrow-pl {
        &::after {
            border: solid #757575;
            border-width: 5px 4px 0 4px;
            border-color: #757575 transparent;
            display: inline-block;
            position: relative;
            top: -23px;
            left: 95%;
            content: "";
            z-index: 1;
        }
    }
    .commune-data {
        button.w-50 {
            width: 50%;
        }
        td.pt-0 {
            padding-top: 0px;
        }
    }

    .commune-data input[type="checkbox"], .commune-data input[type="radio"] {
        display: none;
    }

    .commune-data input[type="checkbox"] + label, .commune-data input[type="checkbox"] + span {
        font-size: 14px;
    }

    .commune-data input[type="checkbox"] + label:before, .commune-data input[type="checkbox"] + span:before {
        width: 16px;
        height: 16px;
        background-color: #FFFFFF;
        display: table;
        content:"";
        border-radius: 2px;
        border: 1.5px solid rgba(69,69,69, 0.38);
        font-size:13px;
        line-height: 0.8;
        border-collapse:separate;
    }

    .commune-data input[type="checkbox"]:checked + label:before, .commune-data input[type="checkbox"]:checked + span:before {
        font-family: 'sr-icons', 'FontAwesome';
        font-size:13px;
        background-color: #8cb82b;
        content: "\f00c";
        color: #FFFFFF;
        width: 16px;
        height: 16px;
        border: 1.5px solid #8cb82b;
        display: table;
        line-height: 0.8;
    }

    .commune-data input[type="radio"] + label:before {
        width:16px;
        height:16px;
        background-color: #FFFFFF;
        display: block;
        content:"";
        border-radius: 10px;
        border: 1.5px solid #b7b7b7;
    }

    .commune-data input[type="radio"]:checked + label:before {
        background-color: #8cb82b;
        box-shadow: inset 0px 0px 0px 3px #FFFFFF;
    }

    select {
        &:invalid {
            color: #454545;
            opacity: .5;
        }
    }
    .space-icon {
        margin-left: 95px;
    }
    .fs-12 {
        font-size: 12px;
        line-height: 17px;
    }
    .sr-creg-icon-style-big {
        color: #454545;
        font-size: 24px;
        opacity: 0.38;
    }
    .form-only-for-administration {
        color: #454545;
        opacity: 0.6;
    }
    .table-info-administration {
        margin-bottom: 16px;
    }
    .table-info-text {
        opacity: 0.6;
        font-size: 12px;
        line-height: 17px;
        color: #454545;
    }
    .table-agb {
        font-size: 12px;
        td.check {
            vertical-align: top;
            padding-top: 5px;
        }
    }
    .sr-creg-icon-style-small {
        color: #b6b6b6;
        font-size: 20px;
        position: relative;
        top: 3px;
    }
    .double-line {
        line-height: 40px;
    }
    .space-right {
        margin-right: 8px;
    }
    .space-left {
        margin-left: 12px;
    }
    #sr_kommunen_nachmeldung_main {
        position: relative;
    }
    .container-main {
        border: 1px solid #F5F5F5;
        background-color: #FFFFFF;
        padding: 27px 24px 46px 24px;
        clear: both;
        color: #454545;
        margin-top: 0;
    }
    .required-text-info {
        color: #454545;
        font-size: 14px;
        line-height: 22px;
        float: right;
        opacity: 0.5;
        position: relative;
        top: 25px;
        right: 45px;
    }
    .sr_kommunenregistrierung_sub {
        border: 1px solid #F5F5F5;
        background-color: #FFFFFF;
        padding: 23px 45px 50px 24px;
        clear: initial;
        color: #454545;
        margin-top: 5px;
    }
    .save-button {
        a.buttons {
            background-color: transparent;
            color: #454545;
            border: 1px solid #454545;
            font-size: 14px;
            padding-top: 9px;
            padding-bottom: 9px;
            border-radius: 22.5px;
            display: inline-block;
            float: left;
            margin-right: 10px;
        }
    }
    .commune-data {
        td.mw-23 {
            min-width: 230px;
            max-width: 250px;
        }
        td.mw-26 {
            min-width: 260px;
        }
        td.mw-29 {
            min-width: 290px;
        }
    }

    #article {
        display: none;
    }
    #own_description {
        display: none;
    }
    .own_description {
        display: none;
    }
    #sr-creg-kommunenregistrierung-finish {
        .modal-header {
            padding: 5px 24px 4px;
        }
        .modal-body {
            padding: 18px 24px 24px 24px;
        }
        .modal-dialog {
            width: 538px;
            margin: 290px auto;
        }
    }
    #sr-creg-final-abort {
        float: left;
        background-color: transparent;
        color: #454545;
        border: 1px solid #454545;
        border-radius: 22.5px;
        font-size: 14px;
        padding: 9px 30px 11px 30px;
    }
    #sr-creg-final-finish {
        float: right;
        padding-top: 9px;
        padding-bottom: 11px;
    }
    .textarea-extra-note {
        border-radius: 7px;
        border-color: rgba(151,151,151, 0.8);
        border-style: solid;
        border-width: 1.5px;
        width: 275px;
        height: 109px;
    }
    .form-control {
        height: 30px;
    }
    #sr_kommunen_nachmeldung_main {
        a#anchor_link {
            &::after {
                content: "";
            }
        }
    }
    .select2.select2-container.select2-container--default {
        width: 70%!important;
        max-width: 70%;
    }
    // desktop only registration info
    .confirm-message {
        width: 100%;
        border-radius: 4px;
        color: #454545;
        display: flex;
        justify-content: left;
        background-color: #fbfbfb;
        flex-direction: column;
        margin-top: -45px;
        .img-confirm-message {
            img {
                width: 100%;
                height: auto;
            }
        }
        .text-confirm-message {
            margin-top: 26px;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            margin-left: 36px;
            margin-right: 36px;
        }
    }
}
