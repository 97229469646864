body {
    margin: 0;
}
article {
	display: block;
}
aside {
	display: block;
}
details {
	display: block;
}
figcaption {
	display: block;
}
figure {
	display: block;
	margin: 1em 40px;
}
footer {
	display: block;
}
header {
	display: block;
}
hgroup {
	display: block;
}
main {
	display: block;
}
menu {
	display: block;
}
nav {
	display: block;
}
section {
	display: block;
}
summary {
	display: block;
}
audio {
	display: inline-block;
	vertical-align: baseline;
	&:not([controls]) {
		display: none;
		height: 0;
	}
}
canvas {
	display: inline-block;
	vertical-align: baseline;
}
progress {
	display: inline-block;
	vertical-align: baseline;
}
video {
	display: inline-block;
	vertical-align: baseline;
}
[hidden] {
	display: none;
}
template {
	display: none;
}
abbr[title] {
	border-bottom: 1px dotted;
}
b {
	font-weight: bold;
}
strong {
	font-weight: bold;
}
dfn {
	font-style: italic;
}
h1 {
	font-size: 2em;
	margin: 0.67em 0;
}
mark {
	background: #ff0;
	color: #000;
}
small {
	font-size: 80%;
}
sub {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
	bottom: -0.25em;
}
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
	top: -0.5em;
}
img {
	border: 0;
}
svg {
	&:not(:root) {
		overflow: hidden;
	}
}
hr {
	box-sizing: content-box;
	height: 0;
}
pre {
	overflow: auto;
	font-family: monospace, monospace;
	font-size: 1em;
}
code {
	font-family: monospace, monospace;
	font-size: 1em;
}
kbd {
	font-family: monospace, monospace;
	font-size: 1em;
}
samp {
	font-family: monospace, monospace;
	font-size: 1em;
}
button {
	color: inherit;
	font: inherit;
	margin: 0;
	overflow: visible;
	text-transform: none;
	-webkit-appearance: button;
	cursor: pointer;
	&::-moz-focus-inner {
		border: 0;
		padding: 0;
	}
}
input {
	color: inherit;
	font: inherit;
	margin: 0;
	line-height: normal;
	&::-moz-focus-inner {
		border: 0;
		padding: 0;
	}
}
optgroup {
	color: inherit;
	font: inherit;
	margin: 0;
	font-weight: bold;
}
select {
	color: inherit;
	font: inherit;
	margin: 0;
	text-transform: none;
}
textarea {
	color: inherit;
	font: inherit;
	margin: 0;
	overflow: auto;
}
html {
	input[type="button"] {
		-webkit-appearance: button;
		cursor: pointer;
	}
	input[disabled] {
		cursor: default;
	}
}
input[type="reset"] {
	-webkit-appearance: button;
	cursor: pointer;
}
input[type="submit"] {
	-webkit-appearance: button;
	cursor: pointer;
}
button[disabled] {
	cursor: default;
}
input[type="checkbox"] {
	box-sizing: border-box;
	padding: 0;
}
input[type="radio"] {
	box-sizing: border-box;
	padding: 0;
}
input[type="number"] {
	&::-webkit-inner-spin-button {
		height: auto;
	}
	&::-webkit-outer-spin-button {
		height: auto;
	}
}
input[type="search"] {
	-webkit-appearance: textfield;
	box-sizing: content-box;
	&::-webkit-search-cancel-button {
		-webkit-appearance: none;
	}
	&::-webkit-search-decoration {
		-webkit-appearance: none;
	}
}
fieldset {
	border: 1px solid #c0c0c0;
	margin: 0 2px;
	padding: 0.35em 0.625em 0.75em;
}
legend {
	border: 0;
	padding: 0;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
td {
	padding: 0;
}
th {
	padding: 0;
}
