.form-horizontal {
	.form-control {
		&::-webkit-input-placeholder {
			color: #bbb;
		}
		&::-moz-placeholder {
			color: #bbb;
		}
		&:-moz-placeholder {
			color: #bbb;
		}
		&:-ms-input-placeholder {
			color: #bbb;
		}
	}
}
.dashboard {
	.form-horizontal {
		.form-control {
			&::-webkit-input-placeholder {
				color: rgba(255, 255, 255, 0.6);
			}
			&::-moz-placeholder {
				color: rgba(255, 255, 255, 0.6);
			}
			&:-moz-placeholder {
				color: rgba(255, 255, 255, 0.6);
			}
			&:-ms-input-placeholder {
				color: rgba(255, 255, 255, 0.6);
			}
		}
	}
}
