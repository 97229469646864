#name-change-modal {
    margin-top: 50px;
    label {
        color: rgba(69, 69, 69, 0.8);
        font-weight: normal;
    }
    .modal-dialog {
        width:900px;
    }
    .modal-header {
        color: #000;
        max-width: 400px;
        .select2.select2-container.select2-container--default.select2-container--below.select2-container--focus {
            .selection {
                .select2-selection--single .select2-selection__rendered {
                    color: #333!important;
                }
            }
        }

    }
    .select2-container--default .select2-selection--single .select2-selection__arrow b {
        border-color: #888 transparent transparent transparent !important;
    }
    .city-name-overview-item {
        color: #000;
        margin-left: 10px;

        .city-name-item {
            min-width: 400px;
            justify-content: left;
        }

        input {
            width: 300px;
            color: rgba(69, 69, 69, 0.8);
        }
    }

    .spinner:before {
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-family: "sr-icons";
        color: #fff;
        content: "\e956";
        animation: fa-spin 2s infinite linear;
    }
}
