.schulradeln-import{
    h1 {
        margin-bottom: 24px;
    }
    .btn {
        width: auto;
    }
    p {
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
    }
    a {
        font-size: 14px;
        line-height: 22px;
        font-weight: 500;
    }
    .icon-download {
        margin-top: 38px;
        margin-bottom: 38px;
        display: block;
        font-size: 14px;
        line-height: 22px;
        font-weight: 500;
        &::before {
            margin-right: 12px;
            font-size: 18px;
            vertical-align: bottom;
        }
    }
    .info {
        ul {
            padding-left: 12px;
        }
    }
    #sr-import-file {
        margin-top: 12px;
        &::file-selector-button {
            display: none;
        }
    }
    @media (max-width: 767px) {
        .schulradeln-import {
            h1 {
                margin-bottom: 30px;
            }
            .btn {
                width: 100%;
            }
        }
    }
}
