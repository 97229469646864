.newsfeed-button {
    padding-left: 15px;
    display: flex;
    flex-direction: row;
}

.newsfeed-item, .event-item {
    margin-bottom: 20px;
    background-color: #073B50;
    border-radius: 7px;
    padding: 20px
}

.newsfeed-header, .event-header {
    display: flex;
    justify-content: space-between;
}

.newsfeed-date, .event-date {
    font-size: 12px;
    line-height: 17px;
    color: #AAD0D2;
    opacity: 0.7;
    display: flex;
}

.newsfeed-title, .event-title {
    font-size: 12px;
    line-height: 17px;
    color: #FFFFFF;
    opacity: 0.5;
    margin-top: 10px;
}

.newsfeed-text, .event-text {
    font-size: 12px;
    color: #FFFFFF;
    margin-top: 10px;
}
.newsfeed-date-text, .event-date-text {
    margin-right: 10px;
}

#sr-newsfeed:hover {
    overflow-y: auto;
}

#sr-newsfeed {
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: hidden;
}

#sr-add-newsfeed-entry {
    width: 250px;
}

#sr-cancel-newsfeed-update {
    width: 250px;
    margin-top: 0px;
}

#newsfeedDeleteCancelButton {
    border-color: #454545;
    color: #454545;
}