.well {
	min-height: 20px;
	padding: 19px;
	margin-bottom: 20px;
	background-color: #f5f5f5;
	border: 1px solid #e3e3e3;
	border-radius: 4px;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
	blockquote {
		border-color: #ddd;
		border-color: rgba(0, 0, 0, 0.15);
	}
}
.well-lg {
	padding: 24px;
	border-radius: 7px;
}
.well-sm {
	padding: 9px;
	border-radius: 3px;
}
.box-info {
	padding: 20px 30px;
	background-color: #ECECEC;
	border-radius: 7px;
	margin-bottom: 30px;
	font-weight: 300;
    a {
        font-weight: 500;
    }
}