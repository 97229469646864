@-ms-viewport {
	width: device-width;
}
.visible-xs {
	display: none !important;
}
.visible-sm {
	display: none !important;
}
.visible-md {
	display: none !important;
}
.visible-lg {
	display: none !important;
}
.visible-print {
	display: none !important;
}
@media (max-width: 767px) {
	.visible-xs {
		display: block !important;
	}
	table.visible-xs {
		display: table !important;
	}
	tr.visible-xs {
		display: table-row !important;
	}
	th.visible-xs {
		display: table-cell !important;
	}
	td.visible-xs {
		display: table-cell !important;
	}
	.hidden-xs {
		display: none !important;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.visible-sm {
		display: block !important;
	}
	table.visible-sm {
		display: table !important;
	}
	tr.visible-sm {
		display: table-row !important;
	}
	th.visible-sm {
		display: table-cell !important;
	}
	td.visible-sm {
		display: table-cell !important;
	}
	.hidden-sm {
		display: none !important;
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	.visible-md {
		display: block !important;
	}
	table.visible-md {
		display: table !important;
	}
	tr.visible-md {
		display: table-row !important;
	}
	th.visible-md {
		display: table-cell !important;
	}
	td.visible-md {
		display: table-cell !important;
	}
	.hidden-md {
		display: none !important;
	}
}
@media (min-width: 1200px) {
	.visible-lg {
		display: block !important;
	}
	table.visible-lg {
		display: table !important;
	}
	tr.visible-lg {
		display: table-row !important;
	}
	th.visible-lg {
		display: table-cell !important;
	}
	td.visible-lg {
		display: table-cell !important;
	}
	.hidden-lg {
		display: none !important;
	}
}
@media print {
	.visible-print {
		display: block !important;
	}
	table.visible-print {
		display: table !important;
	}
	tr.visible-print {
		display: table-row !important;
	}
	th.visible-print {
		display: table-cell !important;
	}
	td.visible-print {
		display: table-cell !important;
	}
	.hidden-print {
		display: none !important;
	}
}
