body {
	text-rendering: optimizeLegibility;
	-webkit-font-feature-settings: "pnum";
	font-feature-settings: "pnum";
	font-variant-numeric: proportional-nums;
}
h1 {
	font-weight: normal;
	font-size: 32px;
	line-height: 40px;
}
h2 {
	font-weight: normal;
	font-size: 32px;
	line-height: 40px;
}
h3 {
	font-weight: normal;
	font-size: 16px;
}
h4 {
	font-weight: normal;
	font-size: 16px;
	line-height: inherit;
}
h5 {
	font-weight: normal;
}
h6 {
	font-weight: normal;
}
.h1 {
	font-weight: normal;
}
.h2 {
	font-weight: normal;
}
.h3 {
	font-weight: normal;
}
.h4 {
	font-weight: normal;
}
.h5 {
	font-weight: normal;
}
.h6 {
	font-weight: normal;
}
.info {
	position: relative;
	padding-left: 40px;
	font-size: 14px;
	font-weight: 500;
	&::before {
		position: absolute;
		left: 0;
		font-family: 'sr-icons' !important;
		content: "\e914";
		font-size: 24px;
		line-height: 20px;
		color: #b6b6b6;
	}
	&.info-centered {
		text-align: center;
		padding-left: 0!important;
		&::before {
			position: relative;
			margin-right: 10px;
		}
	}
	&.info-one-line {
		&::before {
			line-height: 12px;
		}
	}
}
small {
	font-weight: 400;
	opacity: 0.6;
}
@media (max-width: 1025px) {
	body {
		overflow-x: hidden;
	}
}
@media (max-width: 479px) {
	h1 {
		font-size: 24px;
		line-height: 30px;
	}
	h2 {
		font-size: 24px;
		line-height: 30px;
	}
}
@media (max-width: 768px) {
	h1 {
		font-size: 24px;
		line-height: 30px;
	}
	h2 {
		font-size: 24px;
		line-height: 30px;
	}
	h3 {
		font-size: 16px;
		line-height: 24px;
	}
}
@media (min-width: 768px) {
	section {
		>.container {
			>.section-heading {
				text-align: center;
			}
		}
	}
}
