.ct-label {
	fill: rgba(0, 0, 0, 0.4);
	color: rgba(0, 0, 0, 0.4);
	font-size: 7.5px;
	line-height: 1;
}
.ct-chart-line {
	.ct-label {
		display: block;
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
	}
}
.ct-chart-bar {
	.ct-label {
		display: block;
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
	}
	.ct-label.ct-horizontal.ct-start {
		-webkit-box-align: flex-end;
		-webkit-align-items: flex-end;
		-ms-flex-align: flex-end;
		align-items: flex-end;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		text-align: center;
		text-anchor: start;
	}
	.ct-label.ct-horizontal.ct-end {
		-webkit-box-align: flex-start;
		-webkit-align-items: flex-start;
		-ms-flex-align: flex-start;
		align-items: flex-start;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		text-align: center;
		text-anchor: start;
	}
}
.ct-chart-pie {
	.ct-label {
		dominant-baseline: central;
	}
}
.ct-chart-donut {
	.ct-label {
		dominant-baseline: central;
	}
}
.ct-label.ct-horizontal.ct-start {
	-webkit-box-align: flex-end;
	-webkit-align-items: flex-end;
	-ms-flex-align: flex-end;
	align-items: flex-end;
	-webkit-box-pack: flex-start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: flex-start;
	justify-content: flex-start;
	text-align: left;
	text-anchor: start;
}
.ct-label.ct-horizontal.ct-end {
	-webkit-box-align: flex-start;
	-webkit-align-items: flex-start;
	-ms-flex-align: flex-start;
	align-items: flex-start;
	-webkit-box-pack: flex-start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: flex-start;
	justify-content: flex-start;
	text-align: left;
	text-anchor: start;
}
.ct-label.ct-vertical.ct-start {
	-webkit-box-align: flex-end;
	-webkit-align-items: flex-end;
	-ms-flex-align: flex-end;
	align-items: flex-end;
	-webkit-box-pack: flex-end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: flex-end;
	justify-content: flex-end;
	text-align: right;
	text-anchor: end;
}
.ct-label.ct-vertical.ct-end {
	-webkit-box-align: flex-end;
	-webkit-align-items: flex-end;
	-ms-flex-align: flex-end;
	align-items: flex-end;
	-webkit-box-pack: flex-start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: flex-start;
	justify-content: flex-start;
	text-align: left;
	text-anchor: start;
}
.ct-chart-bar.ct-horizontal-bars {
	.ct-label.ct-horizontal.ct-start {
		-webkit-box-align: flex-end;
		-webkit-align-items: flex-end;
		-ms-flex-align: flex-end;
		align-items: flex-end;
		-webkit-box-pack: flex-start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: flex-start;
		justify-content: flex-start;
		text-align: left;
		text-anchor: start;
	}
	.ct-label.ct-horizontal.ct-end {
		-webkit-box-align: flex-start;
		-webkit-align-items: flex-start;
		-ms-flex-align: flex-start;
		align-items: flex-start;
		-webkit-box-pack: flex-start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: flex-start;
		justify-content: flex-start;
		text-align: left;
		text-anchor: start;
	}
	.ct-label.ct-vertical.ct-start {
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: flex-end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: flex-end;
		justify-content: flex-end;
		text-align: right;
		text-anchor: end;
	}
	.ct-label.ct-vertical.ct-end {
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: flex-start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: flex-start;
		justify-content: flex-start;
		text-align: left;
		text-anchor: end;
	}
}
.ct-grid {
	stroke: rgba(0, 0, 0, 0.2);
	stroke-width: 1px;
	stroke-dasharray: 2px;
}
.ct-grid-background {
	fill: none;
}
.ct-point {
	stroke-width: 10px;
	stroke-linecap: round;
}
.ct-line {
	fill: none;
	stroke-width: 4px;
}
.ct-area {
	stroke: none;
	fill-opacity: 0.1;
}
.ct-bar {
	fill: none;
	stroke-width: 10px;
}
.ct-slice-donut {
	fill: none;
	stroke-width: 60px;
}
.ct-series-a {
	.ct-point {
		stroke: #d70206;
	}
	.ct-line {
		stroke: #d70206;
	}
	.ct-bar {
		stroke: #d70206;
	}
	.ct-slice-donut {
		stroke: #d70206;
	}
	.ct-slice-pie {
		fill: #d70206;
	}
	.ct-slice-donut-solid {
		fill: #d70206;
	}
	.ct-area {
		fill: #d70206;
	}
}
.ct-series-b {
	.ct-point {
		stroke: #f05b4f;
	}
	.ct-line {
		stroke: #f05b4f;
	}
	.ct-bar {
		stroke: #f05b4f;
	}
	.ct-slice-donut {
		stroke: #f05b4f;
	}
	.ct-slice-pie {
		fill: #f05b4f;
	}
	.ct-slice-donut-solid {
		fill: #f05b4f;
	}
	.ct-area {
		fill: #f05b4f;
	}
}
.ct-series-c {
	.ct-point {
		stroke: #f4c63d;
	}
	.ct-line {
		stroke: #f4c63d;
	}
	.ct-bar {
		stroke: #f4c63d;
	}
	.ct-slice-donut {
		stroke: #f4c63d;
	}
	.ct-slice-pie {
		fill: #f4c63d;
	}
	.ct-slice-donut-solid {
		fill: #f4c63d;
	}
	.ct-area {
		fill: #f4c63d;
	}
}
.ct-series-d {
	.ct-point {
		stroke: #d17905;
	}
	.ct-line {
		stroke: #d17905;
	}
	.ct-bar {
		stroke: #d17905;
	}
	.ct-slice-donut {
		stroke: #d17905;
	}
	.ct-slice-pie {
		fill: #d17905;
	}
	.ct-slice-donut-solid {
		fill: #d17905;
	}
	.ct-area {
		fill: #d17905;
	}
}
.ct-series-e {
	.ct-point {
		stroke: #453d3f;
	}
	.ct-line {
		stroke: #453d3f;
	}
	.ct-bar {
		stroke: #453d3f;
	}
	.ct-slice-donut {
		stroke: #453d3f;
	}
	.ct-slice-pie {
		fill: #453d3f;
	}
	.ct-slice-donut-solid {
		fill: #453d3f;
	}
	.ct-area {
		fill: #453d3f;
	}
}
.ct-series-f {
	.ct-point {
		stroke: #59922b;
	}
	.ct-line {
		stroke: #59922b;
	}
	.ct-bar {
		stroke: #59922b;
	}
	.ct-slice-donut {
		stroke: #59922b;
	}
	.ct-slice-pie {
		fill: #59922b;
	}
	.ct-slice-donut-solid {
		fill: #59922b;
	}
	.ct-area {
		fill: #59922b;
	}
}
.ct-series-g {
	.ct-point {
		stroke: #0544d3;
	}
	.ct-line {
		stroke: #0544d3;
	}
	.ct-bar {
		stroke: #0544d3;
	}
	.ct-slice-donut {
		stroke: #0544d3;
	}
	.ct-slice-pie {
		fill: #0544d3;
	}
	.ct-slice-donut-solid {
		fill: #0544d3;
	}
	.ct-area {
		fill: #0544d3;
	}
}
.ct-series-h {
	.ct-point {
		stroke: #6b0392;
	}
	.ct-line {
		stroke: #6b0392;
	}
	.ct-bar {
		stroke: #6b0392;
	}
	.ct-slice-donut {
		stroke: #6b0392;
	}
	.ct-slice-pie {
		fill: #6b0392;
	}
	.ct-slice-donut-solid {
		fill: #6b0392;
	}
	.ct-area {
		fill: #6b0392;
	}
}
.ct-series-i {
	.ct-point {
		stroke: #f05b4f;
	}
	.ct-line {
		stroke: #f05b4f;
	}
	.ct-bar {
		stroke: #f05b4f;
	}
	.ct-slice-donut {
		stroke: #f05b4f;
	}
	.ct-slice-pie {
		fill: #f05b4f;
	}
	.ct-slice-donut-solid {
		fill: #f05b4f;
	}
	.ct-area {
		fill: #f05b4f;
	}
}
.ct-series-j {
	.ct-point {
		stroke: #dda458;
	}
	.ct-line {
		stroke: #dda458;
	}
	.ct-bar {
		stroke: #dda458;
	}
	.ct-slice-donut {
		stroke: #dda458;
	}
	.ct-slice-pie {
		fill: #dda458;
	}
	.ct-slice-donut-solid {
		fill: #dda458;
	}
	.ct-area {
		fill: #dda458;
	}
}
.ct-series-k {
	.ct-point {
		stroke: #eacf7d;
	}
	.ct-line {
		stroke: #eacf7d;
	}
	.ct-bar {
		stroke: #eacf7d;
	}
	.ct-slice-donut {
		stroke: #eacf7d;
	}
	.ct-slice-pie {
		fill: #eacf7d;
	}
	.ct-slice-donut-solid {
		fill: #eacf7d;
	}
	.ct-area {
		fill: #eacf7d;
	}
}
.ct-series-l {
	.ct-point {
		stroke: #86797d;
	}
	.ct-line {
		stroke: #86797d;
	}
	.ct-bar {
		stroke: #86797d;
	}
	.ct-slice-donut {
		stroke: #86797d;
	}
	.ct-slice-pie {
		fill: #86797d;
	}
	.ct-slice-donut-solid {
		fill: #86797d;
	}
	.ct-area {
		fill: #86797d;
	}
}
.ct-series-m {
	.ct-point {
		stroke: #b2c326;
	}
	.ct-line {
		stroke: #b2c326;
	}
	.ct-bar {
		stroke: #b2c326;
	}
	.ct-slice-donut {
		stroke: #b2c326;
	}
	.ct-slice-pie {
		fill: #b2c326;
	}
	.ct-slice-donut-solid {
		fill: #b2c326;
	}
	.ct-area {
		fill: #b2c326;
	}
}
.ct-series-n {
	.ct-point {
		stroke: #6188e2;
	}
	.ct-line {
		stroke: #6188e2;
	}
	.ct-bar {
		stroke: #6188e2;
	}
	.ct-slice-donut {
		stroke: #6188e2;
	}
	.ct-slice-pie {
		fill: #6188e2;
	}
	.ct-slice-donut-solid {
		fill: #6188e2;
	}
	.ct-area {
		fill: #6188e2;
	}
}
.ct-series-o {
	.ct-point {
		stroke: #a748ca;
	}
	.ct-line {
		stroke: #a748ca;
	}
	.ct-bar {
		stroke: #a748ca;
	}
	.ct-slice-donut {
		stroke: #a748ca;
	}
	.ct-slice-pie {
		fill: #a748ca;
	}
	.ct-slice-donut-solid {
		fill: #a748ca;
	}
	.ct-area {
		fill: #a748ca;
	}
}
.ct-square {
	display: block;
	position: relative;
	width: 100%;
	&:before {
		display: block;
		float: left;
		content: "";
		width: 0;
		height: 0;
		padding-bottom: 100%;
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
	>svg {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
}
.ct-minor-second {
	display: block;
	position: relative;
	width: 100%;
	&:before {
		display: block;
		float: left;
		content: "";
		width: 0;
		height: 0;
		padding-bottom: 93.75%;
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
	>svg {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
}
.ct-major-second {
	display: block;
	position: relative;
	width: 100%;
	&:before {
		display: block;
		float: left;
		content: "";
		width: 0;
		height: 0;
		padding-bottom: 88.8888888889%;
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
	>svg {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
}
.ct-minor-third {
	display: block;
	position: relative;
	width: 100%;
	&:before {
		display: block;
		float: left;
		content: "";
		width: 0;
		height: 0;
		padding-bottom: 83.3333333333%;
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
	>svg {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
}
.ct-major-third {
	display: block;
	position: relative;
	width: 100%;
	&:before {
		display: block;
		float: left;
		content: "";
		width: 0;
		height: 0;
		padding-bottom: 80%;
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
	>svg {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
}
.ct-perfect-fourth {
	display: block;
	position: relative;
	width: 100%;
	&:before {
		display: block;
		float: left;
		content: "";
		width: 0;
		height: 0;
		padding-bottom: 75%;
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
	>svg {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
}
.ct-perfect-fifth {
	display: block;
	position: relative;
	width: 100%;
	&:before {
		display: block;
		float: left;
		content: "";
		width: 0;
		height: 0;
		padding-bottom: 66.6666666667%;
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
	>svg {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
}
.ct-minor-sixth {
	display: block;
	position: relative;
	width: 100%;
	&:before {
		display: block;
		float: left;
		content: "";
		width: 0;
		height: 0;
		padding-bottom: 62.5%;
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
	>svg {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
}
.ct-golden-section {
	display: block;
	position: relative;
	width: 100%;
	&:before {
		display: block;
		float: left;
		content: "";
		width: 0;
		height: 0;
		padding-bottom: 61.804697157%;
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
	>svg {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
}
.ct-major-sixth {
	display: block;
	position: relative;
	width: 100%;
	&:before {
		display: block;
		float: left;
		content: "";
		width: 0;
		height: 0;
		padding-bottom: 60%;
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
	>svg {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
}
.ct-minor-seventh {
	display: block;
	position: relative;
	width: 100%;
	&:before {
		display: block;
		float: left;
		content: "";
		width: 0;
		height: 0;
		padding-bottom: 56.25%;
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
	>svg {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
}
.ct-major-seventh {
	display: block;
	position: relative;
	width: 100%;
	&:before {
		display: block;
		float: left;
		content: "";
		width: 0;
		height: 0;
		padding-bottom: 53.3333333333%;
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
	>svg {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
}
.ct-octave {
	display: block;
	position: relative;
	width: 100%;
	&:before {
		display: block;
		float: left;
		content: "";
		width: 0;
		height: 0;
		padding-bottom: 50%;
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
	>svg {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
}
.ct-major-tenth {
	display: block;
	position: relative;
	width: 100%;
	&:before {
		display: block;
		float: left;
		content: "";
		width: 0;
		height: 0;
		padding-bottom: 40%;
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
	>svg {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
}
.ct-major-eleventh {
	display: block;
	position: relative;
	width: 100%;
	&:before {
		display: block;
		float: left;
		content: "";
		width: 0;
		height: 0;
		padding-bottom: 37.5%;
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
	>svg {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
}
.ct-major-twelfth {
	display: block;
	position: relative;
	width: 100%;
	&:before {
		display: block;
		float: left;
		content: "";
		width: 0;
		height: 0;
		padding-bottom: 33.3333333333%;
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
	>svg {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
}
.ct-double-octave {
	display: block;
	position: relative;
	width: 100%;
	&:before {
		display: block;
		float: left;
		content: "";
		width: 0;
		height: 0;
		padding-bottom: 25%;
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
	>svg {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
}
