/* styling for citysearch searchbox, for example on the homepage (Kommunensuche) */

$active: #0086C5;
$light: #EFECEC;

.searchbox {
    margin-top:0;
    background-color: #fbfbfb;
    p {
        color:#aaa;
    }
    h2, h3 {
        color:#454545;
    }
    input {
        z-index:10;
        position: relative;
    }
    .input-search-field {
        position: relative;
    }
    .input-group {
        width:100%;
        .form-control {
            &:focus {
                border:1px solid $light;
            }
            &.form-control-searchbox {
                background-color:#fff;
                border-radius:26px;
                border:1px solid $light;
                filter:drop-shadow(0px 0px 20px rgba(0,0,0,0.05));
                -webkit-filter:drop-shadow(0px 0px 20px rgba(0,0,0,0.05)); 
                &.active {
                    border-bottom:0;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
        }
    }
    .btn-search-city {
        background-color:transparent;
        padding:0;
        border:0;
        position:absolute;
        z-index:10;
        right:25px;
        top:-2px;
        &:active {
            box-shadow:none;
        }
    }

    .autocomplete-list {
        position: absolute;
        background-color: #fff;
        top: 52px;
        display: none;
        width: 99%;
        border: 1px solid $light;
        border-bottom-right-radius: 26px;
        border-bottom-left-radius: 26px;
        border-top:0;
        z-index: 10;
        ul {
            padding:0;
            margin-bottom:0;
            max-height: 200px;
            overflow-y: auto;
        }
        li {
            list-style: none;
            line-height: 1.5;
            padding: 10px 0;
            margin:0 35px;
            font-size:17px;
            line-height:24px;
            &:hover {
                cursor: pointer;
                font-weight:500;
                color:$active;
            }
            &:first-of-type {
                border-top:1px solid $active;
            }
            &:last-of-type {
                border-bottom-right-radius: 26px;
                border-bottom-left-radius: 26px;
                padding-bottom: 22px;
            }
            
        }
    }
}

@media (max-width: 767px) { 
    .searchbox {
        .autocomplete-list {
            top:45px;
            width: 99%;
            li {
                margin:0 30px;
            }
        }
        .form-control-searchbox {
            font-size:17px;
        }
    }
}