.alert-success {
	hr {
		border-top-color: #c9e2b3;
	}
	.alert-link {
		color: #2b542c;
	}
}
.alert-info {
	hr {
		border-top-color: #a6e1ec;
	}
	.alert-link {
		color: #245269;
	}
}
.alert-warning {
	hr {
		border-top-color: #f7e1b5;
	}
	.alert-link {
		color: #66512c;
	}
}
.alert-danger {
	hr {
		border-top-color: #e4b9c0;
	}
	.alert-link {
		color: #843534;
	}
}
