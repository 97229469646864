.flyer-generator-page {
    .w70 {
        width: 70%;
    }
    .w65 {
        width: 65%;
    }
    .form-horizontal .form-column-wide.w100 {
        width: 100%;
    }
    .flyer-generator-container #formStepOne .form-group .w-300,
    .flyer-generator-container #formStepTwo .form-group .w-300 {
        width: 300px;
    }
    .bg-color-green {
        background-color: $color-green;
    }
    section {
        background-color: #FBFBFB;
    }
    .flyer-generator-container .control-label.w100 {
        width: 100%;
    }
    .text-color {
        color: rgba(69,69,69, .8);
    }
    .filename {
        display: inline-block;
        font-size: 12px;
        line-height: 17px;
        font-weight: 300;
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 9px 0;
    }
    .delete-image {
        display: inline-block;
        width: 18px;
        height: 18px;
        text-decoration: none;
        vertical-align: middle;
        line-height: 1;
        color: rgba(0,0,0,.6);
        cursor: pointer;
        transform: translateY(-12px);
    }
    .row-buttons {
        #toBeforeStep {
            position: absolute;
            left: 0;
        }
        #flyerPreview {
            margin: 0 auto;
        }
        #toNextStep {
            position: absolute;
            right: 0;
        }
        #sendFlyer {
            position: absolute;
            right: 0;
        }
    }
    .form-group {
        textarea {
            &.form-control {
                resize: both;
                font-size: 11px;
                line-height: 17px;
                height: 100px;
                &#headOfIntroQuote,
                &#headOfExplanation {
                    height: 70px;
                }
            }
        }
    }
    .flyer-generator-container {
        .info {
            &.upload-info {
                font-size: 12px;
                line-height: 17px;
                letter-spacing: 0.5px;
            }
        }

    }
    .preview-wrapper {
        display: flex;
        position: fixed;
        top:0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.73);
        z-index: 2000;
        //overflow: scroll;
        backdrop-filter: blur(10px);
        .close {
            font-size: 36px;
            top: 160px;
            position: absolute;
            right: 50px;
            z-index: 2200;
            color: #fff;
            opacity: 1;
            font-weight: normal;
        }
        .flyer-generator-container {
            background-color: transparent;
        }
        #previewFlyer {
            margin-top: 140px;
            //overflow: scroll;
            width: 100%;
            .flyer-preview {
                display: flex;
                justify-content: center;
            }
            .flyer-preview {
                width: inherit;
                height: inherit;
                position: relative;
            }
        }
    }
    .flyer-generator-container{
        background-color: #ffffff;
        margin-bottom: 60px;
        padding: 15px 24px 60px 24px;
    }
    .btn-primary {
        &:focus {
            border-color: $color-green;
            color: #fff;
        }
    }
    .wizard {
        margin: 10px auto;
        margin-top: 0;
        .nav-tabs {
            position: relative;
            margin: 0 auto;
            margin-bottom: 60px;
            border-bottom: 0;
            width: 860px;
            >li>a.btn-primary {
                cursor: pointer;
            }
            >li.active {
                >a {
                    color: #fff;
                    border: 0;
                    border-bottom-color: transparent;
                    &:hover {
                        color: #fff;
                        border: 0;
                        border-bottom-color: transparent;
                    }
                    &:focus {
                        color: #fff;
                        border: 0;
                        border-bottom-color: transparent;
                    }
                }
            }
            >li {
                width: 16%;
                height: 100px;
                >a {
                    border: 0;
                    cursor: default;
                }
                a {
                    width: 40px;
                    height: 40px;
                    margin: 20px auto 14px;
                    border-radius: 100%;
                    padding: 0;
                    color: #fff;
                    z-index: 2;
                    &:hover {
                        background: transparent;
                    }
                }
            }
            .step-name {
                text-align: center;
                font-size: 15px;
                line-height: 22px;
                color: #4A4A4A;
                opacity: 0.5;
            }
            .sr-flyer-step {
                &.btn-primary {
                    + .step-name {
                        opacity: 1;
                    }
                }
            }
        }
        >div.wizard-inner {
            position: relative;
        }
        li {
            a.disabled {
                span.round-tab {
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    display: inline-block;
                    border-radius: 100px;
                    background: #fbfbfb;
                    border: 1px solid $color-green;
                    color: $color-green;
                    position: absolute;
                    left: 0;
                    text-align: center;
                    font-size: 15px;
                }
            }
        }
        .tab-pane {
            position: relative;
            padding-top: 50px;
        }
    }
    .connecting-line {
        height: 1px;
        background: $color-green;
        position: absolute;
        width: 690px;
        margin: 0 auto;
        left: 0;
        right: 0;
        top: 40%;
        z-index: 1;
    }
    span.round-tab {
        width: 40px;
        height: 40px;
        line-height: 40px;
        display: inline-block;
        border-radius: 100px;
        background: $color-green;
        border: 1px solid $color-green;
        text-align: center;
        font-size: 15px;
        i {
            color: #5bc0de;
        }
    }
    .uploadButton {
        width: 190px;
    }
    .file-upload-button {
        width: 220px;
    }
    .further-upload {
        padding: 9px 0 9px 30px;
    }
    .submit-btn {
        width: 190px;
        padding: 8px 20px;
    }
    .flyer-preview {
        height: 703px;
        width: 336px;
        .flyer-preview-bg-img {
            position:absolute;
            height: 703px;
            width: 336px;
            box-shadow: 0 0px 3px 2px rgba(0, 0, 0, 0.25);
            .background-image {
                width: 100%;
                height: 100%;
            }
            img {
                width: 100%;
                height: 100%;
            }
            .city-preview {
                color: #ffffff;
                top: 505px;
                position: absolute;
                width: 100%;
                padding: 0 10px;
                text-align: center;
                font-weight: 900;
                font-size: 25px;
                //text-shadow: 0 0 4px $color-text;
                line-height: 1;
            }
            .flyer-preview-date {
                color: #ffffff;
                margin-top: 10px;
                width: 100%;
                display: flex;
                justify-content: center;
                font-size: 16px;
                font-weight: 600;
                //text-shadow:0 0 4px $color-text;
            }

            .previewTeaserPhotoText {
                color: #ffffff;
                bottom: 50px;
                position: absolute;
                width: 100%;
                height: 80px;
                text-align: center;
                font-weight: 300;
                font-size: 13px;
                line-height: 16px;
                //text-shadow: 0 0 4px $color-text;
                padding: 0 50px;
                overflow: hidden;
                letter-spacing: 0.25px;
            }
            .previewTeaserPhotoText-fixed {
                color: #ffffff;
                bottom: 10px;
                position: absolute;
                width: 100%;
                height: 35px;
                text-align: center;
                font-weight: 300;
                font-size: 13px;
                line-height: 16px;
                //text-shadow: 0 0 4px $color-text;
                padding: 0 50px;
                letter-spacing: 0.25px;
            }
        }
        .logo-preview, .logo-fstate-wrapper {
            display: flex;
            position: relative;

            img {
                height: 50px;
                max-width: 50px;
                object-fit: contain;
            }
        }
        .logo-fstate-wrapper {
            left: 200px;
        }
    }

    // hide empty src images (chrome broken img icon)
    .user-logo-wrapper {
        position: absolute;
        bottom: 22px;
        width: 100%;
        left: 10px;
        img {
            &[src=""] {
                visibility: hidden;
            }
        }
    }
    .backsite-contact-logo-wrapper {
        img {
            &[src=""] {
                visibility: hidden;
            }
        }
    }


    .user-logos {
        display: flex;
    }

    #localLogosBacksite_upload {
        column-count: 2;
    }

    .cut-radio-wrapper {
        display: flex;
        justify-content: space-between;
    }
    // uc override
    &.uc {
        .form-group {
            input[type="radio"] ~ label::before {
                margin-right: 8px;
            }
        }
        #picture-privacy {
            min-height: unset;
        }
    }
    .form-data {
        width: 540px;
    }
    #stepOne {
        .w50 {
            width: 50%;
        }
        .flyer-generator-container {
            margin-bottom: 50px;
            padding-bottom: 150px;
        }
    }
    #stepTwo, #previewFlyer {
        .backsite-wrapper {
            position: absolute;
            top: 270px;
            left: 32px;
            .backsite-contact-logo-wrapper {
                position: absolute;
                width: 275px;
                height: 420px;
            }
        }
        .backsitePhotoSources {
            transform: rotate(-90deg) translate(100%, -100%);
            transform-origin: 100% 0;
            width: 230px;
            position: absolute;
            //right: -20px;
            left: 65px;
            bottom: 0;
            font-size: 5px;
            font-weight: 300;
            line-height: 1;
        }

        .form-city-wrapper {
            margin-left: 10%;
        }

        .background-image {
            display: flex;
            justify-content: center;

            img {
                width: 100%;
                height: 100%;
            }
        }
        .sr-url {
            margin-top: 50px;
            font-weight: 800;
            font-size: 24px;
        }
        .sr-diclaimer {
            display: flex;
            .disclaimer-text {
                font-size: 12px;
            }
            .kb-logo {
                margin-left: 20px;
                margin-right: 20px;
                margin-top: 40px;
                img {
                    width: 70%;
                    height: 70%;
                }
            }
        }
        .user-contact {
            font-weight: 300;
            font-size: 11px;
            min-height: 130px;

            .user-contact-city-wrapper,
            .user-contact-name-wrapper,
            .user-contact-town-wrapper,
            .user-contact-phone-wrapper,
            .user-contact-email-wrapper,
            .user-contact-website-wrapper
            {
                display: flex;
            }

            .contact {
                margin-bottom: 10px;
                font-weight: bold;
            }
        }
        .closing {
            font-size: 11px;
            margin-top: 10px;
            display: inline-block;
            font-weight: bold;
        }
        .regioPartnerHeading {
            font-size: 11px;
            display: flex;
            font-weight: bold;
            bottom: 75px;
            position: absolute;
        }
        .backsiteLocalLogos, .backsiteRegionalLogos {
            display: flex;
            height: 60px;
            margin-top:5px;
            .logo1 {
                left: 0px;
            }
            .logo2 {
                left: 72px;
            }
            .logo3 {
                left: 144px;
            }
            .logo4 {
                left: 215px;
            }
            .logo5 {
                left: 0px;
            }
            .logo6 {
                left: 72px;
            }
            .logo7 {
                left: 144px;
            }
            .logo8 {
                left: 215px;
            }
        }
        .backsiteRegionalLogos {
            display: flex;
        }
        .backsiteRegionalLogos-preview {
            bottom: 20px;
        }
        .backsiteLocalLogos-preview, .backsiteRegionalLogos-preview {
            display: flex;
            position: absolute;
            img {
                height: 60px;
                max-width: 60px;
                object-fit: contain;
            }
        }
    }
    #stepThree, #previewFlyer {
        .flyer-preview-bg-img.stepThree {
            padding: 50px 36px;
        }
        .min-width-greeting {
            min-width: 58.5%;
        }
        .portrait-photo-preview {
            width: 110px;
            img {
                max-width: 110px;
                max-height: 130px;
                width: auto;
                height: auto;
                /* workaround to hide outline of images that have no source (yet)
                display: none;
                &[src] {
                    display: block;
                }
                */
            }
        }
        .portrait-quote {
            color: #ffffff;
            margin-left: 10px;
            font-weight: 900;
            font-size: 18px;
            line-height: 23px;
            max-width: 143px;
            overflow-wrap: break-word;
            letter-spacing: 0.3px;
        }
        .introducing-text {
            margin-top: 25px;
            font-size: 11px;
            font-weight: 400;
            color: #FFFFFF;
        }
        .signature {
            display: flex;
            margin-top: 20px;
            /*justify-content: center;
            margin-top: 30px;*/
            img {
                width: 160px;
                max-height: 110px;
                max-width: 100%;
            }
        }
        .headOf-prename, .headOf-name {
            color: #fff;
            font-weight: 900;
            font-size: 16px;
        }
        .head-of-description {
            color: #fff;
            font-size: 10px;
        }
        .background-image {
            position: absolute;
            img {
                height: 703px;
                width: 336px;
            }
        }
    }
    #stepFour, #previewFlyer {
        font-size: 10px;
        .flyer-preview-bg-img.stepFour {
            padding: 50px 40px;
        }
        .ownFields-wrapper {
            height: 90%;
        }
        .ownTitel {
            font-weight: bold;
            font-size: 12px;
            margin-top: 10px;
        }
        .ownText {
            margin-bottom: 10px;
            font-size: 11px;
            line-height: 14px;
            font-weight: 300;
        }
        .background-image {
            position: absolute;
            width: 336px;
            img {
                width: 100%;
            }
        }
    }
    .toggle-text-pre-text {
        color: rgba(69, 69, 69, 0.8);
    }
    .switch {
        margin-bottom: 0;
    }
    #formStepFive, #stepThree {
        input[type="checkbox"] ~ span::before {
            content: '';
            border-radius: 50%!important;
            background-color: #fff;
            border: 1.5px solid #fff;
            margin-bottom: 1px;
        }
        input[type="checkbox"]:checked ~ span::before {
            background-color: #fff;
            border: 1.5px solid #fff;
            margin-bottom: 1px;
        }
    }

    #stepSix {
        padding-bottom: 20px;
    }
    #previewFlyer {
        .background-image {
            z-index: -1;
        }
        overflow-y: scroll;
        overflow-x: hidden;
        .flyer-preview-bg-img.stepFour {
            box-shadow: none;
        }
        .flyer-preview-bg-img.previewFlyer {
            width: 1008px;
            position: relative;
            display: flex;
            box-shadow: 0 0px 3px 2px rgba(0, 0, 0, 0.25);
            .pageOne, .pageTwo, .pageThree, .pageFour, .pageFive, .pageSix {
                width: 336px;
            }
            .pageThree {
                padding: 50px 36px;
                height: 100%;
            }
            .pageSix {
                height: 100%;
            }
            .pageTwo .backsiteLocalLogos {
                margin-top: 5px;
            }
            .pageFive .page-wrapper {
                width: 336px;
                height: 100%;
                position: absolute;
            }
        }
        .pageTwo {
            position: relative;
            .background-image {
                width: inherit;
                height: 100%;
            }
            /*
            .backsitePhotoSources-wrapper {
                .backsitePhotoSources {
                    right: -10px;
                    bottom: -15px;
                }
            }*/
        }
        .pageOne {
            .page-wrapper {
                position: absolute;
                height: 100%;
            }
        }
        .pageFour {
            position: relative;
            .background-image {
                height: 100%;
            }
        }
        .pageSix {
            .fixed-text-backsite {
                position: absolute;
                top: 400px;
                font-size: 21px;
                line-height: 24px;
                width: 328px;
                text-align: center;
                font-weight: bold;
                color: #fff;
                padding: 0 30px;
            }
        }
        .pagination {
            display: flex;
            justify-content: center;
            margin-top: 45px;
            font-size: 12px;
            line-height: 13px;
            letter-spacing: 0.2px;
            font-weight: 700;
            .page-item {
                padding: 10px 42px;
                border: 1px solid #fff;
                color: #fff;
                cursor: pointer;
                &.firstPage {
                    border-radius: 5px 0px 0px 5px;
                    border-right: none;
                }
                &.secondPage {
                    border-radius: 0px 5px 5px 0px;
                    border-left: none;
                }
                &.active {
                    background-color: $color-green;
                    border: none;
                    color: white;
                }
            }
        }
    }
    .content-spinner {
        z-index: 5000;
        width: 200px;
        margin: 0 auto;
        padding: 20px;
        img {
            margin-top: 25%;
            -webkit-animation: rotation 1s linear 0s infinite;
            -moz-animation: rotation 1s linear 0s infinite;
            -o-animation: rotation 1s linear 0s infinite;
            animation: rotation 1s linear 0s infinite;
        }
    }
    &.uc.modal-open {
        height: auto;
    }

    #greetingTextErrorMessage {
        background-color:red;
        color: #fff;
        margin-top: 20px;
        padding: 10px;
        font-weight: bold;
    }
}
