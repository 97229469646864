.account-close {
    .delete-account {
        padding-bottom: 50px;
        .form-control {
            border-width: 0 0 1px;
            color: #fff;
            padding: 0;
            height: 32px;
        }
        label {
            font-weight: 400;
            margin-top: 8px;
            color: rgba(255,255,255,0.6);
        }
        .btn-danger {
            background-color: #E30613;
        }
    }
    h3 {
        font-size: 16px;
        margin-bottom: 20px;
    }
    .info-delete {
        margin-bottom: 36px;
    }
    .username {
        font-size: 12px;
        line-height: 17px;
    }
    .password-row {
        margin-top: 20px;
        margin-bottom: 30px;
    }
    .dashboard {
        .delete-account {
            .btn.btn-default {
                border-color: rgba(255, 255, 255, 1);
                color: rgba(255, 255, 255, 1);
            }
        }
    }
    .without-activation {
        padding-left: 15px;
        padding-right: 15px;
    }
    button {
        a {
            color: #fff;
        }
    }
    input[type="checkbox"] + label::before, 
    input[type="checkbox"] + span::before {
        width: 16px;
        height: 16px;
        background-color: #FFFFFF;
        display: table;
        content:"";
        border-radius: 2px;
        border: 1.5px solid rgba(69,69,69, 0.38);
        font-size:13px;
        line-height: 0.8;
        border-collapse:separate;
        display: inline-block;
    }
    input[type="checkbox"]:checked + label::before, 
    input[type="checkbox"]:checked + span::before {
        font-family: 'sr-icons', 'FontAwesome';
        font-size:13px;
        background-color: #8cb82b;
        content: "\f00c";
        color: #FFFFFF;
        width: 16px;
        height: 16px;
        border: 1.5px solid #8cb82b;
        display: table;
        line-height: 0.8;
        display: inline-block;
    }
    @media screen and (min-width: 768px) and (max-width: 992px) {
        h1 {
            font-size: 24px;
            line-height: 30px;
        }
        h2 {
            font-size: 24px;
            line-height: 30px;
        }
        h3 {
            margin-bottom: 16px;
        }
        .username {
            font-size: 14px;
            line-height: 17px;
        }
        p {
            font-size: 14px;
        }
        .info-delete {
            margin-bottom: 40px;
        }
        .delete-account-description {
            max-width: 584px;
        }
        .without-activation {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    @media screen and (max-width:767px) {
        h1 {
            font-size: 24px;
            line-height: 30px;
        }
        h2 {
            font-size: 24px;
            line-height: 30px;
        }
        h3 {
            margin-bottom: 16px;
        }
        .delete-account {
            label {
                color: rgba(255,255,255,1);
            }
        }
        .btn {
            width: 100%;
        }
        .btn-danger {
            margin-top: 24px;
        }
        .username {
            font-size: 14px;
            line-height: 17px;
        }
        p {
            font-size: 14px;
        }
        .info-delete {
            margin-bottom: 40px;
        }
    }
    @media screen and (min-width:992px) {
        .without-activation {
            padding-left: 90px;
            width: calc(580px + 15vw);
        }
    }
}
