.teilnehmerkommunen{
    .status {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #052836;
        margin: 0 auto;
    }
    .status.status-green {
        background-color: #0086C5;
    }
    .status.status-yellow {
        background-color: #7DC6E8;
    }
    .status.status-orange {
        background-color: #FFFFFF;
    }
    .status.status-red {
        background-color: #21507B;
    }
    .status-small {
        &:before {
            content: "";
            display: block;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            float: left;
            margin: 3px 6px 0 0;
        }
    }
    .status-small.status-green {
        &:before {
            background-color: #0086C5;
        }
    }
    .status-small.status-yellow {
        &:before {
            background-color: #7DC6E8;
        }
    }
    .status-small.status-orange {
        &:before {
            background-color: #FFFFFF;
        }
    }
    .status-small.status-red {
        &:before {
            background-color: #21507B;
        }
    }
    .dataTables_filter {
        display: none;
    }

    /* NumberOfResults */
    .numberOfResults {
        >div {
            float: left;
            display: inline-table;
            margin-right: 10px;
            color: rgba(255, 255, 255, 0.5);
            cursor: pointer;
        }
        >span {
            float: left;
            display: inline-table;
            margin-right: 10px;
            color: rgba(255, 255, 255, 0.5);
        }
        margin-top: -120px;
        font-weight: bold;
        margin-bottom: 130px;
        >div.active {
            cursor: pointer;
            color: #0086C5;
        }
    }
    @media (max-width: 768px) {
        .numberOfResults {
            margin-top: -250px;
            font-weight: bold;
            margin-bottom: 130px;
        }
        .dataTables_empty {
            position: relative !important;
            white-space: unset !important;
        }
    }

    /* Pagination */
    .paginate_button.current {
        color: #0086C5 !important;
        font-weight: 600;
    }
    .paginate_button {
        font-size: 18px;
        position: relative;
    }
    .pagination-icon {
        height: 25px;
        fill: white;
        position: absolute;
        top: 8px;
        left: 0;
    }
    @media (max-width: 479px) {
        .paginate_button.first {
            display: inline;
        }
        .paginate_button.previous {
            display: inline;
        }
        .paginate_button.next {
            display: inline;
        }
        .paginate_button.last {
            display: inline;
        }
        .paginate_button {
            font-size: 18px;
        }
    }
    /* End Pagination */

    table.dataTable {
        thead {
            >tr {
                >th.sorting_asc {
                    padding-right: 15px;
                }
                >th.sorting_desc {
                    padding-right: 15px;
                }
                >th.sorting {
                    padding-right: 15px;
                }
                >td.sorting_asc {
                    padding-right: 15px;
                }
                >td.sorting_desc {
                    padding-right: 15px;
                }
                >td.sorting {
                    padding-right: 15px;
                }
            }
        }
    }
    #tableSortXs {
        .btn {
            padding-left: inherit;
            padding-right: inherit;
            padding-top: 4px;
            padding-bottom: 4px;
            font-size: 11px;
        }
    }

}
