// 2-Factor Authentication page after login with 2-FA enabled, by mail or app
.page-twofactor {
    #sr-login {
        p {
            font-weight: 300;
            a {
                font-weight: 500;
            }
        }
        p.sr_error {
            font-weight: 700;
        }
    }
}