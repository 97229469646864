/* global helper classes */

/* helper start */
// margin and padding values array
$space-values : (
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    32,
    35,
    40,
    45,
    48,
    50,
    55,
    60,
    64,
    65,
    70,
    75,
    80,
    85,
    90,
    95,
    100,
    120,
    125,
    150,
    160,
    175,
    200
) !default;
// margin and padding shorthands
$space-prefixes : (
    p  : padding,
    pt : padding-top,
    pr : padding-right,
    pb : padding-bottom,
    pl : padding-left,
    m  : margin,
    mt : margin-top,
    mr : margin-right,
    mb : margin-bottom,
    ml : margin-left,
) !default;
@mixin spacing-generator() {
    @each $attr-short, $attr-long in $space-prefixes { 
        @each $value in $space-values {
            .#{$attr-short}-#{$value} {
                #{$attr-long}: #{$value}#{'px'}!important;
            }
        }
    }
}
@include spacing-generator();

.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.py-1, .py-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}
.py-2, .py-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}
.py-3, .py-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
}
.py-4, .py-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
}
.py-5, .py-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
}
.py-48 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
}
.py-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
}
.py-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
}

.px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.px-1, .px-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
}
.px-2, .px-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
}
.px-3, .px-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
}
.px-4, .px-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
}
.px-5, .px-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
}

.px-8 {
    padding-left: 8px !important;
    padding-right: 8px !important;
}
.px-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}
.my-1, .my-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}
.my-2, .my-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}
.my-3, .my-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
}
.my-4, .my-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
}
.my-5, .my-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
}
.my-48 {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
}
.my-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
}
.my-120 {
    margin-top: 120px !important;
    margin-bottom: 120px !important;
}

.mx-0 {
    margin-left: 0mx !important;
    margin-right: 0mx !important;
}
.mx-1, .mx-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
}
.mx-2, .mx-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
}
.mx-3, .mx-30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
}
.mx-4, .mx-40 {
    margin-left: 40px !important;
    margin-right: 40px !important;
}
.mx-5, .mx-50 {
    margin-left: 50px !important;
    margin-right: 50px !important;
}

.mx-xs-minus-15 {
    margin-left: -15px!important;
    margin-right: -15px!important;
}

.w-auto {
    width: auto;
}

.w-fit-content {
    width: fit-content;
}

// width/height
$width-height-values : (
    5,
    10,
    15,
    20,
    25,
    30,
    33,
    40,
    45,
    50,
    60,
    63,
    65,
    66,
    70,
    75,
    80,
    90,
    95,
    100
) !default;
// margin and padding shorthands
$width-height-prefixes : (
    w  : width,
    h  : height,
) !default;
@mixin width-height-generator() {
    @each $attr-short, $attr-long in $width-height-prefixes { 
        @each $value in $width-height-values {
            .#{$attr-short}-#{$value} {
                #{$attr-long}: #{$value}#{'%'}!important;
            }
        }
    }
}
@include width-height-generator();

.lh-10 {
    line-height: 10px!important;
}
.lh-18 {
    line-height: 18px!important;
}
.lh-20 {
    line-height: 20px!important;
}
.lh-30 {
    line-height: 30px!important;
}
.lh-40 {
    line-height: 40px!important;
}
.lh-50 {
    line-height: 50px!important;
}

.fs-13 {
    font-size: 13px!important;
}
.fs-14 {
    font-size: 14px!important;
}
.fs-15 {
    font-size: 15px!important;
}
.fs-16 {
    font-size: 16px!important;
}
.fs-18 {
    font-size: 18px!important;
}
.fs-20 {
    font-size: 20px!important;
}
.fs-25 {
    font-size: 25px!important;
}
.fs-30 {
    font-size: 30px!important;
}
.fs-34 {
    font-size: 34px!important;
}
.fs-40 {
    font-size: 40px!important;
}
.fs-48 {
    font-size: 48px!important;
}
.fs-50 {
    font-size: 50px!important;
}

.ls-0 {
    letter-spacing: 0;
}
.ls-22 {
    letter-spacing: 0.22px;
}
.ls-30 {
    letter-spacing: 0.3px;
}
.ls-30 {
    letter-spacing: 0.33px;
}
.ls-55 {
    letter-spacing: 0.55px;
}
.ls-60 {
    letter-spacing: 0.6px;
}

.fw-light {
    font-weight: 300!important;
}
.fw-regular,
.fw-normal {
    font-weight: 400!important;
}
.fw-medium {
    font-weight: 500!important;
}
.fw-bold {
    font-weight: 600!important;
}

.color-text {
    color: $text;
}
.color-link,
.color-blue {
    color: $color-blue;
}
.color-green {
    color: $color-green;
}

.d-none {
    display: none;
}
.d-block {
    display: block;
}
.d-inline-block {
    display: inline-block;
}
.d-inline {
    display: inline;
}
.d-flex {
    display: flex;
}
.d-inherit {
    display: inherit;
}
.d-initial {
    display: initial;
}

.position-relative {
    position: relative;
}
.position-inherit {
    position: inherit;
}
.position-initial {
    position: initial;
}
.position-absolute {
    position: absolute;
}
.position-fixed {
    position: fixed;
}
.position-sticky {
    position: sticky;
}

.flex-direction-column, 
.flex-direction-col {
    flex-direction: column;
}
.flex-direction-row {
    flex-direction: row;
}

.flex-grow {
    flex-grow: 1;
}
.flex-shrink {
    flex-shrink: 1;
}

.justify-content-start {
    justify-content: start;
}
.justify-content-center {
    justify-content: center;
}
.justify-content-end {
    justify-content: end;
}
.justify-content-between {
    justify-content: space-between;
}
.justify-content-around {
    justify-content: space-around;
}

.align-items-start {
    align-items: flex-start;
}
.align-items-center {
    align-items: center;
}
.align-items-end {
    align-items: flex-end;
}
.align-items-baseline {
    align-items: baseline;
}
.align-items-stretch {
    align-items: stretch;
}

.vertical-align-top {
    vertical-align: top;
}
.vertical-align-middle {
    vertical-align: middle;
}
.vertical-align-bottom {
    vertical-align: bottom;
}

.overflow-auto {
    overflow: auto!important;
}
.overflow-x-scroll {
    overflow-x: scroll;
}
.overflow-visible {
    overflow: visible!important;
}
.overflow-hidden {
    overflow: hidden!important;
}
.overflow-x-auto {
    overflow-x: auto;
}
.overflow-x-visible {
    overflow-x: visible;
}
.overflow-x-hidden {
    overflow-x: hidden;
}
.overflow-y-auto {
    overflow-y: auto;
}
.overflow-y-scroll {
    overflow-y: scroll;
}
.overflow-y-visible {
    overflow-y: visible;
}
.overflow-y-hidden {
    overflow-y: hidden;
}

.cut-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.visibility-hidden {
    visibility: hidden;
}
.visibility-visible {
    visibility: visible;
}

.text-align-left, .text-left {
    text-align: left;
}
.text-align-center, .text-center {
    text-align: center;
}
.text-align-right, .text-right {
    text-align: right;
}

.text-decoration-none {
    text-decoration: none;
}
.text-decoration-underline {
    text-decoration: underline;
}

.z-index-0 {
    z-index: 0;
}
.z-index-1 {
    z-index: 1;
}
.z-index-2 {
    z-index: 2;
}
.z-index-3 {
    z-index: 3;
}
.z-index-10 {
    z-index: 10;
}
.z-index-999 {
    z-index: 999;
}
.z-index-9999 {
    z-index: 9999;
}

.white-space-normal {
    white-space: normal;
}
.white-space-nowrap {
    white-space: nowrap;
}

.rotate-90 {
    transform: rotate(90deg);
}
.rotate-180 {
    transform: rotate(180deg);
}
.rotate-270 {
    transform: rotate(270deg);
}


// negatives
$space-prefixes-negative : (
    mt : margin-top,
    mr : margin-right,
    mb : margin-bottom,
    ml : margin-left,
    t  : top,
    r  : right,
    b  : bottom,
    l  : left
) !default;
@mixin spacing-generator-negative() {
    @each $attr-short, $attr-long in $space-prefixes-negative { 
        @each $value in $space-values {
            .#{$attr-short}-minus-#{$value} {
                #{$attr-long}: -#{$value}#{'px'}!important;
            }
        }
    }
}
@include spacing-generator-negative();

// helper desktop only -lg
@media (min-width: 992px) {
    @mixin spacing-generator-desktop() {
        @each $attr-short, $attr-long in $space-prefixes { 
            @each $value in $space-values {
                .#{$attr-short}-lg-#{$value} {
                    #{$attr-long}: #{$value}#{'px'}!important;
                }
            }
        }
    }
    @include spacing-generator-desktop();
}

// helper tablet only -sm
@media (min-width: 768px) and (max-width: 992px) {
    @mixin spacing-generator-tablet() {
        @each $attr-short, $attr-long in $space-prefixes { 
            @each $value in $space-values {
                .#{$attr-short}-sm-#{$value} {
                    #{$attr-long}: #{$value}#{'px'}!important;
                }
            }
        }
    }
    @include spacing-generator-tablet();

    @mixin spacing-generator-tablet-negative() {
        @each $attr-short, $attr-long in $space-prefixes-negative { 
            @each $value in $space-values {
                .#{$attr-short}-sm-minus-#{$value} {
                    #{$attr-long}: -#{$value}#{'px'}!important;
                }
            }
        }
    }
    @include spacing-generator-tablet-negative();

    .py-sm-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    .px-sm-0 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .pr-sm-0 {
        padding-right: 0;
    }
    .pl-sm-0 {
        padding-left: 0;
    }
    .mx-sm-minus-15 {
        margin-left: -15px!important;
        margin-right: -15px!important;
    }
}

// helper mobile only -xs
@media (max-width: 767px) {
    @mixin spacing-generator-mobile() {
        @each $attr-short, $attr-long in $space-prefixes { 
            @each $value in $space-values {
                .#{$attr-short}-xs-#{$value} {
                    #{$attr-long}: #{$value}#{'px'}!important;
                }
            }
        }
    }
    @include spacing-generator-mobile();

    // negatives
    @mixin spacing-generator-mobile-negative() {
        @each $attr-short, $attr-long in $space-prefixes-negative { 
            @each $value in $space-values {
                .#{$attr-short}-xs-minus-#{$value} {
                    #{$attr-long}: -#{$value}#{'px'}!important;
                }
            }
        }
    }
    @include spacing-generator-mobile-negative();

    @mixin width-height-generator-mobile() {
        @each $attr-short, $attr-long in $width-height-prefixes { 
            @each $value in $width-height-values {
                .#{$attr-short}-xs-#{$value} {
                    #{$attr-long}: #{$value}#{'%'}!important;
                }
            }
        }
    }
    @include width-height-generator-mobile();

    .overflow-xs-visible {
        overflow: visible!important;
    }
    .overflow-xs-hidden {
        overflow: hidden!important;
    }

    .py-xs-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    .px-xs-0 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .px-xs-15 {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .px-xs-30 {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }

    .mx-xs-minus-15 {
        margin-left: -15px!important;
        margin-right: -15px!important;
    }

    .w-xs-100 {
        width: 100% !important;
    }
    .h-xs-100 {
        height: 100%;
    }
    .w-xs-75 {
        width: 75%;
    }
    .h-xs-75 {
        height: 75%;
    }
    .w-xs-50 {
        width: 50%;
    }
    .h-xs-50 {
        height: 50%;
    }
    .w-xs-33 {
        width: 33%;
    }
    .h-xs-33 {
        height: 33%;
    }
    .w-xs-25 {
        width: 25%;
    }
    .h-xs-25 {
        height: 25%;
    }
    .w-xs-20 {
        width: 20%;
    }
    .h-xs-20 {
        height: 20%;
    }
    .w-xs-10 {
        width: 10%;
    }
    .h-xs-10 {
        height: 10%;
    }
    .text-xs-left {
        text-align: left;
    }
    .text-xs-center {
        text-align: center;
    }
    .text-xs-right {
        text-align: right;
    }

    .cut-text-mobile {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

/* helper end */

.bg-white {
    background:#FFF;
}
.bg-gray {
    background: $bg-gray;
}
.bg-blue {
    background: $bg-blue;
}