@media (max-width: 991px) {
	.offcanvas-stop-scrolling {
		height: 100%;
		overflow: hidden;
	}
	.navbar-default {
		.navbar-offcanvas {
			background-color: #fff;
		}
	}
	.navbar-inverse {
		.navbar-offcanvas {
			background-color: #222;
		}
	}
	.navbar-offcanvas {
		position: fixed;
		width: 100%;
		height: 100%;
		max-width: 100%;
		left: -100%;
		top: 0;
		padding-left: 0;
		padding-right: 0;
		z-index: 999;
		overflow: scroll;
		-webkit-overflow-scrolling: touch;
		transition: all 0.15s cubic-bezier(0.7, 0, 0.3, 1);
		.dropdown.open {
			.caret {
				border-top: 0;
				border-bottom: 4px solid;
			}
		}
		.dropdown-menu {
			position: relative;
			width: 100%;
			border: inherit;
			box-shadow: none;
			transition: height 0.15s cubic-bezier(0.7, 0, 0.3, 1);
		}
		.dropdown-menu.shown {
			display: block;
			margin-bottom: 10px;
		}
	}
	.navbar-offcanvas.in {
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
	}
	.navbar-offcanvas.navbar-offcanvas-fade {
		opacity: 0;
	}
	.navbar-offcanvas.navbar-offcanvas-fade.in {
		opacity: 1;
	}
	.navbar-offcanvas.offcanvas-transform.in {
		transform: translateX(100%);
	}
	.navbar-offcanvas.offcanvas-position.in {
		left: 0;
	}
	.navbar-offcanvas.navbar-offcanvas-right {
		left: auto;
		right: -100%;
	}
	.navbar-offcanvas.navbar-offcanvas-right.offcanvas-transform.in {
		transform: translateX(-100%);
	}
	.navbar-offcanvas.navbar-offcanvas-right.offcanvas-position.in {
		left: auto;
		right: 0;
	}
}
