.uc {
    // popover
	.popover {
		padding: 8px 18px 12px 18px;
		position: absolute;
		z-index: 9999;
		max-width: 220px;
		border: 0px;
		border-radius: 0;
        background-color: #ffffff;
        &.top {
            margin-top: -12px;
            .arrow {
                position: inherit;
                bottom: -10px;
                left: 50%;
                margin-left: -10px;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-top: 12px solid #fff;
                &::after {
                    border-top-color: #ffffff;
                }
            }
        }
        &.bottom {
            margin-top: 10px;
            .arrow {
                position: inherit;
                top: -10px;
                bottom: unset;
                left: 50%;
                margin-left: -10px;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 12px solid #fff;
                &::after {
                    border-top-color: #ffffff;
                }
            }
        }
        .arrow {
            border-color: #fff;
        }
        .popover-content {
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.47px;
            font-weight: 300;
            color: #000;
            text-align: center;
        }
	}
	
    // light background specific (typo3 sites)
	&.light-bg {
		// popover
		.popover {
			background-color: #ffffff;
            &.top {
                .arrow {
                    border-top: 12px solid #fff;
                }
            }
            &.bottom {
                .arrow {
                    border-bottom: 12px solid #fff;
                }
            }
            .popover-content {
                color: #000;
            }
		}
	} // .light-bg end

    // dark background specific (ORK sites)
    // popover
    .popover.dark-bg {
        background-color: #052836;
        &.top {
            .arrow {
                border-top: 12px solid #052836;
            }
        }
        &.bottom {
            .arrow {
                border-bottom: 12px solid #052836;
            }
        }
        .popover-content {
            color: #fff;
        }
    }
} // .uc end