#c34349 {
	.dataTables_filter {
		display: none;
	}
}

#c45514 {
	background: #f8f8f8 !important;
	padding: 10px 20px 0 20px;
	margin-top: -10px;
	h2 {
		opacity: 0.85;
	}
	p {
		font-size: 115%;
		opacity: 0.85;
	}
	li {
		padding-left: 30px;
		font-size: 115%;
		opacity: 0.85;
	}
	li.quicklink {
		margin-left: -70px;
	}
}
#c50433 {
	background: #f8f8f8 !important;
	padding: 10px 20px 0 20px;
	margin-top: -10px;
	h2 {
		opacity: 0.85;
	}
	p {
		font-size: 115%;
		opacity: 0.85;
	}
	li {
		padding-left: 30px;
		font-size: 115%;
		opacity: 0.85;
	}
	li.quicklink {
		margin-left: -70px;
	}
}
#c50434 {
	background: #f8f8f8 !important;
	padding: 10px 20px 0 20px;
	margin-top: -10px;
	h2 {
		opacity: 0.85;
	}
	p {
		font-size: 115%;
		opacity: 0.85;
	}
	li {
		padding-left: 30px;
		font-size: 115%;
		opacity: 0.85;
	}
	li.quicklink {
		margin-left: -70px;
	}
}
#c50435 {
	background: #f8f8f8 !important;
	padding: 10px 20px 0 20px;
	margin-top: -10px;
	h2 {
		opacity: 0.85;
	}
	p {
		font-size: 115%;
		opacity: 0.85;
	}
	li {
		padding-left: 30px;
		font-size: 115%;
		opacity: 0.85;
	}
	li.quicklink {
		margin-left: -70px;
	}
}
#c50436 {
	background: #f8f8f8 !important;
	padding: 10px 20px 0 20px;
	margin-top: -10px;
	h2 {
		opacity: 0.85;
	}
	p {
		font-size: 115%;
		opacity: 0.85;
	}
	li {
		padding-left: 30px;
		font-size: 115%;
		opacity: 0.85;
	}
	li.quicklink {
		margin-left: -70px;
	}
}
#c50437 {
	background: #f8f8f8 !important;
	padding: 10px 20px 0 20px;
	margin-top: -10px;
	h2 {
		opacity: 0.85;
	}
	p {
		font-size: 115%;
		opacity: 0.85;
	}
	li {
		padding-left: 30px;
		font-size: 115%;
		opacity: 0.85;
	}
	li.quicklink {
		margin-left: -70px;
	}
}
#c50438 {
	background: #f8f8f8 !important;
	padding: 10px 20px 0 20px;
	margin-top: -10px;
	h2 {
		opacity: 0.85;
	}
	p {
		font-size: 115%;
		opacity: 0.85;
	}
	li {
		padding-left: 30px;
		font-size: 115%;
		opacity: 0.85;
	}
	li.quicklink {
		margin-left: -70px;
	}
}
#c50439 {
	background: #f8f8f8 !important;
	padding: 10px 20px 0 20px;
	margin-top: -10px;
	h2 {
		opacity: 0.85;
	}
	p {
		font-size: 115%;
		opacity: 0.85;
	}
	li {
		padding-left: 30px;
		font-size: 115%;
		opacity: 0.85;
	}
	li.quicklink {
		margin-left: -70px;
	}
}
#c50440 {
	background: #f8f8f8 !important;
	padding: 10px 20px 0 20px;
	margin-top: -10px;
	h2 {
		opacity: 0.85;
	}
	p {
		font-size: 115%;
		opacity: 0.85;
	}
	li {
		padding-left: 30px;
		font-size: 115%;
		opacity: 0.85;
	}
	li.quicklink {
		margin-left: -70px;
	}
}
#c50441 {
	background: #f8f8f8 !important;
	padding: 10px 20px 0 20px;
	margin-top: -10px;
	h2 {
		opacity: 0.85;
	}
	p {
		font-size: 115%;
		opacity: 0.85;
	}
	li {
		padding-left: 30px;
		font-size: 115%;
		opacity: 0.85;
	}
	li.quicklink {
		margin-left: -70px;
	}
}
#c50442 {
	background: #f8f8f8 !important;
	padding: 10px 20px 0 20px;
	margin-top: -10px;
	h2 {
		opacity: 0.85;
	}
	p {
		font-size: 115%;
		opacity: 0.85;
	}
	li {
		padding-left: 30px;
		font-size: 115%;
		opacity: 0.85;
	}
	li.quicklink {
		margin-left: -70px;
	}
}