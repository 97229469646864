// redesign 2024 rules outside of components

body {
    color: $blue98;
}
.bg-dark {
    background-color: $blue98;
    color: $sr-white;
}
/*
p {
    color: $blue98;
}
a {
    color: $sr-blue;
    text-decoration: underline;
    &:hover,
    &:active,
    &.active
    &:focus,
    &.focus {
        color: $blue40;
        //text-decoration: underline; conflict
    }
    &.secondary {
        color: $blue98;
        &:hover,
        &:active,
        &.active
        &:focus,
        &.focus {
            color: $blue80;
            text-decoration: underline;
        }
    }
}
.bg-dark {
    p {
        color: $sr-white;
    }
    a {
        color: $blue40;
        &:hover,
        &:active,
        &.active
        &:focus,
        &.focus {
            color: $blue20;
            text-decoration: underline;
        }
        &.secondary {
            color: $sr-white;
        }
    }
}
.dropdown-menu > li > a {
    color: $grey60;
    &:hover,
    &:active,
    &:focus {
        color: $grey40;
    }
}
*/