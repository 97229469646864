.confirm-dashboard.full-height {
    display: flex;
    justify-content: flex-start;
    background-color: #063547;
    color: #fff;

    main {
        padding: 20px 0;
    }
}

.confirm-team-member {
    background-color: #063548;
    padding-left: 24px;
    padding-right: 24px;

    .icon {
        display:flex;
        align-items:center;
    }
    .confirm-header {
        display:flex;
        flex-direction: column;
        margin-bottom: 10px;

        .dropdown-wrapper {
            .dropdown {
                button {
                    position:relative;
                }
                li a {
                    margin-top: 0px;
                    margin-bottom: 0px;
                }
            }
        }


        a {
            display:flex;
            justify-content: center;
            margin-bottom: 8px;
        }

        h1 {
            font-size: 24px;
        }

        button {
            padding: 9px 38px;
            background-color: #0E4761;
            color: #fff;
            font-size: 12px;
            line-height: 14px;
            border-radius: 4px;
            font-weight: 500;
            width:100%;
        }
    }

    h1 {
        color: #fff;
    }

    .confirm-message {
        width:100%;
        padding-left: 27px;
        background-color: #fff;
        border-radius: 4px;
        color: #454545;
        display: flex;
        justify-content: left;
        min-height:58px;

        .success-icon:before {
            background-image: url("data:image/svg+xml,%3Csvg height='25' viewBox='0 0 25 25' width='25' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle cx='12.5' cy='12.5' fill='%238cb82b' r='12.5'/%3E%3Cg stroke='%23fff' stroke-width='.966184'%3E%3Cpath d='m8.871638 11.948397h1v5.841597h-1z' transform='matrix(.70710678 -.70710678 .70710678 .70710678 -7.774172 10.969879)'/%3E%3Cpath d='m9.293908 12.800985h10.628019v1h-10.628019z' transform='matrix(.70710678 -.70710678 -.70710678 -.70710678 13.676315 33.017546)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        }

        .success-icon {
            margin-bottom: 15px;
        }

        .text-confirm-message {
            margin: auto 20px;
            font-size: 14px;
            line-height: 19px;
            padding:15px 0;
        }
    }

    .confirm-content {
        display: flex;
        justify-content: left;
        flex-wrap: wrap;
        //max-height: 350px;
        .list-group {
            .name {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 175px;
                display: block;
            }
        }
    }

    .commune-overview {
        margin-top: 25px;
        padding-left: 0;
        padding-right: 0;

        h3 {
            font-size: 19px;
            margin-bottom: 24px;
            a {
                color: #fff;
            }
        }

        .list-group-item {
            .badge {
                position:absolute;
                top:0;
                right:0;
                padding: 6px 9px;
                border-radius: 50% 0px 50% 50%;
                height:25px;
                width:25px;
            }

            border: 2px solid #063547;
        }

        .list-group-item:first-child {
            .badge {
                border-radius: 50% 7px 50% 50%;
            }
        }

        .icon-arrow-external::before {
            font-size: 16px
        }

        .commune-news {
            height:100%;
            overflow: hidden;
            border-radius: 7px;
        }

        .commune-overview-news-article:hover {
            overflow-y: auto;
        }

        .commune-overview-news-article {
            background-color: #073B50;
            border-radius: 7px;
            height: 100%;
            overflow-x: hidden;
            overflow-y: hidden;
            //scrollbar-color: #6e6e6e #073b50;

            .news-article {
                padding:18px;

                .news-article-date {
                    font-weight: lighter;
                    font-size: 12px;
                    color: #AAD0D2;
                    opacity: 0.7;
                    line-height: 17px;
                    margin-bottom: 10px;
                    letter-spacing: 0.28px;
                }

                .news-article-title {
                    font-weight: normal;
                    font-size: 14px;
                    color: #ffffff;
                    opacity: 0.5;
                    line-height: 17px;
                    margin-bottom:15px;
                    letter-spacing: 0.55px;
                }

                .news-article-subtitle {
                    font-weight: lighter;
                    font-size: 14px;
                    color: #ffffff;
                    line-height: 20px;
                    letter-spacing: 0.33px;
                }
            }
        }
    }

    .commune-overview.news {
        padding-bottom: 25px;
    }

}

@media (max-width: 767px) {
    .confirm-dashboard.full-height {
        padding: 15px 0 70px 0;
    }
    .icon {
        svg {
            width:35px;
            height:35px;
        }
    }
    .confirm-team-member {
        .confirm-header {
            .dropdown-wrapper {
                margin-bottom:10px;
            }
        }
        .list-group {
            .name {
                width: 150px;
            }
        }
        .list-group-item {
            .km-count {
                position: absolute;
                right: 30px;
                bottom: 15px;
                font-size: 18px;
                small {
                    font-size:75%;
                }
            }
        }
    }
}

@media (min-width: 768px) {
    .confirm-dashboard.full-height {
        padding: 15px 0 70px 0;

        .confirm-header {
            .dropdown-wrapper {
                margin-top:20px;
                display: flex;
                justify-content: center;

                .dropdown {
                    text-align:center;
                    li {
                        width: 296px;
                    }
                }

                h1 {
                    font-size: 24px;
                    margin-top:14px;
                }
                button {
                    width: 296px;
                }
            }

        }
        .confirm-team-member {
            padding-left: 24px;
            padding-right: 24px;

            .commune-overview {
                &.news {
                    margin-left:80px;
                }

                .commune-overview-news-article {
                    .news-article {
                        .news-article-title {
                            font-size: 14px;
                        }
                        .news-article-subtitle {
                            font-size: 14px;
                            line-height: 20px;
                        }
                    }
                }
            }


            .text-confirm-message {
                font-size: 14px;
                line-height: 17px;
            }
        }
        .confirm-content {
            justify-content: space-between;
            flex-wrap: nowrap;
            max-height:350px;
            .list-group {
                .name {
                    width: 250px;
                }
            }
            .list-group-item {
                .km-count {
                    bottom: 10px;
                }
            }
        }
    }
}

@media (min-width: 992px) {
    .confirm-dashboard.full-height {
        padding: initial;

        .confirm-header {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            margin-bottom: 24px;
            margin-top:50px;

            .dropdown-wrapper {
                .dropdown {
                    ul {
                        margin-top: -17px;
                    }
                }
            }

            a {
                margin-top: 20px;
                margin-bottom: 10px;
            }

            h1 {
                font-size: 32px;
            }
        }
        .confirm-team-member {
            .commune-overview {
                .list-group-item {
                    .km-count {
                        position: absolute;
                        right: 40px;
                        bottom: 18px;
                        font-size: 16px;
                    }
                }

                .commune-overview-news-article {
                    .news-article {
                        .news-article-title {
                            font-size: 12px;
                        }
                        .news-article-subtitle {
                            font-size: 12px;
                            line-height: 17px;
                        }
                    }
                }
            }
        }


        .confirm-content {
            margin-bottom:50px;
        }


    }
}

@media (min-width: 1440px) {
    .confirm-dashboard.full-height {
        .confirm-team-member {
            padding-left: 88px;
            padding-right: 204px;
        }
    }
}
