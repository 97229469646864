.panel {
	margin-bottom: 20px;
	border: 1px solid transparent;
	border-radius: 4px;
	-webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
	>.list-group {
		margin-bottom: 0;
		.list-group-item {
			border-width: 1px 0;
			border-radius: 0;
		}
		&:first-child {
			.list-group-item {
				&:first-child {
					border-top: 0;
					border-top-right-radius: -6px;
					border-top-left-radius: -6px;
				}
			}
		}
		&:last-child {
			.list-group-item {
				&:last-child {
					border-bottom: 0;
					border-bottom-right-radius: -6px;
					border-bottom-left-radius: -6px;
				}
			}
		}
	}
	>.panel-collapse {
		>.list-group {
			margin-bottom: 0;
			.list-group-item {
				border-width: 1px 0;
				border-radius: 0;
			}
			&:first-child {
				.list-group-item {
					&:first-child {
						border-top: 0;
						border-top-right-radius: -6px;
						border-top-left-radius: -6px;
					}
				}
			}
			&:last-child {
				.list-group-item {
					&:last-child {
						border-bottom: 0;
						border-bottom-right-radius: -6px;
						border-bottom-left-radius: -6px;
					}
				}
			}
		}
		>.table {
			margin-bottom: 0;
			caption {
				padding-left: 15px;
				padding-right: 15px;
			}
		}
	}
	>.panel-heading {
		+ {
			.panel-collapse {
				>.list-group {
					.list-group-item {
						&:first-child {
							border-top-right-radius: 0;
							border-top-left-radius: 0;
						}
					}
				}
			}
		}
	}
	>.table {
		margin-bottom: 0;
		caption {
			padding-left: 15px;
			padding-right: 15px;
		}
		&:first-child {
			border-top-right-radius: -6px;
			border-top-left-radius: -6px;
			>thead {
				&:first-child {
					>tr {
						&:first-child {
							border-top-left-radius: -6px;
							border-top-right-radius: -6px;
							td {
								&:first-child {
									border-top-left-radius: -6px;
								}
								&:last-child {
									border-top-right-radius: -6px;
								}
							}
							th {
								&:first-child {
									border-top-left-radius: -6px;
								}
								&:last-child {
									border-top-right-radius: -6px;
								}
							}
						}
					}
				}
			}
			>tbody {
				&:first-child {
					>tr {
						&:first-child {
							border-top-left-radius: -6px;
							border-top-right-radius: -6px;
							td {
								&:first-child {
									border-top-left-radius: -6px;
								}
								&:last-child {
									border-top-right-radius: -6px;
								}
							}
							th {
								&:first-child {
									border-top-left-radius: -6px;
								}
								&:last-child {
									border-top-right-radius: -6px;
								}
							}
						}
					}
				}
			}
		}
		&:last-child {
			border-bottom-right-radius: -6px;
			border-bottom-left-radius: -6px;
			>tbody {
				&:last-child {
					>tr {
						&:last-child {
							border-bottom-left-radius: -6px;
							border-bottom-right-radius: -6px;
							td {
								&:first-child {
									border-bottom-left-radius: -6px;
								}
								&:last-child {
									border-bottom-right-radius: -6px;
								}
							}
							th {
								&:first-child {
									border-bottom-left-radius: -6px;
								}
								&:last-child {
									border-bottom-right-radius: -6px;
								}
							}
						}
					}
				}
			}
			>tfoot {
				&:last-child {
					>tr {
						&:last-child {
							border-bottom-left-radius: -6px;
							border-bottom-right-radius: -6px;
							td {
								&:first-child {
									border-bottom-left-radius: -6px;
								}
								&:last-child {
									border-bottom-right-radius: -6px;
								}
							}
							th {
								&:first-child {
									border-bottom-left-radius: -6px;
								}
								&:last-child {
									border-bottom-right-radius: -6px;
								}
							}
						}
					}
				}
			}
		}
		+ {
			.panel-body {
				border-top: 1px solid #021B26;
			}
		}
		>tbody {
			&:first-child {
				>tr {
					&:first-child {
						th {
							border-top: 0;
						}
						td {
							border-top: 0;
						}
					}
				}
			}
		}
	}
	>.table-responsive {
		>.table {
			margin-bottom: 0;
			caption {
				padding-left: 15px;
				padding-right: 15px;
			}
		}
		&:first-child {
			>.table {
				&:first-child {
					border-top-right-radius: -6px;
					border-top-left-radius: -6px;
					>thead {
						&:first-child {
							>tr {
								&:first-child {
									border-top-left-radius: -6px;
									border-top-right-radius: -6px;
									td {
										&:first-child {
											border-top-left-radius: -6px;
										}
										&:last-child {
											border-top-right-radius: -6px;
										}
									}
									th {
										&:first-child {
											border-top-left-radius: -6px;
										}
										&:last-child {
											border-top-right-radius: -6px;
										}
									}
								}
							}
						}
					}
					>tbody {
						&:first-child {
							>tr {
								&:first-child {
									border-top-left-radius: -6px;
									border-top-right-radius: -6px;
									td {
										&:first-child {
											border-top-left-radius: -6px;
										}
										&:last-child {
											border-top-right-radius: -6px;
										}
									}
									th {
										&:first-child {
											border-top-left-radius: -6px;
										}
										&:last-child {
											border-top-right-radius: -6px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
		&:last-child {
			>.table {
				&:last-child {
					border-bottom-right-radius: -6px;
					border-bottom-left-radius: -6px;
					>tbody {
						&:last-child {
							>tr {
								&:last-child {
									border-bottom-left-radius: -6px;
									border-bottom-right-radius: -6px;
									td {
										&:first-child {
											border-bottom-left-radius: -6px;
										}
										&:last-child {
											border-bottom-right-radius: -6px;
										}
									}
									th {
										&:first-child {
											border-bottom-left-radius: -6px;
										}
										&:last-child {
											border-bottom-right-radius: -6px;
										}
									}
								}
							}
						}
					}
					>tfoot {
						&:last-child {
							>tr {
								&:last-child {
									border-bottom-left-radius: -6px;
									border-bottom-right-radius: -6px;
									td {
										&:first-child {
											border-bottom-left-radius: -6px;
										}
										&:last-child {
											border-bottom-right-radius: -6px;
										}
									}
									th {
										&:first-child {
											border-bottom-left-radius: -6px;
										}
										&:last-child {
											border-bottom-right-radius: -6px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
		+ {
			.panel-body {
				border-top: 1px solid #021B26;
			}
		}
		>.table-bordered {
			border: 0;
			>thead {
				>tr {
					>th {
						&:first-child {
							border-left: 0;
						}
						&:last-child {
							border-right: 0;
						}
					}
					>td {
						&:first-child {
							border-left: 0;
						}
						&:last-child {
							border-right: 0;
						}
					}
					&:first-child {
						>td {
							border-bottom: 0;
						}
						>th {
							border-bottom: 0;
						}
					}
				}
			}
			>tbody {
				>tr {
					>th {
						&:first-child {
							border-left: 0;
						}
						&:last-child {
							border-right: 0;
						}
					}
					>td {
						&:first-child {
							border-left: 0;
						}
						&:last-child {
							border-right: 0;
						}
					}
					&:first-child {
						>td {
							border-bottom: 0;
						}
						>th {
							border-bottom: 0;
						}
					}
					&:last-child {
						>td {
							border-bottom: 0;
						}
						>th {
							border-bottom: 0;
						}
					}
				}
			}
			>tfoot {
				>tr {
					>th {
						&:first-child {
							border-left: 0;
						}
						&:last-child {
							border-right: 0;
						}
					}
					>td {
						&:first-child {
							border-left: 0;
						}
						&:last-child {
							border-right: 0;
						}
					}
					&:last-child {
						>td {
							border-bottom: 0;
						}
						>th {
							border-bottom: 0;
						}
					}
				}
			}
		}
		border: 0;
		margin-bottom: 0;
	}
	>.panel-body {
		+ {
			.table {
				border-top: 1px solid #021B26;
			}
			.table-responsive {
				border-top: 1px solid #021B26;
			}
		}
	}
	>.table-bordered {
		border: 0;
		>thead {
			>tr {
				>th {
					&:first-child {
						border-left: 0;
					}
					&:last-child {
						border-right: 0;
					}
				}
				>td {
					&:first-child {
						border-left: 0;
					}
					&:last-child {
						border-right: 0;
					}
				}
				&:first-child {
					>td {
						border-bottom: 0;
					}
					>th {
						border-bottom: 0;
					}
				}
			}
		}
		>tbody {
			>tr {
				>th {
					&:first-child {
						border-left: 0;
					}
					&:last-child {
						border-right: 0;
					}
				}
				>td {
					&:first-child {
						border-left: 0;
					}
					&:last-child {
						border-right: 0;
					}
				}
				&:first-child {
					>td {
						border-bottom: 0;
					}
					>th {
						border-bottom: 0;
					}
				}
				&:last-child {
					>td {
						border-bottom: 0;
					}
					>th {
						border-bottom: 0;
					}
				}
			}
		}
		>tfoot {
			>tr {
				>th {
					&:first-child {
						border-left: 0;
					}
					&:last-child {
						border-right: 0;
					}
				}
				>td {
					&:first-child {
						border-left: 0;
					}
					&:last-child {
						border-right: 0;
					}
				}
				&:last-child {
					>td {
						border-bottom: 0;
					}
					>th {
						border-bottom: 0;
					}
				}
			}
		}
	}
}
.panel-body {
	padding: 15px;
}
.panel-heading {
	padding: 10px 15px;
	border-bottom: 1px solid transparent;
	border-top-right-radius: -6px;
	border-top-left-radius: -6px;
	>.dropdown {
		.dropdown-toggle {
			color: inherit;
		}
	}
	+ {
		.list-group {
			.list-group-item {
				&:first-child {
					border-top-width: 0;
				}
			}
		}
	}
}
.panel-title {
	margin-top: 0;
	margin-bottom: 0;
	font-size: 16px;
	color: inherit;
	>a {
		color: inherit;
	}
	>small {
		color: inherit;
		>a {
			color: inherit;
		}
	}
	>.small {
		color: inherit;
		>a {
			color: inherit;
		}
	}
}
.panel-footer {
	padding: 10px 15px;
	background-color: #f5f5f5;
	border-top: 1px solid #ddd;
	border-bottom-right-radius: -6px;
	border-bottom-left-radius: -6px;
}
.list-group {
	+ {
		.panel-footer {
			border-top-width: 0;
		}
	}
}
.panel-group {
	margin-bottom: 20px;
	.panel {
		margin-bottom: 0;
		border-radius: 4px;
		+ {
			.panel {
				margin-top: 5px;
			}
		}
	}
	.panel-heading {
		border-bottom: 0;
		+ {
			.panel-collapse {
				>.panel-body {
					border-top: 1px solid #ddd;
				}
				>.list-group {
					border-top: 1px solid #ddd;
				}
			}
		}
	}
	.panel-footer {
		border-top: 0;
		+ {
			.panel-collapse {
				.panel-body {
					border-bottom: 1px solid #ddd;
				}
			}
		}
	}
}
.panel-default {
	border-color: #ddd;
}
.panel-primary {
	border-color: #286AA6;
}
.panel-success {
	border-color: #d6e9c6;
}
.panel-info {
	border-color: #bce8f1;
}
.panel-warning {
	border-color: #faebcc;
}
.panel-danger {
	border-color: #ebccd1;
}


.panel {
	background-color: transparent;
	border: 0px solid transparent;
	border-radius: 0px;
	-webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
.panel-default {
	>.panel-heading {
		color: #fff;
		background-color: transparent;
		border-bottom: 1px solid #052836;
		font-size: 20px;
		max-height: 75px !important;
	}
	.panel-body {
		.panel-title {
			color: #000;
		}
	}
}
.panel-title {
	margin-top: 0;
	margin-bottom: 0;
	font-size: 12px;
	color: #fff;
	line-height: 20px;
}
.panel-title-p {
	margin-top: 0;
	margin-bottom: 0;
	font-size: 12px;
	color: #fff;
	line-height: 20px;
}
.panel-body {
	color: #fff;
	padding-top: 0px;
	padding-bottom: 0px;
	font-size: 20px;
	>.row {
		>div {
			min-height: 75px;
			padding-top: 27px;
			&:first-child {
				padding-left: 22px;
			}
		}
	}
}
.panel-heading {
	position: relative;
	padding: 0;
	text-transform: uppercase;
	border-color: rgba(0, 0, 0, 0.05);
}
.panel-collapse {
	.panel-body {
		>.row {
			min-height: 55px;
			background-color: #fff;
			color: #000;
			transition: background-color 0.5s ease;
		}
	}
}
.panel-group {
	.panel-heading {
		+ {
			.panel-collapse {
				>.panel-body {
					border-top: 0px solid #052836;
				}
				>.list-group {
					border-top: 0px solid #052836;
				}
			}
		}
		>a {
			position: relative;
			display: block;
			padding: 10px 15px;
			color: inherit;
			text-decoration: none;
			text-transform: uppercase;
			background-color: #0086C5;
			transition: background-color 0.5s ease;
			max-height: 75px;
			text-transform: none;
			&:after {
				position: absolute;
				right: 30px;
				top: 22px;
				font-size: 40px;
				height: 20px;
				width: 20px;
				content: "\e316";
				border: none;
				background: none;
				text-align: center;
				line-height: 32px;
				font-family: sr-icons;
				color: white;
			}
		}
		>.collapsed {
			background-color: transparent;
			&:after {
				content: "\e313" !important;
			}
		}
	}
	.panel {
		+ {
			.panel {
				margin-top: 0px;
			}
		}
	}
}
.profile-text-small {
	border-radius: 50%;
	text-align: center;
	background-color: #0E4761;
	width: 40px;
	height: 40px;
	min-width: 40px;
	color: #fff;
	font-size: 15px;
	line-height: 40px;
}
.panel-pre {
	opacity: 0.5;
	color: #FFFFFF;
	font-family: Roboto;
	font-size: 13px;
	line-height: 17px;
	text-shadow: 0 2px 31px 0 rgba(0, 0, 0, 0.79);
	margin-bottom: 15px;
}
ul.edit-buttons {
	white-space: nowrap;
	margin-left: -70px;
}
.edit-buttons {
	li {
		display: inline;
		i {
			display: inline-block;
		}
		a {
			display: inherit;
			padding: 0;
			margin-right: 10px;
			color: #a6a6a6;
			text-decoration: none;
			&:hover {
				color: #0E4761;
			}
		}
	}
	.icon-edit {
		transform: scale();
	}
}
@media (max-width: 479px) {
	.panel-group {
		.panel-heading {
			.row {
				>div {
					padding-left: 5px;
					padding-right: 5px;
					&:first-child {
						padding-left: 15px;
					}
				}
			}
		}
	}
}
