.popover {
    padding: 8px;
    position: absolute;
    z-index: 9999;
    max-width: 220px;
    font-size: 13px;
    border: 0px;
    line-height: inherit;
    background-color: rgba(5, 40, 54, .88);
    border-radius: 0;
    &.bottom .arrow:after {
        border-bottom-color: rgba(5, 40, 54, .88);
    }
    &.right .arrow:after {
        border-right-color: rgba(5, 40, 54, .88);
    }
    &.left .arrow:after {
        border-left-color: rgba(5, 40, 54, .88);
    }
    &.top .arrow:after {
        border-top-color: rgba(5, 40, 54, .88);
    }
    &.top > .arrow {
        border-top-color: rgba(5, 40, 54, .88);
    }
}
.popover-content {
    color: #ffffff;
    opacity: .88;
    font-size:13px;
    line-height:18px;
    padding:0;
}

/* popover light 
.popover {
    padding: 8px 18px 12px 18px;
    position: absolute;
    z-index: 9999;
    max-width: 220px;
    font-size: 13px;
    border: 0px;
    line-height: inherit;
    background-color: #ffffff;
    border-radius: 0;
    &.top {
        margin-top: -15px;
        .arrow:after {
            border-top-color: #ffffff;
        }
        > .arrow {
            border-top-color: #ffffff;
        }
    }
    &.bottom {
        margin-bottom: -15px;
        .arrow:after {
            border-bottom-color: #ffffff;
        }
        > .arrow {
            border-bottom-color: #ffffff;
        }
    }
}
.popover-content {
    color: #000000;
    opacity: .88;
    font-size: 11px;
    line-height: 15px;
    letter-spacing: 0.47px;
    font-weight: 300;
    padding:0;
}*/