// overall styling rules for ORK Lokale Partner und Unterstützer

.ork-partner-local {

    .city {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.4px;
        font-weight: 300;
        margin-top: 20px;
    }
    .info-text {
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 0.55px;
        opacity: 0.5;
    }

    .border-0 {
        border: 0!important;
    }
    a.delete {
        color: rgba(255,255,255,0.5);
    }
    .btn {
        width: 190px;
    }
    /* compat 2017 override */
    button.btn-primary {
        color: #fff;
    }

    .sr_success {
        color: inherit;
    }

    .valign-top {
        vertical-align: top!important;
    }

    .no-results-text {
        font-size: 16px;
        text-align: center;
    }

    // logos preview
    .partners {
        .row-headline {
            text-align: left;
            margin-top: 35px;
            margin-bottom: 4px;
        }
        .grid-wrapper {
            display: flex;
            flex-wrap: wrap;
        }
        .grid-item {
            margin: 20px 0;
            width: 16.66%;
            display: flex;
            justify-content: center;
            img {
                height: 30px;
                max-width: 105px;
                object-fit: contain;
            }
        }
    }

    

    #list-local-partners {
        table-layout: fixed;
        th {
            border: 0;
            font-size: 13px;
            line-height: 17px;
            color: rgba(255,255,255,0.5)!important;
            font-weight: 500;
        }
        td {
            font-size: 12px;
            line-height: 17px;
            border: 0;
            border-bottom: 1px solid rgba(2,27,38,0.7);
            vertical-align: middle;
        }
        thead {
            tr {
                th {
                    padding: 15px 25px;
                    &:first-child {
                        padding-left: 0;
                    }
                    &:last-child {
                        padding-right: 0;
                    }
                }
            }
        }
        tbody {
            tr {
                td {
                    padding: 21px 25px;
                    &:first-child {
                        height: 22px;
                        padding-left: 0;
                    }
                    &:last-child {
                        padding-right: 0;
                    }
                }
            }
            
        }
        input {
            color: #fff;
            background: transparent;
            border: 0;
            border-bottom: 1px solid rgba(255,255,255,0.8);
            padding: 7px 0;
            /*width: 180px;*/
            width: 100%;
            &::placeholder {
                /*color: rgba(255,255,255,0.42);*/
                font-size: 12px;
                line-height: 17px;
            }
            &:disabled {
                border-bottom: 1px solid transparent;
                cursor: not-allowed;
                &::placeholder {
                    color: transparent;
                }
            }
        }
        .upload-add input {
            width: 260px;
            font-size: 12px;
            line-height: 17px;
        }
        
        .partner-logo {
            max-width: 100px; 
            max-height: 22px;
        }
    }

    a.icon-edit {
        cursor: pointer;
        font-size: 18px;
        color: rgba(255,255,255,0.5);
        margin-right: 14px;
    }
    a.icon-check::before {
        content: "\f00c";
        cursor: pointer;
        font-size: 18px;
        color: rgba(255,255,255,0.5);
        margin-right: 14px;
    }
    .delete svg {
        vertical-align: top;
    }
    .image-name-label {
        max-width: 190px;
    }
}

/* tablet/desktop only */
@media (min-width: 768px) {
    #list-local-partners tr > {
        *:nth-child(1) { width:15%; }
        *:nth-child(2) { width:30%; }
        *:nth-child(3) { width:30%; }
        *:nth-child(4) { width:10%; }
        *:nth-child(5) { width:10%; }
    }
}

/* tablet */
@media (min-width: 768px) and (max-width: 991px) {
    .ork-partner-local {
        padding: 0 14px 30px;
        margin-bottom: 30px;
        .dataTables_wrapper {
            padding: 0 20px !important;
        }
        .partners .grid-item {
            width: 25%;
            img {
                max-width: 110px;
            }
        }
    }
}

/* all mobile */
@media (max-width: 767px) {
    .ork-partner-local {
        padding: 0 0 65px;
        h1, .city {
            padding: 0 15px;
        }
        #list-local-partners {
            table-layout: auto;
            th {
                font-size: 11px;
                line-height: 13px;
                letter-spacing: 0.3px;
            }
            thead {
                tr {
                    th {
                        &:first-child {
                            padding-left: 30px;
                        }
                        &:last-child {
                            padding-right: 30px;
                        }
                    }
                }
            }
            tbody {
                tr {
                    td {
                        &:first-child {
                            height: 32px;
                            padding-left: 30px;
                        }
                        &:last-child {
                            padding-right: 30px;
                        }
                    }
                    &.full-width-mobile {
                        td {
                            &:first-child {
                                height: auto;
                            }
                            &:last-child {
                                padding-left: 30px;
                            }
                        }
                    }
                }
            }

            .partner-logo {
                max-height: 22px;
            }
            .upload-add input {
                width: 100%;
            }
        }
        .dataTables_wrapper {
            padding: 0;
            margin: 0 -15px !important;
        }
        .partners {
            width: 90%;
            margin-bottom: 25px;
            .grid-item {
                margin: 18px 0;
                width: 50%;
                img {
                    height: 30px;
                    max-width: 90%;
                }
            }
        }

        .btn {
            display: block;
            width: 100%;
        }
        .full-width-mobile td {
            display: block;
            width: 110%;
        }
        .image-name-label {
            max-width: inherit;
        }
    }
}