fieldset {
	padding: 0;
	margin: 0;
	border: 0;
	min-width: 0;
}
legend {
	display: block;
	width: 100%;
	padding: 0;
	margin-bottom: 20px;
	font-size: 21px;
	line-height: inherit;
	color: #454545;
	border: 0;
	border-bottom: 1px solid transparent;
}
label {
	display: inline-block;
	max-width: 100%;
	margin-bottom: 5px;
	font-weight: bold;
}
input[type="search"] {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-appearance: none;
}
input[type="radio"] {
	margin: 4px 0 0;
	margin-top: 1px \9;
	line-height: normal;
	&:focus {
		outline: 5px auto -webkit-focus-ring-color;
		outline-offset: -2px;
	}
}
input[type="checkbox"] {
	margin: 4px 0 0;
	margin-top: 1px \9;
	line-height: normal;
	&:focus {
		outline: 5px auto -webkit-focus-ring-color;
		outline-offset: -2px;
	}
}
input[type="file"] {
	display: block;
	&:focus {
		outline: 5px auto -webkit-focus-ring-color;
		outline-offset: -2px;
	}
}
input[type="range"] {
	display: block;
	width: 100%;
}
select[multiple] {
	height: auto;
}
select[size] {
	height: auto;
}
output {
	display: block;
	padding-top: 10px;
	font-size: 14px;
	line-height: 1.428571429;
	color: #454545;
}
.form-control {
	display: block;
	width: 100%;
	height: 40px;
	padding: 9px 18px;
	font-size: 14px;
	line-height: 1.428571429;
	color: #454545;
	background-color: transparent;
	background-image: none;
	border: 1px solid #bbb;
	border-radius: 0;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	-webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	-o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	&::-ms-expand {
		border: 0;
		background-color: transparent;
	}
}
.form-control[disabled] {
	background-color: transparent;
	opacity: 1;
	cursor: not-allowed;
}
.form-control[readonly] {
	background-color: transparent;
	opacity: 1;
}
fieldset[disabled] {
	.form-control {
		background-color: transparent;
		opacity: 1;
		cursor: not-allowed;
	}
	input[type="radio"] {
		cursor: not-allowed;
	}
	input[type="checkbox"] {
		cursor: not-allowed;
	}
	.radio-inline {
		cursor: not-allowed;
	}
	.checkbox-inline {
		cursor: not-allowed;
	}
	.radio {
		label {
			cursor: not-allowed;
		}
	}
	.checkbox {
		label {
			cursor: not-allowed;
		}
	}
}
textarea.form-control {
	height: auto;
}
.form-group {
	margin-bottom: 20px;
}
.radio {
	position: relative;
	display: block;
	margin-top: 10px;
	margin-bottom: 10px;
	label {
		min-height: 20px;
		padding-left: 20px;
		margin-bottom: 0;
		font-weight: normal;
		cursor: pointer;
	}
	input[type="radio"] {
		position: absolute;
		margin-left: -20px;
		margin-top: 4px \9;
	}
	+ {
		.radio {
			margin-top: -5px;
		}
	}
}
.checkbox {
	position: relative;
	display: block;
	margin-top: 10px;
	margin-bottom: 10px;
	label {
		min-height: 20px;
		padding-left: 20px;
		margin-bottom: 0;
		font-weight: normal;
		cursor: pointer;
	}
	input[type="checkbox"] {
		position: absolute;
		margin-left: -20px;
		margin-top: 4px \9;
	}
	+ {
		.checkbox {
			margin-top: -5px;
		}
	}
}
.radio-inline {
	input[type="radio"] {
		position: absolute;
		margin-left: -20px;
		margin-top: 4px \9;
	}
	position: relative;
	display: inline-block;
	padding-left: 20px;
	margin-bottom: 0;
	vertical-align: middle;
	font-weight: normal;
	cursor: pointer;
	+ {
		.radio-inline {
			margin-top: 0;
			margin-left: 10px;
		}
	}
}
.checkbox-inline {
	input[type="checkbox"] {
		position: absolute;
		margin-left: -20px;
		margin-top: 4px \9;
	}
	position: relative;
	display: inline-block;
	padding-left: 20px;
	margin-bottom: 0;
	vertical-align: middle;
	font-weight: normal;
	cursor: pointer;
	+ {
		.checkbox-inline {
			margin-top: 0;
			margin-left: 10px;
		}
	}
}
input[type="radio"][disabled] {
	cursor: not-allowed;
}
input[type="radio"].disabled {
	cursor: not-allowed;
}
input[type="checkbox"][disabled] {
	cursor: not-allowed;
}
input[type="checkbox"].disabled {
	cursor: not-allowed;
}
.radio-inline.disabled {
	cursor: not-allowed;
}
.checkbox-inline.disabled {
	cursor: not-allowed;
}
.radio.disabled {
	label {
		cursor: not-allowed;
	}
}
.checkbox.disabled {
	label {
		cursor: not-allowed;
	}
}
.form-control-static {
	padding-top: 10px;
	padding-bottom: 10px;
	margin-bottom: 0;
	min-height: 34px;
}
.form-control-static.input-lg {
	padding-left: 0;
	padding-right: 0;
}
.input-group-lg {
	>.form-control-static.form-control {
		padding-left: 0;
		padding-right: 0;
	}
	>.form-control-static.input-group-addon {
		padding-left: 0;
		padding-right: 0;
	}
	>.input-group-btn {
		>.form-control-static.btn {
			padding-left: 0;
			padding-right: 0;
		}
		>.btn {
			+ {
				.form-control-feedback {
					width: 53px;
					height: 53px;
					line-height: 53px;
				}
			}
		}
	}
	>.form-control {
		+ {
			.form-control-feedback {
				width: 53px;
				height: 53px;
				line-height: 53px;
			}
		}
	}
	>.input-group-addon {
		+ {
			.form-control-feedback {
				width: 53px;
				height: 53px;
				line-height: 53px;
			}
		}
	}
	+ {
		.form-control-feedback {
			width: 53px;
			height: 53px;
			line-height: 53px;
		}
	}
}
.form-control-static.input-sm {
	padding-left: 0;
	padding-right: 0;
}
.input-group-sm {
	>.form-control-static.form-control {
		padding-left: 0;
		padding-right: 0;
	}
	>.form-control-static.input-group-addon {
		padding-left: 0;
		padding-right: 0;
	}
	>.input-group-btn {
		>.form-control-static.btn {
			padding-left: 0;
			padding-right: 0;
		}
		>.btn {
			+ {
				.form-control-feedback {
					width: 34px;
					height: 34px;
					line-height: 34px;
				}
			}
		}
	}
	>.form-control {
		+ {
			.form-control-feedback {
				width: 34px;
				height: 34px;
				line-height: 34px;
			}
		}
	}
	>.input-group-addon {
		+ {
			.form-control-feedback {
				width: 34px;
				height: 34px;
				line-height: 34px;
			}
		}
	}
	+ {
		.form-control-feedback {
			width: 34px;
			height: 34px;
			line-height: 34px;
		}
	}
}
.form-group-sm {
	.form-control {
		height: 34px;
		padding: 7px 14px;
		font-size: 12px;
		line-height: 1.5;
		border-radius: 0;
		+ {
			.form-control-feedback {
				width: 34px;
				height: 34px;
				line-height: 34px;
			}
		}
	}
	select.form-control {
		height: 34px;
		line-height: 34px;
	}
	textarea.form-control {
		height: auto;
	}
	select[multiple].form-control {
		height: auto;
	}
	.form-control-static {
		height: 34px;
		min-height: 32px;
		padding: 8px 14px;
		font-size: 12px;
		line-height: 1.5;
	}
}
.form-group-lg {
	.form-control {
		height: 53px;
		padding: 14px 28px;
		font-size: 18px;
		line-height: 1.25;
		border-radius: 0;
		+ {
			.form-control-feedback {
				width: 53px;
				height: 53px;
				line-height: 53px;
			}
		}
	}
	select.form-control {
		height: 53px;
		line-height: 53px;
	}
	textarea.form-control {
		height: auto;
	}
	select[multiple].form-control {
		height: auto;
	}
	.form-control-static {
		height: 53px;
		min-height: 38px;
		padding: 15px 28px;
		font-size: 18px;
		line-height: 1.25;
	}
}
.has-feedback {
	position: relative;
	.form-control {
		padding-right: 50px;
	}
	label {
		~ {
			.form-control-feedback {
				top: 25px;
			}
		}
	}
	label.sr-only {
		~ {
			.form-control-feedback {
				top: 0;
			}
		}
	}
}
.form-control-feedback {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2;
	display: block;
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	pointer-events: none;
}
.input-lg {
	+ {
		.form-control-feedback {
			width: 53px;
			height: 53px;
			line-height: 53px;
		}
	}
}
.input-sm {
	+ {
		.form-control-feedback {
			width: 34px;
			height: 34px;
			line-height: 34px;
		}
	}
}
.help-block {
	display: block;
	margin-top: 5px;
	margin-bottom: 10px;
	color: #858585;
}
.form-horizontal {
	.radio {
		margin-top: 0;
		margin-bottom: 0;
		padding-top: 10px;
		min-height: 30px;
	}
	.checkbox {
		margin-top: 0;
		margin-bottom: 0;
		padding-top: 10px;
		min-height: 30px;
	}
	.radio-inline {
		margin-top: 0;
		margin-bottom: 0;
		padding-top: 10px;
	}
	.checkbox-inline {
		margin-top: 0;
		margin-bottom: 0;
		padding-top: 10px;
	}
	.form-group {
		margin-left: -15px;
		margin-right: -15px;
	}
	.has-feedback {
		.form-control-feedback {
			right: 15px;
		}
	}
}

.form-control {
	-webkit-box-shadow: none;
	box-shadow: none;
	outline: none;
	&:focus {
		-webkit-box-shadow: none;
		box-shadow: none;
		outline: none;
	}
}
.dashboard {
	.form-horizontal {
		label {
			color: #fefefe;
		}
		input {
			color: #fefefe;
		}
		legend {
			color: #fefefe;
		}
		button {
			color: #fefefe;
		}
		textarea {
			color: #fefefe;
		}
		.control-label {
			color: rgba(255, 255, 255, 0.6);
		}
	}
}
.form-horizontal {
	legend {
		display: block;
		margin: 0 0 25px;
		font-size: 16px;
	}
	textarea.form-control {
		height: 150px;
		resize: none;
		padding: 15px !important;
		border-width: 2px;
		border-radius: 7px;
	}
	.form-column {
		position: relative;
		min-height: 1px;
		padding-left: 15px;
		padding-right: 15px;
	}
	.form-column-wide {
		position: relative;
		min-height: 1px;
		padding-left: 15px;
		padding-right: 15px;
	}
	.control-label {
		position: relative;
		min-height: 1px;
		padding-left: 15px;
		padding-right: 15px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		font-size: 14px;
		font-weight: normal;
		text-align: left !important;
		color: #676767;
		max-height: 34px;
		overflow: visible;
	}
	.control-label.labelfocus {
		color: #0086C5;
		+ {
			div {
				* {
					border-color: #0086C5;
				}
			}
		}
	}
	.form-control {
		padding: 4px 50px 4px 0 !important;
		border-width: 0 0 2px;
		border-radius: 0;
		border-color: #bbb;
	}
	.checkbox {
		position: relative;
		min-height: 20px;
		label {
			font-weight: 300;
			font-size: 14px;
			line-height: 22px;
			position: absolute;
			left: 30px;
			width: auto;
			padding: 0;
			color: rgba(69, 69, 69, 0.8);
			&:before {
				position: absolute;
				left: -30px;
				top: 2px;
				content: "";
				color: #fff;
				display: inline-block;
				vertical-align: middle;
				margin-right: 15px;
				border-radius: 2px;
				width: 16px;
				height: 16px;
				line-height: 14px;
				margin-top: -2px;
				background-color: transparent;
				border: 1px solid #fff;
				text-align: center;
				font-family: fontawesome;
			}
		}
		input[type="checkbox"] {
			display: none;
			&:checked {
				+ {
					label {
						&::before {
							content: "\f00c";
							background-color: #8CB82B;
							border: 1px solid #8CB82B;
						}
					}
				}
			}
		}
	}
	.checkbox.checkbox-reverse {
		label {
			left: 15px !important;
			max-width: 25%;
			&::before {
				position: absolute;
				right: -20px;
				left: auto;
				margin-right: 0 !important;
			}
		}
	}
}
.custom-form {
	.radio {
		input[type="radio"] {
			display: none;
			&:checked {
				+ {
					label {
						&:before {
							border-width: 7px;
						}
					}
				}
			}
		}
		label {
			padding-left: 0;
			&:before {
				content: "";
				display: inline-block;
				vertical-align: middle;
				margin-right: 15px;
				width: 20px;
				height: 20px;
				border-radius: 50%;
				border: 10px solid #eee;
				background-color: #333333;
			}
		}
	}
}
.checkbox-label {
	padding-left: 20px;
}
@media (min-width: 768px) {
	.form-horizontal {
		.form-column {
			float: left;
			width: 41.6666666667%;
		}
		.form-column-wide {
			float: left;
			width: 75%;
		}
		.control-label {
			float: left;
			width: 25%;
		}
	}
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
	input[type="date"].form-control {
		line-height: 40px;
	}
	input[type="time"].form-control {
		line-height: 40px;
	}
	input[type="datetime-local"].form-control {
		line-height: 40px;
	}
	input[type="month"].form-control {
		line-height: 40px;
	}
	input[type="date"].input-sm {
		line-height: 34px;
	}
	.input-group-sm {
		>input[type="date"].form-control {
			line-height: 34px;
		}
		>input[type="date"].input-group-addon {
			line-height: 34px;
		}
		>.input-group-btn {
			>input[type="date"].btn {
				line-height: 34px;
			}
			>input[type="time"].btn {
				line-height: 34px;
			}
			>input[type="datetime-local"].btn {
				line-height: 34px;
			}
			>input[type="month"].btn {
				line-height: 34px;
			}
		}
		input[type="date"] {
			line-height: 34px;
		}
		>input[type="time"].form-control {
			line-height: 34px;
		}
		>input[type="time"].input-group-addon {
			line-height: 34px;
		}
		input[type="time"] {
			line-height: 34px;
		}
		>input[type="datetime-local"].form-control {
			line-height: 34px;
		}
		>input[type="datetime-local"].input-group-addon {
			line-height: 34px;
		}
		input[type="datetime-local"] {
			line-height: 34px;
		}
		>input[type="month"].form-control {
			line-height: 34px;
		}
		>input[type="month"].input-group-addon {
			line-height: 34px;
		}
		input[type="month"] {
			line-height: 34px;
		}
	}
	input[type="time"].input-sm {
		line-height: 34px;
	}
	input[type="datetime-local"].input-sm {
		line-height: 34px;
	}
	input[type="month"].input-sm {
		line-height: 34px;
	}
	input[type="date"].input-lg {
		line-height: 53px;
	}
	.input-group-lg {
		>input[type="date"].form-control {
			line-height: 53px;
		}
		>input[type="date"].input-group-addon {
			line-height: 53px;
		}
		>.input-group-btn {
			>input[type="date"].btn {
				line-height: 53px;
			}
			>input[type="time"].btn {
				line-height: 53px;
			}
			>input[type="datetime-local"].btn {
				line-height: 53px;
			}
			>input[type="month"].btn {
				line-height: 53px;
			}
		}
		input[type="date"] {
			line-height: 53px;
		}
		>input[type="time"].form-control {
			line-height: 53px;
		}
		>input[type="time"].input-group-addon {
			line-height: 53px;
		}
		input[type="time"] {
			line-height: 53px;
		}
		>input[type="datetime-local"].form-control {
			line-height: 53px;
		}
		>input[type="datetime-local"].input-group-addon {
			line-height: 53px;
		}
		input[type="datetime-local"] {
			line-height: 53px;
		}
		>input[type="month"].form-control {
			line-height: 53px;
		}
		>input[type="month"].input-group-addon {
			line-height: 53px;
		}
		input[type="month"] {
			line-height: 53px;
		}
	}
	input[type="time"].input-lg {
		line-height: 53px;
	}
	input[type="datetime-local"].input-lg {
		line-height: 53px;
	}
	input[type="month"].input-lg {
		line-height: 53px;
	}
}
@media (min-width: 768px) {
	.form-inline {
		.form-group {
			display: inline-block;
			margin-bottom: 0;
			vertical-align: middle;
		}
		.form-control {
			display: inline-block;
			width: auto;
			vertical-align: middle;
		}
		.form-control-static {
			display: inline-block;
		}
		.input-group {
			display: inline-table;
			vertical-align: middle;
			.input-group-addon {
				width: auto;
			}
			.input-group-btn {
				width: auto;
			}
			.form-control {
				width: auto;
			}
			>.form-control {
				width: 100%;
			}
		}
		.control-label {
			margin-bottom: 0;
			vertical-align: middle;
		}
		.radio {
			display: inline-block;
			margin-top: 0;
			margin-bottom: 0;
			vertical-align: middle;
			label {
				padding-left: 0;
			}
			input[type="radio"] {
				position: relative;
				margin-left: 0;
			}
		}
		.checkbox {
			display: inline-block;
			margin-top: 0;
			margin-bottom: 0;
			vertical-align: middle;
			label {
				padding-left: 0;
			}
			input[type="checkbox"] {
				position: relative;
				margin-left: 0;
			}
		}
		.has-feedback {
			.form-control-feedback {
				top: 0;
			}
		}
	}
	.form-horizontal {
		.control-label {
			text-align: right;
			margin-bottom: 0;
			padding-top: 10px;
		}
		.form-group-lg {
			.control-label {
				padding-top: 15px;
				font-size: 18px;
			}
		}
		.form-group-sm {
			.control-label {
				padding-top: 8px;
				font-size: 12px;
			}
		}
	}
	.navbar-form {
		.form-group {
			display: inline-block;
			margin-bottom: 0;
			vertical-align: middle;
		}
		.form-control {
			display: inline-block;
			width: auto;
			vertical-align: middle;
		}
		.form-control-static {
			display: inline-block;
		}
		.input-group {
			display: inline-table;
			vertical-align: middle;
			.input-group-addon {
				width: auto;
			}
			.input-group-btn {
				width: auto;
			}
			.form-control {
				width: auto;
			}
			>.form-control {
				width: 100%;
			}
		}
		.control-label {
			margin-bottom: 0;
			vertical-align: middle;
		}
		.radio {
			display: inline-block;
			margin-top: 0;
			margin-bottom: 0;
			vertical-align: middle;
			label {
				padding-left: 0;
			}
			input[type="radio"] {
				position: relative;
				margin-left: 0;
			}
		}
		.checkbox {
			display: inline-block;
			margin-top: 0;
			margin-bottom: 0;
			vertical-align: middle;
			label {
				padding-left: 0;
			}
			input[type="checkbox"] {
				position: relative;
				margin-left: 0;
			}
		}
		.has-feedback {
			.form-control-feedback {
				top: 0;
			}
		}
	}
}
