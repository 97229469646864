.close {
	float: right;
	font-size: 21px;
	font-weight: bold;
	line-height: 1;
	color: #000;
	text-shadow: 0 1px 0 #fff;
	opacity: 0.2;
	filter: alpha(opacity=20);
	&:hover {
		color: #000;
		text-decoration: none;
		cursor: pointer;
		opacity: 0.5;
		filter: alpha(opacity=50);
	}
	&:focus {
		color: #000;
		text-decoration: none;
		cursor: pointer;
		opacity: 0.5;
		filter: alpha(opacity=50);
	}
}
button.close {
	padding: 0;
	cursor: pointer;
	background: transparent;
	border: 0;
	-webkit-appearance: none;
}
