.bg-primary {
	background-color: #286AA6;
}
a.bg-primary {
	&:hover {
		background-color: #1e507d;
	}
	&:focus {
		background-color: #1e507d;
	}
}
.bg-success {
	background-color: #dff0d8;
}
a.bg-success {
	&:hover {
		background-color: #c1e2b3;
	}
	&:focus {
		background-color: #c1e2b3;
	}
}
.bg-info {
	background-color: #d9edf7;
}
a.bg-info {
	&:hover {
		background-color: #afd9ee;
	}
	&:focus {
		background-color: #afd9ee;
	}
}
.bg-warning {
	background-color: #fcf8e3;
}
a.bg-warning {
	&:hover {
		background-color: #f7ecb5;
	}
	&:focus {
		background-color: #f7ecb5;
	}
}
.bg-danger {
	background-color: #f2dede;
}
a.bg-danger {
	&:hover {
		background-color: #e4b9b9;
	}
	&:focus {
		background-color: #e4b9b9;
	}
}
