#ags-administration {
    .link-back {
        display: block;
        margin-bottom: 90px;
        color: #fff;
        font-size: 14px;
        line-height: 16px;
        svg {
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
        }
    }

    .status-item svg {
        background-color: #fff;
        border-radius: 50%;
        margin-right: 7px;
        position: relative;
        top: 3px;
    }

    @media (min-width: 768px)
    { section.dashboard .sr-icon-grid > li {
        flex-basis: 0% !important;
    }
    }

    @media (max-width: 991px)
    { .sr-icon-grid li {
        min-width: 0%;
    }
    }

    .small-button {
        font-size: 10px;
        min-width: auto;
        padding: 5px;
    }

    .content-spinner {
        z-index: 5000;
        width: 200px;
        margin: 0 auto;
        padding: 20px;
        display: flex;
        justify-content: center;
        img {
            margin-top: 25%;
            -webkit-animation: rotation 1s linear 0s infinite;
            -moz-animation: rotation 1s linear 0s infinite;
            -o-animation: rotation 1s linear 0s infinite;
            animation: rotation 1s linear 0s infinite;
        }
    }

    #uploadResult {
        color: #454545;
    }

    .dark-bg .btn.btn-default.dropdown-toggle, .top10btn .btn.btn-default.dropdown-toggle {
        min-width:inherit;
    }

    .arrow-invert .arrow-links li a::before {
        float: left;
        margin-right: 3px;
        transform: rotate(-135deg);
    }

    .arrow-invert .arrow-links li a:hover::before {
        transform: rotate(-90deg);
    }

    @media (min-width: 768px) {

        /* line 157, ../scss/components/_datatables.scss */
        table.sr-ranking tbody > tr > td:first-of-type span {
            height: 40px;
            width: 40px;
            display: block;
            padding: 15px 20px 15px 14px;
            border-radius: 50%;
            margin-left: 2px;
            background: #052836;
            font-size: 15px;
            font-weight: 500;
            line-height: 12px;
        }


        .md-px-0 {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }


    }


    table.sr-ranking span.aktiv::after {
        content: none;
    }

    table.sr-ranking tbody > tr td {
        font-size: 15px;
        color: rgba(255,255,255,0.87);
    }

    .text-15 {
        font-size: 15px !important;
    }

    #tableSortXs .btn {
        padding-left: inherit;
        padding-right: inherit;
        padding-top: 4px;
        padding-bottom: 4px;
    }

    #tableSortXs .btn.btn-default.active {
        background-color: rgba(2,27,38,0.8) !important;
        border: 1px solid rgba(0, 134, 197, 0.4) !important;
    }

    @media (max-width: 767px) {
        /* line 225, ../scss/components/_datatables.scss */
        table.sr-ranking tbody > tr > td.radelnde,
        table.sr-ranking tbody > tr > td.merge-left {
            position: absolute;
            left: 0;
            margin-top: 24px;
            font-size: 11px;
            opacity: 0.7;
            padding-left: 10px !important;
        }
    }

    .data-unit {
        font-size: 13px;
    }

    table.sr-ranking tbody > tr {
        border-bottom: 1px solid #052836;
    }

    /* line 260, ../scss/components/_datatables.scss */
    td i[class*='-dude'] {
        padding-left: 5px;
        font-size: 30px;
        color: #0086C5;
        vertical-align: middle; }


    .data-table tbody > tr .bar_wrapper .bar_bg {
        width: 100%;

    }

    .results-filter > div
    {
        flex:1 1 auto;
        text-align:center;
        margin: 0 50px;
    }



    @media (max-width: 767px) {


        .results-filter > div
        {
            margin: 10px 0;
        }
    }

    @media (min-width: 768px) {
        .results-filter > div
        {
            margin: 0 30px;
        }
    }

    @media (min-width: 1440px) {
        .results-filter > div
        {
            margin: 0 50px;
        }
    }


    .results-filter > div:first-child
    {
        margin-left: 0;
    }

    .results-filter > div:last-child
    {
        margin-right: 0;
    }


    .dark-bg .btn.btn-default.dropdown-toggle, .top10btn .btn.btn-default.dropdown-toggle {
        min-width: 140px !important;
    }


    .nav-pills > li > a {
        border-left-width: 0;
        border-radius: 0;
    }

    .nav-pills > li:first-child > a {
        border-radius: 2px 0 0 2px;
        border-left-width: 1px;
    }

    .nav-pills > li:last-child > a {
        border-radius: 0 2px 2px;
    }

    .nav-pills > li + li {
        margin-left: 0;
    }

    .nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
        color: #fefefe;
        background-color: #fefefe;
        border-color: #8CB82B !important;
        font-weight: 500 !important;
    }


    #legend-wrapper p {
        line-height: 3;
    }




    #teamliste_wrapper div.dataTables_paginate, div.dataTables_paginate {
        text-align: center !important;
        padding-top: 50px;
        padding-bottom: 50px;
    }


    @media (max-width: 767px) {

        #teamliste tbody > tr > td:nth-child(2) {
            padding: 29px 15px 0;
        }

    }


    @media (max-width: 767px) {

        .sr-icon-grid li {
            flex-basis: 110px !important;
        }

        table.sr-ranking tbody > tr > td {
            padding: 13px 15px 0;
            border: 0;
            vertical-align: top;
            overflow: hidden;
            -ms-text-overflow: ellipsis;
            text-overflow: ellipsis;
            white-space: nowrap;
        }


    }

    @media (max-width: 767px) {

        table.sr-ranking tbody > tr > td {
            position: absolute;
            left: 0;
            right: initial !important;
            margin-top: 38px;
            font-size: 11px;
            opacity: 0.7;
            padding-left: 10px !important;
        }

        .data-table tbody > tr .bar_wrapper .bar_text {
            font-size: 18px;
        }

    }

    .nav > li > a {
        position: relative;
        display: block;
        padding: 7px 15px;
        font-size: 13px;
        font-weight: 500;
    }

    #tableSortXs .btn {
        padding-left: inherit;
        padding-right: inherit;
        padding-top: 4px;
        padding-bottom: 4px;
        font-size: 11px;
    }

    /* Pagination */

    .paginate_button.current {
        color: #0086C5 !important;
        font-weight: 600;
    }

    .paginate_button {
        font-size: 18px;
        position: relative;
    }

    @media (max-width: 479px) {

        .paginate_button.first, .paginate_button.previous, .paginate_button.next, .paginate_button.last {
            display: inline;
        }

        .paginate_button {
            font-size: 18px;
        }

    }

    .pagination-icon {
        height: 25px;
        fill: white;
        position: absolute;
        top: 8px;
        left: 0;
    }

    /* End Pagination */

    /* NumberOfResults */

    .numberOfResults > div, .numberOfResults > span {
        float: left;
        display: inline-table;
        margin-right: 10px;
        color: rgba(255, 255, 255, 0.5);
    }


    .numberOfResults > div {
        cursor: pointer;
    }

    .numberOfResults > div.active {
        cursor: pointer;
        color:#0086C5;
    }

    /* ==== Expanded DataTables */

    td.expanded {
        padding: 0 0 !important;
        background-color: #fff;

    }

    td.expanded td, td.expanded td a{
        color: #454545 !important;
    }

    tr.shown {
        background-color: #0086C5;
    }

    table.sr-ranking tbody > tr.expanded > td:first-of-type span {
        background: #fff;
    }


    .shown .subteamlink:after {
        color: #0E4761;
        transform: rotate(0deg);
        top: 0;
        right: 10px;
    }

    .shown .subteamlink {
        color: #0E4761;
    }

    /* ================================================ */
    /* ==== Action Icons inside tables ==== */

    .action-button-list {
        display: flex;
        flex-direction: row;
        position: relative;
        min-width: 130px;
    }
    .action-button-list a {
        padding: 10px 2px;
    }

    .d-none {
        display: none;
    }

    /* ================================================ */
    /* ==== Spinner ==== */

    .spinner {
        z-index: 5000;

    }
    .spinner img {
        margin-top: 25%;
        -webkit-animation: rotation 1s linear 0s infinite;
        -moz-animation: rotation 1s linear 0s infinite;
        -o-animation: rotation 1s linear 0s infinite;
        animation: rotation 1s linear 0s infinite;
    }

    @-webkit-keyframes rotation { from { transform: rotate(0deg); } to { transform: rotate(359deg); }  }
    @-moz-keyframes rotation { from { transform: rotate(0deg); } to { transform: rotate(359deg); }  }
    @-o-keyframes rotation { from { transform: rotate(0deg); } to { transform: rotate(359deg); }  }
    @keyframes rotation { from { transform: rotate(0deg); } to { transform: rotate(359deg); }  }


    div.dataTables_wrapper {
        position: relative;
    }

    div.dataTables_wrapper div.dataTables_processing {
        z-index: 5000;
        position: absolute;
        top: 50px;
        left: 50%;
        width: 200px;
        margin-left: -100px;
        margin-top: -26px;
        text-align: center;
        padding: 1em 0;
    }

    #ags-list {
        width:auto;
    }

    #ags-list_filter, #ags-list_length {
        display:none;
    }

    .dataTables_filter label input {
        border: 1px solid rgba(255,255,255,1);
        background-color: transparent;
        border-radius: 5px;
        height: 30px;
        opacity:1;
    }

    .dataTables_length label select {
        background-color: #0d4058;
        border-radius: 5px;
        height: 30px;
        border: none;
        -moz-appearance:none; /* Firefox */
        -webkit-appearance:none; /* Safari and Chrome */
        appearance:none;
        text-align: center;
        opacity:1;
    }

    #ags-list_length label {
        font-weight: 500;
        opacity: 0.39;
    }

    #ags-list_filter label {
        font-weight: 500;
        opacity: 0.39;
    }

    #ags-insert {
        display:flex;
        justify-content: space-between;
        padding-left: 15px;
    }

    #file-name {
        color: #454545;
        float: right;
    }

    #insert-form {
        display:flex;
        flex-direction: row;
    }

    #insert-form label {
        display: flex;
        align-self: center;
        min-width: 140px;
    }

    #ags-filter {
        display:flex;
        justify-content: space-between;
        padding-left: 15px;

        .filterWrapper {
            .filterInputWrapper {
                display: flex;
                justify-content: flex-end;
            }
        }
    }

    #insert-form input[type="checkbox"], #insert-form input[type="radio"] {
        display: none;
    }

    #insert-form input[type="checkbox"] + label, #insert-form input[type="checkbox"] + span {
        font-size: 14px;
        margin: 5px;
    }

    #insert-form {
        display: none;
    }

    .form-group {
        display:flex;
    }

    .information-text {
        color: #000;
        opacity: 0.39;
    }

    .modal-body {
        padding-top: 0;
        .btn.btn-default:not(.btn-success) {
            border-color: rgba(151,151,151,.8);
            color: #454545;
        }
        .info {
            color: rgba(69,69,69,.5);
            &:before {
                color: rgba(69,69,69,.5);
            }
        }
    }

    .modal-body .form-group {
        color: #000;
        input, select, .select2-selection__rendered, .select2-selection--single {
            border-color: rgba(151,151,151,.8);
            color: #454545;
        }
    }

    .modal-body .form-group label {
        min-width:140px;
    }

    .data-list {
        margin-left: 20px;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
    }

    .data-list label {
        display: flex;
        align-self: center;
        min-width: 140px;
    }

    .data-list input[type="checkbox"], #insert-form input[type="radio"] {
        display: none;
    }

    .data-list input[type="checkbox"] + label, #insert-form input[type="checkbox"] + span {
        font-size: 14px;
        margin: 5px;
    }

    .modal-single-button {
        display:flex;
        justify-content: center;
    }

    .w-250 {
        width:250px;
    }

    .w-100 {
        width:100%;
    }

    #ags-update-uuid {
        margin-left: -20px;
    }

    .select2-container--default {
        .select2-results__group {
            cursor: default;
            display: block;
            padding: 0;
        }
    }
    .select2-results__group {
        position: relative;
        cursor: pointer !important;
        &:after {
            position: absolute;
            content: "\e941" !important;
            transform: rotate(-90deg);
            right: 22px;
            top: 14px;
            font-size: 6px;
            height: 10px;
            width: 10px;
            border: none;
            background: none;
            text-align: center;
            line-height: 32px;
            font-family: sr-icons;
            color: white;
        }
    }
    .select2-results__group.open {
        &:after {
            content: "\e942" !important;
            transform: rotate(180deg);
            right: 12px;
            top: 25px;
        }
        color: #fff;
        padding-bottom: 5px !important;
        cursor: pointer !important;
    }
    .select2-results__option {
        cursor: pointer !important;
    }

    .form-group input[type="checkbox"], .form-group input[type="radio"] {
        display: block !important;
    }

    .form-group input[type="radio"]:checked ~ label::before,
    .form-group input[type="radio"] ~ label::before {
        display: none !important;
    }

    .form-group input[type="checkbox"] ~ label::before,
    .form-group input[type="checkbox"] ~ span::before {
        display: none !important;
    }

    @media (max-width: 767px) {
        .select-wrapper-top .select2-container .select2-selection--single .select2-selection__rendered {
            line-height: 27px !important;
        }
    }

}
