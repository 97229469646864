.visible-xs-block {
	display: none !important;
}
.visible-xs-inline {
	display: none !important;
}
.visible-xs-inline-block {
	display: none !important;
}
.visible-sm-block {
	display: none !important;
}
.visible-sm-inline {
	display: none !important;
}
.visible-sm-inline-block {
	display: none !important;
}
.visible-md-block {
	display: none !important;
}
.visible-md-inline {
	display: none !important;
}
.visible-md-inline-block {
	display: none !important;
}
.visible-lg-block {
	display: none !important;
}
.visible-lg-inline {
	display: none !important;
}
.visible-lg-inline-block {
	display: none !important;
}
.visible-print-block {
	display: none !important;
}
.visible-print-inline {
	display: none !important;
}
.visible-print-inline-block {
	display: none !important;
}
@media (max-width: 767px) {
	.visible-xs-block {
		display: block !important;
	}
	.visible-xs-inline {
		display: inline !important;
	}
	.visible-xs-inline-block {
		display: inline-block !important;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.visible-sm-block {
		display: block !important;
	}
	.visible-sm-inline {
		display: inline !important;
	}
	.visible-sm-inline-block {
		display: inline-block !important;
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	.visible-md-block {
		display: block !important;
	}
	.visible-md-inline {
		display: inline !important;
	}
	.visible-md-inline-block {
		display: inline-block !important;
	}
}
@media (min-width: 1200px) {
	.visible-lg-block {
		display: block !important;
	}
	.visible-lg-inline {
		display: inline !important;
	}
	.visible-lg-inline-block {
		display: inline-block !important;
	}
}
@media print {
	.visible-print-block {
		display: block !important;
	}
	.visible-print-inline {
		display: inline !important;
	}
	.visible-print-inline-block {
		display: inline-block !important;
	}
}
