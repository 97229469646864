@import "mixin";

:root {
    --base-text-dark:#4A4A4A;
    --base-white: #fff;
    --base-text-color: #454545;
    --base-border-color: #efecec;
    --base-border-secondary: #dadada;
    --base-bg-color: rgba(0, 0, 0, 0.3);
    --base-success: #8cb82b;
    --base-shadow: 0 2px 21px 0 rgba(0, 0, 0, 0.06);
    --base-ping-group-color: #0086c5;

    --base-padding: 5;
    --base-radius: 5;


    --search-result-h: 150;
    --search-search-input-h: 54;
    --search-result-top: 85;

    --base-input-w: 315;

    --base-small-fz: 14;
    --base-fz: 16;
    --base-fz-17: 17;

    --base-input-padding-x: 34.5px;
    --base-input-padding: 13.5px var(--base-input-padding-x);

    --base-top-control: 198px;
    --base-side-bar-h: 215px;
    --base-z-index:1001;

    --map-h: 660px;
    --map-sidebar-w: 560px;

    @include for-size(break-point-md) {
        --map-h: 800px;
        --base-side-bar-h: 185px;
        --base-top-control: 0px;
        --base-side-bar-h: var(--map-h, 560px);
        --search-result-h: calc(262 - var(--search-search-input-h));
        --base-input-w: 279;
    }

    @include for-size(break-point-lg) {
        --map-h: 560px;
        --base-input-w: 384;
    }
}




/**
 *
 * Map container
 *
 */

.map__container {
    padding-top: calc(var(--base-padding) * 3px);
    color: var(--base-text-color);
    @include for-size(break-point-md) {
        padding-top: unset;
    }
    .checkbox label{
        padding-left: unset;
    }
}

#cityMap {
    font-family: Roboto, Open Sans, Segoe UI, sans-serif;

    @include for-size(break-point-xl) {
        //margin-bottom: calc(var(--base-padding) * 8px);
        background: unset;
    }
}


/*Leaflet override styles*/
.item-selected {
    .leaflet-top.leaflet-right {
        --top: var(--base-top-control);
    }
}

.leaflet {

    &-bar a {
        border-color: var(--base-border-color);
    }

    &-touch .leaflet-control-layers,
    &-touch .leaflet-bar {
        border: unset;

    }

    &-container {
        font-family: inherit;
    }

    &-top.leaflet-left {
        background: var(--base-white);
        padding-block: calc(var(--base-padding) * 2px);

        @include for-size(break-point-md) {
            background: unset;
            padding-block: unset;
        }
    }

    &-right .leaflet-control{
        margin-right: calc(var(--base-padding) * 3px);
        margin-top: calc(var(--base-padding) * 3px);
    }

    &-left {
        right: 0;
    }

    &-top.leaflet-right {
        --top: var(--base-top-control);

        @include for-size(break-point-md) {
            --top: 85px;
            --top: var(--base-top-control);
        }

        top: var(--top);
    }

    &-left .leaflet-control {
        margin: unset;
        padding: unset;
        width: 100%;
        padding-inline: calc(var(--base-padding) * 6px);

        @include for-size(break-point-md) {
            padding-inline: calc(var(--base-padding) * 5px);
            padding-top: calc(var(--base-padding) * 4px);
            max-width: calc(var(--base-padding) * 64.5px);
        }

        @include for-size(break-point-lg) {
            max-width: calc(var(--base-input-w) * 1.1px);
        }
    }

    &-control-layers {
        width: clamp(calc(var(--base-input-w) * 0.6px), calc(100% - 10px), calc(var(--base-input-w) * 1px));
        position: relative;
    }



    &-popup-content-wrapper {
        box-shadow: var(--base-shadow);
        text-align: center;
        color: var(--base-text-color);
        border-radius: unset;

        .location {
            &_name {
                font-size: calc(var(--base-fz) * 1px);
            }

            &_state {
                display: none;
                opacity: 0;
            }

            &_link {
                margin-top: calc(var(--base-padding) * 3px);
            }
        }
    }

    &-popup-close-button {
        display: none;
    }
    &-container{
        a{
            color: inherit;
        }
    }
}




.map_search {

    &__container {
        @include for-size(break-point-md) {
            min-width: calc(var(--base-padding) * 55px);
        }
    }

    &__container,
    &-input__container {
        position: relative;
        border-radius: calc(var(--base-padding) * 6px);
    }

    &-input__container {
        box-shadow: var(--base-shadow);

        @include for-size(break-point-md) {
        }
    }

    &-input {

        &.item-selected,
        &:placeholder-shown {
            border-radius: calc(var(--base-padding) * 6px);
            border-color: transparent;
            box-shadow: unset;
        }

        &.item-selected~.map_search-input__line,
        &:placeholder-shown~.map_search-input__line {
            display: none;
        }

        &.item-selected {
            box-shadow: var(--base-shadow);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: calc(var(--base-padding) * 20px);
        }

        &.item-selected~.map_search-input__close {
            opacity: 1;
            pointer-events: all;
        }

        border-radius: calc(var(--base-padding) * 4px) calc(var(--base-padding) * 4px) 0 0;
        padding: var(--base-input-padding);
        border: unset;

        color: var(--base-text-color);

        width: 100%;
        font-size: calc(var(--base-fz-17) * 1px);
        line-height: calc(var(--base-padding) * 5px);

        @include for-size(break-point-md) {
            max-width: unset;

        }
    }

    &-input__line {
        height: 2px;
        background: var(--base-white);
        display: block;
        &__inner{
            width: 85%;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            background: var(--base-ping-group-color);
            height: 2px;
        }
    }

    &-input__icon {
        position: absolute;
        top: calc(var(--base-padding) * 1px);
        right: var(--base-input-padding-x);
        bottom: calc(var(--base-padding) * 1px);
        width: 28px;
        display: grid;
        place-content: center;
        pointer-events: none;
        background: var(--base-white);
    }

    &-input__close {
        position: absolute;
        top: 0;
        right: calc(var(--base-input-padding-x) + 28px);
        bottom: 0;
        width: 28px;
        display: grid;
        place-content: center;
        pointer-events: none;
        opacity: 0;
        cursor: pointer;
        background: var(--base-white);
        color: var(--base-text-color);
    }


    // Sidebar
    &-sidebar {
        position: absolute;
        inset: 0;
        width: 100%;
        background: var(--base-white);
        z-index: 1000;
        overflow-y: auto;
        overflow-x: hidden;
        height: var(--base-side-bar-h);
        white-space: nowrap;
        color: var(--base-text-color);
        font-size: calc(var(--base-fz) * 1px);

        display: block;

        @include for-size(break-point-md) {
            width: calc(var(--base-padding) * 64.5px);
            display: none;
        }

        @include for-size(break-point-xl) {
            max-width: calc(var(--base-input-w) * 1.1px);
            width: clamp(calc(var(--base-input-w) * 0.7px), 100%, calc(var(--base-input-w) + 1px));
        }
    }
}

.setting__open {
    .map_search-sidebar {
        --base-side-bar-h: 100%;
        z-index: var(--base-z-index);

        // z-index: 9902;
        @include for-size(break-point-md) {

            --base-side-bar-h: var(--map-h, 560px);
            z-index: 1000;
        }
    }
    .map_setting--options{
        top: calc(var(--search-result-top) * 1.20px);
        border-top: 1px solid var(--base-border-color);
        @include for-size(break-point-md) {
            top: calc(var(--search-result-top) * 1.1px);
        }
    }
    .leaflet-top.leaflet-left{
        z-index: var(--base-z-index);
    }
}




/*    Results css  */
.search-results {
    background: var(--base-white);
    border-radius: 0 0 calc(var(--base-padding) * 4px) calc(var(--base-padding) * 4px);
    list-style: none;
    max-height: calc(var(--search-result-h) * 1px);
    overflow-y: scroll;
    position: absolute;
    top: auto;
    left: 0;
    right: 0;
    padding: unset;
    font-size: calc(var(--base-fz) * 1px);
}

.list-item {
    cursor: pointer;
    padding: calc(var(--base-padding) * 2px) var(--base-input-padding-x);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &:hover {
        color: var(--base-ping-group-color);
    }
}

/*Sidebar styles*/
.sidebar_map {

    &--settings,
    &--selected {
        padding: calc(var(--base-padding) * 6px);
        padding-bottom: calc(var(--base-padding) * 4px);

        @include for-size(break-point-md) {
            padding: calc(var(--base-padding) * 4px);
        }
    }

    &--selected {
        display: none;

        @include for-size(break-point-md) {
            display: block;
            padding-top: unset;
        }
    }

    /**
     *
     * Sidebar search settings
     *
     */

    &--settings {
        border-top: 1px solid var(--base-border-color);
        margin-top: calc(var(--search-result-top) * 1.20px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include for-size(break-point-md) {
            margin-top: calc(var(--search-result-top) * 1.1px);

            .settings_item:not(:first-of-type) {
                margin-top: calc(var(--base-padding) * 3.8px);
            }
        }

        @include for-size(break-point-lg) {
            .settings_item:not(:first-of-type) {
                margin-top: unset;
            }

            flex-direction: row;
        }
    }
}



.settings_item {
    display: flex;
    align-items: center;
    opacity: 0.44;
    cursor: pointer;
    margin-bottom: calc(var(--base-padding) * 2px);
    &:last-of-type{
        margin-bottom: unset;
    }

    @include for-size(break-point-md) {
        margin-bottom: unset;
    }

    &--icon {
        padding-right: calc(var(--base-padding) * 2.2px);
    }
}

/**
 *
 * Selected location styles
 *
 */
.location {

    &_name {
        white-space: break-spaces;
    }

    &_link,
    &_name {
        margin-block: calc(var(--base-padding) * 2px);
    }

    &_link,
    &_state,
    &_time {
        font-size: calc(var(--base-small-fz) * 1px);
        font-weight: 300;
        margin-top: calc(var(--base-padding) * .5px);
    }

    &_link a,
    &_link {
        color: var(--base-ping-group-color);
        font-weight: 500;
        margin-top: calc(var(--base-padding) * 1px);
    }
}



/*Setting options */

[data-js="close_settings"] .settings_item {
    opacity: 1;
}

.map_setting--options {
    display: none;
    position: absolute;
    inset: 0;
    top: calc(var(--search-result-top) * 1px);
    background: var(--base-white);
    padding: calc(var(--base-padding) * 6px);
    @include for-size(break-point-md) {
        padding: calc(var(--base-padding) * 4px);
    }

    .checkbox {

        margin-bottom: calc(var(--base-padding) * 3.6px);

        label {
            color: var(--base-text-color);
            opacity: 0.44;
        }
        label::before {
            background-color: var(--base-white);
            border: 1px solid var(--base-border-secondary);
            border-radius: calc(var(--base-padding) * .2px);
            width: calc(var(--base-padding) * 3.6px);
            height: calc(var(--base-padding) * 3.6px);
            margin-right: calc(var(--base-padding) * 2.2px);
        }
        input[type="checkbox"]:checked+label{
            opacity: 1;
        }

        input[type="checkbox"]:checked+label::before {
            color: var(--base-white);
            content:'';
            background-image: url("data:image/svg+xml,%3Csvg width='21px' height='19px' viewBox='0 0 21 19' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3E20F8F0D4-9FB6-44E5-ABDC-2F116A60E34B%3C/title%3E%3Cg id='Teilnehmerkommunen' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Ranking-1440px_Teilnehmerkommunen_Filter_Land' transform='translate(-1188.000000, -1473.000000)' fill='%23FFFFFF'%3E%3Cpath d='M1197.09892,1492 C1196.72257,1492 1196.35681,1491.87521 1196.05635,1491.64412 L1188.68382,1485.96674 C1188.31808,1485.68635 1188.07831,1485.26885 1188.01607,1484.80819 C1187.95385,1484.34754 1188.07374,1483.88072 1188.35145,1483.51098 C1188.92814,1482.73914 1190.01014,1482.58816 1190.77047,1483.17204 L1196.67992,1487.72301 L1205.831,1473.7881 C1206.08443,1473.39986 1206.48053,1473.1287 1206.92971,1473.03471 C1207.38044,1472.94228 1207.84936,1473.03625 1208.2318,1473.29508 C1208.61424,1473.55391 1208.8783,1473.95602 1208.96781,1474.41356 C1209.05583,1474.87268 1208.96174,1475.34718 1208.70527,1475.73389 L1198.53594,1491.21879 C1198.21573,1491.70717 1197.67701,1492 1197.09881,1492 L1197.09892,1492 Z' id='Nutzt_RADar_weiss'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            background-color: var(--base-success);
            background-repeat: no-repeat;
            background-size: 60%;
            background-position: center;
            border-color: var(--base-success);
        }
    }

}
// Need to override the dark theme text
.map__container .map_setting--options .checkbox label{
    color: var(--base-text-color);
    font-weight: normal;
    font-size: calc(var(--base-fz) * 1px);
}
.close-container {
    display: flex;
    justify-content: space-between;
    text-align: right;
    padding: unset;
    cursor: pointer;
    margin-bottom: calc(var(--base-padding) * 8px);
}

/*    Cluster styles*/
.marker-cluster {

    &-medium div,
    &-large,
    &-small,
    &-medium {
        background-color: var(--base-ping-group-color) !important;
        font-family: inherit;
        font-size: calc(var(--base-fz) * 1px);
        font-weight: 500;
    }
}


/*----------  Confirmation styles  ----------*/
.modal-alert{
    max-width: calc(var(--base-padding) * 107px);
}
.confirmation-content{
    padding: calc(var(--base-padding) * 11px) calc(var(--base-padding) * 12px) calc(var(--base-padding) * 5px);
    text-align: center;
    font-size: calc(var(--base-fz) * .9px);
    line-height: calc(var(--base-padding) * 4.5px);
    color: var(--base-text-dark);

}
