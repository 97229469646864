.hall-of-fame {
    h2.section-heading {
        margin-top: 0px;
        font-size: 20px;
    }
    .catInnerWrapper {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-around;
        align-items: start;
        margin-bottom: 90px;
    }
    .hofcity-inner {
        text-align: center;
    }
    .hofcity-logo {
        img {
            min-height: 100px;
            max-height: 100px;
            max-width: 108px;
            object-fit: contain;
            filter: drop-shadow(2px 2px 14px rgba(0,0,0,.28));
            -webkit-filter: drop-shadow(2px 2px 14px rgba(0,0,0,.28));
        }
    }
    .hofcity-name {
        color: #4A4A4A;
        font-family: Roboto;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 22px;
        text-align: center;
        padding: 15px 0px 10px 0px;
        min-width: 150px;
        max-width: 150px;
    }

    // TODO: Remove custom dropdown elements
    //.dropdown.custom-dropdown .dropdown-menu{
    //    min-width: 240px;
    //}
    //
    //#hallOfFameSelectedYearDropdownBtn{
    //    &:hover {
    //        background-color: #0c4967;
    //        color: white;
    //    }
    //
    //    &:focus {
    //        background-color: #0c4967;
    //        color: white;
    //    }
    //}

    .hofcity-population {
        color: rgba(69,69,69,1);
        font-family: Roboto;
        font-size: 12px;
        font-weight: 300;
        letter-spacing: 0.51px;
        line-height: 17px;
        text-align: center;
    }
    #hallOfFameSelectedYearDropdown {
        width: 190px;
    }
    @media (max-width: 768px) {
        h2.section-heading {
            font-size: 19px;
        }
        .hofcity-wrapper {
            margin-bottom: 54px;
            width: 33%;
        }
        .hofcity-name {
            max-width: 100%;
        }
        .hofcity-logo {
            img {
                min-height: 90px;
                max-height: 90px;
            }
        }
        .white-bg {
            .btn.btn-default.dropdown-toggle {
                line-height: 14px;
            }
        }
        .catInnerWrapper {
            margin-bottom: 30px;
        }
    }
    @media (max-width: 480px) {
        h2.section-heading {
            font-size: 19px;
        }
        .hofcity-wrapper {
            margin-bottom: 54px;
            width: 50%;
        }
        .hofcity-name {
            max-width: 100%;
        }
        .catInnerWrapper {
            margin-bottom: 30px;
        }
        .hofcity-logo {
            img {
                min-height: 90px;
                max-height: 90px;
                max-width: 155px;
            }
        }
        #hallOfFameSelectedYearDropdown {
            width: 100%;
        }
    }

    @media (min-width: 768px) {
        .section-heading {
            text-align: center;
        }
    }

    @keyframes slidenavAnimation {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @-webkit-keyframes slidenavAnimation {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

}
