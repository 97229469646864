.alert {
	padding: 15px;
	margin: 20px 0 30px 0;
	border: 1px solid transparent;
	border-radius: 4px;
	h4 {
		margin-top: 0;
		color: inherit;
	}
	.alert-link {
		font-weight: bold;
	}
	>p {
		margin-bottom: 0;
		+ {
			p {
				margin-top: 5px;
			}
		}
	}
	>ul {
		margin-bottom: 0;
	}
}
.alert-dismissable {
	padding-right: 35px;
	.close {
		position: relative;
		top: -2px;
		right: -21px;
		color: inherit;
	}
}
.alert-dismissible {
	padding-right: 35px;
	.close {
		position: relative;
		top: -2px;
		right: -21px;
		color: inherit;
	}
}
.alert-success {
	background-color: #fff;
	border-color: #fff;
	color: #454545;
}
.alert-info {
	background-color: #d9edf7;
	border-color: #bce8f1;
	color: #31708f;
}
.alert-warning {
	background-color: #fcf8e3;
	border-color: #faebcc;
	color: #8a6d3b;
}
.alert-danger {
	background-color: #f2dede;
	border-color: #ebccd1;
	color: #a94442;
}
