.sr-icon-grid {
	display: flex;
	flex-wrap: wrap;
	overflow: hidden;
	border-radius: 12px;
	padding: 0;
	margin: 0 0 30px;
	li {
		flex-grow: 1;
		min-width: 15%;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		padding: 20px 0 15px;
		margin: 2px;
		color: #fefefe;
		flex-basis: 15%;
		background-color: #073B50;
		h3 {
			margin: 0;
			font-size: x-large;
			min-height: 36px;
		}
		p {
			max-width: 85%;
			margin: 0;
			//overflow: hidden;
			//white-space: nowrap;
			//text-overflow: ellipsis;
			font-size: 12px;
			line-height: 14px;
			letter-spacing: 0.18px;
			color: inherit;
			&::before {
				display: block;
				font-size: 35px;
				line-height: 1.7;
				color: #0086C5;
			}
		}
	}
}
html.has_no-flexbox {
	.sr-icon-grid {
		li {
			float: left;
			width: 16.2%;
		}
	}
}
@media (max-width: 991px) {
	.sr-icon-grid {
		li {
			min-width: 15%;
			flex-basis: 120px;
		}
	}
	.sonderaktion-schulradeln {
		.sr-icon-grid {
			li {
				min-width: 15%;
			}
		}
	}
	html.has_no-flexbox {
		.sr-icon-grid {
			li {
				width: 32.8%;
			}
		}
	}
}
@media (max-width: 767px) {
	.sr-icon-grid {
		margin: 0 30px;
		margin: 10px 30px 30px;
		li {
			flex-basis: 140px;
			min-width: 33%;
			max-width: 100%;
		}
	}
	html.has_no-flexbox {
		.sr-icon-grid {
			li {
				width: 48.8%;
			}
		}
	}
}
