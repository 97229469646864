.label {
	display: inline;
	padding: .2em .6em .3em;
	font-size: 75%;
	font-weight: bold;
	line-height: 1;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: .25em;
	&:empty {
		display: none;
	}
}
.btn {
	.label {
		position: relative;
		top: -1px;
	}
}
a.label {
	&:hover {
		color: #fff;
		text-decoration: none;
		cursor: pointer;
	}
	&:focus {
		color: #fff;
		text-decoration: none;
		cursor: pointer;
	}
}
.label-default {
	background-color: #898989;
}
.label-primary {
	background-color: #286AA6;
}
.label-success {
	background-color: #8CB82B;
}
.label-info {
	background-color: #0086C5;
}
.label-warning {
	background-color: #f0ad4e;
}
.label-danger {
	background-color: #d9534f;
}
