.sonderaktion-radpendler {
    h2 {
        font-size: 20px;
    }

    /* Custom URL style for chat */
    .chat-messages {
        .message {
            a {
                color: #fff;
                text-decoration: underline;
                &:hover {
                    color: #fff;
                    text-decoration: underline;
                }
            }
        }
    }
    .info-right-top {
        font-size: 16px;
        position: absolute;
        top: 10px;
        right: 10px;
        opacity: 0.5;
    }

    @media (max-width: 767px) {
        .ui-widget-content {
            border: 0 !important;
            background: rgba(5,40,54,0.88) !important;
            color: #fff;
            color: rgba(255,255,255,1);
            font-family: Roboto;
            font-size: 12px;
            font-weight: 300;
            letter-spacing: 0.56px;
            line-height: 16px;
            text-shadow: 0 2px 31px 0 rgba(0,0,0,0.79);
        }
    }

    @media (max-width: 480px) {

        section.light-bg {
            padding-left: 15px;
            padding-right: 15px;
        }
        section.bright-bg {
            padding-left: 15px;
            padding-right: 15px;
        }

    }

    .report-user {
        right: 25px;
        bottom: 0;
        top: auto;
    }
    .profile-card {
        padding-bottom: 40px;
    }

    .btn.btn-default.dropdown-toggle {
        background-color: #fff;
        color: #454545;
    }
    section.light-bg {
        padding-top: 20px;
    }

    @media (max-width: 991px) {
        .mobile-nav .dropdown .dropdown-toggle {
            margin-top: 20px;
        }
    }

    @media (max-width: 480px) {
        .sr-icon-grid {
            li.center-flex {
                flex-basis: 100%;
            }
        }
        .dark-bg {
            .btn.btn-default.dropdown-toggle {
                font-size: 14px !important;
            }
        }
        .top10btn {
            .btn.btn-default.dropdown-toggle {
                font-size: 14px !important;
            }
        }
        .mobile-nav {
            .dropdown {
                max-width: 340px;
            }
        }
        .mobile-nav-pad {
            padding-bottom: 50px !important;
        }
    }

    .icon-cycle {
        &:before {
            font-size: 39px !important;
        }
    }
    .city_contact_box {
        a {
            font-weight: 500;
        }
    }
    .img-responsive {
        content: '';
    }

}
