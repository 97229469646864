@charset "UTF-8";

/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/roboto/roboto-v20-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Roboto Light'), local('Roboto-Light'),
       url('../fonts/roboto/roboto-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto/roboto-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto/roboto-v20-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto/roboto-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto/roboto-v20-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto/roboto-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto'), local('Roboto-Regular'),
       url('../fonts/roboto/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto/roboto-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto/roboto-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto/roboto-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto/roboto-v20-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/roboto/roboto-v20-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Roboto Medium'), local('Roboto-Medium'),
       url('../fonts/roboto/roboto-v20-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto/roboto-v20-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto/roboto-v20-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto/roboto-v20-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto/roboto-v20-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/roboto/roboto-v47-latin-600.eot'); /* IE9 Compat Modes */
  src: url('../fonts/roboto/roboto-v47-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto/roboto-v47-latin-600.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
       url('../fonts/roboto/roboto-v47-latin-600.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
       url('../fonts/roboto/roboto-v47-latin-600.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
       url('../fonts/roboto/roboto-v47-latin-600.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/roboto/roboto-v20-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Roboto Bold'), local('Roboto-Bold'),
       url('../fonts/roboto/roboto-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto/roboto-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto/roboto-v20-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto/roboto-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto/roboto-v20-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/montserrat/montserrat-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
       url('../fonts/montserrat/montserrat-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/montserrat/montserrat-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/montserrat/montserrat-v14-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/montserrat/montserrat-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/montserrat/montserrat-v14-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-500 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/montserrat/montserrat-v14-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
       url('../fonts/montserrat/montserrat-v14-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/montserrat/montserrat-v14-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/montserrat/montserrat-v14-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/montserrat/montserrat-v14-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/montserrat/montserrat-v14-latin-500.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/montserrat/montserrat-v14-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
       url('../fonts/montserrat/montserrat-v14-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/montserrat/montserrat-v14-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/montserrat/montserrat-v14-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/montserrat/montserrat-v14-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/montserrat/montserrat-v14-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'sr-icons';
  src: url("../fonts/sr-icons.eot?xi4nvg");
  src: url("../fonts/sr-icons.eot?xi4nvg#iefix") format("embedded-opentype"), url("../fonts/sr-icons.ttf?xi4nvg") format("truetype"), url("../fonts/sr-icons.woff?xi4nvg") format("woff"), url("../fonts/sr-icons.svg?xi4nvg#sr-icons") format("svg");
  font-weight: normal;
  font-style: normal; 
}