section {
    &.dashboard {
        color: #fefefe;
    }
}

.dashboard {
	justify-content: center;
	padding: 0;
	background-color: #063547;
	color: #fefefe;
	.alert {
		padding-left: 20px;
	}
	>nav {
		width: calc(120px + 10vw);
		&::before {
			height: 100%;
			width: 100vw;
			position: absolute;
			top: 0;
			bottom: 0;
			left: -100vw;
			content: '';
			background-color: #073B50;
		}
		.profile-card {
			padding: 0 0 10px;
			background-color: transparent;
			.user-card {
				margin-top: 0;
			}
			.profile-big {
				img {
					box-shadow: 0 0 0 1.5px #fff;
				}
			}
			h3 {
				margin: 14px 0;
				font-size: 17px;
			}
		}
		.sidebar-menu {
			ul {
				margin-bottom: 50px;
				list-style: none;
				li {
					margin: 0;
					color: #aaa;
				}
				li.menu-header {
					line-height: 1.3;
					padding: 10px;
				}
			}
		}
	}
	>main {
		[class*='arrow-link-'] {
			a {
				display: block;
				&::after {
					content: "";
					display: inline-block;
					text-align: center;
					vertical-align: middle;
					font-family: sr-icons;
					font-size: 100%;
					line-height: 1;
					font-weight: normal;
					text-rendering: auto;
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
					position: absolute;
					right: 0;
				}
			}
			a.arrow-link-external {
				a {
					&::after {
						content: "\e935";
					}
				}
			}
			a.arrow-link-internal {
				a {
					&::after {
						content: "\e936";
					}
				}
			}
		}
		.grid-flex {
			margin-left: -15px;
			.col {
				>h3 {
					+ {
						* {
							min-height: 296px;
							overflow: hidden;
							border-radius: 7px;
						}
					}
				}
			}
		}
	}
	.col {
		>h3 {
			padding-bottom: 15px;
			margin-top: 0;
			position: relative;
			a {
				max-width: 280px;
				overflow: hidden;
				text-overflow: ellipsis;
				color: #fff;
			}
		}
		.img-responsive {
			width: 100%;
			height: auto;
		}
	}
	.sr-icon-grid {
		margin: 0;
		border-radius: 15px !important;
		h3 {
			padding: 0;
			white-space: nowrap;
			font-size: 26px;
			line-height: 36px;
			letter-spacing: 0.22px;
		}
	}
	.boxed-icon {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		height: 100%;
		font-size: 120px;
		text-align: center;
		background: #8CB82B;
		border: 3px solid #063547;
		border-radius: 10px !important;
		&:hover {
			background: #7ca326;
		}
		a {
			height: 100%;
			width: 100%;
			position: absolute;
		}
	}
	.list-group {
		margin-bottom: 0;
		.list-group-item {
			max-height: 60px;
			border-width: 1px 0;
			padding: 15px;
			line-height: 1.9;
			overflow: hidden;
			color: #eee;
			&:first-of-type {
				border-top-width: 0;
			}
			.profile-xs {
				margin-right: 10px;
				display: inline;
				img {
					display: inline-block;
				}
			}
			.name {
				display: inline-block;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				max-width: 50%;
				margin: 0 auto -0.50px 0;
				font-size: 12px;
				line-height: 1.5;
				small {
					font-size: 11px;
					font-weight: 300;
				}
			}
			.km-count {
				float: right;
				max-width: 48%;
				margin-right: 15px;
				font-size: 16px;
				small {
					font-size: 11px;
				}
			}
			.teamposition {
				height: 28px;
				width: 28px;
				position: absolute;
				top: -3px;
				right: -3px;
				padding: 10px;
				font-size: 11px;
			}
		}
	}
	.list-group.overflow-ellipsis {
		.list-group-item {
			padding: 16px;
			line-height: 1.8;
			.name {
				line-height: 1.2;
			}
		}
	}
	.text-box {
		padding: 20px;
		background-color: #073B50;
		font-weight: 300;
		p {
			font-size: 12px;
			letter-spacing: 0.4px;
		}
		p.date {
			margin-bottom: 0.50px;
			opacity: 0.7;
		}
		h6 {
			margin: 0 0 15px;
			font-weight: 500;
		}
	}
	.text-box.umfrage-box {
		background-color: #0086C5;
		.image-container {
			margin: -20px -20px 20px;
			img {
				max-width: 100%;
			}
		}
	}
	.nav-pills {
		max-width: 320px;
	}
}
.has_no-flexbox {
	.dashboard {
		.boxed-icon {
			line-height: 296px;
		}
	}
}
@media (min-width: 992px) {
	.dashboard {
		>nav {
			padding: 90px 0;
			background-color: #073B50;
			>* {
				max-width: 250px;
				margin-left: auto;
			}
			.sidebar-menu {
				ul {
					li {
						a {
							color: white;
							line-height: 1.3;
							padding: 10px;
							&:hover {
								text-decoration: none;
								color: #fff;
								background: none;
								background-color: rgba(255, 255, 255, 0.05);
							}
							&:focus {
								text-decoration: none;
								color: #fff;
								background: none;
							}
						}
						a.active {
							text-decoration: none;
							color: #fff;
							background: none;
							span {
								border-bottom: 1px solid #fff;
								padding-bottom: 3px;
							}
						}
					}
				}
			}
			.dropdown-menu {
				position: static;
				background-color: transparent;
				border: 0;
				max-height: 100%;
				opacity: 1;
				box-shadow: none;
				display: block;
			}
			.dropdown-toggle {
				display: none !important;
			}
		}
		>main {
			padding: 90px 0;
			padding-left: 90px;
			max-width: 85vw;
		}
	}
}
@media (max-width: 991px) {
	.dashboard {
		>nav {
			width: 100%;
		}
		>main {
			padding: 10px 15px;
			.grid-flex {
				.col {
					>h3 {
						+ {
							* {
								min-width: 296px;
							}
						}
					}
				}
			}
		}
	}
}
@media (min-width: 1201px) {
	.dashboard {
		>main {
			width: 60%;
			width: calc(580px + 15vw);
		}
	}
}
@media (max-width: 767px) {
	.dashboard {
		.col {
			iframe {
				width: 315px;
				background: white;
			}
		}
	}
}
@media (min-width: 768px) {
	//dashboard.scss
	.dashboard {
		.sr-icon-grid {
			>li {
				flex-basis: 33%;
			}
		}
	}
}
