/* Redesign 2024 - foundations */

// typography
h1, h2, h3, h4, h5 {
    font-weight: $fw-default;
    line-height: $lh-default;
}
.text-large {
    font-size: $text-large;
    //@apply text-lg;
}
p, 
.text-default,
.text-medium {
    font-size: $text-medium;
    line-height: $text-lh-default;
}
.text-small {
    font-size: $text-small;
}

.sr-img-fluid {
    max-width: 100%;
    height: auto;
}

.border-radius {
    border-radius: $border-radius;
}

// colors 
.sr-green {
    color: $sr-green;
}
.bg-green {
    background-color: $sr-green;
}
.sr-blue {
    color: $sr-blue;
}
.bg-blue {
    background-color: $sr-blue;
}
.bg-dark {
    background-color: $blue98;
    color: $sr-white;
}
.sr-black {
    color: $sr-black;
}
.bg-black {
    background-color: $sr-black;
}
.sr-white {
    color: $sr-white;
}
.bg-white {
    background-color: $sr-white;
}
.bg-grey {
    background-color: $grey20; 
}

// green
.green-99 {
    color: $green99;
}
.green-98 {
    color: $green98;
}
.green-95 {
    color: $green95;
}
.green-90 {
    color: $green90;
}
.green-80 {
    color: $green80;
}
.green-70 {
    color: $green70;
}
.green-60 {
    color: $green60;
}
.green-50 {
    color: $green50;
}
.green-40 {
    color: $green40;
}
.green-35 {
    color: $green35;
}
.green-30 {
    color: $green30;
}
.green-25 {
    color: $green25;
}
.green-20 {
    color: $green20;
}
.green-10 {
    color: $green10;
}
.bg-green-99 {
    background-color: $green99;
}
.bg-green-98 {
    background-color: $green98;
}
.bg-green-95 {
    background-color: $green95;
}
.bg-green-90 {
    background-color: $green90;
}
.bg-green-80 {
    background-color: $green80;
}
.bg-green-70 {
    background-color: $green70;
}
.bg-green-60 {
    background-color: $green60;
}
.bg-green-50 {
    background-color: $green50;
}
.bg-green-40 {
    background-color: $green40;
}
.bg-green-35 {
    background-color: $green35;
}
.bg-green-30 {
    background-color: $green30;
}
.bg-green-25 {
    background-color: $green25;
}
.bg-green-20 {
    background-color: $green20;
}
.bg-green-10 {
    background-color: $green10;
}

// blue
.blue-99 {
    color: $blue99;
}
.blue-98 {
    color: $blue98;
}
.blue-95 {
    color: $blue95;
}
.blue-90 {
    color: $blue90;
}
.blue-80 {
    color: $blue80;
}
.blue-70 {
    color: $blue70;
}
.blue-60 {
    color: $blue60;
}
.blue-50 {
    color: $blue50;
}
.blue-40 {
    color: $blue40;
}
.blue-35 {
    color: $blue35;
}
.blue-30 {
    color: $blue30;
}
.blue-25 {
    color: $blue25;
}
.blue-20 {
    color: $blue20;
}
.blue-10 {
    color: $blue10;
}
.bg-blue-99 {
    background-color: $blue99;
}
.bg-blue-98 {
    background-color: $blue98;
}
.bg-blue-95 {
    background-color: $blue95;
}
.bg-blue-90 {
    background-color: $blue90;
}
.bg-blue-80 {
    background-color: $blue80;
}
.bg-blue-70 {
    background-color: $blue70;
}
.bg-blue-60 {
    background-color: $blue60;
}
.bg-blue-50 {
    background-color: $blue50;
}
.bg-blue-40 {
    background-color: $blue40;
}
.bg-blue-35 {
    background-color: $blue35;
}
.bg-blue-30 {
    background-color: $blue30;
}
.bg-blue-25 {
    background-color: $blue25;
}
.bg-blue-20 {
    background-color: $blue20;
}
.bg-blue-10 {
    background-color: $blue10;
}

// grey
.grey-99 {
    color: $grey99;
}
.grey-98 {
    color: $grey98;
}
.grey-95 {
    color: $grey95;
}
.grey-90 {
    color: $grey90;
}
.grey-80 {
    color: $grey80;
}
.grey-70 {
    color: $grey70;
}
.grey-60 {
    color: $grey60;
}
.grey-50 {
    color: $grey50;
}
.grey-40 {
    color: $grey40;
}
.grey-35 {
    color: $grey35;
}
.grey-30 {
    color: $grey30;
}
.grey-25 {
    color: $grey25;
}
.grey-20 {
    color: $grey20;
}
.grey-10 {
    color: $grey10;
}
.bg-grey-99 {
    background-color: $grey99;
}
.bg-grey-98 {
    background-color: $grey98;
}
.bg-grey-95 {
    background-color: $grey95;
}
.bg-grey-90 {
    background-color: $grey90;
}
.bg-grey-80 {
    background-color: $grey80;
}
.bg-grey-70 {
    background-color: $grey70;
}
.bg-grey-60 {
    background-color: $grey60;
}
.bg-grey-50 {
    background-color: $grey50;
}
.bg-grey-40 {
    background-color: $grey40;
}
.bg-grey-35 {
    background-color: $grey35;
}
.bg-grey-30 {
    background-color: $grey30;
}
.bg-grey-25 {
    background-color: $grey25;
}
.bg-grey-20 {
    background-color: $grey20;
}
.bg-grey-10 {
    background-color: $grey10;
}

// border
.sr-border-radius {
    border-radius: $border-radius-80;
}
.sr-border {
    border: 1px solid $grey25;
}
.border-4 {
    border-radius: $border-radius-4;
}
.border-8 {
    border-radius: $border-radius-8;
}
.border-16 {
    border-radius: $border-radius-16;
}
.border-32 {
    border-radius: $border-radius-32;
}
.border-64 {
    border-radius: $border-radius-64;
}
.border-80 {
    border-radius: $border-radius-80;
}
.border-96 {
    border-radius: $border-radius-96;
}
.border-full {
    border-radius: 30px;
}

// shadow
.shadow-1 {
    box-shadow: $box-shadow-1;
}
.shadow-2 {
    box-shadow: $box-shadow-2;
}
.shadow-3 {
    box-shadow: $box-shadow-3;
}

.sr-module {
    margin-bottom: 5rem;
    opacity: 0;
    //transform: translateY(100px); , transform .5s;
    transition: opacity 1s;
    &.indented {
        margin-left: .5rem;
        margin-right: .5rem;
        overflow: hidden;
        border-radius: var(--sr-border-radius-8, 0.5rem) var(--sr-border-radius-8, 0.5rem) var(--sr-border-radius-96, 6rem) var(--sr-border-radius-8, 0.5rem);
    }
    &.sr-reduced-spacing {
        margin-bottom: 3rem;
    }
    a:not(.sr-btn) {
        font-size: 1rem;
    }
}

// laptop/desktop
@media (min-width: 1024px) {
    .sr-border-radius {
        border-radius: $border-radius-96;
    }

    .sr-module {
        margin-bottom: 12rem;
        &.indented {
            border-radius: var(--sr-border-radius-8, 0.5rem) var(--sr-border-radius-8, 0.5rem) var(--sr-border-radius-80, 5rem) var(--sr-border-radius-8, 0.5rem);
        }
        &.sr-reduced-spacing {
            margin-bottom: 4rem;
        }
    }
}

@media (min-width: 1440px) {
    .sr-module {
        &.indented {
            margin-left: 1rem;
            margin-right: 1rem;
        }
    }
}

//media queries redesign 2024
/*
all styles <768px global
// tablet
@media (min-width: 768px) {}
// laptop/desktop
@media (min-width: 1024px) {}
// desktop xl
@media (min-width: 1440px) {}
*/