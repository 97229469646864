.Searchbox {
	position: relative;
	margin-top: 0;
	z-index: 1;
	input {
		background-color: #fff;
	}
}
.home-content {
	.sr-icon-grid {
		li {
			letter-spacing: 0.1px;
		}
	}
	h2 {
		margin-bottom: 60px;
		text-align: center;
	}
	.tab-pane {
		h3 {
			display: inline-block;
			padding-bottom: 10px;
			border-bottom: 1px solid;
			margin-left: 180px;
		}
	}
	.icon-list {
		padding: 0;
		margin: 40px 0;
		text-align: center;
		list-style-position: inside;
		li {
			position: relative;
			margin: 120px 0 50px;
			font-size: 16px;
			line-height: 2;
			white-space: nowrap;
			p {
				font-size: 14px;
				line-height: 1.3;
				font-weight: 300;
				margin-top: 10px;
				text-align: left;
				white-space: normal;
			}
			&::before {
				position: absolute;
				left: 50%;
				top: -100px;
				transform: translateX(-50%);
				font-size: 60px;
			}
		}
	}
}
.shop-index__section.blue {
	background-color: #063548;
	color: #fff;
	padding-top: 0px;
	padding-bottom: 10px;
}
.shop-index__section.middleblue {
	background-color: #0E4761;
	color: #fff;
	padding-top: 0px;
	padding-bottom: 30px;
}
.section__inner {
	padding: 60px 0;
}
@media (max-width: 1024px) {
	.Searchbox {
		margin-top: -20px;
	}
}
@media (max-width: 991px) {
	.Searchbox {
		margin-top: -40px;
	}
}
@media (max-width: 767px) {
	.Searchbox {
		margin-top: 0;
	}
	.section__inner {
		padding: 30px 0;
	}
	.home-content {
		h2 {
			margin-left: 30px;
			text-align: left;
		}
		h2.mb-60 {
			margin: 20px 0;
		}
	}
}
@media (min-width: 768px) {
	.shop-index__section.middleblue {
		padding-bottom: 0;
	}
	.home-content {
		.tab-content {
			>.tab-pane {
				display: block;
				&:first-of-type {
					margin-bottom: 120px;
				}
			}
		}
		.icon-list {
			margin: 40px 0 40px 150px;
			text-align: left;
			li {
				padding-right: 40px;
				margin: 30px;
				p {
					min-height: 60px;
					font-size: 12px;
					line-height: 1.5;
				}
				&::before {
					left: -15%;
					top: -20px;
				}
			}
		}
	}
}
@media (min-width: 992px) {
	.home-content {
		.tab-pane {
			button {
				margin-left: 100px;
			}
		}
		.icon-list {
			margin: 40px 0 40px 70px;
		}
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.home-content {
		.tab-pane {
			h3 {
				position: relative;
				left: 50%;
				margin-left: 0;
				transform: translateX(-50%);
			}
		}
	}
}
