.auswertung-kommune{
    .status {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #052836;
        margin: 0 auto;
    }
    .status.status-green {
        background-color: #8CB82B;
    }
    .status.status-red {
        background-color: #D0021B;
    }
    .status.status-yellow {
        background-color: #F8E71C;
    }
    .status.status-orange {
        background-color: #F96C0A;
    }
    .status-small {
        &:before {
            content: "";
            display: block;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            float: left;
            margin: 3px 6px 0 0;
        }
    }
    .status-small.status-green {
        &:before {
            background-color: #8CB82B;
        }
    }
    .status-small.status-red {
        &:before {
            background-color: #D0021B;
        }
    }
    .status-small.status-yellow {
        &:before {
            background-color: #F8E71C;
        }
    }
    .status-small.status-orange {
        &:before {
            background-color: #F96C0A;
        }
    }
    .dataTables_filter {
        display: none;
    }
    .arrow-invert {
        .arrow-links {
            li {
                a {
                    &::before {
                        float: left;
                        margin-right: 3px;
                        transform: rotate(-135deg);
                    }
                    &:hover {
                        &::before {
                            transform: rotate(-90deg);
                        }
                    }
                }
            }
        }
    }
    table.sr-ranking {
        span.aktiv {
            &::after {
                content: none;
            }
        }
        tbody {
            >tr {
                td {
                    font-size: 20px;
                    color: rgba(255,255,255,0.87) !important;
                }
                td.align-middle {
                    overflow: visible;
                }
            }
        }
    }
    .text-15 {
        font-size: 15px !important;
    }
    @media (min-width: 768px) {
        table.sr-ranking {
            tbody {
                >tr {
                    >td {
                        &:first-of-type {
                            span {
                                height: 40px;
                                width: 40px;
                                display: block;
                                padding: 15px 20px 15px 14px;
                                border-radius: 50%;
                                margin-left: 2px;
                                background: #052836;
                                font-size: 15px;
                                font-weight: 500;
                                line-height: 12px;
                            }
                        }
                    }
                }
            }
        }
        .dropdown-col {
            margin-top: -65px;
        }
    }

    .sort-buttons {
        > label {
            font-weight: 500;
            font-size: 11px;
            line-height: 13px;
            letter-spacing: 0.3px;
            color: rgba(255, 255, 255, 0.4);
        }
    }
    .tableSortXs {
        .btn {
            padding-left: inherit;
            padding-right: inherit;
            padding-top: 4px;
            padding-bottom: 4px;
            border: 1px solid rgba(0, 134, 197, 0.4) !important;
            font-weight: 500;
            font-size: 11px;
            line-height: 13px;
            letter-spacing: 0.2px;
            color: rgba(255, 255, 255, 0.7) !important;
            height: 25px;
        }
        .btn.btn-default.active {
            background-color: rgba(2,27,38,0.8) !important;
            border: 1px solid rgba(0, 134, 197, 0.4) !important;
            color: rgba(255, 255, 255, 0.9) !important;
        }
    }
    .aktive-radelnde {
        margin-left: 15px;
    }
    .data-unit {
        font-size: 13px;
    }
    table.sr-ranking {
        tbody {
            >tr {
                border-bottom: 1px solid #052836;
            }
        }
    }
    @media (max-width: 767px) {
        table.sr-ranking {
            tbody {
                >tr {
                    >td.radelnde {
                        position: absolute;
                        left: 0;
                        margin-top: 24px;
                        font-size: 11px;
                        opacity: 0.7;
                    }
                    >td.merge-left {
                        position: absolute;
                        left: 0;
                        margin-top: 24px;
                        font-size: 11px;
                        opacity: 0.7;
                    }
                }
            }
        }
        .social-links {
            line-height: 1;
            text-indent: -220vw;
        }
    }

    /* NumberOfResults */

    .numberOfResults {
        >div {
            float: left;
            display: inline-table;
            margin-right: 10px;
            color: rgba(255, 255, 255, 0.5);
            cursor: pointer;
        }
        >span {
            float: left;
            display: inline-table;
            margin-right: 10px;
            color: rgba(255, 255, 255, 0.5);
        }
        margin-top: -120px;
        font-weight: bold;
        margin-bottom: 130px;
        >div.active {
            cursor: pointer;
            color: #0086C5;
        }
    }
    @media (max-width: 767px) {
        .numberOfResults {
            margin-top: -230px;
            font-weight: bold;
            margin-bottom: 130px;
        }
        .dropdown-col {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
    @media (max-width: 480px) {
        .numberOfResults {
            margin-top: -230px;
            >div {
                padding: 0 5px;
            }
        }
    }

    /* Pagination */
    div.dataTables_paginate {
        text-align: center !important;
        padding-top: 70px;
        padding-bottom: 35px;
    }
    .dataTables_length {
        display: none;
    }
    .paginate_button.current {
        color: #0086C5 !important;
        font-weight: 600;
    }
    .paginate_button {
        font-size: 18px;
        position: relative;
    }
    .pagination-icon {
        height: 25px;
        fill: white;
        position: absolute;
        top: 8px;
        left: 0;
    }
    @media (max-width: 479px) {
        .paginate_button.first {
            display: inline;
        }
        .paginate_button.previous {
            display: inline;
        }
        .paginate_button.next {
            display: inline;
        }
        .paginate_button.last {
            display: inline;
        }
        .paginate_button {
            font-size: 18px;
        }
    }

    /* End Pagination */

    /* Nav Pill Style */
    .nav-pills {
        .btn {
            padding-bottom: 8px;
            padding-top: 8px;
            font-size: 11px !important;
            color: #fff;
        }
        .active {
            .btn {
                border-color: #8CB82B !important;
                font-weight: 600;
            }
        }
    }
    /* End Nav Pill Style */

    .auswertung {
        .nav-pills {
            width: 250px;
            margin: 0 auto;
        }
        .custom-dropdown {
            width: 250px;
        }
        .th-search {
            width: auto;
        }
        #staedteliste_filter {
            width: 250px;
        }
    }
    .tab-pane {
        padding-top: 0;
    }
    .sort-buttons {
        .btn-group {
            margin-bottom: 0;
        }
    }
    .visible-xs {
        z-index: 999;
    }
    .xs-ff-fix-min-width {
        min-width: 96px;
    }
    @media (max-width: 768px) {
        .xs-no-gutters {
            width: 100%;
            padding-left: 15px;
            margin: -15px;
        }
        .dataTables_empty {
            width: 100%;
        }
        .auswertung {
            .nav-pills {
                width: 50%;
                margin: 0;
            }
            .custom-dropdown {
                width: 100%;
            }
            .th-search {
                width: 100%;
                padding-left: 0px;
                margin-bottom: 30px;
            }
            #staedteliste_filter {
                width: 100%;
            }
        }
        .data-table {
            tbody {
                >tr {
                    .bar_wrapper {
                        .bar_text {
                            position: inherit;
                        }
                    }
                }
            }
        }
        .sr-ranking-link {
            padding-right: 15px;
        }
        // fix track position
        .data-table .tracks {
            position: absolute;
            top: 20px;
        }
    }
    @media (max-width: 480px) {
        .auswertung {
            .nav-pills {
                width: 100%;
            }
        }
        table#auswertungKommune {
            thead {
                .w-40 {
                    min-width: 140px;
                }
            }
            tbody {
                >tr {
                    >td.teamname {
                        padding: 33px 0 0 45px;
                    }
                    >td.radelnde {
                        padding: 15px 0 0 45px;
                    }
                }
            }
        }
        table#auswertungLk {
            tbody {
                >tr {
                    >td.teamname {
                        padding: 38px 0 0 10px;
                    }
                }
            }
        }
        .aktive-radelnde {
            margin-top: 20px;
            margin-left: 0;
        }
        #sr-city-results {
            .sort-buttons {
                margin-top: 30px;
            }
        }
        #auswertungKommune {
            thead {
                visibility: collapse;
            }
        }
    }
    /* browser specific styles */

    /* chrome */
    @media all and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) {
        .registrierte-radelnde {
            margin-top: 15px;
        }
        @media (max-width: 767px) {
            table#auswertungKommune {
                tbody {
                    >tr {
                        >td.teamname {
                            padding: 20px 0 0 0;
                            left: -7.5px;
                        }
                        >td.radelnde {
                            padding: 0 0 0 47.5px;
                            left: -18px;
                        }
                        .bar_wrapper {
                            left: 28vw;
                        }
                    }
                }
            }
            .aktive-radelnde {
                margin-top: 20px;
                margin-left: 0;
            }

        }
    }
    /* firefox */
    @-moz-document url-prefix() {
        @media (max-width: 480px) {
            table#auswertungKommune {
                tbody {
                    >tr {
                        >td.teamname {
                            padding: 18px 0 0 0;
                        }
                        >td.radelnde {
                            padding: 15px 0 0 43px;
                            left: -8px;
                        }
                    }
                }
            }
            table#auswertungLk {
                tbody {
                    >tr {
                        >td.teamname {
                            padding: 28px 0 0 0;
                            left: -7.5px;
                        }
                    }
                }
            }
            .aktive-radelnde, .registrierte-radelnde {
                margin-top: 0;
                margin-left: -4px;
            }

        }
    }
    /* safari */
    @supports (background: -webkit-named-image(i)) {
        @media (max-width: 767px) {
            table#auswertungLk {
                tbody {
                    >tr {
                        >td.teamname {
                            padding: 28px 10px 0 0;
                            padding: 28px 0 0 40px;
                        }
                    }
                }
            }
            table#auswertungKommune {
                tbody {
                    >tr {
                        >td.teamname {
                            left: 34px !important;
                        }
                        .bar_wrapper {
                            left: 120px;
                        }
                        >td.radelnde {
                            left: -18px;
                        }
                    }
                }
            }
            .aktive-radelnde {
                left: -13px !important;
            }

        }
    }
}
