.btn-default {
	&:focus {
		color: #454545;
		background-color: rgba(0, 0, 0, 0);
		border-color: #050505;
	}
	&:hover {
		color: #454545;
		background-color: rgba(0, 0, 0, 0);
		border-color: #262626;
	}
	&:active {
		color: #454545;
		background-color: rgba(0, 0, 0, 0);
		border-color: #262626;
		background-image: none;
		&:hover {
			color: #454545;
			background-color: rgba(0, 0, 0, 0);
			border-color: #050505;
		}
		&:focus {
			color: #454545;
			background-color: rgba(0, 0, 0, 0);
			border-color: #050505;
		}
	}
	&:active.focus {
		color: #454545;
		background-color: rgba(0, 0, 0, 0);
		border-color: #050505;
	}
	.badge {
		color: transparent;
		background-color: #454545;
	}
}
.btn-default.focus {
	color: #454545;
	background-color: rgba(0, 0, 0, 0);
	border-color: #050505;
}
.btn-default.active {
	color: #454545;
	background-color: rgba(0, 0, 0, 0);
	border-color: #262626;
	background-image: none;
	&:hover {
		color: #454545;
		background-color: rgba(0, 0, 0, 0);
		border-color: #050505;
	}
	&:focus {
		color: #454545;
		background-color: rgba(0, 0, 0, 0);
		border-color: #050505;
	}
}
.open {
	>.btn-default.dropdown-toggle {
		color: #454545;
		background-color: rgba(0, 0, 0, 0);
		border-color: #262626;
		background-image: none;
		&:hover {
			color: #454545;
			background-color: rgba(0, 0, 0, 0);
			border-color: #050505;
		}
		&:focus {
			color: #454545;
			background-color: rgba(0, 0, 0, 0);
			border-color: #050505;
		}
	}
	>.btn-default.dropdown-toggle.focus {
		color: #454545;
		background-color: rgba(0, 0, 0, 0);
		border-color: #050505;
	}
	>.btn-primary.dropdown-toggle {
		color: #fff;
		background-color: #6d8f21;
		border-color: #57721b;
		background-image: none;
		&:hover {
			color: #fff;
			background-color: #57721b;
			border-color: #2e3c0e;
		}
		&:focus {
			color: #fff;
			background-color: #57721b;
			border-color: #2e3c0e;
		}
	}
	>.btn-primary.dropdown-toggle.focus {
		color: #fff;
		background-color: #57721b;
		border-color: #2e3c0e;
	}
	>.btn-success.dropdown-toggle {
		color: #fff;
		background-color: #6d8f21;
		border-color: #6d8f21;
		background-image: none;
		&:hover {
			color: #fff;
			background-color: #57721b;
			border-color: #57721b;
		}
		&:focus {
			color: #fff;
			background-color: #57721b;
			border-color: #57721b;
		}
	}
	>.btn-success.dropdown-toggle.focus {
		color: #fff;
		background-color: #57721b;
		border-color: #57721b;
	}
	>.btn-info.dropdown-toggle {
		color: #fff;
		background-color: #006392;
		border-color: #004b6e;
		background-image: none;
		&:hover {
			color: #fff;
			background-color: #004b6e;
			border-color: #001e2c;
		}
		&:focus {
			color: #fff;
			background-color: #004b6e;
			border-color: #001e2c;
		}
	}
	>.btn-info.dropdown-toggle.focus {
		color: #fff;
		background-color: #004b6e;
		border-color: #001e2c;
	}
	>.btn-warning.dropdown-toggle {
		color: #fff;
		background-color: #ec971f;
		border-color: #d58512;
		background-image: none;
		&:hover {
			color: #fff;
			background-color: #d58512;
			border-color: #985f0d;
		}
		&:focus {
			color: #fff;
			background-color: #d58512;
			border-color: #985f0d;
		}
	}
	>.btn-warning.dropdown-toggle.focus {
		color: #fff;
		background-color: #d58512;
		border-color: #985f0d;
	}
	>.btn-danger.dropdown-toggle {
		color: #fff;
		background-color: #c9302c;
		border-color: #ac2925;
		background-image: none;
		&:hover {
			color: #fff;
			background-color: #ac2925;
			border-color: #761c19;
		}
		&:focus {
			color: #fff;
			background-color: #ac2925;
			border-color: #761c19;
		}
	}
	>.btn-danger.dropdown-toggle.focus {
		color: #fff;
		background-color: #ac2925;
		border-color: #761c19;
	}
}
.btn-default.active.focus {
	color: #454545;
	background-color: rgba(0, 0, 0, 0);
	border-color: #050505;
}
.btn-default.disabled {
	&:hover {
		background-color: transparent;
		border-color: #454545;
	}
	&:focus {
		background-color: transparent;
		border-color: #454545;
	}
}
.btn-default.disabled.focus {
	background-color: transparent;
	border-color: #454545;
}
.btn-default[disabled] {
	&:hover {
		background-color: transparent;
		border-color: #454545;
	}
	&:focus {
		background-color: transparent;
		border-color: #454545;
	}
}
.btn-default[disabled].focus {
	background-color: transparent;
	border-color: #454545;
}
fieldset[disabled] {
	.btn-default {
		&:hover {
			background-color: transparent;
			border-color: #454545;
		}
		&:focus {
			background-color: transparent;
			border-color: #454545;
		}
	}
	.btn-default.focus {
		background-color: transparent;
		border-color: #454545;
	}
	.btn-primary {
		&:hover {
			background-color: #8CB82B;
			border-color: #7ca326;
		}
		&:focus {
			background-color: #8CB82B;
			border-color: #7ca326;
		}
	}
	.btn-primary.focus {
		background-color: #8CB82B;
		border-color: #7ca326;
	}
	.btn-success {
		&:hover {
			background-color: #8CB82B;
			border-color: #8CB82B;
		}
		&:focus {
			background-color: #8CB82B;
			border-color: #8CB82B;
		}
	}
	.btn-success.focus {
		background-color: #8CB82B;
		border-color: #8CB82B;
	}
	.btn-info {
		&:hover {
			background-color: #0086C5;
			border-color: #0075ac;
		}
		&:focus {
			background-color: #0086C5;
			border-color: #0075ac;
		}
	}
	.btn-info.focus {
		background-color: #0086C5;
		border-color: #0075ac;
	}
	.btn-warning {
		&:hover {
			background-color: #f0ad4e;
			border-color: #eea236;
		}
		&:focus {
			background-color: #f0ad4e;
			border-color: #eea236;
		}
	}
	.btn-warning.focus {
		background-color: #f0ad4e;
		border-color: #eea236;
	}
	.btn-danger {
		&:hover {
			background-color: #d9534f;
			border-color: #d43f3a;
		}
		&:focus {
			background-color: #d9534f;
			border-color: #d43f3a;
		}
	}
	.btn-danger.focus {
		background-color: #d9534f;
		border-color: #d43f3a;
	}
}
.btn-primary {
	&:focus {
		color: #fff;
		background-color: #6d8f21;
		border-color: #2e3c0e;
	}
	&:hover {
		color: #fff;
		background-color: #6d8f21;
		border-color: #57721b;
	}
	&:active {
		color: #fff;
		background-color: #6d8f21;
		border-color: #57721b;
		background-image: none;
		&:hover {
			color: #fff;
			background-color: #57721b;
			border-color: #2e3c0e;
		}
		&:focus {
			color: #fff;
			background-color: #57721b;
			border-color: #2e3c0e;
		}
	}
	&:active.focus {
		color: #fff;
		background-color: #57721b;
		border-color: #2e3c0e;
	}
	.badge {
		color: #8CB82B;
		background-color: #fff;
	}
}
.btn-primary.focus {
	color: #fff;
	background-color: #6d8f21;
	border-color: #2e3c0e;
}
.btn-primary.active {
	color: #fff;
	background-color: #6d8f21;
	border-color: #57721b;
	background-image: none;
	&:hover {
		color: #fff;
		background-color: #57721b;
		border-color: #2e3c0e;
	}
	&:focus {
		color: #fff;
		background-color: #57721b;
		border-color: #2e3c0e;
	}
}
.btn-primary.active.focus {
	color: #fff;
	background-color: #57721b;
	border-color: #2e3c0e;
}
.btn-primary.disabled {
	&:hover {
		background-color: #8CB82B;
		border-color: #7ca326;
	}
	&:focus {
		background-color: #8CB82B;
		border-color: #7ca326;
	}
}
.btn-primary.disabled.focus {
	background-color: #8CB82B;
	border-color: #7ca326;
}
.btn-primary[disabled] {
	&:hover {
		background-color: #8CB82B;
		border-color: #7ca326;
	}
	&:focus {
		background-color: #8CB82B;
		border-color: #7ca326;
	}
}
.btn-primary[disabled].focus {
	background-color: #8CB82B;
	border-color: #7ca326;
}
.btn-success {
	&:focus {
		color: #fff;
		background-color: #6d8f21;
		border-color: #6d8f21;
	}
	&:hover {
		color: #fff;
		background-color: #6d8f21;
		border-color: #6d8f21;
	}
	&:active {
		color: #fff;
		background-color: #6d8f21;
		border-color: #6d8f21;
		background-image: none;
		&:hover {
			color: #fff;
			background-color: #57721b;
			border-color: #57721b;
		}
		&:focus {
			color: #fff;
			background-color: #57721b;
			border-color: #57721b;
		}
	}
	&:active.focus {
		color: #fff;
		background-color: #57721b;
		border-color: #57721b;
	}
	.badge {
		color: #8CB82B;
		background-color: #fff;
	}
}
.btn-success.focus {
	color: #fff;
	background-color: #6d8f21;
	border-color: #6d8f21;
}
.btn-success.active {
	color: #fff;
	background-color: #6d8f21;
	border-color: #6d8f21;
	background-image: none;
	&:hover {
		color: #fff;
		background-color: #57721b;
		border-color: #57721b;
	}
	&:focus {
		color: #fff;
		background-color: #57721b;
		border-color: #57721b;
	}
}
.btn-success.active.focus {
	color: #fff;
	background-color: #57721b;
	border-color: #57721b;
}
.btn-success.disabled {
	&:hover {
		background-color: #8CB82B;
		border-color: #8CB82B;
	}
	&:focus {
		background-color: #8CB82B;
		border-color: #8CB82B;
	}
}
.btn-success.disabled.focus {
	background-color: #8CB82B;
	border-color: #8CB82B;
}
.btn-success[disabled] {
	&:hover {
		background-color: #8CB82B;
		border-color: #8CB82B;
	}
	&:focus {
		background-color: #8CB82B;
		border-color: #8CB82B;
	}
}
.btn-success[disabled].focus {
	background-color: #8CB82B;
	border-color: #8CB82B;
}
.btn-info {
	&:focus {
		color: #fff;
		background-color: #006392;
		border-color: #001e2c;
	}
	&:hover {
		color: #fff;
		background-color: #006392;
		border-color: #004b6e;
	}
	&:active {
		color: #fff;
		background-color: #006392;
		border-color: #004b6e;
		background-image: none;
		&:hover {
			color: #fff;
			background-color: #004b6e;
			border-color: #001e2c;
		}
		&:focus {
			color: #fff;
			background-color: #004b6e;
			border-color: #001e2c;
		}
	}
	&:active.focus {
		color: #fff;
		background-color: #004b6e;
		border-color: #001e2c;
	}
	.badge {
		color: #0086C5;
		background-color: #fff;
	}
}
.btn-info.focus {
	color: #fff;
	background-color: #006392;
	border-color: #001e2c;
}
.btn-info.active {
	color: #fff;
	background-color: #006392;
	border-color: #004b6e;
	background-image: none;
	&:hover {
		color: #fff;
		background-color: #004b6e;
		border-color: #001e2c;
	}
	&:focus {
		color: #fff;
		background-color: #004b6e;
		border-color: #001e2c;
	}
}
.btn-info.active.focus {
	color: #fff;
	background-color: #004b6e;
	border-color: #001e2c;
}
.btn-info.disabled {
	&:hover {
		background-color: #0086C5;
		border-color: #0075ac;
	}
	&:focus {
		background-color: #0086C5;
		border-color: #0075ac;
	}
}
.btn-info.disabled.focus {
	background-color: #0086C5;
	border-color: #0075ac;
}
.btn-info[disabled] {
	&:hover {
		background-color: #0086C5;
		border-color: #0075ac;
	}
	&:focus {
		background-color: #0086C5;
		border-color: #0075ac;
	}
}
.btn-info[disabled].focus {
	background-color: #0086C5;
	border-color: #0075ac;
}
.btn-warning {
	&:focus {
		color: #fff;
		background-color: #ec971f;
		border-color: #985f0d;
	}
	&:hover {
		color: #fff;
		background-color: #ec971f;
		border-color: #d58512;
	}
	&:active {
		color: #fff;
		background-color: #ec971f;
		border-color: #d58512;
		background-image: none;
		&:hover {
			color: #fff;
			background-color: #d58512;
			border-color: #985f0d;
		}
		&:focus {
			color: #fff;
			background-color: #d58512;
			border-color: #985f0d;
		}
	}
	&:active.focus {
		color: #fff;
		background-color: #d58512;
		border-color: #985f0d;
	}
	.badge {
		color: #f0ad4e;
		background-color: #fff;
	}
}
.btn-warning.focus {
	color: #fff;
	background-color: #ec971f;
	border-color: #985f0d;
}
.btn-warning.active {
	color: #fff;
	background-color: #ec971f;
	border-color: #d58512;
	background-image: none;
	&:hover {
		color: #fff;
		background-color: #d58512;
		border-color: #985f0d;
	}
	&:focus {
		color: #fff;
		background-color: #d58512;
		border-color: #985f0d;
	}
}
.btn-warning.active.focus {
	color: #fff;
	background-color: #d58512;
	border-color: #985f0d;
}
.btn-warning.disabled {
	&:hover {
		background-color: #f0ad4e;
		border-color: #eea236;
	}
	&:focus {
		background-color: #f0ad4e;
		border-color: #eea236;
	}
}
.btn-warning.disabled.focus {
	background-color: #f0ad4e;
	border-color: #eea236;
}
.btn-warning[disabled] {
	&:hover {
		background-color: #f0ad4e;
		border-color: #eea236;
	}
	&:focus {
		background-color: #f0ad4e;
		border-color: #eea236;
	}
}
.btn-warning[disabled].focus {
	background-color: #f0ad4e;
	border-color: #eea236;
}
.btn-danger {
	&:focus {
		color: #fff;
		background-color: #c9302c;
		border-color: #761c19;
	}
	&:hover {
		color: #fff;
		background-color: #c9302c;
		border-color: #ac2925;
	}
	&:active {
		color: #fff;
		background-color: #c9302c;
		border-color: #ac2925;
		background-image: none;
		&:hover {
			color: #fff;
			background-color: #ac2925;
			border-color: #761c19;
		}
		&:focus {
			color: #fff;
			background-color: #ac2925;
			border-color: #761c19;
		}
	}
	&:active.focus {
		color: #fff;
		background-color: #ac2925;
		border-color: #761c19;
	}
	.badge {
		color: #d9534f;
		background-color: #fff;
	}
}
.btn-danger.focus {
	color: #fff;
	background-color: #c9302c;
	border-color: #761c19;
}
.btn-danger.active {
	color: #fff;
	background-color: #c9302c;
	border-color: #ac2925;
	background-image: none;
	&:hover {
		color: #fff;
		background-color: #ac2925;
		border-color: #761c19;
	}
	&:focus {
		color: #fff;
		background-color: #ac2925;
		border-color: #761c19;
	}
}
.btn-danger.active.focus {
	color: #fff;
	background-color: #ac2925;
	border-color: #761c19;
}
.btn-danger.disabled {
	&:hover {
		background-color: #d9534f;
		border-color: #d43f3a;
	}
	&:focus {
		background-color: #d9534f;
		border-color: #d43f3a;
	}
}
.btn-danger.disabled.focus {
	background-color: #d9534f;
	border-color: #d43f3a;
}
.btn-danger[disabled] {
	&:hover {
		background-color: #d9534f;
		border-color: #d43f3a;
	}
	&:focus {
		background-color: #d9534f;
		border-color: #d43f3a;
	}
}
.btn-danger[disabled].focus {
	background-color: #d9534f;
	border-color: #d43f3a;
}
