.btn {
	font-size: 1rem;
	display: inline-block;
	margin-bottom: 0;
	font-weight: normal;
	text-align: center;
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;
	background-image: none;
	border: 1px solid transparent;
	white-space: nowrap;
	padding: 9px 18px;
	font-size: 14px;
	line-height: 1.428571429;
	border-radius: 20px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	&:focus {
		outline: 5px auto -webkit-focus-ring-color;
		outline-offset: -2px;
		//color: #454545;
		text-decoration: none;
	}
	&:active {
		&:focus {
			outline: 5px auto -webkit-focus-ring-color;
			outline-offset: -2px;
		}
		outline: 0;
		background-image: none;
	}
	&:active.focus {
		outline: 5px auto -webkit-focus-ring-color;
		outline-offset: -2px;
	}
	&:hover {
		text-decoration: none;
	}
}
.btn.focus {
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px;
	color: #454545;
	text-decoration: none;
}
.btn.active {
	&:focus {
		outline: 5px auto -webkit-focus-ring-color;
		outline-offset: -2px;
	}
	outline: 0;
	background-image: none;
}
.btn.active.focus {
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}
.btn.disabled {
	cursor: not-allowed;
	opacity: 0.65;
	filter: alpha(opacity=65);
	-webkit-box-shadow: none;
	box-shadow: none;
}
.btn[disabled] {
	cursor: not-allowed;
	opacity: 0.65;
	filter: alpha(opacity=65);
	-webkit-box-shadow: none;
	box-shadow: none;
}
fieldset[disabled] {
	.btn {
		cursor: not-allowed;
		opacity: 0.65;
		filter: alpha(opacity=65);
		-webkit-box-shadow: none;
		box-shadow: none;
	}
	a.btn {
		pointer-events: none;
	}
	.btn-link {
		background-color: transparent;
		-webkit-box-shadow: none;
		box-shadow: none;
		&:hover {
			color: #898989;
			text-decoration: none;
		}
		&:focus {
			color: #898989;
			text-decoration: none;
		}
	}
}
a.btn.disabled {
	pointer-events: none;
}
.btn-default {
	color: #454545;
	background-color: transparent;
	border-color: #454545;
}
.btn-primary {
	color: #fff;
	background-color: #8CB82B;
	border-color: #7ca326;
}
.btn-success {
	color: #fff;
	background-color: #8CB82B;
	border-color: #8CB82B;
}
.btn-info {
	color: #fff;
	background-color: #0086C5;
	border-color: #0075ac;
}
.btn-warning {
	color: #fff;
	background-color: #f0ad4e;
	border-color: #eea236;
}
.btn-danger {
	color: #fff;
	background-color: #d9534f;
	border-color: #d43f3a;
}
.btn-link {
	color: #0086C5;
	font-weight: normal;
	border-radius: 0;
	background-color: transparent;
	-webkit-box-shadow: none;
	box-shadow: none;
	border-color: transparent;
	&:active {
		background-color: transparent;
		-webkit-box-shadow: none;
		box-shadow: none;
		border-color: transparent;
	}
	&:hover {
		border-color: transparent;
		color: #005279;
		text-decoration: underline;
		background-color: transparent;
	}
	&:focus {
		border-color: transparent;
		color: #005279;
		text-decoration: underline;
		background-color: transparent;
	}
}
.btn-link.active {
	background-color: transparent;
	-webkit-box-shadow: none;
	box-shadow: none;
}
.btn-link[disabled] {
	background-color: transparent;
	-webkit-box-shadow: none;
	box-shadow: none;
	&:hover {
		color: #898989;
		text-decoration: none;
	}
	&:focus {
		color: #898989;
		text-decoration: none;
	}
}
.btn-lg {
	padding: 14px 28px;
	font-size: 18px;
	line-height: 1.25;
	border-radius: 26px;
}
.btn-group-lg {
	>.btn {
		padding: 14px 28px;
		font-size: 18px;
		line-height: 1.25;
		border-radius: 26px;
	}
}
.btn-sm {
	padding: 7px 14px;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 4px;
}
.btn-group-sm {
	>.btn {
		padding: 7px 14px;
		font-size: 12px;
		line-height: 1.5;
		border-radius: 4px;
	}
}
.btn-xs {
	padding: 1px 5px;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 4px;
}
.btn-group-xs {
	>.btn {
		padding: 1px 5px;
		font-size: 12px;
		line-height: 1.5;
		border-radius: 4px;
	}
}
.btn-block {
	display: block;
	width: 100%;
	+ {
		.btn-block {
			margin-top: 5px;
		}
	}
}
input[type="submit"].btn-block {
	width: 100%;
}
input[type="reset"].btn-block {
	width: 100%;
}
input[type="button"].btn-block {
	width: 100%;
}

.btn {
	padding-right: 40px;
	padding-left: 40px;
	font-weight: 400;
	[type="button"] {
		min-width: 190px;
	}
	&:focus {
		outline: none !important;
	}
	&:active {
		&:focus {
			outline: none !important;
		}
	}
	&:active.focus {
		outline: none !important;
	}
	.btn-send-message {
		border-radius: 0 4px 4px 0;
		background-color: #8CB82B;
		color: white;
	}
}
.btn.focus {
	outline: none !important;
}
.btn.active {
	&:focus {
		outline: none !important;
	}
}
.btn.active.focus {
	outline: none !important;
}
.btn.btn-default {
	&:hover {
		background-color: rgba(69, 69, 69, 0.07);
		a {
			color: inherit;
			text-decoration: none;
		}
	}
	&:focus {
		background-color: rgba(69, 69, 69, 0.07);
		a {
			color: inherit;
			text-decoration: none;
		}
	}
}
.btn.btn-default.focus {
	background-color: rgba(69, 69, 69, 0.07);
	a {
		color: inherit;
		text-decoration: none;
	}
}
.dark-bg {
	.btn.btn-default {
		border-color: rgba(254, 254, 254, 1);
		color: rgba(254, 254, 254, 1);
		&:not(.btn-success) {
			border-color: rgba(254, 254, 254, 1);
			color: rgba(254, 254, 254, 1);
		}
		&:active {
			border-color: rgba(254, 254, 254, 1);
			color: rgba(254, 254, 254, 1);
		}
		&:hover {
			border-color: rgba(254, 254, 254, 1);
			color: rgba(254, 254, 254, 1);
			background-color: rgba(254, 254, 254, 0.07);
		}
		&:focus {
			border-color: rgba(254, 254, 254, 1);
			color: rgba(254, 254, 254, 1);
			background-color: rgba(254, 254, 254, 0.07);
		}
	}
	.btn.btn-default.focus {
		border-color: rgba(254, 254, 254, 1);
		color: rgba(254, 254, 254, 1);
		background-color: rgba(254, 254, 254, 0.07);
	}
	.btn.btn-default.dropdown-toggle {
		border-color: rgba(254, 254, 254, 1);
		color: rgba(254, 254, 254, 1);
		min-width: 190px;
		font-size: 12px;
		font-weight: 500;
		border-color: #fff;
		background-color: #fff;
		color: #454545;
		transition: background-color 500ms;
		min-width: 240px;
		font-size: 12px;
		font-weight: 500;
		border-color: #0d4058;
		background-color: #0d4058;
		color: rgba(254, 254, 254, 0.8);
		transition: background-color 500ms;
		.caret {
			display: none;
			display: none;
		}
		&:hover {
			border-color: #fff;
			background-color: #fff;
			color: #454545;
		}
		&:focus {
			border-color: #fff;
			background-color: #fff;
			color: #454545;
		}
	}
	.btn.btn-default.dropdown-toggle.focus {
		border-color: #fff;
		background-color: #fff;
		color: #454545;
	}
}
.dashboard {
	.btn.btn-default {
		border-color: rgba(254, 254, 254, 1);
		color: rgba(254, 254, 254, 1);
		&:not(.btn-success) {
			border-color: rgba(255, 255, 255, 0.7);
			color: rgba(254, 254, 254, 1);
		}
		&:active {
			border-color: rgba(254, 254, 254, 1);
			color: rgba(254, 254, 254, 1);
		}
		&:hover {
			border-color: rgba(254, 254, 254, 1);
			color: rgba(254, 254, 254, 1);
			background-color: rgba(254, 254, 254, 0.07);
		}
		&:focus {
			border-color: rgba(254, 254, 254, 1);
			color: rgba(254, 254, 254, 1);
			background-color: rgba(254, 254, 254, 0.07);
		}
	}
	.btn.btn-default.focus {
		border-color: rgba(254, 254, 254, 1);
		color: rgba(254, 254, 254, 1);
		background-color: rgba(254, 254, 254, 0.07);
	}
	.btn.btn-default.dropdown-toggle {
		border-color: rgba(254, 254, 254, 1);
		color: rgba(254, 254, 254, 1);
		min-width: 190px;
		font-size: 12px;
		font-weight: 500;
		border-color: #fff;
		background-color: #fff;
		color: #454545;
		transition: background-color 500ms;
		.caret {
			display: none;
		}
		&:hover {
			border-color: #fff;
			background-color: #fff;
			color: #454545;
		}
		&:focus {
			border-color: #fff;
			background-color: #fff;
			color: #454545;
		}
	}
	.btn.btn-default.dropdown-toggle.focus {
		border-color: #fff;
		background-color: #fff;
		color: #454545;
	}
}
.top10btn {
	.btn.btn-default.dropdown-toggle {
		min-width: 240px;
		font-size: 12px;
		font-weight: 500;
		border-color: #0d4058;
		background-color: #0d4058;
		color: rgba(254, 254, 254, 0.8);
		transition: background-color 500ms;
		.caret {
			display: none;
		}
	}
}
.btn.btn-default.dropdown-toggle {
	border-radius: 4px;
	&::after {
		content: "\e5c5";
		display: inline-block;
		text-align: center;
		vertical-align: middle;
		font-family: sr-icons;
		font-size: 22px;
		line-height: 14px;
		font-weight: normal;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		position: absolute;
		right: 10px;
	}
}
.dropdown.open {
	.btn.btn-default.dropdown-toggle {
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}
}
.btn-group {
	.btn.btn-default {
		border-radius: 4px;
	}
	.btn.btn-default.active {
		background-color: #8CB82B;
		box-shadow: none;
		border: #8CB82B;
		&:hover {
			color: #fff;
			background-color: #7ca326;
		}
	}
}
