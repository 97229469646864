#top10kommunen_length {
	display: none;
}
#top10kommunen_filter {
	display: none;
}
#top10kommunen_paginate {
	display: none;
}
#mein_team_length {
	display: none;
}
#mein_team_filter {
	display: none;
}
#mein_team_paginate {
	display: none;
}
.data-table {
	thead {
		>tr {
			>th {
				border-bottom: none;
				font-weight: normal;
				text-shadow: 0 2px 15px rgba(0, 0, 0, 0.4);
				color: rgba(255, 255, 255, 0.5);
			}
			>th[class*='sorting'] {
				outline: none !important;
				&:after {
					content: "";
					display: inline-block;
					text-align: center;
					vertical-align: middle;
					font-family: sr-icons;
					font-size: 100%;
					line-height: 1;
					font-weight: normal;
					text-rendering: auto;
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
					position: absolute;
					right: auto;
					bottom: 0;
					left: auto;
					font-size: 25px;
					color: #fefefe;
					opacity: 1;
					margin-bottom: 5px;
				}
			}
			>th.sorting_desc {
				&:after {
					content: "\e5c5" !important;
				}
				padding-left: 25px;
				padding-right: 13px;
				color: #fefefe;
				opacity: 1;
			}
			>th.sorting_asc {
				&:after {
					content: "\e5c7" !important;
				}
				padding-left: 25px;
				padding-right: 13px;
				color: #fefefe;
				opacity: 1;
			}
		}
	}
	tbody {
		>tr {
			height: 75px;
			td {
				position: relative;
				padding: 0 17.5px;
				font-size: 14px;
				line-height: 1.2;
				vertical-align: middle;
				a {
					color: #fff;
					&:hover {
						color: #fff;
						text-decoration: none;
					}
          &.blog-link {
            color: #0086C5;
          }
				}
				.badge {
					position: absolute;
					top: 0;
					right: 0;
					height: 30px;
					width: 30px;
					line-height: 2;
				}
				h3 {
					margin: 0;
				}
			}
			td.not-public-text {
				font-weight: 300;
			}
			.bar_wrapper {
				position: relative;
				.bar_bg {
					position: inherit;
					height: 30px;
					width: 90%;
					background-color: #052836;
					.bar_fg {
						position: absolute;
						top: 0;
						height: 30px;
						background-color: #0086C5;
					}
				}
				.bar_text {
					position: absolute;
					top: 0;
					left: 10px;
					font-size: 20px;
					line-height: 1.5;
				}
			}
		}
		>tr.selected {
			background-color: #0086C5;
			.bar_wrapper {
				.bar_bg {
					.bar_fg {
						background-color: #286AA6;
					}
				}
			}
		}
		>tr.last {
			>td {
				border-bottom: 0;
			}
		}
	}
}
.oval {
	height: 30px;
	width: 30px;
	position: absolute;
	top: 0;
	right: 0;
	border-radius: 50% 0px 50% 50%;
	margin-right: 0px;
	background-color: #052836;
	.text-center {
		color: #fff;
		font-size: 12px;
		line-height: 30px;
	}
}
tr.selected {
	.oval {
		background-color: #286aa6;
	}
}
.table-text-small {
	opacity: 0.8;
	color: #FFFFFF;
	font-family: Roboto;
	font-size: 11px;
	font-weight: 300;
	line-height: 25px;
}
table.sr-ranking {
	table-layout: fixed;
	width: 100%;
	tbody {
		>tr {
			border-bottom: 1px solid #052836;
			>td {
				&:first-of-type {
					span {
						height: 10px;
						width: 10px;
						display: block;
						padding: 15px 20px 15px 10px;
						border-radius: 50%;
						margin-left: 15px;
						background: #052836;
						font-size: 12px;
						font-weight: 500;
						line-height: 0;
					}
				}
				line-height: 1.4;
			}
		}
	}
	span.aktiv {
		&::after {
			content: ' \/';
		}
	}
	thead {
		>tr {
			>th {
				width: 10%;
				&:first-of-type {
					padding-left: 25px;
				}
			}
		}
	}
}
span.data-unit {
	display: none;
}
.sr-ranking {
	th.w-25 {
		width: 25%;
	}
	th.w-40 {
		width: 30%;
	}
	th.w-60 {
		width: 30%;
	}
}
table.dataTable.sr-ranking {
	border-collapse: collapse !important;
}
td {
	i[class*='-dude'] {
		padding-left: 5px;
		font-size: 25px;
		color: #0086C5;
		vertical-align: middle;
	}
}
// show spinner in front of table
div.dataTables_wrapper div.dataTables_processing {
	z-index: 100;
}
// TODO: Cleanup; Not specific egough to datatables
// .dark-bg {
// 	.form-control {
// 		color: #fefefe;
// 		background-color: #063547;
// 		//border-radius: 4px;
// 		&::-webkit-input-placeholder {
// 			color: #fefefe;
// 			opacity: 0.6;
// 		}
// 		&::-moz-placeholder {
// 			color: #fefefe;
// 			opacity: 0.6;
// 		}
// 		&:-ms-input-placeholder {
// 			color: #fefefe;
// 			opacity: 0.6;
// 		}
// 		&:-moz-placeholder {
// 			color: #fefefe;
// 			opacity: 0.6;
// 		}
// 	}
// 	.btn-group {
// 		.btn {
// 			border-color: rgba(0, 134, 197, 0.4) !important;
// 		}
// 	}
// }
.th-search {
	display: flex;
	align-items: center;
	label {
		margin: 0 20px 0 0;
		font-weight: normal;
		white-space: nowrap;
		line-height: 1;
	}
	input {
		border-color: #fff;
	}
}

.dark-bg .sort-buttons .btn-group .btn {
  border-color: rgba(0, 134, 197, 0.4) 
}

.sort-buttons {
	label {
		display: block;
		opacity: 0.4;
	}
	.btn-group {
		display: flex;
		margin-bottom: -60px;
		.btn {
			flex: 1;
		}
	}
}

.numberOfResults {
	>div {
		float: left;
		display: inline-table;
		margin-right: 10px;
		color: rgba(255, 255, 255, 0.5);
		cursor: pointer;
	}
	>span {
		float: left;
		display: inline-table;
		margin-right: 10px;
		color: rgba(255, 255, 255, 0.5);
	}
	margin-top: -110px;
	font-weight: 500;
	margin-bottom: 130px;
	>div.active {
		cursor: pointer;
		color: #0086C5;
	}
}
@media (max-width: 768px) {
	.numberOfResults {
		margin-top: -200px;
		font-weight: 500;
		margin-bottom: 130px;
	}
}
@media (max-width: 480px) {
	.numberOfResults {
		>div {
			margin-right: 20px;
		}
		>span {
			margin-right: 20px;
		}
		margin-bottom: 90px;
	}
}

@media (max-width: 767px) {
	.data-table {
		tbody {
			>tr {
				.bar_wrapper {
					.bar_bg {
						display: none;
					}
					.bar_text {
						position: relative;
					}
				}
			}
		}
	}
	.dataTables_wrapper {
		margin: 0 -20px 0 -20px !important;
	}
	.sr-ranking {
		th.w-40 {
			width: 60%;
		}
		th.w-60 {
			width: 40%;
		}
	}
	span.data-unit {
		font-weight: 300;
		display: inline;
	}
	.align-middle {
		vertical-align: middle !important;
		padding: 0 !important;
	}
	table.sr-ranking {
		thead {
			>tr {
				>th {
					visibility: hidden;
				}
				>th.hide-on-mobile {
					width: 0 !important;
					overflow: hidden;
				}
			}
		}
		tbody {
			>tr {
				>td {
					padding: 13px 30px 40px;
					border: 0;
					vertical-align: top;
					overflow: hidden;
					-ms-text-overflow: ellipsis;
					text-overflow: ellipsis;
					white-space: nowrap;
					&:first-of-type {
						position: absolute;
						right: 0;
						padding: 0;
						span {
							border-radius: 50% 0px 50% 50%;
						}
					}
				}
				>td.radelnde {
					position: absolute;
					left: 0;
					margin-top: 20px;
					font-size: 11px;
					opacity: 0.7;
					span {
						display: block;
					}
				}
				>td.merge-left {
					position: absolute;
					left: 0;
					margin-top: 20px;
					font-size: 11px;
					opacity: 0.7;
					&:last-of-type {
						margin-top: 35px;
					}
					i {
						display: none;
					}
				}
			}
		}
		span.aktiv {
			&::after {
				content: ' Radelnde aktiv';
			}
		}
		span.gesamt {
			&::after {
				content: ' Radelnde gesamt';
			}
		}
		span.radelnde {
			&::after {
				content: ' Radelnde';
			}
		}
		span.parl {
			&::after {
				content: ' ParlamentarierInnen';
			}
		}
	}
	.th-search {
		display: flex;
		align-items: center;
		margin: 30px 0;
	}
}
@media (max-width: 991px) {
	table.sr-ranking {
		thead {
			>tr {
				>th.hide-on-tablet {
					width: 0 !important;
					overflow: hidden;
				}
			}
		}
	}
}
/* tablet only */
@media (min-width: 768px) and (max-width: 992px) {
	.dataTables_wrapper {
		margin: 0 -24px 0 -24px !important;
	}
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
	table.sr-ranking {
		table-layout: auto !important;
		thead {
			>tr {
				>th {
					width: auto !important;
				}
				>th.w-60 {
					width: 25% !important;
				}
			}
		}
	}
}
@media (min-width: 992px) {
	.th-search {
		input {
			&::placeholder {
				visibility: hidden !important;
				color: transparent !important;
			}
		}
	}
}
