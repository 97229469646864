#agfk-import {
    .content-spinner {
        z-index: 5000;
        width: 200px;
        margin: 0 auto;
        padding: 20px;
        img {
            margin-top: 25%;
            -webkit-animation: rotation 1s linear 0s infinite;
            -moz-animation: rotation 1s linear 0s infinite;
            -o-animation: rotation 1s linear 0s infinite;
            animation: rotation 1s linear 0s infinite;
        }
    }
    .agfk-import-flag {
        width: 20px;
    }

    .table-mobile {
        display: none;
    }

    .dark-bg .btn.btn-default.dropdown-toggle, .top10btn .btn.btn-default.dropdown-toggle {
        min-width:inherit;
    }

    .arrow-invert .arrow-links li a::before {
        float: left;
        margin-right: 3px;
        transform: rotate(-135deg);
    }

    .arrow-invert .arrow-links li a:hover::before {
        transform: rotate(-90deg);
    }

    /* popover light */
    .popover {
        padding: 8px 18px 12px 18px;
        position: absolute;
        z-index: 9999;
        max-width: 220px;
        font-size: 13px;
        border: 0px;
        line-height: inherit;
        background-color: #ffffff;
        border-radius: 0;
    }
    .popover-content {
        color: #000000;
        opacity: .88;
        font-size: 11px;
        line-height: 15px;
        letter-spacing: 0.47px;
        font-weight: 300;
        padding:0;
    }
    .popover.top .arrow:after {
        border-top-color: #ffffff;
    }
    .popover.top > .arrow {
        border-top-color: #ffffff;
    }

    #ags-list {
        &.data-table {
            thead > tr > th {
                &[class*="sorting"]::after {
                    left: unset;
                    margin-bottom: 7px;
                }
                &.sorting_asc,
                &.sorting_desc,
                &.sorting {
                    padding-left: 18px!important;
                    padding-right: 18px;
                }
                /* show sorting icon on all sorting columns */
                &.sorting::after {
                    content: "\e5c5";
                    opacity: 0.4;
                }
                &:first-child {
                    padding-left: 0!important;
                }
                &:last-child {
                    text-align: right;
                    padding-right: 25px;
                }
            }
            /* remove border from first and last row */
            tbody > tr {
                &:first-child td {
                    border: 0;
                }
                &:last-child {
                    border: 0;
                }
            }
            tbody {
                tr {
                    td {
                        &:last-child {
                            text-align: right;
                        }
                        &:only-child {
                            text-align: center;
                        }
                    }
                }
            }
        }
        tr > td:first-child {
            padding-left: 0;
        }
    }

    #numberOfResults {
        font-weight: 500;
        margin-top: -115px;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.33px;
    }


    table.sr-ranking span.aktiv::after {
        content: none;
    }

    table.sr-ranking tbody > tr td {
        font-size: 15px;
        color: rgba(255,255,255,0.87);
    }

    .text-15 {
        font-size: 15px !important;
    }

    #tableSortXs .btn {
        padding-left: inherit;
        padding-right: inherit;
        padding-top: 4px;
        padding-bottom: 4px;
    }

    #tableSortXs .btn.btn-default.active {
        background-color: rgba(2,27,38,0.8) !important;
        border: 1px solid rgba(0, 134, 197, 0.4) !important;
    }

    .data-unit {
        font-size: 13px;
    }

    table.sr-ranking tbody > tr {
        border-bottom: 1px solid #052836;
    }

    /* line 260, ../scss/components/_datatables.scss */
    td i[class*='-dude'] {
        padding-left: 5px;
        font-size: 30px;
        color: #0086C5;
        vertical-align: middle; }


    .data-table tbody > tr .bar_wrapper .bar_bg {
        width: 100%;

    }

    .results-filter > div
    {
        flex:1 1 auto;
        text-align:center;
        margin: 0px 50px;
    }

    .results-filter > div:first-child
    {
        margin-left: 0px;
    }

    .results-filter > div:last-child
    {
        margin-right: 0px;
    }


    .dark-bg .btn.btn-default.dropdown-toggle, .top10btn .btn.btn-default.dropdown-toggle {
        min-width: 140px !important;
    }


    .nav-pills > li > a {
        border-left-width: 0;
        border-radius: 0;
    }

    .nav-pills > li:first-child > a {
        border-radius: 2px 0 0 2px;
        border-left-width: 1px;
    }

    .nav-pills > li:last-child > a {
        border-radius: 0 2px 2px;
    }

    .nav-pills > li + li {
        margin-left: 0px;
    }

    .nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
        color: #fefefe;
        background-color: #fefefe;
        border-color: #8CB82B !important;
        font-weight: 500 !important;
    }


    #legend-wrapper p {
        line-height: 3;
    }

    #teamliste_wrapper div.dataTables_paginate, div.dataTables_paginate {
        text-align: center !important;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .nav > li > a {
        position: relative;
        display: block;
        padding: 7px 15px;
        font-size: 13px;
        font-weight: 500;
    }

    #tableSortXs .btn {
        padding-left: inherit;
        padding-right: inherit;
        padding-top: 4px;
        padding-bottom: 4px;
        font-size: 11px;
    }

    /* Pagination */
    .paginate_button {
        font-size: 18px;
        position: relative;
        &.current {
            color: #0086C5 !important;
            font-weight: 600;
        }
    }
    .pagination-icon {
        height: 25px;
        fill: white;
        position: absolute;
        top: 8px;
        left: 0px;
    }
    /* End Pagination */

    /* NumberOfResults */
    .numberOfResults > div, .numberOfResults > span {
        float: left;
        display: inline-table;
        margin-right: 10px;
        color: rgba(255, 255, 255, 0.5);
    }
    .numberOfResults > div {
        cursor: pointer;
        &.active {
            cursor: pointer;
            color:#0086C5;
        }
    }

    /* ==== Expanded DataTables */

    td.expanded {
        padding: 0px 0px !important;
        background-color: #fff;
        td {
            color: #454545 !important;
            a {
                color: #454545 !important;
            }
        }
    }

    tr.shown {
        background-color: #0086C5;
    }

    table.sr-ranking tbody > tr.expanded > td:first-of-type span {
        background: #fff;
    }

    .shown {
        .subteamlink:after {
            color: #0E4761;
            transform: rotate(0deg);
            top: 0px;
            right: 10px;
        }
        .subteamlink {
            color: #0E4761;
        }
    }

    /* ==== Spinner ==== */

    .spinner {
        z-index: 5000;
        img {
            margin-top: 25%;
            -webkit-animation: rotation 1s linear 0s infinite;
            -moz-animation: rotation 1s linear 0s infinite;
            -o-animation: rotation 1s linear 0s infinite;
            animation: rotation 1s linear 0s infinite;
        }
    }

    @-webkit-keyframes rotation { from { transform: rotate(0deg); } to { transform: rotate(359deg); }  }
    @-moz-keyframes rotation { from { transform: rotate(0deg); } to { transform: rotate(359deg); }  }
    @-o-keyframes rotation { from { transform: rotate(0deg); } to { transform: rotate(359deg); }  }
    @keyframes rotation { from { transform: rotate(0deg); } to { transform: rotate(359deg); }  }


    div.dataTables_wrapper {
        position: relative;
    }

    div.dataTables_wrapper div.dataTables_processing {
        z-index: 5000;
        position: absolute;
        top: 50px;
        left: 50%;
        width: 200px;
        margin-left: -100px;
        margin-top: -26px;
        text-align: center;
        padding: 1em 0;
    }

    #ags-list {
        width:auto;
    }

    #ags-list_filter, #ags-list_length {
        display:none;
    }

    .dataTables_filter label input {
        border: 1px solid rgba(255,255,255,1);
        background-color: transparent;
        border-radius: 5px;
        height: 30px;
        opacity:1;
    }

    .dataTables_length label select {
        background-color: #0d4058;
        border-radius: 5px;
        height: 30px;
        border: none;
        -moz-appearance:none; /* Firefox */
        -webkit-appearance:none; /* Safari and Chrome */
        appearance:none;
        text-align: center;
        opacity:1;
    }

    #ags-list_length label {
        font-weight: 500;
        opacity: 0.39;
    }

    #ags-list_filter label {
        font-weight: 500;
        opacity: 0.39;
    }

    #insert-form {
        display:flex;
        flex-direction: row;
    }

    #insert-form label {
        display: flex;
        align-self: center;
        min-width: 140px;
    }

    #insert-form input, #insert-form select {
        background-color: transparent;
        padding: 4px 50px 4px 0;
        border-radius: 0;
        border-width: 0 0 2px;
        border-color: #bbb;

    }
    #ags-search-field {
        justify-content: end;
        display: flex;
    }

    #insert-form input[type="checkbox"]:checked + label:before, .commune-data input[type="checkbox"]:checked + span:before {
        font-family: 'sr-icons', 'FontAwesome';
        font-size:13px;
        background-color: #8cb82b;
        content: "\f00c";
        color: #FFFFFF;
        width: 16px;
        height: 16px;
        border: 1.5px solid #8cb82b;
        display: table;
        line-height: 0.8;
    }

    #insert-form input[type="checkbox"], #insert-form input[type="radio"] {
        display: none;
    }

    #insert-form input[type="checkbox"] + label, #insert-form input[type="checkbox"] + span {
        font-size: 14px;
        margin: 5px;
    }

    #insert-form input[type="checkbox"] + label:before, #insert-form input[type="checkbox"] + span:before {
        width: 16px;
        height: 16px;
        background-color: #FFFFFF;
        display: table;
        content:"";
        border-radius: 2px;
        border: 1.5px solid rgba(69,69,69, 0.38);
        font-size:13px;
        line-height: 0.8;
        border-collapse:separate;
    }

    .ags-label {
        opacity: 0.39;
    }

    #insert-form {
        display: none;
    }

    .form-group {
        display:flex;
        justify-content: space-between;
    }

    .information-text {
        color: #000;
        opacity: 0.39;
    }

    #import-agfk-list-modal {
        .modal-header h2 {
            color: #454545;
            margin: 0;
        }
        label {
            font-weight: 400;
            font-size: 12px;
            line-height: 17px;
            color: #454545;
        }
    }
    .modal-single-button {
        display:flex;
        justify-content: center;
    }
    .modal-options {
        display: flex;
    }
    .modal-header {
        padding-top: 15px;
    }
    .modal-body {
        padding-top: 0px;
        .info {
            margin: 8px 36px 0 6px;
            padding-left: 20px;
            &::before {
                font-size: 18px;
            }
        }
        .form-group {
            color: #000;
            label {
                min-width: auto;
            }
        }
    }

    .data-list {
        margin-top: 20px;
        margin-bottom: 35px;
        margin-left: 0;
        display: flex;
        flex-direction: column;
        label {
            display: flex;
            align-self: center;
            min-width: 140px;
        }
        input, select {
            background-color: transparent;
            padding: 4px 50px 4px 0;
            border-radius: 0;
            border-width: 0 0 2px;
            border-color: #bbb;
        }
    }

    .data-list input[type="checkbox"]:checked + label:before, .commune-data input[type="checkbox"]:checked + span:before {
        font-family: 'sr-icons', 'FontAwesome';
        font-size:13px;
        background-color: #8cb82b;
        content: "\f00c";
        color: #FFFFFF;
        width: 16px;
        height: 16px;
        border: 1.5px solid #8cb82b;
        display: table;
        line-height: 0.8;
    }

    .data-list input[type="checkbox"], #insert-form input[type="radio"] {
        display: none;
    }

    .data-list input[type="checkbox"] + label, #insert-form input[type="checkbox"] + span {
        font-size: 14px;
        margin: 5px;
    }

    .data-list input[type="checkbox"] + label:before, #insert-form input[type="checkbox"] + span:before {
        width: 16px;
        height: 16px;
        background-color: #FFFFFF;
        display: table;
        content:"";
        border-radius: 2px;
        border: 1.5px solid rgba(69,69,69, 0.38);
        font-size:13px;
        line-height: 0.8;
        border-collapse:separate;
    }

    .w-250 {
        width:250px;
        display:flex;
    }

    #tableSortXs {
        .btn {
            padding: 4px 0;
            font-size: 11px;
            cursor: pointer;
            &.btn-default {
                background-color: transparent;
                border-color: rgba(0, 134, 197, 0.4);
                &:hover {
                    background-color: rgba(254, 254, 254, 0.07);
                }
                &.active {
                    background-color: rgba(2, 27, 38, 0.8);
                    border: 1px solid rgba(0, 134, 197, 0.4);
                }
            }
        }
    }

    input[type="radio"] {
        display: none;
    }

    input[type="radio"] + label:before {
        width:16px;
        height:16px;
        background-color: #FFFFFF;
        display: block;
        content:"";
        border-radius: 10px;
        border: 1.5px solid #b7b7b7;
        margin-right: 10px;
    }

    input[type="radio"]:checked + label:before {
        background-color: #8cb82b;
        box-shadow: inset 0px 0px 0px 3px #FFFFFF;
    }

    .button-wrap {
        position: relative;
    }

    .file-name {
        color: #454545;
        text-align: right;
        padding-right: 15px;
    }

    #add-ags-msg {
        margin-bottom: 20px;
    }

    #ags-report {
        margin-bottom: 20px;
        display: none;
    }
}

/* tablet */
@media (min-width: 768px) and (max-width: 992px) {
    #agfk-import {
        #ags-list {
            width: 100%;
            thead {
                tr {
                    // spacing between thead and tbody
                    border-bottom: 15px solid transparent;
                }
            }
        }
        #ags-list_wrapper {
            padding: 0 20px;
        }
        .results-filter > div {
            margin: 0px 30px;
        }

        /* line 157, ../scss/components/_datatables.scss */
        table.sr-ranking tbody > tr > td:first-of-type span {
            height: 40px;
            width: 40px;
            display: block;
            padding: 15px 20px 15px 1.40px;
            border-radius: 50%;
            margin-left: 2px;
            background: #052836;
            font-size: 15px;
            font-weight: 500;
            line-height: 12px;
        }

        .md-px-0 {
            padding-left: 0px !important;
            padding-right: 0px !important;
        }
        section.dashboard .sr-icon-grid > li {
            flex-basis: 0% !important;
        }

        .sr-icon-grid li {
            min-width: 0%;
        }
    }
}

// mobile
@media (max-width: 767px) {
    #agfk-import {
        #import-agfk-list-modal label {
            font-size: 14px;
            line-height: 17px;
        }
        .data-list {
            margin-top: 10px;
            margin-left: 0;
            margin-right: 0;
            display: block;
            label {
                display: inline;
            }
        }
        .modal-body .info {
            display: inline;
        }
        .form-group {
            display: block;
        }
        .modal-options {
            display: block;
        }
        input[type="radio"] + label::before {
            display: inline-block;
        }
        .results-filter > div {
            margin: 10px 0px;
        }
        #teamliste tbody > tr > td:nth-child(2) {
            padding: 29px 15px 0;
        }

        .sr-icon-grid li {
            flex-basis: 110px !important;
        }
        table.sr-ranking tbody > tr > td {
            padding: 13px 15px 0;
            border: 0;
            vertical-align: top;
            overflow: hidden;
            -ms-text-overflow: ellipsis;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        table.sr-ranking tbody > tr > td {
            position: absolute;
            left: 0;
            right: initial !important;
            margin-top: 38px;
            font-size: 11px;
            opacity: 0.7;
            padding-left: 10px !important;
        }

        .data-table tbody > tr .bar_wrapper .bar_text {
            font-size: 18px;
        }
        .paginate_button.first, .paginate_button.previous, .paginate_button.next, .paginate_button.last {
            display: inline;
        }
        .paginate_button {
            font-size: 18px;
        }
        /* line 225, ../scss/components/_datatables.scss */
        table.sr-ranking tbody > tr > td.radelnde,
        table.sr-ranking tbody > tr > td.merge-left {
            position: absolute;
            left: 0;
            margin-top: 2.40px;
            font-size: 11px;
            opacity: 0.7;
            padding-left: 10px !important;
        }
        .table-desktop {
            display: none;
        }
        .table-mobile {
            display: block;
        }
        #ags-filter {
            display: block;
        }
        .search-label {
            display: none;
        }
        #ags-list-mobile {
            margin-top:20px!important;
        }
        #ags-list-mobile_filter, #ags-list-mobile_length {
            display: none;
        }
        .table-mobile {
            thead {
                visibility: hidden;
                th {
                    height: 0px !important;
                    line-height: 0px !important;
                    padding: 0 !important;
                }
            }
            tbody tr {
                td:first-child {
                    padding-left: 35px;
                }
                td:last-child {
                    padding-right: 37px;
                }
                &:first-child td {
                    border-top: 0;
                }
            }
        }
    }
}
