/* @import files here when migrating parts out of the old css files */

//components
@import "compat-2017";
@import "chat";
@import "templates";
@import "dataTables";
@import "login";
@import "extra";
@import "promotions";
@import "confirm-team-member";
@import "mobileApp";

@import "components/accordion";
@import "components/newsfeed";

//@import "footer";
@import "agfkImport";
@import "agsAdministration";
@import "pagination";
@import "popover";
@import "components/modals";
@import "citysearch";
@import "contentelements";
@import "posterGenerator";
@import "approvalCenter";
@import "adminAuswertung";
@import "testcases";
@import "staedtekarte";
@import "results";
@import "lightbox";
@import "select2";
@import "blog";
@import "dashboard";
@import "typo3-elements";
@import "partner-local";
@import "ork_partner-local";
@import "ork";
@import "helper";
@import "layout";
@import "ride";

//pages
@import "pages/kommune-verwalten";
@import "pages/kommune-registrieren";
@import "pages/einstellungen";
@import "pages/page-dashboard";
@import "pages/mein-km-buch";
@import "pages/mein-team";
@import "pages/mein-team-anlegen-hauptteam";
@import "pages/mein-team-anlegen-unterteam";
@import "pages/kommune-radar";
@import "pages/nutzerverwaltung";
@import "pages/nutzerverwaltung-editieren";
@import "pages/teamverwaltung";
@import "pages/teamverwaltung-editieren";
@import "pages/teamverwaltung-editieren-unterteam";
@import "pages/startpage-results";
@import "pages/auswertung-gesamt";
@import "pages/kommune";
@import "pages/historie-gesamt";
@import "pages/stadtradler-star-tabelle";
@import "pages/teilnehmerkommunen";
@import "pages/registrierung";
@import "pages/auswertung-kommune";
@import "pages/kommune-star-fragment";
@import "pages/kommune-statement-fragment";
@import "pages/hall-of-fame";
@import "pages/account-close";
@import "pages/datenschutz";
@import "pages/koordinatoren-nachmeldung";
@import "pages/auswertung-gesamt-ranking-table";
@import "pages/password-forgot";
@import "pages/poster-generator-page";
@import "pages/print-freigabe";
@import "pages/search-contact";
@import "pages/sonderaktion-radpendler";
@import "pages/sonderaktion-schulradeln";
@import "pages/admin-auswertung";
@import "pages/auswertung-kommune-ptcounter";
@import "pages/schulradeln-import";
@import "pages/sonderaktion-vervalten";
@import "pages/auswertung-sonderaktion";
@import "pages/radelkalender-weiterempfehlen";
@import "pages/kommunenanmeldung-nachmeldung";
@import "pages/wer-radelt-wann";
@import "pages/flyer-generator";
@import "pages/zweifaktor";
@import "pages/sub-city-name-change-modal";

//unified components (uc)
@import "base/uc/global-uc";
@import "base/uc/forms-uc";
@import "base/uc/select2-uc";
@import "base/uc/cke-uc";
@import "base/uc/datepicker-uc";
@import "base/uc/popover-uc";
@import "base/uc/dropdown-uc";
@import "base/uc/quill-uc";

@import "../build/node_modules/quill/dist/quill.core.css";
@import "../build/node_modules/quill/dist/quill.snow.css";

// redesign 2025
@import "global";
@import "base/foundations";
