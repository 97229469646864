.social-link {
	height: 30px;
	display: inline-block;
	border-radius: 50%;
	padding: 7px;
	background: white;
	color: #063547;
	text-decoration: none;
	transition: color 200ms;
	&:hover {
		color: #fff;
	}
}
.social-links {
	.mobile-toggle {
		height: 30px;
		display: inline-block;
		border-radius: 50%;
		padding: 7px;
		background: white;
		color: #063547;
		text-decoration: none;
		transition: color 200ms;
		display: none;
		&:hover {
			color: #fff;
		}
	}
	position: absolute;
	top: 16px;
	right: 30px;
	line-height: 2.6;
	text-align: center;
	font-size: 14px;
	nav {
		li {
			display: inline;
			margin: 3.5px;
			font-size: 16px;
			transition: all 200ms;
			a {
				height: 28px;
				padding: 6px;
			}
		}
	}
}
.social-icons.collapse.in {
	right: 45px;
	li {
		margin: 5px;
		transition: all 200ms;
	}
}
.icon-facebook {
	&:hover {
		background: #3b5998;
	}
}
.icon-twitter,
.icon-social-x {
	&:hover {
		background: #55acee;
	}
}
.icon-instagram {
	&:hover {
		background: linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d, #f56040, #f77737, #fcaf45, #ffdc80);
	}
}
.icon-flickr {
	&:hover {
		background: #0063dc;
	}
}
.icon-youtube {
	&:hover {
		background: #ed413e;
	}
}
.icon-mastodon {
	&:hover {
		background: linear-gradient(180deg, #6364FF, #563ACC);
	}
}
@media (max-width: 767px) {
	.social-links {
		line-height: 1;
		text-indent: -200vw;
		>* {
			text-indent: 0;
		}
		.mobile-toggle {
			position: absolute;
			top: 0;
			right: -12px;
			height: 28px;
			width: 28px;
			display: block;
			padding-top: 8px;
			&:hover {
				color: #063547;
				background-color: #2cbcff;
			}
		}
		nav {
			position: absolute;
			top: 0;
			width: 100px;
			white-space: nowrap;
			li {
				margin: 16px;
			}
		}
	}
}
@media (min-width: 768px) {
	.social-links {
		nav.social-icons.collapse {
			display: block;
			opacity: 1;
		}
	}
}
