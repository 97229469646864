.accordion-template .akkordeon-topics .akkordeon-topic .akkordeon-topic-body .akkordeon-content {
    .ql-editor {
        strong {
            font-weight: bold;
        }
    }
}

.uc {
    .ql-indent-1 {
        padding-left: 3em;
    }
    .ql-indent-2 {
        padding-left: 6em;
    }
    .ql-indent-3 {
        padding-left: 9em;
    }
    .ql-indent-4 {
        padding-left: 12em;
    }
    .ql-indent-5 {
        padding-left: 15em;
    }
    .ql-indent-6 {
        padding-left: 18em;
    }
    .ql-indent-7 {
        padding-left: 21em;
    }
    .ql-indent-8 {
        padding-left: 24em;
    }
    .ql-snow {
        .ql-stroke {
            stroke: #fff;
        }
        .ql-fill {
            fill: #fff;
        }
        strong {
            font-weight: bold;
        }
    }

    .ql-toolbar {
        background: #0086c5;
        border: none;
        border-radius: 8px 8px 0 0;
    }

    .ql-container {
        border: 2px solid #0086c5;
        border-radius: 0 0 8px 8px;
        background: #0086c5;
    }

    .ql-editor {
        background: #063547;
        border-radius: 8px;
    }

    .ql-editor.ql-blank {
        height: 200px;
    }

    .ql-tooltip input[type="text"] {
        color: #000;
    }

    .ql-snow .ql-tooltip[data-mode="link"]::before {
        content: 'Link eintragen: ';
    }

    .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
        content: 'Speichern';
    }
}

