.grid-flex {
	display: grid;
	grid-gap: 60px 30px;
	grid-template-columns: repeat(auto-fill, [col] 300px);
	justify-content: space-around;
	.sponsor {
		grid-column: col 1/span 2;
		grid-row: row 3;
	}
	.stay-row-4 {
		grid-column: col 1/span 2;
		grid-row: row 3;
	}
	.fullwidth-box {
		grid-column: col 1/span 2;
		grid-row: row 3;
	}
}
@media (max-width: 767px) {
	.grid-flex {
		display: block;
		.col {
			margin: 50px 0 50px 15px;
		}
	}
}
