#ork-startpage-auswertung {
    background-color: #063547;
}

p.ride-paragraph {
  text-align: center;
  font-weight: 100;
  font-size: 13px;
}

.startpage-results {
    .container.title-auswertung h2 {
        text-align: center;
        padding-bottom: 30px;
    }

    .top10btn {
        .custom-dropdown {
            min-width: 270px !important;
            .dropdown-toggle {
                min-width: 270px !important;
            }
        }
    }
    .sr-icon-grid {
        li {
            p {
                padding-left: 5px;
                padding-right: 5px;
            }
        }
    }
    .icon-title {
        display: flex;
        .info_small {
            font-size: 20px;
            opacity: 0.5;
            height: 20px;
            width: 10px;
            margin-left: 7px;
            bottom: 3px;
        }
    }
    .info_small {
        &::before {
            left: 3px;
        }
    }

	@media (max-width: $breakpoint-mobile) {
		&.uc {
            .btn {
			    width: auto;
            }
        }
        .container.title-auswertung h2 {
            text-align: left;
            padding-bottom: 10px;
        }
        .sr-icon-grid {
            li {
                flex-basis: 100px !important;
            }
            margin: 10px 15px 30px;
        }
        .top10btn {
            .custom-dropdown {
                min-width: 270px !important;
                .dropdown-toggle {
                    min-width: 270px !important;
                }
            }
        }

        p.ride-paragraph {
            text-align: left;
        }

    }

}