.badge {
	display: inline-block;
	min-width: 10px;
	padding: 3px 7px;
	font-size: 12px;
	font-weight: normal;
	color: #fff;
	line-height: 1;
	vertical-align: middle;
	white-space: nowrap;
	text-align: center;
	background-color: #052836;
	border-radius: 50% 0 50% 50%;
	&:empty {
		display: none;
	}
}
.btn {
	.badge {
		position: relative;
		top: -1px;
	}
}
.btn-xs {
	.badge {
		top: 0;
		padding: 1px 5px;
	}
}
.btn-group-xs {
	>.btn {
		.badge {
			top: 0;
			top: 0;
			padding: 1px 5px;
			padding: 1px 5px;
		}
	}
}
.list-group-item.active {
	>.badge {
		color: #fff;
		background-color: #286AA6;
	}
}
.nav-pills {
	>.active {
		>a {
			>.badge {
				color: #fff;
				background-color: #286AA6;
			}
		}
	}
	>li {
		>a {
			>.badge {
				margin-left: 3px;
			}
		}
	}
}
.list-group-item {
	>.badge {
		float: right;
		+ {
			.badge {
				margin-right: 5px;
			}
		}
	}
}
a.badge {
	&:hover {
		color: #fff;
		text-decoration: none;
		cursor: pointer;
	}
	&:focus {
		color: #fff;
		text-decoration: none;
		cursor: pointer;
	}
}
