// overall styling rules for ORK - Online Radel Kalender

// Kommune anmelden
#sr_kommunenregistrierung_get_prevyear {
    input {
        color:#fff;
    }
}

.dashboard {
    .tab-pane {
        padding-top:0;
    }
}

.commune-search input {
    width: 100%;
    height: 52px;
    box-shadow: 0 2px 20px rgba(0,0,0,0.06);
    border-radius: 25px;
    background-color: #ffffff;
    border: 1px solid #efecec;
    padding-left: 30px;
    font-size: 17px;
    &::after {
        font-family: 'sr-icons', 'FontAwesome';
        content: "\e947";
        font-size: 20px;
        position: absolute;
        top: 10px;
    }
}

.team-tags {
    .form-group {
        min-height:40px;
        margin-bottom:0;
        white-space: nowrap;
    }
}

// dashboard headlines
section.dashboard .col > h2 {
    padding-bottom: 15px;
    margin-top: 0;
    position: relative; 
    a {
        max-width: 280px;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #fff;
    }
}
    

/* tablet */
@media (min-width: 768px) and (max-width: 991px) {
    section {
        &.dashboard {
            > main {
                padding: 10px 24px;
            }
        }
    }
    .col-sm-centered {
        margin: 0 auto;
        float: none;
    }
    .sidebar-menu {
        &.col-sm-centered {
            width: 50%;
        }
    }
    .mobile-nav {
        .dropdown {
            max-width: unset;
            padding: 0 15px;
            &.open {
                .dropdown-menu {
                    width: 95%;
                    margin: 0 10px;
                }
            }
        }
    }
}

/* all mobile */
@media (max-width: 767px) {
    section {
        &.dashboard {
            .nav-pills {
                max-width: 100%;
            }
            > main {
                padding: 10px 30px;
            }
        }
    }
    .col-sm-centered {
        margin:0 auto;
        float:none;
    }
}