.form-control {
	&:focus {
		border-color: #0086C5;
		outline: 0;
		-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 134, 197, 0.6);
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 134, 197, 0.6);
	}
}
.input-sm {
	height: 34px;
	padding: 7px 14px;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 0;
}
.input-group-sm {
	>.form-control {
		height: 34px;
		padding: 7px 14px;
		font-size: 12px;
		line-height: 1.5;
		border-radius: 0;
	}
	>.input-group-addon {
		height: 34px;
		padding: 7px 14px;
		font-size: 12px;
		line-height: 1.5;
		border-radius: 0;
	}
	>.input-group-btn {
		>.btn {
			height: 34px;
			padding: 7px 14px;
			font-size: 12px;
			line-height: 1.5;
			border-radius: 0;
		}
		>select.btn {
			height: 34px;
			line-height: 34px;
		}
		>textarea.btn {
			height: auto;
		}
		>select[multiple].btn {
			height: auto;
		}
	}
	>select.form-control {
		height: 34px;
		line-height: 34px;
	}
	>select.input-group-addon {
		height: 34px;
		line-height: 34px;
	}
	>textarea.form-control {
		height: auto;
	}
	>textarea.input-group-addon {
		height: auto;
	}
	>select[multiple].form-control {
		height: auto;
	}
	>select[multiple].input-group-addon {
		height: auto;
	}
}
select.input-sm {
	height: 34px;
	line-height: 34px;
}
textarea.input-sm {
	height: auto;
}
select[multiple].input-sm {
	height: auto;
}
.input-lg {
	height: 53px;
	padding: 14px 28px;
	font-size: 18px;
	line-height: 1.25;
	border-radius: 0;
}
.input-group-lg {
	>.form-control {
		height: 53px;
		padding: 14px 28px;
		font-size: 18px;
		line-height: 1.25;
		border-radius: 0;
	}
	>.input-group-addon {
		height: 53px;
		padding: 14px 28px;
		font-size: 18px;
		line-height: 1.25;
		border-radius: 0;
	}
	>.input-group-btn {
		>.btn {
			height: 53px;
			padding: 14px 28px;
			font-size: 18px;
			line-height: 1.25;
			border-radius: 0;
		}
		>select.btn {
			height: 53px;
			line-height: 53px;
		}
		>textarea.btn {
			height: auto;
		}
		>select[multiple].btn {
			height: auto;
		}
	}
	>select.form-control {
		height: 53px;
		line-height: 53px;
	}
	>select.input-group-addon {
		height: 53px;
		line-height: 53px;
	}
	>textarea.form-control {
		height: auto;
	}
	>textarea.input-group-addon {
		height: auto;
	}
	>select[multiple].form-control {
		height: auto;
	}
	>select[multiple].input-group-addon {
		height: auto;
	}
}
select.input-lg {
	height: 53px;
	line-height: 53px;
}
textarea.input-lg {
	height: auto;
}
select[multiple].input-lg {
	height: auto;
}
.has-success {
	.help-block {
		color: #3c763d;
	}
	.control-label {
		color: #3c763d;
	}
	.radio {
		color: #3c763d;
	}
	.checkbox {
		color: #3c763d;
	}
	.radio-inline {
		color: #3c763d;
	}
	.checkbox-inline {
		color: #3c763d;
	}
	.form-control {
		border-color: #3c763d;
		-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
		&:focus {
			border-color: #2b542c;
			-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
			box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
		}
	}
	.input-group-addon {
		color: #3c763d;
		border-color: #3c763d;
		background-color: #dff0d8;
	}
	.form-control-feedback {
		color: #3c763d;
	}
}
.has-success.radio {
	label {
		color: #3c763d;
	}
}
.has-success.checkbox {
	label {
		color: #3c763d;
	}
}
.has-success.radio-inline {
	label {
		color: #3c763d;
	}
}
.has-success.checkbox-inline {
	label {
		color: #3c763d;
	}
}
.has-warning {
	.help-block {
		color: #8a6d3b;
	}
	.control-label {
		color: #8a6d3b;
	}
	.radio {
		color: #8a6d3b;
	}
	.checkbox {
		color: #8a6d3b;
	}
	.radio-inline {
		color: #8a6d3b;
	}
	.checkbox-inline {
		color: #8a6d3b;
	}
	.form-control {
		border-color: #8a6d3b;
		-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
		&:focus {
			border-color: #66512c;
			-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
			box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
		}
	}
	.input-group-addon {
		color: #8a6d3b;
		border-color: #8a6d3b;
		background-color: #fcf8e3;
	}
	.form-control-feedback {
		color: #8a6d3b;
	}
}
.has-warning.radio {
	label {
		color: #8a6d3b;
	}
}
.has-warning.checkbox {
	label {
		color: #8a6d3b;
	}
}
.has-warning.radio-inline {
	label {
		color: #8a6d3b;
	}
}
.has-warning.checkbox-inline {
	label {
		color: #8a6d3b;
	}
}
.has-error {
	.help-block {
		color: #a94442;
	}
	.control-label {
		color: #a94442;
	}
	.radio {
		color: #a94442;
	}
	.checkbox {
		color: #a94442;
	}
	.radio-inline {
		color: #a94442;
	}
	.checkbox-inline {
		color: #a94442;
	}
	.form-control {
		border-color: #a94442;
		-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
		&:focus {
			border-color: #843534;
			-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
			box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
		}
	}
	.input-group-addon {
		color: #a94442;
		border-color: #a94442;
		background-color: #f2dede;
	}
	.form-control-feedback {
		color: #a94442;
	}
}
.has-error.radio {
	label {
		color: #a94442;
	}
}
.has-error.checkbox {
	label {
		color: #a94442;
	}
}
.has-error.radio-inline {
	label {
		color: #a94442;
	}
}
.has-error.checkbox-inline {
	label {
		color: #a94442;
	}
}
