.teamverwaltung {

    .spinner {
        z-index: 5000;
        img {
            margin-top: 25%;
            -webkit-animation: rotation 1s linear 0s infinite;
            -moz-animation: rotation 1s linear 0s infinite;
            -o-animation: rotation 1s linear 0s infinite;
            animation: rotation 1s linear 0s infinite;
        }
    }

    @-webkit-keyframes rotation { from { transform: rotate(0deg); } to { transform: rotate(359deg); }  }
    @-moz-keyframes rotation { from { transform: rotate(0deg); } to { transform: rotate(359deg); }  }
        @-o-keyframes rotation { from { transform: rotate(0deg); } to { transform: rotate(359deg); }  }
            @keyframes rotation { from { transform: rotate(0deg); } to { transform: rotate(359deg); }  }

    @media (min-width: 768px) {
        section.dashboard {
            .sr-icon-grid {
                >li {
                    flex-basis: 0% !important;
                }
            }
        }
        table.sr-ranking {
            tbody {
                >tr {
                    >td {
                        &:first-of-type {
                            span {
                                height: 40px;
                                width: 40px;
                                display: block;
                                padding: 15px 20px 15px 14px;
                                border-radius: 50%;
                                margin-left: 2px;
                                background: #052836;
                                font-size: 15px;
                                font-weight: 500;
                                line-height: 12px;
                            }
                        }
                    }
                }
            }
        }
        .md-px-0 {
            padding-left: 0px !important;
            padding-right: 0px !important;
        }
        .col-min-130 {
            min-width: 30px;
        }
        .col-min-200 {
            min-width: 20px;
        }
        .results-filter {
            >div {
                margin: 0px 30px;
            }
            margin-right: 0px;
        }
        #legend-hide {
            float: none;
            margin: 30px auto;
            display: block;
            width: 250px;
        }
    }
    .status {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #052836;
        margin: 0 auto;
    }
    .status.status-green {
        background-color: #8CB82B;
    }
    .status.status-red {
        background-color: #D0021B;
    }
    .status.status-yellow {
        background-color: #F8E71C;
    }
    .status.status-orange {
        background-color: #F96C0A;
    }
    .status-small {
        &:before {
            content: "";
            display: block;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            float: left;
            margin: 3px 6px 0 0;
        }
    }
    .status-small.status-green {
        &:before {
            background-color: #8CB82B;
        }
    }
    .status-small.status-red {
        &:before {
            background-color: #D0021B;
        }
    }
    .status-small.status-yellow {
        &:before {
            background-color: #F8E71C;
        }
    }
    .status-small.status-orange {
        &:before {
            background-color: #F96C0A;
        }
    }
    .dataTables_filter {
        display: none;
    }
    .arrow-invert {
        .arrow-links {
            li {
                a {
                    &::before {
                        float: left;
                        margin-right: 3px;
                        transform: rotate(-135deg);
                    }
                    &:hover {
                        &::before {
                            transform: rotate(-90deg);
                        }
                    }
                }
            }
        }
    }
    table.sr-ranking {
        span.aktiv {
            &::after {
                content: none;
            }
        }
        tbody {
            >tr {
                td {
                    font-size: 15px;
                    color: rgba(255,255,255,0.87);
                }
                border-bottom: 1px solid #052836;
            }
            >tr.expanded {
                >td {
                    &:first-of-type {
                        span {
                            background: #fff;
                        }
                    }
                }
            }
        }
    }
    .text-15 {
        font-size: 15px !important;
    }
    #tableSortXs {
        .btn {
            padding-left: inherit;
            padding-right: inherit;
            padding-top: 4px;
            padding-bottom: 4px;
            padding-left: inherit;
            padding-right: inherit;
            padding-top: 4px;
            padding-bottom: 4px;
            font-size: 11px;
        }
        .btn.btn-default.active {
            background-color: rgba(2,27,38,0.8) !important;
            border: 1px solid rgba(0, 134, 197, 0.4) !important;
            background-color: rgba(2,27,38,0.8) !important;
            border: 1px solid rgba(0, 134, 197, 0.4) !important;
        }
    }
    .data-unit {
        font-size: 13px;
    }
    td {
        i[class*='-dude'] {
            padding-left: 5px;
            font-size: 30px;
            color: #0086C5;
            vertical-align: middle;
        }
    }
    .results-filter-wrapper {
        display: flex;
        flex-wrap: wrap;
    }
    .text-md {
        font-size: 14px !important;
    }
    .data-table {
        tbody {
            >tr {
                .bar_wrapper {
                    .bar_bg {
                        width: 100%;
                    }
                }
            }
        }
    }
    .results-filter {
        display: flex;
        flex-direction: row;
        width: 100%;
        >div {
            flex: 1 1 auto;
            text-align: center;
            margin: 0px 50px;
            &:first-child {
                margin-left: 0px;
            }
            &:last-child {
                margin-right: 0px;
            }
        }
    }
    #legend-wrapper {
        p {
            line-height: 3;
        }
    }
    section.dark-bg {
        color: rgba(255,255,255,0.8) !important;
    }
    #teamliste_wrapper {
        div.dataTables_paginate {
            text-align: center !important;
            padding-top: 50px;
            padding-bottom: 50px;
        }
    }
    div.dataTables_paginate {
        text-align: center !important;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .nav {
        >li {
            >a {
                position: relative;
                display: block;
                padding: 7px 15px;
                font-size: 13px;
                font-weight: 500;
            }
        }
    }
    .no-sort {
        &::after {
            display: none !important;
        }
        pointer-events: none !important;
        cursor: default !important;
    }
    .paginate_button.current {
        color: #0086C5 !important;
        font-weight: 600;
    }
    .paginate_button {
        font-size: 18px;
        position: relative;
    }
    .pagination-icon {
        height: 25px;
        fill: white;
        position: absolute;
        top: 8px;
        left: 0px;
    }

    #teamliste_length {
        display: none;
    }
    td.expanded {
        padding: 0px 0px !important;
        background-color: #fff;
        td {
            color: #454545 !important;
            a {
                color: #454545 !important;
            }
        }
    }
    tr.shown {
        background-color: #0086C5;
    }
    .w240 {
        width: 240px;
    }
    .w55 {
        width: 55px;
    }
    .subteamlink {
        color: #0086C5;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        cursor: pointer;
        max-width: 150px;
        position: relative;
        &:after {
            position: absolute;
            top: 5px;
            right: 15px;
            font-size: 6px;
            height: 10px;
            width: 10px;
            border: none;
            background: none;
            text-align: center;
            font-family: sr-icons;
            color: #0086C5;
            content: "\e941" !important;
            transform: rotate(-90deg);
            top: 5px;
            right: 15px;
        }
    }
    .shown {
        .subteamlink {
            &:after {
                color: #0E4761;
                transform: rotate(0deg);
                top: 0px;
                right: 10px;
            }
            color: #0E4761;
        }
    }
    .action-button-list {
        display: flex;
        flex-direction: row;
        position: relative;
        min-width: 160px;
        a {
            padding: 10px 2px;
        }
    }
    .action-wrapper {
        min-width: 180px;
    }
    .data-table {
        tbody > tr {
            &.shown,
            &.expanded-child {
                td {
                    &:first-child {
                        padding-left: 10px;
                    }
                }
            }
        } 
    }

    div.dataTables_wrapper {
        position: relative;
        div.dataTables_processing {
            z-index: 5000;
            position: absolute;
            top: 50px;
            left: 50%;
            width: 200px;
            margin-left: -100px;
            margin-top: -26px;
            text-align: center;
            padding: 1em 0;
        }
    }
    #teamliste.loading {
        opacity: 0.3;
    }
    #exportDropdown {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    #errorModal {
        .modal-dialog {
            .modal-content {
                .modal-body {
                    min-height: 10px;
                }
                border-radius: 4px;
            }
        }
    }
    #errorMessage {
        display: flex;
        color: #454545;
    }
    @media (max-width: 767px) {
        table.sr-ranking {
            tbody {
                >tr {
                    >td.radelnde {
                        position: absolute;
                        left: 0;
                        margin-top: 24px;
                        font-size: 11px;
                        opacity: 0.7;
                        padding-left: 10px !important;
                    }
                    >td.merge-left {
                        position: absolute;
                        left: 0;
                        margin-top: 24px;
                        font-size: 11px;
                        opacity: 0.7;
                        padding-left: 10px !important;
                    }
                    >td {
                        border: 0;
                        vertical-align: top;
                        overflow: hidden;
                        -ms-text-overflow: ellipsis;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        position: absolute;
                        left: 0;
                        right: initial !important;
                        margin-top: 28px;
                        font-size: 11px;
                        opacity: 0.7;
                        padding-left: 20px !important;
                        width: 45%;
                    }
                }
            }
        }
        .col-min-130 {
            min-width: 30px;
        }
        .col-min-200 {
            min-width: 20px;
        }
        .results-filter {
            flex-direction: column;
            margin-right: 0px;
            >div {
                margin: 10px 0px;
            }
        }
        #teamliste_length {
            display: none;
        }
        #teamliste {
            tbody {
                >tr {
                    >td {
                        &:nth-child(2) {
                            padding: 29px 15px 0;
                        }
                    }
                }
            }
        }
        .sr-icon-grid {
            li {
                flex-basis: 110px !important;
            }
        }
        .btn {
            width: 100%;
        }
        .action-wrapper {
            margin-top: 4px !important;
            margin-left: 42vw;
        }
        .action-button-list {
            justify-content: flex-end;
        }
        .xs-text-13 {
            font-size: 13px !important;
        }
        .data-table {
            tbody {
                >tr {
                    .bar_wrapper {
                        .bar_text {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
    @media (min-width: 1440px) {
        .col-min-130 {
            min-width: 130px;
        }
        .col-min-200 {
            min-width: 180px;
        }
        .results-filter {
            >div {
                margin: 0px 50px;
            }
            margin-right: 0px;
        }
        #legend-hide {
            float: right;
        }
    }
    @media (max-width: 479px) {
        .paginate_button.first {
            display: inline;
        }
        .paginate_button.previous {
            display: inline;
        }
        .paginate_button.next {
            display: inline;
        }
        .paginate_button.last {
            display: inline;
        }
        .paginate_button {
            font-size: 18px;
        }

        .sort-buttons .btn-group {
            margin-bottom: 10px !important;
        }

        .dataTables_wrapper {
            margin: 0 0 0 -14px !important;
        }

    }
    @media (max-width: 768px) {
        .numberOfResults {
            margin-top: -250px;
            font-weight: bold;
            margin-bottom: 130px;
        }
    }

}