// styling for default typo3 content elements

/* typo3 content appearance spacing - space before - space after */
.space-before-extra-small {
    margin-top: 5px;
}
.space-before-small {
    margin-top: 15px;
}
.space-before-medium {
    margin-top: 30px;
}
.space-before-large {
    margin-top: 60px;
}
.space-before-extra-large {
    margin-top: 100px;
}

.space-after-extra-small {
    margin-bottom: 5px;
}
.space-after-small {
    margin-bottom: 15px;
}
.space-after-medium {
    margin-bottom: 30px;
}
.space-after-large {
    margin-bottom: 60px;
}
.space-after-extra-large {
    margin-bottom: 100px;
}

// Type Header
.frame-type-header {
    header {
        padding-left: 15px;
        padding-right: 15px;
    }
    h1, h2, h3, h4  {
        text-align:center;
    }
    h1 {
        margin-bottom: 60px;
    }
}

// headline M01 Text style
/*
.frame-default {
    h2 {
        margin-top: 0px;
        margin-bottom: 30px;
    }
}*/

/* tablet and up */
@media (min-width: 768px) and (max-width: 992px) {
    // Type Header
    .frame-type-header {
        header {
            padding-left: 9px;
            padding-right: 9px;
        }
        h3, h4 {
            text-align: center;
        }
        h1 {
            text-align: center;
            margin-bottom: 40px;
        }
        h2 {
            text-align: left;
        }
    }
    .space-after-large {
        margin-bottom: 45px;
    }
    .space-before-extra-large {
        margin-top: 70px;
    }
    .space-after-extra-large {
        margin-bottom: 70px;
    }
}

/* mobile */
@media (max-width: 767px) {
    // Type Header
    .frame-type-header {
        h1, h2, h3, h4  {
            text-align:left;
        }
        h1 {
            margin-bottom: 30px;
        }
    }
    .space-before-extra-large {
        margin-bottom: 70px;
    }
    .space-after-extra-large {
        margin-bottom: 70px;
    }
}