.logo-kommune {
	img {
		max-height: 56px;
	}
}
.termine {
	p {
		&::first-line {
			font-weight: 500;
		}
	}
}
