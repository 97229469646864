/* flex layout for sticky footer */
body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}
.sr_start_content {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    > .full-height {
        flex-grow: 1;
    }
}