/* jquery.dataTables.css default overwrite */
table {
    &.dataTable {
        tbody {
            tr {
                background-color: transparent;

                &.selected {
                    background-color: #0086C5;
                }
            }
        }
    }
}

.dataTables_wrapper {
    /* Pagination */
    div.dataTables_paginate {
        text-align: center !important;
        padding-top: 55px;
        padding-bottom: 50px;
    }
    .paginate_button {
        font-size: 16px;
        position: relative;
        &.current {
            color: #0086C5 !important;
            font-weight: 600;
        }
    }
    .pagination-icon {
        height: 15px;
        fill: white;
        position: absolute;
        top: 12px;
        left: 0px;
    }
    /* End Pagination */
}

.DTFC_RightWrapper,
.DTFC_LeftWrapper {
    table.dataTable {
        margin-bottom: 0 !important;
    }
    .DTFC_LeftBodyWrapper,
    .DTFC_RightBodyWrapper,
    .DTFC_LeftFootWrapper,
    .DTFC_RightFootWrapper {
        table.dataTable {
            margin-top: 0 !important;
        }
    }
}

table {
    &.dataTable {
        thead {
            .sorting,
            .sorting_asc,
            .sorting_desc,
            .sorting_asc_disabled,
            .sorting_desc_disabled {
                background-image:none!important;
            }
        }
    }
}

/* styling of last update below datatables */
.last-update {
    margin-top: 80px;
    text-align: center;
}

/* special treat for result table */
.gab-bottom-result {
    margin-bottom: 50px !important;
}
@media (max-width: 992px) {
    .gab-bottom-result {
        margin-bottom: 20px !important;
    }
}

/* tablet 
@media (min-width: 768px) and (max-width: 992px) {

}*/

/* all mobile */
@media (max-width: 767px) {
    #staedteliste {
        &.table.sr-ranking tbody > tr > td {
            padding: 13px 30px 20px;
        }
        thead {
            visibility: collapse;
        }
        tbody {
            .text-lg {
                font-size: 13px;
                line-height: 17px;
                letter-spacing: 0.3px;
                opacity: 0.87;
            }
            .text-sm {
                font-size: 11px;
                line-height: 18px;
                letter-spacing: 0.37px;
                opacity: 0.8;
                font-weight: 300;
            }
            .kilometer {
                font-size: 18px;
                line-height: 42px;
                opacity: 0.87;
            }
            .kilometer-einheit {
                font-size: 13px;
                letter-spacing: 0.43px;
                opacity: 0.8;
            }
        }
    }
    .last-update {
        margin-top: 0;
        text-align: left;
    }
}