.btn-group {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	>.btn {
		position: relative;
		float: left;
		&:hover {
			z-index: 2;
		}
		&:focus {
			z-index: 2;
		}
		&:active {
			z-index: 2;
		}
		&:not(:first-child) {
			&:not(:last-child) {
				&:not(.dropdown-toggle) {
					border-radius: 0;
				}
			}
		}
		&:first-child {
			margin-left: 0;
			&:not(:last-child) {
				&:not(.dropdown-toggle) {
					border-bottom-right-radius: 0;
					border-top-right-radius: 0;
				}
			}
		}
		&:last-child {
			&:not(:first-child) {
				border-bottom-left-radius: 0;
				border-top-left-radius: 0;
			}
		}
		+ {
			.dropdown-toggle {
				padding-left: 8px;
				padding-right: 8px;
			}
		}
	}
	>.btn.active {
		z-index: 2;
	}
	.btn {
		+ {
			.btn {
				margin-left: -1px;
			}
			.btn-group {
				margin-left: -1px;
			}
		}
	}
	.btn-group {
		+ {
			.btn {
				margin-left: -1px;
			}
			.btn-group {
				margin-left: -1px;
			}
		}
	}
	>.dropdown-toggle {
		&:not(:first-child) {
			border-bottom-left-radius: 0;
			border-top-left-radius: 0;
		}
	}
	>.btn-group {
		float: left;
		&:not(:first-child) {
			&:not(:last-child) {
				>.btn {
					border-radius: 0;
				}
			}
		}
		&:first-child {
			&:not(:last-child) {
				>.btn {
					&:last-child {
						border-bottom-right-radius: 0;
						border-top-right-radius: 0;
					}
				}
				>.dropdown-toggle {
					border-bottom-right-radius: 0;
					border-top-right-radius: 0;
				}
			}
		}
		&:last-child {
			&:not(:first-child) {
				>.btn {
					&:first-child {
						border-bottom-left-radius: 0;
						border-top-left-radius: 0;
					}
				}
			}
		}
	}
	.dropdown-toggle {
		&:active {
			outline: 0;
		}
	}
	>.btn-lg {
		+ {
			.dropdown-toggle {
				padding-left: 12px;
				padding-right: 12px;
			}
		}
	}
}
.btn-group-vertical {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	>.btn {
		position: relative;
		float: left;
		display: block;
		float: none;
		width: 100%;
		max-width: 100%;
		&:hover {
			z-index: 2;
		}
		&:focus {
			z-index: 2;
		}
		&:active {
			z-index: 2;
		}
		+ {
			.btn {
				margin-top: -1px;
				margin-left: 0;
			}
			.btn-group {
				margin-top: -1px;
				margin-left: 0;
			}
		}
		&:not(:first-child) {
			&:not(:last-child) {
				border-radius: 0;
			}
		}
		&:first-child {
			&:not(:last-child) {
				border-top-right-radius: 20px;
				border-top-left-radius: 20px;
				border-bottom-right-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
		&:last-child {
			&:not(:first-child) {
				border-top-right-radius: 0;
				border-top-left-radius: 0;
				border-bottom-right-radius: 20px;
				border-bottom-left-radius: 20px;
			}
		}
	}
	>.btn.active {
		z-index: 2;
	}
	>.btn-group {
		display: block;
		float: none;
		width: 100%;
		max-width: 100%;
		>.btn {
			display: block;
			float: none;
			width: 100%;
			max-width: 100%;
			float: none;
		}
		+ {
			.btn {
				margin-top: -1px;
				margin-left: 0;
			}
			.btn-group {
				margin-top: -1px;
				margin-left: 0;
			}
		}
		&:not(:first-child) {
			&:not(:last-child) {
				>.btn {
					border-radius: 0;
				}
			}
		}
		&:first-child {
			&:not(:last-child) {
				>.btn {
					&:last-child {
						border-bottom-right-radius: 0;
						border-bottom-left-radius: 0;
					}
				}
				>.dropdown-toggle {
					border-bottom-right-radius: 0;
					border-bottom-left-radius: 0;
				}
			}
		}
		&:last-child {
			&:not(:first-child) {
				>.btn {
					&:first-child {
						border-top-right-radius: 0;
						border-top-left-radius: 0;
					}
				}
			}
		}
	}
}
.btn-toolbar {
	margin-left: -5px;
	.btn {
		float: left;
	}
	.btn-group {
		float: left;
	}
	.input-group {
		float: left;
	}
	>.btn {
		margin-left: 5px;
	}
	>.btn-group {
		margin-left: 5px;
	}
	>.input-group {
		margin-left: 5px;
	}
}
.btn-group.open {
	.dropdown-toggle {
		outline: 0;
		-webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
		box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	}
	.dropdown-toggle.btn-link {
		-webkit-box-shadow: none;
		box-shadow: none;
	}
}
.btn-group-lg.btn-group {
	>.btn {
		+ {
			.dropdown-toggle {
				padding-left: 12px;
				padding-right: 12px;
			}
		}
	}
}
.btn {
	.caret {
		margin-left: 0;
	}
}
.btn-lg {
	.caret {
		border-width: 5px 5px 0;
		border-bottom-width: 0;
	}
}
.btn-group-lg {
	>.btn {
		.caret {
			border-width: 5px 5px 0;
			border-bottom-width: 0;
		}
	}
}
.dropup {
	.btn-lg {
		.caret {
			border-width: 0 5px 5px;
		}
	}
	.btn-group-lg {
		>.btn {
			.caret {
				border-width: 0 5px 5px;
			}
		}
	}
}
.btn-group-justified {
	display: table;
	width: 100%;
	table-layout: fixed;
	border-collapse: separate;
	>.btn {
		float: none;
		display: table-cell;
		width: 1%;
	}
	>.btn-group {
		float: none;
		display: table-cell;
		width: 1%;
		.btn {
			width: 100%;
		}
		.dropdown-menu {
			left: auto;
		}
	}
}
[data-toggle="buttons"] {
	>.btn {
		input[type="radio"] {
			position: absolute;
			clip: rect(0, 0, 0, 0);
			pointer-events: none;
		}
		input[type="checkbox"] {
			position: absolute;
			clip: rect(0, 0, 0, 0);
			pointer-events: none;
		}
	}
	>.btn-group {
		>.btn {
			input[type="radio"] {
				position: absolute;
				clip: rect(0, 0, 0, 0);
				pointer-events: none;
			}
			input[type="checkbox"] {
				position: absolute;
				clip: rect(0, 0, 0, 0);
				pointer-events: none;
			}
		}
	}
}
