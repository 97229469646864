/*
Styling for all new (2021) Typo3 Content Elements (CE)
Named by module and number
*/

.ce-module {
    h1 {
        text-align:center;
        margin-top: 20px;
        margin-bottom:40px;
    }
    > h2, > h3, > h4 {
        text-align: center;
    }
    h1.grid-header {
        font-size:32px;
        text-align: center;
        margin-bottom: 40px;
    }
    &.column {
        .col-md-12 {
            > h1 {
                margin-top:30px;
            }
            > h2 {
                margin-bottom: 30px;
            }
            > h3 {
                margin-bottom: 60px;
            }
        }
        .header {
            justify-content: center;
        }
    }
    p {
        font-size:14px;
        line-height:22px;
        font-weight:300;
        margin: 0 0 20px;
        sub {
            font-size:11px;
            vertical-align: baseline;
        }
        .intro {
            font-size: 20px;
            line-height: 26px;
        }
    }
    strong {
        font-weight:500;
    }
    ul {
        padding-left:0;
        list-style:none;
        position: relative;
        li {
            font-size:14px;
            line-height:22px;
            font-weight:300;
            margin: 0 0 10px 5px;
            padding-left: 20px;
            position: relative;
            &::before {
                content:"·";
                font-size: 30px;
                position: absolute;
                left: -5px;
            }
        }
    }
    .nav-pills li {
        font-size: 11px;
        line-height: 13px;
        font-weight: 500;
        margin: 0;
        padding: 0;
        &::before {
            display: none;
        }
        a {
            padding: 5px 15px;
        }
        p {
            font-size: 11px;
            line-height: 13px;
            font-weight: 500;
            margin: 0;
        }
        .btn-default {
            border-color: #8CB82B;
        }
    }
    // for custom class on col- content element
    &.headline-left {
        .col-md-12 {
            h2 {
                text-align: left;
                margin-bottom: 10px;
            }
        }
    }
    &.headline-center-desktop {
        .col-md-12 {
            > h2 {
                text-align: center;
            }
            > h3 {
                text-align: center;
            }
        }
    }
    .info {
        color: rgba(51,51,61,0.6);
        &::before {
            top: 8px;
        }
        p {
            letter-spacing: 0.3px;
            line-height: 18px;
        }
        a {
            color: rgba(0, 134, 197,0.6);
        }
    }
    .carousel-indicators {
        li {
            margin:2px 6px 2px 6px;;
            padding:0;
            &::before {
                content:"";
            }
        }
    }
    a, p a {
        color:#0086C5;
        font-weight:500;
    }
    .btn-primary {
        color: #fff;
    }
    .col {
        position: relative;
        min-height: 1px;
        padding-left: 15px;
        padding-right: 15px;
    }
    h2 {
        &.gridheader {
            margin-bottom:20px;
        }
    }
    .image-description {
        font-size:11px;
        line-height:16px;
        font-weight:300;
        margin-top:5px;
    }
    /* start slider homepage header */
    &.shop__slideshow {

        .slide-content {
            width: 570px;
            background-color: #0086C5;
            padding:153px 63px 157px 63px;
            position: absolute;
            height:670px;
        }
        .slide-image {
            img {
                width: 100%;
                padding-left: 570px;
                min-height:670px;
                object-fit: cover;
                object-position: left;
            }
        }
        .shop-slideshow__heading {
            font-size:56px;
            line-height:60px;
            font-weight:normal;
            margin-bottom:16px;
            text-align: left;
        }
        .slider-text {
            font-size:18px;
            line-height:32px;
        }
        .btn-default {
            color:#fff;
            border:1px solid #fff;
            border-radius:20px;
            margin-top:70px;
            &:hover {
                color:#fff;
                background-color: rgba(69,69,69,0.07);
            }
        }
        .carousel-indicators {
            bottom:20px;
        }
        /* increased slide speed, default 0.6s */
        .carousel-inner > .item {
            -webkit-transition: -webkit-transform 0.3s ease-in-out;
            -moz-transition: -moz-transform 0.3s ease-in-out;
            -o-transition: -o-transform 0.3s ease-in-out;
            transition: transform 0.3s ease-in-out;
        }
    }
    h1, h2, h3, h4, h5, h6 {
        &.ce-headline-left {
            text-align: left;
        }
        &.ce-headline-center {
            text-align: center;
        }
        &.ce-headline-right {
            text-align: right;
        }
    }
}

.ce-container {
    .container-header {
        .justify-center {
            text-align: center;
        }
    }
}

/* Text Modul */
.m01-text {
    .m01-centered {
        h2, h3 {
            text-align: center;
        }
        /*
        &.col {
            margin-bottom: 30px;
        }
        */
    }
    h2 {
        margin-top:0;
        margin-bottom:30px;
        text-align: left;
    }
    h3 {
        margin-top:0;
        margin-bottom:30px;
        text-align: left;
    }
    h4 {
        margin-top: 30px;
    }
    p {
        margin: 0 0 10px;
    }
    h3.header-subheader {
        margin-top: 50px;
    }
    table {
        border-collapse: separate;
        border: 1px solid rgba(0,0,0,0.8);
        border-radius: 4px;
        p {
            margin: 0;
        }
        strong {
            color: #979797;
            letter-spacing: 0.28px;
        }
        td, th {
            border-left: 1px solid rgba(69,69,69,0.2);
            border-top: 1px solid rgba(69,69,69,0.2);
            border-right: 0;
            border-bottom: 0;
            padding: 0 25px 0 25px;
            &:first-child {
                border-left: none;
            }
        }
        tr {
            &:hover {
                background: #f4f4f4;    
                transition: all 0.1s ease-in-out;
            }
            &:first-child {
                td, th {
                    border-top: 0;
                }
            }
        }
    }
}
/* Typo3 Text */ 
.frame-type-text {
    h2 {
        margin-top: 0;
        margin-bottom: 30px;
    }
}

/* Bild-Text Modul (Querformat) */
.m03-text-bild {
    h2 {
        text-align: left;
        margin-top: 0;
        margin-bottom: 28px;
    }
    h4 {
        margin-top: 40px;
        margin-bottom: 24px;
    }
    .m03-outer {
        float: left;
    }
    .m03-inner {
        padding-right: 60px;
    }
    img {
        border-radius: 7.5px;
        width: 100%;
    }
    .frame {
        img {
            border-radius: 0;
            max-width: 100%;
            height: auto;
            width: auto;
        }
        .ce-gallery {
            .ce-column {
                display: inline-block;
            }
        }
    }
}

/* Intro Text */
.m05-intro-text {
    font-size:20px;
    line-height:29px;
    font-weight:300;
    padding:0 75px;
    .col {
        margin-bottom:80px;
    }
    p {
        font-size:20px;
        line-height:29px;
        font-weight:300;
    }
    .btn {
        margin-top: 10px;
    }
}

/* Bühne Sonderwettbewerb */
.m10-buehne {
    padding-bottom: 40px;
    background: #FBFBFB; //$bg-gray
    overflow-x: hidden;
    .m10-background {
        color: #fefefe;
        background-color: $bg-dark;
        padding-top: 60px;
        min-height: 470px;
    }
    .image-wrapper {
        img {
            display: block;
            max-width: 250px;
            margin-top: 0;
            margin-left: auto;
            margin-right: auto;
        }
    }
    h1 {
        margin-top: 30px;
        margin-bottom: 20px;
    }
    .sr-icon-grid {
        margin-bottom: 40px;
        padding: 0 30px;
        h3 {
            font-size: 26px;
            line-height: 36px;
            letter-spacing: 0.22px;
        }
        li {
            margin: 2px;
            padding: 20px 0 15px;
            p {
                font-size: 12px;
                line-height: 20px;
                font-weight: 400;
                sub {
                    vertical-align: baseline;
                }
                &::before {
                    font-size: 35px;
                }
            }
        }
    }
    ul {
        &.sr-icon-grid {
            li {
                &::before {
                    content: none;
                }
            }
        }
    }
}

/* Logo Raster - Partner Logos */
.m11-logos {
    h2 {
        text-align: center;
        margin-bottom: 15px;
    }
    .grid-wrapper {
        display: flex;
        flex-wrap: wrap;
    }
    .grid-item {
        margin: 20px 0;
        /* 100%/6logos */
        width: 16.65%;
        display: flex;
        justify-content: center;
        img {
            height: 40px;
            -webkit-filter: grayscale(1);
            filter: grayscale(1);
            opacity: .6;
            max-width: 135px;
            object-fit: contain;
            &:hover {
                opacity: .9;
            }
        }
    }
}

/* Galerie - modded bs carousel */
.m13-galerie {
    /* modal lightbox */
    .modal-dialog {
        .modal-content {
            box-shadow:none;
        }
    }
    .modal-content {
        background-color:transparent;
        border:0;
    }
    .lightbox-modal {
        img {
            padding-top:70px;
            margin:0 auto;
        }
        .close {
            opacity:1;
            position: absolute;
            color: #fff;
            right: 20px;
            top:150px;
            font-size: 34px;
            cursor: pointer;
            z-index:10;
        }
    }
    .carousel {
        padding-bottom:65px;
        margin-top:40px;
    }
    /* text content required to close modal with custom icon */
    .close-content-x {
        opacity:0;
        margin-left:-30px;
    }
    .carousel-inner .item img, .carousel-inner > .item > a > img {
        margin:0 auto;
        max-width: 500px;
        /*border-radius: 7.5px;*/
        height: 350px;
        object-fit: cover;
        border-radius: 6px;
    }
    .carousel-control {
        opacity:1;
        &:hover {
            opacity:.5;
        }
        svg {
            position: absolute;
            top:50%;
        }
        &.left {
            background:none;
            svg {
                left:0;
            }
        }
        &.right {
            background:none;
            svg {
                right:0;
            }
        }
    }
    .carousel-indicators {
        bottom:0;
        li {
            background-color:#B4B0B0;
            border: 1px solid #B4B0B0;
            padding:0;
            margin:2px 6px 2px 6px;
            &.active {
                margin: 2px 6px 0 6px;
                background-color:#B4B0B0;
            }
        }
    }
}

/* Weiche mit Icon und Text */
.m17-weiche {
    background-color: $bg-dark;
    border-radius: 7px;
    padding: 68px 40px 30px;
    min-height: 270px;
    max-height: 270px;
    margin: 15px 10px 25px 0;

    .header {
        h2 {
            line-height: 27px;
            margin-top: 20px;
        }
    }

    .image-wrapper {
        display: flex;
        justify-content: center;
    }

    .header h2 {
        text-align: center;
        color: #FFFFFF;
    }
}

/* Katalog Items mit Lightbox bei klick*/
.m18-katalog {
    margin-bottom: 25px;
    .header {
        margin-top: 5px;
    }
    h3 {
        margin-top: 28px;
        text-align: left;
        margin-bottom: 5px;
    }
    h4.brand {
        margin-top: 5px;
        margin-bottom: 0;
        text-align: left;
        font-size: 13px;
        line-height: 17px;
        color: rgba(69, 69, 69, 0.5);
    }
    .image-wrapper {
        cursor: pointer;
        .teaser-image-wrapper {
            position:relative;
            .second-teaser-image {
                display: none;
            }
            .teaser-point-wrapper {
                position: absolute;
                display: flex;
                width: 100%;
                justify-content: center;
                bottom: 10px;

                .teaser-point {
                    content: '';
                    height:8px;
                    width:8px;
                    background-color:#454545;
                    border-radius: 50%;
                    opacity: 20%;
                    margin-left: 8px;
                }
                .teaser-point:nth-child(1) {
                    opacity: 50%;
                }
            }
        }
        .teaser-image-wrapper:hover {
            position:relative;
            .first-teaser-image {
                display: none;
            }
            .second-teaser-image {
                display: block;
            }
            .teaser-point:nth-child(1) {
                opacity: 20%;
            }
            .teaser-point:nth-child(2) {
                opacity: 50%;
            }

        }
        img {
            object-fit: cover;
            border-radius: 10px;
            width: 315px;
            height: 270px;
        }

        .lightbox-background {
            display:none;
            &.active {
                position: fixed;
                display: block;
                top:0;
                bottom: 0;
                right: 0;
                left: 0;
                background-color: rgba(0,0,0,.73);
                backdrop-filter: blur(5px);
                z-index: 100;
            }
        }
        .lightbox-wrapper {
            display: none;
            &.active {
                position: fixed;
                display: flex;
                z-index: 210;
                top: 130px;
                justify-content: center;
                flex-direction: column;
                left:0;
                right:0;
                max-height: 80vh;
                overflow: auto;
                padding-bottom: 5%;

                picture {
                    display:flex;
                    justify-content: center;
                }
            }
            .lightbox-image {
                &.active {
                    margin-top: 60px;
                }
            }
            img {
                border-radius: 0;
                max-height:450px;
                height: 450px;
                width:auto;
            }
            .lightbox-header {
                display: flex;
                justify-content: center;
                padding-top: 20px;
                flex-direction: column;
                h2 {
                    color: #ffffff;
                    font-size: 20px;
                    line-height: 27px;
                    margin-bottom:5px;
                    text-align: center;
                }
                h3.brand {
                    color: rgba(255, 255, 255, 0.5);
                    text-align: center;
                    margin-top: 0;
                    margin-bottom: 0;
                    font-size: 13px;
                    line-height: 17px;
                }
            }
            .lightbox-text {
                display: flex;
                justify-content: center;
                color: #ffffff;
                font-size: 14px;
                font-weight: 400;
                margin-top: 20px;
                p {
                    text-align: left;
                    max-width: 637px;
                }
            }
            .lightbox-close {
                .icon-close {
                    position:absolute;
                    color:#ffffff;
                    right: 20px;
                    font-size: 34px;
                    top: 12px;
                    cursor: pointer;
                }
            }
            .lightbox-image-slider {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 60px;
                .pagination-icon {
                    position: inherit;
                }
                .arrow {
                    height:26px;
                    width:auto;
                    cursor: pointer;
                }
                .arrow-left {
                    margin-right: 43px;
                }
                .arrow-right {
                    margin-left: 43px;
                }
                .lightbox-image {
                    display: none;
                    position:relative;
                    &.active {
                        display: block;
                    }
                    .lightbox-point-wrapper {
                        position: absolute;
                        display: flex;
                        width: 100%;
                        justify-content: center;
                        bottom: 10px;

                        .lightbox-point {
                            content: '';
                            height:8px;
                            width:8px;
                            background-color:#454545;
                            border-radius: 50%;
                            opacity: 20%;
                            margin-left: 8px;
                        }
                        .lightbox-point:nth-child(1) {
                            opacity: 50%;
                        }
                    }
                }
            }
        }
    }
    .katalog-content {
        p {
            margin-bottom:10px;
        }
    }
}

.m19-hall-of-fame {
    // header
    h2 {
        margin-bottom:40px;
        text-align:center;
    }
    // subheader
    h3 {
        margin-top:50px;
        margin-bottom:30px;
        text-align:center;
    }
    p {
        &.population-headline {
            margin-bottom:24px;
        }
    }
    .hofcity-wrapper {
        width: 25%;
    }
    .catInnerWrapper {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
    	align-items:start;
    }
    .hofcity-inner {
        text-align: center;
    }
    .hofcity-logo {
        height: 109px;
        display:flex;
        justify-content: center;
        img {
            filter: drop-shadow(2px 2px 14px rgba(0,0,0,.28));
            -webkit-filter: drop-shadow(2px 2px 14px rgba(0,0,0,.28));
            max-width:103px;
            height: 109px;
            object-fit:contain;
        }
        svg {
            filter: drop-shadow(2px 2px 14px rgba(0,0,0,.28));
            -webkit-filter: drop-shadow(2px 2px 14px rgba(0,0,0,.28));
            max-width:103px;
        }
    }
    /* position svg placeholder at same position as emblem images */
    .hofcity-name {
        text-align: center;
        padding: 15px 0px 10px 0px;
        width:100%;
        h4 {
            max-width:175px;
            margin:0 auto;
            text-align: center;
        }
    }
    .hofcity-population {
        width:100%;
        p {
            margin-bottom:30px;
        }
    }
}

.m21-headerimage {
    .header {
        padding-top: 50px;
    }
    .image-wrapper {
        margin: 0 24px;
    }
    img {
        border-radius: 7.5px;
        margin: 0 auto;
    }
}

.m22-profile {
    img {
        object-fit: cover;
        border-radius: 10px;
        width: 190px;
        height: 190px;
        margin-top: 12px;
        margin-bottom: 12px;
    }
    p {
        margin: 0;
    }
}

/* Icon Text */
.m23-icon-text {
    .icon-wrapper {
        width:auto;
        height:80px;
        margin-top:15px;
        margin-bottom:45px;
    }
    h3 {
        margin-bottom:20px;
        text-align: left;
    }
    .arrow-symbol {
        font-size: 50px;
        position:absolute;
        right: 50px;
    }
    .reduced-width {
        max-width:238px;
    }
    .mobile-counter {
        display: none;
    }
    img {
        height: 100%;
        width: auto;
    }
}

.m25-text-download-link {
    margin-bottom: 12px;
    .dl-link {
        font-size: 13px;
        line-height:21px;
        margin-bottom:10px;
        a {
            &::before {
                font-family: 'sr-icons', 'FontAwesome';
                content:"\e902";
                margin-right: 5px;
                font-size: 18px;
                vertical-align: middle;
            }
        }
    }
    .dl-link-wrapper {
        display: none;
        &.active {
            display: block;
        }
    }
    .d-none {
        display: none;
    }
    .select-wrapper {
        margin-bottom: 15px;
        position: relative;
        min-width:241px;
        max-width:241px;
        select {
            background-color: transparent;
            border: 1px solid #4A4A4A;
            padding: 7px;
            border-radius: 4px;
            color: #4A4A4A;
            text-align: center;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            font-weight: 500;
            position:relative;
            width:100%;
        }
    }
    .select-wrapper::after {
        position:absolute;
        height: 10px;
        width: 10px;
        content: '';
        background-color: #4A4A4A;
        clip-path: polygon(100% 0%, 0 0%, 50% 70%);
        right:15px;
        top:15px;
    }
}

.m26-statements {
    margin-bottom:30px;
    h3, h4 {
        text-align: left;
    }
    .col-winner {
        margin-bottom:30px;
    }
    .statement-name {
        margin-top:28px;
        margin-bottom:3px;
    }
    .statement-job {
        margin-top:0;
        margin-bottom:14px;
    }
    .img-statement {
        border-radius:5px;
        object-fit:cover;
        object-position: 50% 10%;
        width:314px;
        height:270px;
    }
    .winnerstatement {
        h3 {
            float:left;
            margin-top:4px;
            max-width: 145px;
            hyphens: auto;
        }
    }
    .winnerstatement-item {
        background-color:#fff;
        position:relative;
        margin-bottom:40px;
        padding:24px 24px 5px 24px;
    }
    .quote-start-icon {
        position:absolute;
        left:168px;
        top:30px;
    }
    .quote-end-icon {
        position:absolute;
        right:168px;
        bottom:30px;
    }
    .statement-content {
        display:block;
        margin:0 auto;
        width:500px;
    }
    .statement-toggle {
        font-size: 14px;
        line-height: 22px;
        font-weight: 500;
        &::after {
            /* icon arrow up */
            background-image: url('data:image/svg+xml,%3Csvg height="8" viewBox="0 0 15 8" width="15" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="m7.49451913 8c.19046583 0 .40859635-.08293529.57247073-.22080231l6.67374044-6.34188305c.3266591-.30374863.3543347-.82720214.0276647-1.1578624-.3000732-.33066027-.8171927-.35867485-1.1438519-.02800355l-6.13003487 5.79041495-6.12894526-5.81743689c-.32665916-.30374863-.84486829-.30374863-1.14385186.02800355-.30007316.33066027-.30007316.85521671.02766469 1.1578624l6.6737404 6.34188305c.16280113.16479521.38092076.2477305.57140838.2477305z" fill="%230086c5" transform="matrix(-1 0 0 -1 15 8)"/%3E%3C/svg%3E');        
            content:'';
            width:15px;
            height:8px;
            display:inline-block;
            background-repeat:no-repeat;
            margin-left: 5px;
        }
        &.collapsed {
            &::after {
                /* icon arrow down */
                background-image: url('data:image/svg+xml,%3Csvg height="8" viewBox="0 0 15 8" width="15" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="m7.49451913 8c.19046583 0 .40859635-.08293529.57247073-.22080231l6.67374044-6.34188305c.3266591-.30374863.3543347-.82720214.0276647-1.1578624-.3000732-.33066027-.8171927-.35867485-1.1438519-.02800355l-6.13003487 5.79041495-6.12894526-5.81743689c-.32665916-.30374863-.84486829-.30374863-1.14385186.02800355-.30007316.33066027-.30007316.85521671.02766469 1.1578624l6.6737404 6.34188305c.16280113.16479521.38092076.2477305.57140838.2477305z" fill="%230086c5"/%3E%3C/svg%3E');        
                content:'';
                width:15px;
                height:8px;
                display:inline-block;
                background-repeat:no-repeat;
                margin-left: 5px;
            }
        }
    }
}

.m36-partner {
    margin-bottom: 20px;
    .row-partner {
        .col-md-12 {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    .col-partner {
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 20px;
        margin-bottom:40px;
    }
    .row-headline {
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: left;
    }
    .partner-name {
        margin-top:28px;
        margin-bottom:14px;
        text-align: left;
    }
    .teaser-text {
        p {
            display: none;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            &:first-child {
                display: -webkit-box;
            }
        }
        h2, h3, h4, h5, h6, ul, ol {
            display: none;
        }
        
    }
    .img-partner {
        max-height: 180px;
        max-width: 200px;
        object-fit: contain;
    }
    .img-partner-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 270px;
        padding: 20px;
        background-color:#fff;
        border-radius: 4px;
    }
    .partner-description {
        h3 {
            float:left;
            margin-top:5px;
        }
    }
    .partner-item {
        background-color:#fff;
        position:relative;
        margin-bottom:40px;
        padding:20px 24px 1px 24px;
    }
    .partner-content {
        display:block;
        margin:0 auto;
        width:500px;
    }
    .partner-toggle {
        font-size: 14px;
        line-height: 22px;
        font-weight: 500;
        &::before {
            content:'Statement verbergen';
        }
        &::after {
            /* icon arrow up */
            background-image: url('data:image/svg+xml,%3Csvg height="8" viewBox="0 0 15 8" width="15" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="m7.49451913 8c.19046583 0 .40859635-.08293529.57247073-.22080231l6.67374044-6.34188305c.3266591-.30374863.3543347-.82720214.0276647-1.1578624-.3000732-.33066027-.8171927-.35867485-1.1438519-.02800355l-6.13003487 5.79041495-6.12894526-5.81743689c-.32665916-.30374863-.84486829-.30374863-1.14385186.02800355-.30007316.33066027-.30007316.85521671.02766469 1.1578624l6.6737404 6.34188305c.16280113.16479521.38092076.2477305.57140838.2477305z" fill="%230086c5" transform="matrix(-1 0 0 -1 15 8)"/%3E%3C/svg%3E');        
            content:'';
            width:15px;
            height:8px;
            display:inline-block;
            background-repeat:no-repeat;
            margin-left:5px;
        }
        &.collapsed {
            &::before {
                content:'Statement lesen';
            }
            &::after {
                /* icon arrow down */
                background-image: url('data:image/svg+xml,%3Csvg height="8" viewBox="0 0 15 8" width="15" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="m7.49451913 8c.19046583 0 .40859635-.08293529.57247073-.22080231l6.67374044-6.34188305c.3266591-.30374863.3543347-.82720214.0276647-1.1578624-.3000732-.33066027-.8171927-.35867485-1.1438519-.02800355l-6.13003487 5.79041495-6.12894526-5.81743689c-.32665916-.30374863-.84486829-.30374863-1.14385186.02800355-.30007316.33066027-.30007316.85521671.02766469 1.1578624l6.6737404 6.34188305c.16280113.16479521.38092076.2477305.57140838.2477305z" fill="%230086c5"/%3E%3C/svg%3E');        
                content:'';
                width:15px;
                height:8px;
                display:inline-block;
                background-repeat:no-repeat;
                margin-left:5px;
            }
        }
    }
}    

.m37-teaser {
    padding: 50px 0 80px;
    p {
        font-size: 14px;
        line-height: 22px;
        font-weight: 300;
        strong {
            font-weight: 500;
        }
    }
    .bg-box {
        background-color: #ECECEC;
        border-radius: 7px;
        padding: 30px 15px;
        margin: 0 15px;
        display: flex;
    }
    .col-teaser-image {
        //height: 240px;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .teaser-button {
        margin-top: 25px;
        .btn-secondary:hover {
            background-color: rgba(69,69,69,.07);
        }
    }
}

.m39-arrowlink {
    &.normal-link {
        .col-md-12 {
            margin-top: 50px;
            margin-bottom: 0;
        }
    }
    .col-md-12 {
        margin-bottom: 40px;
    }
    .arrow-back {
        transform: rotateY(180deg);
        display: inline-block;
        font-size: 19px;
        vertical-align: -2.5px;
        margin-right: 8px;
        color: #454545;
    }
    .arrow-forward {
        display: inline-block;
        font-size: 18px;
        margin-right: 8px;
        color: #0086C5;
        font-weight: 800;
        vertical-align: middle;
    }
    .link-back {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #454545;
    }
    .link-forward {
        font-weight: 500;
        font-size: 16px;
        line-height: 40px;
        color: #0086C5;
    }
    .sr-blue {
        color: #0086C5;
        .arrow-back,
        .arrow-forward, 
        .link-back,
        .link-forward {
            color: #0086C5;
        }
    }
}
// no extra padding when child of another module 
.ce-module {
    .m39-arrowlink {
        .col-md-12 {
            padding: 0;
        }
    }
}

.m40-howitworks {
    //margin-bottom: 40px;
    font-size: 14px;
    h2 {
        margin-top: 0;
    }
    p {
        margin-bottom: 0;
    }
    img {
        min-height: 40px;
    }
    .tablet-view {
        img {
            margin-top: 0;
        }
    }
    .mobile-view {
        img {
            height: 45px;
            width: 100px;
            margin-bottom: 16px;
        }
    }
}

.m41-sticky {
    height: 90px;
    bottom: -90px;
    background-color: #063548;
    width: 100%;
    padding: 24px 80px;
    position: relative;
    transition: all .25s linear;
    &.position-sticky {
        bottom: 0;
    }
    .icon {
        display: inline-block;
        margin-right: 30px;
        height: 40px;
    }
    .text {
        display: inline-block;
        color: #fff;
        margin: 0;
        line-height: 40px;
    }
    .button {
        float: right;
    }
}

.m42-table {
    > h2 {
        margin-top: 0;
        margin-bottom: 30px;
    }
    .text {
        p {
            margin: 0;
        }
    }
    .table-responsive {
        display: flex;
        flex-wrap: wrap;
        border-collapse: separate;
        border: 1px solid rgba(0,0,0,0.8);
        border-radius: 4px;
        margin: 0;
        p {
            margin: 0;
        }
        strong {
            color: #979797;
            letter-spacing: 0.28px;
        }
        .table-row {
            width: 100%;
            display: inline-flex;
            justify-content: space-evenly;
            &:hover {
                background: #f4f4f4;    
                transition: all 0.1s ease-in-out;
            }
            &:first-child {
                .table-cell {
                    border-top: 0;
                }
            }
        }
        .table-cell {
            box-sizing: border-box;
            flex-grow: 1;
            width: 100%; 
            overflow: hidden;
            list-style: none;
            border-left: 1px solid rgba(69,69,69,0.2);
            border-top: 1px solid rgba(69,69,69,0.2);
            border-right: 0;
            border-bottom: 0;
            padding: 10px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            > * {
                text-align: center;
            }
            &:first-child {
                border-left: none;
            }
        }
    }
    .t-2cols .table-row .table-cell  { width: 50%; }
    .t-3cols .table-row .table-cell  { width: 33.33%; }
    .t-4cols .table-row .table-cell  { width: 25%; }
    .t-5cols .table-row .table-cell  { width: 20%; }
    .t-6cols .table-row .table-cell  { width: 16.6%; }
}

.m43-video {
    h1, h2 {
        margin-bottom: 60px;
    }
    .video-placeholder {
        width: 560px;
        height: 315px;
        background-color: #073B50;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        cursor: pointer;
        &:hover .play-button {
            opacity: 0.8;
        }
        .preview-text {
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.3px;
            font-weight: 500;
            color: #fff;
            margin: 10px;
            padding: 8px;
            text-shadow: 0px 0px 2px #000;
            position: absolute;
            background-color: rgba(0,0,0,0.4);
            border-radius: 4px;
            z-index: 2;
            display: flex;
            width: auto;
            max-width: 350px;
            &::before {
                font-family: 'sr-icons' !important;
                content: "\e914";
                font-size: 24px;
                line-height: 20px;
                color: #fff;
                display: block;
                padding-right: 10px;
                margin-top: 6px;
            }
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            z-index: 1;
            top: 0;
        }
        .play-button-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            .play-button {
                border-style: solid;
                border-width: 25px 0 25px 40px;
                border-color: transparent transparent transparent #fff;
                z-index: 2;
            }
        }
    }
}
.m44-bulletin-board {
    h1 {
        text-align: center;
    }
    .akkordeon-header {
        flex-direction: column;
    }
    .intro-text {
        font-size:20px;
        line-height:29px;
        font-weight:300;
        p {
            font-size:20px;
            line-height:29px;
            font-weight:300;
            margin-bottom: 0;
        }
    }
    .faq-categories {
        .faq-category {
            height: 148px;
            position: relative;
            padding-top: 55px;
            // icon
            img {
                max-width: 65px;
                height: auto;
            }
            // title
            p {
                position: absolute;
                bottom: 10px;
            }
        }
    }
    .m44-item {
        .date {
            font-size: 11px;
            line-height: 22px;
            font-weight: 300;
            display: inline-block;
            vertical-align: middle;
            min-width: 80px;
        }
        .akkordeon-content {
            img {
                margin-top: 40px;
                border-radius: 8px;
            }
        }
    }
}


/* tablet */
@media (min-width: 768px) and (max-width: 992px) {
    .ce-module {
        h1 {
            text-align:center;
            margin-top:20px;
        }
        h1.grid-header {
            font-size:24px;
        }
        li {
            &::before {
                left:8px;
            }
        }
        /* bootstrap cols */
        [class^="col-"], [class*=" col-"] {
            padding-left: 9px;
            padding-right: 9px;
        }
        &.column {
            .header {
                justify-content: start;
            }
            h1, h2, h3, h4 {
                text-align: left;
            }
            .col-md-12 {
                > h2 {
                    margin-bottom: 30px;
                }
                > h3 {
                    margin-bottom: 30px;
                }
            }
        }
        // for custom class on col- content element
        &.headline-left {
            .col-md-12 {
                h2 {
                    margin-bottom: 15px;
                }
            }
        }
        &.headline-center-desktop {
            .col-md-12 {
                > h2 {
                    text-align: left;
                }
                > h3 {
                    text-align: left;
                }
            }
        }
        &.shop__slideshow {
            .slide-content {
                width: 345px;
                padding:105px 24px 157px 24px;
                height:480px;
            }
            .slide-image {
                img {
                    width:auto;
                    padding-left:345px;
                    min-height:50vh;
                    object-fit:cover;
                    object-position:left;
                }
            }
            .shop-slideshow__heading {
                font-size:40px;
                line-height:42px;
                margin-bottom:17px;
            }
            .slider-text {
                font-size:14px;
                line-height:26px;
            }
            .btn-default {
                margin-top:21px;
            }
            .carousel-inner {
                .item {
                    height:480px;
                }
            }
        }
    }
    .container {
        .container-header {
            h1 {
                margin-top:20px;
                margin-bottom:20px;
            }
        }
    }
    .quicknavigation {
        margin-left: 15px;
        margin-right: 15px;
    }
    .m01-text {
        .m01-centered {
            h2, h3 {
                text-align: left;
            }
        }
        .justify-center {
            justify-content: inherit;
        }
        h2 {
            margin-bottom:28px;
        }
        h3 {
            margin-bottom:20px;
        }
    }
    .m03-text-bild {
        width:100%;
        .header-mobile {
            margin-top: 28px;
            margin-bottom: 0;
        }
        .m03-text {
            width: 80%;
        }
        .m03-inner {
            margin-top: 10px;
        }
        .col-image {
            margin-bottom: 28px;
        }
    }
    .m05-intro-text {
        padding:0;
        .col {
            margin-bottom:70px;
        }
        p {
            font-size:19px;
            line-height:29px;
        }
    }
    /* Bühne Sonderwettbewerb */
    .m10-buehne {
        padding-bottom: 10px;
        .m10-background {
            padding-top: 66px;
        }
        .image-wrapper {
            img {
                max-width: 200px;
            }
        }
        h1 {
            margin-top: 20px;
            margin-bottom: 14px;
        }
        .sr-icon-grid {
            padding: 0 20px;
            margin-bottom: 66px;
            li {
                min-width: 30%;
                flex-basis: 220px;
            }
        }
    }
    /* Logo Raster - Partner Logos */
    .m11-logos {
        h2 {
            text-align: left;
            margin-bottom: 2px;
        }
        .grid-item {
            width: 20%;
            img {
                height: 40px;
                max-width: 110px;
            }
            &.grid-item-empty {
                display: none;
            }
        }
    }
    .m13-galerie {
        .carousel {
            padding-bottom:50px;
            margin-top:36px;
        }
        .carousel-inner .item img, .carousel-inner > .item > a > img {
            max-height: 250px;
            height: 250px;
            width: auto;
        }
        .lightbox-modal {
            .close {
                top:100px;
            }
        }
    }
    /* Weiche mit Icon und Text */
    .m17-weiche {
        min-height: 185px;
        max-height: 185px;
        padding: 40px 15px 20px;
        margin: 10px 20px 28px 0;

        h2 {
            font-size: 16px;
            line-height: 20px;
            text-align: center;
        }
        .image-wrapper {
            img {
                max-height: 84px;
            }
        }
    }
    // to overrite default left align headline on tablet
    .ce-module.m17-weiche {
        h2 {
            text-align: center;
        }
    }
    /* Katalog Items mit Lightbox bei klick*/
    .m18-katalog {
        margin-bottom: 15px;
        h3 {
            margin-top: 25px;
            text-align: left;
        }
        .image-wrapper {
            .teaser-image-wrapper {
                .teaser-point-wrapper {
                    padding-right: 20px;
                    .teaser-point {
                        height:6px;
                        width:6px;
                        margin-left: 6px;
                    }
                }
            }
            img {
                object-fit: cover;
                border-radius: 10px;
                width: 215px;
                height: 185px;
            }
            .lightbox-wrapper {
                &.active {
                    top: 110px;
                    .icon-close {
                        margin-top:-10px;
                    }
                }
                .lightbox-image {
                    &.active {
                        margin-top: 40px;
                    }
                }
            }
        }
        .header {
            h2 {
                line-height: 27px;
            }
        }
    }
    .m19-hall-of-fame {
        padding-left:9px;
        padding-right:9px;
        margin-bottom:10px;
        h2 {
            margin-top:10px;
            text-align:left;
        }
        h3 {
            text-align: center;
            margin-top:10px;
        }
        p {
            &.population-headline {
                text-align: center;
                margin-bottom:24px;
            }
        }
        .catInnerWrapper {
            justify-content: normal;
        }
        .hofcity-wrapper {
            width: 33%;
        }
        .hofcity-logo {
            height:90px;
            img {
                max-width:90px;
                height: 90px;
            }
            svg {
                max-width:85px;
                height:95px;
            }
        }
        .hofcity-name {
            max-width:inherit;
        }
        .hofcity-population {
            max-width:inherit;
            p {
                margin-bottom:50px;
            }
        }
    }
    .m21-headerimage {
        .image-wrapper {
            margin:0;
        }
    }
    .m23-icon-text {
        .icon-wrapper {
            margin-bottom:20px;
        }
    }
    .m25-text-download-link {
        .select-wrapper {
            min-width: 216px;
            max-width: 216px;
        }
        .dl-link {
            span {
                display: inline-block;
                margin-left: 25px;
            }
        }
        .image-wrapper {
            .lightbox-wrapper {
                img {
                    height:406px;
                }
            }
        }
    }
    .m26-statements {
        .img-statement {
            width:100%;
            height:185px;
            border-radius:5px;
        }
        .statement-content {
            margin-left: 0;
            width: auto;
        }
        .statement-name {
            margin-top:25px;
            margin-bottom:4px;
        }
        .statement-job {
            margin-top:0;
            margin-bottom:14px;
        }
        .winnerinfos-wrapper {
            p {
                margin:0 0 14px;
            }
        }
        .winnerstatement-item {
            padding:20px 60px 4px 60px;
            margin-bottom:50px;
            h3 {
                max-width:215px;
            }
        }
        .quote-start-icon {
            top: 25px;
            left:16px;
            svg {
                width:28px;
                height:20px;
            }
        }
        .quote-end-icon {
            right:20px;
            svg {
                width:28px;
                height:20px;
            }
        }
    }
    .m36-partner {
        h1 {
            margin-bottom: 25px;
        }
        .row-partner {
            .col-md-12 {
                /* tablet 24px border spacing = 15px container + 9px column */
                padding-left: 9px;
                padding-right: 9px;
            }
        }
        .partner-content {
            margin-left: 0;
            width: auto;
        }
        .partner-name {
            margin-top:25px;
            margin-bottom:14px;
        }
        .img-partner {
            max-height: 145px;
            max-width: 100%;
        }
        .img-partner-wrapper {
            height: 185px;
            padding: 20px 50px;
        }
        .partnerinfos-wrapper {
            p {
                margin:0 0 14px;
            }
        }
        .partner-item {
            padding:24px 60px 4px 60px;
            margin-bottom:50px;
            h3 {
                max-width:215px;
            }
        }
    }
    .m37-teaser {
        padding: 20px 0 50px;
        .bg-box {
            padding: 20px;
            overflow: hidden;
            margin: 0 8px;
        }
        .teaser-button {
            button {
                margin-bottom: 10px;
            }
        }
    }
    .m39-arrowlink {
        .col-md-12 {
            margin-bottom: 30px;
        }
    }
    .m41-sticky {
        padding: 25px 24px;
        height: 90px;
        text-align: center;
        .icon {
            margin-right: 30px;
            height: 40px;
            float: left;
        }
        .text {
            line-height: inherit;
            max-width: 320px;
            text-align: left;
            float: left;
        }
        .button {
            display: block;
            width: fit-content;
            margin: 0 auto;
        }
    }
    .m44-bulletin-board {
        .container {
            padding-left: 24px;
            padding-right: 24px;
        }
        .faq-categories {
            margin-top: 20px;
            overflow: hidden;
            border-radius: 10px;
            .faq-category {
                flex-basis: 45%;
                &:first-child,
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5),
                &:last-child {
                    border-radius: 0;
                }
            }
        }
        // pull to full width outside of container
        .akkordeon-topics {
            margin-left: -24px;
            margin-right: -24px;
        }
        .akkordeon-content {

        }
    }
}

/* mobile */
@media (max-width: 767px) {
    .ce-module {
        h1 {
            text-align:left;
            margin-top:20px;
            margin-bottom:30px;
        }
        .image-description {
            margin-left:30px;
            margin-right:30px;
            line-height:16px;
        }
        h1.grid-header {
            font-size:24px;
            text-align: left;
        }
        h1.grid-header {
            margin-bottom: 23px;
        }
        h2, h3, h4 {
            text-align: left;
        }
        li {
            &::before {
                left:14px;
            }
        }
        p {
            sub {
                font-size:12px;
            }
            .intro {
                font-size: 19px;
            }
        }
        .header-mobile {
            margin-left: 30px;
        }
        &.column {
            .header {
                justify-content: start;
            }
            h1, h2, h3, h4 {
                text-align: left;
            }
            .col-md-12 {
                > h2 {
                    margin-bottom: 27px;
                }
                > h3 {
                    margin-bottom: 27px;
                }
            }
        }
        // for custom class on col- content element
        &.headline-left {
            .col-md-12 {
                h2 {
                    margin-bottom: 0;
                }
            }
        }
        &.headline-center-desktop {
            .col-md-12 {
                > h2 {
                    text-align: left;
                }
                > h3 {
                    text-align: left;
                }
            }
        }
        /*
        .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
            padding-left: 30px;
            padding-right: 30px;
        }
        
        h2 {
            padding-left:15px;
            padding-right:15px;
        }
        h3 {
            padding-left:15px;
            padding-right:15px;
        }
        */

        &.shop__slideshow {
            margin-top:0;
            height:95vh;
            .slide-content {
                width: 100%;
                padding:25px 26px 15px 26px;
                height:290px;
                bottom:0;
            }
            .slide-image {
                img {
                    height:64vh;
                    width:100%;
                    object-fit: cover;
                    object-position: top;
                    padding-left:0;
                    min-height:auto;
                }
            }
            .shop-slideshow__heading {
                font-size:26px;
                line-height:30px;
            }
            .slider-text {
                font-size:14px;
                line-height:22px;
                min-height: 90px;
            }
            .btn-default {
                margin-top:20px;
                width:100%;
            }
            .carousel-inner {
                .item {
                    height:100%;
                }
                .item__container {
                    height:95vh;
                }
            }
            .carousel-indicators {
                bottom:290px;
            }
            .shop-slideshow__control {
                height:50%;
            }
            .shop-slideshow__subheading {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 140px;
            }
        }
        .container {
            /* reduce spacing of all cols inside container */
            [class^="col-xs-"], [class*=" col-xs-"] {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
    .container {
        .container-header {
            margin-bottom:28px;
            h1 {
                margin-top:30px;
                margin-bottom:0;
            }
            .justify-center {
                text-align: left;
            }
        }
        .header {
            &.justify-center {
                justify-content: start;
            }
        }
    }
    .quicknavigation {
        margin-left: 15px;
        margin-right: 15px;
    }
    .m01-text {
        overflow-x: auto;
        .m01-centered {
            h2, h3 {
                text-align: left;
            }
        }
        h2 {
            margin-top:0;
            margin-bottom:28px;
        }
        h3 {
            margin-bottom:20px;
        }
        h4 {
            margin-top:28px;
            margin-bottom: 28px;
            font-size: 16px;
            line-height: 22px;
        }
        h3.header-subheader {
            margin-top: 40px;
        }
    }
    .m03-text-bild {
        width:100%;
        margin-bottom: 50px;
        img {
            border-radius:0;
        }
        .header-mobile {
            margin-top: 28px;
            margin-bottom: 0;
        }
        .m03-inner {
            margin-top: 32px;
            padding-right: 15px;
        }
        /* image to full page width */
        .col-image {
            padding-left:0;
            padding-right:0;
            margin-left:-15px;
            margin-right:-15px;
        }
    }
    .m05-intro-text {
        padding:0;
        .col {
            margin-bottom:70px;
        }
        p {
            font-size:19px;
            line-height:29px;
        }
        .btn {
            width: 100%;
            margin-top: 50px;
        }
    }
    /* Bühne Sonderwettbewerb */
    .m10-buehne {
        .m10-background {
            padding-top: 70px;
        }
        .image-wrapper {
            img {
                max-width: 220px;
            }
        }
        h1 {
            text-align: center;
            margin-top: 33px;
            margin-bottom: 23px;
        }
        .sr-icon-grid {
            margin: 0 45px 70px 45px;
            padding: 0;
        }
    }
    /* Logo Raster - Partner Logos */
    .m11-logos {
        h2 {
            text-align: left;
            margin-bottom: 10px;
        }
        .grid-item {
            margin: 18px 0;
            width: 50%;
            img {
                height: 40px;
                max-width: 110px;
            }
            &.grid-item-empty {
                display: none;
            }
        }
    }
    .m13-galerie {
        .lightbox-modal {
            .close {
                top:60px;
            }
        }
        .carousel {
            padding-bottom:0;
            margin-top:15px;
            margin-left:-30px;
            margin-right:-30px;
        }
        .carousel-inner .item img, .carousel-inner > .item > a > img {
            margin:0 auto;
            max-width:inherit;
            width:100%;
            height: 250px;
            border-radius: 0;
        }
        .carousel-control {
            opacity:0;
            &:hover {
                opacity:.5;
            }
        }
        .carousel-indicators {
            li {
                margin: 2px 3px 2px 3px;
                opacity: .5;
                background-color: #fff;
                border: 1px solid #fff;
                &.active {
                    margin: 2px 3px 2px 3px;
                    opacity: 1;
                    background-color: #fff;
                    border: 1px solid #fff;
                    width:6px;
                    height:6px;
                }
            }
            
        }
    }
    /* Weiche mit Icon und Text */
    .m17-weiche {
        min-height: 142px;
        max-height: 142px;
        border-radius: 0;
        padding: 35px 30px 20px 30px;
        margin: 10px -30px 10px -30px;

        h2 {
            font-size: 16px;
            line-height: 20px;
            margin-top: 14px;
        }
        .image-wrapper {
            img {
                max-height: 50px;
            }
        }
    }
    .ce-module.m17-weiche {
        h2 {
            text-align: center;
        }
    }
    /* Katalog Items mit Lightbox bei klick*/
    .m18-katalog {
        margin-bottom: 30px;
        h3 {
            margin-top: 25px;
            text-align: left;
        }
        .image-wrapper {
            margin-left: -30px;
            margin-right: -30px;
            .teaser-image-wrapper {
                .teaser-point-wrapper {
                    bottom:12px;
                    .teaser-point {
                        height:6px;
                        width:6px;
                        margin-left: 6px;
                    }
                }
            }
            img {
                object-fit: cover;
                border-radius: 0;
                width: 100%;
                height: 323px;
            }
            .lightbox-wrapper {
                &.active {
                    padding-top: 60px;
                    top: 70px;
                    max-height: 100%;
                    justify-content: normal;
                    .lightbox-close {
                        .icon-close {
                            top: 5px;
                        }
                    }
                }
                .lightbox-header {
                    justify-content: start;
                    h2,
                    h3.brand {
                        text-align: left;
                    }
                }
                .lightbox-image {
                    &.active {
                        margin-top: 0;
                    }
                }
                .lightbox-text {
                    padding-bottom: 100px;
                }
                img {
                    width:100%;
                    height:auto;
                }
                .container {
                    padding-left:30px;
                    padding-right:30px;
                }
                .lightbox-image-slider {
                    margin-top: 0;
                    .pagination-arrow-left {
                        display:none;
                    }
                    .pagination-arrow-right {
                        display: none;
                    }
                }
            }
        }
    }
    .m19-hall-of-fame {
        padding-left:15px;
        padding-right:15px;
        h2 {
            text-align:left;
        }
        h3 {
            text-align: left;
            margin-bottom: 24px;
        }
        .catInnerWrapper {
            justify-content: normal;
        }
        .hofcity-wrapper {
            width: 50%;
        }
        .hofcity-logo {
            height:90px;
            img {
                max-width:90px;
                height: 90px;
            }
            svg {
                max-width:85px;
                height:95px;
            }
        }
        .hofcity-name {
            max-width:inherit;
        }
        .hofcity-population {
            max-width:inherit;
            p {
                margin-bottom:40px;
            }
        }
        .population-headline {
            margin-top:20px;
        }
    }
    .m21-headerimage {
        .image-wrapper {
            margin:0 24px;
        }
        .header {
            margin-left:30px;
            margin-right:30px;
        }
        img {
            border-radius:0;
            height:210px;
            display:block;
            object-fit: cover;
        }
    }
    .m23-icon-text {
        margin-bottom:60px;
        .icon-wrapper {
            margin-top:15px;
            margin-bottom:20px;
            img {
                display: inline-block;
            }
        }
        // mobile counter
        h3.mobile-counter {
            display: inline-block;
            margin-right: 5px;
        }
        h3 {
            display: inline-block;
        }
        .reduced-width {
            max-width: none;
        }
    }
    .m22-profile {
        margin-bottom:30px;
        img {
            width:100%;
            height:315px;
            margin-top:12px;
            margin-bottom:12px;
        }
    }
    .m25-text-download-link {
        .select-wrapper {
            min-width: inherit;
            max-width: inherit;
        }
    }
    .m26-statements {
        .img-statement {
            border-radius: 0;
            width:100%;
            height:325px;
        }
        .col-winner {
            padding:0;
            margin-left:-15px;
            margin-right:-15px;
            margin-bottom:30px;
        }
        .statement-name {
            margin-top:23px;
            margin-bottom:4px;
        }
        .statement-job {
            margin-top:0;
            margin-bottom:14px;
        }
        .winnerinfos-wrapper {
            margin:0 30px;
            p {
                margin:0 0 14px;
            }
        }
        .winnerstatement {
            padding:0;
            .wrapper {
                margin-left:-15px;
                margin-right:-15px;
            }
        }
        .winnerstatement-item {
            padding:60px 30px 40px 30px;
            margin-bottom:70px;
        }
        .statement-toggle {
            &.collapsed {
                padding-bottom:40px;
            }
        }
        .statement-content {
            width:auto;
        }
        .quote-start-icon {
            left:30px;
            top:20px;
            svg {
                width:28px;
                height:20px;
            }
        }
        .quote-end-icon {
            right:30px;
            bottom:20px;
            svg {
                width:28px;
                height:20px;
            }
        }
    }
    .m36-partner {
        h1 {
            margin: 20px 15px 20px 15px;
        }
        .row-partner {
            .col-md-12 {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
        .col-partner {
            padding:0;
            margin-left:-15px;
            margin-right:-15px;
            margin-top: 25px;
            margin-bottom:30px;
        }
        .row-headline {
            margin-top: 30px;
        }
        .partner-name {
            margin-top:28px;
            margin-bottom:14px;
        }
        .img-partner {
            max-height: 280px;
            max-width: 100%;
        }
        .img-partner-wrapper {
            height: 320px;
            padding: 20px 80px;
            border-radius: 0;
        }
        .partnerinfos-wrapper {
            margin:0 30px;
            p {
                margin:0 0 14px;
            }
        }
        .partner-description {
            padding:0;
            .wrapper {
                margin-left:-15px;
                margin-right:-15px;
            }
        }
        .partner-item {
            padding:20px 30px 6px 30px;
            margin-bottom:70px;
            p {
                margin-bottom: 14px;
            }
        }
        .partner-toggle {
            &.collapsed {
                padding-bottom:40px;
            }
        }
        .partner-content {
            width:auto;
        }
    }
    /* override typo3 headline center alignment in mobile */
    .header {
        &.justify-center {
            justify-content: left;
        }
    }
    .m37-teaser {
        padding: 20px 0 4.50px;
        p {
            text-align: center;
        }
        .bg-box {
            border-radius: 0;
            padding: 20px;
            margin-left: -15px;
            margin-right: -15px;
            display: block;
        }
        .teaser-image {
            width: 100%;
        }
    }
    .m39-arrowlink {
        &.normal-link {
            .col-md-12 {
                margin-top: 50px;
                margin-bottom: 0;
            }
        }
        .col-md-12 {
            margin-top: 22px;
            margin-bottom: 15px;
        }
    }
    .m41-sticky {
        padding: 27px 30px;
        height: 95px;
        .icon {
            display: none;
        }
        .text {
            display: none;
        }
        .button {
            float: inherit;
            width: 100%;
        }
    }

    .m42-table {
        .table-responsive {
            .table-cell {
                padding: 10px 1.20px;
            }
        }
    }

    .m43-video {
        h1, h2 {
            margin-bottom: 30px;
        }
        .video-placeholder {
            width: 100%;
            height: 175px;
            .preview-text { 
                margin: 5px;
            }
            .play-button-wrapper {
                .play-button {
                    border-width: 1.50px 0 1.50px 2.50px;
                }
            }
        }
    }
    .m44-bulletin-board {
        h1 {
            text-align: left;
        }
        .faq-categories {
            margin-top: 20px;
            overflow: hidden;
            border-radius: 10px;
            .faq-category {
                flex-basis: 45%;
                padding-top: 50px;
                &:first-child,
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5),
                &:last-child {  
                    border-radius: 0;
                }
            }
        }
    }
}
/* Landscape */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 812px) 
  and (orientation: landscape) { 
    .ce-module {
        &.shop__slideshow {
            height:95vh;
            .slide-content {
                padding:20px 26px 0px 26px;
                height:190px;
            }
            .slide-image {
                height: auto;
                width: 100%;
            }
            .btn-default {
                margin-top:15px;
                width:auto;
            }
            .carousel-inner {
                .item__container {
                    height:88vh;
                }
            }
        }
    }
}