
.select2-dropdown {
	background-color: #fafafa;
	border: none;
	input {
		&:focus {
			outline: 0;
		}
	}
}
.select2-search--dropdown {
	margin-bottom: 5px;
}
.form-group {
	.select2.select2-container.select2-container--default {
		width: 100% !important;
		position: relative;
		display: block;
		border: 0;
		height: 34px;
		font-size: 14px;
		line-height: 2.5;
		border-style: solid;
		border-width: 0 0 1px 0;
		border-color: #bbbbbb;
		background-color: transparent;
		border-radius: 0;
		outline: none;
		.select2-selection--single {
			border: 0;
			height: 34px;
			font-size: 14px;
			line-height: 2.5;
			border-style: solid;
			border-width: 0 0 1px 0;
			border-color: #bbbbbb;
			background-color: rgba(0, 0, 0, 0);
			border-radius: 0;
			outline: none;
		}
	}
}
.form-horizontal {
	.select2-container--default {
		.select2-selection--single {
			.select2-selection__arrow {
				b {
					border-color: #fff transparent transparent transparent;
					border-width: 7px 5px 0 5px;
				}
			}
		}
	}
	.select2-container--default.select2-container--open {
		.select2-selection--single {
			.select2-selection__arrow {
				b {
					border-color: transparent transparent #0086C5 transparent;
					border-bottom-color: #0086C5 !important;
					border-width: 0 5px 7px 5px;
				}
			}
		}
	}
}
.select2-container--open {
	.select2-selection {
		background-color: transparent;
		border: 0;
	}
	.select2-dropdown {
		width: 100%;
		.select2-results {
			ul.select2-results__options {
				position: absolute;
				display: block;
				width: 100%;
				border: 0;
				border-radius: 0;
				margin-top: -5px !important;
				line-height: 3;
				color: #fff;
				background-color: #0b3a4f;
				box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
				li.select2-results__option {
					padding: 0;
					font-size: 14px;
					text-align: center;
					border: 1px solid rgba(20, 104, 142, 0.5);
					border-bottom-width: 0;
				}
				.select2-results__option--highlighted[aria-selected] {
					background-color: #0086C5;
					color: #fff;
				}
				li.select2-results__option[aria-selected=true] {
					background-color: #0b3a4f;
				}
			}
		}
	}
}

/* mobile */
@media (max-width: $breakpoint-mobile) {
	.form-group {
		.select2.select2-container.select2-container--default {
			font-size: 16px;
			.select2-selection--single {
				font-size: 16px;
			}
		}
	}
}
