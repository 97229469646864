.sonderaktion-vervalten {
    .form-horizontal {
        .form-control {
            border-color: rgba(255,255,255,0.8);
            border-bottom-width: 1px;
        }
        .checkbox {
            label {
                font-size: 12px;
                font-weight: 300;
                letter-spacing: 0.33px;
                line-height: 17px;
            }
        }
        .control-label {
            font-size: 13px;
        }
    }
    .rk-compat-2017 {
        .checkbox {
            label {
                &:before {
                    background-color: transparent;
                }
            }
        }
    }
    .dark-bg {
        .btn.btn-default.dropdown-toggle {
            min-width: 240px;
            font-size: 12px;
            font-weight: 500;
            border: none;
            background-color: #0E4761;
            color: rgba(254, 254, 254, 0.8);
            transition: background-color 500ms;
        }
    }
    .top10btn {
        .btn.btn-default.dropdown-toggle {
            min-width: 240px;
            font-size: 12px;
            font-weight: 500;
            border: none;
            background-color: #0E4761;
            color: rgba(254, 254, 254, 0.8);
            transition: background-color 500ms;
        }
    }

    /* modal image cropper */
    .preview-container {
        padding-left: 30px;
    }
    .preview-title {
        padding-left: 5px;
        color: #454545;
    }
    .preview {
        height: 200px;
        width: 200px;
        border: 1px solid black;
        overflow: hidden;
        float: right;
    }
    .modal-header {
        text-align: center;
    }
    #modalLabel {
        color: #454545;
        font-size: 20px;
    }
    #successMessage {
        color: #454545;
    }
    .privacy-check {
        min-height: 220px;
    }
    .table-condensed {
        >tbody {
            >tr {
                >td {
                    padding: 6px 10px;
                }
            }
        }
    }
    #sr_date_arrow {
        position: relative;
        float: right;
        margin-right: 10px;
        bottom: 10px;
        b {
            border-color: rgba(117,117,117,1) transparent transparent transparent;
            border-width: 7px 5px 0 5px;
            border-style: solid;
        }
    }

    /* modal image cropper */
    .preview-container {
        padding-left: 30px;
    }
    .preview-title {
        padding-left: 5px;
        color: #454545;
    }
    .preview {
        height: 200px;
        width: 200px;
        border: 1px solid black;
        overflow: hidden;
        float: right;
    }
    .modal-header {
        text-align: center;
    }
    #modalLabel {
        color: #454545;
        font-size: 20px;
    }
    #successMessage {
        color: #454545;
    }
    #eventCreatedMessage {
        color: #454545;
    }
    #eventDeleteMessage {
        color: #454545;
    }

    /* Style RTE */
    .cke_wysiwyg_frame {
        background: #063547;
        html {
            background: #063547;
        }
        body {
            color: #fff;
            background: #063547;
        }
    }
    .cke_wysiwyg_div {
        background: #063547;
    }
    .cke_top {
        background: #0086C5;
        border-bottom: 0;
    }
    .cke_inner {
        background: #0086C5;
    }
    .cke_reset {
        border-radius: 10px 10px 0 0;
    }
    .cke_chrome {
        border: 2px solid #0086C5;
        border-radius: 7px;
        background: #063547;
    }
    .cke_bottom {
        background: #063547;
        border-top: 0;
        display: none;
    }
    .cke_contents {
        margin-bottom: 10px;
    }
    .form-horizontal {
        textarea.form-control {
            background-color: transparent;
        }
        .form-group {
            .ml-0 {
                margin-left: 0;
            }
        }
    }
    .event-button {
        padding-left: 0px;
    }
    .date-entry {
        margin-bottom: 20px;
    }
    .date-header {
        color: rgba(255,255,255,0.5);
        font-size: 14px;
        margin-bottom: 10px;
    }
    .date-body {
        color: rgba(255,255,255,0.5);
        font-size: 14px;
    }
    .delete-date {
        font-size: 14px;
        color: rgba(255,255,255,0.5);
        cursor: pointer;
        font-size: 14px;
        color: rgba(255,255,255,0.5);
        cursor: pointer;
    }
    #sr-extra-date-infos {
        max-height: 300px;
        overflow-x: hidden;
        overflow-y: hidden;
        margin-left: -15px;
        margin-right: -15px;
        &:hover {
            overflow-y: auto;
        }
    }
    .event-wrapper {
        margin-right: 15px;
    }
    .event-item {
        margin-bottom: 20px;
        background-color: #073B50;
        border-radius: 7px;
        padding: 20px;
    }
    .event-header {
        display: flex;
        justify-content: space-between;
    }
    .event-date {
        font-size: 12px;
        line-height: 17px;
        color: #AAD0D2;
        opacity: 0.7;
        display: flex;
    }
    .delete-event {
        font-size: 14px;
        color: rgba(255,255,255,0.5);
        cursor: pointer;
        display: flex;
        justify-content: flex-end;
    }
    .update-event {
        font-size: 14px;
        color: rgba(255,255,255,0.5);
        cursor: pointer;
    }
    .event-title {
        font-size: 12px;
        line-height: 17px;
        color: #FFFFFF;
        opacity: 0.5;
        margin-top: 10px;
    }
    .event-text {
        font-size: 12px;
        color: #FFFFFF;
        margin-top: 10px;
    }
    .event-date-text {
        margin-right: 10px;
    }

    #successMessage {
        color: #454545;
    }
    #eventCreatedMessage {
        color: #454545;
    }
    #eventDeleteMessage {
        color: #454545;
    }
    #sr-missing-field-message {
        color: #454545;
        display: flex;
    }
    #rteMessage {
        color: #454545;
        display: flex;
    }
    #sr-modal-success-message {
        color: #454545;
        color: #454545;
        display: flex;
    }
    #sr_date_arrow {
        position: relative;
        float: right;
        top: -13px;
        right: 5px;
        b {
            border-color: rgb(117, 117, 117) transparent transparent transparent;
            border-width: 7px 5px 0 5px;
            border-style: solid;
        }
    }
    #sr-add-extra-date {
        margin-top: 5px;
    }
    .event-button {
        padding: 0px;
        display: flex;
        flex-direction: row;
    }
    #sr-modal-success {
        .modal-text {
            display: flex;
        }
        .modal-dialog {
            .modal-content {
                .modal-body {
                    min-height: 10px;
                }
                border-radius: 4px;
            }
        }
    }
    #sr-rte-too-many-characters {
        .modal-dialog {
            .modal-content {
                .modal-body {
                    min-height: 10px;
                }
                border-radius: 4px;
            }
        }
    }
    #sr-missing-field {
        .modal-dialog {
            .modal-content {
                .modal-body {
                    min-height: 10px;
                }
                border-radius: 4px;
            }
        }
    }
    #sr-modal-event-delete {
        color: #454545;
        .modal-body {
            min-height: 30px;
        }
    }
    #sr-modal-newsfeed-delete {
        color: #454545;
        .modal-body {
            min-height: 30px;
        }
    }
    #sr-missing-field-message-text {
        margin-top: 5px;
        margin-top: 5px;
    }
    #sr-modal-success-message-text {
        margin-top: 5px;
    }
    #rtedMessageText {
        margin-top: 5px;
    }
    #newsfeedDeleteCancelButton {
        border-color: #454545;
        color: #454545;
    }
    #sr-extra-date-infos {
        max-height: 300px;
        overflow-y: scroll;
        margin-left: -15px;
        margin-right: -15px;
    }

    .event-wrapper {
        margin-bottom: 50px;
    }
    .event-item {
        .icons {
            display: flex;
            .delete-event {
                margin-right: 10px;
            }
        }
    }
    .form-control#sr_date_time {
        padding: 0 !important;
    }
    @media (min-width: 768px) {
        section.dashboard {
            .sr-icon-grid {
                >li {
                    flex-basis: 0% !important;
                }
            }
        }
        .form-horizontal {
            .form-column.form-width-75 {
                width: 75% !important;
            }
        }
    }
    @media (max-width: 991px) {
        .sr-icon-grid {
            li {
                min-width: 0%;
            }
        }
        .form-group-sm {
            .form-control {
                font-size: 14px;
            }
        }
    }
    @media (max-width: 480px) {
        .form-horizontal {
            legend {
                font-size: 16px;
            }
            .control-label {
                font-size: 12px;
                color: #fff !important;
            }
        }
    }

}
