/* includes styling for FAQ, M08 accordion and M44 bulletin board */
.accordion-template {

    .akkordeon-header {
        display: flex;
        justify-content: center;
        color: #454545;
        h1 {
            margin-top: 55px;
        }
    }

    .search-bar {
        margin-top: 0;
        display: flex;
        justify-content: center;

        input {
            width: 100%;
            height: 52px;
            box-shadow: 0 2px 21px rgba(0, 0, 0, 0.06);
            border-radius: 26px;
            background-color: #ffffff;
            border: 1px solid #efecec;
            padding-left: 30px;
            font-size: 17px;
            &::placeholder {
                opacity: 0.44;
            }
            &:focus {
                outline: none;
            }
        }

        .search-field-wrapper {
            position: relative;
            width: 100%;
        }

        #clear-btn {
            position: absolute;
            right: 20px;
            height: 100%;
            width: 40px;
            color: #ACADAB;
        }

        #clear-btn::after {
            font-family: 'sr-icons', 'FontAwesome';
            content: "\e947";
            font-size: 20px;
            position: absolute;
            top: 15px;
        }

        #clear-btn.delete::before {
            font-family: 'sr-icons', 'FontAwesome';
            content: "\e955";
            font-size: 28px;
            position: absolute;
            color: #454545;
            top: 5px;
            left: -47.5px;
            cursor: pointer;
        }
    }

    .faq-categories {
        display: flex;
        flex-wrap: wrap;
        margin-top: 35px;

        .faq-category {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding: 20px 0 15px;
            margin: 1px;
            color: #fefefe;
            flex-basis: 45%;
            background-color: #073b50;
            cursor: pointer;
            &.active {
                background-color: #8CB82B;
            }
            &:first-child {
                border-radius: 7px 0 0 0;
            }
            &:nth-child(2) {
                border-radius: 0 7px 0 0;
            }
            &::nth-child(5) {
                border-radius: 0 0 0 7px;
            }
            &:last-child {
                border-radius: 0 0 7px 0;
            }
            p {
                font-size: 12px;
                &::before {
                    display: block;
                    font-size: 40px;
                    line-height: 1.7;
                    padding-top: 20px;
                    padding-bottom: 10px;
                }
            }
        }

        .icon-rules::before {
            font-family: 'sr-icons', 'FontAwesome';
            content: "\e953";
        }
        .icon-skyline::before {
            font-family: 'sr-icons', 'FontAwesome';
            content: "\e900";
        }
        .icon-app::before {
            font-family: 'sr-icons', 'FontAwesome';
            content: "\e954";
        }
        .icon-bike::before {
            font-family: 'sr-icons', 'FontAwesome';
            content: "\e90e";
        }
        .icon-radar::before {
            font-family: 'sr-icons', 'FontAwesome';
            content: "\e952";
        }
        .icon-movebis::before {
            font-family: 'sr-icons', 'FontAwesome';
            content: "\e951";
        }

    }

    .panel {
        margin-bottom: 0;
        box-shadow: none;
    }

    &.accordion-promotion {
        .akkordeon-topics {
            margin-top: 0;
            margin-bottom: 0;
            .akkordeon-topic {
                padding-top: 0;
                margin-top: 0;

                .akkordeon-topic-header {
                    .promotion-progressbar {
                        width: 56%;
                        position: absolute;
                        display: inline-block;
                        right: 75px;
                    }
                }

                .akkordeon-topic-body {
                    .akkordeon-content {
                        margin: 24px 275px 20px 100px;
                    }
                }
            }
        }
    }

    .akkordeon-topics {
        margin-top: 40px;
        margin-bottom: 40px;

        .akkordeon-topic {
            // anchor header offset
            padding-top: 130px;
            margin-top: -130px;

            li {
                padding-left:15px;
            }

            .akkordeon-topic-header {
                background-color: #ffffff;
                margin-bottom: 0;
                position: relative;
                border-bottom: 1px solid #DBDDE1;
                //box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.08);
                box-shadow: none;
            }

            .akkordeon-topic-header .akkordeon-topic-link {
                color: #454545;
                font-size: 16px;
                line-height: 20px;
                font-weight: 400;
                padding: 28px 75px 28px 30px;
                margin-bottom: 0;

                .state-name {
                    width: 30%;
                    display: inline-block;
                    line-height: 26px;
                }
            }

            .akkordeon-topic-link a,
            .akkordeon-topic-link a:hover,
            .akkordeon-topic-link a:focus {
                color: #454545;
            }

            .akkordeon-topic-header a::after {
                font-family: 'sr-icons', 'FontAwesome';
                content: "\e5c8";
                position: absolute;
                right: 40px;
                top: 35%;
                font-size: 20px;
                border: 1px solid #8CB82B;
                border-radius: 25px;
                color: #8CB82B;
                padding: 1px 1px 1px 2px;
                width: 25px;
                height: 25px;
                line-height: 20px;
            }
            .akkordeon-topic-header {
                &.akkordeon-add {
                    a {
                        &::after {
                            font-family: Roboto, Open Sans, Segoe UI, sans-serif;
                            content: "+";
                            font-size: 28px;
                            display: inline;
                            text-align: center;
                            line-height: 20px;
                            font-weight: 300;
                            padding: 1px 4px;
                        }
                        &:not(.collapsed)::after {
                            font-family: "sr-icons", "FontAwesome";
                            content: "\e5c8";
                            font-size: 20px;
                            line-height: 20px;
                            padding: 1px;
                        }
                    }
                }
            }

          .akkordeon-topic-header a:not(.collapsed)::after {
            transform:rotate(90deg);
          }
        .akkordeon-topic-body {
            .akkordeon-content {
              font-size: 14px;
              line-height: 22px;
              letter-spacing: normal;
              font-weight:300;
              color: #454545;
              margin: 15px 275px 20px 100px;
              b, strong {
                font-weight: 500;
              }
            }
            /* content elements inside accordion */
            .frame-default {
                //margin: 15px 130px 20px 130px;
                margin: 24px 130px 20px 100px;
                /* another content element (grid element) inside accordion */
                .frame-default {
                    margin: 0;
                    /* three cols */
                    .col-md-4 {
                        padding-left: 0;
                    }
                    /* one col */
                    .col-md-12 {
                        padding-left: 0;
                        padding-right: 0;
                    }
                    h2 {
                        font-size: 15px;
                        line-height: 22px;
                        text-align: left;
                    }
                }
            }
            .column {
                .col-md-12 {
                    > h2 {
                        margin-bottom: 10px;
                    }
                    > h3 {
                        margin-bottom: 10px;
                    }
                }
            }
        }
        &.m44-item {
            .akkordeon-topic-body {
                .akkordeon-content {
                    margin: 35px 100px 40px 100px;
                    h2, h3, h4, p, ul, ol {
                        margin-right: 175px;
                    }
                }
            }
        }
    }

    .akkordeon-topic.active {
        .akkordeon-topic-header {
            background-color: #ffffff;
            margin-bottom:0;
            position:relative;
            box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.08);
        }
    }
}

    &.dark-bg {
        .akkordeon-header {
            color:#fff;
        }
        .akkordeon-topics {
            margin-top: 50px;
            .akkordeon-topic {
            .akkordeon-topic-header {
                background-color: transparent;
                border-bottom:1px solid transparent;
                box-shadow: none;

                    .akkordeon-topic-link {
                        color: #FFFFFF;
                        position: relative;

                        a,
                        a:hover,
                        a:focus {
                            color: #FFFFFF;
                        }
                    }

                    a::after {
                        border: 1px solid #FFFFFF;
                        color: #FFFFFF;
                    }

                    > a {
                        .akkordeon-topic-link {
                            background-color: #0E4761;
                            border-bottom: 1px solid #0E4761;
                        }

                        &.collapsed {
                            .akkordeon-topic-link {
                                background-color: #073B50;
                                border-bottom: 1px solid #073B50;
                            }
                        }
                    }
                }
                .akkordeon-topic-body {
                    .akkordeon-content {
                        color: #fff;
                        margin: 15px 10px 20px 10px;
                        padding-top: 10px;
                        padding-bottom: 10px;
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .accordion-template {

        &.spacing-negative {
            margin-left: -15px;
            margin-right: -15px;
        }

        /* bootstrap cols */
        [class^="col-"],
        [class*=" col-"] {
            padding-left: 30px;
            padding-right: 30px;
        }

        &.container {
            padding-left: 0;
            padding-right: 0;
        }

        &.accordion {
            .akkordeon-header {
                margin-top: 15px;
                margin-bottom: 15px;

                h1 {
                    margin: 0;
                    margin-top: 30px;
                }
            }

            .search-bar {
                input {
                    height: 40px;
                }

                .search-field-wrapper::after {
                    top: 6px;
                }
            }
        }


        .akkordeon-topics {
            .akkordeon-topic {
                // anchor header offset
                padding-top: 50px;
                margin-top: -50px;

                .akkordeon-topic-header {
                    overflow: hidden;

                    .akkordeon-topic-link {
                        padding: 36px 75px 36px 30px;
                    }
                }

                .akkordeon-topic-body {
                    .akkordeon-content {
                        margin: 24px 21px 24px 30px;
                    }

                    /* MOBILE: another content element (grid element) inside accordion */
                    .frame-default {
                        margin: 0 15px;

                        /* three cols */
                        .col-md-4 {
                            padding-left: 0;
                            padding-right: 0;
                        }

                        .col-md-12 {
                            padding-left: 0;
                            padding-right: 0;
                        }

                        h2 {
                            font-size: 15px;
                            line-height: 22px;
                        }
                    }
                }
                &.m44-item {
                    .akkordeon-topic-header {
                        a {
                            &::after {
                                right: 30px;
                            }
                        }
                    }
                    .akkordeon-topic-body {
                        .akkordeon-content {
                            margin: 35px 0 40px 0;
                            h2, h3, h4, p, ul, ol {
                                margin-left: 30px;
                                margin-right: 30px;
                            }
                            img {
                                border-radius: 0;
                            }
                        }
                    }
                }
            }
        }

        .akkordeon-topic-body {
            ul {
                padding-left: 10px;
            }
        }

        h1,
        h2 {
            font-size: 24px;
            line-height: 36px;
        }

        .akkordeon-header {
            margin-left: 30px;
            margin-right: 30px;
            justify-content: normal;
        }

        .container {
            padding-left: 0;
            padding-right: 0;
        }

        .search-bar {
            margin: 30px 0;

            .search-field-wrapper {
                padding-left: 30px;
                padding-right: 30px;

                &::after {
                    right: 50px;
                }
            }

            input {
                height: 40px;
                font-size: 14px;
                line-height: 17px;
            }

            #clear-btn {
                &.delete::before {
                    top: 0;
                    left: -60px;
                }

                &::after {
                    top: 5px;
                    right: 40px;
                }
            }
        }

        .faq-categories {
            margin-left: 30px;
            margin-right: 30px;
        }

        .akkordeon-topics {
            margin-top: 30px;
            margin-bottom: 110px;

            .akkordeon-topic {
                .akkordeon-topic-body {
                    .frame-default {
                        margin: 0;
                    }
                }

                .akkordeon-topic-header {
                    .akkordeon-topic-link {
                        color: #4A4A4A;
                        padding: 0 75px 0 30px;
                        display: flex;
                        align-items: center;
                        min-height: 95px;
                    }
                }
            }
        }

        &.dark-bg {
            .akkordeon-topics {
                .akkordeon-topic {
                    .akkordeon-topic-header {
                        margin-left: -30px;
                        margin-right: -30px;

                        &.sticky {
                            top: 0px;

                            #sticky-dropdown-nav {
                                position: absolute;
                                left: -3000px;
                            }
                        }
                    }

                    .akkordeon-topic-body {
                        .akkordeon-content {
                            margin: 15px 0 20px 0;
                        }
                    }
                }
            }
        }

        &.accordion-promotion {
            .akkordeon-topics {
                .akkordeon-topic {
                    .akkordeon-topic-header {
                        .akkordeon-topic-link {
                            flex-wrap: wrap;
                            padding: 15px 30px 15px 30px;

                            .state-name {
                                line-height: inherit;
                                display: block;
                                width: 100%;
                            }
                        }

                        .promotion-progressbar {
                            display: block;
                            width: 100%;
                            position: relative;
                            right: 0;
                        }
                        a::after {
                            top: 17px;
                            right: 30px;
                        }
                    }

                    .akkordeon-topic-body {
                        .akkordeon-content {
                            margin: 24px 30px 24px 30px;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .accordion-template {
        &.accordion {
            .accordion-image-wrapper {
                margin: 0 24px 34px 24px;
                img {
                    border-radius: 10px;
                }
            }

            .search-bar {
                padding: 0 24px;
                margin-bottom: 32px;
            }

            .akkordeon-header {
                margin-top: 50px;
                margin-bottom: 25px;

                h1 {
                    margin: 0;
                }
            }
        }
        &.spacing-negative {
            margin-left: -15px;
            margin-right: -15px;
        }

        .akkordeon-content {

            h2,
            h3 {
                margin-top: 24px;
            }

            .promotion-info-text {
                margin-right: 82px;
            }
        }

        &.container {
            padding: 0;
        }

        h1,
        h2 {
            font-size: 24px;
            line-height: 36px;
        }

        .faq-categories {
            margin-bottom: 35px;
            .faq-category {
                flex-basis: 25%;
                &:first-child {
                    border-radius: 7px 0 0 0;
                }
                &:nth-child(2) {
                    border-radius: 0;
                }
                &:nth-child(3) {
                    border-radius: 0 7px 0 0;
                }
                &:nth-child(4) {
                    border-radius: 0 0 0 7px;
                }
                &:nth-child(5) {
                    border-radius: 0;
                }
                &:last-child {
                    border-radius: 0 0 7px 0;
                }
            }
        }

        &.m44-bulletin-board {
            .faq-categories {
                .faq-category {
                    flex-basis: 45%;
                    &:last-child {
                        border-radius: 0 0 7px 7px;
                    }
                    &:nth-child(5) {
                        border-radius: 0 0 7px 7px;
                    }
                }
            }
        }

        .akkordeon-topic-body {
            p {
                padding-left: 0;
            }

            ul {
                margin-left: -25px;
            }
        }

        .akkordeon-topics {
            margin-top: 10px;
            margin-bottom: 65px;

            .akkordeon-topic {
                // anchor header offset
                padding-top: 90px;
                margin-top: -90px;

                .akkordeon-topic-header {
                    a {
                        &::after {
                            right: 24px;
                        }
                    }

                    .akkordeon-topic-link {
                        padding: 30px 75px 30px 24px;
                    }
                }

                .akkordeon-topic-body {
                    .akkordeon-content {
                        margin: 24px;

                        h2,
                        h3,
                        h4 {
                            margin-bottom: 24px;
                        }

                        p {
                            margin: 0;
                        }
                    }

                    /* TABLET: another content element (grid element) inside accordion */
                    .frame-default {
                        margin: 15px 24px 20px 14px;

                        // three cols
                        .col-md-4 {
                            padding-left: 9px;
                        }

                        // one col
                        .col-md-12 {
                            padding-left: 9px;
                            padding-right: 9px;
                        }

                        h2 {
                            font-size: 15px;
                            line-height: 22px;
                        }
                    }
                }
                &.m44-item {
                    .akkordeon-topic-body {
                        .akkordeon-content {
                            margin: 35px 24px 40px 24px;
                            p {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }

        &.dark-bg {
            .akkordeon-topics {
                .akkordeon-topic {
                    .akkordeon-topic-header {
                        margin-left: -30px;
                        margin-right: -30px;

                        &.sticky {
                            position: -webkit-sticky;
                            position: -moz-sticky;
                            position: -ms-sticky;
                            position: -o-sticky;
                            position: sticky;
                            top: 90px;
                            z-index: 999;

                            #sticky-dropdown-nav {
                                display: none;
                                position: absolute;
                                top: 21px;
                                left: 280px;

                                .select2-selection,
                                .select2-container--open .select2-selection {
                                    border-color: #fff;
                                    border-radius: 0px;
                                    border-width: 0 0 1px 0;
                                }
                            }

                            &.stuck {
                                &>a {
                                    pointer-events: none;
                                }

                                #sticky-dropdown-nav {
                                    display: block;
                                }

                                .akkordeon-topic-link {
                                    -webkit-box-shadow: 0px 30px 50px 10px rgba(6, 53, 71, 0.85);
                                    box-shadow: 0px 30px 50px 10px rgba(6, 53, 71, 0.85);
                                }
                            }
                        }
                    }
                }
            }
        }

        &.accordion-promotion {
            .akkordeon-topics {
                .akkordeon-topic {
                    .akkordeon-topic-body {
                        .akkordeon-content {
                            margin: 24px;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 1024px) {
    .accordion-template {
        &.accordion {
            .accordion-image-wrapper {
                margin: 0;
                margin-bottom: 40px;
            }

            .search-bar {
                padding: 0;
            }
        }

        .search-bar {
            .search-field-wrapper {
                width: auto;
            }

            input {
                width: 639px;
            }
        }

        .faq-categories {
            .faq-category {
                flex-basis: 15%;
                &:first-child {
                    border-radius: 7px 0 0 7px;
                }
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5) {
                    border-radius: 0;
                }
                &:last-child {
                    border-radius: 0 7px 7px 0;
                }
            }
        }

        .akkordeon-topics {
            .frame-default {

                /* three cols */
                .col-md-4 {
                    padding-left: 0;
                }

                /* one col */
                .col-md-12 {
                    padding-left: 0;
                    padding-right: 0;
                }
            }

            .akkordeon-topic {
                .akkordeon-topic-header .akkordeon-topic-link {
                    margin-left: 70px;
                }
            }
        }
    }

    .accordion-template.dark-bg {
        .akkordeon-topic {
            .akkordeon-topic-header {
                &.sticky {
                    position: sticky;
                    top: 128px;
                    z-index: 999;

                    #sticky-dropdown-nav {
                        display: none;
                        position: absolute;
                        top: 21px;
                        left: 280px;

                        .select2-selection,
                        .select2-container--open .select2-selection {
                            border-color: #fff;
                            border-radius: 0px;
                            border-width: 0 0 1px 0;
                        }
                    }

                    &.stuck {
                        &>a {
                            pointer-events: none;
                        }

                        #sticky-dropdown-nav {
                            display: block;
                        }

                        .akkordeon-topic-link {
                            -webkit-box-shadow: 0px 30px 50px 10px rgba(6, 53, 71, 0.85);
                            box-shadow: 0px 30px 50px 10px rgba(6, 53, 71, 0.85);
                        }
                    }
                }

                .akkordeon-topic-link {
                    margin-left: 0px;
                    padding-left: 43px;
                    padding-right: 15px;
                }
            }
        }
    }
}
