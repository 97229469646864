.switch {
	position: relative;
	display: block;
	height: 18px;
	width: 30px;
	margin-bottom: 30px;
	input {
		display: none;
	}
}
.toggle {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #adadad;
	-webkit-transition: .4s;
	transition: .4s;
	&:before {
		position: absolute;
		content: "";
		height: 20px;
		width: 20px;
		left: -8px;
		bottom: -1px;
		background-color: #7DCA26;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
		-webkit-transition: .4s;
		transition: .4s;
	}
}
input {
	&:checked {
		+ {
			.toggle.toggle-blue {
				background-color: #2196F3;
			}
			.toggle.toggle-green {
				background-color: #7EC927;
			}
			.toggle.toggle-dark {
				background-color: #102C2E;
			}
			.toggle {
				&:before {
					-webkit-transform: translateX(26px);
					-ms-transform: translateX(26px);
					transform: translateX(26px);
					background-color: #7DCA26;
				}
			}
		}
	}
	&:focus {
		+ {
			.toggle.toggle-blue {
				box-shadow: 0 0 1px #2196F3;
			}
			.toggle.toggle-green {
				box-shadow: 0 0 1px #7EC927;
			}
			.toggle.toggle-dark {
				box-shadow: 0 0 1px #102C2E;
			}
		}
	}
}
.toggle.round {
	border-radius: 34px;
	&:before {
		border-radius: 50%;
	}
}
.toggle-text {
	color: #FFFFFF;
	font-family: Roboto;
	font-size: 16px;
	line-height: 17px;
	position: absolute;
	left: 50px;
	top: -3px;
	font-weight: 200;
	min-width: 80px;
}
