.ride-searchbox-wrapper {
	position: relative;
	background-color: #063547;
	.ride-searchbox {
		position: absolute;
		top: 25px;
		left: 25px;
		z-index: 3999;
		background-color: transparent;
		min-width: 350px;
		input {
			padding-right: 75px !important;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
        .btn-search-city {
            display: none;
            right: 55px;
            z-index: 11;
            position: absolute;
            top: 13px;
            font-size: 22px;
        }
        .ride-search-icon {
            position: absolute;
            top: 0px;
            right: 10px;
            z-index: 10;
        }
	}
}

.ride_paragraph {
    text-align: center;
}

.ride-toggle {
    .nav {
        li {
            a {
                padding: 6px 15px;
            }
            &.active a.btn-default {
                border-color: #8CB82B;
            }
        }
    }
}

/* low res */
@media (max-width: 1024px) {

}
/* tablet */
@media (min-width: 768px) and (max-width: 992px) {
}
/* mobile */
@media (max-width: 767px) {
    .ride-searchbox-wrapper {
        .ride-searchbox {
            position: inherit;
            margin-right: 30px;
            margin-left: 30px;
            top: auto;
            left: auto;
            min-width: auto;
            padding-bottom: 10px;
            .btn-search-city {
                right: 55px;
                top: 10px;
            }
            .ride-search-icon {
                top: -3px;
                right: 10px;
            }
        }
    }
    .ride_paragraph {
        text-align: left;
    }
}