.uc {

    /*
	.form-group .select2.select2-container.select2-container--default,
	.form-group .select2.select2-container.select2-container--default .select2-selection--single {
		border-bottom-width: 1px;
	}
    */
    .select2-selection {
        min-width: 100%;
        font-size: 14px;
        border-color: #fff;
        background-color: none;
        transition: background-color 500ms;
        display: inline-block;
        vertical-align: middle;
        touch-action: manipulation;
        cursor: pointer;
        background-image: none;
        padding: 4px 4px;
        padding-left: 0px;
    }

    .form-group .select2.select2-container.select2-container--default {
        height: 32px;
    }

    .form-group .select2.select2-container.select2-container--default .select2-selection--single {
        height: 26px;
        padding: 0px;
    }

    .select2-container {
        .select2-selection--single {
            height: 30px;
            .select2-selection__rendered {
                padding-left: 0px;
                line-height: 22px;
            }
        }
    }

    .select2-container--default {
        .select2-selection--single {
            background-color: transparent;
            border-radius: 0;
            .select2-selection__placeholder {
                color: rgba(69,69,69,0.5);
            }
            .select2-selection__arrow {
                top: 6px;
                right: 0px;
                b {
                    color: rgba(254,254,254,1);
                    border-color: #888 transparent transparent transparent;
                    top: 36%;
                }
            }
        }
        .select2-results {
            >.select2-results__options {
                max-height: 500px;
                overflow-y: auto;
                overflow-x: hidden;
            }
        }

        .select2-search--dropdown {
            .select2-search__field {
                border: 0;
                padding: 7px 0;
                border-bottom: 1px solid rgba(151,151,151,0.8);
                background-color: transparent;
                color: #454545;
            }
        }
        .select2-results__group {
            cursor: default;
            display: block;
            padding: 0px;
            font-weight: normal;
        }
    }
    .disabled {
        .select2-container--default {
            .select2-selection--single {
                .select2-selection__placeholder {
                    color: rgba(69,69,69,0.3);
                }
            }
        }
    }
    .select2-container--default.select2-container--open {
        .select2-selection--single {
            .select2-selection__arrow {
                b {
                    border-color: transparent transparent rgba(254,254,254,0.8) transparent;
                }
            }
        }
    }

    .select2-container--open {
        .select2-dropdown {
            .select2-results {
                ul.select2-results__options {
                    color: #454545;
                    .select2-results__option--highlighted[aria-selected] {
                        background-color: #fff;
                        color: #0086C5;
                        font-weight: 500;
                    }
                    position: inherit;
                    background-color: #fff;
                    li.select2-results__option {
                        color: rgba(255,255,255,0.5);
                        font-size: 12px;
                        font-weight: normal;
                    }
                }
            }
        }
        .select2-selection--single {
            border-color: #073B50;
        }
    }
    .select2-search--dropdown {
        padding: 6px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .select2-results__group {
        position: relative;
        &:after {
            position: absolute;
            content: "\e941";
            transform: rotate(-90deg);
            right: 22px;
            top: 4px;
            font-size: 6px;
            height: 10px;
            width: 10px;
            border: none;
            background: none;
            text-align: center;
            line-height: 32px;
            font-family: sr-icons;
            color: white;
        }
    }
    .select2-search.select2-search--dropdown {
        position: relative;
        &:after {
            position: absolute;
            right: 23px;
            top: 4px;
            font-size: 18px;
            height: 10px;
            width: 10px;
            border: none;
            background: none;
            text-align: center;
            line-height: 32px;
            font-family: sr-icons;
            color: rgba(172,173,171,1);
            content: "\e947";
        }
    }
    .select2-results__group.open {
        &:after {
            content: "\e942";
            transform: rotate(180deg);
            right: 12px;
            top: 15px;
        }
        color: #fff;
        padding-bottom: 5px;
    }
    ul.select2-results__options.open {
        box-shadow: 0 0 black;
        margin-top: 0px;
        li {
            background-color: rgba(14,71,97,1);
            border-left: 0px;
            border-right: 0px;
            color: rgba(255,255,255,0.5);
            font-size: 12px;
            font-weight: 500;
        }
    }

    .sidebar-menu.dropdown.open {
        #dropdownMenu1 {
            color: #454545;
        }
    }

    .select2-container--open .select2-selection {
        background-color: transparent;
        border: 0;
    }

    .select2-container--open {
        .select2-selection {
            background-color: transparent;
            border-width: 0 0 1px 0;
            border-color:rgba(0, 135, 197, 0.8);
            border: 1px solid;
        }
        .select2-dropdown--below {
            background-color: #fff;
            border-top: 0px;
            box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.08);
        }
        .select2-dropdown {
            width: 100%;
            .select2-results {
                ul.select2-results__options {
                    position: absolute;
                    display: block;
                    width: 100%;
                    border: 0;
                    border-radius: 0;
                    margin-top: -5px;
                    text-align: left;
                    line-height: 2.4;
                    color: #fff;
                    background-color: #fff;
                    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.08);
                    li.select2-results__option {
                        text-align: left;
                        font-size: 14px;
                        line-height: 17px;
                        border: 0;
                        padding: 8px 5px 8px 15px;
                        &:last-child {
                            padding-bottom: 15px;
                        }
                    }
                    .select2-results__option--highlighted[aria-selected] {
                        color: #0086C5;
                        background-color: transparent;
                    }
                    li.select2-results__option {
                        color: #454545;
                        background-color: transparent;
                    }
                    li.select2-results__option[aria-selected=true] {
                        color: #0086C5;
                        background-color: transparent;
                    }
                }
            }
        }
    }

    .select2-container.select2-container--default {
        border-width: 0 0 1px 0;
        height: 30px;
        .select2-selection--single {
            border-width: 0 0 1px 0;
            font-size: 14px;
            border-color: rgba(151, 151, 151, 0.8);
        }
    }
    .select2.select2-container.select2-container--open {
        .select2-selection--single {
            border-color:rgba(0, 135, 197, 0.8);
        }
    }
    
    .form-group .select2.select2-container.select2-container--default {
        border-color: transparent;
    }

    // light background specific (typo3 sites)
    /*
    &.light-bg, .light-bg {

    } 
    */
    // .light-bg end

    // dark background specific (ORK sites)
    .dark-bg .select2-selection {
        color: #fff;
    }

    .dark-bg {
        .select2-container--default {
            .select2-selection--single {
                background-color: #063547;
                border-color: rgba(255, 255, 255, 0.8);
                border-radius: 0px;
                border-width: 0 0 1px 0;
                .select2-selection__rendered {
                    color: #fff;
                }
                .select2-selection__arrow {
                    b {
                        color: #fff;
                        border-color: rgba(255, 255, 255, 0.8) transparent transparent transparent;
                    }
                }
            }
        }

        .form-group {
            .select2.select2-container.select2-container--default {
                .select2-selection--single {
                    border-color: rgba(255, 255, 255, 0.8);
                }
            }
        }
    }

    &.dark-bg .form-horizontal {
        .select2-container--default {
            .select2-selection--single {
                .select2-selection__arrow {
                    b {
                        border-color: rgba(255, 255, 255, 0.8) transparent transparent transparent;
                    }
                }
            }
        }
    }

    &.dark-bg .select2.select2-container.select2-container--default .select2-selection--single {
        border-color: rgba(255, 255, 255, 0.8);
    }

    &.dark-bg .select2.select2-container.select2-container--open .select2-selection--single {
        border-color: rgba(0, 135, 197, 0.8);
    }

    .dark-bg {
        .sticky {
            .select2-container--default {
                .select2-selection--single {
                    background-color: transparent;
                }
            }
        }
    }

    .select2-container.dark-bg {
        .select2-selection--single {
            .select2-selection__rendered {
                color: #fff;
            }
        }
    }

    .select2-container--default.dark-bg,
    .dark-bg .select2-container--default,
    &.dark-bg .select2-container--default {
        .select2-selection--single {
            .select2-selection__placeholder,
            .select2-selection__rendered {
                color: rgba(254,254,254,0.8);
            }
            .select2-selection__arrow {
                b {
                    color: rgba(254,254,254,1);
                    border-color: rgba(254,254,254,1) transparent rgba(254,254,254,1) transparent;
                }
            }
        }
        .select2-search--dropdown {
            &:after {
                color: rgba(255,255,255,0.3);
                content: "\e947";
            }
            .select2-search__field {
                border-bottom: 1px solid rgba(255, 255, 255, 0.8);
                background-color: transparent;
                color: #fff;
            }
        }
    }

    .disabled {
        .select2-container--default.dark-bg {
            .select2-selection--single {
                .select2-selection__placeholder {
                    color: rgba(254,254,254,0.4);
                }
            }
        }
    }

    .select2-container--open.dark-bg .select2-dropdown .select2-results ul.select2-results__options {
        background-color: #0b3a4f !important;
        box-shadow: 0 0px 12px rgba(0, 0, 0, 0.175);
    }

    .select2-container--open.dark-bg {
        .select2-selection {
            background-color: transparent;
            border-width: 0 0 1px 0;
            border-color:rgba(0, 135, 197, 0.8);
        }
        .select2-dropdown--below {
            background-color: #073B50;
            border-top: 0px;
            box-shadow: 0 0px 12px rgba(0, 0, 0, 0.175);
        }
        .select2-selection--single {
            .select2-selection__arrow {
                b {
                    color: #0086C5;
                    border-color: #0086C5 transparent #0086C5 transparent
                }
            }
        }
        .select2-dropdown {
            width: 100%;
            .select2-results {
                ul.select2-results__options {
                    background-color: #0b3a4f !important;
                    box-shadow: 0 0px 12px rgba(0, 0, 0, 0.175);
                    .select2-results__option--highlighted[aria-selected] {
                        color: #0086C5;
                        background-color: transparent;
                    }
                    li.select2-results__option {
                        color: rgba(255, 255, 255, 0.5);
                        background-color: transparent;
                    }
                    li.select2-results__option[aria-selected=true] {
                        color: #0086C5;
                        background-color: transparent;
                    }
                }
            }
        }
        ul.select2-results__options--nested.open {
            box-shadow: none !important;
        }
    }
    .dark-bg .form-group {
        .select2.select2-container.select2-container--open .select2-selection--single {
            border-color: rgba(0, 135, 197, 0.8);
        }
    }

    // .dark-bg end

    .select2-container--default {
        .select2-selection--single {
            .select2-selection__arrow {
                b {
                    border-width: 6px 5px 0 5px;
                    margin-left: 0;
                }
            }
        }
    }

    // special select2 (search-contact)
    .commune-search {
        width: 100%;
        max-width: 542px;
        .select2-container {
            &.select2-container--default .select2-selection--single {
                border: 1px solid #efecec;
                font-size: 17px;
                line-height: 24px;
                width: 100%;
            }
            .select2-selection--single .select2-selection__rendered {
                line-height: 28px;
            }
        }
        .select2-container--default {
            height: auto;
            .select2-selection--single .select2-selection__arrow {
                top: 1px;
            }
        }
    }
    // js added class to select2 overlay (appended to body)
    .commune-search-overlay {
        &.select2-container--open {
            .select2-dropdown {
                .select2-results ul.select2-results__options {
                    border-bottom-left-radius: 25px;
                    border-bottom-right-radius: 25px;
                    border:1px solid #efecec;
                    border-top:0;
                    max-height: 210px;
                    padding-bottom: 10px;
                    li.select2-results__option {
                        font-size: 17px;
                        line-height: 24px;
                        padding: 10px 35px;
                    }
                }
            }
            .select2-dropdown--below {
                border-top-left-radius: 25px;
                border-top-right-radius: 25px;
            }
        }
        /* remove double search icon */
        .select2-search.select2-search--dropdown {
            &::after {
                display: none;
            }
            padding: 2px 35px;
        }
        &.select2-container--default .select2-search--dropdown .select2-search__field {
            border-bottom: 1px solid #0086C5;
            padding-top: 10px;
            padding-bottom: 14px;
        }
        /* ul li items */
        &.select2-container--open .select2-dropdown .select2-results ul.select2-results__options li.select2-results__option {
            font-size: 17px;
            line-height: 24px;
            padding: 10px 35px;
        }
    }

} // .uc end

/* tablet */
@media (min-width: 768px) and (max-width: 992px) {
    .uc {
        .commune-search {
            max-width: 452px;
        }
    }   
}


/* mobile */
@media (max-width: $breakpoint-mobile) {
    .uc {
        .select2-container--open {
            .select2-dropdown {
                .select2-results {
                    ul.select2-results__options {
                        li.select2-results__option {
                            font-size: 16px;
                            line-height: 2.4;
                        }
                    }
                }
            }
        }
        .select2-selection {
            padding-top: 0px;
        }
        .select2-container .select2-selection--single .select2-selection__rendered {
            line-height: 16px;
        }

        .select2-container {
            &.select2-container--default .select2-selection--single {
                font-size: 16px;
            }
        }

        // special select2 (search-contact)
        .commune-search {
            width: 100%;
            .select2-container {
                &.select2-container--default .select2-selection--single {
                    font-size: 14px;
                    line-height: 17px;
                    border-radius: 20px;
                }
            }
            .select2-container--default {
                .select2-selection--single .select2-selection__arrow {
                    top: 4px;
                    right: 8px;
                }
            }
            .select2-selection__arrow::after {
                font-size: 18px;
            }
            .select2-container {
                margin-bottom: 24px;
            }
        }
        .commune-search-overlay {
            .select2-search.select2-search--dropdown {
                padding: 0 24px;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
            }
            &.select2-container--default .select2-search--dropdown .select2-search__field {
                padding-bottom: 8px;
            }
            /* ul li items */
            &.select2-container--open .select2-dropdown .select2-results ul.select2-results__options li.select2-results__option {
                font-size: 14px;
                line-height: 24px;
                padding: 8px 24px;
            }
            .select2-dropdown--below {
                margin-top: -40px;
            }
        }
    } // .uc end
}