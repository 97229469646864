.stadtradler-star-tabelle {
    div.dataTables_wrapper {
        width: 100%;
        margin: 0 auto;
    }
    #sr-star-table_filter label {
        font-size: 14px;
        line-height: 1;
        font-weight: 300;
        color: #fefefe;
        margin-bottom: 40px;
    }
    #sr-star-table {
        width: 100%;
        &.dataTable {
            thead {
                th {
                    font-weight: 400;
                    &:last-child {
                        padding-right: 0;
                    }
                }
                .sorting {
                    color: rgba(255, 255, 255, 0.5);
                    &::after {
                        font-family: sr-icons;
                        content: "\e5c5" !important;
                        font-size: 25px;
                        position: relative;
                        display: inline;
                        top: 5px;
                        right: 0;
                        opacity: 1;
                    }
                }
                .sorting_asc {
                    color: #fff;
                    &::after {
                        opacity: 1;
                        font-family: sr-icons;
                        content: "\e5c5" !important;
                        font-size: 25px;
                        position: relative;
                        display: inline;
                        top: 5px;
                        right: 0;
                    }
                }
                .sorting_desc {
                    color: #fff;
                    &::after {
                        opacity: 1;
                        font-family: sr-icons;
                        content: "\e5c7" !important;
                        font-size: 25px;
                        position: relative;
                        display: inline;
                        top: 5px;
                        right: 0;
                    }
                }
            }
            tbody {
                tr {
                    background-color: transparent;
                    height: 75px;
                    &:first-child td {
                        border: 0;
                    }
                    > td {
                        padding: 0 10px 0 0;
                        vertical-align: middle;
                        border-top: 1px solid #052836;
                        font-weight: 300;
                        &:first-child {
                            padding-left: 0;
                        }
                        &:last-child {
                            padding-right: 0;
                        }
                        a {
                            color: #fefefe;
                        }
                    }
                }
            }
        }
    }
    
    .dataTables_wrapper {
        .dataTables_paginate {
            padding-top: 70px;
            .paginate_button {
                font-size: 16px;
                position: relative;
                background: transparent;
                color: #fff;
                &.current {
                    background: transparent;
                    color: $active !important;
                    font-weight: 600;
                    border: 0;
                    &:hover {
                        background: transparent;
                        color: $active;
                        border: 0;
                    }
                }
                &.disabled {
                    .pagination-icon {
                        fill: rgba(255, 255, 255, 0.4);
                    }
                }
                .pagination-icon {
                    height: 15px;
                    fill: #fff;
                    position: absolute;
                    top: 12px;
                    left: 0px;
                }
            }
        }
    }

    // checkboxes
    input[type="checkbox"] {
        display: none;
    }
    input[type="checkbox"] + label {
        margin-bottom: 0;
    }
    input[type="checkbox"] + label:before,
    input[type="checkbox"] + span:before {
        width: 16px;
        height: 16px;
        background-color: transparent;
        display: table;
        content:"";
        border-radius: 2px;
        border: 1px solid #fff;
        font-size:13px;
        line-height: 0.8;
        border-collapse:separate;
    }

    input[type="checkbox"]:checked + label:before,
    input[type="checkbox"]:checked + span:before {
        font-family: 'sr-icons', 'FontAwesome';
        font-size:13px;
        background-color: $color-green;
        content: "\f00c";
        color: #FFFFFF;
        width: 16px;
        height: 16px;
        border: 1.5px solid $color-green;
        display: table;
        line-height: 0.8;
    }

    // number per page dropdown
    .dataTables_length {
        display: none;
    }

    // custom number per page element
    .numberOfResults {
        display: block;
    }

    .status-approved {
        height:15px;
        width:15px;
        display:inline-block;
        position: relative;
        top: -2px;
        background-image: url("data:image/svg+xml,%3Csvg height='15' viewBox='0 0 15 15' width='15' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle cx='6.75' cy='7.5' fill='%23fff' r='6.75'/%3E%3Cpath d='m7.34159675.00164202c-1.97218601.04154919-3.84778188.85206042-5.22180132 2.25705193-1.37400295 1.40433695-2.13569696 3.28944086-2.11954366 5.24719982-.01287731 1.98279549.77135302 3.88966323 2.17888231 5.29645473 1.40813917 1.406906 3.3236238 2.1976515 5.32086592 2.1976515 1.9978974 0 3.9127227-.7906964 5.3208619-2.1976515 1.4074963-1.4069061 2.1923983-3.31365924 2.1788823-5.29645473.0167401-2.01290479-.7887426-3.94579085-2.2309685-5.35912787-1.4429017-1.41392611-3.40092884-2.18734236-5.42717895-2.14512388zm4.33699605 4.20024715-.0006438.00063916c.3096983-.01406251.5955788.16235949.7198438.44360488.1249095.28126012.0630984.60981148-.1558151.82713843l-5.4380577 5.39807356c-.3901846.3873627-1.02374008.3873627-1.41456752 0l-2.64881146-2.62965446c-.29102312-.2914711-.29038028-.76129071.00193164-1.05213999.29230879-.29019473.76619385-.29083291 1.05916196-.00127833l2.29475751 2.27881571 5.08400377-5.04723481h.0006438c.1319923-.13167745.3096983-.20965235.4970601-.21732695z' fill='%238cb82b' fill-rule='nonzero'/%3E%3C/g%3E%3C/svg%3E");
        margin-right: 10px;
        vertical-align: middle;
    }
    .status-declined {
        height:15px;
        width:15px;
        display:inline-block;
        position: relative;
        top: -2px;
        background-image: url("data:image/svg+xml,%3Csvg height='15' viewBox='0 0 15 15' width='15' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle cx='7.5' cy='7.5' fill='%23fff' r='7.5'/%3E%3Cpath d='m7.5 0c-4.14966667 0-7.5 3.35033333-7.5 7.5 0 4.1496667 3.35033333 7.5 7.5 7.5 4.1496667 0 7.5-3.3503333 7.5-7.5 0-4.14966667-3.3503333-7.5-7.5-7.5zm3.8163333 10.0331667c.3502667.3502666.3502667.9166666 0 1.2669333-.1836.1836-.3997333.2669333-.6334666.2669333-.2330667 0-.4668-.0833333-.6334667-.2669333l-2.49933333-2.5-2.517 2.5c-.1836.1836-.39973334.2669333-.63346667.2669333-.23306667 0-.4668-.0833333-.63346667-.2669333-.35026666-.3502667-.35026666-.9166667 0-1.2669333l2.517-2.51700003-2.51633333-2.49933334c-.35026667-.35026666-.35026667-.91666666 0-1.26693333s.91666667-.35026667 1.26693333 0l2.517 2.517 2.51699997-2.517c.3502667-.35026667.9166667-.35026667 1.2669334 0 .3502666.35026667.3502666.91666667 0 1.26693333l-2.5181667 2.51633334z' fill='%23e30613' fill-rule='nonzero'/%3E%3C/g%3E%3C/svg%3E");
        margin-right: 10px;
        vertical-align: middle;
    }
}