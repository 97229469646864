
/* styling header */
#topnav {
	height: 34px;
	transition: height .5s;
}

/* burger button */
.navbar-toggler {
	.navbar-toggler-icon {
		background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.53341 25.3333L6.66675 23.4666L14.1334 16L6.66675 8.53329L8.53341 6.66663L16.0001 14.1333L23.4667 6.66663L25.3334 8.53329L17.8667 16L25.3334 23.4666L23.4667 25.3333L16.0001 17.8666L8.53341 25.3333Z' fill='%2307293E'/%3E%3C/svg%3E");
	}
	&.collapsed .navbar-toggler-icon {
		background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 24V21.3333H28V24H4ZM4 17.3333V14.6667H28V17.3333H4ZM4 10.6667V8H28V10.6667H4Z' fill='%2307293E'/%3E%3C/svg%3E");
	}
	&:focus {
		box-shadow: none;
	}
}