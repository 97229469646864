.caret {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: 2px;
	vertical-align: middle;
	border-top: 4px dashed;
	border-top: 4px solid \9;
	border-right: 4px solid transparent;
	border-left: 4px solid transparent;
}
.dropup {
	position: relative;
	.caret {
		border-top: 0;
		border-bottom: 4px dashed;
		border-bottom: 4px solid \9;
		content: "";
	}
	.dropdown-menu {
		top: auto;
		bottom: 100%;
		margin-bottom: 2px;
	}
}
.dropdown {
	position: relative;
}
.dropdown-toggle {
	&:focus {
		outline: 0;
	}
}
.dropdown-menu {
	position: absolute;
	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 160px;
	padding: 5px 0;
	margin: 2px 0 0;
	list-style: none;
	font-size: 14px;
	text-align: left;
	background-color: #fff;
	border: 1px solid transparent;
	border: 1px solid transparent;
	border-radius: 4px;
	-webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	background-clip:unset;
	.divider {
		height: 1px;
		margin: 9px 0;
		overflow: hidden;
		background-color: #0E4761;
	}
	>li {
		>a {
			display: block;
			padding: 3px 20px;
			clear: both;
			font-weight: normal;
			line-height: 1.428571429;
			color: #fefefe;
			white-space: nowrap;
			&:hover {
				text-decoration: none;
				color: #fefefe;
				background-color: #fefefe;
			}
			&:focus {
				text-decoration: none;
				color: #fefefe;
				background-color: #fefefe;
			}
		}
	}
	>.active {
		>a {
			color: #fefefe;
			text-decoration: none;
			outline: 0;
			background-color: #fefefe;
			&:hover {
				color: #fefefe;
				text-decoration: none;
				outline: 0;
				background-color: #fefefe;
			}
			&:focus {
				color: #fefefe;
				text-decoration: none;
				outline: 0;
				background-color: #fefefe;
			}
		}
	}
	>.disabled {
		>a {
			color: #898989;
			&:hover {
				color: #898989;
				text-decoration: none;
				background-color: transparent;
				background-image: none;
				filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
				cursor: not-allowed;
			}
			&:focus {
				color: #898989;
				text-decoration: none;
				background-color: transparent;
				background-image: none;
				filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
				cursor: not-allowed;
			}
		}
	}
}
.dropdown-menu.pull-right {
	right: 0;
	left: auto;
}
.open {
	>.dropdown-menu {
		display: block;
	}
	>a {
		outline: 0;
	}
}
.dropdown-menu-right {
	left: auto;
	right: 0;
}
.dropdown-menu-left {
	left: 0;
	right: auto;
}
.dropdown-header {
	display: block;
	padding: 3px 20px;
	font-size: 12px;
	line-height: 1.428571429;
	color: #fff;
	white-space: nowrap;
}
.dropdown-backdrop {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	z-index: 990;
}
.pull-right {
	>.dropdown-menu {
		right: 0;
		left: auto;
	}
}
.navbar-fixed-bottom {
	.dropdown {
		.caret {
			border-top: 0;
			border-bottom: 4px dashed;
			border-bottom: 4px solid \9;
			content: "";
		}
		.dropdown-menu {
			top: auto;
			bottom: 100%;
			margin-bottom: 2px;
		}
	}
}

.dropdown.open {
	.dropdown-menu {
		border-top-right-radius: 0;
		border-top-left-radius: 0;
	}
}
.dropdown.custom-dropdown {
	.dropdown-menu {
		width: 100%;
		border: 0;
		margin-top: -1px;
		background-color: #0b3a4f;
		li {
			border: 1px solid rgba(20, 104, 142, 0.5);
			border-bottom-width: 0;
			font-size: 12px;
			text-align: center;
			&:last-of-type {
				border-bottom-width: 1px;
			}
			&:first-of-type {
				border-top-right-radius: 0;
				border-top-left-radius: 0;
			}
			a {
				padding-top: 10px;
				padding-bottom: 10px;
				color: rgba(254, 254, 254, 0.66);
				font-weight: 500;
				&:hover {
					background-color: rgba(0, 134, 197, 0.2);
					color: #fefefe;
					text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.4);
				}
				&:focus {
					background-color: rgba(0, 134, 197, 0.2);
					color: #fefefe;
					text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.4);
				}
				&:active {
					background-color: rgba(0, 134, 197, 0.2);
					color: #fefefe;
					text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.4);
				}
			}
			a.active {
				&:hover {
					background-color: rgba(0, 134, 197, 0.2);
					color: #fefefe;
					text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.4);
				}
				&:focus {
					background-color: rgba(0, 134, 197, 0.2);
					color: #fefefe;
					text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.4);
				}
				&:active {
					background-color: rgba(0, 134, 197, 0.2);
					color: #fefefe;
					text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.4);
				}
			}
		}
	}
}

@media (min-width: 768px) {
	.navbar-right {
		.dropdown-menu {
			right: 0;
			//left: auto;
		}
		.dropdown-menu-left {
			left: 0;
			right: auto;
		}
	}
}
