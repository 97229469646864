.kommune-statement-fragment {
    .w-100 {
        width: 100%;
    }
    button a {
        color: #454545;
    }
    .report-user {
        display: table;
        position: absolute;
        right: 20px;
        top: 15px;
        margin-bottom: 20px;
        text-align: right;
        line-height: 2;
        color: #c7c7c7;
        text-decoration: none !important;
        font-weight: 500;
    }
    .user-card {
        .profile-big {
            img {
                width: 90px;
                height: 90px;
                overflow: hidden;
                border-radius: 50%;
            }
        }
    }
    .team-name {
        display: block;
        min-height: 60px;
        padding-top: 5px;
        font-size: 14px;
        font-weight: 300;
    }
    .team-name-small {
        font-size: 12px;
    }
    .user-card-text {
        max-height: 130px;
        display: inline-block;
        font-size: 14px;
        font-weight: lighter;
        line-height: 22px;
    }
    &.tc-statement {
        min-width: 300px;
        max-width: 314px;
        margin-bottom: 20px;

        .col-md-12 {
            border-radius: 8px;
        }
    }
    .sr-readmore {
        font-size: 14px;
        line-height: 17px;
        font-weight: 500;
        display: block;
        margin-top: 10px;
    }
    .arrow-icon {
        font-size: 25px;
        position: absolute;
        margin-top: -2px;
    }
}

/* tablet */
@media (min-width: 768px) and (max-width: 992px) {
    .kommune-statement-fragment {
        &.tc-statement {
            max-width: 330px;
        }
    }
}

@media (max-width: 767px) {
    .kommune-statement-fragment {
        .tc-statements-items {
            margin-left: -15px;
            margin-right: -15px;
        }
        &.tc-statement {
            min-width: 100%;
        }
    }
}
