.form-control-searchbox {
	padding: 15px 35px;
	border-radius: 26px;
	font-size: 17px;
	height: 52px;
	border: 1px solid rgba(123, 163, 38, 0.16);
}
@media (max-width: 767px) {
	.form-control-searchbox {
		padding: 12px 30px 12px 30px;
		border-radius: 40px;
		font-size: 14px;
		height: 100%;
	}
}
