table {
	background-color: transparent;
	col[class*="col-"] {
		position: static;
		float: none;
		display: table-column;
	}
	td[class*="col-"] {
		position: static;
		float: none;
		display: table-cell;
	}
	th[class*="col-"] {
		position: static;
		float: none;
		display: table-cell;
	}
}
caption {
	padding-top: 10px;
	padding-bottom: 10px;
	color: #898989;
	text-align: left;
}
th {
	text-align: left;
}
.table {
	width: 100%;
	max-width: 100%;
	margin-bottom: 20px;
	>thead {
		>tr {
			>th {
				padding: 10px 18px 10px 18px;
				line-height: 1.428571429;
				vertical-align: top;
				border-top: 1px solid #052836;
				vertical-align: bottom;
				border-bottom: 2px solid #052836;
			}
			>td {
				padding: 10px 18px 10px 18px;
				line-height: 1.428571429;
				vertical-align: top;
				border-top: 1px solid #052836;
			}
		}
		&:first-child {
			>tr {
				&:first-child {
					>th {
						border-top: 0;
					}
					>td {
						border-top: 0;
					}
				}
			}
		}
	}
	>tbody {
		>tr {
			>th {
				padding: 10px 18px 10px 18px;
				line-height: 1.428571429;
				vertical-align: top;
				border-top: 1px solid #052836;
			}
			>td {
				padding: 10px 18px 10px 18px;
				line-height: 1.428571429;
				vertical-align: top;
				border-top: 1px solid #052836;
			}
		}
		+ {
			tbody {
				border-top: 2px solid #052836;
			}
		}
	}
	>tfoot {
		>tr {
			>th {
				padding: 10px 18px 10px 18px;
				line-height: 1.428571429;
				vertical-align: top;
				border-top: 1px solid #052836;
			}
			>td {
				padding: 10px 18px 10px 18px;
				line-height: 1.428571429;
				vertical-align: top;
				border-top: 1px solid #052836;
			}
		}
	}
	>caption {
		+ {
			thead {
				>tr {
					&:first-child {
						>th {
							border-top: 0;
						}
						>td {
							border-top: 0;
						}
					}
				}
			}
		}
	}
	>colgroup {
		+ {
			thead {
				>tr {
					&:first-child {
						>th {
							border-top: 0;
						}
						>td {
							border-top: 0;
						}
					}
				}
			}
		}
	}
	.table {
		background-color: #fff;
	}
}
.table-condensed {
	>thead {
		>tr {
			>th {
				padding: 5px;
			}
			>td {
				padding: 5px;
			}
		}
	}
	>tbody {
		>tr {
			>th {
				padding: 5px;
			}
			>td {
				padding: 5px;
			}
		}
	}
	>tfoot {
		>tr {
			>th {
				padding: 5px;
			}
			>td {
				padding: 5px;
			}
		}
	}
}
.table-bordered {
	border: 1px solid #021B26;
	>thead {
		>tr {
			>th {
				border: 1px solid #021B26;
				border-bottom-width: 2px;
			}
			>td {
				border: 1px solid #021B26;
				border-bottom-width: 2px;
			}
		}
	}
	>tbody {
		>tr {
			>th {
				border: 1px solid #021B26;
			}
			>td {
				border: 1px solid #021B26;
			}
		}
	}
	>tfoot {
		>tr {
			>th {
				border: 1px solid #021B26;
			}
			>td {
				border: 1px solid #021B26;
			}
		}
	}
}
.table-striped {
	>tbody {
		>tr {
			&:nth-of-type(odd) {
				background-color: #f9f9f9;
			}
		}
	}
}
.table-hover {
	>tbody {
		>tr {
			&:hover {
				background-color: white;
			}
		}
	}
}
.table-responsive {
	overflow-x: auto;
	min-height: 0.01%;
}
@media screen and (max-width: 767px) {
	.table-responsive {
		width: 100%;
		margin-bottom: 15px;
		overflow-y: hidden;
		-ms-overflow-style: -ms-autohiding-scrollbar;
		border: 1px solid #021B26;
		>.table {
			margin-bottom: 0;
			>thead {
				>tr {
					>th {
						white-space: nowrap;
					}
					>td {
						white-space: nowrap;
					}
				}
			}
			>tbody {
				>tr {
					>th {
						white-space: nowrap;
					}
					>td {
						white-space: nowrap;
					}
				}
			}
			>tfoot {
				>tr {
					>th {
						white-space: nowrap;
					}
					>td {
						white-space: nowrap;
					}
				}
			}
		}
		>.table-bordered {
			border: 0;
			>thead {
				>tr {
					>th {
						&:first-child {
							border-left: 0;
						}
						&:last-child {
							border-right: 0;
						}
					}
					>td {
						&:first-child {
							border-left: 0;
						}
						&:last-child {
							border-right: 0;
						}
					}
				}
			}
			>tbody {
				>tr {
					>th {
						&:first-child {
							border-left: 0;
						}
						&:last-child {
							border-right: 0;
						}
					}
					>td {
						&:first-child {
							border-left: 0;
						}
						&:last-child {
							border-right: 0;
						}
					}
					&:last-child {
						>th {
							border-bottom: 0;
						}
						>td {
							border-bottom: 0;
						}
					}
				}
			}
			>tfoot {
				>tr {
					>th {
						&:first-child {
							border-left: 0;
						}
						&:last-child {
							border-right: 0;
						}
					}
					>td {
						&:first-child {
							border-left: 0;
						}
						&:last-child {
							border-right: 0;
						}
					}
					&:last-child {
						>th {
							border-bottom: 0;
						}
						>td {
							border-bottom: 0;
						}
					}
				}
			}
		}
	}
}
