.chat {
	height: 236px;
	width: 100%;
	padding: 15px;
	overflow: hidden;
	ul {
		list-style: none;
		margin: 0;
		box-sizing: content-box;
		height: 100%;
		width: 100%;
		overflow-y: auto;
		padding: 0 32px 0 0;
		li {
			position: relative;
			padding-bottom: 10px;
			margin: 30px 0;
			* {
				font-size: 12px;
			}
			&:last-of-type {
				margin-bottom: 0;
			}
			div {
				&::before {
					content: "";
					position: absolute;
					right: 0;
				}
				&::after {
					content: "";
					position: absolute;
					right: 0;
				}
			}
			.user {
				position: absolute;
				bottom: 12px;
				color: #343434;
				img {
					height: 40px;
					width: 40px;
					position: relative;
					z-index: 2;
					border-radius: 50%;
					background-color: #f8f8f8;
				}
			}
			.date {
				margin: -20px 0 10px;
				text-align: center;
				color: #fefefe;
				opacity: 0.5;
			}
			.message {
				position: relative;
				padding: 10px;
				margin: 0 50px 0 0;
				color: white;
				background-color: #084760;
				border-radius: 14px;
				p {
					margin: 0;
					padding: 2px 5px 0;
					transition: all 0.1s;
				}
				&::before {
					z-index: 1;
					right: 0;
					height: 30px;
					width: 30px;
					margin: -10px -30px 0 0;
					border-radius: 14px;
					background-color: #073B50;
					display: block;
				}
				&::after {
					position: absolute;
					right: auto;
					bottom: 0;
					left: 0;
					margin-left: -15px;
					border: 18px solid transparent;
					border-bottom-color: #084760;
				}
			}
		}
		li.you {
			.user {
				position: absolute;
				top: 30px;
				right: 0;
			}
			.chat-icons {
				position: absolute;
				bottom: 10px;
				font-size: 10px;
				color: #809ea6;
				right: 0;
			}
			.message {
				margin-left: 80px;
				color: white;
				min-height: 80px;
				background-color: #0086C5;
				&::before {
					z-index: 1;
					right: 0;
					height: 30px;
					width: 30px;
					margin: -10px -30px 0 0;
					border-radius: 14px;
					border-top-right-radius: 0;
					background-color: #073B50;
					display: block;
				}
				&::after {
					position: absolute;
					top: 0;
					right: 0;
					margin: 0 -15px 0 110px;
					border: 15px solid transparent;
					border-top-color: #0086C5;
				}
			}
		}
		li.other {
			.message {
				margin-left: 50px;
				&::before {
					position: absolute;
					bottom: 0;
					left: 0;
					margin: -10px 0 0 -30px;
				}
			}
		}
	}
}
.chat-wrapper {
	background: #073B50;
	border-radius: 7px;
	form {
		padding: 12px;
		border-bottom-right-radius: 7px;
		border-bottom-left-radius: 7px;
		background: #084760;
		input.send-message {
			height: 36px;
			border: 0;
			border-top-left-radius: 7px;
			border-bottom-left-radius: 7px;
			color: #454545;
			background-color: #fff;
			font-size: 12px;
			font-weight: 300;
			&.form-control {
				padding: 9px 18px;
				color: #454545;
			}
		}
		button[type=submit] {
			height: 36px;
			padding: 6px 12px;
			border-top-right-radius: 7px;
			border-bottom-right-radius: 7px;
			border-color: #8CB82B;
			line-height: 1;
			font-size: 18px;
		}
	}
}
.chat-oval {
	width: 30px;
	height: 30px;
	margin: -8px auto 0px 5px;
	border-radius: 15px;
	border: 5px solid #0086C5;
	background-color: #0086C5;
	position: absolute;
	text-align: center;
}
.chat-oval-txt {
	height: 23px;
	width: 15px;
	color: #FFFFFF;
	font-family: Roboto;
	font-size: 15px;
	font-weight: 500;
	letter-spacing: 0.6px;
	line-height: 23px;
}
.chat-oval-sm {
	width: 16px;
	height: 16px;
	margin: -9px auto 0px -10px;
	border-radius: 8px;
	background-color: #3C99C5;
	position: absolute;
	text-align: center;
}
.chat-oval-txt-sm {
	top: 3px;
	left: 6px;
	color: #FFFFFF;
	font-family: Roboto;
	font-size: 8px;
	font-weight: 500;
	position: absolute;
	letter-spacing: 0.56px;
}
.chat-message-icon {
	display: inline-block;
	float: left;
	width: 10%;
	.chat-oval-sm {
		margin: -8px auto 0px 12px;
	}
}
.chat-message-txt {
	float: right;
	display: inline-block;
	width: 80%;
	padding-left: 15px;
}
