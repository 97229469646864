.label-default[href] {
	&:hover {
		background-color: #707070;
	}
	&:focus {
		background-color: #707070;
	}
}
.label-primary[href] {
	&:hover {
		background-color: #1e507d;
	}
	&:focus {
		background-color: #1e507d;
	}
}
.label-success[href] {
	&:hover {
		background-color: #6d8f21;
	}
	&:focus {
		background-color: #6d8f21;
	}
}
.label-info[href] {
	&:hover {
		background-color: #006392;
	}
	&:focus {
		background-color: #006392;
	}
}
.label-warning[href] {
	&:hover {
		background-color: #ec971f;
	}
	&:focus {
		background-color: #ec971f;
	}
}
.label-danger[href] {
	&:hover {
		background-color: #c9302c;
	}
	&:focus {
		background-color: #c9302c;
	}
}
