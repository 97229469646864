/* Registrierung Kommunen
cities/signup */

.kommune-registrieren {
    // banner prev year
    .commune-registered {
        background-color: #ECECEC;
        color: $color-default;
        padding: 30px 50px 36px 30px;
        display:flex;
        flex-direction: row;
        flex-wrap: nowrap;
        border-radius: 7px;
        #sr_prevyear_feedback_text {
            width: 60%;
            p {
                font-weight: 300;
                font-size: 14px;
                line-height: 22px;
                margin: 0;
            }
        }
        #sr_prevyear_feedback_mail {
            display: flex;
            justify-content: flex-end;
            width: 40%;
            #sr_kommunenregistrierung_get_prevyear {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 70%;
                input {
                    background-color: transparent;
                    border:0;
                    border-bottom: 1px solid $color-gray;
                    height:30px;
                    margin-right: 30px;
                    width:100%;
                    color: $color-gray;
                    &::placeholder {
                        color: $color-gray;
                    }
                }
                button {
                    background-color: transparent;
                    color: $color-default;
                    border: 1px solid $color-default;
                    border-radius: 30px;
                    font-size: 14px;
                    padding-top: 9px;
                    padding-bottom: 9px;
                    width: 100%;
                    background-image: none;
                    height:auto;
                    text-shadow: none;
                    box-shadow: none;
                }
            }
        }
    }
    .wizard {
        margin: 0 auto 10px;
        display: flex;
        justify-content: center;
        .nav-tabs {
            position: relative;
            margin: 0 auto 25px;
            border-bottom: 0;
            width: 790px;
            display: flex;
            justify-content: center;
            >li.active {
                >a {
                    color: #fff;
                    cursor: default;
                    border: 0;
                    border-bottom-color: transparent;
                    &:hover {
                        color: #fff;
                        cursor: default;
                        border: 0;
                        border-bottom-color: transparent;
                    }
                    &:focus {
                        color: #fff;
                        cursor: default;
                        border: 0;
                        border-bottom-color: transparent;
                    }
                }
            }
            >li {
                width: 24%;
                height: 100px;
                margin-top: 25px;
                text-align: center;
                background-color: transparent;
                color: #454545;
                .step-name {
                    display: inline-flex;
                    justify-content: center;
                    opacity: 0.5;
                }
                &.active {
                    .step-name {
                        opacity: 1;
                    }
                }
                >a {
                    border: 0;
                    pointer-events: none;
                    cursor: default;
                }
                a {
                    width: 40px;
                    height: 40px;
                    margin: 20px auto 14px;
                    border-radius: 100%;
                    padding: 0;
                    color: #fff;
                    z-index: 2;
                    &:hover {
                        background: transparent;
                    }
                }
            }
        }
        >div.wizard-inner {
            position: relative;
            width: 100%;
        }
        li {
            a.disabled {
                span.round-tab {
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    display: inline-block;
                    border-radius: 100px;
                    background: #fbfbfb;
                    border: 1px solid $color-green;
                    color: $color-green;
                    z-index: 2;
                    position: absolute;
                    left: 0;
                    text-align: center;
                    font-size: 15px;
                }
            }
        }
        .tab-pane {
            position: relative;
            padding-top: 50px;
        }
    }
    .connecting-line {
        height: 1px;
        background: $color-green;
        position: absolute;
        width: 375px;
        margin: 0 auto;
        left: 0;
        right: 0;
        top: 65px;
        z-index: 1;
    }
    span.round-tab {
        width: 40px;
        height: 40px;
        line-height: 40px;
        display: inline-block;
        border-radius: 100px;
        background: $color-green;
        border: 1px solid $color-green;
        text-align: center;
        font-size: 15px;
        i {
            color: #5bc0de;
        }
    }
    span.missing-field-info {
        background-color: $color-warning;
        color: #fff;
        font-family: "Roboto", sans-serif;
        font-weight: 500;
        border-radius: 50%;
        font-size: 20px;
        line-height: 24px;
        width: 24px;
        height: 24px;
        display: inline-flex;
        justify-content: center;
    }
    .sr-creg-step-city {
        .missing-field-info {
            position: absolute;
            left: -84px;
            bottom: -36px;
        }
        &#sr-creg-step-overview {
            .missing-field-info {
                left: -62px;
            }
        }
    }

    #sr_kommunenregistrierung_main,
    #sr_kommunenregistrierung_sub_cities,
    #sr_kommunenregistrierung_merchandise,
    #sr_kommunenregistrierung_legal {
        td,
        span,
        p {
            a {
                font-weight:500;
                /* external link icon */
                &::after {
                    font-family:'sr-icons', 'FontAwesome';
                    content: " \e935";
                    font-size:9px;
                }
            }
        }
    }

    /* Special class for hiding elements without setting display:none for functionality reasons */
    .sr-invisible, .sr-invisible * {
        visibility: hidden;
        height: 0 !important;
        font-size: 0.1px !important;
        line-height: 0.1px !important;
        padding: 0 !important;
        margin: 0 !important;
        border: 0 !important;
        min-height: 0 !important;
    }

    .sr-invisible i, .sr-invisible h1, .sr-invisible h2, .sr-invisible h3, .sr-invisible h4 {
        display: none;
    }

    label {
        font-weight: normal;
    }
    .sr_creg_half_fade {
        opacity: 0.35;
    }

    section {
        background-color: #FBFBFB;
    }

    .csc-default .bodytext {
        margin-bottom: 6px;
    }
    .required-text-info {
        color: $color-default;
        font-size: 14px;
        line-height: 22px;
        float: right;
        opacity: 0.5;
    }

    .commune-data {
        width:65%;
        &.font-weight-normal {
            font-weight: normal;
        }
        &.font-weight-light {
            font-weight: lighter;
        }
        h4 {
            margin-top:0;
            font-size: 16px;
        }
        select {
            background-color: transparent;
            border:0;
            border-bottom: 1px solid rgba(151,151,151, 0.8);
            line-height: 25px;
            width:100%;
            box-shadow: none;
        }
        td:first-child {
            min-width:200px;
            /*width: 200px;*/
            color: rgba(69,69,69,0.8);
        }
        tr.promotion-color td {
            color: $color-green;
        }
        td:first-child.mw-60 {
            min-width: 60%;
        }
        input[type=text] {
            width:70%;
            background-color: transparent;
            border:0;
            border-bottom: 1px solid rgba(151,151,151, 0.8);
            line-height: 25px;
            font-size:14px;
            &::placeholder {
                opacity: 0.5;
                color:$color-default;
            }
        }
        input {
            &.input-small {
                width: 50px;
            }
        }
        button {
            background-color: transparent;
            color: $color-default;
            border: 1px solid $color-default;
            border-radius: 22.5px;
            font-size: 14px;
            padding: 9px 20px;
            width: auto;
            background-image: none;
            height: auto;
            text-shadow: none;
            box-shadow: none;
            font-weight: normal;
            min-width: 230px;

            &.save {
                background-color: $color-green;
                border: none;
                color: white;
            }
            &.delete {
                background-color: #E30613;
                border: none;
                color: white;
            }
        }
        input[type="checkbox"],
        input[type="radio"] {
            display: none;
        }

        input[type="checkbox"] + label,
        input[type="checkbox"] + span {
            font-size: 14px;
        }

    input[type="checkbox"] + label:before,
    input[type="checkbox"] + span:before {
            width: 16px;
            height: 16px;
            background-color: #FFFFFF;
            display: table;
            content:"";
            border-radius: 2px;
            border: 1.5px solid rgba(69,69,69, 0.38);
            font-size:13px;
            line-height: 0.8;
            border-collapse:separate;
        }

        input[type="checkbox"]:checked + label:before,
        input[type="checkbox"]:checked + span:before {
            font-family: 'sr-icons', 'FontAwesome';
            font-size:13px;
            background-color: $color-green;
            content: "\f00c";
            color: #FFFFFF;
            width: 16px;
            height: 16px;
            border: 1.5px solid $color-green;
            display: table;
            line-height: 0.8;
        }

        input[type="radio"] + label:before {
            width:16px;
            height:16px;
            background-color: #FFFFFF;
            display: block;
            content:"";
            border-radius: 10px;
            border: 1.5px solid #b7b7b7;
        }

        input[type="radio"]:checked + label:before {
            background-color: $color-green;
            box-shadow: inset 0px 0px 0px 2px #FFFFFF;
        }

        label {
            vertical-align: middle;
        }

        td, label {
            font-size: 14px;
            line-height: 22px;
            letter-spacing: 0;
            font-weight: 300;
        }

        td.font-weight-normal {
            font-weight: normal;
        }

        td.font-weight-light {
            font-weight: lighter;
        }

        .check label {
            margin: 4px 0 8px 0;
        }

        td.mw-23 {
            min-width: 230px;
            max-width:250px;
        }
        td.mw-25 {
            min-width: 250px;
        }
        td.mw-26 {
            min-width: 260px;
        }
        td.mw-29 {
            min-width: 290px;
        }
        td.minimum-width {
            min-width: 400px;
        }
    }

    .chosen-container.chosen-container-single {
        background-color: transparent;
        border:0;
        border-bottom: 1px solid rgba(151,151,151, 0.8);
        line-height: 25px;
        width:100%;
    }

    #sr_creg_ags_search_main_chosen, #sr_creg_ags_search_main_1, #sr_creg_ags_search_main {
        width: 100% !important;
    }

    #sr_creg_ags_search_main_chosen .chosen-single{
        background:none;
        box-shadow: none;
        border: none;
        width:100%;
    }

    #sr_creg_ags_sub_commune_chosen {
        background:none;
        box-shadow: none;
        border: none;
        width:100%;
    }

    #sr_creg_coordination_section td:first-child {
        width: 200px;
    }

    span.sr_form_feedback, .rk-compat-2017 span.sr_form_feedback  {
        padding-left:0px;
    }

    span.sr_form_feedback {
        padding-left: 0px;
        font-size: 11px;
        font-weight: 400;
    }

    #sr_creg_start_date::placeholder {
        color:$color-default;
        opacity:1;
    }

    .sr-input-date {
        width:45%;
        background-color: transparent;
        border:0;
        border-bottom: 1px solid rgba(151,151,151, 0.8);
        padding-right:10%;
        line-height: 25px;
    }

    #sr_creg_radar_start_date {
        width:40%;
        background-color: transparent;
        border:0;
        border-bottom: 1px solid rgba(151,151,151, 0.8);
        padding-right:10%;
        line-height: 25px;
    }

    #sr_creg_radar_end_date {
        width:40%;
        background-color: transparent;
        border:0;
        border-bottom: 1px solid rgba(151,151,151, 0.8);
        float:right;
        line-height: 25px;
    }

    #sr_creg_radar_date_to {
        display: inline-block;
        width: 20%;
        line-height: 25px;
        text-align: center;
    }

    #sr_creg_country, #sr_creg_fstate, #sr_creg_citytype_preset,
    #sr_creg_ags_search_main_chosen,
    #sr_creg_ags_search_main,
    #sr_creg_ags_search_main_1,
    #sr_creg_contact_salutation,
    #sr_creg_contact_title,
    #sr_creg_ownDescription_article,
    #sr_creg_coordination_title,
    #sr_creg_coordination_salutation,
    #sr_creg_invoice_title,
    #sr_creg_radar_salutation,
    #sr_creg_radar_title {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        position: relative;
        z-index: 10;
    }

    .sr_creg_select_field {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        position: relative;
        z-index: 10;
    }

    .icon {
        font-family: 'sr-icons', 'FontAwesome';
        font-size: 20px;
    }

    .table-checkbox {
        td.check {
            width: 30px;
            max-width: 30px;
            min-width: 30px;
        }
        span.sr_form_feedback {
            display: block;
            position: relative;
            top: -5px;
        }
    }

    .team-tag-min-width {
        min-width: 180px;
    }

    .table-checkbox td {
        padding-top:0px;
        padding-bottom:10px;
    }

    .table-checkbox label {
        font-size: 14px!important;
        line-height: 22px!important;
    }

    #sr_creg_send_flyers_extra, #sr_creg_send_posters_extra {
        width: 35%;
    }

    .button-submit, .button_big_c {
        float:right;
        background-color: $color-green;
        color: #FFFFFF;
        border: 1px solid transparent;
        border-radius: 22.5px;
        font-size: 14px;
        padding: 9px 30px 11px 30px;
    }

    a.button-submit, a.button-submit:hover {
        color:#fff;
    }

    .shopping-basket {
        width:304px;
        min-height:304px;
        max-height:60vh;
        border:1px solid $color-green;
        border-radius: 4px;
    }

    .shopping-header h4 {
        color:#4A4A4A;
        font-size: 15px;
    }

    .shopping-content {
        min-height:210px;
        max-height:50vh;
        overflow: hidden;
        overflow-y: auto;
        padding-right: 15px;
    }

    table#merchandise {
        border-collapse: separate;
        border-spacing: 0px 20px;
    }

    .shopping-content #attendence-overview .price-label, .shopping-content #merchandise-overview .price-label, .shopping-content #radar-overview .price-label {
        color: #A3A0A0;
    }

    #merchandise-overview, #radar-overview {
        margin-top:10px;
    }

    #attendence-overview td, #merchandise-overview td, #radar-overview td {
        margin-top:5px;
    }

    .select2.select2-container.select2-container--default {
        width:70%!important;
        max-width:70%;
    }

    #sr_kommunenregistrierung_sub_communes a::after {
        content:"";
    }

    .shopping-basket-fade {
        width:93%;
        height:110px;
        position:absolute;
        bottom: 1px;
        background:linear-gradient(rgba(255,255,255,0) 0px, white 40px);
        border-radius: 0 0 10px 10px;
    }

    .shopping-total {
        position: absolute;
        bottom: 5px;
        margin-left:1px;
        width: 100%;
    }

    .shopping-basket table {
        width:90%;
        margin-left: 22px;
        margin-right: 22px;
    }

    #total-overview {
        position: relative;
    }

    #total-overview .price-label {
        width: 160px;
    }

    #total-price {
        font-weight: bold;
        font-size: 16px;
        float:right;
        margin-right: 20px;
    }

    .shopping-item {
        display:none;
    }

    .commune-pricing {
        float:right;
        max-width: 305px;
        min-width: 305px;
        font-size: 12px;
        position: absolute;
        right:45px;
        top: 62px;
        z-index: 10;
        background-color: #fff;
    }

    .fixed-top {
        position: absolute;
        right: 45px;
    }

    .fixed-bottom {
        position: absolute;
        bottom: 140px;
        right: 45px;
    }

    .attend-label {
        display: none;
    }

    .price-label {
        color: $color-default;
        font-size: 12px;
        width: 195px;
        opacity:0.6;
    }

    .price-list {
        float:right;
        font-size: 12px;
        font-weight: bold;
        color: $color-default;
        padding-right:1px;
    }

    #shopping-list h4 {
        margin-top:20px;
    }

    #shopping-list-info {
        display:none;
    }

    .merch-label {
        display:none;
    }

    .radar-label {
        display:none;
    }

    .attendence-green {
        color: $color-green;
    }

    .attendence-orange {
        color: #F5A623;
    }

    #attendence-info-price {
        float:right;
        margin-right: 3px;
    }

    .select-arrow::after {
        border: solid #757575;
        border-width: 5px 4px 0 4px;
        border-color: #757575 transparent;
        display: inline-block;
        position:relative;
        top:-23px;
        left:94%;
        content:"";
        z-index: 1;
    }

    .select-arrow-pl::after {
        border: solid #757575;
        border-width: 5px 4px 0 4px;
        border-color: #757575 transparent;
        display: inline-block;
        position:relative;
        top:-23px;
        left:95%;
        content:"";
        z-index: 1;
    }

    select:invalid {
        color: $color-default;
        opacity: .5;
    }

    .space-icon {
        margin-left:95px;
    }

    .fs-12 {
        font-size: 12px;
        line-height:17px;
    }

    .info-icon:before {

        font-size: 24px;
        line-height:20px;

    }

    .sr-creg-icon-style-big {
        color: $color-default;
        font-size: 24px;
        opacity:0.38;
    }

    .form-only-for-administration {
        color:$color-default;
        opacity:0.6;
    }

    .table-info-administration {
        margin-bottom:16px;
    }

    .table-info-text {
        font-size: 14px;
        line-height: 18px;
        color: rgba(69,69,69,0.6);
        max-width: 580px;
    }

    .table-agb {
        font-size: 12px;
    }
    .table-agb td.check {
        vertical-align:top;
        padding-top:5px;
    }

    .sr-creg-icon-style-small {
        color: #b6b6b6;
        font-size: 20px;
        position: relative;
        top:3px;
    }

    .double-line {
        line-height:40px;
    }

    .space-right {
        margin-right: 8px;
    }

    .space-left {
        margin-left: 8px;
    }

    .container-main,
    .sr_kommunenregistrierung_sub {
        border: 1px solid #F5F5F5;
        background-color: #FFFFFF;
        padding: 24px 45px 45px 24px;
        clear: both;
        color: $color-default;
    }

    .save-button {
        display: flex;
        justify-content: space-between;
        a.buttons {
            background-color: transparent;
            color: $color-default;
            border: 1px solid $color-default;
            font-size: 14px;
            padding-top:9px;
            padding-bottom:9px;
            border-radius: 22.5px;
            display:inline-block;
            width: 215px;
            background-color: #fff;
        }
    }

    #article, #own_description, .own_description {
        display: none;
    }

    #sr-creg-kommunenregistrierung-finish .modal-header {
        padding: 5px 24px 4px;
    }

    #sr-creg-kommunenregistrierung-finish .modal-body {
        padding: 18px 24px 24px 24px;
    }
    #sr-creg-kommunenregistrierung-finish .modal-dialog {
        width:538px;
        margin:290px auto;
    }

    #sr-creg-final-abort {
        float:left;
        background-color: transparent;
        color: $color-default;
        border: 1px solid $color-default;
        border-radius: 22.5px;
        font-size: 14px;
        padding: 9px 30px 11px 30px;
    }

    #sr-creg-final-finish {
        float:right;
        padding-top: 9px;
        padding-bottom: 11px;
    }

    .final-buttons {
        .btn {
            width: 215px;
        }
    }

    .select2-results {
        margin-top: -3px;
    }

    .textarea-extra-note {
        border-radius: 7px;
        border-color: rgba(151,151,151, 0.8);
        border-style:solid;
        border-width:1.5px;
    }

    .form-control {
        height:30px;
    }

    .pt-80 {
        padding-top:80px;
    }

    .success-icon:before {
        font-family: 'sr-icons', 'FontAwesome';
        content: '\f058';
        color: $color-green;
        font-size:40px;
        margin-bottom: 0;
    }

    .success-icon {
        margin-bottom: 5px;
    }

    #sr_creg_start_date_arrow {
        position: relative;
        float: right;
        margin-right: 125px;
        bottom: 12px;
    }

    #sr_creg_start_date_arrow b {
        border-color: rgba(117,117,117,1) transparent transparent transparent;
        border-width: 7px 5px 0 5px;
        border-style: solid;
    }

    .pos-rel {
        position: relative;
    }

    .pos-rel label {
        position: absolute;
        top: 0px;
    }

    .select2-search.select2-search--dropdown::after {
        position:absolute;
        font-family: sr-icons;
        content: "\e947" !important;
        right: 25px;
        top: 6px;
        height: 10px;
        width: 10px;
        border: none;
        background: none;
        text-align: center;
        line-height: 32px;
        font-size:20px;
        color: #ACADAB;
        font-size: 18px;
    }

    .confirm-message {
        width: 100%;
        border-radius: 4px;
        color: $color-default;
        display: flex;
        justify-content: left;
        background-color: #fbfbfb;
        flex-direction: column;
        margin-top: -35px;
        .img-confirm-message {
            margin-left: -15px;
            margin-right: -15px;
            img {
                width: 100%;
                height: auto;
            }
        }
        .text-confirm-message {
            margin-top: 26px;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            margin-left: 36px;
            margin-right: 36px;
        }
    }

    .sr_contract_open {
        cursor: pointer;
    }

    // add plus icon to accordion of new subcity
    #subcity-new-content-wrapper {
        .accordion-template {
            .akkordeon-topics {
                margin-top: 20px;
                .akkordeon-topic {
                    .akkordeon-topic-header {
                        a {
                            &::after {
                                font-family: Roboto, Open Sans, Segoe UI, sans-serif;
                                content: "+";
                                font-size: 28px;
                                display: inline;
                                text-align: center;
                                line-height: 20px;
                                font-weight: 300;
                                padding: 1px 4px;
                            }
                            &:not(.collapsed)::after {
                                font-family: "sr-icons", "FontAwesome";
                                content: "\e5c8";
                                font-size: 20px;
                                line-height: 20px;
                                padding: 1px;
                            }
                        }
                    }
                }
            }
        }
    }

    // part of styling for subcities
    .accordion-template {
        .missing-field-info {
            position: absolute;
            left: 70px;
            margin-top: -2px;
        }
        .akkordeon-topics {
            margin-top: 0;
            margin-bottom: 0;
            &:first-child {
                margin-top: 20px;
            }
            .akkordeon-topic {
                padding-top: unset;
                margin-top: unset;
                .akkordeon-topic-header {
                    .akkordeon-topic-link {
                        min-height: 76px;
                    }
                }
                .akkordeon-topic-body {
                    .akkordeon-content {
                        margin: unset;
                    }
                }
            }
        }
    }

    #step-city-overview {
        .order-confirmation-merch-list-wrapper {
            .order-confirmation-merch-item-wrapper {
                display: flex;
            }
        }
    }
    .mw-60 {
        min-width: 60%;
    }
    #order-confirmation {
        font-size: 14px;
        line-height: 22px;
        color: $color-default;
    }
    .order-confirmation-titel {
        font-size: 16px;
        line-height: 20px;
    }
    .order-confirmation-info-text {
        font-size: 14px;
        line-height: 18px;
    }
    .font-weight-normal {
        font-weight: normal;
    }
    .font-weight-light {
        font-weight: lighter;
    }
    .promotion-color {
        color: $color-green;
    }
    .order-confirmation-prices {
        border-top: 1px solid #aaa;
    }
    .order-confirmation-spacer {
        height: 50px;
    }
    #order-condirmation-gross-price {
        font-size: 18px;
    }
    #sr-error-message-text {
        display: block;
    }
    .spinner-overlap {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        background-color: rgba(0,0,0,0.4);
        z-index: 5000;
        .content-spinner {
            height: 100%;
            img {
                animation: rotation 1s linear 0s infinite;
            }
        }
    }

    #waitModal {
        .content-spinner {
            z-index: 5000;
            width: 200px;
            margin: 0 auto;
            padding: 20px;
            img {
                margin-top: 25%;
                -webkit-animation: rotation 1s linear 0s infinite;
                -moz-animation: rotation 1s linear 0s infinite;
                -o-animation: rotation 1s linear 0s infinite;
                animation: rotation 1s linear 0s infinite;
            }
        }
    }
}

/* tablet */
@media (min-width: $breakpoint-tablet-min) and (max-width: $breakpoint-tablet-max) {
    .confirm-message {
        margin-top: 6px;
    }

    .img-confirm-message {
        padding-left: 24px;
        padding-right: 24px;
    }

    .img-confirm-message img {
        border-radius: 10px;
    }

    .text-confirm-message {
        margin-left: 160px;
        margin-right: 160px;
    }
}

/* mobile */
@media (max-width: $breakpoint-mobile) {

}
