/* image lightbox styling */

.stadtradeln-lightbox {
    &.modal {
        display: none;
        position: fixed;
        z-index: 1;
        padding-top: 205px;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgba(0,0,0,0.73);
    }
    .modal-content {
        position: relative;
        background-color: transparent;
        margin: auto;
        padding: 0;
        width: 45%;
        max-width: 800px;
        box-shadow: none;
        border: 0;
    }
    .close {
        color: #fff;
        position: absolute;
        top: 150px;
        right: 25px;
        font-size: 34px;
        font-weight: bold;
        opacity: 1;
        text-shadow: none;
        &:hover,
        &:focus {
            text-decoration: none;
            cursor: pointer;
            opacity: .9;
        }
    }
    .prev,
    .next {
        cursor: pointer;
        position: absolute;
        top: 50%;
        width: auto;
        padding: 16px;
        margin-top: -50px;
        transition: 0.6s ease;
        user-select: none;
        -webkit-user-select: none;
        opacity: 0.6;
    }
    .prev:hover,
    .next:hover {
        opacity: 1;
    }
    .prev {
        left: -23px;
    }
    .next {
      right: -23px;
    }
    .caption-container {
        color: white;
        h2 {
            text-align: center;
        }
    }
    .image-wrapper {
        margin: 0 54px;
        display: flex;
    }
    .social-icons {
        position: absolute;
        top: 24px;
        // 18px + 57px image-wrapper margin
        left: 75px;
        z-index: 99;
        display: none;
        a {
            display:block;
            width: 27px;
            height: 27px;
            margin-bottom: 12px;
            font-size: 16px;
            padding: 6px;
        } 
    }
    .social-share-responsive {
        display: none;
    }
}



/* mobile, tablet and up */
@media (max-width: 992px) {
    .stadtradeln-lightbox {
        .social-share-responsive {
            color: #fff;
            display: block;
            margin-top: 28px;
            .social-icons {
                display: inline-block;
                position: initial;
                a {
                    display: inline-block;
                    width: 25px;
                    height: 25px;
                    font-size: 13px;
                    margin: 0 6px;
                }
            }
        }
        .image-wrapper {
            margin: 0 48px;
        }
    }
}

/* tablet and up */
@media (min-width: 768px) and (max-width: 992px) {
    .stadtradeln-lightbox {
        &.modal {
            padding-top: 175px;
        }
        .modal-content {
            width: 80%;
        }
        .close {
            top: 115px;
        }
        .prev {
            left: -13px;
        }
        .next {
          right: -13px;
        }
    }
}

/* mobile */
@media (max-width: 767px) {
    .stadtradeln-lightbox {
        &.modal {
            padding-top: 120px;
        }
        .modal-content {
            width: 100%;
        }
        .close {
            top: 70px;
            right: 20px;
        }
        .prev {
            left: 0;
        }
        .next {
            right: 0;
        }
        .image-wrapper {
            margin: 0;
        }
        .social-share-responsive {
            margin-left: 30px;
            margin-right: 30px;
        }
    }
}