.search-contact {
    /* clear text from input X icon */
    .input-clear-icon::after {
        font-family: 'sr-icons', 'FontAwesome';
        content: "\e955";
        font-size: 28px;
        position: absolute;
        color: #454545;
        top: 14px;
        right: 70px;
        cursor: pointer;
    }
    /*
        select2 override
    */
    .commune-search {
        margin-top:32px;
    }
    .commune-search span.selection  {
        font-size:17px;
        line-height:24px;
    }
    .commune-search span.selection > span {
        box-shadow: 0 2px 20px rgba(0,0,0,0.06);
    }
    .commune-search .select2-container--default .select2-selection--single {
        border-radius: 25px;
        background-color: #ffffff;
        border: 1px solid #efecec;
        height:53px;
        padding:12px 35px;
    }

    #contactDetails {
        margin-bottom: 5px;
        font-weight: 300;
    }

    #ork-searchcontactform {
        min-height: 280px;
    }
    
    .select2-results__options {
        padding-bottom: 15px;
    }

    .commune-search .select2-container {
        margin-bottom:35px;
    }

    .commune-search .select2-container--open.select2-container--default  .select2-selection--single  {
        border-bottom:0;
    }
    .commune-search #select2-inputCitySearchContact-container {
        color:#454545;
        //width:330px;
    }
    .select2-container .select2-selection--single .select2-selection__rendered {
        padding-left:0;
    }
    .select2-search--dropdown {
        padding: 0 35px;
        background-color:#fff;
        font-size:17px;
        line-height:24px;
        border-top: 1px solid #efecec;
        border-left:1px solid #efecec;
        border-right:1px solid #efecec;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
    }

    .commune-search .select2-container--default .select2-selection--single .select2-selection__clear {
        margin-right: 20px;
        font-size: 35px;
        color: #acadab;
    }

    .select2-dropdown--below {
        margin-top: -52px;
    }

    .commune-search .select2-results li:hover {
        color:#0086C5;
        font-weight:500;
    }
    .commune-search .select2-selection__arrow {
        padding-right:60px;
    }
    .commune-search .select2-selection__arrow b {
        display:none;
    }
    .commune-search .select2-selection__arrow::after {
        font-family: 'sr-icons', 'FontAwesome';
        content: "\e947";
        font-size: 20px;
        position: absolute;
        top: 14px;
        color: #acadab;
        z-index: 1060;
    }
    .commune-search .select2-container--open #select2-inputCitySearchContact-container {
        border-bottom: 1px solid #0086C5;
        padding-top: 11px;
        padding-bottom: 14px;
    }
    .select2-container--default .select2-search--dropdown .select2-search__field {
        border:0;
        padding:0;
        border-bottom: 1px solid #0086C5;
        padding-bottom: 14px;
    }

    /* select ul li options */
    .select2-container--open .select2-dropdown .select2-results ul.select2-results__options {
        background-color:#fff;
        color:#454545;
        border-bottom-left-radius:25px;
        border-bottom-right-radius:25px;
        box-shadow:none;
        border:1px solid #efecec;
        border-top:0;
    }
    .select2-container--open .select2-dropdown .select2-results ul.select2-results__options .select2-results__option--highlighted[aria-selected] {
        color:#0086C5;
        font-weight:500;
        background-color:#fff;
    }
    .select2-container--open .select2-dropdown .select2-results ul.select2-results__options li.select2-results__option[aria-selected="true"] {
        background-color:#fff;
    }
    .select2-container--open .select2-dropdown .select2-results ul.select2-results__options li.select2-results__option {
        font-size:17px;
        line-height: 24px;
        padding:10px 35px;
        text-align:left;
        border:0;
    }
    .select2-container--open .select2-dropdown .select2-results ul.select2-results__options li.select2-results__option:last-child {
        padding-bottom:22px;
    }
    @media (max-width: 375px) {
        .commune-search {
            margin-top: 20px;
            min-height: 220px;
        }
        #contactDetails {
            margin-bottom:30px;
        }
        /* smaller input on mobile */
        .commune-search .select2-container--default .select2-selection--single {
            height:40px;
            padding:4px 20px;
        }
        .commune-search .select2-selection__arrow::after {
            top:6px;
        }
        .commune-search .select2-selection__arrow {
            padding-right:40px;
        }
    }
    
    @media (min-width: 376px) and (max-width: 767px) {
        #ork-searchcontactform {
            margin-top: 20px;
            min-height: 280px;
        }
        #contactDetails {
            margin-bottom:30px;
        }
        /* smaller input on mobile */
        .commune-search .select2-container--default .select2-selection--single {
            height:40px;
            padding:4px 20px;
        }
        .commune-search .select2-selection__arrow::after {
            top:6px;
        }
        .commune-search .select2-selection__arrow {
            padding-right:40px;
        }
        
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        #ork-searchcontactform {
            min-height: 220px;
        }
        .commune-search {
            margin-top:0;
        }
        .commune-search .select2-container {
            margin-bottom:25px;
        }
        #contactDetails {
            margin-bottom:20px;
        }
    }
} // .search-contact end