.modal-open {
	overflow: hidden;
	.modal {
		overflow-x: hidden;
		overflow-y: auto;
	}
}
.modal {
	display: none;
	overflow: hidden;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1050;
	-webkit-overflow-scrolling: touch;
	outline: 0;
}
.modal.fade {
	.modal-dialog {
		-webkit-transform: translate(0, -25%);
		-ms-transform: translate(0, -25%);
		-o-transform: translate(0, -25%);
		transform: translate(0, -25%);
		-webkit-transition: -webkit-transform 0.3s ease-out;
		-moz-transition: -moz-transform 0.3s ease-out;
		-o-transition: -o-transform 0.3s ease-out;
		transition: transform 0.3s ease-out;
	}
}
.modal.in {
	.modal-dialog {
		-webkit-transform: translate(0, 0);
		-ms-transform: translate(0, 0);
		-o-transform: translate(0, 0);
		transform: translate(0, 0);
	}
}
.modal-dialog {
	position: relative;
	width: auto;
	margin: 10px;
}
.modal-content {
	position: relative;
	background-color: #f8f8f8;
	border: 1px solid #999;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 7px;
	-webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
	box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
	background-clip: padding-box;
	outline: 0;
}
.modal-backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1040;
	background-color: #000;
}
.modal-backdrop.fade {
	opacity: 0;
	filter: alpha(opacity=0);
}
.modal-backdrop.in {
	opacity: 0.5;
	filter: alpha(opacity=50);
}
.modal-header {
	padding: 30px 24px 4px;
	border-bottom: 1px solid transparent;
	.close {
		margin-top: -2px;
	}
}
.modal-title {
	margin: 0;
	line-height: 1.428571429;
}
.modal-body {
	position: relative;
	padding: 30px;
}
.modal-footer {
	padding: 30px;
	text-align: right;
	border-top: 1px solid transparent;
	.btn {
		+ {
			.btn {
				margin-left: 5px;
				margin-bottom: 0;
			}
		}
	}
	.btn-group {
		.btn {
			+ {
				.btn {
					margin-left: -1px;
				}
			}
		}
	}
	.btn-block {
		+ {
			.btn-block {
				margin-left: 0;
			}
		}
	}
}
.modal-scrollbar-measure {
	position: absolute;
	top: -9999px;
	width: 50px;
	height: 50px;
	overflow: scroll;
}


.modal-dialog {
	align-items: center;
	justify-content: center;
	min-height: 100%;
	.btn {
		min-width: 200px;
	}
	.register-link {
		width: 100%;
		float: left;
		padding: 0 10px 15px;
		font-weight: 500;
	}
	.modal-content {
		border-radius: 0;
		box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.15);
		.modal-header {
			.close {
				opacity: 0.1;
				&:hover {
					opacity: 0.5;
				}
			}
			h3 {
				text-align: left;
			}
		}
		.modal-body {
			font-size: 15px;
			min-height: 150px;
		}
		
		.img-warning {
			padding-right: 20px; 
			padding-left: 30px;
			width: auto; 
			height:30px;
			margin-bottom: 20px;
		}
	}
}
.modal-small {
	.modal-dialog {
		.modal-content {
			.modal-body {
				min-height: 70px;
				p {
					margin: 10px 0 0;
					font-size: 16px;
					font-size: 16px;
					font-weight: normal;
					letter-spacing: 0.56px;
				}
			}
			min-width: 480px;
		}
	}
}
#sr-recommend-form {
	.control-label {
		line-height: 18px !important;
		font-weight: normal;
	}
}
.modal-backdrop {
	-webkit-backdrop-filter: saturate(180%) blur(10px);
	backdrop-filter: saturate(180%) blur(10px);
}
html.has_no-backdropfilter {
	body.modal-open {
		section {
			filter: blur(7px);
		}
	}
}

@media (min-width: 768px) {
	.modal-dialog {
		width: 680px;
		margin: 140px auto;
	}
	.modal-content {
		-webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
		box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
	}
	.modal-sm {
		width: 300px;
	}
}
@media (min-width: 992px) {
	.modal-lg {
		width: 800px;
	}
}

@media (max-width: 767px) {
	.modal-dialog {
		height: 100%;
		width: 100%;
		min-height: 100%;
		padding-top: 50px;
		margin: 0;
		.modal-content {
			height: 100%;
			display: flex;
			flex-direction: column;
			flex: 1 1 auto;
			border: 0;
			.modal-header {
				padding-top: 45px;
				.close {
					display: none;
				}
			}
			.modal-footer {
				height: 100%;
				display: flex;
				flex-direction: column-reverse;
				align-items: center;
				button {
					margin: 15px 0;
				}
				.register-link {
					text-align: center;
					font-size: 12px;
					margin-top: auto;
				}
			}
		}
	}
	.modal-small {
		.modal-dialog {
			height: calc(100vh - 80px);
			.modal-content {
				justify-content: center;
				min-width: auto;
				.modal-body {
					p {
						padding: 20px 45px 0;
						font-size: 17px;
					}
				}
				.modal-footer {
					height: auto;
				}
			}
		}
	}
	.modal-big {
		.modal-dialog {
			.modal-content {
				display: block;
				overflow-y: auto;
				.modal-footer {
					height: auto;
				}
			}
		}
	}
}
