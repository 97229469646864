.koordinatoren-nachmeldung {
    .sr_kommunenregistrierung_extra {
        td {
            padding-left: 3px;
        }
        th {
            padding-left: 3px;
        }
    }
    label {
        font-weight: normal;
    }
    .sr_creg_half_fade {
        opacity: 0.35;
    }

}
