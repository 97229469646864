.password-forgot {

    .info {
        &::before {
            top: 0 !important;
        }
    }
    .stepwizard-step {
        p {
            margin-top: 10px;
        }
        .disabled {
            opacity: 1 !important;
            filter: alpha(opacity=100) !important;
        }
        display: table-cell;
        text-align: center;
        position: relative;
    }
    .stepwizard-row {
        display: table-row;
    }
    .stepwizard {
        display: table;
        width: 100%;
        position: relative;
    }
    .setup-content {
        min-height: 400px;
        margin-bottom: 50px;
        .well {
            -webkit-transition: height 2s;
            -moz-transition: height 2s;
            -ms-transition: height 2s;
            -o-transition: height 2s;
            transition: height 2s;
        }
    }
    .btn-circle {
        width: 100%;
        height: 5px;
        text-align: center;
        padding: 0px;
        font-size: 12px;
        line-height: 1.428571429;
        margin: 0px;
        background-color: #76a230;
        border: 0;
    }
    .disabled {
        .control-label {
            color: rgba(69,69,69,0.3);
        }
    }
    a {
        font-weight: 500;
    }
    .small {
        font-size: 11px;
        line-height: 1.3;
    }
    .bg-white {
        background-color: #fff !important;
    }
    .well-min-height {
        min-height: 200px;
    }
    .well {
        color: rgba(69,69,69,1);
        text-shadow: 0 2px 31px 0 rgba(0,0,0,0.79);
        font-weight: 300;
        border: 1px solid rgba(245,245,245,1);
        background-color: #fff;
        box-shadow: none;
        border-radius: 0px;
        padding: 30px;
    }
    .radio {
        margin-top: 0px;
    }
    .checkbox {
        margin-top: 0px;
    }
    .well-disabled {
        opacity: 0.6;
        pointer-events: none;
    }
    .well-active {
        box-shadow: 0 2px 15px 0 rgba(0,0,0,0.05);
    }
    .well-shadow {
        border-color: rgba(0, 0, 0, 0.14);
        -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
    }

    .checkbox {
        margin-left: 10px;
        label {
            &:before {
                margin-left: -35px;
                background-color: #fff;
                border: 1px solid #a5a5a5;
            }
        }
        input[type="checkbox"] {
            &:checked {
                + {
                    label {
                        &::before {
                            content: "\f00c";
                            color: #fff;
                            border: 1px solid #73a425;
                        }
                    }
                }
            }
        }
    }
    .btn-default.active {
        &:focus {
            background: #e0e0e0;
            border-color: rgba(0, 0, 0, 0.125);
            -webkit-box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.125);
            box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.125);
            outline: none;
        }
    }
    .btn-primary {
        background-color: #8cb82b;
        border: none !important;
        color: white !important;
        -webkit-transition: all .05s linear;
        -o-transition: all .05s linear;
        transition: all .05s linear;
        &:hover {
            background-color: #769c24;
            border-color: rgba(0, 0, 0, 0.15);
            color: white;
        }
        &:focus {
            background: #769c24;
            border-color: rgba(0, 0, 0, 0.125);
            -webkit-box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.125);
            box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.125);
            outline: none;
        }
        &:active {
            background: #769c24;
            border-color: rgba(0, 0, 0, 0.125);
            -webkit-box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.125);
            box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.125);
            outline: none;
            &:hover {
                background: #769c24;
                border-color: rgba(0, 0, 0, 0.125);
                -webkit-box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.125);
                box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.125);
                outline: none;
            }
            &:focus {
                background: #769c24;
                border-color: rgba(0, 0, 0, 0.125);
                -webkit-box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.125);
                box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.125);
                outline: none;
            }
        }
    }
    .btn-primary.focus {
        background: #769c24;
        border-color: rgba(0, 0, 0, 0.125);
        -webkit-box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.125);
        box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.125);
        outline: none;
    }
    .btn-primary.active {
        background: #769c24;
        border-color: rgba(0, 0, 0, 0.125);
        -webkit-box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.125);
        box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.125);
        outline: none;
        &:hover {
            background: #769c24;
            border-color: rgba(0, 0, 0, 0.125);
            -webkit-box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.125);
            box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.125);
            outline: none;
        }
        &:focus {
            background: #769c24;
            border-color: rgba(0, 0, 0, 0.125);
            -webkit-box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.125);
            box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.125);
            outline: none;
        }
    }
    .btn-success {
        color: #ffffff;
        background-color: #8cb82b;
        border-color: #8cb82b;
        &:focus {
            color: #ffffff;
            background-color: #8cb82b;
            border-color: #8cb82b;
        }
    }
    .btn-success.focus {
        color: #ffffff;
        background-color: #8cb82b;
        border-color: #8cb82b;
    }
    .btn-tab {
        margin-right: 20px;
        border-radius: 2px !important;
        min-width: 150px;
        span {
            &:before {
                content: "\f096";
                font-family: FontAwesome;
                margin-right: 5px;
            }
        }
    }
    .btn-tab.active {
        span {
            &:before {
                content: "\f046";
                font-family: FontAwesome;
                margin-right: 5px;
            }
        }
    }


    #sr-reg-schulradeln-wrapper {
        min-height: 50px;
    }
    .checkbox {
        label {
            line-height: 1.5;
        }
        .control-label {
            width: 95% !important;
        }
    }
    #DSEModal {
        .modal-content {
            background-color: #fff;
        }
    }
    #SRModal {
        .modal-content {
            background-color: #fff;
        }
    }
    section {
        background-color: #fbfbfb;
    }
    .wizard {
        margin: 10px auto;
        margin-top: 0px;
        .nav-tabs {
            position: relative;
            margin: 0px auto;
            margin-bottom: 25px;
            border-bottom: 0px;
            width: 250px;
            >li.active {
                >a {
                    color: #fff;
                    cursor: default;
                    border: 0;
                    border-bottom-color: transparent;
                    &:hover {
                        color: #fff;
                        cursor: default;
                        border: 0;
                        border-bottom-color: transparent;
                    }
                    &:focus {
                        color: #fff;
                        cursor: default;
                        border: 0;
                        border-bottom-color: transparent;
                    }
                }
            }
            >li {
                width: 25%;
                >a {
                    border: 0px;
                    pointer-events: none;
                    cursor: default;
                }
                a {
                    width: 40px;
                    height: 40px;
                    margin: 20px auto;
                    border-radius: 100%;
                    padding: 0;
                    color: #fff;
                    &:hover {
                        background: transparent;
                    }
                }
            }
        }
        >div.wizard-inner {
            position: relative;
        }
        li {
            a.disabled {
                span.round-tab {
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    display: inline-block;
                    border-radius: 100px;
                    background: #fbfbfb;
                    border: 1px solid #8CB82B;
                    color: #8CB82B;
                    z-index: 2;
                    position: absolute;
                    left: 0;
                    text-align: center;
                    font-size: 15px;
                }
            }
        }
        .tab-pane {
            position: relative;
            padding-top: 50px;
        }
    }
    .connecting-line {
        height: 1px;
        background: #8CB82B;
        position: absolute;
        width: 220px;
        margin: 0 auto;
        left: 0;
        right: 0;
        top: 50%;
        z-index: 1;
    }
    span.round-tab {
        width: 40px;
        height: 40px;
        line-height: 40px;
        display: inline-block;
        border-radius: 100px;
        background: #8CB82B;
        border: 1px solid #8CB82B;
        z-index: 2;
        position: absolute;
        left: 0;
        text-align: center;
        font-size: 15px;
        i {
            color: #5bc0de;
        }
    }
    h3 {
        margin-top: 0px !important;
    }
    .form-horizontal {
        .form-column {
            width: 100%;
        }
        .checkbox {
            label {
                &:before {
                    position: absolute;
                    left: -5px;
                    top: 2px;
                    content: "";
                    color: #fff;
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 15px;
                    border-radius: 2px;
                    width: 24px;
                    height: 24px;
                    line-height: 22px;
                    margin-top: -2px;
                    background-color: transparent;
                    border: 2px solid rgba(69, 69, 69, 0.38);
                    text-align: center;
                    font-family: fontawesome;
                }
                color: #676767 !important;
                text-shadow: 0 2px 31px 0 rgba(0,0,0,0.79);
                font-weight: 300;
            }
        }
    }
    .schulradeln-wrapper {
        margin-top: 223px;
    }
    @media (min-width: 768px) {
        .modal-dialog {
            width: 680px;
            margin: 220px auto;
        }
        .form-horizontal {
            .control-label {
                width: 40% !important;
            }
            .form-column {
                width: 60% !important;
            }
            .control-label.datenschutz {
                width: 18% !important;
            }
            .form-column.datenschutz {
                width: 82% !important;
            }
        }
        .checkbox {
            .control-label {
                width: 95% !important;
            }
        }
    }
    @media (min-width: 768px) and (max-width: 992px) {
        .schulradeln-wrapper {
            position: absolute;
            top: -140px;
            right: 15px;
            width: 128px;
            margin-top: 0px;
        }
    }


    /* =============  Accordion  ================ */

    .accordion {
        h3 {
            margin-top: 10px;
            position: relative;
            margin-bottom: 0px;
            cursor: pointer;
        }
    }
    .well.accordion {
        h3 {
            img {
                position: absolute;
                right: -8px;
                top: -7px;
                width: 36px;
                height: 36px;
                -webkit-transition: 0.5s ease-in-out;
                -moz-transition: 0.5s ease-in-out;
                -o-transition: 0.5s ease-in-out;
                transition: 0.5s ease-in-out;
                transform: rotate(0deg);
            }
        }
        h3.collapsed {
            img {
                color: red;
                transform: rotate(-90deg);
            }
        }
    }
    .checkbox-special-hight {
        padding-bottom: 110px;
    }
    .form-horizontal {
        .control-label {
            color: #454545 !important;
            font-family: Roboto;
            font-size: 13px;
            letter-spacing: 0;
            line-height: 17px;
            padding-top: 5px;
        }
        .form-control {
            border-bottom: 1px solid #979797;
        }
    }
    .pflichtfeld {
        opacity: 0.5;
        color: #454545;
        font-family: Roboto;
        font-size: 12px;
        line-height: 17px;
        text-align: right;
        margin-top: -10px;
    }
    .new-label {
        background-color: #8cb82b;
        padding: 2px 3px;
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 0.23px;
        line-height: 10px;
    }
    .hspace-2 {
        padding-left: 20px;
    }
    h1 {
        margin-top: 0;
        margin-bottom: 50px;
    }
    .row-info {
        min-height: 20px;
    }
    .form-control {
        height: 30px;
    }
    #pwresetform {
        input {
            &::placeholder {
                color: #454545;
                opacity: 0.5;
            }
        }
        .form-horizontal {
            .form-control {
                &::placeholder {
                    color: #454545;
                    opacity: 0.5;
                }
            }
        }
    }
    .hp-text-field {
        display: none;
    }
    #sr_resetpw {
        margin-bottom: 30px;
    }
    canvas.jCaptchaCanvas {
        position: absolute;
        margin-top: 8px;
    }
    input.jCaptcha {
        border: 0;
        border-bottom: 1px solid #979797;
        padding: 4px 0;
        height: 30px;
        font-size: 12px;
        line-height: 17px;
        color: #454545;
        width: 75%;
        float: right;
        border-radius: 0;
        &:focus {
            outline: none;
        }
    }
    .jCaptcha.error {
        border-bottom: 1px solid #E30613;
        border-bottom: 1px solid #E30613;
    }
    #mini-info {
        font-size: 10px;
        line-height: 17px;
        font-weight: normal;
        padding-left: 15px;
        color: #E30613;
    }
    @media (max-width: 585px ) {
    .wizard {
        width: 90%;
        height: auto !important;
        .nav-tabs {
            >li {
                a {
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                }
            }
        }
    }
    span.round-tab {
        font-size: 15px;
        width: 40px;
        height: 40px;
        line-height: 40px;
    }
    .schulradeln-wrapper {
        margin-top: 0px;
        max-width: 80px;
    }
}
    @media (max-width: 768px) {
        h3 {
            font-size: 17px;
            line-height: 24px;
            text-align: left;
        }
        .checkbox-special-hight {
            padding-bottom: 320px;
        }
        .pflichtfeld {
            padding-right: 10px;
        }
    }
    @media (max-width: 321px) {
        .checkbox-special-hight {
            padding-bottom: 420px;
        }
    }
    @media (max-width: 767px) {
        input.jCaptcha {
            font-size: 14px;
            width: 80%;
        }
        h1 {
            font-size: 24px;
            line-height: 32px;
            margin-top: 15px;
            margin-bottom: 25px;
            padding: 0 20px;
        }
        .form-horizontal {
            .control-label {
                font-size: 12px;
                padding-left: 0;
                padding-right: 0;
            }
            .form-column {
                padding-left: 0;
                padding-right: 0;
            }
        }
        .row-info {
            min-height: 60px;
        }
        .input-group {
            width: 100%;
        }
        #mini-info {
            font-size: 14px;
            line-height: 20px;
            margin-top: 20px;
            padding-left: 0;
        }
        .well.well-active {
            margin-left: -15px;
            margin-right: -15px;
        }
    }
}
