/* additional styling to overwrite default stylings */

/* variables */
$headline-h1: 32px;
$headline-h1-lh: 40px;
$headline-h2: 20px;
$headline-h2-lh: 36px;
$headline-h3: 16px;
$headline-h3-lh: 20px;
$headline-h4: 15px;
$headline-h4-lh: 22px;

$headline-mobile-h1: 24px;
$headline-mobile-h1-lh: 36px;
$headline-mobile-h2: 19px;
$headline-mobile-h2-lh: 27px;
$headline-mobile-h3: 16px;
$headline-mobile-h3-lh: 20px;
$headline-mobile-h4: 15px;
$headline-mobile-h4-lh: 22px;

h1 {
    font-size: $headline-h1;
    line-height: $headline-h1-lh;
    font-weight:normal;
}
h2 {
    font-size: $headline-h2;
    line-height: $headline-h2-lh;
    font-weight:normal;
}
h3 {
    font-size: $headline-h3;
    line-height: $headline-h3-lh;
    font-weight:normal;
}
h4 {
    font-size: $headline-h4;
    line-height: $headline-h4-lh;
    font-weight:normal;
}

.container {
    .header {
        width:100%;
    }
    .container-header {
        margin-bottom:50px;
    }
}

body {
    &.lightbox-open {
        height: 100vh;
        overflow-y: hidden;
    }
    &.modal-open {
        height: 100vh;
        overflow-y: hidden;
    }
}

.section > .section__inner {
    padding:40px 0;
}

.btn {
    padding-right: 40px;
    padding-left: 40px;
    font-weight: 400;
}

.btn-primary {
    color: #fff;
    background-color: #8CB82B;
    border-color: #8CB82B;
}

.btn-secondary {
    background-color: transparent;
    border: 1px solid $color-text;
    color: $color-text;
}

input.btn {
    color:#fff;
}

.btn-lg, .btn-group-lg > .btn {
    padding: 14px 28px;
    padding-right: 28px;
    padding-left: 28px;
    font-size: 18px;
    line-height: 1.25;
    border-radius: 26px;
}

.mainnav-spacer {
    height: 127px;
}

.section {
    &.bg-gray {
        margin-bottom:0;
        padding-bottom:40px;
    }
}

.bg-admin {
    background-color: #fffff0;
}
.dark-bg {
    .bg-admin {
        background-color: #223a54;
    }
}

// center icons on home
.sr-howitworks {
    img {
        margin: 0 auto;
    }
    .tablet-view {
        img {
            margin-top: 15px;
        }
    }
}

.btn-width {
    width: 190px;
}

// override style.css:8288
/*
.form-horizontal .form-control {
    border-width: 0 0 1px;
    border-color: #969696;
}
*/

// btn in modals: override style.css:8783
.dashboard .modal .btn.btn-default, .dashboard .modal .btn.btn-default:not(.btn-success){
    color: $color-text;
    background-color: transparent;
    border-color: $color-text;
}

/* overwrite fixed 750px container width tablet+ */
@media screen and (min-width: 768px) and (max-width: 992px) {
    h1 {
        font-size: $headline-mobile-h1;
        line-height: $headline-mobile-h1-lh;
    }
    h2 {
        font-size: $headline-mobile-h2;
        line-height: $headline-mobile-h2-lh;
    }
    h3 {
        font-size: $headline-mobile-h3;
        line-height: $headline-mobile-h3-lh;
        font-weight:normal;
    }
    h4 {
        font-size: $headline-mobile-h4;
        line-height: $headline-mobile-h4-lh;
        font-weight:normal;
        margin-bottom: 24px;
    }
    .container {
        .container-header {
            margin-bottom:10px;
        }
    }
    .mainnav-spacer {
        height: 90px;
    }
    .sr_start_content {
        .container {
            width: auto;
        }
    }
    .section > .section__inner {
        padding:34px 0;
    }
}

.extra-container {
    margin-right: auto;
    margin-left: auto;
    padding-left:15px;
    padding-right:15px;
}

.commune-search {
    font-size:14px;
    line-height:22px;
    p {
        font-size:14px;
        line-height:22px;
        font-weight:300;
    }
}

/* info i styling for after a label -> Mein km-Buch */
.icon-info-label {
    font-size: 18px;
    vertical-align: middle;
    color: $color-text;
    opacity: .5;
}

.fade-carousel {
    &:hover {
        .carousel-control {
            opacity: 0.5;
        }
    }
}

/* mailjet iframe override */
iframe {
    .mj-form {
        .mj-embedded {
            &.mj-vertical {
                padding:0!important;
                margin-bottom:15px;
            }
        }
        .mj-embedded-content {
            background:transparent!important;
            input {
                border-radius:0!important;
                border:0!important;
                border-bottom:1px solid #979797!important;
                background:transparent!important;
                outline:0;
                padding-left:0!important;
            }
            .w-preview-consent-checkbox-label {
                padding-left: 5px!important;
                position: absolute!important;
                max-width: 400px!important;
            }
            .w-preview-consent {
                padding:20px 0px 40px!important;
            }
            .mj-subscribe-button {
                height:40px!important;
                line-height:40px!important;
                background:#8CB82B!important;
                width:190px!important;
                border-radius: 25px!important;
            }
        }
    }
}

.arrow-links {
    a {
        &:focus {
            color: #0086c5;
        }
    }
}

/* ergebnisse/results table, newcomer kommunen */
.sr-results {
    .toggle-wrapper {
        margin-top: 14px;
        margin-left: 7px;
    }
    .toggle-text {
        font-size: 12px;
        line-height: 14px;
        font-weight: 500;
        letter-spacing: 0.25px;
        opacity: 0.47;
        min-width: 180px;
        top: 0;
    }
}
/* newcomer ranking outline */
table.sr-ranking tbody > tr.newcomer > td:first-of-type span {
    border: 1px solid $green;
}
/* startseite newcomer */
.data-table {
    .newcomer {
        .profile-small {
            border: 1px solid $green;
        }
        .oval {
            border: 1px solid $green;
        }
    }
}

/* switch toggle */
.switch {
    height: 14px;
    border: 1px solid rgba(255,255,255,0.5);
    border-radius: 10px;
    margin-bottom: 10px;
}
.toggle {
    background: rgba(172,173,171,0.5);
    // border: 1px solid rgba(255,255,255,0.3);
    &::before {
        height: 18px;
        width: 18px;
        bottom: -3px;
        background-color: #91A7AD;
    }
}
input:checked + .toggle.toggle-green {
    background-color: #86a867;
    //border: 1px solid transparent;
}

// video shariff typo3 extension
.video {
    .video-shariff-play {
        display: inline-block;
        .video-shariff-preview-overlay {
            background: rgba(0,0,0,0.3);
        }
        .video-shariff-preview {
            top: 0;
            height: 100%;
        }
        .video-shariff-preview-icon {
            top: 47%;
            border-left: 44px solid #fff;
            border-top: 25px solid transparent;
            border-bottom: 25px solid transparent;
        }
        .video-shariff-preview-text {
            font-size: 12px;
            line-height: 17px;
            font-weight: 500;
            text-align: left;
            color: #fff;
            top: -7px;
            margin: 0;
            padding-left: 50px;
            text-shadow: 0px 0px 2px #000;
            &::before {
                position: absolute;
                left: 15px;
                top: 6px;
                font-family: 'sr-icons' !important;
                content: "\e914";
                font-size: 24px;
                line-height: 20px;
                color: #fff;
            }
        }
    }
    a.video-shariff-play:hover .video-shariff-preview-overlay, 
    a.video-shariff-play:focus .video-shariff-preview-overlay {
        background: rgba(0,0,0,0.4);
    }
}

// desktop only info
// desktop only registration info
.desktop-only-info {
    &.confirm-message {
        width: 100%;
        border-radius: 4px;
        color: $color-text;
        display: flex;
        justify-content: left;
        background-color: #fbfbfb;
        flex-direction: column;
        .img-confirm-message {
            img {
                width: 100%;
                height: auto;
            }
        }
        .text-confirm-message {
            margin-top: 26px;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            margin-left: 36px;
            margin-right: 36px;
        }
    }
}

// sonderaktion schulradeln: logo glow
.sonderaktion-schulradeln {
    .logo-kommune {
        filter: drop-shadow(0px 0px 5px #073b50);
        img {
            filter: drop-shadow(0px 0px 1px rgb(255, 255, 255));
        }
    }
}

.dark-bg .footer-partner {
  background: #fff;
  color: initial;
}

// socials (facebook, twitter, instagram)
.socials {
    [class^="icon-"] {
        padding: 4px 6px;
        border-radius: 7px;
        margin-right: 5px;
    }
    a {
        &:hover {
            [class^="icon-"] {
                color: #fff;
            }
            .icon-facebook {
                background: #3b5998;
            }
            .icon-twitter {
                background: #55acee;
            }
            .icon-instagram {
                background: linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d, #f56040, #f77737, #fcaf45, #ffdc80);
            }
        }
    }
}

/* all mobile */
@media (max-width: 767px) {
    h1 {
        font-size: $headline-mobile-h1;
        line-height: $headline-mobile-h1-lh;
    }
    h2 {
        font-size: $headline-mobile-h2;
        line-height: $headline-mobile-h2-lh;
    }
    h3 {
        font-size: $headline-mobile-h3;
        line-height: $headline-mobile-h3-lh;
        font-weight:normal;
    }
    h4 {
        font-size: $headline-mobile-h4;
        line-height: $headline-mobile-h4-lh;
        font-weight:normal;
    }
    .w-100-mobile {
        width: 100%;
    }
    /* mein team dropdowns */
    .mobile-nav {
        .dropdown {
            max-width: unset;
            padding: 0 15px;
            &.open {
                .dropdown-menu {
                    left:0;
                    margin: 0 15px;
                }
            }
        }
    }
    .mainnav-spacer {
        height: 50px;
    }
    .section > .section__inner {
        padding: 10px 0;
    }
    #team-change-wrapper {
        margin: 0 auto;
        padding-top: 10px;
    }
    /* startseite newcomer mobile */
    #top10kommunen.data-table tbody > tr .bar_wrapper .bar_text {
        left: 0;
        white-space: nowrap;
    }
    #top10kommunen .bar_wrapper .data-unit {
        font-size: 11px;
        color: rgba(255, 255, 255, 0.5);
        margin-left: 2.5px;
    }
    /* newcomer */
    table.sr-ranking tbody > tr > td:first-of-type span {
        margin-top: -4px;
        margin-right: -4px;
    }
    /* safari horizontal scrolling fix (happens where the tables are loading in) */
    #ork-startpage-auswertung {
        overflow-x: hidden;
    }
    // auswertung
    .results-table {
        overflow-x: hidden;
    }
}

// desktop
@media (min-width: 992px) {
    /* login dropdown styling */
    body > header.navbar .navbar-default .mega-menu .dropdown .dropdown-menu {
        padding: 15px 15px;
        margin-bottom: 40px;
    }
    body > header.navbar .navbar-default .mega-menu .dropdown .dropdown-menu li a {
        line-height: 3;
        padding-top: 0;
        padding-bottom: 0;
    }
    .input-search-wrapper {
        float: right;
        input {
            width: 260px;
        }
    }
}

// redesign 2024 rules outside of components
.bg-dark {
    background-color: $blue98;
    color: $sr-white;
}