.flex-grid {
	display: flex;
	justify-content: space-between;
	padding: 30px 0;
	.col {
		flex: 1;
		padding: 15px;
	}
}
.has_no-flexbox {
	.flex-grid {
		display: inline-block;
		position: relative;
		width: 100%;
		min-height: 150px;
		clear: both;
		padding: 40px 0;
		.col {
			width: 50%;
			float: left;
		}
	}
}
@media (max-width: 767px) {
	.flex-grid {
		display: block;
	}
}
