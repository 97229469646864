/* IE 6-8 fallback colors */
/* Spacer for Main-Navigation */
/* New Menu */
/* Overrides SR App Style */
#km-chart {
	max-height: 370px;
}
#kmbook-table {
	.panel-heading {
		a {
			>.row {
				div {
					&:nth-child(2) {
						padding-top: 21px;
					}
				}
			}
		}
	}
}
.leaflet-control-layers {
	border-radius: 0;
	background-color: transparent !important;
	box-shadow: 0 0px 0px rgba(0,0,0,0.4) !important;
	border: 0px !important;
}
.sr-list-toggle {
	cursor: pointer;
	padding: 1em 0.5em;
	height: 40px;
	width: 296px;
	border-radius: 22.5px;
	background-color: #8CB82B;
	text-align: center;
	color: #fff;
	&:hover {
		background-color: #6d8f21;
	}
}
.marker-cluster-small {
	background-color: #94C11F !important;
	div {
		background-color: #0086C5 !important;
	}
}
.marker-cluster-medium {
	background-color: #94C11F !important;
	div {
		background-color: #0086C5 !important;
	}
}
.marker-cluster-large {
	background-color: #94C11F !important;
	div {
		background-color: #0086C5 !important;
	}
}
.leaflet-oldie {
	.marker-cluster-small {
		background-color: #94C11F !important;
		div {
			background-color: #0086C5 !important;
		}
	}
	.marker-cluster-medium {
		background-color: #94C11F !important;
		div {
			background-color: #0086C5 !important;
		}
	}
	.marker-cluster-large {
		background-color: #94C11F !important;
		div {
			background-color: #0086C5 !important;
		}
	}
}
.marker-cluster {
	div {
		color: #fff !important;
		font-weight: 500 !important;
		font-size: 15px !important;
		width: 34px !important;
		height: 34px !important;
		margin-left: 3px !important;
		margin-top: 3px !important;
		text-align: center !important;
		border-radius: 50% !important;
	}
	span {
		line-height: 33px !important;
	}
}
.paginate_button {
	padding: 10px;
	color: #fff !important;
	text-decoration: none !important;
	&:hover {
		color: #fff !important;
		text-decoration: none !important;
		cursor: pointer;
	}
}
#staedteliste_length {
	display: none;
}

#staedteliste_wrapper {
	div.dataTables_paginate {
		text-align: center !important;
		padding-top: 50px;
		padding-bottom: 50px;
	}
}
.info_small {
	position: relative;
	padding-left: 20px;
	font-size: 14px;
	font-weight: 500;
	&::before {
		position: absolute;
		left: 0px;
		font-family: 'sr-icons' !important;
		content: "\e914";
		font-size: 14px !important;
		line-height: 20px;
		color: #b6b6b6;
	}
}
.mainnav-spacer {
	height: 127px;
}
input {
	&::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}
.desktopmenu {
	color: #454545;
	font-family: Roboto;
	.submenu {
		margin-bottom: 20px;
		font-size: 16px;
		font-weight: 400;
		line-height: 19px;
	}
	.submenu_act {
		font-size: 16px;
		font-weight: 500;
		line-height: 19px;
		margin-bottom: 20px;
	}
	.subsubmenu {
		font-size: 16px;
		font-weight: 400;
		line-height: 19px;
		margin-bottom: 20px;
	}
	li.submenu {
		list-style-type: none;
		a {
			line-height: 1.428571429 !important;
		}
	}
	li.submenu_act {
		list-style-type: none;
		a {
			line-height: 1.428571429 !important;
		}
	}
	li.subsubmenu {
		list-style-type: none;
		a {
			line-height: 1.428571429 !important;
		}
	}
	ul {
		margin: 0;
		padding: 0;
	}
}
@media (max-width: 479px) {
	#kmbook-table {
		.panel-heading {
			a {
				>.row {
					div {
						&:nth-child(2) {
							padding-top: 10px;
						}
					}
				}
			}
		}
	}
	.paginate_button.first {
		display: none;
	}
	.paginate_button.previous {
		display: none;
	}
	.paginate_button.next {
		display: none;
	}
	.paginate_button.last {
		display: none;
	}
}
@media (max-width: 768px) {
	.mainnav-spacer {
		height: 50px;
	}
	body {
		>header.navbar {
			.navbar-default {
				.navbar-kb-kampagne {
					.navbar-nav {
						width: 100%;
					}
				}
			}
		}
	}
}
