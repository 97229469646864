.contact-portrait {
	float: left;
	max-width: 50px;
	margin: 4px 20px 0 0;
	border-radius: 50%;
	background: var(--highlight-color);
	+ {
		p {
			&::first-line {
				font-weight: 500;
			}
		}
	}
}
.profile-card {
	background: #fff;
	padding: 30px;
	margin: 10px 40px;
	flex: 1;
	p {
		margin-top: 10px;
	}
}
.user-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 30px;
	padding: 0;
	text-align: center;
	.profile-big {
		position: relative;
		img {
			width: 100px;
			height: 100px;
			overflow: hidden;
			border-radius: 50%;
		}
	}
	h4 {
		margin: 10px 0 0;
		font-size: 12px;
		font-weight: 500;
	}
}
.profile-big.star-right {
	&::after {
		content: "\e905";
		display: block;
		position: absolute;
		right: -40px;
		top: 50%;
		transform: translateY(-50%);
		font-family: 'sr-icons' !important;
		font-size: 22px;
		line-height: 1;
		color: #0086C5;
		color: var(--highlight-color);
	}
}
.report-user {
	display: table;
	position: absolute;
	right: 20px;
	top: 20px;
	margin-bottom: 20px;
	text-align: right;
	line-height: 2;
	color: #c7c7c7;
	text-decoration: none !important;
	&::after {
		content: '\e923';
		display: table-cell;
		width: 25px;
		font-family: 'sr-icons' !important;
		font-size: 15px;
		line-height: 1;
		vertical-align: middle;
	}
}
.profile-xs {
	img {
		height: 30px;
		width: 30px;
		overflow: hidden;
		border-radius: 50%;
	}
}
