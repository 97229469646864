.promotion-fund {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 20px;
    .checkbox-wrapper {
        margin-top: 10px;
        display: flex;
        label {
            margin-right: 10px;
        }
        .checkbox-text {
            margin-top: 2px;
        }
    }

    .promotion-last-time {
        width: 25%;
        margin-left: 10px;
    }
}

.promotion-fragment-admin {
    .akkordeon-topic-body {
        margin-bottom: 20px;
    }
}


.promotion-header {
    margin-bottom:60px;
    margin-left: 15px;
    h2 {
        font-size: 20px;
        display: flex;
        justify-content: left;
    }
}

.promotion-fstate {
    margin-bottom: 20px;
}

.admin-panel {
    margin-bottom: 90px;
    min-height: 50px;
}

#promotion-overview {
    a {
        font-weight:500;
    }
    .promotion-header {
        margin-right:80px;
        h2 {
            font-size:19px;
            line-height:26px;
        }
    }
}

.progress {
    height: 26px;
    margin-bottom: 0;
}

.progress-bar {
    line-height: 26px;
    position: relative;
}

.promotion-button {
    background-color: transparent;
    color: #FFFFFF;
    border: 1px solid #454545;
    border-radius: 22.5px;
    font-size: 14px;
    padding: 9px 30px 11px 30px;
    margin-top: 40px;
    //margin-left: 115px;
    width:100%;
}

.dark-bg .promotion-button {
    border-color: #fefefe !important;
    color: #fefefe !important;
    border: 1px solid #fefefe;
    &:focus, :hover{
        border: 1px solid #fefefe;
        color: #fefefe !important;
    }
}


.excel-export-promotion-button {
    margin-top: 40px;
    width:100%;
    //margin-left: 115px;
}

#promotionFields {
    display: none;

    table {
        margin-top:40px;
    }

    td {
        padding-top:15px;
    }

    input, select {
        width:100%;
        background-color: transparent;
        border:0;
        border-bottom: 2px solid rgba(151,151,151, .8);
        line-height: 30px;
        font-size:12px;
        color: #fff;
    }
    .quillTextEditor button {
        background-color: inherit;
        color: inherit;
        border: inherit;
        border-radius: inherit;
        font-size: inherit;
        padding: inherit;
    }
    button {
        background-color: #8CB82B;
        color: #FFFFFF;
        border: 1px solid #454545;
        border-radius: 22.5px;
        font-size: 14px;
        padding: 9px 30px 11px 30px;
    }
}
#addNewPromotionFund {
    margin-top: 20px;
    width:100%;
}

#exportAllPromotionFunds {
    margin-top: 20px;
    width:100%;
}

#addPromotion #promotionFields {
    margin-bottom:80px;
}

.promotion-fragment-admin {
    margin-bottom: 45px;
    position: relative;

    h3 {
        font-size: 16px;
        line-height: 20px;
        display: flex;
        justify-content: left;
    }

    .promotion-progressbar {
        .progress {
            margin-bottom: 5px;
            box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.12) inset;
            background-color: #EEEEEE;
            .progress-bar.progress-bar-success {
                background-color: #8CB82B;
                box-shadow: none;
                position: relative;
            }
            .progress-bar.progress-bar-success.text-center {
                text-align: center;
            }
            .progress-bar.progress-bar-warning {
                background-color: #F5A623;
                box-shadow: none;
            }
            .progress-bar.progress-bar-danger {
                background-color: #E30613;
                box-shadow: none;
            }

            .progress-bar {
                width: 100%;
                text-align: right;
                position: relative;
            }

            .progress-value-outside {
                font-weight: 600;
                color: #454545;
                margin-left: 15px;
                position:absolute;
                font-size: 14px;
            }
        }
    }

    .promotion-values {
        display:flex;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        margin-top:8px;

        .promotion-value-left {
            display:none;
        }

        .promotion-value-total {
            display: none;
        }
     }

    .promotion-current-status {
        font-size: 11px;
        font-weight: normal;
        opacity: .5;
    }

    .promotion-info-text {
        margin-top: 32px;
        font-size: 14px;
        line-height: 20px;
        color: #ffffff;
        font-weight: lighter;
    }

    .promotion-admin-fields {
        display: none;

        .admin-table {
            width:100%;
        }

        .select2 {
            width:100%;
        }

        table {
            margin-top:40px;
        }

        td {
            padding-top:15px;
        }

        input, textarea, select, .select2 {
            //margin-left: 20px;
            width:100%;
            background-color: transparent;
            border:0;
            border-bottom: 2px solid rgba(151,151,151, .8);
            line-height: 30px;
            font-size:12px;
            color: #fff;
        }

        .saveData {
            background-color: #8CB82B;
            color: #FFFFFF;
            border: 1px solid #454545;
            border-radius: 22.5px;
            font-size: 14px;
            padding: 9px 30px 11px 30px;
            float:right;
        }

        .deleteData {
            background-color: transparent;
            color: #FFFFFF;
            border: 1px solid #454545;
            border-radius: 22.5px;
            font-size: 14px;
            padding: 9px 30px 11px 30px;
        }
    }
}

.dark-bg .promotion-admin-fields .deleteData {
    border-color: #fefefe !important;
    color: #fefefe !important;
    border: 1px solid #fefefe;
    &:focus, :hover{
        border: 1px solid #fefefe;
        color: #fefefe !important;
    }
}


.promotion-fragment-admin .hideProgressBar {
    display:none;
}

#sr-promotion-changes-finish, #sr-delete-promotion-finish  {
    .modal-header {
        float:right;
    }

    .modal-body {
        padding-top:0px;
    }

    .row.text-center {
        margin-top: 0px;
        margin-bottom: 35px;
    }

    .row {
        margin-top:0px;
    }

    button {
        width: 100%;
    }

    .information-text {
        color: #454545;
        font-size: 16px;
    }

    #sr-promotion-final-finish {
        float:right;
    }
}

.promotion-fragment-public {
    margin-bottom: 70px;
    margin-left: -15px;
    margin-right: -15px;
    position: relative;

    h3 {
        font-size: 16px;
        line-height: 20px;
        display: flex;
        justify-content: left;
    }

    .promotion-values.hideProgressBar, .promotion-progressbar.hideProgressBar, .promotion-current-status.hideProgressBar {
        display:none;
    }

    .promotion-progressbar {
        .progress {
            margin-bottom: 0;
            box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.12) inset;
            background-color: #EEEEEE;
            .progress-bar.progress-bar-success {
                background-color: #8CB82B;
                box-shadow: none;
            }
            .progress-bar.progress-bar-success.text-center {
                text-align: center;
            }
            .progress-bar.progress-bar-warning {
                background-color: #F5A623;
                box-shadow: none;
            }
            .progress-bar.progress-bar-danger {
                background-color: #E30613;
                box-shadow: none;
            }

            .progress-bar {
                .progress-value {
                    width:100%;
                    display:block;
                    padding-left: 4px;
                    padding-right: 12px;
                    padding-bottom: 0;
                }
                .progress-value-outside {
                    text-align: right;
                    font-weight: 600;
                    color: #454545;
                    position:absolute;
                    font-size: 14px;
                    right: -30px;
                }
            }
        }
    }

    .promotion-values {
        display:flex;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0.6px;
        margin-top:8px;

        .promotion-value-left {
            display:none;
        }

        .promotion-value-total {
            display: none;
        }

    }

    .promotion-current-status {
        font-size: 12px;
        line-height:17px;
        font-weight: normal;
        margin-top: 7px;
        opacity: .5;
    }

    .promotion-info-text {
        margin-top: 40px;
        font-size: 14px;
        line-height: 22px;
        color: #454545;
        font-weight: lighter;
    }

    .admin-panel {
        display:none;
    }
}

/******************/
/*      Tablet    */
/******************/
@media (min-width: 768px) {
    .promotion-header {
        margin-left: 80px;
        h2 {
            justify-content: center;
        }
    }

    .promotion-fragment-admin {
        .promotion-values {
            .promotion-value-mobile {
                display: none;
            }

            .promotion-value-left {
                display: block;
            }

            .promotion-value-total {
                display: block;
            }
        }
    }

    .promotion-fragment-public {
        .promotion-values {
            .promotion-value-mobile {
                display: none;
            }

            .promotion-value-left {
                display: block;
            }

            .promotion-value-total {
                display: block;
            }
        }
    }
}

// mobile
@media (max-width: 767px) {
    .promotion-fragment-public {
        .promotion-values {
            display: block;
            .promotion-progressbar {
                margin-bottom: 10px;
            }
        }
        .promotion-info-text {
            margin-top: 28px;
        }
    }
}

/******************/
/*      Desktop   */
/******************/
@media (min-width: 992px) {

    #sr-promotion-changes-finish, #sr-delete-promotion-finish  {
        width: initial;
        margin-top: initial;
    }

    .admin-panel {
        margin-bottom: unset;
    }

    .promotion-button {
        width: initial;
    }

    #addNewPromotionFund {
        margin-top: 20px;
        width: initial;
    }

    .excel-export-promotion-button {
        width: initial;
    }

    #exportAllPromotionFunds {
        margin-top: 20px;
        width: initial;
    }

    #addPromotion #promotionFields {
        margin-bottom: initial;
    }

    .promotion-fragment-admin {
        margin-bottom: 95px;
        margin-right: 0px;
        margin-left: 0px;

        h3 {
            justify-content: center;
        }

        .promotion-info-text {
            margin-left:0px;
            margin-right: 0px;
        }
    }

    .promotion-fragment-public {

        h3 {
            justify-content: center;
        }
    }
}

@media (min-width: 1440px) {
    .promotion-fragment-admin {
        margin-right: 0px;
        margin-left: 0px;
    }

    .promotion-fragment-public {
        margin-right: 0px;
        margin-left: 0px;
    }
}
