/* styling for the login page - /login or /user/dashboard */
#sr-login {
    background-color:#063547;
    justify-content:center;
    color:#fff;
    padding:60px 0;
    max-width: 675px;
    label {
        font-weight:300;
        margin-top:15px;
        color:rgba(255, 255, 255, 0.8);
    }
    input {
        color:#fefefe;
    }
    h1 {
        font-size: 20px;
        line-height: 27px;
    }
    .info {
        max-width:280px;
        color:rgba(255,255,255,0.5);
        font-size:13px;
    }
    .info::before {
        /*top:5px;*/
        opacity:0.3;
    }
    .form-control {
        border-color:rgba(255,255,255,0.8);
        border-width:0 0 1px;
        border-radius:0;
        padding:4px 50px 0 0; 
    }
    .btn.btn-default {
        border-color:#fff;
        color:#fff;
    }
    .pw-reset-link {
        font-weight:500;
    }
    .buttons-login {
        display:block;
    }
    .buttons-mobile {
        display:none;
    }
}
// uc override
.uc {
    #sr-login {
        .control-label {
            width: 174px;
        }
    }
}
/* low res */
@media (max-width: 1024px) {
    #sr-login {
        padding:0 30px;
    }
}
/* tablet */
@media (min-width: 768px) and (max-width: 992px) {
    #sr-login {
        padding:0 24px;
        h1 {
            font-size: 19px;
            line-height: 30px;
        }
    }
}
/* mobile */
@media (max-width: 767px) {
    #sr-login {
        h1 {
            font-size: 19px;
            line-height: 30px;
        }
        .btn {
            width:100%;
        }
        label {
            color:rgba(255, 255, 255, 1);
            font-size: 14px;
            line-height: 22px;
        }
        .buttons-mobile {
            display:block;
        }
        .buttons-login {
            display:none;
        }
    }
}