.arrow-links {
	display: flex;
	justify-content: space-between;
	list-style: none;
	padding: 0;
	margin-bottom: 30px;
	li {
		display: inline;
		a {
			font-size: 14px;
			font-weight: 500;
			&::before {
				content: "\e5db";
				display: inline-block;
				text-align: center;
				vertical-align: middle;
				font-family: sr-icons;
				font-size: 25px;
				line-height: 0.8;
				font-weight: normal;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				float: right;
				margin-left: 3px;
				transform: rotate(-45deg);
				transition: transform 0.2s ease;
			}
			&:hover {
				color: #00a9f8;
				&::before {
					transform: rotate(90deg);
				}
			}
		}
	}
}
html.has_no-flexbox {
	.arrow-links {
		display: table;
		width: 100%;
		text-align: center;
		li {
			display: table-cell;
			a {
				position: relative;
			}
			&::first-child {
				text-align: left;
			}
			&::before {
				padding-right: -30px;
				float: none;
			}
		}
	}
}
