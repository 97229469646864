.print-freigabe{
    label[for=sr-mark-all] {
        display: flex;
        &:before {
            margin-right:10px;
        }
    }
    .link-back {
        display: block;
        margin-bottom: 90px;
        color: #fff;
        font-size: 14px;
        line-height: 16px;
        svg {
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
        }
    }
    .status-open {
        height: 15px;
        width: 15px;
        background-color: #B4B4B4;
        border-radius: 50%;
        display: inline-block;
        position: relative;
        top: -2px;
        margin-right: 10px;
        vertical-align: middle;
    }
    .status-approved {
        height:15px;
        width:15px;
        display:inline-block;
        position: relative;
        top: -2px;
        background-image: url("data:image/svg+xml,%3Csvg height='15' viewBox='0 0 15 15' width='15' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle cx='6.75' cy='7.5' fill='%23fff' r='6.75'/%3E%3Cpath d='m7.34159675.00164202c-1.97218601.04154919-3.84778188.85206042-5.22180132 2.25705193-1.37400295 1.40433695-2.13569696 3.28944086-2.11954366 5.24719982-.01287731 1.98279549.77135302 3.88966323 2.17888231 5.29645473 1.40813917 1.406906 3.3236238 2.1976515 5.32086592 2.1976515 1.9978974 0 3.9127227-.7906964 5.3208619-2.1976515 1.4074963-1.4069061 2.1923983-3.31365924 2.1788823-5.29645473.0167401-2.01290479-.7887426-3.94579085-2.2309685-5.35912787-1.4429017-1.41392611-3.40092884-2.18734236-5.42717895-2.14512388zm4.33699605 4.20024715-.0006438.00063916c.3096983-.01406251.5955788.16235949.7198438.44360488.1249095.28126012.0630984.60981148-.1558151.82713843l-5.4380577 5.39807356c-.3901846.3873627-1.02374008.3873627-1.41456752 0l-2.64881146-2.62965446c-.29102312-.2914711-.29038028-.76129071.00193164-1.05213999.29230879-.29019473.76619385-.29083291 1.05916196-.00127833l2.29475751 2.27881571 5.08400377-5.04723481h.0006438c.1319923-.13167745.3096983-.20965235.4970601-.21732695z' fill='%238cb82b' fill-rule='nonzero'/%3E%3C/g%3E%3C/svg%3E");
        margin-right: 10px;
        vertical-align: middle;
    }
    .status-declined {
        height:15px;
        width:15px;
        display:inline-block;
        position: relative;
        top: -2px;
        background-image: url("data:image/svg+xml,%3Csvg height='15' viewBox='0 0 15 15' width='15' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle cx='7.5' cy='7.5' fill='%23fff' r='7.5'/%3E%3Cpath d='m7.5 0c-4.14966667 0-7.5 3.35033333-7.5 7.5 0 4.1496667 3.35033333 7.5 7.5 7.5 4.1496667 0 7.5-3.3503333 7.5-7.5 0-4.14966667-3.3503333-7.5-7.5-7.5zm3.8163333 10.0331667c.3502667.3502666.3502667.9166666 0 1.2669333-.1836.1836-.3997333.2669333-.6334666.2669333-.2330667 0-.4668-.0833333-.6334667-.2669333l-2.49933333-2.5-2.517 2.5c-.1836.1836-.39973334.2669333-.63346667.2669333-.23306667 0-.4668-.0833333-.63346667-.2669333-.35026666-.3502667-.35026666-.9166667 0-1.2669333l2.517-2.51700003-2.51633333-2.49933334c-.35026667-.35026666-.35026667-.91666666 0-1.26693333s.91666667-.35026667 1.26693333 0l2.517 2.517 2.51699997-2.517c.3502667-.35026667.9166667-.35026667 1.2669334 0 .3502666.35026667.3502666.91666667 0 1.26693333l-2.5181667 2.51633334z' fill='%23e30613' fill-rule='nonzero'/%3E%3C/g%3E%3C/svg%3E");
        margin-right: 10px;
        vertical-align: middle;
    }
    .custom-dropdown {
        .btn.btn-default.dropdown-toggle {
            border-color: transparent;
            background-color: transparent;
            padding: 3px 10px 3px 0;
            border-width: 0 0 0px 0 !important;
            transition: none !important;
            text-align: left;
            min-width: unset;
            &::after {
                position: inherit;
            }
            &:hover, &:active {
                border-width: 0 0 0px 0 !important;
            }
        }
    }
    tr.shown {
        .custom-dropdown {
            .btn.btn-default.dropdown-toggle {
                background-color: transparent !important;
            }
        }
    }

    #freigabeliste {
        a {
            color: #0086C5;
        }
    }
    .freigabe-username {
        line-height: 18px;
    }
    .modal {
        .modal-body {
            color: #454545;
        }
    }
    #freigabeliste_length {
        display: none;
    }
    .dataTables_filter {
        display: none;
    }

    /* Pagination */

    .paginate_button.current {
        color: #0086C5 !important;
        font-weight: 600;
    }

    .paginate_button {
        font-size: 16px;
        position: relative;
        padding-left: 8px;
    }

    @media (max-width: 479px) {
        .paginate_button.first {
            display: inline;
        }
        .paginate_button.previous {
            display: inline;
        }
        .paginate_button.next {
            display: inline;
        }
        .paginate_button.last {
            display: inline;
        }
        .paginate_button {
            font-size: 18px;
        }
    }

    .pagination-icon {
        height: 15px;
        fill: white;
        position: absolute;
        top: 12px;
        left: 0px;
    }

    /* NumberOfResults */

    .numberOfResults {
        font-weight: bold;
        margin-top: -120px;
        margin-bottom: 130px;
        font-size: 12px;
        line-height: 13px;
        letter-spacing: 0.3px;
        padding: 0;
        display: none;
        >div {
            float: left;
            display: inline-table;
            margin-right: 10px;
            color: rgba(255, 255, 255, 0.4);
            font-weight: 500;
        }
        >span {
            float: left;
            display: inline-table;
            margin-right: 10px;
            color: rgba(255, 255, 255, 0.4);
            font-weight: 500;
        }
    }
    @media (max-width: 768px) {
        .numberOfResults {
            margin-top: -160px;
            font-weight: bold;
            margin-bottom: 130px;
        }
    }

    .numberOfResults {
        >div {
            cursor: pointer;
        }
        >div.active {
            cursor: pointer;
            color: #0086C5;
        }
    }

    /* End NumberOfResults */

    #confirmModal {
        .modal-dialog {
            margin-top: 315px;
        }
    }
    .spinner {
        z-index: 99999;
    }


    /* col sorting */
    .data-table {
        thead {
            >tr {
                >th[class*="sorting"] {
                    &::after {
                        left: unset;
                    }
                }
            }
        }
    }

    /* force width of table */
    #freigabeliste {
        td {
            font-size: 12px;
            line-height: 18px;
            white-space: nowrap;
        }
        tr {
            >td {
                padding-left: 0;
                ~ {
                    td {
                        padding-left: 17.5px;
                    }
                }
            }
            >th {
                padding-left: 0 !important;
                ~ {
                    th {
                        padding: 10px 18px 10px 18px !important;
                    }
                }
            }
        }
        th {
            white-space: nowrap;
        }
        thead {
            th {
                padding-left: 0;
            }
        }
        tr.shown {
            background-color: #0086C5;
            td.col-name {
                padding-left: 17.5px;
            }
        }
        .shown {
            a {
                color: #fff;
            }
        }
        .expanded {
            background-color: #fff;
            color: #454545;
            padding-bottom: 20px;
        }
        td.expanded {
            padding-top: 50px;
            padding-left: 16px;
            border-top: 0;
            font-size: 13px;
        }
        td.reason {
            display: inline-block;
            vertical-align: top;
            margin-top: 10px;
        }
        #reasons_text {
            border-radius: 7px;
            display: inline-block;
            border: 1px solid #6a6a6a;
            font-size: 12px;
            color: #454545;
        }
        .btn_freigabe {
            margin: 20px 0;
            display: block;
            width: 190px;
        }
        span.required {
            width: 100%;
            display: block;
            text-align: right;
            font-size: 12px;
            line-height: 14px;
            font-weight: 300;
        }
        .col-name {
            max-width: 100px;
            white-space: normal;
        }
        .col-city {
            max-width: 100px;
            white-space: normal;
        }
        .col-status {
            max-width: 95px;
        }
    }
    #freigabeliste_paginate {
        text-align: center;
        padding-top: 70px;
    }

    .content-spinner-wrapper {
        width: 100%;
        min-height: 200px;
        text-align: center;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        background-color: rgba(0,0,0, .3);
    }
    .content-spinner {
        z-index: 5000;
        width: 200px;
        margin: 0 auto;
        padding: 20px;
        position: fixed;
        top: 50vh;
        left: 50vw;
        img {
            margin-top: 25%;
            -webkit-animation: rotation 1s linear 0s infinite;
            -moz-animation: rotation 1s linear 0s infinite;
            -o-animation: rotation 1s linear 0s infinite;
            animation: rotation 1s linear 0s infinite;
        }
    }

}
