.pager {
	padding-left: 0;
	margin: 20px 0;
	list-style: none;
	text-align: center;
	li {
		display: inline;
		>a {
			display: inline-block;
			padding: 5px 14px;
			background-color: #fff;
			border: 1px solid #ddd;
			border-radius: 15px;
			&:hover {
				text-decoration: none;
				background-color: #e7e7e7;
			}
			&:focus {
				text-decoration: none;
				background-color: #e7e7e7;
			}
		}
		>span {
			display: inline-block;
			padding: 5px 14px;
			background-color: #fff;
			border: 1px solid #ddd;
			border-radius: 15px;
		}
	}
	.next {
		>a {
			float: right;
		}
		>span {
			float: right;
		}
	}
	.previous {
		>a {
			float: left;
		}
		>span {
			float: left;
		}
	}
	.disabled {
		>a {
			color: #898989;
			background-color: #fff;
			cursor: not-allowed;
			&:hover {
				color: #898989;
				background-color: #fff;
				cursor: not-allowed;
			}
			&:focus {
				color: #898989;
				background-color: #fff;
				cursor: not-allowed;
			}
		}
		>span {
			color: #898989;
			background-color: #fff;
			cursor: not-allowed;
		}
	}
}
