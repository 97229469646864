.poster-generator-page {
    section {
        background-color: #fbfbfb;
    }
    .h1-header {
        font-size: 32px;
        line-height: 40px;
    }
    #modal-close {
        display: none;
    }
    #finish-step {
        font-size: 14px;
        line-height: 22px;
        a {
            font-weight: 600;
            color: #ffffff;
        }
        #download-link {
            font-size: 12px;
        }
    }
    #finish-step-btn {
        a {
            color: #ffffff;
        }
        margin-top: 43px;
    }
    .content-spinner {
        z-index: 5000;
        width: 200px;
        margin: 0 auto;
        padding: 20px;
        img {
            margin-top: 25%;
            -webkit-animation: rotation 1s linear 0s infinite;
            -moz-animation: rotation 1s linear 0s infinite;
            -o-animation: rotation 1s linear 0s infinite;
            animation: rotation 1s linear 0s infinite;
        }
    }
    .privacy-check {
        background-color: #FFFFFF;
        min-height: 180px;
    }
    .modal-dialog {
        .modal-content {
            .modal-header {
                .close {
                    opacity: 0.8;
                }
            }
        }
    }
    .close {
        font-size: 22px;
    }

    #confirmPrivacyLabel{
        width: auto !important;
        font-weight: normal;
        font-size: 14px;
        color: #454545;
    }
}
