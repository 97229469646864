.thumbnail {
	display: block;
	padding: 4px;
	margin-bottom: 20px;
	line-height: 1.428571429;
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 4px;
	-webkit-transition: border 0.2s ease-in-out;
	-o-transition: border 0.2s ease-in-out;
	transition: border 0.2s ease-in-out;
	>img {
		display: block;
		max-width: 100%;
		height: auto;
		margin-left: auto;
		margin-right: auto;
	}
	a {
		>img {
			display: block;
			max-width: 100%;
			height: auto;
			margin-left: auto;
			margin-right: auto;
		}
	}
	.caption {
		padding: 9px;
		color: #454545;
	}
}
a.thumbnail {
	&:hover {
		border-color: #0086C5;
	}
	&:focus {
		border-color: #0086C5;
	}
}
a.thumbnail.active {
	border-color: #0086C5;
}
