.mein-team {
    .sr-icon-grid {
        display: flex;
        flex-wrap: wrap;
        >li {
            flex-basis: 0% !important;
        }
    }
    .link-back {
        color: #fff;
        font-size: 14px;
        line-height: 16px;
        svg {
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
        }
    }
    .dashboard-headline {
        margin-top: 40px;
        font-size: 20px;
        line-height: 22px;
    }
    .help-block {
        color: #454545;
    }
    .col-centered {
        margin: 0 auto;
        float: none;
    }
    .info-label {
        color: rgba(255, 255, 255, 0.5);
        font-size: 12px;
        line-height: 14px;
        font-weight: 500;
    }
    .team-switch-wrapper {
        position: absolute;
        margin-top: -30px;
    }
    .nav-pills {
        .btn {
            padding-bottom: 8px;
            padding-top: 8px;
            font-size: 11px !important;
            color: #fff;
            min-width: 135px;
            font-weight: 500;
        }
        .btn-middle {
            border-radius: 0;
            margin-left: -1px;
            margin-right: -1px;
        }
        .active {
            .btn {
                border-color: #8CB82B !important;
            }
        }
    }
    .dataTables_filter {
        display: none;
    }
    #my-team-results-table_length {
        display: none;
    }
    #my-team-results-2-table_length {
        display: none;
    }
    #my-team-results-3-table_length {
        display: none;
    }
    div.dataTables_paginate {
        text-align: center !important;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .paginate_button.current {
        color: #0086C5 !important;
        font-weight: 600;
    }
    .paginate_button {
        font-size: 18px;
        position: relative;
    }
    .pagination-icon {
        height: 25px;
        fill: white;
        position: absolute;
        top: 8px;
        left: 0px;
    }

    #team-change-wrapper.pull-right {
        margin-right: 38px;
    }

    .profile-small {
        position: relative;
        image-orientation: none;
    }
    .info-right-top {
        font-size: 16px;
        position: absolute;
        top: 10px;
        right: 10px;
        opacity: 0.5;
    }
    .icon-captain-wrapper {
        position: absolute;
        top: 10px;
        right: -28px;
        padding: 3px;
    }
    .icon-captain {
        font-size: 13px;
        color: #a6a6a6;
    }
    .selected {
        .icon-captain {
            color: #0E4761;
        }
    }
    .profile-card-captains {
        background-color: transparent;
        h3 {
            color: #FFFFFF;
            font-size: 14px;
            letter-spacing: 0.7px;
            line-height: 20px;
            text-align: center;
            text-shadow: 0 2px 31px 0 rgba(0,0,0,0.39);
        }
        .user-card {
            margin-top: 0px;
        }
    }
    .profile-card.profile-card-captains {
        background: #fff;
        padding: 0;
        margin: 10px 20px 20px 20px;
        flex: 1;
    }
    .user-card {
        .profile-big {
            img {
                width: 100px;
                height: 100px;
                overflow: hidden;
                border-radius: 50%;
            }
        }
    }
    .link-teamcaptain {
        color: #0086C5;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.51px;
        line-height: 17px;
        text-align: center;
        text-shadow: 0 2px 31px 0 rgba(0,0,0,0.79);
    }
    .captain-legende {
        color: rgba(255,255,255,0.5);
        font-size: 12px;
        letter-spacing: 0.33px;
        line-height: 14px;
    }
    .info {
        position: relative;
        padding-left: 40px;
        font-weight: 500;
        color: rgba(255,255,255,0.5);
        font-size: 12px;
        letter-spacing: 0.55px;
        line-height: 17px;
    }
    .icon-edit {
        color: rgba(255, 255, 255, 0.6);
        font-size: 16px;
        padding-left: 7px;
    }
    a {
        &:hover {
            .icon-edit {
                color: rgba(255, 255, 255, 1);
            }
            .icon-mail {
                color: rgba(255, 255, 255, 1);
            }
            .icon-lock {
                color: rgba(255, 255, 255, 1);
            }
            .icon-bin {
                color: rgba(255, 255, 255, 1);
            }
        }
    }
    .icon-mail {
        color: rgba(255, 255, 255, 0.6);
        font-size: 20px;
        margin-top: 2px;
    }
    .icon-lock {
        color: rgba(255, 255, 255, 0.6);
        font-size: 18px;
        padding-left: 18px;
    }
    .icon-bin {
        color: rgba(255, 255, 255, 0.6);
        font-size: 18px;
        padding-left: 16px;
    }
    .tc-setup-link-text-edit {
        padding-left: 21px;
        font-size: 14.5px;
        color: #fff;
    }
    .tc-setup-link-text-mail {
        padding-left: 20px;
        margin-top: 4px;
        font-size: 14.5px;
        color: #fff;
        position: absolute;
    }
    .tc-setup-link-text-lock {
        padding-left: 22px;
        font-size: 14.5px;
        color: #fff;
    }
    .tc-setup-link-text-bin {
        padding-left: 20px;
        font-size: 14.5px;
        color: #fff;
    }
    .tc-profile {
        width: 70px;
        height: 70px;
        overflow: hidden;
        border-radius: 50%;
    }
    .tc_profile-text {
        margin-top: 15px;
        font-size: 15px;
    }
    .chat-messages {
        .message {
            a {
                color: #fff;
                text-decoration: underline;
                &:hover {
                    color: #fff;
                    text-decoration: underline;
                }
            }
        }
    }
    .mobile-txt-radelnde {
        padding-top: 10px;
        font-size: 12px;
        opacity: 0.75;
        display: inline-block;
    }
    .btn-delete {
        background-color: #de1520;
        color: #fff;
        &:hover {
            color: #fff;
            background-color: rgba(222, 21, 32, 0.9);
        }
        &:active {
            color: #fff;
            background-color: rgba(222, 21, 32, 0.9);
        }
        &:focus {
            color: #fff;
            background-color: rgba(222, 21, 32, 0.9);
        }
    }
    .icon-info {
        opacity: 0.8;
        font-size: 18px;
        position: absolute;
        margin-left: 20px;
    }
    @media (min-width: 768px) {
        .icon-info {
            margin-left: 5px;
            margin-top: -2px;
        }
    }
    @media (max-width: 767px) {
        .paginate_button.first {
            display: inline;
        }
        .paginate_button.previous {
            display: inline;
        }
        .paginate_button.next {
            display: inline;
        }
        .paginate_button.last {
            display: inline;
        }
        .paginate_button {
            font-size: 18px;
        }

        .nav-pills > li + li {
            margin-left: 0px;
        }

        .dashboard-headline {
            font-size: 19px;
            line-height: 1.4;
        }
        /*
        section.dashboard {
            >main {
                padding: 10px 15px;
            }
        }
        */
        div.dataTables_paginate {
            padding-bottom: 0;
        }
        .row-legende {
            margin-top: -25px;
        }
        .tc-settings {
            .row {
                margin-bottom: 15px;
            }
        }
        #my-team-results-3-table {
            th.active-bikers {
                max-width: 20px;
            }
        }
        .dropdown-sm {
            max-width: 320px;
            margin: 0 auto;
        }
        #team-change-wrapper {
            margin-top: 40px;
        }
        .ui-widget-content {
            border: 0 !important;
            background: rgba(5,40,54,0.88) !important;
            color: #fff;
            color: rgba(255,255,255,1);
            font-family: Roboto;
            font-size: 12px;
            font-weight: 300;
            letter-spacing: 0.56px;
            line-height: 16px;
            text-shadow: 0 2px 31px 0 rgba(0,0,0,0.79);
        }
        .mt-xs-10 {
            margin-top: 10px;
        }
        .dashboard-headline {
            margin-top: 30px;
        }
        .row-legende {
            margin-top: 25px;
            padding-bottom: 20px;
        }
        .dataTables_wrapper {
            .dataTables_paginate {
                padding-top: 90px;
            }
        }
        .info {
            font-size: 14px;
        }
        .icon-info {
            margin-left: 10px;
            margin-top: -3px;
        }
        #team-change-wrapper {
            margin-top: 30px;
        }
        .nav-pills {
            .btn {
                min-width: auto;
            }
        }
        .tracks {
            font-size: 20px;
            line-height: 1.5;
            text-align: right;
        }
    }
}