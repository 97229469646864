.auswertung-kommune-ptcounter {
    .status {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #052836;
    }
    .status.status-green {
        background-color: #8CB82B;
    }
    .status.status-red {
        background-color: #D0021B;
    }
    .status.status-yellow {
        background-color: #F8E71C;
    }
    .status.status-orange {
        background-color: #F96C0A;
    }
    .status-small {
        &:before {
            content: "";
            display: block;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            float: left;
            margin: 3px 6px 0 0;
        }
    }
    table.sr-ranking {
        tbody {
            >tr {
                &:last-child {
                    border-bottom: 0px !important;
                }
                >td {
                    &:first-of-type {
                        span {
                            height: 35px;
                            width: 35px;
                            display: block;
                            padding: 12px 0 15px 0;
                            border-radius: 50% 0px 50% 50%;
                            margin-left: 2px;
                            background: #052836;
                            font-size: 10px;
                            font-weight: 500;
                            line-height: 12px;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
    .status-small.status-green {
        &:before {
            background-color: #8CB82B;
        }
    }
    .status-small.status-red {
        &:before {
            background-color: #D0021B;
        }
    }
    .status-small.status-yellow {
        &:before {
            background-color: #F8E71C;
        }
    }
    .status-small.status-orange {
        &:before {
            background-color: #F96C0A;
        }
    }
    .ml-10 {
        margin-left: 10px;
    }
    .dataTables_filter {
        display: none;
    }
    .arrow-invert {
        .arrow-links {
            li {
                a {
                    &::before {
                        float: left;
                        margin-right: 3px;
                        transform: rotate(-135deg);
                    }
                    &:hover {
                        &::before {
                            transform: rotate(-90deg);
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 768px) {
        table.sr-ranking {
            tbody {
                >tr {
                    >td {
                        &:first-of-type {
                            span {
                                height: 45px;
                                width: 45px;
                                display: block;
                                padding: 17px 0 15px 0;
                                border-radius: 50%;
                                margin-left: 2px;
                                background: #052836;
                                font-size: 13px;
                                font-weight: 500;
                                line-height: 12px;
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
        .md-px-0 {
            padding-left: 0px !important;
            padding-right: 0px !important;
        }
    }
    table.sr-ranking {
        span.aktiv {
            &::after {
                content: none;
            }
        }
        tbody {
            >tr {
                td {
                    font-size: 20px;
                    color: rgba(255, 255, 255, 0.87) !important;
                }
            }
        }
    }
    .text-15 {
        font-size: 15px !important;
    }
    #ptcounter-table-sort-xs {
        .btn {
            padding-left: inherit;
            padding-right: inherit;
            padding-top: 5px;
            padding-bottom: 5px;
        }
        .btn.btn-default.active {
            background-color: rgba(2, 27, 38, 0.8) !important;
            border: 1px solid rgba(0, 134, 197, 0.4) !important;
        }
    }

    @media (max-width: 767px) {

        /* line 225, ../scss/components/_datatables.scss */
        table.sr-ranking tbody>tr>td.radelnde,
        table.sr-ranking tbody>tr>td.merge-left {
            position: absolute;
            left: 0;
            margin-top: 22px;
            font-size: 11px;
            opacity: 0.7;
            padding-left: 10px !important;
        }
    }

    .data-unit {
        font-size: 13px;
    }
    table.sr-ranking {
        tbody {
            >tr {
                border-bottom: 1px solid #052836;
            }
        }
    }
    td {
        i[class*='-dude'] {
            padding-left: 7px;
            font-size: 30px;
            color: #0086C5;
            vertical-align: middle;
        }
    }
    .results-filter-wrapper {
        display: flex;
        flex-wrap: wrap;
    }
    .text-md {
        font-size: 14px !important;
    }
    .data-table {
        tbody {
            >tr {
                .bar_wrapper {
                    .bar_bg {
                        width: 100%;
                    }
                }
            }
        }
    }

    @media (max-width: 767px) {
        .col-min-130 {
            min-width: 30px;
        }

        .col-min-200 {
            min-width: 20px;
        }
    }


    @media (min-width: 768px) {
        .col-min-130 {
            min-width: 30px;
        }

        .col-min-200 {
            min-width: 20px;
        }

        .filter-search {
            max-width: 355px;
        }
    }

    @media (min-width: 991px) {

        .filter-search {
            max-width: 425px;
        }
    }

    @media (min-width: 1440px) {
        .col-min-130 {
            min-width: 130px;
        }

        .col-min-200 {
            min-width: 180px;
        }

    }

    .results-filter {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .results-filter>div {
        flex: 1;
        text-align: center;
        margin: 0px 50px;
    }

    @media (max-width: 767px) {
        .results-filter {
            flex-direction: column;
        }

        .results-filter>div {
            margin: 10px 0px;
        }
    }

    @media (min-width: 768px) {
        .results-filter>div {
            margin: 0px 30px;
        }
    }

    @media (min-width: 1440px) {
        .results-filter>div {
            margin: 0px 50px;
        }
    }

    .results-filter>div:first-child {
        margin-left: 0px;
    }

    .results-filter>div:last-child {
        margin-right: 0px;
    }

    @media (max-width: 767px) {
        .results-filter {
            margin-right: 0px;
        }
    }

    @media (min-width: 768px) {
        .results-filter {
            margin-right: 0px;
        }
    }

    @media (min-width: 1440px) {
        .results-filter {
            margin-right: 0px;
        }
    }

    .nav-pills {
        display: flex !important;
        justify-content: stretch;
    }

    @media (max-width: 767px) {
        .nav-pills {
            display: none !important;
            justify-content: stretch;
        }

        .hidden-xs {
            display: none !important;
        }
    }

    .nav-pills {
        >li {
            >a {
                border-left-width: 0px;
                border-radius: 0;
            }
            &:first-child {
                >a {
                    border-radius: 2px 0 0 2px;
                    border-left-width: 1px;
                }
            }
            &:last-child {
                >a {
                    border-radius: 0 2px 2px;
                }
            }
        }
        >li+li {
            margin-left: -1px;
        }
        >li.active {
            >a {
                color: #fefefe;
                background-color: #fefefe;
                border-color: #8CB82B !important;
                font-weight: 500 !important;
                &:hover {
                    color: #fefefe;
                    background-color: #fefefe;
                    border-color: #8CB82B !important;
                    font-weight: 500 !important;
                }
                &:focus {
                    color: #fefefe;
                    background-color: #fefefe;
                    border-color: #8CB82B !important;
                    font-weight: 500 !important;
                }
            }
        }
    }
    #legend-wrapper {
        p {
            line-height: 17px;
        }
    }

    @media (min-width: 768px) {
        #legend-hide {
            float: none;
            margin: 30px auto;
            display: block;
            width: 250px;
        }
    }

    @media (min-width: 992px) {
        #legend-hide {
            float: right;
        }
    }

    @media (max-width: 991px) {
        table.sr-ranking thead > tr > th.hide-on-tablet {
            width: 0px !important;
            overflow: hidden !important;
            display: none;

        }

        #legend-wrapper p {
            padding-left: 100px;
            padding-right: 100px;
        }
    }
    #results-table-ranking_length {
        display: none;
    }
    #results-table-winning_length {
        display: none;
    }
    #results-table-stars_length {
        display: none;
    }
    #results-table-ranking_wrapper {
        div.dataTables_paginate {
            text-align: center !important;
            padding-top: 70px;
            padding-bottom: 50px;
        }
    }
    #results-table-winning_wrapper {
        div.dataTables_paginate {
            text-align: center !important;
            padding-top: 70px;
            padding-bottom: 50px;
        }
    }
    #results-table-stars_wrapper {
        div.dataTables_paginate {
            text-align: center !important;
            padding-top: 70px;
            padding-bottom: 50px;
        }
    }

    @media (max-width: 767px) {
        #results-table-winning tbody>tr>td:nth-child(2) {
            padding: 29px 15px 0;
        }
    }

    @media (max-width: 767px) {
        .sr-icon-grid li {
            flex-basis: 100px !important;
        }

        .xs-mt-0 {
            margin-top: 0px !important;
        }

        .xs-pt-0 {
            padding-top: 0px !important
        }

        table.sr-ranking tbody>tr>td {
            padding: 13px 28px 0;
            border: 0;
            vertical-align: top;
            overflow: hidden;
            -ms-text-overflow: ellipsis;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    @media (max-width: 767px) {
        table.sr-ranking tbody>tr>td.parlamentarierinnen {
            position: absolute;
            left: 0;
            margin-top: 38px;
            font-size: 11px;
            opacity: 0.7;
            padding-left: 10px !important;
        }

        .xs-text-13 {
            font-size: 13px !important;
        }

        .data-table tbody>tr .bar_wrapper .bar_text {
            font-size: 18px;
            left: 60%;
        }

    }
    .nav {
        >li {
            >a {
                position: relative;
                display: block;
                padding: 7px 15px;
                font-size: 11px;
                font-weight: 500;
            }
        }
    }
    #ptcounter-table-sort-xs {
        .btn {
            padding-left: inherit;
            padding-right: inherit;
            padding-top: 4px;
            padding-bottom: 4px;
            font-size: 11px;
        }
        .btn.btn-default.active {
            background-color: rgba(2, 27, 38, 0.8) !important;
            border: 1px solid rgba(0, 134, 197, 0.4) !important;
        }
    }
    .numberOfResults_sulzbach {
        >div {
            float: left;
            display: inline-table;
            margin-right: 10px;
            color: rgba(255, 255, 255, 0.5);
            cursor: pointer;
        }
        >span {
            float: left;
            display: inline-table;
            margin-right: 10px;
            color: rgba(255, 255, 255, 0.5);
        }
        margin-top: -120px;
        font-weight: bold;
        >div.active {
            cursor: pointer;
            color: #0086C5;
        }
    }

    @media (max-width: 479px) {
        h1, h2 {
            font-size: 24px !important;
            line-height: 30px !important;

        }

        .xs-mb-10 {
            margin-bottom: 10px !important;
        }

        .xs-mt-25 {
            margin-top: 25px !important;
        }

         .xs-w-60 {
            min-width: 60% !important;

        }

        table tbody > tr > td:nth-child(2) {
            min-width: 200px;
        }

    }

    .sr-icon-grid li {
        padding: 25px 0 15px;
    }

    @media (max-width: 767px) {
        .sr-icon-grid {
            margin: 10px 15px 30px;
        }
    }

    @media (max-width: 767px) {
        table.sr-ranking tbody > tr > td:first-of-type {
            right: -15px;
        }

        .bar_text {
            text-align: right;
            padding-right: 40px;
        }
    }

    .sr-icon-grid li p {
        padding-left: 5px;
        padding-right: 5px;
    }

    .sr-ranking th.w-20 {
        width: 20%;
    }

    @media (max-width: 768px) {
        h1, h2 {
            font-size: 24px;
            line-height: 30px;
            padding-top: 20px !important;
        }
    }

    @media (max-width: 480px) {
        @-moz-document url-prefix() {
            .xs-ff-fix {
                padding-left: 0px !important;
                min-width:140px !important;
            }
            .xs-ff-fix-ml-15 {
                margin-left: 15px !important;
            }
            .xs-ff-fix-min-width {
                min-width: 100px !important;
            }
            .xs-align-left {
                text-align: left !important;
            }
          }
        .bar_text {
            text-align: left !important;
        }
        .sr-icon-grid {
            margin: 0;
        }
    }
    .icon-sm-table {
        color:#0086C5;
        margin-left: 7px;
        font-size: 14px;
    }

    #sr-ptcounter-table_wrapper .data-table tbody > tr .bar_wrapper .bar_text {
        top: -15px;
    }

    @media (max-width: 991px) {
        .data-table tbody > tr .bar_wrapper .bar_text {
            top: 5px !important;
        }

    }
    div.dataTables_paginate {
        text-align: center !important;
        padding-top: 70px;
        padding-bottom: 35px;
    }
    .dataTables_length {
        display: none;
    }
    .paginate_button.current {
        color: #0086C5 !important;
        font-weight: 600;
    }
    .paginate_button {
        font-size: 18px;
        position: relative;
    }
    .pagination-icon {
        height: 25px;
        fill: white;
        position: absolute;
        top: 8px;
        left: 0;
    }
    @media (max-width: 479px) {
        .paginate_button.first {
            display: inline;
        }
        .paginate_button.previous {
            display: inline;
        }
        .paginate_button.next {
            display: inline;
        }
        .paginate_button.last {
            display: inline;
        }
        .paginate_button {
            font-size: 18px;
        }
    }

    /* browser specific styles */

    /* chrome */
    @media all and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) {
        @media (max-width: 767px) {
            #sr-ptcounter-table thead > tr > th:nth-child(2) {
                width: 120px !important;
            }
            #sr-ptcounter-table tbody > tr > td:nth-child(2) {
                left: -48px;
            }
            #sr-ptcounter-table tbody>tr .bar_wrapper .bar_text {
                left: 0;
            }

            #sr-ptcounter-table tbody>tr>td.radelnde {
                left: -7px;
            }
        }
    }
    /* firefox */
    @-moz-document url-prefix() {
        @media (max-width: 767px) {
            #sr-ptcounter-table tbody > tr > td:nth-child(2) {
                left: -24px;
            }
            #sr-ptcounter-table tbody>tr>td.radelnde {
                left: -26px;
            }
        }
    }
    /* safari */
    @supports (background: -webkit-named-image(i)) {
        @media (max-width: 767px) {
            #sr-ptcounter-table tbody > tr > td:nth-child(2) {
                left: -10px;
            }
            #sr-ptcounter-table tbody>tr>td.radelnde {
                left: -12px;
            }
            #sr-ptcounter-table tbody>tr .bar_wrapper .bar_text {
                left: 80px;
            }
        }
    }


}
