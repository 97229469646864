.shop-index__section {
	position: relative;
	margin-bottom: 0px;
}
.shop-index__section.alt {
	background-color: #f5f5f5;
	padding-top: 60px;
	padding-bottom: 60px;
	border-width: 1px 0 1px 0;
	border-style: solid;
	border-color: rgba(0, 0, 0, 0.05);
}
.shop-index__heading {
	margin-top: 0;
	margin-bottom: 60px;
	+ {
		p {
			margin-top: -50px;
			margin-bottom: 60px;
			color: #777777;
		}
	}
}
.shop__slideshow {
	.carousel-inner {
		.item__container {
			display: table;
			height: 100%;
			width: 100%;
			.item-container__inner {
				display: table-cell;
				vertical-align: middle;
				padding-top: 45px;
				padding-left: 30px;
				padding-right: 30px;
			}
		}
		.item {
			width: 100%;
			height: 666px;
		}
	}
	.shop-slideshow__heading {
		margin: 0 0 29px 0;
		font-size: 56px;
		line-height: 61px;
		color: white;
	}
	.shop-slideshow__subheading {
		margin-bottom: 30px;
		font-size: 15px;
		line-height: 22px;
		color: white;
	}
	.shop-slideshow__heading_dark {
		margin: 0 0 20px 0;
		font-size: 46px;
		line-height: 1.2;
		color: #454545;
	}
	.shop-slideshow__subheading_dark {
		margin-bottom: 30px;
		font-size: 20px;
		line-height: 1.5;
		color: #454545;
	}
	.btn {
		border: 2px solid currentColor;
		border-radius: 0;
		font-weight: 500;
		&:hover {
			color: #343434;
			background-color: white;
		}
		&:focus {
			color: #343434;
			background-color: white;
		}
	}
	.shop-slideshow__btn {
		color: white;
	}
	.shop-slideshow__btn_dark {
		color: #454545;
	}
	.carousel-indicators {
		li {
			display: inline-block;
			height: 6px;
			width: 6px;
			margin: 2px 6px 2px 6px;
			text-indent: -999px;
			cursor: pointer;
			background-color: #fff;
			border-radius: 12px;
		}
		li.active {
			height: 10px;
			width: 10px;
			margin: 2px 6px 0 6px;
			background-color: #fff;
		}
	}
	&:hover {
		.shop-slideshow__control {
			opacity: .3;
		}
	}
}
.shop-slideshow__control {
	display: block;
	position: absolute;
	top: 50%;
	padding-top: 45px;
	left: 10px;
	width: 30px;
	height: 70px;
	opacity: 0;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	-webkit-transition: opacity .3s;
	-o-transition: opacity .3s;
	transition: opacity .3s;
	&:hover {
		opacity: 1 !important;
	}
}
.shop-slideshow__control[data-slide="next"] {
	left: auto;
	right: 10px;
}
.gewinne-slideshow__control {
	display: block;
	position: absolute;
	top: 50%;
	padding-top: 30px;
	left: 15px;
	width: 30px;
	height: 70px;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	&:hover {
		opacity: 1 !important;
	}
}
.gewinne-slideshow__control[data-slide="next"] {
	left: auto;
	right: 10px;
}
.carousel-fade {
	.carousel-control {
		z-index: 2;
	}
}
section.slider {
	padding: 0;
	position: relative;
	color: #fefefe;
	.searchbox {
		height: 220px;
		width: 830px;
		position: relative;
		top: -17px;
		padding: 10px 100px;
		margin: 0 auto -100px;
		background: #0E4761;
		.input-group {
			input {
				height: 50px;
				line-height: 0;
				border-top-left-radius: 50px;
				border-bottom-left-radius: 50px;
				padding-left: 30px;
				margin: 20px 0;
				background-color: #fff;
			}
			button {
				height: 50px;
				line-height: 0;
				border-top-right-radius: 50px;
				border-bottom-right-radius: 50px;
			}
		}
		h3 {
			text-align: center;
		}
	}
}
.fade-carousel {
	.carousel-inner {
		.item {
			height: 100%;
			.hero {
				opacity: 0;
				transition: 1s opacity ease-in-out .1s;
			}
		}
		.item.active {
			.hero {
				opacity: 1;
				transition: 1s opacity ease-in-out .1s;
			}
		}
	}
	.carousel-indicators {
		bottom: 110px;
		>li {
			height: 6px;
			width: 6px;
			margin: 0 2px;
			background-color: white;
			border-color: white;
			opacity: .7;
		}
		>li.active {
			height: 10px;
			width: 10px;
			margin-bottom: -2px;
			opacity: 1;
		}
	}
	.hero {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 3;
		padding: 0;
		color: #fefefe;
		text-align: left;
		text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.75);
		transform: translate3d(-50%, -50%, 0);
		h1 {
			font-size: 55px;
			line-height: 1.1;
			font-weight: 400;
			margin: 0 0 30px;
		}
		p {
			font-size: 15px;
			line-height: 1.5;
			font-weight: 400;
			max-width: 500px;
		}
	}
	.carousel-control {
		width: 25px;
		opacity: 0;
		background-image: none;
		&:hover {
			opacity: .6;
		}
		svg {
			fill: #ffffff;
			height: 100%;
			width: 100%;
			padding: 50% 0;
		}
	}
	.carousel-control.left {
		left: 2%;
		text-align: left;
	}
	.carousel-control.right {
		right: 2%;
		text-align: right;
	}
	.slides {
		.slide {
			height: 660px;
			background-size: cover;
			background-position: top center;
			background-repeat: no-repeat;
		}
	}
}
@media (max-width: 767px) {
	.shop__slideshow {
		.carousel-inner {
			.item {
				height: 400px;
				padding: 0;
				text-align: center;
			}
		}
		.shop-slideshow__heading {
			margin: 0 0 15px 0;
			font-size: 28px;
			line-height: 33px;
		}
		.shop-slideshow__subheading {
			font-size: 14px;
			line-height: 20px;
		}
	}
	section.slider {
		.searchbox {
			height: 34vh;
			width: 100%;
			padding: 10px 30px;
			margin: 0 auto;
			top: 0;
			h3 {
				text-align: left;
			}
			.input-group {
				display: block;
				input {
					height: 40px;
					margin: 5px 0 20px;
					border-radius: 40px;
				}
			}
		}
	}
	.fade-carousel {
		.hero {
			h1 {
				font-size: 30px;
				line-height: 1.1;
			}
		}
		.slides {
			.slide {
				height: calc( 66vh - 50px);
			}
		}
	}
}
@media (max-width: 1024px) {
	.shop__slideshow {
		.shop-slideshow__heading {
			font-size: 50px;
			line-height: 55px;
		}
	}
}
@media (min-width: 1023px) and (max-width: 1024px) {
	.shop__slideshow {
		.shop-slideshow__heading {
			margin-top: 250px;
		}
	}
}
@media all and (transform-3d) {
	.carousel-fade {
		.carousel-inner {
			>.item.next {
				opacity: 0;
				-webkit-transform: translate3d(0, 0, 0);
				transform: translate3d(0, 0, 0);
			}
			>.item.active.right {
				opacity: 0;
				-webkit-transform: translate3d(0, 0, 0);
				transform: translate3d(0, 0, 0);
			}
			>.item.prev {
				opacity: 0;
				-webkit-transform: translate3d(0, 0, 0);
				transform: translate3d(0, 0, 0);
			}
			>.item.active.left {
				opacity: 0;
				-webkit-transform: translate3d(0, 0, 0);
				transform: translate3d(0, 0, 0);
			}
			>.item.next.left {
				opacity: 1;
				-webkit-transform: translate3d(0, 0, 0);
				transform: translate3d(0, 0, 0);
			}
			>.item.prev.right {
				opacity: 1;
				-webkit-transform: translate3d(0, 0, 0);
				transform: translate3d(0, 0, 0);
			}
			>.item.active {
				opacity: 1;
				-webkit-transform: translate3d(0, 0, 0);
				transform: translate3d(0, 0, 0);
			}
		}
	}
}
@media (-webkit-transform-3d) {
	.carousel-fade {
		.carousel-inner {
			>.item.next {
				opacity: 0;
				-webkit-transform: translate3d(0, 0, 0);
				transform: translate3d(0, 0, 0);
			}
			>.item.active.right {
				opacity: 0;
				-webkit-transform: translate3d(0, 0, 0);
				transform: translate3d(0, 0, 0);
			}
			>.item.prev {
				opacity: 0;
				-webkit-transform: translate3d(0, 0, 0);
				transform: translate3d(0, 0, 0);
			}
			>.item.active.left {
				opacity: 0;
				-webkit-transform: translate3d(0, 0, 0);
				transform: translate3d(0, 0, 0);
			}
			>.item.next.left {
				opacity: 1;
				-webkit-transform: translate3d(0, 0, 0);
				transform: translate3d(0, 0, 0);
			}
			>.item.prev.right {
				opacity: 1;
				-webkit-transform: translate3d(0, 0, 0);
				transform: translate3d(0, 0, 0);
			}
			>.item.active {
				opacity: 1;
				-webkit-transform: translate3d(0, 0, 0);
				transform: translate3d(0, 0, 0);
			}
		}
	}
}
@media (max-width: 991px) {
	section.slider {
		.searchbox {
			height: 200px;
			width: 100%;
			top: 0;
			margin: 0 auto;
		}
	}
	.fade-carousel {
		.carousel-indicators {
			bottom: 10px;
		}
		.hero {
			position: absolute;
			width: 100%;
			padding: 24px;
		}
		.slides {
			.slide {
				height: 480px;
			}
		}
	}
}
