.km-buch {

    #result-grid-squared {
        height: 1080px;
        width: 1080px;
        position: absolute;
        left: -10000px;
        top: 0;
        .sr-icon-grid {
            li {
                p {
                    font-size: 32px;
                    &:before {
                        font-size: 90px !important;
                        margin-bottom: 10px;
                    }
                }
            }
            h3 {
                font-size: 65px;
                padding-top: 50px;
            }
        }
    }
    #result-grid-rectangle {
        height: 1920px;
        width: 1080px;
        position: absolute;
        left: -12000px;
        top: 0;
        h2 {
            font-size: 52px;
            font-weight: bold;
            text-align: center;
            margin-bottom: 150px;
        }
        .sr-icon-grid {
            li {
                p {
                    font-size: 32px;
                    &:before {
                        font-size: 90px !important;
                        margin-bottom: 10px;
                    }
                }
            }
            h3 {
                font-size: 65px;
                padding-top: 50px;
            }
        }
    }
    .nav-pills {
        .btn {
            padding-bottom: 8px;
            padding-top: 8px;
            font-size: 11px !important;
            color: #fff;
        }
        .active {
            .btn {
                border-color: #8CB82B !important;
                font-weight: 600;
            }
        }
    }
    h4 {
        font-size: 15px;
    }
    .space-left {
        margin-left: 4px;
    }
    #socialLinks {
        >li {
            list-style-type: none;
        }
        margin-left: 20px;
        display: flex;
        min-height: 178px;
        button {
            align-self: flex-end;
        }
    }
    #current-results {
        background-color: #0A3547;
        color: #FFF;
        font-size: 12px;
        transform: scale(0.65);
        -moz-transform: scale(0.65);
        margin-top: -50px;
        margin-left: -54px;
        padding-right: 39px;
        .sr-icon-grid {
            li {
                h3 {
                    min-height: 25px;
                }
                p {
                    max-width: 90%;
                }
            }
        }
    }
    #results-grid {
        h2 {
            margin-bottom: 15px;
        }
    }
    .m--20 {
        margin-left: -20px !important;
    }
    .rk-compat-2017 {
        button {
            color: #fff;
        }
    }
    .ct-grid {
        stroke: rgba(255,255,255,0.2);
    }
    .toggle-text {
        font-weight: normal;
    }
    .ct-chart {
        max-height: 370px;
        overflow: hidden;
    }
    .toggle-dark {
        border: 1px solid #7EC927;
    }
    .profile-text-small {
        padding-left: 3px;
    }
    .panel-group {
        .panel-heading {
            >a {
                &:after {
                    opacity: 0.5;
                    content: "\e942" !important;
                    font-size: 10px;
                }
            }
            >.collapsed {
                &:after {
                    content: "\e941" !important;
                    font-size: 10px;
                }
            }
        }
        .panel-default {
            &:last-of-type {
                >.panel-heading {
                    border-bottom: 0px solid #052836;
                }
            }
        }
    }
    .panel-heading {
        i {
            color: #FFF;
            transition: color 0.5s ease;
        }
        a.collapsed {
            i {
                color: #0086C5;
            }
        }
    }
    .week-mobile {
        position: absolute;
        left: 6px;
        bottom: -30px;
        font-size: 9px;
        font-weight: 300;
    }
    .pt-0 {
        padding-top: 0px !important;
    }
    .modal-dialog {
        .modal-content {
            .modal-header {
                .close {
                    display: none;
                }
            }
        }
    }
    .char-count {
        color: #454545;
        font-size: 11px;
        line-height: 17px;
        letter-spacing: 0.3px;
    }

    #sr-create-cancel {
        a {
            color: rgba(254, 254, 254, 0.8);
        }
    }
    #sr-entry-form-message {
        font-family: "Roboto", sans-serif !important;
        font-size: 12pt;
        font-weight: normal !important;
        line-height: 17pt !important;
        p {
            margin-top: 16px !important;
        }
        p.sr_error {
            color: #E30613 !important;
        }
    }
    .error-icon {
        &::before {
            color: #E30613;
        }
    }
    p.sr_error {
        &:before {
            color: #E30613;
        }
    }
    .sr-kmbook-highlight-blue {
        label {
            color: #0086c5 !important;
        }
        input {
            color: #0086c5 !important;
        }
    }
    #sr-img-dl-modal {
        label {
            font-weight: 400;
            margin-left: 8px;
        }
        .preview {
            margin-left: 28px;
            margin-top: 7px;
            label {
                margin: 0;
            }
            img {
                cursor: pointer;
                max-width: 110px;
                filter: drop-shadow(0px 0px 10px rgba(0,0,0,0.3));
                -webkit-filter: drop-shadow(0px 0px 10px rgba(0,0,0,0.3));
            }
        }
    }
    .modal-header {
        padding-top: 17px;
    }
    #sr-img-dl-modal.modal {
        .checkbox {
            input[type="checkbox"] {
                &:checked {
                    + {
                        label {
                            &::before {
                                content: "\f00c";
                                background-color: #8CB82B;
                                border: 1px solid #8CB82B;
                            }
                        }
                    }
                }
            }
            label {
                &::before {
                    position: absolute;
                    left: -2px;
                    top: 5px;
                    content: "";
                    color: #fff;
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 15px;
                    border-radius: 2px;
                    width: 16px;
                    height: 16px;
                    line-height: 14px;
                    margin-top: -2px;
                    background-color: transparent;
                    border: 1.5px solid #b5b5b5;
                    text-align: center;
                }
            }
        }
        .mobile-btn {
            display: none;
        }
    }
    .modal {
        .btn-secondary {
            padding: 9px 18px;
            height: 40px;
        }
    }
    .one-line {
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
    }
    .sr-icon-grid {
        .tile-1 {
            margin: 0 5px 5px 0;
        }
        .tile-2 {
            margin: 0 0 5px 5px;
        }
        .tile-3 {
            margin: 5px 5px 0 0;
        }
        .tile-4 {
            margin: 5px 0 0 5px;
        }
    }
    #modalLabel-jpg {
        color: #454545;
        font-size: 20px;
    }
    #modalLabel-fb {
        color: #454545;
        font-size: 20px;
    }
    #modalLabel-inst {
        color: #454545;
        font-size: 20px;
    }
    #modalLabel-twitter {
        color: #454545;
        font-size: 20px;
    }
    .modal-body {
        color: #454545;
    }
    .btn-secondary {
        background-color: transparent;
        border: 1px solid #454545;
        color: #454545;
    }
    .chat-messages {
        .message {
            a {
                color: #fff;
                text-decoration: underline;
                &:hover {
                    color: #fff;
                    text-decoration: underline;
                }
            }
        }
    }
    .info-right-top {
        font-size: 16px;
        position: absolute;
        top: 10px;
        right: 10px;
        opacity: 0.5;
    }
    #askDeleteOkay {
        background-color: #E30613;
        border-color: #E30613;
    }

    @media (min-width: 768px) and (max-width: 991px) {
        #current-results {
            margin-left: -50px;
        }
    }
    @media (max-width: 767px) {
        #current-results {
            transform: scale(1);
            -moz-transform: scale(1);
            margin-top: 0;
            margin-left: 0;
            padding-right: 15px;
        }
        #socialLinks {
            margin-left: 0;
            min-height: unset;
            margin-top: 30px;
        }
        .xs-pt-0 {
            padding-top: 0px !important;
        }
        .xs-pl-0 {
            padding-left: 0px !important;
        }
        .xs-plr-0 {
            padding-left: 0px !important;
            padding-right: 0px !important;
        }
        .xs-mt-15 {
            margin-top: 15px !important;
        }
        .xs-mt-30 {
            margin-top: 30px !important;
        }
        .xs-mt-60 {
            margin-top: 60px !important;
        }
        .xs-mt-80 {
            margin-top: 80px !important;
        }
        #sr-img-dl-modal.modal {
            .btn {
                width: 100%;
            }
            .mobile-btn {
                display: block;
            }
        }
        .ui-widget-content {
            border: 0 !important;
            background: rgba(5,40,54,0.88) !important;
            color: #fff;
            color: rgba(255,255,255,1);
            font-family: Roboto;
            font-size: 12px;
            font-weight: 300;
            letter-spacing: 0.56px;
            line-height: 16px;
            text-shadow: 0 2px 31px 0 rgba(0,0,0,0.79);
        }
        .modal-small {
            .modal-dialog {
                height: calc(100vh - 80px);
                margin: 0 0 0;
                .modal-content {
                    min-width: 375px !important;
                }
            }
        }
    }
    @media (min-width: 768px) {
        .sm-pt-210 {
            padding-top: 210px !important;
        }
    }
    @media (max-width: 991px) {
        .mobile-mlr--30 {
            margin-left: -30px !important;
            margin-right: -30px !important;
        }
    }
    @media (min-width: 992px) {
        .md-mt--10 {
            margin-top: -10px !important;
        }
        .md-m--20 {
            margin-left: -20px !important;
        }
        .md-pt-170 {
            padding-top: 170px !important;
        }
        .md-pt-220 {
            padding-top: 220px !important;
        }
    }
    @media (min-width: 1080px) {
        .lg-pt-32 {
            padding-top: 32px !important;
        }
        .panel-body {
            >.row {
                >div {
                    padding-top: 25px;
                }
            }
        }
        .lg-pt-210 {
            padding-top: 220px !important;
        }
        .lg-pt-220 {
            padding-top: 220px !important;
        }
        .modal {
            top: 22px !important;
        }
        .modal.vcenter {
            top: 180px !important;
        }
        .md-max-540 {
            .modal-content {
                max-width: 540px !important;
            }
            .modal-dialog {
                max-width: 540px !important;
            }
        }
    }

}