.page-dashboard {

    #result-grid-squared {
        height: 1080px;
        width: 1080px;
        position: absolute;
        left: -12000px;
        top: 0;
        .sr-icon-grid {
            li {
                p {
                    font-size: 32px;
                    &:before {
                        font-size: 90px !important;
                        margin-bottom: 10px;
                    }
                }
            }
            h3 {
                font-size: 65px;
                padding-top: 50px;
            }
        }
    }
    #result-grid-rectangle {
        height: 1920px;
        width: 1080px;
        position: absolute;
        left: -10000px;
        top: 0;
        h2 {
            font-size: 52px;
            font-weight: bold;
            text-align: center;
            margin-bottom: 150px;
        }
        .sr-icon-grid {
            li {
                p {
                    font-size: 32px;
                    &:before {
                        font-size: 90px !important;
                        margin-bottom: 10px;
                    }
                }
            }
            h3 {
                font-size: 65px;
                padding-top: 50px;
            }
        }
    }
    #container-results {
        position: relative;
    }
    .overlay {
        position: absolute;
        top: 60px;
        bottom: 0;
        left: 0;
        right: 0;
        height: auto;
        width: 100%;
        opacity: 0;
        transition: none;
        background-color: #323232;
        padding: 16px;
        border-radius: 7px;
        font-size: 14px;
        line-height: 22px;
    }
    .icon-share {
        position: absolute;
        top: 73px;
        right: 15px;
        cursor: pointer;
        z-index: 5;
    }
    #image-dl {
        cursor: pointer;
        &::after {
            cursor: pointer;
            background-color: #fff;
            border-radius: 50%;
            margin-left: 10px;
            padding: 5px;
            font-family: 'sr-icons', 'FontAwesome';
            content: "\e902";
            color: #000;
        }
    }
    #sr-img-dl-modal {
        label {
            font-weight: 400;
            margin-left: 8px;
        }
        .preview {
            margin-left: 28px;
            margin-top: 7px;
            label {
                margin: 0;
            }
            img {
                cursor: pointer;
                max-width: 110px;
                filter: drop-shadow(0px 0px 10px rgba(0,0,0,0.3));
                -webkit-filter: drop-shadow(0px 0px 10px rgba(0,0,0,0.3));
                max-width: 110px;
            }
        }
    }
    .modal-header {
        padding-top: 17px;
        text-align: center;
    }
    #sr-img-dl-modal.modal {
        .checkbox {
            input[type="checkbox"] {
                &:checked {
                    + {
                        label {
                            &::before {
                                content: "\f00c";
                                background-color: #8CB82B;
                                border: 1px solid #8CB82B;
                            }
                        }
                    }
                }
            }
            label {
                &::before {
                    position: absolute;
                    left: -2px;
                    top: 5px;
                    content: "";
                    color: #fff;
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 15px;
                    border-radius: 2px;
                    width: 16px;
                    height: 16px;
                    line-height: 14px;
                    margin-top: -2px;
                    background-color: transparent;
                    border: 1.5px solid #b5b5b5;
                    text-align: center;
                }
            }
        }
        .mobile-btn {
            display: none;
        }
    }
    .modal {
        .btn-secondary {
            padding: 9px 18px;
            height: 40px;
        }
    }
    .one-line {
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
    }
    .sr-icon-grid {
        .tile-1 {
            margin: 0 5px 5px 0;
        }
        .tile-2 {
            margin: 0 0 5px 5px;
        }
        .tile-3 {
            margin: 5px 5px 0 0;
        }
        .tile-4 {
            margin: 5px 0 0 5px;
        }
    }
    #container-results.share {
        .overlay {
            background: rgba(0,0,0,0.6);
            opacity: 1;
        }
    }
    #modalLabel {
        color: #454545;
        font-size: 20px;
    }
    .modal-body {
        color: #454545;
    }
    .chat-messages {
        .message {
            a {
                color: #fff;
                text-decoration: underline;
                &:hover {
                    color: #fff;
                    text-decoration: underline;
                }
            }
        }
    }
    .ui-widget-content {
        border: 0 !important;
        background: rgba(5,40,54,0.88) !important;
        color: #fff;
        color: rgba(255,255,255,1);
        font-family: Roboto;
        font-size: 13px;
        font-weight: 300;
        letter-spacing: 0.56px;
        line-height: 18px;
        text-shadow: 0 2px 31px 0 rgba(0,0,0,0.79);
    }
    .info-right-top {
        font-size: 16px;
        position: absolute;
        top: 10px;
        right: 10px;
        opacity: 0.5;
        z-index: 6;
    }
    .overview-team-info {
        margin-left: 10px;
        margin-bottom: 50px;
    }
    #hide_fb_iframe {
        display: none;
        margin-top: 20px;
        display: none;
    }
    #show_fb_iframe {
        display: none;
        margin-top: 20px;
        display: none;
    }
    .fb_text_wrapper {
        height: 100%;
        width: 100%;
        padding: 20px;
    }
    .iframe_wrapper {
        min-width: 300px;
        min-height: 300px;
        background-color: #073B50;
        border-radius: 7px;
        overflow: hidden;
        max-height: 300px;
        p {
            color: #FFFFFF;
            font-family: Roboto;
            font-size: 12px;
            font-weight: 300;
            letter-spacing: 0.33px;
            line-height: 17px;
        }
    }
    .newsfeed-wrapper {
        height: 100%;
        border-radius: 7px;
        min-height: 296px;
        max-height: 296px;
        background-color: #073B50;
        overflow-x: hidden;
        overflow-y: hidden;
        &:hover {
            overflow-y: auto !important;
        }
    }
    .newsfeed-items {
        padding: 18px;
    }
    .newsfeed-item {
        margin-bottom: 25px;
        padding: 0;
    }
    .newsfeed-date {
        font-weight: lighter;
        font-size: 12px;
        color: #AAD0D2;
        opacity: 0.7;
        line-height: 17px;
        margin-bottom: 10px;
        letter-spacing: 0.28px;
        display: flex;
    }
    .newsfeed-title {
        font-weight: normal;
        font-size: 14px;
        color: #ffffff;
        opacity: 0.5;
        line-height: 17px;
        margin-bottom: 15px;
        letter-spacing: 0.55px;
    }
    .newsfeed-text {
        font-weight: lighter;
        font-size: 14px;
        color: #ffffff;
        line-height: 20px;
        letter-spacing: 0.33px;
    }
    section.dashboard {
        >main {
            .grid-flex {
                .col {
                    >h3 {
                        + {
                            .newsfeed-msg {
                                border-radius: 15px;
                                min-height: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
    .newsfeed-header {
        display: flex;
        h3, h2 {
            margin-top: 0px;
            margin-bottom: 25px;
        }
        .chat-oval {
            position: relative;
        }
    }
    .newsfeed-date-text {
        margin-right: 10px;
    }
    #modalLabel-jpg {
        color: #454545;
        font-size: 20px;
        text-align: left;
    }
    #modalLabel-fb {
        color: #454545;
        font-size: 20px;
        text-align: left;
    }
    #modalLabel-inst {
        color: #454545;
        font-size: 20px;
        text-align: left;
    }
    #modalLabel-twitter {
        color: #454545;
        font-size: 20px;
        text-align: left;
    }
    .btn-secondary {
        background-color: transparent;
        border: 1px solid #454545;
        color: #454545;
    }
    @media (max-width: 767px) {
        .ui-widget-content {
            border: 0 !important;
            background: rgba(5,40,54,0.88) !important;
            color: #fff;
            color: rgba(255,255,255,1);
            font-family: Roboto;
            font-size: 12px;
            font-weight: 300;
            letter-spacing: 0.56px;
            line-height: 16px;
            text-shadow: 0 2px 31px 0 rgba(0,0,0,0.79);
        }
        .overview-team-info {
            margin-left: 0;
            margin-top: 20px;
        }
        #sr-img-dl-modal.modal {
            .btn {
                width: 100%;
            }
            .mobile-btn {
                display: block;
            }
        }
    }
    @media (max-width: 1080px) {
        .newsfeed-wrapper {
            overflow-y: auto !important;
        }
    }
    /* tablet */
    @media (min-width: $breakpoint-tablet-min) and (max-width: $breakpoint-tablet-max) {
        .icon-share {
            top: 65px;
        }
        .overlay {
            top: 50px;
        }
    }

    @media (max-width: $breakpoint-mobile) {   
        .icon-share {
            top: 65px;
        }
        .overlay {
            top: 50px;
        }
    }


}