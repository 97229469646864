.m01-text {
    .col.mb-25 {
        margin-bottom: 25px;
    }
}

.poster-generator {
    padding-top:30px;
    padding-bottom:45px;
    margin-bottom:20px;
    background-color:#fff;
    border:1px solid #F5F5F5;
    &.container {
        padding-left:24px;
        padding-right:24px;
    }
    label {
        font-weight:normal;
        font-size:12px;
        line-height:17px;
        margin-bottom: 0px;
    }
    .missing-field {
        color:#E30613;
        font-weight: 400;
        font-size: 12px;
    }
    .info {
        color:rgba(69, 69, 69, 0.6);
        font-size: 12px;
        line-height:17px;
        font-weight:400;
        letter-spacing:0.5px;
        padding-left:30px;
        &::before {
            color:rgb(69, 69, 69);
            opacity:.4;
            top:5px;
        }
        &.small {
            margin-left:5px;
            padding-left:0;
            &::before {
                font-size:18px;
                top:-3px;
            }
        }
        &.upload {
            margin-top:10px;
            margin-bottom:9px;
            width:215px;
        }
    }
    input[type=text], textarea {
        border:0;
        border-bottom:1px solid #979797;
        border-radius: 0;
        padding: 0 0 6px;
        outline:0;
    }
    .flyer-date-input {
        input {
            width:128px;
            line-height:17px;
            &::placeholder {
                opacity:1;
           }
        }
    }
    input {
        &.datepicker {
            width:100%;
            border:0;
            border-bottom:1px solid #979797;
            border-radius: 0;
            padding: 0 0 6px;
            outline:0;
            height:25px;
        }
    }
    .flyer-form {
        width: 290px;
        margin-right:170px;
        select {
            &.form-control {
                border:0;
                border-bottom:1px solid #979797;
                border-radius: 0;
                height:25px;
                padding:0 0 7px;
                outline:0;
            }
            &::after {
                border-color:#757575 transparent transparent transparent;
                border-style: solid;
                border-width: 5px 4px 0 4px;
                height: 0;
                left: 50%;
                margin-left: -4px;
                margin-top: -2px;
                position: absolute;
                top: 50%;
                width: 0;
            }
        }
        input[type="radio"],
        input[type="checkbox"] {
            appearance: none;
            transform: translateY(5px);
            border: 0.5px solid #b7b7b7;
            border-radius: 50%;
            background-color: #FFF;
            margin: 0 8px 8px 0;
        }
        input[type="radio"]::before,
        input[type="checkbox"]::before {
            width:15px;
            height:15px;
            display: block;
            content:"";
            border-radius: 50%;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
        }
        input[type="radio"]:checked::before,
        input[type="checkbox"]:checked::before {
            background-color: #8cb82b;
            box-shadow: inset 0px 0px 0px 2px #FFF;
            transform: scale(1);
        }
        input[type="radio"][disabled],
        input[type="checkbox"][disabled]{
            opacity:.3;
        }
        input {
            &:disabled {
                cursor:not-allowed!important;
                opacity:.5;
            }
        }
        button  {
            width:220px;
            padding: 9px 0;
        }
        button.further-upload {
            padding: 9px 0 9px 30px;
        }
        .flyer-form-item {
            .flyer-form-header {
                font-size: 14px;
                line-height:17px;
                margin-bottom: 8px;
                margin-top: 45px;
            }
            .flyer-form-radio {
                display: flex;
                .option {
                    display:flex;
                    flex-direction: row;
                    width:140px;
                    margin-top:10px;
                }
                .disabled {
                    label {
                        opacity:.5;
                    }
                }
                label {
                    &.disabled {
                        cursor:not-allowed;
                    }
                }
                .radio-wrapper {
                    display: flex;
                    flex-direction: row;
                }
                .radio-label {
                    padding-top: 4px;
                }
            }
            .flyer-form-input {
                display: flex;
                flex-direction: column;
                width:290px;
                &.url {
                    flex-direction: initial;
                }
                .user-profile-img {
                    img {
                        width:200px;
                        height:auto;
                    }
                }
                .delete-image {
                    width:18px;
                    height:18px;
                    vertical-align: super;
                    text-decoration: none;
                    line-height: 1;
                    color: rgba(0,0,0,.6);
                    cursor: pointer;
                    display:inline-block;
                    transform: translateY(-4px);
                    &::before {
                        font-size:18px;
                        color:#454545;
                        opacity:.5;
                    }
                }
                .filename {
                    display:inline-block;
                    font-size:12px;
                    line-height:17px;
                    font-weight:300;
                    width:200px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space:nowrap;
                    margin:9px 0;
                    //transform: translateY(-4px);
                }
            }
            .flyer-form-select {
                select {
                    font-size:12px;
                    line-height:17px;
                    /*appearance:none;*/
                    /* commented out, cut off in chrome
                    text-indent:-2px;
                    */
                }
            }
        }
        #website-url-prefix {
            width:95px;
        }
        #website-url {
            width:200px;
            text-transform:lowercase;
        }
        #extraImagesButton {
            padding-left:20px;
            width:222px;
            margin:9px 0;
        }
        .file-upload {
            button {
                margin:9px 0;
            }
        }
    }

    .flyer-view {
        width: 56%;
        position: relative;
        .flyer-view-header {
            font-size: 14px;
            line-height:17px;
            margin-bottom: 20px;
            margin-top: 45px;
        }
        img {
            width: 100%;
            height: 100%;
        }
        .flyer-preview-bg-img {
            position:absolute;
            height: 733px;
            box-shadow: 0 0px 3px 2px rgba(0, 0, 0, 0.25);
            .city-preview {
                color: #ffffff;
                bottom: 135px;
                position: absolute;
                width: 100%;
                padding:0 10px;
                /*height: 35px;*/
                text-align:center;
                font-weight:900;
                font-size:26px;
                text-shadow:0 0 4px #454545;
            }
            .flyer-preview-date {
                color: #ffffff;
                position: absolute;
                bottom: 105px;
                width: 100%;
                display: flex;
                justify-content: center;
                font-size: 21px;
                font-weight: 600;
                text-shadow:0 0 4px #454545;
            }
            .url-preview {
                color: #ffffff;
                bottom: 80px;
                position: absolute;
                width: 100%;
                padding:0 10px;
                text-align:center;
                font-weight: 600;
                font-size:14px;
                text-shadow:0 0 4px #454545;
            }
            .logo-preview {
                display: flex;
                bottom: 21px;
                position: absolute;

                img {
                    height: 28px;
                    max-width:30px;
                    object-fit: contain;
                }
            }
        }
        .flyer-preview-info {
            margin-top:770px;
        }
    }
    /* step 2 */
    .poster-user-data {
        label {
            width:200px;
            &.data-privacy-text {
                width:725px;
                margin-left:12px;
            }
            &.icon {
                width: auto;
            }
        }
        input[type=text] {
            width:290px;
        }
        /* custom cb */
        input[type=checkbox] {
            display: none;
        }
        input[type=checkbox] + label::before,
        input[type=checkbox] + span::before {
            width: 16px;
            height: 16px;
            background-color: #FFFFFF;
            display: table;
            content: "";
            border-radius: 2px;
            border: 1.5px solid rgba(69,69,69, 0.38);
            font-size: 13px;
            line-height: 0.8;
            margin-top:2px;
        }
        input[type=checkbox]:checked + label::before,
        input[type=checkbox]:checked + span::before {
            font-family: 'sr-icons', 'FontAwesome';
            background-color: #8cb82b;
            content: "\f00c";
            color: #FFFFFF;
            border: 1.5px solid #8cb82b;
        }
    }
    /* popover specific
    .popover {
        padding:8px 15px;
        background-color:#fff;
        border-radius:0;
        border:0;
        font-size:11px;
        line-height:15px;
        max-width:200px;
        &.top {
            margin-top:-12px;
            > .arrow {
                margin-left: 9px;
            }
            .arrow {
                border:0;
                background-color:#fff;
                &::after {
                    border-top-color:#fff;
                }
            }
        }
    }
    .popover-content {
        color:#000;
        font-size:11px;
        line-height:15px;
        font-weight:400;
        letter-spacing:0.5px;
    }*/
}
.steps {
    .btn {
        min-width:190px;
    }
    .row-buttons {
        display:block;
        padding-bottom:40px;
        .btn {
            width:190px;
            padding: 9px 0;
        }
        input {
            width:190px;
            padding: 9px 0;
        }
    }
}

.row-required {
    margin-bottom:27px;
    text-align:right;
    opacity:.5;
    font-size:12px;
    line-height:17px;
}

#picture-privacy {
    .control-label {
        font-size: 14px;
        line-height: 22px;
    }
    .checkbox {
        label::before {
            margin-right: 10px;
            width: 16px;
            height: 16px;
            line-height: 12px;
        }
        input[type="checkbox"]:checked + label::before {
            content: "";
            border-radius: 2px;
            border: 1.5px solid rgba(69, 69, 69, 0.38);
        }
        input[type="checkbox"]:checked + label::before {
            background-color: #8cb82b;
            content: "\f00c";
            color: #FFFFFF;
            border: 1.5px solid #8cb82b;
        }
    }
}

#delete-modal {
    &.poster-generator {
        .modal-dialog {
            margin:230px auto;
        }
    }
}

.modal {
    &.generator-modal {
        .modal-dialog {
            max-width:540px;
            margin: 285px auto;
            .modal-content {
                .modal-body {
                    p {
                        font-size:14px;
                        line-height:22px;
                        color:#4A4A4A;
                        font-weight:300;
                    }
                }
            }
        }
    }
}

.icon-plus {
    color:#000;
    position:absolute;
    margin-left:-30px;
    font-size:28px;
    font-weight:300;
    transform: translateY(-5px);
}

/*
//tablet
@media screen and (min-width: 768px) and (max-width: 992px) {

}

//mobile
@media (max-width: 767px) {

}
*/
