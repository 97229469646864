#pagination-previous-link, #pagination-next-link {
    background-color: transparent;
}

#pagination-previous-link svg, #pagination-next-link svg {
    fill: #454545;
}

.pagination li a, .pagination li span{
    border: none;
    box-shadow: none;
    font-size: 18px;
    color: #454545;
}

.pagination li a, .pagination li span:hover {
    background-color: transparent;
    box-shadow: none;
}

.pagination li a.active {
    color: #0086C5;
}

.pagination li a:hover, .pagination li a:focus {
    box-shadow: none;
    background-color: inherit;
    border-color: inherit;
}

.pagination {
    background-color: transparent;
}

#pagination {
    display: flex;
    justify-content: center;
}
