.media {
	margin-top: 15px;
	zoom: 1;
	overflow: hidden;
	&:first-child {
		margin-top: 0;
	}
	>.pull-right {
		padding-left: 10px;
	}
	>.pull-left {
		padding-right: 10px;
	}
}
.media-body {
	zoom: 1;
	overflow: hidden;
	width: 10000px;
	display: table-cell;
	vertical-align: top;
}
.media-object {
	display: block;
}
.media-object.img-thumbnail {
	max-width: none;
}
.media-right {
	padding-left: 10px;
	display: table-cell;
	vertical-align: top;
}
.media-left {
	padding-right: 10px;
	display: table-cell;
	vertical-align: top;
}
.media-middle {
	vertical-align: middle;
}
.media-bottom {
	vertical-align: bottom;
}
.media-heading {
	margin-top: 0;
	margin-bottom: 5px;
}
.media-list {
	padding-left: 0;
	list-style: none;
}
