.dl-horizontal {
	dd {
		&:before {
			content: " ";
			display: table;
		}
		&:after {
			content: " ";
			display: table;
			clear: both;
		}
	}
}
.container {
	&:before {
		content: " ";
		display: table;
	}
	&:after {
		content: " ";
		display: table;
		clear: both;
	}
}
.container-fluid {
	&:before {
		content: " ";
		display: table;
	}
	&:after {
		content: " ";
		display: table;
		clear: both;
	}
}
.row {
	&:before {
		content: " ";
		display: table;
	}
	&:after {
		content: " ";
		display: table;
		clear: both;
	}
}
