.gridelement.space-before-extra-small {
	margin-top: 5px;
}
.gridelement.space-after-extra-small {
	margin-bottom: 5px;
}
.gridelement.space-before-small {
	margin-top: 15px;
}
.gridelement.space-after-small {
	margin-bottom: 15px;
}
.gridelement.space-before-medium {
	margin-top: 30px;
}
.gridelement.space-after-medium {
	margin-bottom: 30px;
}
.gridelement.space-before-large {
	margin-top: 60px;
}
.gridelement.space-after-large {
	margin-bottom: 60px;
}
.gridelement.space-before-extra-large {
	margin-top: 100px;
}
.gridelement.space-after-extra-large {
	margin-bottom: 100px;
}
/* tablet */
@media (min-width: 768px) and (max-width: 992px) {
	.gridelement.space-before-extra-large {
		margin-top: 70px;
	}
	.gridelement.space-after-extra-large {
		margin-bottom: 70px;
	}
}
/* mobile */
@media (max-width: 767px) {
	.gridelement.space-before-extra-large {
		margin-top: 70px;
	}
	.gridelement.space-after-extra-large {
		margin-bottom: 70px;
	}
}