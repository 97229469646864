.nav {
	margin-bottom: 0;
	padding-left: 0;
	list-style: none;
	>li {
		position: relative;
		//display: block;
		>a {
			position: relative;
			display: block;
			padding: 10px 15px;
			&:hover {
				text-decoration: none;
				background-color: rgba(0, 0, 0, 0.05);
			}
			&:focus {
				text-decoration: none;
				background-color: rgba(0, 0, 0, 0.05);
			}
			>img {
				max-width: none;
			}
		}
	}
	>li.disabled {
		>a {
			color: #898989;
			&:hover {
				color: #898989;
				text-decoration: none;
				background-color: transparent;
				cursor: not-allowed;
			}
			&:focus {
				color: #898989;
				text-decoration: none;
				background-color: transparent;
				cursor: not-allowed;
			}
		}
	}
	.open {
		>a {
			background-color: rgba(0, 0, 0, 0.05);
			border-color: #0086C5;
			&:hover {
				background-color: rgba(0, 0, 0, 0.05);
				border-color: #0086C5;
			}
			&:focus {
				background-color: rgba(0, 0, 0, 0.05);
				border-color: #0086C5;
			}
		}
	}
	.nav-divider {
		height: 1px;
		margin: 9px 0;
		overflow: hidden;
		background-color: #e5e5e5;
	}
}
.nav-tabs {
	border-bottom: 1px solid #ddd;
	>li {
		float: left;
		margin-bottom: -1px;
		>a {
			margin-right: 2px;
			line-height: 1.428571429;
			border: 1px solid transparent;
			border-radius: 4px 4px 0 0;
			&:hover {
				border-color: #e7e7e7 #e7e7e7 #ddd;
			}
		}
	}
	>li.active {
		>a {
			color: #676767;
			background-color: #fff;
			border: 1px solid #ddd;
			border-bottom-color: transparent;
			cursor: default;
			&:hover {
				color: #676767;
				background-color: #fff;
				border: 1px solid #ddd;
				border-bottom-color: transparent;
				cursor: default;
			}
			&:focus {
				color: #676767;
				background-color: #fff;
				border: 1px solid #ddd;
				border-bottom-color: transparent;
				cursor: default;
			}
		}
	}
	.dropdown-menu {
		margin-top: -1px;
		border-top-right-radius: 0;
		border-top-left-radius: 0;
	}
}
.nav-pills {
	>li {
		float: left;
		>a {
			border-radius: 4px;
		}
		+ {
			li {
				margin-left: 1px;
			}
		}
		&.active {
			>a {
				color: #fefefe;
				background-color: #fefefe;
				border-color: #8CB82B;
				&:hover {
					color: #fefefe;
					background-color: #fefefe;
					border-color: #8CB82B;
				}
				&:focus {
					color: #fefefe;
					background-color: #fefefe;
					border-color: #8CB82B;
				}
			}
			// remove border from next element after active
			+ li {
				a {
					border-left: 0;
				}
			}
		}
	}
}
.nav-stacked {
	>li {
		float: none;
		+ {
			li {
				margin-top: 2px;
				margin-left: 0;
			}
		}
	}
}
.nav-justified {
	width: 100%;
	>li {
		float: none;
		>a {
			text-align: center;
			margin-bottom: 5px;
		}
	}
	>.dropdown {
		.dropdown-menu {
			top: auto;
			left: auto;
		}
	}
}
.nav-tabs.nav-justified {
	width: 100%;
	border-bottom: 0;
	>li {
		float: none;
		>a {
			text-align: center;
			margin-bottom: 5px;
			margin-right: 0;
			border-radius: 4px;
		}
	}
	>.active {
		>a {
			border: 1px solid #ddd;
			&:hover {
				border: 1px solid #ddd;
			}
			&:focus {
				border: 1px solid #ddd;
			}
		}
	}
}
.nav-tabs-justified {
	border-bottom: 0;
	>li {
		>a {
			margin-right: 0;
			border-radius: 4px;
		}
	}
	>.active {
		>a {
			border: 1px solid #ddd;
			&:hover {
				border: 1px solid #ddd;
			}
			&:focus {
				border: 1px solid #ddd;
			}
		}
	}
}
.tab-content {
	>.tab-pane {
		display: none;
	}
	>.active {
		display: block;
	}
}
@media (min-width: 768px) {
	.nav-justified {
		>li {
			display: table-cell;
			width: 1%;
			>a {
				margin-bottom: 0;
			}
		}
	}
	.nav-tabs.nav-justified {
		>li {
			display: table-cell;
			width: 1%;
			>a {
				margin-bottom: 0;
				border-bottom: 1px solid #ddd;
				border-radius: 4px 4px 0 0;
			}
		}
		>.active {
			>a {
				border-bottom-color: #fff;
				&:hover {
					border-bottom-color: #fff;
				}
				&:focus {
					border-bottom-color: #fff;
				}
			}
		}
	}
	.nav-tabs-justified {
		>li {
			>a {
				border-bottom: 1px solid #ddd;
				border-radius: 4px 4px 0 0;
			}
		}
		>.active {
			>a {
				border-bottom-color: #fff;
				&:hover {
					border-bottom-color: #fff;
				}
				&:focus {
					border-bottom-color: #fff;
				}
			}
		}
	}
}
