.progress {
	position: relative;
	height: 30px;
	border-radius: 15px;
	color: #fefefe;
	background-color: #063547;
}
.progress.progress-lg {
	height: 36px;
	border-radius: 18px;
	background-color: #fff;
}
.progress-bar {
	background-color: #0086C5;
}
.one-third {
	.progress-bar {
		width: 33%;
	}
}
.two-thirds {
	.progress-bar {
		width: 66%;
	}
}
.complete {
	.progress-bar {
		width: 100%;
	}
}
.bar-step {
	position: absolute;
	height: 100%;
	border-left: 2px solid currentColor;
	text-align: center;
	white-space: nowrap;
	font-size: 15px;
	line-height: 30px;
	color: currentColor;
	&:nth-of-type(1) {
		border: 0;
	}
}
.progress-lg {
	.bar-step {
		font-size: 1.63636363640px;
		line-height: 36px;
	}
}
.progress-thirds {
	.bar-step {
		width: 33%;
		&:nth-of-type(2) {
			left: 33%;
		}
		&:nth-of-type(3) {
			left: 66%;
		}
	}
}
.progress-soon {
	.bar-step {
		width: 80%;
		text-align: right;
		padding-right: 10px;
		padding-left: 10px;
	}
	.progress-bar {
		width: 80%;
		text-align: right;
		padding-right: 10px;
		padding-left: 10px;
	}
}
