.form-control {
	&::-moz-placeholder {
		color: #b0b0b0;
		opacity: 1;
	}
	&:-ms-input-placeholder {
		color: #b0b0b0;
	}
	&::-webkit-input-placeholder {
		color: #b0b0b0;
	}
}