.sr-in-app {
  padding: 0;
  &.chat {
    background-color:#073B50;
  }
  &.results {
    background-color:#073B50;
  }
  /* chat */
  .app-chat {
    background-color: #073B50;
    section {
      background-color: transparent;
      padding:0;
    }
    .chat {
      padding:0;
    }
    .btn {
      padding: 6px 12px;
    }
    input {
      &.form-control {
      height: 34px;
      border:1px solid #ccc;
      border-radius: 4px 0 0 4px;
      background-color:#fff;
      padding: 6px 12px;
      color:#555;
      }
      &::placeholder {
        font-weight:500;
      }
    }
  }
  h1, h2 {
    font-size:24px;
    line-height:30px;
  }
  #app-register {
    padding:30px 0 60px 0;
  }
  /* profile */
  .form-horizontal {
    .checkbox {
      label {
        color:#676767!important;
        &::before {
          border:1px solid #333;
        }
      }
    }
  }
  /* results */
  .tab-pane {
    padding-top:20px;
  }
  .dataTables_wrapper { 
    .dataTables_paginate {
      padding-top:80px;
    }
  }
}