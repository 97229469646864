.pagination {
	display: inline-block;
	padding-left: 0;
	margin: 20px 0;
	border-radius: 4px;
	>li {
		display: inline;
		>a {
			position: relative;
			float: left;
			padding: 6px 12px;
			margin-left: -1px;
			line-height: 1.42857143;
			color: #337ab7;
			text-decoration: none;
			background-color: #fff;
			border: 1px solid #ddd;
			&:hover {
				z-index: 2;
				color: #23527c;
				background-color: #eee;
				border-color: #ddd;
			}
			&:focus {
				z-index: 2;
				color: #23527c;
				background-color: #eee;
				border-color: #ddd;
			}
		}
		>span {
			position: relative;
			float: left;
			padding: 6px 12px;
			margin-left: -1px;
			line-height: 1.42857143;
			color: #337ab7;
			text-decoration: none;
			background-color: #fff;
			border: 1px solid #ddd;
			&:hover {
				z-index: 2;
				color: #23527c;
				background-color: #eee;
				border-color: #ddd;
			}
			&:focus {
				z-index: 2;
				color: #23527c;
				background-color: #eee;
				border-color: #ddd;
			}
		}
		&:first-child {
			>a {
				margin-left: 0;
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;
			}
			>span {
				margin-left: 0;
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;
			}
		}
		&:last-child {
			>a {
				border-top-right-radius: 4px;
				border-bottom-right-radius: 4px;
			}
			>span {
				border-top-right-radius: 4px;
				border-bottom-right-radius: 4px;
			}
		}
	}
	>.active {
		>a {
			z-index: 3;
			color: #fff;
			cursor: default;
			background-color: #337ab7;
			border-color: #337ab7;
			&:hover {
				z-index: 3;
				color: #fff;
				cursor: default;
				background-color: #337ab7;
				border-color: #337ab7;
			}
			&:focus {
				z-index: 3;
				color: #fff;
				cursor: default;
				background-color: #337ab7;
				border-color: #337ab7;
			}
		}
		>span {
			z-index: 3;
			color: #fff;
			cursor: default;
			background-color: #337ab7;
			border-color: #337ab7;
			&:hover {
				z-index: 3;
				color: #fff;
				cursor: default;
				background-color: #337ab7;
				border-color: #337ab7;
			}
			&:focus {
				z-index: 3;
				color: #fff;
				cursor: default;
				background-color: #337ab7;
				border-color: #337ab7;
			}
		}
	}
	>.disabled {
		>span {
			color: #777;
			cursor: not-allowed;
			background-color: #fff;
			border-color: #ddd;
			&:hover {
				color: #777;
				cursor: not-allowed;
				background-color: #fff;
				border-color: #ddd;
			}
			&:focus {
				color: #777;
				cursor: not-allowed;
				background-color: #fff;
				border-color: #ddd;
			}
		}
		>a {
			color: #777;
			cursor: not-allowed;
			background-color: #fff;
			border-color: #ddd;
			&:hover {
				color: #777;
				cursor: not-allowed;
				background-color: #fff;
				border-color: #ddd;
			}
			&:focus {
				color: #777;
				cursor: not-allowed;
				background-color: #fff;
				border-color: #ddd;
			}
		}
	}
}
.pagination-lg {
	>li {
		>a {
			padding: 10px 16px;
			font-size: 18px;
			line-height: 1.3333333;
		}
		>span {
			padding: 10px 16px;
			font-size: 18px;
			line-height: 1.3333333;
		}
		&:first-child {
			>a {
				border-top-left-radius: 6px;
				border-bottom-left-radius: 6px;
			}
			>span {
				border-top-left-radius: 6px;
				border-bottom-left-radius: 6px;
			}
		}
		&:last-child {
			>a {
				border-top-right-radius: 6px;
				border-bottom-right-radius: 6px;
			}
			>span {
				border-top-right-radius: 6px;
				border-bottom-right-radius: 6px;
			}
		}
	}
}
.pagination-sm {
	>li {
		>a {
			padding: 5px 10px;
			font-size: 12px;
			line-height: 1.5;
		}
		>span {
			padding: 5px 10px;
			font-size: 12px;
			line-height: 1.5;
		}
		&:first-child {
			>a {
				border-top-left-radius: 3px;
				border-bottom-left-radius: 3px;
			}
			>span {
				border-top-left-radius: 3px;
				border-bottom-left-radius: 3px;
			}
		}
		&:last-child {
			>a {
				border-top-right-radius: 3px;
				border-bottom-right-radius: 3px;
			}
			>span {
				border-top-right-radius: 3px;
				border-bottom-right-radius: 3px;
			}
		}
	}
}
