/* Compat fixes */
.rk-compat-2017 {
   button,
   input {
    color: #000;
  }

   input[type=file] {
    color: #7e7e7e;
  }

   table.dataTable {
    color: #000;
  }

  .sr-auswertung-table-2017 td {
    padding-right: 3px;
  }

   .dashboard label {
    color: #fff;
  }

   textarea,
   select {
    color: #333;
  }


  /* Fix for old checkboxes */
   .checkbox input[type="checkbox"] {
    display: none;
  }

   .checkbox label {
    padding-left: 0;
  }

   .checkbox label:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    background-color: #aaa;
    text-align: center;
    font-family: "FontAwesome";
  }

   .checkbox input[type="checkbox"]:checked + label::before {
    content: "\f00c";
  }


  /* Fix for old radio buttons */
   .radio input[type="radio"] {
    display: none;
  }

   .radio label {
    padding-left: 0;
  }

   .radio label:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 10px solid #eee;
    background-color: #333333;
  }

   .radio input[type="radio"]:checked + label:before {
    border-width: 7px;
  }


   .chosen-container .chosen-results li {
    color: #333;
    border: 0;
  }


  /* Table styles */
  /* Table used by local partner editor */
   .sr-compat-table-bright,
   .sr-compat-table-bright td,
   .sr-compat-table-bright th,
   .sr-compat-table-bright .button,
   .sr-compat-table-bright .btn-secondary {
    color: #fff !important;
    border-color: #aaa;
  }


  /* Old styles that are still widely in use */

  #sr_success, .sr_success {
    color: #73a425; /*#00c000;*/
  }

  #sr_error, .sr_error {
    color: #c00000;
    font-weight: 700;
  }

  .nobreaks {
    white-space: nowrap;
  }

  .extra_bold {
    font-weight: 700 !important;
    color: #0f70b7;
  }


  span.sr_form_feedback {
    display: inline-block;
    padding-left: 10px;
    color: #c00000;
    font-weight: 600;
  }


  span.sr_form_feedback_blue {
    display: inline-block;
    padding-left: 10px;
    color: #337ec0;
    font-weight: 600;
  }


  /* PTCounter styles */

  .ptcounter-table {
    width: 100%;
  }

  .ptcounter-table th {
    border-bottom: #c0c0c0 solid 2px;
    text-align: left;
  }

  .ptcounter-table td {
    border-bottom: #c0c0c0 solid 1px;
    text-align: left;
  }


  /* Button styles */


   button.button_big,  input.button_big,  a.button_big,  a.button_big:link {
    -moz-box-shadow: inset 0px 1px 0px 0px #c1ed9c;
    -webkit-box-shadow: inset 0px 1px 0px 0px #c1ed9c;
    box-shadow: inset 0px 1px 0px 0px #c1ed9c;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0.05, #9dce2c), color-stop(1, #8cb82b));
    background: -moz-linear-gradient(center top, #9dce2c 5%, #8cb82b 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#9dce2c', endColorstr='#8cb82b');
    background-color: #9dce2c;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    border: 1px solid #83c41a;
    display: inline-block;
    color: #ffffff;
    font-family: arial;
    font-weight: 600 !important;
    font-size: 15px;
    padding: 6px 24px;
    text-decoration: none;
    text-shadow: 1px 1px 0px #689324;

    font-family: 'Roboto', sans-serif;
    font-weight: 600 !important;
    height: auto;
  }

   button.button_big:hover,  input.button_big:hover,  a.button_big:hover {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0.05, #8cb82b), color-stop(1, #9dce2c));
    background: -moz-linear-gradient(center top, #8cb82b 5%, #9dce2c 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8cb82b', endColorstr='#9dce2c');
    background-color: #8cb82b;
    cursor: pointer;
    text-decoration: none;
    color: #ffffff;
    height: auto;
  }

   button.button_big:active,  input.button_big:active,  a.button_big:active {
    position: relative;
    top: 1px;
  }

  /*
   a.button,  a.button_red,  a.button_green {
    height: 18px;
  }


   button, input[type=button], input[type=submit] {
      height: 22px;
  }
  */

   a.button,  a.button:visited,  a.button:link {
    -moz-box-shadow: inset 0px 1px 0px 0px #ffffff;
    -webkit-box-shadow: inset 0px 1px 0px 0px #ffffff;
    box-shadow: inset 0px 1px 0px 0px #ffffff;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0.05, #ededed), color-stop(1, #dfdfdf));
    background: -moz-linear-gradient(center top, #ededed 5%, #dfdfdf 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ededed', endColorstr='#dfdfdf');
    background-color: #ededed;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    border: 1px solid #dcdcdc;
    display: inline-block;
    color: #333;
    font-family: arial;
    font-size: 13px;
    font-weight: normal;
    padding: 1px 10px;
    margin: 0;
    text-decoration: none;
    text-shadow: 1px 1px 0px #ffffff;

    font-family: 'Roboto', sans-serif;
    /* font-weight:500 !important; */
  }


   a.button_red,  a.button_red:visited,  a.button_red:link {
    -moz-box-shadow: inset 0px 1px 0px 0px #ffffff;
    -webkit-box-shadow: inset 0px 1px 0px 0px #ffffff;
    box-shadow: inset 0px 1px 0px 0px #ffffff;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0.05, #e65c5c), color-stop(1, #e62e2e));
    background: -moz-linear-gradient(center top, #e65c5c 5%, #e62e2e 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e65c5c', endColorstr='#e62e2e');
    background-color: #e65c5c;
    -webkit-border-top-left-radius: 6px;
    -moz-border-radius-topleft: 6px;
    border-top-left-radius: 6px;
    -webkit-border-top-right-radius: 6px;
    -moz-border-radius-topright: 6px;
    border-top-right-radius: 6px;
    -webkit-border-bottom-right-radius: 6px;
    -moz-border-radius-bottomright: 6px;
    border-bottom-right-radius: 6px;
    -webkit-border-bottom-left-radius: 6px;
    -moz-border-radius-bottomleft: 6px;
    border-bottom-left-radius: 6px;
    text-indent: 0;
    border: 1px solid #e62e2e;
    display: inline-block;
    color: #ffffff;
    text-shadow: 1px 1px 0px #555555;
    padding: 2px 3px;
  }

   a.button_red:hover {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0.05, #e62e2e), color-stop(1, #e65c5c));
    background: -moz-linear-gradient(center top, #e62e2e 5%, #e65c5c 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e62e2e', endColorstr='#e65c5c');
    background-color: #e62e2e;
    cursor: pointer;
    text-decoration: none;
    color: #ffffff;
  }


   a.button_green,  a.button_green:visited,  a.button_green:link {
    -moz-box-shadow: inset 0px 1px 0px 0px #c1ed9c;
    -webkit-box-shadow: inset 0px 1px 0px 0px #c1ed9c;
    box-shadow: inset 0px 1px 0px 0px #c1ed9c;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0.05, #9dce2c), color-stop(1, #8cb82b));
    background: -moz-linear-gradient(center top, #9dce2c 5%, #8cb82b 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#9dce2c', endColorstr='#8cb82b');
    background-color: #9dce2c;
    -webkit-border-top-left-radius: 6px;
    -moz-border-radius-topleft: 6px;
    border-top-left-radius: 6px;
    -webkit-border-top-right-radius: 6px;
    -moz-border-radius-topright: 6px;
    border-top-right-radius: 6px;
    -webkit-border-bottom-right-radius: 6px;
    -moz-border-radius-bottomright: 6px;
    border-bottom-right-radius: 6px;
    -webkit-border-bottom-left-radius: 6px;
    -moz-border-radius-bottomleft: 6px;
    border-bottom-left-radius: 6px;
    text-indent: 0;
    border: 1px solid #83c41a;
    display: inline-block;
    color: #ffffff;
    text-shadow: 1px 1px 0px #689324;
    padding: 2px 3px;
  }

   a.button_green:hover {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0.05, #8cb82b), color-stop(1, #9dce2c));
    background: -moz-linear-gradient(center top, #8cb82b 5%, #9dce2c 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8cb82b', endColorstr='#9dce2c');
    background-color: #8cb82b;
    cursor: pointer;
    text-decoration: none;
    color: #ffffff;
  }


   a.button_210 {
    width: 210px;
    text-align: center;
  }

  /* ORK Dropdown Menu Fix */

  .dashboard .dropdown.open .dropdown-menu {
    left: 0;
  }

  /* turns ORK Menü Button blue, no longer needed
  @media (max-width: 991px) {
      .dashboard .mobile-nav .dropdown .dropdown-toggle:hover {
      background: #125b7c !important;}
  }

  @media (max-width: 991px) {
      .dashboard .mobile-nav .dropdown .dropdown-toggle:hover {
      background: #125b7c !important;}
  }

  @media (max-width: 991px) {
      .dashboard .mobile-nav .dropdown .dropdown-toggle:active {
      background: #125b7c !important;}
  }

  @media (max-width: 991px) {
      .dashboard .mobile-nav .dropdown .dropdown-toggle:focus {
      background: #125b7c !important;}
  }
  */


  /* Probably not used anymore */


   div.yellow-box {
    background: #fffff0;
    margin: 0;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
    padding-top: 8px;
  }


   div.blue-box {
    background: #86bae3;
    color: #fff;
    margin: 0;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
    padding-top: 8px;
  }


   div.blue-box h1,
   div.blue-box h2,
   div.blue-box h3,
   div.blue-box h4,
   div.blue-box div,
   div.blue-box b,
   div.blue-box p {
    color: #fff;
  }

}
/* Fix for select boxes */
section.dashboard .rk-compat-2017 .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #fefefe !important;
}
