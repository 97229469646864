.einstellungen {
    .preview-container {
        padding-left: 30px;
    }
    .preview-title {
        padding-left: 5px;
        color: #454545;
    }
    .preview {
        height: 200px;
        width: 200px;
        border: 1px solid black;
        overflow: hidden;
        float: right;
    }
    .modal-header {
        text-align: center;
    }
    #modalLabel {
        color: #454545;
        font-size: 20px;
    }
    .privacy-check {
        min-height: 180px;
    }
    @media (max-width: 767px) {
        .user-profile-img {
            align-items: center;
            img {
                border-radius: 50%;
            }
        }
        #sr-cropping-modal {
            .preview-title {
                justify-content: center;
                margin-bottom: 10px;
            }
            .preview {
                float: none;
                margin: 0 auto;
                display: block;
                border-radius: 50%;
            }
            .cropper-container {
                max-width: 100%;
            }
        }
    }
}
