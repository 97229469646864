.jumpmarks {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 50px;
}

.jumpmarks-column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
}

.jumpmarks a {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 20px;
}

.jumpmarks a:after {
    font-family: 'sr-icons', 'FontAwesome';
    content: " \e936";
    transform: rotate(45deg);
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    margin-left: 5px
}

.jumpmarks.sidebar-menu .dropdown-toggle{
    width:100%;
}
.jumpmarks .dropdown-menu{
    width:100%;
}

.jumpmarks.sidebar-menu .dropdown-menu a {
    color: #454545;
    text-align: center;
    line-height: 2.4;
    border-bottom: 1px solid #aaa;
    margin-bottom: 0;
}

.jumpmarks.sidebar-menu .dropdown-menu a:after {
    content: '';
}

@media (max-width: 767px) {
    .jumpmarks {
        flex-direction: column;
        display: none;
    }
}
