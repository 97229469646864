.label {
	font-weight: 500;
}
.fullwidth {
	width: 100%;
}
.text-center {
	text-align: center;
}
.no-pad-left {
	padding-left: 0;
}
.no-pad-right {
	padding-right: 0;
}
.float-left {
	float: left !important;
}
.float-right {
	float: right !important;
}
.float-none {
	float: none !important;
}
.hidden {
	display: none !important;
	visibility: hidden !important;
}
input[type="hidden"] {
	display: none !important;
	visibility: hidden !important;
}
.optimize-png {
	image-rendering: -webkit-optimize-contrast;
	image-rendering: -moz-crisp-edges;
}
.center {
	margin: auto;
	width: 50%;
}
.xlg_text {
	font-size: 36px;
	line-height: 36px;
}
.lg_text {
	font-size: 26px;
	line-height: 36px;
}
.md_text {
	font-size: 19px;
	line-height: 36px;
}
.sm_text {
	font-size: 12px;
}
.xs_text {
	font-size: 11px;
}
.text-lg {
	font-size: 18px;
}
.text-md {
	font-size: 16px;
}
.text-sm {
	font-size: 12px;
}
.text-xs {
	font-size: 11px;
}
.underline {
	padding-bottom: 0;
	border-bottom: 1px solid #454545;
	display: inline-block;
}
.white {
	color: #fff;
}
.grey {
	color: #454545;
}
.blue {
	color: #1D71BA;
}
.brand-color {
	color: #1D71BA;
}
.greyblue {
	color: #AAD0D2;
}
.lightblue {
	color: #2481B5;
}
.light-text {
	font-weight: 300;
	a {
		font-weight: 500;
	}
}
.thin-text {
	font-weight: 100;
}
.justify-text {
	text-align: justify;
}
.bg_middleblue {
	background-color: #0E4761;
}
.md-opacity {
	opacity: 0.87;
}
.low-opacity {
	opacity: 0.7;
}
.center-block {
	float: none;
}
.center-table {
	display: table;
	margin: 0 auto;
}
.mt--5 {
	margin-top: -5px !important;
}
.mt-4 {
	margin-top: 4px !important;
}
.mt-5 {
	margin-top: 5px !important;
}
.mt-8 {
	margin-top: 8px !important;
}
.mt-10 {
	margin-top: 10px !important;
}
.mt-15 {
	margin-top: 15px !important;
}
.mt-20 {
	margin-top: 20px !important;
}
.mt-30 {
	margin-top: 30px !important;
}
.mt-40 {
	margin-top: 40px !important;
}
.mt-45 {
	margin-top: 45px !important;
}
.mt-50 {
	margin-top: 50px !important;
}
.mt-60 {
	margin-top: 60px !important;
}
.mt-80 {
	margin-top: 80px !important;
}
.mb-4 {
	margin-bottom: 4px !important;
}
.mb-5 {
	margin-bottom: 5px !important;
}
.mb-15 {
	margin-bottom: 15px !important;
}
.mb-20 {
	margin-bottom: 20px !important;
}
.mb-30 {
	margin-bottom: 30px !important;
}
.mb-45 {
	margin-bottom: 45px !important;
}
.mlr-15 {
	margin-left: 15px;
	margin-right: 15px;
}
.mlr-30 {
	margin-left: 30px;
	margin-right: 30px;
}
.mlr-60 {
	margin-left: 60px;
	margin-right: 60px;
}
.mlr-80 {
	margin-left: 80px;
	margin-right: 80px;
}
.ml-10 {
	margin-left: 10px !important;
}
.ml-15 {
	margin-left: 15px !important;
}
.mr-15 {
	margin-right: 15px !important;
}
.pt-5 {
	padding-top: 5px !important;
}
.pt-10 {
	padding-top: 10px !important;
}
.pt-13 {
	padding-top: 13px !important;
}
.pt-15 {
	padding-top: 15px !important;
}
.pt-20 {
	padding-top: 20px !important;
}
.pt-25 {
	padding-top: 25px !important;
}
.pt-30 {
	padding-top: 30px !important;
}
.pt-40 {
	padding-top: 40px !important;
}
.pt-45 {
	padding-top: 45px !important;
}
.pt-60 {
	padding-top: 60px !important;
}
.pb-5 {
	padding-bottom: 5px !important;
}
.pb-10 {
	padding-bottom: 10px !important;
}
.pb-15 {
	padding-bottom: 15px !important;
}
.pb-20 {
	padding-bottom: 20px !important;
}
.pb-25 {
	padding-bottom: 25px !important;
}
.pb-30 {
	padding-bottom: 30px !important;
}
.pb-40 {
	padding-bottom: 40px !important;
}
.pb-45 {
	padding-bottom: 45px !important;
}
.pb-60 {
	padding-bottom: 60px !important;
}
.p-5-0 {
	padding: 5px 0 5px 5px;
}
.p-0-5 {
	padding: 5px 5px 5px 0;
}
.p-5 {
	padding: 5px;
}
.p-15 {
	padding: 15px;
}
.p-30 {
	padding: 30px;
}
.pl-0 {
	padding-left: 0px !important;
}
.pl-5 {
	padding-left: 5px !important;
}
.pl-10 {
	padding-left: 10px !important;
}
.pl-15 {
	padding-left: 15px !important;
	padding-left: 15px !important;
}
.pr-0 {
	padding-right: 0px !important;
}
.plr-15 {
	padding: 0 15px 0 15px;
}
.plr-20 {
	padding: 0 20px 0 20px;
}
.plr-25 {
	padding: 0 25px 0 25px;
}
.plr-30 {
	padding: 0 30px 0 30px;
}
.plr-60 {
	padding: 0 60px 0 60px;
}
.plr-80 {
	padding: 0 80px 0 80px;
}
.font-size-11 {
	font-size: 11px !important;
}
.font-size-12 {
	font-size: 12px !important;
}
.font-weight-300 {
	font-weight: 300 !important;
}
.font-weight-bold {
	font-weight: bold !important;
}
.mw-50 {
	max-width: 50px;
}
.mw-100 {
	max-width: 100px;
}
.mw-200 {
	max-width: 200px;
}
.mw-300 {
	max-width: 300px;
}
.mw-400 {
	max-width: 400px;
}
.mw-500 {
	max-width: 500px;
}
.full-width {
	width: 100%;
}
.no-bullets {
	list-style: none;
}
.lh-17 {
	line-height: 17px !important;
}
.flex-container {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flexbox;
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-start;
}
.flex-container.centered {
	align-items: center;
	justify-content: center;
}
.flex-center {
	align-items: center;
	justify-content: center;
}
.align-center {
	align-items: center;
}
.justify-center {
	justify-content: center;
}
.justify-between {
	justify-content: space-between;
}
.justify-end {
	justify-content: flex-end;
}
.flex-row {
	display: flex;
	margin: 0;
}
.flex-row.justify-between {
	>[class*="col"] {
		margin-left: auto;
		&:first-child {
			margin-left: 0;
		}
	}
}
.flex-column {
	display: flex;
	flex-direction: column;
}
.has_no-flexbox {
	.flex-column {
		display: block;
		overflow: hidden;
	}
}
.no-gutters {
	>.col {
		padding-right: 0;
		padding-left: 0;
	}
	>[class*="col-"] {
		padding-right: 0;
		padding-left: 0;
	}
}
.profile-small {
	background-size: cover;
	-webkit-background-size: cover;
	background-position: center center;
	border-radius: 50%;
	margin: auto;
	text-align: center;
	width: 40px;
	height: 40px;
	min-width: 40px;
}
.img-sm {
	width: 100px;
	height: auto;
}
.vertical-align-top {
	vertical-align: top!important;
}
.vertical-align-middle {
	vertical-align: middle!important;
}
.vertical-align-bottom {
	vertical-align: bottom!important;
}
.vertical-align-baseline {
	vertical-align: baseline!important;
}
@media (max-width: 767px) {
	.flex-row {
		flex-direction: column;
	}
	.xs-no-gutters {
		width: 100%;
		padding: 0;
		margin: 0;
	}
}
@media (max-width: 991px) {
	.sm-no-gutters {
		width: 100%;
		padding: 0;
		margin: 0;
	}
}
@media (max-width: 479px) {
	.profile-small {
		margin-left: 5px;
	}
}
