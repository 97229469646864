.embed-responsive {
	position: relative;
	display: block;
	height: 0;
	padding: 0;
	overflow: hidden;
	.embed-responsive-item {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		height: 100%;
		width: 100%;
		border: 0;
	}
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		height: 100%;
		width: 100%;
		border: 0;
	}
	embed {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		height: 100%;
		width: 100%;
		border: 0;
	}
	object {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		height: 100%;
		width: 100%;
		border: 0;
	}
	video {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		height: 100%;
		width: 100%;
		border: 0;
	}
}
.embed-responsive-16by9 {
	padding-bottom: 56.25%;
}
.embed-responsive-4by3 {
	padding-bottom: 75%;
}
