.panel-default {
	>.panel-heading {
		color: #454545;
		background-color: #f5f5f5;
		border-color: #ddd;
		+ {
			.panel-collapse {
				>.panel-body {
					border-top-color: #ddd;
				}
			}
		}
		.badge {
			color: #f5f5f5;
			background-color: #454545;
		}
	}
	>.panel-footer {
		+ {
			.panel-collapse {
				>.panel-body {
					border-bottom-color: #ddd;
				}
			}
		}
	}
}
.panel-primary {
	>.panel-heading {
		color: #fff;
		background-color: #286AA6;
		border-color: #286AA6;
		+ {
			.panel-collapse {
				>.panel-body {
					border-top-color: #286AA6;
				}
			}
		}
		.badge {
			color: #286AA6;
			background-color: #fff;
		}
	}
	>.panel-footer {
		+ {
			.panel-collapse {
				>.panel-body {
					border-bottom-color: #286AA6;
				}
			}
		}
	}
}
.panel-success {
	>.panel-heading {
		color: #3c763d;
		background-color: #dff0d8;
		border-color: #d6e9c6;
		+ {
			.panel-collapse {
				>.panel-body {
					border-top-color: #d6e9c6;
				}
			}
		}
		.badge {
			color: #dff0d8;
			background-color: #3c763d;
		}
	}
	>.panel-footer {
		+ {
			.panel-collapse {
				>.panel-body {
					border-bottom-color: #d6e9c6;
				}
			}
		}
	}
}
.panel-info {
	>.panel-heading {
		color: #31708f;
		background-color: #d9edf7;
		border-color: #bce8f1;
		+ {
			.panel-collapse {
				>.panel-body {
					border-top-color: #bce8f1;
				}
			}
		}
		.badge {
			color: #d9edf7;
			background-color: #31708f;
		}
	}
	>.panel-footer {
		+ {
			.panel-collapse {
				>.panel-body {
					border-bottom-color: #bce8f1;
				}
			}
		}
	}
}
.panel-warning {
	>.panel-heading {
		color: #8a6d3b;
		background-color: #fcf8e3;
		border-color: #faebcc;
		+ {
			.panel-collapse {
				>.panel-body {
					border-top-color: #faebcc;
				}
			}
		}
		.badge {
			color: #fcf8e3;
			background-color: #8a6d3b;
		}
	}
	>.panel-footer {
		+ {
			.panel-collapse {
				>.panel-body {
					border-bottom-color: #faebcc;
				}
			}
		}
	}
}
.panel-danger {
	>.panel-heading {
		color: #a94442;
		background-color: #f2dede;
		border-color: #ebccd1;
		+ {
			.panel-collapse {
				>.panel-body {
					border-top-color: #ebccd1;
				}
			}
		}
		.badge {
			color: #f2dede;
			background-color: #a94442;
		}
	}
	>.panel-footer {
		+ {
			.panel-collapse {
				>.panel-body {
					border-bottom-color: #ebccd1;
				}
			}
		}
	}
}
