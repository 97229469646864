@media (max-width: 991px) {
	.mobile-nav {
		.dropdown {
			padding: 0 10px;
			margin: 0 auto 25px auto;
			.dropdown-toggle {
				background: white;
				color: #454545;
				position: relative;
				width: 100%;
				margin-top: 50px;
				padding: 13px;
				border: 0;
				font-size: 14px;
				border-radius: 4px;
				border-bottom: 1px solid transparent;
				&:hover {
					background: white !important;
				}
				&:active {
					background: white !important;
				}
				&:focus {
					background: white !important;
				}
			}
			.dropdown-menu {
				li {
					a {
						background: white;
						color: #454545;
						text-align: center;
						justify-content: center;
						line-height: 2.4;
						border-bottom: 1px solid #aaa;
						&:hover {
							color: #0086C5;
						}
					}
					margin: 0 !important;
				}
				background: white;
				width: 370px;
				padding: 0;
				border: 0;
				border-radius: 0 0 4px 4px;
				margin: 0 auto;
				.admin-link {
					background-color: #8CB82B;
					border-color: #a6d343;
					color: #fff;
				}
			}
		}
		.dropdown.open {
			.dropdown-toggle {
				background: white;
				border-color: #aaa;
				&::after {
					content: "\e5c7";
				}
			}
		}
	}
	.social-links {
		font-size: 12px;
		/*
		nav {
			li {
				a {
					height: 22px;
					padding: 4px;
				}
			}
		}
		*/
	}
	.social-icons {
		li {
			font-size: 14px;
		}
	}
}
@media (max-width: 991px) and (max-width: 767px) {
	.mobile-nav {
		.dropdown {
			.dropdown-toggle {
				width: 100%;
			}
			.dropdown-menu {
				margin: 0 15px;
				width: 90vw;
				width: -webkit-fill-available;
				width: -moz-available;
			}
		}
	}
}
@media (max-width: 767px) {
	.mobile-nav {
		.dropdown {
			max-width: 100%;
			padding: 0 15px;
			.dropdown-toggle {
				margin-top: 30px;
			}
		}
	}
}
@media (max-width: 480px) {
	.mobile-nav {
		.dropdown {
			max-width: 320px;
			margin: 0 auto;
			max-width: 320px;
			margin: 0 auto;
		}
	}
}
.generate-dropdown {
	select {
		display: none;
	}
}
@media (max-width: 992px) {
	.generate-dropdown {
		li {
			display: none;
		}
		select {
			height: 30px;
			width: 50%;
			display: block;
			margin: 0 auto;
			color: #454545;
			background: #fefefe;
		}
	}
}
