.partner-lokal {
    h2 {
        &.row-headline {
            text-align: left;
            margin-top: 35px;
            margin-bottom: 4px;
        }
    }
    .col-select-city,
    .col-select-fstate {
        margin-bottom: 30px;
    }
    select {
        width: 80%;
    }
    label {
        font-weight: 400;
    }
    .grid-wrapper {
        display: flex;
        flex-wrap: wrap;
    }
    .grid-item {
        margin: 20px 0;
        /* 100%/6logos */
        width: 16.66%;
        display: flex;
        justify-content: center;
        img {
            height: 80px;
            max-width: 140px;
            object-fit: contain;
            filter: grayscale(100%);
            opacity: 0.6;
            &:hover {
                filter: grayscale(0%);
                opacity: 1;
            }
        }
    }
}

/* tablet and up */
@media (min-width: 768px) and (max-width: 992px) {
    .partner-lokal {
        .grid-item {
            width: 25%;
            img {
                height: 70px;
                max-width: 110px;
                filter: grayscale(0%);
            }
        }
    }
}

/* mobile */
@media (max-width: 767px) {
    .partner-lokal {
        .grid-item {
            margin: 18px 0;
            width: 50%;
            img {
                height: 90px;
                max-width: 90%;
                filter: grayscale(0%);
            }
        }
        .col-select-fstate {
            margin-bottom: 45px;
        }
        .col-select-city {
            margin-bottom: 35px;
        }
    }
}
