/* all stylings blog related: blog post, blog list, blog editor ... */

/* overview list */
.blog-overview-headline {
    text-align: center;
    margin-bottom: 40px;
}
.blog.blog-teaser .column-text {
    width:37%;
}

.blog-overview {
    .sorting-wrapper {
        margin-top: 10px;
    }
    .blog-sorting {
        display:flex;
        background-color: transparent;
        border: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        padding-right: 22px;
        cursor: pointer;
        &.newest::after {
            font-family: 'sr-icons', 'FontAwesome';
            content: "\e313";
            margin-left: 10px;
            font-size: 25px;
            vertical-align: middle;
        }
        .sorting-title {
            margin-right: 5px;
        }
        .sorting-icon {
            font-size: 9px;
            cursor: pointer;
            margin-left: 10px;
            margin-top: 6px;
        }
        .asc {
            margin-bottom: 10px;
            transform: rotate(180deg);
        }
        .oldest::after {
            font-family: 'sr-icons', 'FontAwesome';
            content: "\e316";
            margin-left: 10px;
            font-size: 25px;
            vertical-align: middle;
        }
    }

    .star-item-wrapper {
        display: flex;
        justify-content: space-between;
    }
    .search-field-wrapper {
        margin-bottom: 22px;
    }
    .input-search-field {
        margin-bottom: 30px;
    }
    .info {
        letter-spacing: 0.5px;
        opacity: .6;
        font-size: 12px;
        line-height: 20px;
        color: #454545;
        font-weight: 400;
        &::before {
            color: #454545;
            opacity: .6;
        }
    }
    .tags {
        display: flex;
        justify-content: end;
        flex-wrap: wrap;
        margin-bottom: 5px;
        .tag-item {
            letter-spacing: 0.28px;
            border: 1px solid #262626;
            border-radius: 20px;
            padding: 9px 30px;
            margin-bottom: 5px;
            min-height: 40px;
            text-align: center;
            vertical-align: middle;
            display: inline-block;
            margin-left: 10px;
            margin-top: 5px;
            .tag-delete {
                display: inline-block;
                cursor: pointer;
                margin-left: 24px;
                &::after {
                    font-family: 'sr-icons', 'FontAwesome';
                    content: "\e955";
                    font-size: 18px;
                    line-height: 0;
                    vertical-align: middle;
                }
            }
        }
    }
    // sorting & tags row
    .blog-controls {
        margin-top: 20px;
        margin-bottom: 48px;
        font-size: 14px;
        line-height: 20px;
    }
    .pagination {
        li a, li span {
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.44px;
        }
        .disabled {
            svg {
                opacity: .4;
            }
        }
        .pagination-icon {
            height: 15px;
            top: 8px;
        }
    }

    .blog-stars {
        margin-top: 10px;
        margin-bottom: 8px;
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 0.5px;
        color: #454545;
        &.blog-stars-mobile {
            display: none;
        }
        .star-item {
            width: 24%;
            text-align: center;
            display: inline-block;
        }
        img {
            border-radius: 50%;
            width: 100px;
            height: 100px;
            object-fit: cover;
            margin-bottom: 16px;
        }
        .star-name {
            opacity: .5;
            font-weight: 400;
            margin-bottom: 6px;
            font-size: 15px;
            line-height: 17px;
            letter-spacing: 0.64px;

        }
        .star-role {
            font-weight: 300;
        }
        .star-slider {
            width: 100%;
            margin-top: 20px;
            .carousel-control {
                opacity: 1;
                &.left {
                    background-image: none;
                    width: 25px;
                }
                &.right {
                    background-image: none;
                    width: 25px;
                }
                svg {
                    height: 26px;
                    width: 100%;
                    margin-top: 40px;
                }
            }
            .carousel-indicators {
                display: none;
                bottom: -50px;
                li {
                    background: #B4B0B0;
                    border-color: #B4B0B0;
                }
            }
        }
    }

    .blog-intro {
        font-size: 20px;
        line-height: 29px;
        font-weight: 300;
        margin-bottom: 100px;
    }

    #post-pagination {
        display: flex;
        justify-content: center;
    }

    .pagination-icon {
        height: 25px;
        fill: white;
        position: absolute;
        top: 8px;
        left: 0px;
    }
}

// zum Blogbeitrag
.blog-button {
    margin-top: 28px;
}

/* single blog post */
.blog {
    p {
        font-size: 14px;
        line-height: 22px;
        font-weight: 300;
    }
    a {
        font-weight: 500;
    }
    .btn {
        font-weight: 400;
        letter-spacing: 0.28px;
    }
    .blog-published-message {
        p {
            color: orange;
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 20px;
        }
        &.info::before {
            color: orange;
        }
    }
    .blog-headline {
        margin-top: 70px;
        margin-bottom: 20px;
        line-height: 22px;
        width: 50%;
    }
    .blog-image {
        border-radius: 7px;
        /* Using icons as image fallbacks breaks image display in Safari */
        /* font-family: 'sr-icons', 'FontAwesome'; */
        /* content: "\e90e"; */
        width: 100%;
        object-fit: cover;
        height: 426px;
    }
    .blog-data {
        margin: 20px 0;
        p {
            font-weight: 400;
        }
    }
    .blog-text {
        margin-bottom: 63px;
    }
    /* blog preview from blog overview */
    &.blog-teaser {
        .col-md-push-6 {
            left: 41.5%;
        }
        .col-md-pull-7 {
            margin-left: -45px;
        }
        .column-image {
            margin-right: 45px;
        }
        margin-bottom: 77px;
        .blog-headline {
            margin-top: 25px;
            font-size: 16px;
            line-height: 22px;
            width: 100%;
        }
        .blog-image {
            max-height: 370px;
            object-fit: cover;
            width: 100%;
            // placeholder if no image
            height: 370px;
            background-color: rgba(0,0,0,0.06);
            text-align: center;
            font-size: 80px;
            display: flex;
            justify-content: center;
            align-content: center;
            flex-direction: column;
        }
        .blog-text {
            margin-bottom: 25px;
            /* replaced by php limited character loaded
            p {
                display: -webkit-box;
                -webkit-line-clamp: 6;
                -webkit-box-orient: vertical;
                overflow: hidden;
                display: none;
                &:first-child {
                    display: block;
                }
            }
            ul, li, b, strong, i, u {
                display: none;
            }
            */
        }
        .blog-button {
            margin-top: 0;
        }
    }
    .blog-extra-images {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 115%;
        img {
            height: 208px;
            width: 208px;
            border-radius: 7px;
            margin: 37px 97px 37px 0;
            cursor: pointer;
            object-fit: cover;
        }
        .modal {
            img {
                height: 60vh;
                width: auto;
                margin: 0 auto;
                border-radius: 7px;
                cursor: default;
                max-height: 75vh;
                max-width: 100%;
                object-fit: contain;
            }
        }
    }
    /* blog share */
    .blog-share {
        display: flex;
        justify-content: end;
        margin-top: 7px;
        margin-bottom: 74px;
        .social-icons {
            margin-top: -4px;
            margin-left: 6px;
            a {
                width: 27px;
                height: 27px;
                color: #fff;
                background-color: #0E4761;
                margin: 0 6px;
                font-size: 16px;
                padding: 6px;
            }
        }
    }
    .mt-130 {
        margin-top: 130px;
    }
    .blog-overview-link-not-published {
        background-color: #f2f2f2;
        position:fixed;
        left: 0;
        top: 125px;
        height: 130px;
        z-index: 9999;
        .post-not-published-links {
            display: flex;
            justify-content: space-between;
            margin-top: 40px;
            .btn-default a {
                color: #454545;
                font-weight: 400;
            }
            .btn-primary {
                a {
                    color: #fff;
                }
            }
        }
    }
    .blog-overview-link {
        a {
            color: #454545;
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
        }
    }
    .blog-overview-link {
        .icon-arrow-internal {
            transform: rotateY(180deg);
            display: inline-block;
            font-size: 19px;
            vertical-align: -2.5px;
            margin-right: 8px;
        }
    }
    .blog-footer-navigation {
        display: flex;
        justify-content: space-between;
        // blog share commented out, so margin goes here until social media share is implemented
        margin-top: 37px;
        .btn {
            min-width: 190px;;
        }
    }
}

/* ork blog editor */
.blog-editor {
    .btn.btn-default {
        border-color: rgba(254, 254, 254, 1);
        color: rgba(254, 254, 254, 1);
    }
    #replace-image-text {
        font-size: 12px;
        line-height: 17px;
    }
    .main-image-wrapper {
        display: flex;
    }
    .main-image-spacing {
        margin-right: 70px;
    }
    .blog-image-info {
        margin-top: 15px;
    }
    .delete-blog-image {
        margin-top: 12px;
        margin-bottom: 25px;
        &.delete-special {
            bottom: -30px;
        }
    }
    .delete-mobile {
        display: none;
    }
    .extra-image-title {
        margin-left: 20px;
        margin-top: 60px;
    }
    .btn.btn-default {
        border-color: rgba(254, 254, 254, 1);
        color: rgba(254, 254, 254, 1);
    }
    .extra-image-preview, #main-image-preview {
        height: 110px;
        max-width: 190px;
        object-fit: contain;
    }
    .preview-container {
        padding-left:30px;
    }

    .preview-title {
        padding-left:5px;
        color: #454545;
    }

    .preview {
        height: 200px;
        width: 200px;
        border: 1px solid black;
        overflow: hidden;
        float:right;
    }

    .btn-width {
        width: 190px;
    }

    .modal-header {
        text-align: center;
    }

    #modalLabel {
        color: #454545;
        font-size: 20px;
    }

    #blogpost-submit:disabled {
        background-color: #657b83;
        border: 1px solid #323232;
        cursor: not-allowed;
    }

    #blogpost-submit {
        color: #FFF;
    }

    /* Style Rich Text Editor */
    .cke_wysiwyg_frame, .cke_wysiwyg_div {
        background: #063547;
    }
    .cke_top {
        background: #0086C5;
        border-bottom: 0;
        border-radius: 7px;
        padding: 2px;
    }
    .cke_inner {
        background: #0086C5;
    }
    .cke_reset {
        border-radius: 10px 10px 0 0;
    }
    .cke_chrome {
        border: 1px solid #0086C5;
        border-radius: 7px;
        background: #063547;
    }
    .cke_bottom {
        background: #063547;
        border-top: 0;
        display: none;
    }
    .cke_contents {
        margin-bottom: 10px;
    }
    .cke_wysiwyg_frame html {
        background: #063547;
    }
    .cke_wysiwyg_frame body {
        color: #fff;
        background: #063547;
    }
    /* rich text image icons */
    .cke_button_icon {
        filter: invert(100%) brightness(200%) contrast(100%);
    }
    a.cke_button_off:hover, a.cke_button_off:focus, a.cke_button_off:active {
        background: transparent;
        opacity: 0.7;
    }
    a.cke_button_on {
        background: transparent;
    }
    /* RTE end */

    // sections
    .section-title {
        margin-top: 80px;
    }
    .section-intro {
        margin-top: 100px;
    }
    .section-text {
        margin-top: 90px;
    }
    .section-extra {
        margin-top: 100px;
    }
    .section-info {
        margin-top: 0;
        margin-bottom: 100px;
    }
    .section-copyright {
        margin-top: 0;
        margin-bottom: 100px;
    }
    .section-controls {
        margin-top: 20px;
        margin-bottom: 100px;
    }

    .form-horizontal textarea.form-control {
        background-color: transparent;
    }

    input.input-field-blog {
        background-color: transparent;
        box-shadow: none;
        outline: none;
        height: 26px;
        font-size: 12px;
        line-height: 1.5;
        padding: 0 0 5px 0;
        border: 0 solid rgba(255, 255, 255, 0.8);
        border-bottom-width: 1px;
        color: #f8f8f8;
        width: 100%;
    }

    .form-control.upload-name {
        color: rgba(255, 255, 255, 0.8);
    }

    .icon-delete {
        cursor: pointer;
    }

    .blog-text-input {
        width: 100%;
    }
    .row-text {
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.2px;
        opacity: .5;
    }
    .info {
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 0.55px;
        opacity: .5;
        font-weight: 400;
    }
    .extra-image-item {
        padding-left: 0px;
        padding-right: 0px;
        margin-bottom: 15px;
        .extra-image-item-wrapper {
            padding-left: 0px;
            padding-right: 0px;
        }
        .col-md-6 {
            padding-right: 0px;
        }
    }
    .delete-image {
        color: #fff;
        cursor: pointer;
        display: inline;
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 0.33px;
        &::before {
            font-size: 18px;
            vertical-align: top;
            margin-right: 10px;
            opacity: .5;
        }
    }
    .hidden-file-select {
        visibility:hidden;
        font-size:1px !important;
    }
    .upload-name {
        border:0;
        margin-top:5px;
        font-weight: 400;
    }
    .col-label {
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.2px;
        opacity: .5;
        //margin-top: 12px;
    }
    .dashboard .btn.btn-default, .dashboard .btn.btn-default:not(.btn-success) {
        border-color: rgb(255, 255, 255);
        color: rgb(255, 255, 255);
    }
    .info-no-icon {
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 0.55px;
        opacity: .5;
        font-weight: 400;
    }
    #picture-privacy .control-label {
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 0.55px;
        font-weight: 300;
    }
    #picture-privacy .control-label span {
        font-weight: 400;
    }
    .checkbox label::before {
        position: absolute;
        left: -15px;
        top: 2px;
        content: "";
        color: #fff;
        display: inline-block;
        margin-right: 15px;
        border-radius: 2px;
        width: 16px;
        height: 16px;
        line-height: 14px;
        margin-top: -2px;
        background-color: transparent;
        border: 1px solid #fff;
        text-align: center;
        font-family: fontawesome;
    }
    .delete-image-text {
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 0.33px;
        margin-left: 13px;
    }
    #picture-privacy {
        .section-copyright {
            .label-privacy {
                width: 100%;
                display: flex;
                flex-direction: column;
                .label-privacy-head {
                    margin-bottom: 20px;
                }
            }
        }
    }
}

// ORK Mein Blog overview
.mein-blog {
    margin-bottom: 20px;
    h1 {
        margin-bottom: 24px;
    }
    .blog-info {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.4px;
        font-weight: 300;
    }
    .blogtitle-search {
        text-align: right;
        margin-top: 3px;
        margin-bottom: 0;
        label {
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0.33px;
            opacity: 0.4;
        }
        input {
            border: 1px solid #fff;
            display: inline-block;
            width: 190px;
            height: 34px;
            padding: 8px 12px;
        }
    }
    .sr_success {
        margin-bottom: 30px;
    }
    #blogtitle_searchbox::placeholder {
        opacity: 0;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.33px;
        font-weight: 500;
    }
    /* blog list */
    .status-released {
        height:15px;
        width:15px;
        display:inline-block;
        position: relative;
        top: -2px;
        background-image: url("data:image/svg+xml,%3Csvg height='15' viewBox='0 0 15 15' width='15' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle cx='6.75' cy='7.5' fill='%23fff' r='6.75'/%3E%3Cpath d='m7.34159675.00164202c-1.97218601.04154919-3.84778188.85206042-5.22180132 2.25705193-1.37400295 1.40433695-2.13569696 3.28944086-2.11954366 5.24719982-.01287731 1.98279549.77135302 3.88966323 2.17888231 5.29645473 1.40813917 1.406906 3.3236238 2.1976515 5.32086592 2.1976515 1.9978974 0 3.9127227-.7906964 5.3208619-2.1976515 1.4074963-1.4069061 2.1923983-3.31365924 2.1788823-5.29645473.0167401-2.01290479-.7887426-3.94579085-2.2309685-5.35912787-1.4429017-1.41392611-3.40092884-2.18734236-5.42717895-2.14512388zm4.33699605 4.20024715-.0006438.00063916c.3096983-.01406251.5955788.16235949.7198438.44360488.1249095.28126012.0630984.60981148-.1558151.82713843l-5.4380577 5.39807356c-.3901846.3873627-1.02374008.3873627-1.41456752 0l-2.64881146-2.62965446c-.29102312-.2914711-.29038028-.76129071.00193164-1.05213999.29230879-.29019473.76619385-.29083291 1.05916196-.00127833l2.29475751 2.27881571 5.08400377-5.04723481h.0006438c.1319923-.13167745.3096983-.20965235.4970601-.21732695z' fill='%238cb82b' fill-rule='nonzero'/%3E%3C/g%3E%3C/svg%3E");
        margin-right: 10px;
        vertical-align: middle;
    }
    .status-draft {
        height:15px;
        width:15px;
        display:inline-block;
        position: relative;
        top: -2px;
        background-image: url("data:image/svg+xml,%3Csvg height='15' viewBox='0 0 15 15' width='15' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle cx='7.5' cy='7.5' fill='%23fff' r='7.5'/%3E%3Cpath d='m7.5 0c-4.14966667 0-7.5 3.35033333-7.5 7.5 0 4.1496667 3.35033333 7.5 7.5 7.5 4.1496667 0 7.5-3.3503333 7.5-7.5 0-4.14966667-3.3503333-7.5-7.5-7.5zm3.8163333 10.0331667c.3502667.3502666.3502667.9166666 0 1.2669333-.1836.1836-.3997333.2669333-.6334666.2669333-.2330667 0-.4668-.0833333-.6334667-.2669333l-2.49933333-2.5-2.517 2.5c-.1836.1836-.39973334.2669333-.63346667.2669333-.23306667 0-.4668-.0833333-.63346667-.2669333-.35026666-.3502667-.35026666-.9166667 0-1.2669333l2.517-2.51700003-2.51633333-2.49933334c-.35026667-.35026666-.35026667-.91666666 0-1.26693333s.91666667-.35026667 1.26693333 0l2.517 2.517 2.51699997-2.517c.3502667-.35026667.9166667-.35026667 1.2669334 0 .3502666.35026667.3502666.91666667 0 1.26693333l-2.5181667 2.51633334z' fill='%23e30613' fill-rule='nonzero'/%3E%3C/g%3E%3C/svg%3E");
        margin-right: 10px;
        vertical-align: middle;
    }
    .blog-list tbody tr td:first-of-type {
        max-width: 200px;
    }
    .action-icons {
        padding: 5px 7px;
    }

    #numberOfResults.numberOfResults {
        margin-top: -75px;
        padding: 0;
    }

    #blog-table tbody {
        color: #fff
    }
    #blog-table_filter, #blog-table-mobile_filter {
        display: none;
    }

    /* Pagination */
    div.dataTables_paginate {
        text-align: center !important;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .paginate_button {
        font-size: 16px;
        position: relative;
        &.current {
            color: #0086C5 !important;
            font-weight: 600;
        }
    }
    .pagination-icon {
        height: 15px;
        fill: white;
        position: absolute;
        top: 12px;
        left: 0px;
    }
    /* End Pagination */

    /* ================================================ */
    /* ==== Spinner ==== */

    .spinner {
        z-index: 5000;
        img {
            margin-top: 25%;
            -webkit-animation: rotation 1s linear 0s infinite;
            -moz-animation: rotation 1s linear 0s infinite;
            -o-animation: rotation 1s linear 0s infinite;
            animation: rotation 1s linear 0s infinite;
        }
    }
    div.dataTables_wrapper {
        position: relative;
        div.dataTables_processing {
            z-index: 5000;
            position: absolute;
            top: 50px;
            left: 50%;
            width: 200px;
            margin-left: -100px;
            margin-top: -26px;
            text-align: center;
            padding: 1em 0;
        }
    }
    .dataTables_filter label input {
        border: 1px solid rgba(255,255,255,1);
        background-color: transparent;
        border-radius: 5px;
        height: 30px;
        opacity:1;
    }
    .dataTables_length label select {
        background-color: #0d4058;
        border-radius: 5px;
        height: 30px;
        border: none;
        -moz-appearance:none; /* Firefox */
        -webkit-appearance:none; /* Safari and Chrome */
        appearance:none;
        text-align: center;
        opacity:1;
    }
    .action-icons {
        font-size: 18px;
        opacity: .52;
        cursor: pointer;
    }

    .table-mobile {
        display: none;
    }

    #tableSortXs {
        .btn {
            padding: 4px 0;
            font-size: 11px;
            cursor: pointer;
            &.btn-default {
                background-color: transparent;
                border-color: rgba(0, 134, 197, 0.4);
                &:hover {
                    background-color: rgba(254, 254, 254, 0.07);
                }
                &.active {
                    background-color: rgba(2, 27, 38, 0.8);
                    border: 1px solid rgba(0, 134, 197, 0.4);
                }
            }
        }
    }

    #blog-table {
        &.data-table {
            thead > tr > th {
                &[class*="sorting"]::after {
                    left: unset;
                    margin-bottom: 7px;
                }
                &.sorting_asc,
                &.sorting_desc,
                &.sorting {
                    padding-left: 18px!important;
                    padding-right: 18px;
                }
                /* show sorting icon on all sorting columns */
                &.sorting::after {
                    content: "\e5c5";
                    opacity: 0.4;
                }
                &:first-child {
                    padding-left: 0!important;
                }
            }
            /* remove border from first row */
            tbody > tr:first-child td {
                border: 0;
            }
        }
        tr > td:first-child {
            padding-left: 0;
        }
        th.action {
            text-align: center;
            padding-right: 8px;
        }
        td.action {
            text-align: right;
            padding-right: 10px;
        }
    }
    #blog-table_paginate {
        padding-top: 65px;
        padding-bottom: 0px;
    }
    .row-new-post-search {
        margin-top: 30px;
        margin-bottom: 50px;
    }
}

/* tablet and up */
@media (min-width: 768px) and (max-width: 992px) {
    .blog-overview {
        .blog.blog-teaser .column-text {
            width:100%;
        }
        .blog.blog-teaser .blog-headline {
            width: 50%;
        }
        .blog-intro {
            margin-bottom: 50px;
        }
        .blog-stars {
            margin-bottom: 20px;
        }
        .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
            padding-left: 25px;
            padding-right: 25px;
        }
        .search-field-wrapper {
            margin-bottom: 30px;
        }
        .input-search-field {
            margin-bottom: 28px;
        }
        // sorting & tags row
        .blog-controls {
            margin-bottom: 60px;
            margin-top: 20px;
            .tags {
                .tag-item {
                    margin-right: 0px;
                }
            }
        }
        .blog-teaser {
            .col-md-push-6 {
                left: 0;
            }
            .col-md-pull-7 {
                margin-left: 0px;
            }
        }
        &.blog-teaser {
            margin-bottom: 70px;
            .blog-headline {
                font-size: 19px;
                line-height: 21px;
                margin-top: 28px;
                margin-bottom: 28px;
            }
        }
    }
    .blog-stars {
        .star-slider {
            .carousel-indicators {
                display: block;
            }
            .star-item {
                width: 33%;
            }
        }
    }

    /* single blog post */
    .blog {
        &.blogpost {
            .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
                padding-left: 25px;
                padding-right: 25px;
            }
            .blog-image {
                height: 481px;
            }
        }
        // container total spacing 25px
        // margin: 0 15px;

        .blog-extra-images {
            img {
                margin-right: 70px;
            }
            .modal {
                img {
                    border-radius: 10px;
                }
            }
        }
        // blog preview in draft
        .blog-overview-link-not-published {
            left: 0;
            top: 90px;
            height: 156px;
            width: 100%;
            .container {
                padding: 0;
            }
            .post-not-published-links {
                margin-left: 0;
                margin-right: 0;
                margin-top: 45px;
            }
        }
    }



    .blog-editor {
        #replace-image-text {
            margin-top: 10px;
        }
    }

    // ORK Mein Blog overview tablet
    .mein-blog {
        .row-new-post-search {
            margin-bottom: 35px;
        }
        #blog-table {
            padding-left: 24px;
        }
        #blog-table_paginate {
            padding-top: 75px;
        }
    }
}

/* mobile */
@media (max-width: 767px) {

    .blog-overview {
        .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
            padding-left: 30px;
            padding-right: 30px;
        }
        .blog.blog-teaser .column-text {
            width:100%;
        }
        .blog-intro {
            margin-bottom: 50px;
        }
        .search-field-wrapper {
            margin-bottom: 0px;
        }
        .input-search-field {
            margin-bottom: 15px;
        }
        .blog-overview-headline {
            text-align: left;
        }
        .blog-headline {
            font-size: 19px;
            line-height: 21px;
        }
        // sorting & tags row
        .blog-controls {
            display: flex;
            flex-direction: column-reverse;
            margin-bottom: 13px;
            margin-top: 0px;
            .blog-sorting {
                margin: 15px 0;
                .sorting-icon {
                    top: 10px;
                    right: 0;
                    left: 190px;
                }
            }
            .tags {
                justify-content: flex-start;
                .tag-item {
                    margin-left:0px;
                }
            }
        }
        .info::before {
            margin-top: 6px;
        }
        .blog-teaser {
            .blog-headline {
                font-size: 19px;
                line-height: 30px;
            }
            .col-md-push-6 {
                left: 0;
            }
            .col-md-pull-7 {
                margin-left: 0px;
            }
            // overview mobile images full width
            .col-xs-12 {
                &.column-image {
                    padding-left: 0;
                    padding-right: 0;
                }
                &.column-text {
                    padding-left: 45px;
                    padding-right: 45px;
                }
            }
            .row {
                margin: 0 -30px;
            }
        }
    }
    /* single blog post */
    .blog {
        font-size: 14px;
        //margin: 0 15px;
        &.blog-teaser {
            margin-bottom: 70px;
        }
        .btn {
            width: 100%;
        }
        .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
            padding-left: 30px;
            padding-right: 30px;
        }
        .blog-headline {
            width: 100%;
            margin-top: 28px;
            margin-bottom: 28px;
        }
        .blog-image {
            border-radius: 0;
            height: 250px;
        }
        .blog-data {
            margin-top: 28px;
        }
        .blog-text {
            margin-bottom: 36px;
        }
        .blog-extra-images {
            img {
                height: auto;
                width: 85%;
                margin: 36px 0;
                aspect-ratio: 1;
            }
            .modal {
                img {
                    border-radius: 0;
                    aspect-ratio: auto;
                }
            }
        }
        .blog-share {
            margin-bottom: 70px;
        }
        .blog-footer-navigation {
            display: block;
            // blog share margin
            margin-top: 36px;
            .btn {
                width: 100%;
                margin-bottom: 28px;
            }
        }
        // blogpost mobile images full width
        .row-content {
            margin: 0 -15px;
            .col-xs-12 {
                padding-left: 0;
                padding-right: 0;
                .blog-headline, .blog-data, .blog-text {
                    padding-left: 30px;
                    padding-right: 30px;
                }
            }
        }
        .blog-overview-link-not-published {
            left: 0;
            top: 50px;
            height: 156px;
            .post-not-published-links {
                margin-left: 0;
                margin-right: 0;
                display: block;
                margin-top: 28px;
                .btn {
                    margin-bottom: 20px;
                }
            }
        }
    }
    .blog-stars {
        display:none;
        margin-bottom: 28px;
        &.blog-stars-mobile {
            display: block;
        }
        .star-slider {
            .carousel-indicators {
                display: block;
                bottom: -40px;
                li {
                    margin: 2px 2px 2px 2px;
                    &.active {
                        width: 6px;
                        height: 6px;
                        margin: 2px 2px 2px 2px;
                        background-color: #454545;
                        border-color: #454545;
                    }
                }
            }
            .star-item {
                width: 100%;
                .star-name {
                    font-size: 12px;
                    line-height: 17px;
                    letter-spacing: 0.5px;
                }
            }
        }
    }
    .blog-overview .blog-stars.blog-stars-mobile {
        display: block;
    }
    // blog editor mobile
    .blog-editor {
        .col-label {
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.24px;
            opacity: 1;
        }
        .btn-width {
            width: 100%;
        }
        // sections
        .section-title {
            margin-top: 34px;
        }
        .section-intro {
            margin-top: 40px;
        }
        .section-text {
            margin-top: 40px;
        }
        .section-extra {
            margin-top: 40px;
        }
        .section-info {
            margin-bottom: 80px;
        }
        .section-copyright {
            margin-bottom: 80px;
        }
        .section-controls .btn {
            width: 100%;
            margin-bottom: 30px;
        }
        .section-intro img {
            margin-bottom: 12px;
        }
        .col-label {
            margin-bottom: 19px;
        }
        .section-title .col-label {
            font-size: 12px;
            line-height: 17px;
            margin-bottom: 6px;
        }
        small {
            font-size: 14px;
            line-height: 17px;
        }
        #post_title {
            font-size: 16px;
            line-height: 17px;
        }
        .delete-image {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.6px;
        }
        #replace-image-text {
            margin-top: 45px;
        }
        .main-image-upload {
            width: 100%;
        }
        .info {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.6px;
            margin-bottom: 0;
        }
        .info::before {
            line-height: 24px;
        }
        .cke_contents {
            border-radius: 10px;
            margin-bottom: 0;
        }
        .cke_reset {
            border-radius: 10px;
        }
        #icon-delete-main {
            position: relative;
            bottom: 124px;
            margin: 0;
        }
        .main-image-spacing {
            margin-right: 0;
        }
        .delete-blog-image {
            position: absolute;
            bottom: 78px;
            margin: 0;
        }
        .extra-image-title {
            margin-top: 45px;
            margin-left: 0;
        }
        .extra-image-item {
            margin-bottom: 25px;
        }
        input.input-field-blog {
            font-size: 14px;
            line-height: 17px;
        }
        .checkbox label::before {
            left: 0;
        }
        .checkbox label {
            padding-left: 30px;
        }
        #picture-privacy .control-label {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.6px;
        }
        .extra-image-preview, #main-image-preview {
            height: auto;
            max-width: 100%;
            width: 100%;
            max-height: 300px;
            object-fit: cover;
        }
    }


    // ORK Mein Blog overview mobile
    .mein-blog {
        .dataTables_wrapper {
            margin: 0 -20px 0 -30px!important;
        }
        #blogtitle_searchbox::placeholder {
            opacity: 1;
        }
        .blogtitle-search {
            text-align: left;
            input {
                width: 100%;
            }
        }
        .btn {
            width: 100%;
            margin-bottom: 37px;
        }
        .paginate_button {
            font-size: 16px;
            &.first, &.previous, &.next, &.last {
                display: inline;
            }
        }
        .table-mobile {
            display: block;
        }
        .table-desktop {
            display: none;
        }
        .row-new-post-search {
            margin-bottom: 35px;
        }
        .table > tbody > tr > td {
            border-top: 0;
        }
        table#blog-table-mobile.dataTable {
            border-collapse: collapse!important;
        }
        #blog-table-mobile {
            .sorting_asc, .sorting_desc {
                display: none;
            }
            thead {
                display: none;
            }
            tbody {
                tr {
                    position: relative;
                    color: #FFF;
                }
                > tr {
                    border-bottom: 1px solid #021B26;
                    &:last-child {
                        border: 0;
                    }
                }
            }
            td.title {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 48vw;
                padding-left: 5px;
                //width: 50%;
                top: -10px;
            }
            .status .status-text {
                display: none;
            }
            td {
                &.status {
                    // mobile spacing
                    padding: 0 0 0 32px;
                    width: 10%;
                }
                &.date {
                    font-size: 10px;
                    position: absolute;
                    border: none;
                    left: 57px;
                    top: 40px;
                    padding-left: 5px;
                    opacity: 0.52;
                }
            }
        }
        div.dataTables_paginate {
            padding-top: 90px;
        }
        .numberOfResults {
            margin-top: -160px;
        }
    }
}

#sr-modal-blog-error-message, #sr-modal-blog-success-message {
    z-index:10000;
    color: #454545;
    .modal-header {
        text-align: center;
    }
    .modal-content {
        border-radius: 4px;
    }
    .modal-body {
        min-height: 10px;
        display: flex;
    }
    .btn.btn-success {
        color: #fff;
    }
    .btn.btn-default {
        border-color: #454545;
        color: #454545;
    }
    .message {
        margin-top: 5px;
    }
}

#sr-modal-blog-delete  {
    color: #454545;
    .modal-header {
        text-align: center;
    }
    .modal-content {
        border-radius: 4px;
    }
    .modal-body {
        min-height: 30px;
    }
    .btn.btn-success {
        color: #fff;
    }
    .btn.btn-default {
        border-color: #454545;
        color: #454545;
    }
}
