// base scss template for (2023) uc refactoring
.uc {
    //limit to ORK main content and specific pages in typo3 due to conflict with the mobile ORK menu
    //TODO: better solution would be to exclude the ORK menu
    main, 
    &.startpage-results,
    &.auswertung-gesamt .sr_start_content,
    .auswertung-sonderaktion,
    .hall-of-fame,
    .historie-gesamt,
    &.kommune-page .kommune-wrapper {
        //general uc styling, not specific to light-bg or dark-bg
        
            // light background specific (typo3 sites)
            &.light-bg, .light-bg {

                .dropdown.custom-dropdown {
                    .dropdown-menu {
                        width: 100%;
                        border: 0;
                        margin-top: 0px;
                        opacity: 1;
                        border-bottom: 0px;
                        background-color: #fff;
                        box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.08);
                        color: #454545;
                        li {
                            border: 0px;
                            opacity: 1;
                            border-bottom-width: 0;
                            font-size: 14px;
                            text-align: left;
                            &:last-of-type {
                                border-bottom-width: 0px;
                            }
                            &:first-of-type {
                                border-top-right-radius: 0;
                                border-top-left-radius: 0;
                            }
                            a {
                                padding-top: 10px;
                                opacity: 1;
                                padding-bottom: 10px;
                                color: #454545;
                                font-weight: normal;
                                &:hover {
                                    background-color: transparent;
                                    color: #0086C5;
                                    text-shadow: none;
                                }
                                &:focus {
                                    background-color: transparent;
                                    color: #0086C5;
                                    text-shadow: none;
                                }
                                &:active {
                                    background-color: transparent;
                                    color: #0086C5;
                                    text-shadow: none;
                                }
                            }
                            a.active {
                                &:hover {
                                    background-color: transparent;
                                    color: #0086C5;
                                    text-shadow: none;
                                }
                                &:focus {
                                    background-color: transparent;
                                    color: #0086C5;
                                    text-shadow: none;
                                }
                                &:active {
                                    background-color: transparent;
                                    color: #0086C5;
                                    text-shadow: none;
                                }
                            }
                        }
                    }
                }
                .btn.btn-default.dropdown-toggle {
                    font-size: 14px;
                    font-weight: normal;
                    border-color: rgba(151, 151, 151, 0.8);
                    border-width: 0 0 1px 0;
                    background-color: transparent;
                    color: #454545;
                    min-width: 190px;
                    text-align: left;
                    padding-left: 0px;
                    padding-top: 0px;
                    padding-bottom: 4px;
                    border-radius: 0;
                    &::after {
                        content: "\e5c5";
                        display: inline-block;
                        text-align: center;
                        vertical-align: middle;
                        font-family: sr-icons;
                        font-size: 26px;
                        line-height: 14px;
                        position: absolute;
                        right: -5px;
                        top: 3px;
                        color: #888;
                    }
                }
        
                .btn.btn-default.dropdown-toggle:hover,
                .btn.btn-default.dropdown-toggle:focus {
                    background-color: transparent;
                    color: #454545;
                    border-color: rgba(151, 151, 151, 0.8);
                    border-width: 0 0 1px 0;
                }
        
                .dropdown.open .btn.btn-default.dropdown-toggle {
                    border-width: 0 0 1px 0;
                    border-color: #0086C5;
                    &::after {
                        content: "\e5c7";
                        color: #0086C5;
                        right: -5px;
                        top: 3px;
                    }
                }
        
                .dropdown.open {
                    .dropdown-menu {
                        border-radius: 0;
                    }
                }
            
            
            } // .light-bg end

            // dark background specific (ORK sites)
            &.dark-bg, .dark-bg {

                .dropdown-menu {
                    position: absolute;
                    left: 0;
                    z-index: 1000;
                    display: none;
                    float: left;
                    min-width: 160px;
                    padding: 5px 0;
                    margin: 2px 0 0;
                    list-style: none;
                    font-size: 14px;
                    text-align: left;
                    background-color: #fff;
                    border: 1px solid transparent;
                    border: 1px solid transparent;
                    border-radius: 0;
                    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
                    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
                    background-clip: unset;
                    .divider {
                        height: 1px;
                        margin: 9px 0;
                        overflow: hidden;
                        background-color: #0E4761;
                    }
                    >li {
                        >a {
                            display: block;
                            padding: 3px 20px;
                            clear: both;
                            font-weight: normal;
                            line-height: 1.428571429;
                            color: #fefefe;
                            white-space: nowrap;
                            &:hover {
                                text-decoration: none;
                                color: #fefefe;
                                background-color: #fefefe;
                            }
                            &:focus {
                                text-decoration: none;
                                color: #fefefe;
                                background-color: #fefefe;
                            }
                        }
                    }
                    >.active {
                        >a {
                            color: #0086C5 !important;
                            text-decoration: none;
                            outline: 0;
                            background-color: rgba(0,0,0,0) !important;
                            &:hover {
                                color: #0086C5 !important;
                                text-decoration: none;
                                outline: 0;
                                background-color: rgba(0,0,0,0) !important;
                            }
                            &:focus {
                                color: #0086C5 !important;
                                text-decoration: none;
                                outline: 0;
                                background-color: rgba(0,0,0,0) !important;
                            }
                        }
                    }
                    >.disabled {
                        >a {
                            color: #898989;
                            &:hover {
                                color: #898989;
                                text-decoration: none;
                                background-color: transparent;
                                background-image: none;
                                filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
                                cursor: not-allowed;
                            }
                            &:focus {
                                color: #898989;
                                text-decoration: none;
                                background-color: transparent;
                                background-image: none;
                                filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
                                cursor: not-allowed;
                            }
                        }
                    }
                }
                .dropdown-menu.pull-right {
                    right: 0;
                    left: auto;
                }
                .open {
                    >.dropdown-menu {
                        display: block;
                    }
                    >a {
                        outline: 0;
                    }
                }
                .dropdown-menu-right {
                    left: auto;
                    right: 0;
                }
                .dropdown-menu-left {
                    left: 0;
                    right: auto;
                }
                .dropdown-header {
                    display: block;
                    padding: 3px 20px;
                    font-size: 12px;
                    line-height: 1.428571429;
                    color: #fff;
                    white-space: nowrap;
                }
                .dropdown-backdrop {
                    position: fixed;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    top: 0;
                    z-index: 990;
                }
                .pull-right {
                    >.dropdown-menu {
                        right: 0;
                        left: auto;
                    }
                }
                .navbar-fixed-bottom {
                    .dropdown {
                        .caret {
                            border-top: 0;
                            border-bottom: 4px dashed;
                            border-bottom: 4px solid \9;
                            content: "";
                        }
                        .dropdown-menu {
                            top: auto;
                            bottom: 100%;
                            margin-bottom: 2px;
                        }
                    }
                }

                .dropdown.custom-dropdown {
                    .dropdown-menu {
                        width: 100%;
                        border: 0;
                        margin-top: 0px;
                        opacity: 1;
                        background-color: #0b3a4f;
                        li {
                            border: 0px;
                            opacity: 1;
                            border-bottom-width: 0;
                            font-size: 14px;
                            text-align: left;
                            &:last-of-type {
                                border-bottom-width: 0px;
                            }
                            &:first-of-type {
                                border-top-right-radius: 0;
                                border-top-left-radius: 0;
                            }
                            a {
                                padding-top: 10px;
                                opacity: 1;
                                padding-bottom: 10px;
                                color: rgba(255, 255, 255, 0.8);
                                font-weight: normal;
                                &:hover {
                                    background-color: transparent;
                                    color: #0086C5;
                                    text-shadow: none;
                                }
                                &:focus {
                                    background-color: transparent;
                                    color: #0086C5;
                                    text-shadow: none;
                                }
                                &:active {
                                    background-color: transparent;
                                    color: #0086C5;
                                    text-shadow: none;
                                }
                            }
                            a.active {
                                &:hover {
                                    background-color: transparent;
                                    color: #0086C5;
                                    text-shadow: none;
                                }
                                &:focus {
                                    background-color: transparent;
                                    color: #0086C5;
                                    text-shadow: none;
                                }
                                &:active {
                                    background-color: transparent;
                                    color: #0086C5;
                                    text-shadow: none;
                                }
                            }
                        }
                    }
                }

                .btn.btn-default.dropdown-toggle {
                    font-size: 14px;
                    font-weight: normal;
                    border-color: rgba(255, 255, 255, 0.8);
                    border-width: 0 0 1px 0;
                    background-color: #063548;
                    color: #fff;
                    min-width: 190px;
                    text-align: left;
                    padding-left: 0px;
                    padding-top: 0px;
                    padding-bottom: 4px;
                    border-radius: 0;
                    &::after {
                        content: "\e5c5";
                        display: inline-block;
                        text-align: center;
                        vertical-align: middle;
                        font-family: sr-icons;
                        font-size: 26px;
                        line-height: 14px;
                        position: absolute;
                        right: -5px;
                        top: 3px;
                    }
                }

                .btn.btn-default.dropdown-toggle:hover,
                .btn.btn-default.dropdown-toggle:focus {
                    background-color:#063548;
                    color: #fff;
                    border-color: #fff;
                    border-width: 0 0 1px 0;
                }

                .dropdown.open .btn.btn-default.dropdown-toggle {
                    border-width: 0 0 1px 0;
                    border-color: #0086C5;
                    &::after {
                        content: "\e5c7";
                        color: #0086C5;
                        right: -5px;
                        top: 3px;
                    }
                }

                .dropdown.open {
                    .dropdown-menu {
                        border-top-right-radius: 0;
                        border-top-left-radius: 0;
                    }
                }

            } // .dark-bg end
    }  
} // .uc end

/* tablet */
@media (min-width: $breakpoint-tablet-min) and (max-width: $breakpoint-tablet-max) {
    .uc {
    
        &.light-bg, .light-bg {
        
        } // .light-bg end
        &.dark-bg, .dark-bg {
    
        } // .dark-bg end
    } // .uc end
}

/* mobile */
@media (max-width: $breakpoint-mobile) {
    .uc {
        main, 
        &.startpage-results .sr_start_content,
        &.auswertung-gesamt .sr_start_content,
        &.hall-of-fame .sr_start_content,
        &.historie-gesamt .sr_start_content,
        &.kommune-page .kommune-wrapper {
            
            &.light-bg, .light-bg {
                .dropdown.custom-dropdown {
                    .dropdown-menu {
                        li {
                            font-size: 16px;
                        }
                    }
                }
            } // .light-bg end
            &.dark-bg, .dark-bg {
                .dropdown.custom-dropdown {
                    .dropdown-menu {
                        li {
                            font-size: 16px;
                        }
                    }
                }
                .btn.btn-default.dropdown-toggle {
                    font-size: 16px;
                }
            } // .dark-bg end
        }
    } // .uc end
}