.ct-label {
	color: white;
	line-height: 1;
	font-family: Roboto;
	font-size: 9px;
	font-weight: 300;
	line-height: 10px;
}
.ct-label.ct-horizontal {
	font-size: 14px;
	padding-top: 8px;
}
.ct-label.ct-vertical {
	font-size: 12px;
}
.ct-series-a {
	.ct-line {
		stroke: #8CB82B;
		stroke-width: 1px;
	}
	.ct-area {
		fill: url(#gradient_dark) !important;
		fill-opacity: 1;
	}
}
.ct-series-b {
	.ct-line {
		stroke: #2481B5;
		stroke-width: 1px;
	}
	.ct-area {
		fill: url(#gradient_blue) !important;
		fill-opacity: 1;
	}
}
.ct-series-c {
	.ct-line {
		stroke: #7EC927;
		stroke-width: 1px;
	}
	.ct-area {
		fill: url(#gradient_green) !important;
		fill-opacity: 1;
	}
}
.ct-grid {
	stroke: #335B6D;
	stroke-width: 2px;
	stroke-dasharray: 0px;
}
.ct-vertical {
	display: none;
}
.ct-chart-wrapper {
	position: relative;
}
.ct-chart-y-label {
	position: absolute;
	top: -3px;
	left: 25px;
	color: #fff;
}
