@mixin for-size($size) {
  @if $size == break-point-sm {
    @media (max-width: 576px) { @content; }
  } @else if $size == break-point-md {
    @media (min-width: 768px) { @content; }
  } @else if $size == break-point-lg {
    @media (min-width: 992px) { @content; }
  } @else if $size == break-point-xl {
    @media (min-width: 1200px) { @content; }
  } @else if $size == break-point-xxl {
    @media (min-width: 1400px) { @content; }
  }
}
