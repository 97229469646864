.form-horizontal {
	.form-group {
		&:before {
			content: " ";
			display: table;
		}
		&:after {
			content: " ";
			display: table;
			clear: both;
		}
	}
}

.btn-toolbar {
	&:before {
		content: " ";
		display: table;
	}
	&:after {
		content: " ";
		display: table;
		clear: both;
	}
}
.btn-group-vertical {
	>.btn-group {
		&:before {
			content: " ";
			display: table;
		}
		&:after {
			content: " ";
			display: table;
			clear: both;
		}
	}
}

.nav {
	&:before {
		content: " ";
		display: table;
	}
	&:after {
		content: " ";
		display: table;
		clear: both;
	}
}
.navbar {
	&:before {
		content: " ";
		display: table;
	}
	&:after {
		content: " ";
		display: table;
		clear: both;
	}
}
.navbar-header {
	&:before {
		content: " ";
		display: table;
	}
	&:after {
		content: " ";
		display: table;
		clear: both;
	}
}
.navbar-collapse {
	&:before {
		content: " ";
		display: table;
	}
	&:after {
		content: " ";
		display: table;
		clear: both;
	}
}
.pager {
	&:before {
		content: " ";
		display: table;
	}
	&:after {
		content: " ";
		display: table;
		clear: both;
	}
}
.panel-body {
	&:before {
		content: " ";
		display: table;
	}
	&:after {
		content: " ";
		display: table;
		clear: both;
	}
}
.modal-header {
	&:before {
		content: " ";
		display: table;
	}
	&:after {
		content: " ";
		display: table;
		clear: both;
	}
}
.modal-footer {
	&:before {
		content: " ";
		display: table;
	}
	&:after {
		content: " ";
		display: table;
		clear: both;
	}
}
.clearfix {
	&:before {
		content: " ";
		display: table;
	}
	&:after {
		content: " ";
		display: table;
		clear: both;
	}
}
