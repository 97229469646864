.text-primary {
	color: #286AA6;
}
a.text-primary {
	&:hover {
		color: #1e507d;
	}
	&:focus {
		color: #1e507d;
	}
}
.text-success {
	color: #3c763d;
}
a.text-success {
	&:hover {
		color: #2b542c;
	}
	&:focus {
		color: #2b542c;
	}
}
.text-info {
	color: #31708f;
}
a.text-info {
	&:hover {
		color: #245269;
	}
	&:focus {
		color: #245269;
	}
}
.text-warning {
	color: #8a6d3b;
}
a.text-warning {
	&:hover {
		color: #66512c;
	}
	&:focus {
		color: #66512c;
	}
}
.text-danger {
	color: #a94442;
}
a.text-danger {
	&:hover {
		color: #843534;
	}
	&:focus {
		color: #843534;
	}
}
