.auswertung-sonderaktion {

    @-webkit-keyframes rotation { from { transform: rotate(0deg); } to { transform: rotate(359deg); }  }
    @-moz-keyframes rotation { from { transform: rotate(0deg); } to { transform: rotate(359deg); }  }
    @-o-keyframes rotation { from { transform: rotate(0deg); } to { transform: rotate(359deg); }  }
    @keyframes rotation { from { transform: rotate(0deg); } to { transform: rotate(359deg); }  }

    .pr-0 {
        padding-right: 0px !important;
    }
    .status {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #052836;
        margin: 0 auto;
    }
    .status.status-green {
        background-color: #0086C5;
    }
    .status.status-yellow {
        background-color: #7DC6E8;
    }
    .status.status-orange {
        background-color: #FFFFFF;
    }
    .status.status-red {
        background-color: #21507B;
    }
    .status-small {
        &:before {
            content: "";
            display: block;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            float: left;
            margin: 3px 6px 0 0;
        }
    }
    .status-small.status-green {
        &:before {
            background-color: #0086C5;
        }
    }
    .status-small.status-yellow {
        &:before {
            background-color: #7DC6E8;
        }
    }
    .status-small.status-orange {
        &:before {
            background-color: #FFFFFF;
        }
    }
    .status-small.status-red {
        &:before {
            background-color: #21507B;
        }
    }
    .ml-10 {
        margin-left: 10px;
    }
    .dataTables_filter {
        display: none;
    }
    .dark-bg {
        .btn.btn-default.dropdown-toggle {
            min-width: intrinsic;
            min-width: 140px;
            font-size: 12px;
            font-weight: 500;
            border: none;
            background-color: #0E4761;
            color: rgba(254, 254, 254, 0.8);
            transition: background-color 500ms;
        }
        .th-search {
            .btn-group {
                .btn {
                    border-color: #fff !important;
                }
            }
        }
    }
    .top10btn {
        .btn.btn-default.dropdown-toggle {
            min-width: intrinsic;
            min-width: 140px;
            font-size: 12px;
            font-weight: 500;
            border: none;
            background-color: #0E4761;
            color: rgba(254, 254, 254, 0.8);
            transition: background-color 500ms;
        }
    }
    .arrow-invert {
        .arrow-links {
            li {
                a {
                    &::before {
                        float: left;
                        margin-right: 3px;
                        transform: rotate(-135deg);
                    }
                    &:hover {
                        &::before {
                            transform: rotate(-90deg);
                        }
                    }
                }
            }
        }
    }
    table.sr-ranking {
        span.aktiv {
            &::after {
                content: none;
            }
        }
        tbody {
            >tr {
                td {
                    font-size: 20px;
                    color: rgba(255,255,255,0.87) !important;
                }
                border-bottom: 1px solid #052836;
            }
        }
    }
    .text-15 {
        font-size: 15px !important;
    }
    #tableSortXs {
        .btn {
            padding-left: inherit;
            padding-right: inherit;
            padding-top: 4px;
            padding-bottom: 4px;
        }
        .btn.btn-default.active {
            background-color: rgba(2,27,38,0.8) !important;
            border: 1px solid rgba(0, 134, 197, 0.4) !important;
        }
    }
    #tableSortCitiesXs {
        .btn {
            padding-left: inherit;
            padding-right: inherit;
            padding-top: 4px;
            padding-bottom: 4px;
        }
        .btn.btn-default.active {
            background-color: rgba(2,27,38,0.8) !important;
            border: 1px solid rgba(0, 134, 197, 0.4) !important;
        }
    }
    .container {
        .btn.btn-default.dropdown-toggle {
            &:hover {
                border-color: #0E4761;
                background-color: #0E4761;
                color: #fff;
            }
            &:focus {
                border-color: #0E4761;
                background-color: #0E4761;
                color: #fff;
            }
        }
        .btn.btn-default.dropdown-toggle.focus {
            border-color: #0E4761;
            background-color: #0E4761;
            color: #fff;
        }
    }
    .dashboard {
        .btn.btn-default.dropdown-toggle {
            &:hover {
                border-color: #0E4761;
                background-color: #0E4761;
                color: #fff;
            }
            &:focus {
                border-color: #0E4761;
                background-color: #0E4761;
                color: #fff;
            }
        }
        .btn.btn-default.dropdown-toggle.focus {
            border-color: #0E4761;
            background-color: #0E4761;
            color: #fff;
        }
    }
    .data-unit {
        font-size: 13px;
    }
    .spinner {
        z-index: 5000;
        img {
            margin-top: 25%;
            -webkit-animation: rotation 1s linear 0s infinite;
            -moz-animation: rotation 1s linear 0s infinite;
            -o-animation: rotation 1s linear 0s infinite;
            animation: rotation 1s linear 0s infinite;
        }
    }
    .numberOfResults {
        >div {
            float: left;
            display: inline-table;
            margin-right: 10px;
            color: rgba(255, 255, 255, 0.5);
            cursor: pointer;
        }
        >span {
            float: left;
            display: inline-table;
            margin-right: 10px;
            color: rgba(255, 255, 255, 0.5);
        }
        margin-top: -75px;
        font-weight: bold;
        margin-bottom: 75px;
        >div.active {
            cursor: pointer;
            color: #0086C5;
        }
    }
    div.dataTables_paginate {
        text-align: center !important;
        padding-top: 70px;
        padding-bottom: 50px;
    }
    .dataTables_wrapper {
        .dataTables_paginate {
            padding-top: 20px;
        }
    }
    .dataTables_length {
        display: none;
    }
    .paginate_button.current {
        color: #0086C5 !important;
        font-weight: 600;
    }
    .paginate_button {
        font-size: 18px;
        position: relative;
    }
    .pagination-icon {
        height: 15px;
        fill: white;
        position: absolute;
        top: 14px;
        left: 0px;
    }
    .nav-pills {
        .btn {
            padding-bottom: 8px;
            padding-top: 8px;
            font-size: 11px !important;
            color: #fff;
        }
        .active {
            .btn {
                border-color: #8CB82B !important;
                font-weight: 600;
            }
        }
    }
    .tab-pane {
        padding-top: 0px;
    }
    .sort-buttons {
        .btn-group {
            margin-bottom: 0;
        }
    }
    .visible-xs {
        z-index: 999;
    }
    .content-spinner-wrapper {
        width: 100%;
        min-height: 200px;
        text-align: center;
    }
    .content-spinner {
        z-index: 5000;
        width: 200px;
        margin: 0 auto;
        padding: 20px;
        img {
            margin-top: 25%;
            -webkit-animation: rotation 1s linear 0s infinite;
            -moz-animation: rotation 1s linear 0s infinite;
            -o-animation: rotation 1s linear 0s infinite;
            animation: rotation 1s linear 0s infinite;
        }
    }
    @media (min-width: 768px) {
        table.sr-ranking {
            tbody {
                >tr {
                    >td {
                        &:first-of-type {
                            span {
                                height: 40px;
                                width: 40px;
                                display: block;
                                padding: 15px 20px 15px 14px;
                                border-radius: 50%;
                                margin-left: 2px;
                                background: #052836;
                                font-size: 15px;
                                font-weight: 500;
                                line-height: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 767px) {
        #specials-last-updated {
            display: none !important;
        }
        table.sr-ranking {
            tbody {
                >tr {
                    >td.radelnde {
                        position: absolute;
                        left: 0;
                        margin-top: 24px;
                        font-size: 11px;
                        opacity: 0.7;
                        padding-left: 10px;
                    }
                    >td.merge-left {
                        position: absolute;
                        left: 0;
                        margin-top: 24px;
                        font-size: 11px;
                        opacity: 0.7;
                        padding-left: 10px;
                    }
                }
            }
        }
        .numberOfResults {
            margin-top: -230px;
            font-weight: bold;
            margin-bottom: 130px;
        }
        #sr-sonder-table {
            table.sr-ranking {
                tbody {
                    >tr {
                        >td {
                            padding: 10px 0 30px 0;
                            max-width: 200px;
                            &:first-of-type {
                                margin-top: -6px;
                            }
                        }
                        td.align-middle {
                            overflow: visible;
                        }
                        >td.schulradelnde {
                            position: absolute;
                            left: 20px;
                            margin-top: 42px;
                            font-size: 11px;
                            opacity: 0.7;
                        }
                    }
                }
            }
            table.sr-ranking {
                thead {
                    >tr {
                        >th:nth-child(2) {
                            width: 140px !important;
                        }
                    }
                }
            }
            .bar_text {
                left: 30px !important;
            }
        }
    }
    @media (max-width: 479px) {
        .paginate_button.first {
            display: inline;
        }
        .paginate_button.previous {
            display: inline;
        }
        .paginate_button.next {
            display: inline;
        }
        .paginate_button.last {
            display: inline;
        }
        .paginate_button {
            font-size: 18px;
        }
    }


}
