.input-group {
	position: relative;
	display: table;
	border-collapse: separate;
	.form-control {
		position: relative;
		z-index: 2;
		float: left;
		width: 100%;
		margin-bottom: 0;
		display: table-cell;
		&:focus {
			z-index: 3;
		}
		&:not(:first-child) {
			&:not(:last-child) {
				border-radius: 0;
			}
		}
		&:first-child {
			border-bottom-right-radius: 0;
			border-top-right-radius: 0;
		}
		&:last-child {
			border-bottom-left-radius: 0;
			border-top-left-radius: 0;
		}
	}
}
.input-group[class*="col-"] {
	float: none;
	padding-left: 0;
	padding-right: 0;
}
.input-group-addon {
	display: table-cell;
	width: 1%;
	white-space: nowrap;
	vertical-align: middle;
	padding: 9px 18px;
	font-size: 14px;
	font-weight: normal;
	line-height: 1;
	color: #454545;
	text-align: center;
	background-color: #e7e7e7;
	border: 1px solid #bbb;
	border-radius: 0;
	&:not(:first-child) {
		&:not(:last-child) {
			border-radius: 0;
		}
	}
	input[type="radio"] {
		margin-top: 0;
	}
	input[type="checkbox"] {
		margin-top: 0;
	}
	&:first-child {
		border-bottom-right-radius: 0;
		border-top-right-radius: 0;
		border-right: 0;
	}
	&:last-child {
		border-bottom-left-radius: 0;
		border-top-left-radius: 0;
		border-left: 0;
	}
}
.input-group-btn {
	display: table-cell;
	width: 1%;
	white-space: nowrap;
	vertical-align: middle;
	position: relative;
	font-size: 0;
	white-space: nowrap;
	&:not(:first-child) {
		&:not(:last-child) {
			border-radius: 0;
		}
	}
	&:first-child {
		>.btn {
			border-bottom-right-radius: 0;
			border-top-right-radius: 0;
			margin-right: -1px;
			&:not(:first-child) {
				border-bottom-left-radius: 0;
				border-top-left-radius: 0;
			}
		}
		>.btn-group {
			>.btn {
				border-bottom-right-radius: 0;
				border-top-right-radius: 0;
			}
			&:not(:first-child) {
				>.btn {
					border-bottom-left-radius: 0;
					border-top-left-radius: 0;
				}
			}
			margin-right: -1px;
		}
		>.dropdown-toggle {
			border-bottom-right-radius: 0;
			border-top-right-radius: 0;
		}
	}
	&:last-child {
		>.btn {
			&:not(:last-child) {
				&:not(.dropdown-toggle) {
					border-bottom-right-radius: 0;
					border-top-right-radius: 0;
				}
			}
			border-bottom-left-radius: 0;
			border-top-left-radius: 0;
			z-index: 10;
			margin-left: -1px;
		}
		>.btn-group {
			&:not(:last-child) {
				>.btn {
					border-bottom-right-radius: 0;
					border-top-right-radius: 0;
				}
			}
			>.btn {
				border-bottom-left-radius: 0;
				border-top-left-radius: 0;
			}
			z-index: 10;
			margin-left: -1px;
		}
		>.dropdown-toggle {
			border-bottom-left-radius: 0;
			border-top-left-radius: 0;
		}
	}
	>.btn {
		position: relative;
		+ {
			.btn {
				margin-left: -1px;
			}
		}
		&:hover {
			z-index: 2;
		}
		&:focus {
			z-index: 2;
		}
		&:active {
			z-index: 2;
		}
	}
}
.input-group-addon.input-sm {
	padding: 7px 14px;
	font-size: 12px;
	border-radius: 0;
}
.input-group-sm {
	>.input-group-addon {
		padding: 7px 14px;
		font-size: 12px;
		border-radius: 0;
	}
	>.input-group-btn {
		>.input-group-addon.btn {
			padding: 7px 14px;
			font-size: 12px;
			border-radius: 0;
		}
	}
}
.input-group-addon.input-lg {
	padding: 14px 28px;
	font-size: 18px;
	border-radius: 0;
}
.input-group-lg {
	>.input-group-addon {
		padding: 14px 28px;
		font-size: 18px;
		border-radius: 0;
	}
	>.input-group-btn {
		>.input-group-addon.btn {
			padding: 14px 28px;
			font-size: 18px;
			border-radius: 0;
		}
	}
}
