.modal {
    p {
        color: #454545;
    }
    .form-control {
        &::placeholder {
            font-size: 12px;
        }
    }
}

#sr-entry-modal {
    p.info {
        position: relative;
        padding-left: 34px;
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 0.51px;
        font-weight: 400;
        color: #8d8d8d;
        // info i
        &::before {
            line-height: 30px;
        }
    }
}

#sr-cropping-modal {

    .preview-title {
        padding-left:5px;
        color: #454545;
        display: flex;
        margin-left: -5px;
    }

    .preview {
        height: 200px;
        width: 200px;
        border: 1px solid black;
        overflow: hidden;
        float:left;
        margin-left:0px;
    }

    .modal-dialog {
        width: 680px;
    }
    .preview-container {
        padding-left: 20px;
    }
}

.modal-dialog {
    width: 620px;
    margin: 220px auto;
    .modal-content {
        .modal-header {
            .close {
                font-size: 22px;
                opacity: 0.8;
            }
        }
    }
}

.modal-spielregeln {
    .modal-header {
        padding:0;
    }
    .modal-body {
        padding:0;
    }
    .btn {
        margin-right:24px;
        margin-bottom:24px;
    }
    #accordion-template {
        &.accordion {
            .faq-header {
                margin-top:0;
            }
        }
        .search-bar {
            display:none;
        }
    }
}

.modal-dialog {
    form {
        .input-group-addon {
            background-color: #f8f8f8;
            padding: 4px 0 6px 0;
            border: 0;
            border-bottom: 1px solid #bbb;
            color: #676767;
        }
    }
}

/* desktop+ */
@media (min-width: 992px) {
    .modal-dialog {
        &.modal-lg {
            width: 900px;
        }
    }
}

/* tablet */
@media (min-width: 768px) and (max-width: 992px) {
    .modal-dialog {
        width: 680px;
        margin: 140px auto;
        &.modal-lg {
            width: 700px;
        }
    }
}

/* all mobile */
@media (max-width: 767px) {
    .modal {
        .form-control {
            &::placeholder {
                font-size: 14px;
            }
        }
    }
    .modal-dialog {
        height: 100%;
        width: 100%;
        min-height: 100%;
        padding-top: 50px;
        margin: 0;
        form {
            input {
                font-size: 14px;
            }
            textarea {
                font-size: 14px;
            }
            .input-group-addon {
                background-color: #fff;
                padding: 4px 0 6px 0;
                border: 0;
                border-bottom: 1px solid #bbb;
                color: #676767;
            }
            .datepicker {
                &::before {
                    background-color: #fff;
                }
            }
        }
    }
    .modal-content {
        background-color: #fff;
    }
    .modal-header {
        padding: 30px 30px 23px;
    }
    .modal-body {
        padding: 30px;
    }
    .modal-footer {
        padding: 24px 30px;
        .btn {
            width: 100%;
        }
    }
    #sr-cropping-modal {
        .modal-dialog {
            width: 100%;
            .modal-content {
                .modal-header {
                    padding: 30px 30px 23px;
                }
                .modal-footer {
                    height: auto;
                }
            }
        }

        .modal-body {
            padding-top: 0;
        }
        .preview-title {
            padding-left:0;
            margin-left: 0;
            margin-top: 5px;
        }
        .preview-container {
            padding-left: 15px;
            overflow: auto;
        }
    }
}

.modal-header {
    text-align: left;
}

#modalLabel {
    color: #454545;
    font-size: 20px;
}


#sr-modal-success-message,
#sr-error-message {
    color: #454545;
}

#sr-modal-success .modal-text,
#sr-error-message
{
    display: flex;
}

#sr-modal-success .modal-dialog .modal-content .modal-body {
    min-height: 10px;
}

#sr-modal-success .modal-dialog .modal-content {
    border-radius: 4px;
}

#sr-error-modal
{
    z-index: 2000;
}

#sr-rte-too-many-characters .modal-dialog .modal-content .modal-body,
#sr-missing-field .modal-dialog .modal-content .modal-body,
#sr-error-modal .modal-dialog .modal-content .modal-body,
{
    min-height: 10px;
}

#sr-rte-too-many-characters .modal-dialog .modal-content,
#sr-missing-field .modal-dialog .modal-content
#sr-error-modal .modal-dialog .modal-content
{
    border-radius: 4px;
}

#sr-modal-event-delete,
#sr-modal-newsfeed-delete {
    color: #454545;
}

#sr-missing-field-message-text, #sr-modal-success-message-text, #sr-missing-field-message-text, #rtedMessageText, #sr-error-message-text {
    margin-top: 5px;
}

#sr-missing-field-message, #sr-modal-success-message, #rteMessage, #sr-error-message-text {
    display: flex;
}

#sr-error-message-text .sr-error
{
    font-weight: normal;
}

#sr-modal-event-delete .modal-body,
#sr-modal-newsfeed-delete .modal-body {
    min-height: 30px;
}
