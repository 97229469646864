.kommune-page {

    section {
        background-color: #FBFBFB;
        padding: 20px 0 30px;
    }

    section.without-padding {
        padding: 0;
    }

    .custom-dropdown {
        width:50%
    }

    .event-section {
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: -50px;
    }

    .partner-container {
        margin-left: 130px;
        margin-right: 130px;
        width: inherit;
    }
    .partner-lokal {
        .grid-wrapper {
            justify-content: center;
        }
    }

    .sr-icon-grid {
        margin: 0 0 50px;
    }

    /* Custom URL style for chat */
    .chat-messages {
        .message {
            a {
                color: #fff;
                text-decoration: underline;
                &:hover {
                    color: #fff;
                    text-decoration: underline;
                }
            }
        }
    }

    .link-back {
        position: absolute;
        top: 23px;
        left: 30px;
        color: #fff;
        font-size: 14px;
        line-height: 16px;
        svg {
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
        }
    }

    .info-right-top {
        font-size: 16px;
        position: absolute;
        top: 10px;
        right: 10px;
        opacity: 0.5;
    }

    .report-user {
        right: 25px;
    }


    #tc-navigation {
        display: flex;
        justify-content: center;
    }

    .icon-title {
        display: flex;
        .info_small {
            font-size: 20px;
            opacity: 0.5;
            height: 20px;
            width: 10px;
            margin-left: 10px;
            bottom: 3px;
        }
    }

    .logo-kommune {
        margin-top: 60px;
    }

    #radelnde-chart {
        text.ct-axis-title {
            &:nth-child(1) {
                transform: translate(10px, -20px);
            }
        }
    }

    .city_contact_info {
        >h3 {
            margin-bottom: 28px;
        }
    }

    .city-contact {
        .btn-success {
            width: 260px;
            margin-top: 18px;
        }
        .extra-contact {
            padding-top: 46px;
            font-size: 14px;
            line-height: 22px;
            font-weight: 300;
            a {
                font-weight: 500;
            }
        }
    }

    .city-requirements {
        >h3 {
            margin-bottom: 28px;
        }
    }

    .tc-statements-items {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .city_info_text {
        margin-top: 22px;
        font-size: 14px;
        line-height: 22px;
        font-weight: 300;
        a {
            font-weight: 500;
        }
    }

    .bar-step {
        line-height: 26px;
        z-index: 99;
    }

    .city_contact_info {
        font-size: 14px;
        line-height: 22px;
        font-weight: 300;
        a {
            font-weight: 500;
        }
    }

    .jumpmarks.sidebar-menu {
        display: none;
    }

    #sr-mobile-anchor-links-dropdown {
        font-size: 14px;
    }

    .light-bg {
        .btn-default {
            color: #454545 !important;
            background-color: transparent !important;
            border-color: #454545 !important;
        }
        .rofile-card {
            .btn.btn-default {
                &:hover {
                    background-color: rgba(69, 69, 69, 0.07) !important;
                }
                &:focus {
                    background-color: rgba(69, 69, 69, 0.07) !important;
                }
            }
            .btn.btn-default.focus {
                background-color: rgba(69, 69, 69, 0.07) !important;
            }
        }
    }
    .press-files {
        margin-top: 30px;
        li {
            margin-top: 15px;
            list-style-type: none;
        }
        li:before {
            content: '\e902';
            display: inline-block;
            text-align: center;
            vertical-align: middle;
            font-family: sr-icons;
            font-size: 16px;
            line-height: 1;
            font-weight: normal;
            text-rendering: auto;
            margin: 3px 00px 5px;
            color: #0086C5;
        }
    }
    .info-padding {
        padding: 10px 0 0;
    }
    .greeting-padding {
        padding: 0 0 40px;
    }
    .main-image {
        border-radius: 7px;
        width: 100%;
        object-fit: cover;
        margin-bottom: 50px;
    }
    #image-lightbox {
        .extra-image {
            height: 60vh;
            width: auto;
            margin: 0 auto;
            border-radius: 7px;
            cursor: default;
            max-height: 75vh;
            max-width: 100%;
            object-fit: contain;
        }
    }
    .press-extra-images-wrapper {
        display: flex;
        justify-content: space-between;
        gap: 30px;
        flex-wrap: wrap;
        width: 100%;
        .extra-image {
            height: 208px;
            width: 208px;
            border-radius: 7px;
            margin: 37px 0 37px 0;
            cursor: pointer;
            object-fit: cover;
        }
    }

    .event-entries {
        .event-entry {
            margin-bottom: 50px;
            h3 {
                text-align: left;
                margin-bottom: 10px
            }
            .event-entry-date {
                margin-bottom: 20px;
            }
        }
    }

    .kommune-titel-align {
        align-items: center;
    }

    .kommune-mt-large {
        margin-top: 70px;
    }

    ul.downloads {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li {
            margin: 0;
        }
    }

    ul.downloads::after {
        content:inherit;
    }

    .kommune-event-margin {
        margin-bottom: 40px;
    }

    .team-captain-margin {
        padding-top: 70px;
    }

    /* tablet */
    @media (min-width: 768px) and (max-width: 992px) {
        section {
            padding: 10px 0 20px;
        }

        .container {
            width: 790px;
        }

        .partner-container {
            margin-left: 0px;
            margin-right: 0px;
            padding-left: 24px;
            padding-right: 24px;
            h2 {
                text-align: left;
            }
        }

        .event-section {
            margin-bottom: -25px;
        }

        /*
        .ce-module [class^="col-"], .ce-module [class*=" col-"] {
            padding-left: 0;
        }
        */

        .kommune-event-margin {
            margin-top: 40px;
            margin-bottom: 30px;
        }

        .kommune-mt-large {
            margin-top: 40px;
        }

        .kommune-titel-align {
            align-items: start;
        }

        ul.downloads {
            margin: 0;
            display: flex;
            justify-content: space-between;
            li {
                width: 30%;
                margin: 0;
                height: 185px;
            }
        }

        .section-kommune {
            padding-top: 50px;
        }

        .jumpmarks.sidebar-menu {
            display: inherit;
        }

        .logo-kommune {
            margin-top: 50px;
        }

        .sr-icon-grid {
            li {
                min-width: 30%;
                flex-basis: 140px !important;
            }
        }

        .city-requirements {
            margin-top: 30px;
        }

        .city-contact {
            .extra-contact {
                padding-top: 42px;
            }
        }

        .gallery-margin {
            padding-top: 40px;
        }

        .team-captain-margin {
            padding-top: 80px;
        }

    }

    @media (min-width: 768px) {
        ul.downloads {
            li {
                margin: 2%;
                width: 29%;
            }
        }
        .section-heading {
            text-align: center;
        }
        .bar-step {
            font-size: 14px;
        }
    }

    /* mobile */
    @media (max-width: 767px) {
        section {
            padding: 10px 0 20px;
        }

        .partner-container {
            > .row {
                margin-left: 15px;
                margin-right: 15px;
            }
        }

        .partner-lokal {
            .grid-item {
                img {
                    height: 50px;
                    opacity: 1;
                }
            }
        }

        .m03-text-bild {
            .col-image {
                margin-left: -30px;
                margin-right: -30px;
            }
            img {
                width: 100%;
            }
        }

        .event-section {
            margin-bottom: -45px;
        }

        .gallery-margin {
            padding-top: 20px;
        }

        .custom-dropdown {
            width:100%
        }

        .kommune-event-margin {
            margin-top: 40px;
            margin-bottom: 30px;
        }

        .kommune-mt-large {
            margin-top: 50px;
        }

        .pl-30-mobile {
            padding-left: 30px;
        }

        .pl-15-mobile {
            padding-left: 15px;
        }

        .kommune-titel-align {
            align-items: start;
        }

        .section-heading {
            text-align: center;
        }

        .sr-icon-grid {
            margin: 10px 10px 10px;
        }

        .jumpmarks.sidebar-menu {
            display: inherit;
        }

        .logo-kommune {
            margin-top: 50px;
        }

        .ui-widget-content {
            border: 0 !important;
            background: rgba(5,40,54,0.88) !important;
            color: #fff;
            color: rgba(255,255,255,1);
            font-family: Roboto;
            font-size: 12px;
            font-weight: 300;
            letter-spacing: 0.56px;
            line-height: 16px;
            text-shadow: 0 2px 31px 0 rgba(0,0,0,0.79);
        }

        .city-contact {
            .btn-success {
                width: 100%;
            }
            .extra-contact {
                padding-top: 0;
            }
        }

        .city-requirements {
            margin-top: 60px;
        }

        .m01-text {
            overflow-x: inherit;
        }

        .press-extra-images-wrapper {
            gap: inherit;
            width: 115%;
            .extra-image {
                height: auto;
                width: 85%;
                margin: 36px 0;
                aspect-ratio: 1;
            }
        }

        .star-fragment-wrapper {
            margin-left: -15px;
            margin-right: -30px;
        }

        ul.downloads {
            flex-direction: column;
            margin-left: 15px;
            margin-right: 15px;
            width: 90%;
            li {
                margin-bottom: 30px;
            }
        }

        .team-captain-margin {
            padding-top: 40px;
        }

        .tc-statements {
            margin-left: 15px;
            margin-right: 15px;
        }

    }
}
