.table {
	>thead {
		>tr {
			>td.active {
				background-color: white;
			}
			>th.active {
				background-color: white;
			}
			>td.success {
				background-color: #dff0d8;
			}
			>th.success {
				background-color: #dff0d8;
			}
			>td.info {
				background-color: #d9edf7;
			}
			>th.info {
				background-color: #d9edf7;
			}
			>td.warning {
				background-color: #fcf8e3;
			}
			>th.warning {
				background-color: #fcf8e3;
			}
			>td.danger {
				background-color: #f2dede;
			}
			>th.danger {
				background-color: #f2dede;
			}
		}
		>tr.active {
			>td {
				background-color: white;
			}
			>th {
				background-color: white;
			}
		}
		>tr.success {
			>td {
				background-color: #dff0d8;
			}
			>th {
				background-color: #dff0d8;
			}
		}
		>tr.info {
			>td {
				background-color: #d9edf7;
			}
			>th {
				background-color: #d9edf7;
			}
		}
		>tr.warning {
			>td {
				background-color: #fcf8e3;
			}
			>th {
				background-color: #fcf8e3;
			}
		}
		>tr.danger {
			>td {
				background-color: #f2dede;
			}
			>th {
				background-color: #f2dede;
			}
		}
	}
	>tbody {
		>tr {
			>td.active {
				background-color: white;
			}
			>th.active {
				background-color: white;
			}
			>td.success {
				background-color: #dff0d8;
			}
			>th.success {
				background-color: #dff0d8;
			}
			>td.info {
				background-color: #d9edf7;
			}
			>th.info {
				background-color: #d9edf7;
			}
			>td.warning {
				background-color: #fcf8e3;
			}
			>th.warning {
				background-color: #fcf8e3;
			}
			>td.danger {
				background-color: #f2dede;
			}
			>th.danger {
				background-color: #f2dede;
			}
		}
		>tr.active {
			>td {
				background-color: white;
			}
			>th {
				background-color: white;
			}
		}
		>tr.success {
			>td {
				background-color: #dff0d8;
			}
			>th {
				background-color: #dff0d8;
			}
		}
		>tr.info {
			>td {
				background-color: #d9edf7;
			}
			>th {
				background-color: #d9edf7;
			}
		}
		>tr.warning {
			>td {
				background-color: #fcf8e3;
			}
			>th {
				background-color: #fcf8e3;
			}
		}
		>tr.danger {
			>td {
				background-color: #f2dede;
			}
			>th {
				background-color: #f2dede;
			}
		}
	}
	>tfoot {
		>tr {
			>td.active {
				background-color: white;
			}
			>th.active {
				background-color: white;
			}
			>td.success {
				background-color: #dff0d8;
			}
			>th.success {
				background-color: #dff0d8;
			}
			>td.info {
				background-color: #d9edf7;
			}
			>th.info {
				background-color: #d9edf7;
			}
			>td.warning {
				background-color: #fcf8e3;
			}
			>th.warning {
				background-color: #fcf8e3;
			}
			>td.danger {
				background-color: #f2dede;
			}
			>th.danger {
				background-color: #f2dede;
			}
		}
		>tr.active {
			>td {
				background-color: white;
			}
			>th {
				background-color: white;
			}
		}
		>tr.success {
			>td {
				background-color: #dff0d8;
			}
			>th {
				background-color: #dff0d8;
			}
		}
		>tr.info {
			>td {
				background-color: #d9edf7;
			}
			>th {
				background-color: #d9edf7;
			}
		}
		>tr.warning {
			>td {
				background-color: #fcf8e3;
			}
			>th {
				background-color: #fcf8e3;
			}
		}
		>tr.danger {
			>td {
				background-color: #f2dede;
			}
			>th {
				background-color: #f2dede;
			}
		}
	}
}
.table-hover {
	>tbody {
		>tr {
			>td.active {
				&:hover {
					background-color: #f2f2f2;
				}
			}
			>th.active {
				&:hover {
					background-color: #f2f2f2;
				}
			}
			&:hover {
				>.active {
					background-color: #f2f2f2;
				}
				>.success {
					background-color: #d0e9c6;
				}
				>.info {
					background-color: #c4e3f3;
				}
				>.warning {
					background-color: #faf2cc;
				}
				>.danger {
					background-color: #ebcccc;
				}
			}
			>td.success {
				&:hover {
					background-color: #d0e9c6;
				}
			}
			>th.success {
				&:hover {
					background-color: #d0e9c6;
				}
			}
			>td.info {
				&:hover {
					background-color: #c4e3f3;
				}
			}
			>th.info {
				&:hover {
					background-color: #c4e3f3;
				}
			}
			>td.warning {
				&:hover {
					background-color: #faf2cc;
				}
			}
			>th.warning {
				&:hover {
					background-color: #faf2cc;
				}
			}
			>td.danger {
				&:hover {
					background-color: #ebcccc;
				}
			}
			>th.danger {
				&:hover {
					background-color: #ebcccc;
				}
			}
		}
		>tr.active {
			&:hover {
				>td {
					background-color: #f2f2f2;
				}
				>th {
					background-color: #f2f2f2;
				}
			}
		}
		>tr.success {
			&:hover {
				>td {
					background-color: #d0e9c6;
				}
				>th {
					background-color: #d0e9c6;
				}
			}
		}
		>tr.info {
			&:hover {
				>td {
					background-color: #c4e3f3;
				}
				>th {
					background-color: #c4e3f3;
				}
			}
		}
		>tr.warning {
			&:hover {
				>td {
					background-color: #faf2cc;
				}
				>th {
					background-color: #faf2cc;
				}
			}
		}
		>tr.danger {
			&:hover {
				>td {
					background-color: #ebcccc;
				}
				>th {
					background-color: #ebcccc;
				}
			}
		}
	}
}
