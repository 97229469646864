#admin-auswertung {
    h2 {
        font-size: 20px;
    }

    /* ==== Select2 Dropdown with collapsable groups */

    .custom-dropdown .btn, .select2-selection {
        min-width: 240px;
        font-size: 12px;
        font-weight: 500;
        border-color: #0d4058 !important;
        background-color: #0d4058 !important;
        color: rgba(254,254,254,0.8) !important;
        transition: background-color 500ms;
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        touch-action: manipulation;
        cursor: pointer;
        background-image: none;
        padding: 3px 9px;
        padding-right: 40px;
        padding-left: 40px;
    }

    .custom-dropdown li a {
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;
    }

    .select2-container .select2-selection--single {
        height: 37px;
        width: 100%;
    }

    .select2-container--default .select2-selection--single .select2-selection__placeholder{
        color: rgba(254,254,254,0.8) !important;
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow {
        top: 6px;
        right: 10px;
    }

    .select2-container--default .select2-results>.select2-results__options {
        max-height: 500px;
        overflow-y: auto;
    }


    .select2-container--default .select2-selection--single .select2-selection__arrow b {
        color: rgba(254,254,254,0.8) !important;
        border-color: rgba(254,254,254,0.8) transparent rgba(254,254,254,0.8) transparent;
    }

    .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
        border-color: transparent transparent rgba(254,254,254,0.8) transparent;
    }

    .select2-container--default .select2-search--dropdown .select2-search__field {
        border: 0px solid rgba(20, 104, 142, 0.5);
        background-color: #063548;
        color: #fff !important;
    }

    .select2-container--open .select2-dropdown--below {
        background-color: #073B50 !important;
        border: 1px solid rgba(20, 104, 142, 0.5) !important;
    }


    .select2-search--dropdown {
        padding: 6px;
    }

    .select2-container--open li.select2-results__option:first-child {
        border-top: 0px !important;
    }

    .select2-container--open .select2-selection--single {
        border-color: #073B50 !important;
    }

    .select2-container--open .select2-dropdown .select2-results ul.select2-results__options {
        position:inherit;

    }

    .select2-container--open .select2-dropdown .select2-results ul.select2-results__options li.select2-results__option {
        color: rgba(255,255,255,0.5);
        font-size: 12px;
        font-weight: normal;
    }


    .select2-results__group{
        position: relative;
    }

    .select2-search.select2-search--dropdown{
        position: relative;
    }

    .select2-search.select2-search--dropdown:after {
        position: absolute;
        right: 18px;
        top: 4px;
        font-size: 18px;
        height: 10px;
        width: 10px;
        border: none;
        background: none;
        text-align: center;
        line-height: 32px;
        font-family: sr-icons;
        color: rgba(255,255,255,0.3);
    }

    .select2-search.select2-search--dropdown:after {
        content: "\e947" !important;
    }

    .select2-results__group:after {
        position: absolute;
        content: "\e941" !important;
        transform: rotate(-90deg);
        right: 22px;
        top: 14px;
        font-size: 6px;
        height: 10px;
        width: 10px;
        border: none;
        background: none;
        text-align: center;
        line-height: 32px;
        font-family: sr-icons;
        color: white;
    }

    .select2-results__group.open:after {
        content: "\e942" !important;
        transform: rotate(180deg);
        right: 12px;
        top: 25px;
    }

    .select2-results__group.open {
        color: #fff;
        padding-bottom: 5px !important;
    }

    ul.select2-results__options.open {
        box-shadow: 0 0 black !important;
        margin-top: 0px !important;
    }

    ul.select2-results__options.open li {
        background-color: rgba(14,71,97,1);
        border-left: 0px !important;
        border-right: 0px !important;
        color: rgba(255,255,255,0.5);
        font-size: 12px;
        font-weight: 500;
    }

    .select2-container--default .select2-results__group {
        cursor: default;
        display: block;
        padding: 0px;
        font-weight: normal;
    }
}
