.nutzerverwaltung-editieren {

    .rk-compat-2017 {
        .checkbox {
            label {
                &:before {
                    background-color: transparent;
                }
            }
        }
    }

    .checkbox.disabled {
        opacity: 0.6;
        pointer-events: none;
    }
    @media (min-width: 768px) {
        .form-horizontal {
            .form-column.form-width-75 {
                width: 75% !important;
            }
        }
    }
    @media (max-width: 991px) {
        .new-team-button-wrapper {
            margin-top: 20px;
        }
    }
    @media (max-width: 767px) {
        body {
            font-size: 14px;
        }
        .form-group-sm {
            .form-control {
                font-size: 16px;
            }
        }
        .form-group {
            .select2.select2-container.select2-container--default {
                .select2-selection--single {
                    font-size: 16px;
                }
            }
        }
        .xs-bg-lighter {
            background-color: rgba(7,59,80,1) !important;
        }
        .content {
            >.row {
                margin-left: 0px !important;
                margin-right: 0px !important;
            }
        }
        .xs-no-gutters-30 {
            padding: 30px;
            margin: -30px;
        }
        .user-profile-img {
            img {
                height: 100%;
                width: 100%;
            }
        }
    }


}