// admin/cities/signups/overview
.page-wrw {

    .status-info {
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px;
        width: 16px;
        height: 16px;
        border-radius: 2px;
        background-color: $status-progress;
        /* #fdfd00 */
        &.status-done {
            background-color: $status-ok;
        }
    }
    .link-back {
        display: block;
        margin-bottom: 90px;
        color: #fff;
        font-size: 14px;
        line-height: 16px;
        svg {
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
        }
    }

    #wrw-search {
        &.form-control {
            &::placeholder {
                visibility: visible!important;
                color: #b0b0b0!important;
            }
        }
    }

    .icon-edit {
        color: rgba(255, 255, 255, 0.5);
        font-size: 18px;
        cursor: pointer;
        margin-left: 0;
        text-align: center;
    }

    textarea {
        width: 160px;
        height: 60px;
        padding: 10px;
        background-color: transparent;
        border: 1px solid #fff;
        border-radius: 4px;
        resize: none;
        font-size: 14px;
        line-height: 19px;
        color: rgba(255, 255, 255, 0.5);
        outline: none;
    }

    table {
        &.dataTable {
            thead {
                .sorting::after {
                    opacity: 1;
                    font-family: sr-icons;
                    content: "\e5c5" !important;
                    font-size: 25px;
                }
                .sorting_asc {
                    color: #fff;
                    &::after {
                        opacity: 1;
                        font-family: sr-icons;
                        content: "\e5c5" !important;
                        font-size: 25px;
                    }
                }
                .sorting_desc {
                    color: #fff;
                    &::after {
                        opacity: 1;
                        font-family: sr-icons;
                        content: "\e5c7" !important;
                        font-size: 25px;
                    }
                }
                > tr > th {
                    font-size: 13px;
                    line-height: 17px;
                    font-weight: 400;
                    border-bottom: 0;
                    padding-left: 0;
                    color: rgba(255, 255, 255, 0.5);
                    white-space: nowrap;
                    &.col-merch {
                        padding-left: 15px;
                        padding-right: 15px;
                        text-align: center;
                    }
                }
                // override bs datatables
                .sorting::after,
                .sorting_asc::after,
                .sorting_desc::after,
                .sorting_asc_disabled::after,
                .sorting_desc_disabled::after {
                    position: relative;
                    display: inline-block;
                    bottom: unset;
                    right: unset;
                    top: 5px;
                    left: 0;
                }
            }
            tbody {
                tr {
                    background-color: transparent;
                    height: 75px;
                    &:first-child td {
                        border: 0;
                    }
                    > td {
                        padding: 0 7px 0 0;
                        vertical-align: middle;
                        &:first-child {
                            padding-left: 0;
                        }
                        &:last-child {
                            padding-right: 0;
                        }
                        &.col-merch {
                            padding-left: 0;
                            padding-right: 1px;
                        }
                    }
                }
            }
            td {
                .merch {
                    font-size: 11px;
                    line-height: 12px;
                    letter-spacing: 0.25px;
                    padding: 0 2px;
                    position: absolute;
                    bottom: 48px;
                    width: 100%;
                    font-weight: 400;
                    color: #fff;
                }
            }
        }
        /* datatable overrides */
        &.DTFC_Cloned {
            thead, tfoot {
                background-color: transparent;
            }
        }
    }
    .dataTables_wrapper {
        .dataTables_paginate {
            padding-top: 70px;
            .paginate_button {
                font-size: 16px;
                position: relative;
                background: transparent;
                color: #fff;
                &.current {
                    background: transparent;
                    color: $active !important;
                    font-weight: 600;
                    border: 0;
                    &:hover {
                        background: transparent;
                        color: $active;
                        border: 0;
                    }
                }
                &.disabled {
                    .pagination-icon {
                        fill: rgba(255, 255, 255, 0.4);
                    }
                }
                .pagination-icon {
                    height: 15px;
                    fill: #fff;
                    position: absolute;
                    top: 12px;
                    left: 0px;
                }
            }
        }
    }
    .dataTables_filter, 
    .dataTables_length {
        display: none;
    }   
    /*
    #sr-wrw-table_wrapper table.dataTable tbody tr {
        background-color: transparent;
        height: 75px;
    }*/

    .col-rng-select {
        margin-right: 12px;
    }

    .checkbox-wrapper {
        display: flex;
        flex-direction: column;
        min-width: 60px;
        min-height: 74px;
        justify-content: center;
        position: relative;
        text-align: center;
        label {
            margin: 0;
            padding: 29px 22px;
        }
    }

    // spinner
    @keyframes Rotate {
        from { transform: rotate(0deg) }
        to { transform: rotate(360deg) }
    }
    .spinner { 
        position: relative;
        animation: Rotate 1s linear infinite;
    }
    div.dataTables_wrapper div.dataTables_processing {
        top: 43%;
    }

    // checkbox override
    &.uc {
        &.dark-bg {
            .checkbox-wrapper {
                input[type="checkbox"] + label::before {
                    margin: 0 auto;
                }
                input[type="checkbox"]:checked + label::before {
                    background-color: $active;
                    border: 1.5px solid $active;
                    font-size: 13px;
                    padding: 0;
                }
            }
        }
    }

    /* NumberOfResults */
    .numberOfResults {
        margin-top: -120px;
        font-size: 12px;
        line-height: 13px;
        letter-spacing: 0.3px;
        > div, 
        > span {
            float: left;
            display: inline-table;
            margin-right: 10px;
            color: rgba(255, 255, 255, 0.5);
        }
        > div {
            cursor: pointer;
            &.active {
                cursor: pointer;
                color:$active;
            }
        }
    }
    @media (min-width: 1200px) {
        .container-xl {
            width: 1340px;
        }
    }
    /* tablet */
    @media (min-width: 768px) and (max-width: 992px) {
        .dataTables_wrapper {
            overflow-x: scroll;
        }
    }
}
