/* TODO: clear possible non used classes */

/*
    stepwizard-row
    stepwizard
    stepwizard-step
    btn-circle
    margin-bottom-twenty
    margin-top-ten
    no-margin-bottom
    no-margin
    well-min-height
    well-disabled
    btn-default.active
    btn-success
    btn-tab
*/

.registrierung {
    #sr-reg-form {
        min-height: 350px;
    }
    .stepwizard-step {
        p {
            margin-top: 10px;
        }
        .disabled {
            opacity: 1 !important;
            filter: alpha(opacity=100) !important;
        }
        display: table-cell;
        text-align: center;
        position: relative;
    }
    .stepwizard-row {
        display: table-row;
    }
    .stepwizard {
        display: table;
        width: 100%;
        position: relative;
    }
    .setup-content {
        min-height: 400px;
        margin-bottom: 0;
        .well {
            -webkit-transition: height 2s;
            -moz-transition: height 2s;
            -ms-transition: height 2s;
            -o-transition: height 2s;
            transition: height 2s;
        }
    }
    .btn-circle {
        width: 100%;
        height: 5px;
        text-align: center;
        padding: 0;
        font-size: 12px;
        line-height: 1.428571429;
        margin: 0;
        background-color: #76a230;
        border: 0;
    }
    // .control-label {
    //     display: block !important;
    // }
    .disabled {
        .control-label {
            color: rgba(69,69,69,0.3);
        }
    }
    .sr_start_content {
        .container {
            a {
                font-weight: 500;
            }
        }
    }
    .margin-top-twenty {
        margin-top: 20px;
    }
    .margin-bottom-twenty {
        margin-bottom: 20px;
    }
    .margin-top-ten {
        margin-top: 10px;
    }
    .no-margin-bottom {
        margin-bottom: 0 !important;
    }
    .no-margin {
        margin: 0 !important;
    }
    .bg-white {
        background-color: #fff !important;
    }
    .well-min-height {
        min-height: 200px;
    }
    .well {
        color: rgba(69,69,69,1);
        text-shadow: 0 2px 31px 0 rgba(0,0,0,0.79);
        font-weight: 300;
        border: 1px solid rgba(245,245,245,1);
        background-color: #fff;
        box-shadow: none;
        border-radius: 0;
        padding: 17px 30px;
    }
    .radio {
        margin-top: 0;
    }
    .checkbox {
        margin-top: 0;
        margin-left: 10px;
    }
    .well-disabled {
        opacity: 0.6;
        pointer-events: none;
    }
    .well-active {
        box-shadow: 0 2px 15px 0 rgba(0,0,0,0.05);
    }
    .well-shadow {
        border-color: rgba(0, 0, 0, 0.14);
        -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
    }

    .checkbox {
        input[type="checkbox"] {
            &:checked {
                + {
                    label {
                        &::before {
                            content: "\f00c";
                            color: #fff;
                            border: 1px solid #73a425;
                        }
                    }
                }
            }
        }
        label {
            line-height: 1.5;
        }
    }
    .btn-default.active {
        &:focus {
            background: #e0e0e0;
            border-color: rgba(0, 0, 0, 0.125);
            -webkit-box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.125);
            box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.125);
            outline: none;
        }
    }
    .btn-primary {
        background-color: #8cb82b;
        border: none !important;
        color: white !important;
        -webkit-transition: all .05s linear;
        -o-transition: all .05s linear;
        transition: all .05s linear;
        &:hover {
            background-color: #769c24;
            border-color: rgba(0, 0, 0, 0.15);
            color: white;
        }
        &:focus {
            background: #769c24;
            border-color: rgba(0, 0, 0, 0.125);
            -webkit-box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.125);
            box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.125);
            outline: none;
        }
        &:active {
            background: #769c24;
            border-color: rgba(0, 0, 0, 0.125);
            -webkit-box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.125);
            box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.125);
            outline: none;
            &:hover {
                background: #769c24;
                border-color: rgba(0, 0, 0, 0.125);
                -webkit-box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.125);
                box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.125);
                outline: none;
            }
            &:focus {
                background: #769c24;
                border-color: rgba(0, 0, 0, 0.125);
                -webkit-box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.125);
                box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.125);
                outline: none;
            }
        }
    }
    .btn-primary.focus {
        background: #769c24;
        border-color: rgba(0, 0, 0, 0.125);
        -webkit-box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.125);
        box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.125);
        outline: none;
    }
    .btn-primary.active {
        background: #769c24;
        border-color: rgba(0, 0, 0, 0.125);
        -webkit-box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.125);
        box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.125);
        outline: none;
        &:hover {
            background: #769c24;
            border-color: rgba(0, 0, 0, 0.125);
            -webkit-box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.125);
            box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.125);
            outline: none;
        }
        &:focus {
            background: #769c24;
            border-color: rgba(0, 0, 0, 0.125);
            -webkit-box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.125);
            box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.125);
            outline: none;
        }
    }
    .btn-success {
        color: #ffffff;
        background-color: #8cb82b;
        border-color: #8cb82b;
        &:focus {
            color: #ffffff;
            background-color: #8cb82b;
            border-color: #8cb82b;
        }
    }
    .btn-success.focus {
        color: #ffffff;
        background-color: #8cb82b;
        border-color: #8cb82b;
    }
    .btn-tab {
        margin-right: 20px;
        border-radius: 2px !important;
        min-width: 150px;
        span {
            &:before {
                content: "\f096";
                font-family: FontAwesome;
                margin-right: 5px;
            }
        }
    }
    .btn-tab.active {
        span {
            &:before {
                content: "\f046";
                font-family: FontAwesome;
                margin-right: 5px;
            }
        }
    }
    #sr-reg-schulradeln-wrapper {
        min-height: 50px;
    }
    .select2-selection--single {
        width: 100%;
    }
    #DSEModal {
        .modal-content {
            background-color: #fff;
        }
    }
    #SRModal {
        .modal-content {
            background-color: #fff;
        }
    }
    section {
        background-color: #fbfbfb;
    }
    .wizard {
        margin: 10px auto;
        margin-top: 0;
        .nav-tabs {
            position: relative;
            margin: 0 auto;
            margin-bottom: 40px;
            border-bottom: 0;
            width: 790px;
            >li.active {
                >a {
                    color: #fff;
                    cursor: default;
                    border: 0;
                    border-bottom-color: transparent;
                    &:hover {
                        color: #fff;
                        cursor: default;
                        border: 0;
                        border-bottom-color: transparent;
                    }
                    &:focus {
                        color: #fff;
                        cursor: default;
                        border: 0;
                        border-bottom-color: transparent;
                    }
                }
            }
            >li {
                width: 20%;
                height: 100px;
                >a {
                    border: 0;
                    pointer-events: none;
                    cursor: default;
                }
                a {
                    width: 40px;
                    height: 40px;
                    margin: 20px auto 14px;
                    border-radius: 100%;
                    padding: 0;
                    color: #fff;
                    z-index: 2;
                    &:hover {
                        background: transparent;
                    }
                }
            }
        }
        >div.wizard-inner {
            position: relative;
        }
        li {
            a.disabled {
                span.round-tab {
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    display: inline-block;
                    border-radius: 100px;
                    background: #fbfbfb;
                    border: 1px solid #8CB82B;
                    color: #8CB82B;
                    z-index: 2;
                    position: absolute;
                    left: 0;
                    text-align: center;
                    font-size: 15px;
                }
            }
        }
        .tab-pane {
            position: relative;
            padding-top: 50px;
        }
    }
    .connecting-line {
        height: 1px;
        background: #8CB82B;
        position: absolute;
        width: 630px;
        margin: 0 auto;
        left: 0;
        right: 0;
        top: 40%;
        z-index: 1;
    }
    span.round-tab {
        width: 40px;
        height: 40px;
        line-height: 40px;
        display: inline-block;
        border-radius: 100px;
        background: #8CB82B;
        border: 1px solid #8CB82B;
        text-align: center;
        font-size: 15px;
        i {
            color: #5bc0de;
        }
    }
    h2 {
        margin-top: 0 !important;
    }
    .step-name {
        text-align: center;
        font-size: 15px;
        line-height: 22px;
        color: #4A4A4A;
        opacity: 0.5;
    }
    .active {
        .step-name {
            opacity: 1;
        }
    }
    .form-horizontal {
        .form-column {
            width: 100%;
        }
    }
    .schulradeln-wrapper {
        padding-top: 20px;
    }
    .radio {
        label {
            &::before {
                width: 16px;
                height: 16px;
                background-color: #FFFFFF;
                display: inline-block;
                content: "";
                border-radius: 10px;
                border: 1.5px solid #b7b7b7;
                margin-right: 14px;
            }
        }
        input[type="radio"] {
            &:checked {
                + {
                    label {
                        &::before {
                            background-color: #8cb82b;
                            box-shadow: inset 0 0 0 2px #FFFFFF;
                            border-width: 1px;
                        }
                    }
                }
            }
        }
    }

    /* =============  Accordion  ================ */
    /* mobile */
    /* required to fix justify content */
    @media (min-width: 768px) {
        .modal-dialog {
            width: 680px;
            margin: 220px auto;
        }
        .form-horizontal {
            .control-label {
                width: 40% !important;
                padding-top: 3px !important;
            }
            .form-column {
                width: 60% !important;
            }
            .control-label.datenschutz {
                width: 23% !important;
            }
            .form-column.datenschutz {
                width: 77% !important;
            }
        }
        .checkbox {
            .control-label {
                width: 95% !important;
            }
        }
        .wizard-popover {
            display: none !important;
        }
    }
    @media (min-width: 768px) and (max-width: 992px) {
        .schulradeln-wrapper {
            position: relative;
            width: 100%;
        }
    }
    .checkbox {
        label {
            &:before {
                margin-left: -25px;
                background-color: #fff;
                border: 1px solid #a5a5a5;
            }
        }
        .control-label {
            width: 95% !important;
        }
    }
    .form-horizontal {
        .checkbox {
            label {
                &::before {
                    left: -5px;
                    top: 5px;
                    border: 2px solid rgba(69, 69, 69, 0.38);
                }
                color: #000 !important;
                text-shadow: 0 2px 31px 0 rgba(0,0,0,0.79);
                font-weight: 300;
                left: 20px;
            }
        }
        .form-control {
            border-width: 0 0 1px;
            border-color: #969696;
        }
        .control-label {
            color: #454545;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: 0;
            font-weight: 300;
            opacity: 0.8;
            display: block;
        }
    }
    .accordion {
        h2 {
            margin-top: 0;
            position: relative;
            margin-bottom: 0;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    .well.accordion {
        h2 {
            img {
                width: 34px;
                height: 34px;
                -webkit-transition: 0.5s ease-in-out;
                -moz-transition: 0.5s ease-in-out;
                -o-transition: 0.5s ease-in-out;
                transition: 0.5s ease-in-out;
                transform: rotate(0deg);
                float: right;
            }
        }
        h2.collapsed {
            img {
                color: red;
                transform: rotate(-90deg);
            }
        }
        >a {
            color: rgba(69, 69, 69,1);
        }
    }
    .pflichtfeld {
        opacity: 0.5 !important;
        color: #454545 !important;
        font-family: Roboto !important;
        font-size: 12px !important;
        line-height: 17px !important;
        text-align: right !important;
        margin-top: -10px !important;
    }
    .checkbox-special-hight {
        padding-bottom: 20px;
    }
    .new-label {
        background-color: #8cb82b;
        padding: 2px 3px;
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 0.23px;
        line-height: 10px;
    }
    i.info {
        &::before {
            position: absolute;
            left: 5px;
            font-size: 17px;
            font-style: normal;
            top: -1px;
        }
    }
    label {
        display: inline-block;
        max-width: 100%;
        margin-bottom: 0;
        font-weight: bold;
    }
    .link-extern-arrow {
        position: relative;
        margin-right: 17px;
        &::before {
            position: absolute;
            top: 1px;
            right: -17px;
            content: "\e5db";
            display: inline-block;
            text-align: center;
            vertical-align: middle;
            font-family: sr-icons;
            font-size: 18px;
            line-height: 0.8;
            font-weight: normal;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            float: right;
            margin-left: 3px;
            transform: rotate(-135deg);
            transition: transform 0.2s ease;
        }
    }
    .schulradeln-float {
        display: block;
        text-align: center;
        width: 120px;
        margin: 0 auto;
        padding-bottom: 20px;
        img {
            max-width: 100%;
        }
    }
    .radkultur-float {
        display: block;
        text-align: center;
        width: 60px;
        margin: 0 auto;
        padding-bottom: 20px;
        img {
            max-width: 100%;
        }
    }
    .setup-content {
        >.col-md-6 {
            padding-right: 12px;
            padding-left: 12px;
        }
        .form-min-height {
            min-height: 270px;
        }
    }
    #sr-reg-school-selection-position {
        #sr-reg-teamname-group {
            .control-label {
                padding-right: 0 !important;
            }
        }
    }
    .info.reg-info-float::before {
        top: 5px;
    }
    @media (min-width: 992px) {
        .reg-info-float {
            max-width: 330px;
            position: absolute !important;
            right: -420px;
            top: 50px;
            margin: 0px 0px 0px 0px;
        }
    }

    @media (max-width: 585px ) {
        .accordion {
            h2 {
                font-size: 16px;
                padding-right: 20px;
            }
        }
        .wizard {
            width: 90%;
            height: auto !important;
        }
        .schulradeln-wrapper {
            margin-top: 0;
        }
        .well {
            margin-left: -15px;
            margin-right: -15px;
        }
    }
    @media (max-width: 990px) {
        .checkbox-special-hight {
            padding-bottom: 40px;
        }
        .checkbox-special-hight-xs-40 {
            padding-bottom: 40px;
        }
    }
    @media (max-width: 768px) {
        h3 {
            font-size: 19px;
            line-height: 24px;
            text-align: left;
        }
        .checkbox-special-hight {
            padding-bottom: 60px;
        }
        .checkbox-special-hight-xs-40 {
            padding-bottom: 60px;
        }
        .pflichtfeld {
            padding-right: 10px;
        }
        .connecting-line {
            width: 100%;
            top: 40px;
        }
        .step-name {
            font-size: 12px;
        }
        .wizard-popover {
            &.popover {
                &.bottom {
                    margin-top: -40px;
                }
            }
        }
        .nav {
            &::before {
                content: none;
            }
            &::after {
                content: none;
            }
        }
        .wizard {
            width: 100%;
            margin-bottom: 0;
            .nav-tabs {
                display: flex;
                justify-content: space-between;
                width: 100%;
                margin: 0;
                padding: 0;
                >li {
                    height: 80px;
                    width: 30px;
                    margin-top: 24px;
                    margin-bottom: 8px;
                    justify-content: center;
                    a {
                        margin: 24px 15px 8px 15px;
                        margin: 0 !important;
                        width: 30px;
                        height: 30px;
                        line-height: 30px;
                    }
                    &:first-child {
                        .step-name {
                            justify-content: start;
                        }
                    }
                    &:last-child {
                        .step-name {
                            justify-content: end;
                        }
                    }
                }
                .step-name {
                    display: none;
                    justify-content: center;
                }
                .active {
                    .step-name {
                        display: block;
                        overflow: visible;
                        display: flex;
                        justify-content: center;
                        white-space: nowrap;
                        margin-top: 5px;
                    }
                }
            }
            >div.wizard-inner {
                margin: 0 15px;
            }
            li {
                a.disabled {
                    span.round-tab {
                        width: 30px;
                        height: 30px;
                        line-height: 30px;
                    }
                }
            }
        }
        span.round-tab {
            font-size: 15px;
            width: 30px;
            height: 30px;
            line-height: 30px;
        }
        .setup-content {
            .form-min-height {
                min-height: fit-content;
            }
        }
    }
    @media (max-width: 480px) {
        .checkbox-special-hight {
            padding-bottom: 110px;
        }
        .checkbox-special-hight-xs-40 {
            padding-bottom: 60px;
        }
        .form-horizontal {
            .control-label {
                width: 100%;
            }
        }
        #sr-schulradeln-without-city-info-box {
            padding-left: 15px;
            padding-right: 15px;
        }
        .form-horizontal {
            .datenschutz {
                .checkbox {
                    label {
                        left: 0;
                    }
                }
            }
        }
    }
    @media (max-width: 350px) {
        .checkbox-special-hight {
            padding-bottom: 150px;
        }
    }
    @media (min-width: 515px) {
        .radkultur-float {
            position: absolute;
            top: -10px;
            right: 20px;
            width: 70px;
        }
        .schulradeln-float {
            position: absolute;
            top: 0;
            right: 20px;
            width: 160px;
        }
    }
    @media (min-width: 1025px) {
        .radkultur-float {
            position: absolute;
            top: -30px;
            right: 15px;
            width: 90px;
        }
        .schulradeln-float {
            position: absolute;
            top: 0;
            right: 15px;
            width: 160px;
        }
    }

}
