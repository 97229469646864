/*------------------------------------------------------------------
Custom Styles for > STADTRADELN 2018 
-------------------------------------------------------------------*/

/**
 * Comments
 */
.comments {
  margin: 20px 0;
}
.comments__heading {
  margin-bottom: 15px;
  font-weight: 600;
  text-transform: uppercase;
  color: #777777;
}
/* New comment */
form[name="comments__new"] {
  margin-bottom: 30px;
}
form[name="comments__new"]:before,
form[name="comments__new"]:after {
  content: " ";
  display: table;
}
form[name="comments__new"]:after {
  clear: both;
}
form[name="comments__new"]:before,
form[name="comments__new"]:after {
  content: " ";
  display: table;
}
form[name="comments__new"]:after {
  clear: both;
}
form[name="comments__new"] textarea {
  height: 32px;
}
form[name="comments__new"] button[type="submit"] {
  float: right;
}
/* List of comments */
.comments__item {
  margin-bottom: 30px;
}
.comments-item__avatar {
  float: left;
  margin-right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}
.comments-item__body {
  overflow: hidden;
}
.comments-item__info {
  margin-bottom: 10px;
}
.comments-item__info:before,
.comments-item__info:after {
  content: " ";
  display: table;
}
.comments-item__info:after {
  clear: both;
}
.comments-item__info:before,
.comments-item__info:after {
  content: " ";
  display: table;
}
.comments-item__info:after {
  clear: both;
}
.comments-item-info__author,
.comments-item-info__divider,
.comments-item-info__timestamp {
  float: left;
}
.comments-item-info__author {
  font-weight: 600;
}
.comments-item-info__divider {
  margin-left: 15px;
  margin-right: 15px;
  line-height: 18px;
  color: #ddd;
  font-size: 6px;
}
.comment-item-info__timestamp {
  color: #999;
}
.comments-item__content {
  line-height: 1.7;
  color: #777777;
  margin-bottom: 10px;
}
.comments-item__actions > a {
  color: #999;
}
.comments-item__actions > a:hover,
.comments-item__actions > a:focus {
  color: #333333;
}
/**
 * Forms
 */
.form-control,
.form-control:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}
/* Has error */
.has-error .form-control {
  border-color: #d9534f;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.has-error .form-control:focus {
  border-color: #b52b27;
}
.has-error .help-block {
  color: #d9534f;
}
/* Checkboxes */
.checkbox input[type="checkbox"] {
  display: none;
}
.checkbox label {
  padding-left: 0;
}
.checkbox label:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  background-color: #eee;
  text-align: center;
  font-family: "FontAwesome";
}
.checkbox input[type="checkbox"]:checked + label::before {
  content: "\f00c";
}
/* Radios */
.radio input[type="radio"] {
  display: none;
}
.radio label {
  padding-left: 0;
}
.radio label:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 10px solid #eee;
  background-color: #333333;
}
.radio input[type="radio"]:checked + label:before {
  border-width: 7px;
}
/* Quantity */
.input_qty {
  margin-bottom: 10px;
}
.input_qty input[type="text"] {
  display: none;
}
.input_qty label {
  width: 100%;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  line-height: 40px;
  text-align: center;
}
.input_qty label > span:not(.output) {
  width: 40px;
  height: 40px;
  float: left;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
}
.input_qty label > span:not(.output):last-child {
  float: right;
  border-right: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}
.input_qty label > span:not(.output):hover {
  background-color: rgba(0, 0, 0, 0.02);
}
.input_qty label > output {
  display: inline-block;
  line-height: inherit;
  padding: 0;
}
.input_qty_sm label {
  width: 80px;
  height: 20px;
  border: 0;
  line-height: 20px;
  color: #ccc;
}
.input_qty_sm label > span:not(.output) {
  width: 20px;
  height: 20px;
  border: 0 !important;
}
.input_qty_sm label > span:not(.output):hover {
  background-color: transparent;
  color: #333333;
}
.input_qty_sm label output {
  color: #ccc;
  font-weight: 600;
}
/**
 * Columns with equal height
 */
/* USAGE
<div class="row">
  <div class="row-height">
    <div class="col-xs-2 col-xs-height col-xs-middle">
      <div class="inside"></div>
    </div>
    <div class="col-xs-4 col-lg-5 col-xs-height col-xs-middle">
      <div class="inside"></div>
    </div>
  </div>
</div>
*/
/* content styles */
.inside-full-height {
  /*
  // if you want to give content full height give him height: 100%;
  // with content full height you can't apply margins to the content
  // content full height does not work in ie http://stackoverflow.com/questions/27384433/ie-display-table-cell-child-ignores-height-100
  */
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
/* columns of same height styles */
.row-height {
  display: table;
  table-layout: fixed;
  height: 100%;
  width: 100%;
}
.col-height {
  display: table-cell;
  float: none;
  height: 100%;
}
.col-top {
  vertical-align: top;
}
.col-middle {
  vertical-align: middle;
}
.col-bottom {
  vertical-align: bottom;
}
@media (min-width: 480px) {
  .row-xs-height {
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
  }
  .col-xs-height {
    display: table-cell;
    float: none;
    height: 100%;
  }
  .col-xs-top {
    vertical-align: top;
  }
  .col-xs-middle {
    vertical-align: middle;
  }
  .col-xs-bottom {
    vertical-align: bottom;
  }
}
@media (min-width: 768px) {
  .row-sm-height {
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
  }
  .col-sm-height {
    display: table-cell;
    float: none;
    height: 100%;
  }
  .col-sm-top {
    vertical-align: top;
  }
  .col-sm-middle {
    vertical-align: middle;
  }
  .col-sm-bottom {
    vertical-align: bottom;
  }
}
@media (min-width: 992px) {
  .row-md-height {
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
  }
  .col-md-height {
    display: table-cell;
    float: none;
    height: 100%;
  }
  .col-md-top {
    vertical-align: top;
  }
  .col-md-middle {
    vertical-align: middle;
  }
  .col-md-bottom {
    vertical-align: bottom;
  }
}
@media (min-width: 1200px) {
  .row-lg-height {
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
  }
  .col-lg-height {
    display: table-cell;
    float: none;
    height: 100%;
  }
  .col-lg-top {
    vertical-align: top;
  }
  .col-lg-middle {
    vertical-align: middle;
  }
  .col-lg-bottom {
    vertical-align: bottom;
  }
}
/**
 * Headline
 */
.section > .headline:first-child,
.section > .headline-lg:first-child {
  margin-top: 0;
}
.headline {
  display: block;
  position: relative;
  margin-bottom: 30px;
  font-size: 18px;
}
.headline:before {
  display: block;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  height: 6px;
  margin-top: -3px;
  border-width: 1px 0 1px;
  border-style: solid;
  border-color: #e9e9e9;
}
.headline > span {
  display: inline-block;
  position: relative;
  background: white;
  padding-right: 15px;
}
/* Headline: Large */
.headline-lg {
  margin-bottom: 30px;
  text-align: center;
  text-transform: uppercase;
  font-size: 22px;
}
.headline-lg + h4 {
  margin: -15px auto 45px;
  text-align: center;
  font-size: 14px;
  color: #757575;
}
.headline-lg_left {
  text-align: left;
}
.headline-lg_left + h4 {
  text-align: left;
}
.headline-lg_right {
  text-align: right;
}
.headline-lg_right + h4 {
  text-align: right;
}
/**
 * Info boards
 */
.info-board {
  margin-bottom: 20px;
  padding: 30px;
  background: #f9f9f9;
  border-top: 2px solid rgba(0, 0, 0, 0.2);
}
.info-board__header {
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: 600;
}
.info-board_primary {
  border-color: #0f70b7;
  background-color: rgba(237, 62, 73, 0.05);
}
.info-board_secondary {
  border-color: #8cb82b;
  background-color: rgba(43, 171, 207, 0.05);
}
/**
 * Lightbox
 */
.lightboxOverlay {
  -webkit-filter: ;
          filter: ;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.5);
}
.lightbox .lb-image,
.lb-outerContainer,
.lb-dataContainer {
  border-radius: 0;
}
*
 * Blackout
 */
.blackout {
  position: relative;
}
.blackout__layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0;
  -webkit-transition: opacity 1000ms linear;
       -o-transition: opacity 1000ms linear;
          transition: opacity 1000ms linear;
}
/**
 * Background parallax
 */
.bg-parallax,
.bg-parallax__layer {
  background-repeat: no-repeat;
  background-position: center top;
  -webkit-background-size: cover;
          background-size: cover;
}
.bg-parallax {
  position: relative !important;
  overflow: hidden !important;
  background-color: transparent !important;
}
.bg-parallax__layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  z-index: -1;
}
/**
 * Categories
 */
.categories-list {
  list-style: none;
  padding-left: 5px;
  margin-bottom: 20px;
}
.categories-list > li {
  padding: 4px 0;
}
/**
 * Rating
 */
.rating {
  display: inline-block;
  list-style: none;
  padding-left: 0;
  margin-bottom: 20px;
}
.rating li {
  display: inline-block;
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #F7C41F;
  cursor: pointer;
}
/**
 * Features list
 */
.ft-list {
  list-style-type: none;
  padding-left: 0;
}
.ft-list > li {
  padding: 3px 0;
}
.ft-list > li:before {
  content: "\f00c";
  font-family: FontAwesome;
  display: inline-block;
  margin-right: 10px;
  color: #8cb82b;
}
/* Animation delay */
.delay-1 {
  -webkit-animation-delay: 300ms;
  -o-animation-delay: 300ms;
     animation-delay: 300ms;
}
.delay-2 {
  -webkit-animation-delay: 600ms;
  -o-animation-delay: 600ms;
     animation-delay: 600ms;
}
.delay-3 {
  -webkit-animation-delay: 900ms;
  -o-animation-delay: 900ms;
     animation-delay: 900ms;
}
.delay-4 {
  -webkit-animation-delay: 1200ms;
  -o-animation-delay: 1200ms;
     animation-delay: 1200ms;
}
.delay-5 {
  -webkit-animation-delay: 1500ms;
  -o-animation-delay: 1500ms;
     animation-delay: 1500ms;
}
.delay-6 {
  -webkit-animation-delay: 1800ms;
  -o-animation-delay: 1800ms;
     animation-delay: 1800ms;
}
.delay-7 {
  -webkit-animation-delay: 2100ms;
  -o-animation-delay: 2100ms;
     animation-delay: 2100ms;
}
.delay-8 {
  -webkit-animation-delay: 2400ms;
  -o-animation-delay: 2400ms;
     animation-delay: 2400ms;
}
/* Circle text block */
/* Lists */
.list-style-none {
  list-style: none;
  padding-left: 0;
}
/* Shadow effect */
.shadow-effect {
  position: relative;
  background: white;
}
.shadow-effect:before,
.shadow-effect:after {
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 15px;
  left: 10px;
  width: 50%;
  top: 80%;
  max-width: 300px;
  background: #777;
  -webkit-box-shadow: 0 15px 10px #777777;
  box-shadow: 0 15px 10px #777777;
  -webkit-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  transform: rotate(-3deg);
}
.shadow-effect:after {
  -webkit-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  -o-transform: rotate(3deg);
  transform: rotate(3deg);
  right: 10px;
  left: auto;
}
/* Vertically centered columns */
@media (min-width: 768px) {
  .row_v-centered {
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 100%;
  }
  .row_v-centered [class*="col-"] {
    display: table-cell;
    vertical-align: middle;
    float: none;
    height: 100%;
  }
}
/* Pills */
.nav-pills > li > a {
  border-radius: 2px;
  padding: 10px 30px;
  color: #333333;
  -webkit-transition: all .05s linear;
       -o-transition: all .05s linear;
          transition: all .05s linear;
}
.nav-pills > li > a:hover,
.nav-pills > li > a:focus {
  background-color: #f9f9f9;
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2);
          box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2);
  color: #333333;
}
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
  background-color: #8cb82b;
}
/* Tab Pane */
.tab-pane {
  padding-top: 20px;
}
/**
 * Pagination
 */
/* Border radius */
.pagination,
.pagination-lg,
.pagination-sm {
  border-radius: 2px;
}
.pagination > li:first-child > a,
.pagination-lg > li:first-child > a,
.pagination-sm > li:first-child > a,
.pagination > li:first-child > span,
.pagination-lg > li:first-child > span,
.pagination-sm > li:first-child > span {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.pagination > li:last-child > a,
.pagination-lg > li:last-child > a,
.pagination-sm > li:last-child > a,
.pagination > li:last-child > span,
.pagination-lg > li:last-child > span,
.pagination-sm > li:last-child > span {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
/* General */
.pagination {
  background-color: #f9f9f9;
}
.pagination > li > a,
.pagination > li > span {
  background: transparent;
  border-left-width: 0;
  -webkit-box-shadow: 1px 0 1px rgba(255, 255, 255, 0.9);
          box-shadow: 1px 0 1px rgba(255, 255, 255, 0.9);
  -webkit-transition: all .05s linear;
       -o-transition: all .05s linear;
          transition: all .05s linear;
}
.pagination > li > a:hover,
.pagination li > span:hover,
.pagination > li > a:focus,
.pagination li > span:focus,
.pagination > li.active > a,
.pagination li.active > span {
  -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
          box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  border-left-width: 1px;
}
.pagination > li.disabled > a,
.pagination li.disabled > span {
  border-left-width: 1px;
}
.pagination > li.disabled > a:hover,
.pagination li.disabled > span:hover,
.pagination > li.disabled > a:focus,
.pagination li.disabled > span:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  z-index: 1;
}
/* Pager */
.pager > li > a,
.pager > li > span {
  background-color: #f9f9f9;
  color: #333333;
  -webkit-transition: all .05s linear;
       -o-transition: all .05s linear;
          transition: all .05s linear;
}
.pager > li > a:hover,
.pager > li > span:hover,
.pager > li > a:focus,
.pager > li > span:focus {
  -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
          box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  color: white;
}
.pager > li.disabled > a,
.pager > li.disabled > a:hover,
.pager > li.disabled > a:focus,
.pager > li.disabled > span,
.pager > li.disabled > span:hover,
.pager > li.disabled > span:focus {
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  -webkit-box-shadow: none;
          box-shadow: none;
}
/**
 * Panels
 */
.panel-heading {
  text-transform: uppercase;
  border-color: rgba(0, 0, 0, 0.05);
}
/* Panel Colors */
.panel-primary {
  border-color: rgba(0, 0, 0, 0.1);
}
.panel-primary > .panel-heading {
  color: white;
  background-color: #ef555f;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px 2px 0 0;
  margin: -1px -1px 0 -1px;
}
.panel-primary > .panel-heading + .panel-collapse .panel-body {
  border-top-color: rgba(0, 0, 0, 0.1);
}
.panel-primary > .panel-footer + .panel-collapse .panel-body {
  border-bottom-color: rgba(0, 0, 0, 0.1);
}
.panel-primary > .panel-footer {
  background-color: #f5f5f5;
  color: #333333;
}
.panel-secondary {
  border-color: rgba(0, 0, 0, 0.1);
}
.panel-secondary > .panel-heading {
  color: white;
  background-color: #3db5d7;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px 2px 0 0;
  margin: -1px -1px 0 -1px;
}
.panel-secondary > .panel-heading + .panel-collapse .panel-body {
  border-top-color: rgba(0, 0, 0, 0.1);
}
.panel-secondary > .panel-footer + .panel-collapse .panel-body {
  border-bottom-color: rgba(0, 0, 0, 0.1);
}
.panel-secondary > .panel-footer {
  background-color: #f5f5f5;
  color: #333333;
}
/* Accordion */
.panel-group .panel-heading {
  position: relative;
  padding: 0;
}
.panel-group .panel-heading > a {
  position: relative;
  display: block;
  padding: 10px 15px;
  color: inherit;
  text-decoration: none;
  text-transform: uppercase;
}
.panel-group .panel-heading > a:after {
  position: absolute;
  right: 10px;
  height: 20px;
  width: 20px;
  content: "\f107";
  background: rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  text-align: center;
  line-height: 20px;
  font-family: FontAwesome;
  color: white;
}
.panel-group .panel-heading > .collapsed:after {
  content: "\f105" !important;
}
/* Panel body */
.panel-body > ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.panel-body > ul > li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.panel-body > ul > li:last-child {
  border-bottom: 0;
}
.panel-body > ul > li > a {
  display: block;
  padding: 8px 0;
  font-weight: 600;
  font-size: 11px;
  color: #777777;
  text-transform: uppercase;
  text-decoration: none;
}
.panel-body > ul > li > a:hover {
  color: #333333;
}
/**
 * Popovers
 */
.popover {
  border-radius: 2px;
  padding: 12px;
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
          box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
}
.popover-title,
.popover-content {
  padding: 6px;
}
.popover-title {
  border-bottom: 0;
  border-radius: 2px 2px 0 0;
  font-size: 14px;
  font-weight: 600;
  color: #0f70b7;
}
.popover-content {
  color: #555;
  font-size: 12px;
}
/**
 * Progress
 */
/* General */
.progress {
  background: #f5f5f5;
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
          box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
  border-radius: 0px;
  height: 10px;
}
.progress-bar {
  line-height: 10px;
}
.progress-caption,
.progress-value {
  font-size: 14px;
  padding-bottom: 12px;
}
.progress-value {
  text-align: right;
  font-weight: 600;
}
/* Progress bar (small) */
.progress-sm {
  height: 3px;
}
.progress-sm > .progress-bar {
  line-height: 3px;
}
/* Progress bar (secondary) */
.progress-secondary > .progress-bar {
  background-color: #8cb82b;
}
/**
 * Scaffolding
 */
.text-primary {
  color: #0f70b7 !important;
}
.text-secondary {
  color: #8cb82b !important;
}
/**
 * Scrolltopcontrol
 */
.backtotop {
  background: rgba(0, 0, 0, 0.2);
  padding: 10px 15px;
  font-size: 18px;
  color: #FFF;
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2);
          box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2);
  text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
  -webkit-transition: background .1s;
       -o-transition: background .1s;
          transition: background .1s;
}
.backtotop:hover {
  background: rgba(0, 0, 0, 0.4);
}
/**
 * Social links
 */
.social {
  margin-bottom: 15px;
}
.social > ul,
.social > ul > li {
  margin: 0 0 10px;
  padding: 0;
}
.social li > a {
  display: inline-block;
  background: #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #333333;
  height: 34px;
  width: 34px;
  border-radius: 34px;
  line-height: 34px;
  text-align: center;
  -webkit-transition: all .1s linear;
       -o-transition: all .1s linear;
          transition: all .1s linear;
}
.social li > a.focus:hover {
  background: #73a425;
}
.social li > a.rss:hover {
  background: #ff9900;
}
.social li > a.facebook:hover {
  background: #3b5998;
}
.social li > a.twitter:hover {
  background: #55acee;
}
.social li > a.plus:hover {
  background: #dd4b39;
}
.social li > a.vk:hover {
  background: #3f628a;
}
.social li > a.pinterest:hover {
  background: #c92026;
}
.social li > a.vimeo:hover {
  background: #446d82;
}
.social li > a.github:hover {
  background: #657e8c;
}
.social li > a.linkedin:hover {
  background: #0c7bb3;
}
.social li > a.dropbox:hover {
  background: #4fa3e3;
}
.social li > a.youtube:hover {
  background: #ed413e;
}
.social li:hover > a {
  color: #fff;
  border-color: transparent;
}
/*** Social Large ***/
.social-lg li > a {
  height: 45px;
  width: 45px;
  border-radius: 45px;
  line-height: 45px;
  text-align: center;
}
/*** Social Small ***/
.social-sm li > a {
  height: 30px;
  width: 30px;
  border-radius: 30px;
  line-height: 30px;
  text-align: center;
}
/**
 * Tables
 */
/* General */
.table > thead > tr > th {
  border-bottom-width: 1px;
}
.table > thead > tr > th > span {
  position: relative;
}
.table > thead > tr > th > span:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -9px;
  border-top: 2px solid #0f70b7;
}
.table-condensed > thead > tr > th > span:after {
  bottom: -7px;
}
/* Custom tables */
.table > thead > tr > td.yellow,
.table > tbody > tr > td.yellow,
.table > tfoot > tr > td.yellow,
.table > thead > tr > th.yellow,
.table > tbody > tr > th.yellow,
.table > tfoot > tr > th.yellow,
.table > thead > tr.yellow > td,
.table > tbody > tr.yellow > td,
.table > tfoot > tr.yellow > td,
.table > thead > tr.yellow > th,
.table > tbody > tr.yellow > th,
.table > tfoot > tr.yellow > th {
  background-color: #ffff33;
}
.table-hover > tbody > tr > td.yellow:hover,
.table-hover > tbody > tr > th.yellow:hover,
.table-hover > tbody > tr.yellow:hover > td,
.table-hover > tbody > tr.yellow:hover > th {
  background-color: yellow;
}
/**
 * Tooltips
 */
.tooltip-inner {
  padding: 7px;
  border-radius: 2px;
}
/**
 * Typography
 */
/**
 * Wells
 */
.well {
  -webkit-box-shadow: none;
          box-shadow: none;
  background-color: #f5f5f5;
  border-color: rgba(0, 0, 0, 0.05);
}
/* Theme */
@media (min-width: 768px) {
  /* Sections by Layout */
  .section_centered .row {
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 100%;
  }
  .section_centered .row [class*="col-"] {
    display: table-cell;
    vertical-align: middle;
    float: none;
    height: 100%;
  }
  .section > .section__inner {
    padding: 40px 0; /* SPACE ABOVE HEADLINE */
  }
}
/* Home App: Typography */
.home-app__heading {
  position: relative;
  margin: 0 0 40px;
  font-family: 'Montserrat', sans-serif;
  font-size: 42px;
  font-weight: 700;
}
.home-app__heading:before {
  content: "";
  position: absolute;
  width: 30px;
  height: 2px;
  /*background-color: #0f70b7;*/ /*blue line between headline and subheadline*/
  bottom: -20px;
  left: 0;
}
.home-app__heading.text-center:before {
  left: 50%;
  margin-left: -15px;
}
.home-app__heading.inverse {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 0.9);
}
.home-app__subheading {
  margin: 0 0 40px;
  font-size: 15px;
}
.home-app__subheading.inverse {
  color: rgba(255, 255, 255, 0.6);
}
@media (min-width: 768px) {
  .home-app__button > .btn {
    padding: 15px 25px;
  }
}
@media (max-width: 767px) {
  .home-app__heading {
    font-size: 32px !important;
    text-align: center;
  }
  .home-app__heading:before {
    left: 50%;
    margin-left: -15px;
  }
  .home-app__subheading {
    font-size: 14px !important;
    text-align: center;
  }
  .home-app__button {
    text-align: center;
  }
}
/* Home App: Images */
.home-app__img > img {
  max-width: 250px;
  margin: 0 auto;
}
.home-app__img_center {
  max-width: 250px;
  margin: 0 auto;
}
.home-app__img_lg > img {
  max-width: 350px;
}
/* Home App: Features */
.home-app__features {
  margin: 20px 0;
}
.home-app__feature {
  margin-bottom: 40px;
  text-align: left;
}
.home-app-feature__icon {
  float: left;
  margin-left: auto;
  margin-right: 20px;
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  font-size: 15px;
  color: #757575;
}
.home-app-feature__body {
  overflow: hidden;
}
.home-app-feature__title {
  font-family: 'Montserrat', sans-serif;
  margin: 0 0 10px 0;
  font-size: 20px;
  color: #000;
}
@media (min-width: 768px) {
  .home-app__feature_left {
    text-align: right;
  }
  .home-app__feature_left .home-app-feature__icon {
    float: right;
    margin-left: 20px;
    margin-right: auto;
  }
}
/* Home App: Describe */
.home-app__features-list {
  padding-left: 0;
  list-style: none;
  color: rgba(255, 255, 255, 0.6);
}
.home-app__features-list > li {
  margin-bottom: 15px;
}
.home-app__features-list > li > .fa {
  font-size: 15px;
  color: #0f70b7;
  margin-right: 10px;
}
@media (min-width: 768px) {
  .home-app__features-list > li > .fa {
    margin-right: 20px;
  }
}
/* Home App: Video */
.home-app__video {
  -webkit-box-shadow: 0 10px 50px rgba(0, 0, 0, 0.5);
          box-shadow: 0 10px 50px rgba(0, 0, 0, 0.5);
}
/* Home App: Reviews */
.home-app__review {
  margin-bottom: 40px;
}
.home-app-review__avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto 20px;
  overflow: hidden;
}
.home-app-review__content {
  margin-bottom: 20px;
}
.home-app-review__body {
  text-align: center;
}
.home-app-review__author > span {
  font-family: 'Montserrat', sans-serif;
  font-size: 22px;
  color: #333;
}
@media (min-width: 768px) {
  .home-app__review {
    display: table;
  }
  .home-app-review__avatar {
    display: table-cell;
    vertical-align: middle;
    float: left;
    margin: 0 50px 0 0;
    width: 200px;
    height: 200px;
  }
  .home-app-review__body {
    display: table-cell;
    vertical-align: middle;
    overflow: hidden;
    text-align: left;
  }
}
/* Home App: Pricing */
.home-app__pricing .row > div[class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}
.home-app-pricing__item {
  padding: 40px;
  margin-left: -1px;
  margin-top: -1px;
  border: 1px solid #ddd;
  text-align: center;
  -webkit-transition: all .1s linear;
       -o-transition: all .1s linear;
          transition: all .1s linear;
}
.home-app-pricing__item:hover {
  -webkit-box-shadow: 0 3px 25px rgba(0, 0, 0, 0.3);
          box-shadow: 0 3px 25px rgba(0, 0, 0, 0.3);
}
.home-app-pricing__title {
  font-family: 'Montserrat', sans-serif;
  font-size: 22px;
  font-weight: 400;
}
.home-app-pricing__price {
  margin: 20px 0;
  font-size: 48px;
  color: #0f70b7;
}
.home-app-pricing__price > span {
  font-size: 15px;
}
.home-app-pricing__price > span:first-child {
  vertical-align: 100%;
}
.home-app-pricing__content {
  margin-bottom: 10px;
  font-weight: 400;
}
/* Home App: Navigation */
#fp-nav > ul > li > a > span,
#fp-nav > ul > li > a > span:hover,
#fp-nav > ul > li > a > span:focus {
  width: 12px;
  height: 12px;
  margin: -6px 0 0 -6px;
  padding: 2px;
  -webkit-background-clip: content-box !important;
          background-clip: content-box !important;
  background: transparent;
  border: 1px solid #ccc;
}
#fp-nav > ul > li > .active > span,
#fp-nav > ul > li > .active > span:hover,
#fp-nav > ul > li > .active > span:focus {
  background-color: #ccc;
}
/* Animation */
[data-animation]:not(.animated) {
  visibility: hidden;
}
/**
 * Index: version 3
 */
/* General */
.index-3__section {
  padding: 60px 0;
}
.index-3__section_inverse {
  background-color: rgba(47, 59, 64, 0.02);
}
/* Jumbotron */
.index-3__jumbotron {
  position: relative;
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 600px;
  margin-top: -18px;
  /* background: #2f3b40 url(../img/home_10.jpg) no-repeat center center / cover; */
  text-align: center;
}
.index-3__jumbotron:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(47, 59, 64, 0.9);
}
.index-3-jumbotron__inner {
  display: table-cell;
  width: 100%;
  vertical-align: middle;
}
.index-3-jumbotron__heading {
  font-family: 'Montserrat', sans-serif;
  font-size: 40px;
  color: white;
  margin-bottom: 30px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}
.index-3-jumbotron__subheading {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 60px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
}
.index-3-jumbotron__btn .btn-link {
  color: rgba(255, 255, 255, 0.5);
}
.index-3-jumbotron__btn .btn-link:hover,
.index-3-jumbotron__btn .btn-link:focus {
  color: #fff;
}
@media (min-width: 992px) {
  .index-3__jumbotron {
    text-align: left;
  }
}
/* Headings */
.index-3__heading {
  font-family: 'Montserrat', sans-serif;
  font-size: 32px;
  font-weight: 400;
  margin-bottom: 60px;
}
.index-3__subheading {
  font-size: 14px;
  color: #777777;
  margin-top: -50px;
  margin-bottom: 60px;
}
/* Features */
.index-3-features__item {
  margin-bottom: 20px;
}
.index-3-features__img,
.index-3-features__heading,
.index-3-features__subheading {
  max-width: 440px;
  margin-left: auto;
  margin-right: auto;
}
.index-3-features__icon {
  font-size: 24px;
}
.index-3-features__img {
  margin-bottom: 20px;
  border-radius: 2px;
  overflow: hidden;
}
.index-3-features__heading {
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 20px;
  line-height: 1.3;
}
.index-3-features__subheading {
  color: #777777;
  line-height: 1.7;
  margin-bottom: 10px;
}
/* Features 2 */
.index-3-features-2__item {
  margin-bottom: 30px;
}
.index-3-features-2__icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 3px;
  color: white;
  background-color: #2f3b40;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 15px;
}
.index-3-features-2__heading {
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  line-height: 40px;
}
.index-3-features-2__content {
  color: #777777;
}
/* Subscribe */
.index-3__sign-up .form-control {
  height: 44px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.index-3__sign-up [type="submit"] {
  padding-top: 12px;
  padding-bottom: 12px;
}
@media (min-width: 768px) {
  .index-3__sign-up .row > [class*="col-"] {
    padding-left: 5px;
    padding-right: 5px;
  }
}
/**
 * Shop
 */
/** Shop: Index **/
/* General */
.shop-index__section {
  position: relative;
  margin-bottom: 0px;
}
.shop-index__section.alt {
  background-color: #f5f5f5;
  padding-top: 60px;
  padding-bottom: 60px;
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.05);
}
.shop-index__heading {
  margin-top: 0;
  margin-bottom: 60px;
  font-family: 'Montserrat', sans-serif;
}
.shop-index__heading + p {
  margin-top: -50px;
  margin-bottom: 60px;
  color: #777777;
}
/* Shop: Slideshow */
.shop__slideshow {
  /* margin-top: 80px; */     /*SPACE ABOVE SLIDER TO TOP */
  margin-bottom: 0px;
}
.shop__slideshow .carousel-inner .item__container {
  display: table;
  width: 100%;
  height: 100%;
}
.shop__slideshow .carousel-inner .item-container__inner {
  display: table-cell;
  vertical-align: middle;
  padding-top: 45px;
}
.shop__slideshow .carousel-inner .item {
  width: 100%;
  height: 570px;
}
.shop-slideshow__heading {
  margin: 0 0 20px 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 46px;
  line-height: 1.2;
  color: white;
}
.shop-slideshow__subheading {
  margin-bottom: 30px;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  line-height: 1.5;
  color: white;
}
.shop-slideshow__heading_dark {
  margin: 0 0 20px 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 46px;
  line-height: 1.2;
  color: #454545;
}
.shop-slideshow__subheading_dark {
  margin-bottom: 30px;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  line-height: 1.5;
  color: #454545;
}
.shop-slideshow__btn {
  border: 2px solid white;
  border-radius: 0;
  color: white;
  font-weight: 600;
}
.shop-slideshow__btn:hover,
.shop-slideshow__btn:focus {
  color: #333333;
  background-color: white;
}
.shop-slideshow__btn_dark {
  border: 2px solid #454545;
  border-radius: 0;
  color: #454545;
  font-weight: 600;
}
.shop-slideshow__btn_dark:hover,
.shop-slideshow__btn_dark:focus {
  color: #333333;
  background-color: white;
}
@media (max-width: 767px) {
  .shop__slideshow .carousel-inner .item {
    height: 400px;
    padding: 0 30px;
    text-align: center;
  }
  .shop-slideshow__heading {
    font-size: 32px;
  }
  .shop-slideshow__subheading {
    font-size: 16px;
  }
}
/* Carousel controls */
.shop-slideshow__control {
  display: block;
  position: absolute;
  top: 50%;
  padding-top: 45px;
  left: 10px;
  width: 30px;
  height: 70px;
  opacity: 0;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
       -o-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: opacity .3s;
       -o-transition: opacity .3s;
          transition: opacity .3s;
}
.shop-slideshow__control:hover {
  opacity: 1 !important;
}
.shop-slideshow__control[data-slide="next"] {
  left: auto;
  right: 10px;
}
.shop__slideshow:hover .shop-slideshow__control {
  opacity: .3;
}
/* Blog post */
.shop-index-blog__posts > [class*="col-"] {
  padding-top: 20px;
  padding-bottom: 20px;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}
.shop-index-blog__posts > [class*="col-"]:last-child {
  border-right: 0;
}
.shop-index-blog__post {
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.shop-index-blog__img {
  position: relative;
  float: left;
  margin-right: 30px;
  margin-bottom: 20px;
  width: 90px;
  height: 90px;
  overflow: hidden;
  -moz-border-radius: 90px; 
  -webkit-border-radius: 90px; 
  border-radius: 90px;
}
.shop-index-blog__img:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.shop-index-blog__img > img {
  height: 100%;
  width: auto;
}
.shop-index-blog__body {
  overflow: hidden;
}
.shop-index-blog__heading {
  position: relative;
  margin-top: 0;
  margin-bottom: 30px;
  line-height: 1.5;
}
.shop-index-blog__heading:after {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 0;
  width: 30px;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.1);
}
.shop-index-blog__content {
  margin-bottom: 20px;
  color: #777777;
}
@media (max-width: 991px) {
  .shop-index-blog__img {
    float: none;
    margin-right: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .shop-index-blog__heading {
    text-align: center;
  }
  .shop-index-blog__heading:after {
    left: 50%;
    margin-left: -15px;
  }
}
@media (max-width: 767px) {
  .shop-index-blog__posts > [class*="col-"] {
    padding-top: 0;
    padding-bottom: 60px;
    border-right: 0;
  }
  .shop-index-blog__posts > [class*="col-"]:last-child {
    padding-bottom: 0;
  }
  .shop-index-blog__post {
    width: 100%;
  }
}
/* Newsletter */
.shop-index__newsletter {
  padding-bottom: 20px;
}
.shop-index__newsletter .shop-index__heading {
  margin-bottom: 20px;
  line-height: 42px;
  text-align: center;
}
.shop-index__newsletter input[type="email"] {
  height: 42px;
  padding: 11px 12px;
}
.shop-index__newsletter button[type="submit"] {
  padding: 11px 30px;
  width: 100%;
}
@media (min-width: 768px) {
  .shop-index__newsletter .shop-index__heading {
    margin-bottom: 0px;
    text-align: right;
  }
  .shop-index__newsletter input[type="email"] {
    border-radius: 21px 0 0 21px;
  }
  .shop-index__newsletter button[type="submit"] {
    margin-left: -3px;
    border-radius: 0 21px 21px 0;
    width: auto;
  }
}
/** Shop: Thumbnails **/
.shop__thumb {
  border: 1px solid rgba(0, 0, 0, 0.05);
  padding: 20px;
  margin-bottom: 20px;
  background-color: white;
  text-align: center;
  -webkit-transition: border-color 0.1s, -webkit-box-shadow 0.1s;
       -o-transition: border-color 0.1s, box-shadow 0.1s;
          transition: border-color 0.1s, box-shadow 0.1s;
}
.shop__thumb:hover {
  border-color: rgba(0, 0, 0, 0.07);
  -webkit-box-shadow: 0 5px 30px rgba(0, 0, 0, 0.07);
          box-shadow: 0 5px 30px rgba(0, 0, 0, 0.07);
}
.shop__thumb > a {
  color: #333333;
}
.shop__thumb > a:hover {
  text-decoration: none;
}
.shop-thumb__img {
  position: relative;
  margin-bottom: 20px;
  overflow: hidden;
}
.shop-thumb__title {
  font-weight: 600;
}
.shop-thumb__price {
  color: #777777;
}
.shop-thumb-price_old {
  text-decoration: line-through;
}
.shop-thumb-price_new {
  color: red;
}
/** Shop: Item **/
.shop-item__info {
  padding: 30px;
  margin-bottom: 40px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.05);
}
.shop-item-cart__title {
  margin-bottom: 20px;
  line-height: 1.3;
}
.shop-item-cart__price {
  font-size: 28px;
  font-weight: 400;
  color: #F7C41F;
}
.shop-item__intro {
  color: #777777;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.shop-item__add button[type="submit"] {
  padding: 15px 20px;
}
.shop-item__img {
  margin-bottom: 40px;
}
.shop-item-img__main {
  width: -webkit-calc(100% - 110px);
  width: calc(100% - 110px);
  height: auto;
  float: left;
}
.shop-item-img__aside {
  width: 100px;
  height: auto;
  float: left;
}
.shop-item-img__aside > img {
  cursor: pointer;
  margin-bottom: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  opacity: .5;
}
.shop-item-img__aside > img:hover,
.shop-item-img__aside > img.active {
  border-color: rgba(0, 0, 0, 0.05);
  opacity: 1;
}
@media (max-width: 767px) {
  .shop-item-img__main {
    width: -webkit-calc(100% - 60px);
    width: calc(100% - 60px);
  }
  .shop-item-img__aside {
    width: 50px;
  }
}
/** Shop: Filter **/
.shop__filter {
  margin-bottom: 40px;
}
/* Shop filter: Pricing */
.shop-filter__price {
  padding: 15px;
}
.shop-filter__price [class*='col-'] {
  padding: 2px;
}
/* Shop filter: Colors */
.shop-filter__color {
  display: inline-block;
  margin: 0 2px 2px 0;
}
.shop-filter__color input[type="text"] {
  display: none;
}
.shop-filter__color label {
  width: 30px;
  height: 30px;
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  cursor: pointer;
}
/** Shop: Sorting **/
.shop__sorting {
  list-style: none;
  padding-left: 0;
  margin-bottom: 40px;
  margin-top: -20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  text-align: right;
}
.shop__sorting > li {
  display: inline-block;
}
.shop__sorting > li > a {
  display: block;
  padding: 20px 10px;
  margin-bottom: -1px;
  border-bottom: 2px solid transparent;
  color: #757575;
  -webkit-transition: all .05s linear;
       -o-transition: all .05s linear;
          transition: all .05s linear;
}
.shop__sorting > li > a:hover,
.shop__sorting > li > a:focus {
  color: #333333;
  text-decoration: none;
}
.shop__sorting > li.active > a {
  color: #0f70b7;
  border-bottom-color: #0f70b7;
}
@media (max-width: 767px) {
  .shop__sorting {
    text-align: left;
    border-bottom: 0;
  }
  .shop__sorting > li {
    display: block;
  }
  .shop__sorting > li > a {
    padding: 10px 15px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .shop__sorting > li.active > a {
    font-weight: 600;
  }
}
/** Shop: Checkout **/
.checkout__block {
  margin-bottom: 40px;
}
.checkout-cart__item {
  margin-bottom: 15px;
}
.checkout-cart-item__img {
  max-width: 80px;
  margin-right: 10px;
  float: left;
}
.checkout-cart-item__content {
  overflow: hidden;
}
.checkout-cart-item__heading {
  margin-top: 0;
}
.checkout-cart-item__footer {
  padding: 10px 0;
  margin-top: 10px;
  border-top: 1px solid #eee;
}
.checkout-cart-item__price {
  font-weight: 600;
}
.checkout-total__block {
  margin-bottom: 40px;
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
}
.checkout-total__block > .row > [class*="col-"] {
  padding: 40px 40px;
  border-right: 1px solid #e9e9e9;
  color: #aaa;
}
.checkout-total__block > .row > [class*="col-"]:last-child {
  border-right: 0;
  color: #333333;
}
@media (max-width: 767px) {
  .checkout-total__block {
    border: 0;
  }
  .checkout-total__block > .row > [class*="col-"] {
    padding: 15px 20px;
    border: 0;
    border-top: 1px solid #e9e9e9;
  }
  .checkout-total__block > .row > [class*="col-"]:last-child {
    border-bottom: 1px solid #e9e9e9;
  }
}
.checkout-total__heading {
  float: left;
}
.checkout-total__price {
  float: right;
  margin: 9px 0;
  font-size: 17px;
}
.checkout__submit {
  padding: 15px 40px;
}
/** Shop: Order Confirmation */
.shop-conf__heading {
  margin-bottom: 40px;
}
.shop-conf__heading + p {
  margin-bottom: 100px;
}
/**
 * General
 */
html {
  position: relative;
  min-height: 100%;
}
.section {
  margin-bottom: 40px;
}
/**
 * Topic header
 */
.topic {
  position: relative;
  margin-top: -18px;
  margin-bottom: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  background: #f5f5f5;
}
.topic h3 {
  display: inline-block;
  margin: 0;
  padding: 23px 0;
  font-size: 16px;
}
.topic .breadcrumb {
  margin-bottom: 0;
  padding: 21px 0;
  background: transparent;
}
/**
 * About Us: Default
 */
.team-photo {
  position: relative;
  height: 500px;
  width: 100%;
  margin-top: -30px;
  padding-top: 10px;
  /* background-image: url(../img/our-team.jpg); */
}
.team-photo > .container,
.team-photo > .container > .row {
  height: 100%;
}
.team-photo__caption > h1 {
  font-weight: 300;
  margin: 0 0 30px;
}
.team-about {
  padding: 50px 0;
  margin-bottom: 40px;
  background: #f5f5f5;
  font-size: 16px;
  line-height: 1.4;
  text-align: center;
  color: #777;
}
/**
 * About Us: Option 1
 */
.team-member {
  margin-bottom: 10px;
}
.team-member > img {
  margin-bottom: 5px;
  border-radius: 4px 4px 0 0;
}
.team-member > .info {
  padding: 5px;
  background: #f0f0f0;
  border-radius: 0 0 5px 5px;
  transiton: background .3s linear;
}
.team-member:hover > .info {
  background: #f5f5f5;
}
.team-member > .info > p {
  margin-bottom: 0;
}
/**
 * Blog
 */
.blog-post__avatar {
  display: none;
}
@media (min-width: 768px) {
  .blog-post__avatar {
    display: block;
    width: 80px;
    height: 80px;
    margin-right: 20px;
    float: left;
    border: 5px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    overflow: hidden;
  }
}
.blog-post__body {
  position: relative;
  overflow: hidden;
  padding-bottom: 40px;
}
.blog-post__body:after {
  content: "";
  position: absolute;
  bottom: 20px;
  left: 0;
  height: 1px;
  width: 100%;
  background-image: -webkit-linear-gradient(left, white, #dddddd 50%, white);
  background-image: -o-linear-gradient(left, white, #dddddd 50%, white);
  background-image: -webkit-gradient(linear, left top, right top, from(white), color-stop(50%, #dddddd), to(white));
  background-image: linear-gradient(to right, white, #dddddd 50%, white);
  background-repeat: no-repeat;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffffffff', GradientType=1);
}
.blog-post__body.no-hr:after {
  display: none;
}
.blog-post__title {
  font-weight: 500;
  font-size: 20px;
  line-height: 1.5;
  margin-top: 0;
}
.blog-post__info {
  margin-bottom: 20px;
  color: #777777;
}
.blog-post__content {
  margin-bottom: 20px;
  line-height: 1.7;
}
.blog-post__read-more {
  margin-bottom: 20px;
}
.blog-post__img {
  border-radius: 2px;
  margin-bottom: 20px;
}
/* Blog Tags */
.blog-tags > a {
  padding: 3px 6px;
  margin: 4px 4px 4px 0;
  background: #eee;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-width: 0 1px 1px 0;
  border-radius: 2px;
  color: #515151;
  text-decoration: none;
  font-size: 12px;
  white-space: nowrap;
  display: inline-block;
}
.blog-tags > a:hover {
  background: #8cb82b;
  color: #FFF;
  border-color: transparent;
}
.blog-tags > a:hover:before {
  color: #FFF;
}
.blog-tags > a:before {
  font-family: FontAwesome;
  content: "\f02b";
  color: #999;
  margin-right: 5px;
}
/**
 * Coming soon
 */
.coming-soon {
  height: 100vh;
  background-color: #555;
  text-align: center;
}
.coming-soon__table {
  display: table;
  height: 100%;
  width: 100%;
}
.coming-soon-table__inner {
  display: table-cell;
  vertical-align: middle;
}
.coming-soon__heading {
  color: white;
  font-weight: 700;
  font-size: 36px;
  text-transform: uppercase;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  margin-top: 0;
  margin-bottom: 30px;
}
.coming-soon__intro {
  color: white;
  font-weight: 300;
  font-size: 14px;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  line-height: 1.7;
  margin-bottom: 40px;
}
.coming-soon__form {
  display: block;
  margin-bottom: 40px;
}
.coming-soon__form .form-control {
  height: 45px;
  width: 100%;
  padding: 12px 24px;
  border-radius: 45px;
  font-size: 13px;
}
.coming-soon__form button[type="submit"] {
  height: 45px;
  border-radius: 45px;
  width: 100%;
}
/* Countdown */
.countdown-row {
  display: block;
}
.countdown-section {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  margin: 0 10px;
  font-weight: 300;
  color: white;
}
.countdown-period {
  display: block;
  margin-top: 10px;
  font-size: 13px;
  line-height: 18px;
}
@media (min-width: 768px) {
  .coming-soon__heading {
    font-size: 52px;
  }
  .coming-soon__intro {
    font-size: 16px;
  }
  .coming-soon__form {
    display: inline-block;
    border: 20px solid rgba(255, 255, 255, 0.1);
    border-radius: 100px;
    -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
            box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  }
  .coming-soon__form .form-control {
    width: 300px;
    border-radius: 45px 0 0 45px;
  }
  .coming-soon__form button[type="submit"] {
    border-radius: 0 45px 45px 0;
    margin-left: -4px;
    width: auto;
  }
  .countdown-section {
    width: 70px;
    height: 70px;
    line-height: 70px;
    font-size: 24px;
    margin: 0 5px;
  }
}
/**
 * Contact us: Option 1
 */
.contact-us__google-map_big {
  width: 100%;
  height: 400px;
  margin: -40px auto 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: #f9f9f9;
}
.contact-us__intro {
  margin-bottom: 20px;
}
/* Contact form */
#form_message {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 0;
  text-align: center;
  z-index: 999999;
}
#form_sendemail .help-block {
  display: none;
}
/* Contact info */
.contact-info {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 40px;
}
.contact-info > li {
  padding: 10px 0;
  border-bottom: 1px dashed #ddd;
}
.contact-info > li > .fa {
  float: left;
  margin-top: 10px;
  font-size: 28px;
}
.contact-info__content {
  overflow: hidden;
  margin-left: 45px;
}
.contact-info__content .title {
  font-weight: 700;
  margin-bottom: 0;
}
.contact-info__content .description {
  font-size: 13px;
}
/**
 * Error Page
 */
.error-page__container {
  position: relative;
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  padding: 40px 60px;
  background: rgba(0, 0, 0, 0.05);
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.05);
}
.error-page__container:before,
.error-page__container:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
}
.error-page__container:before {
  top: -1px;
  right: -1px;
  border-top: 80px solid white;
  border-left: 80px solid transparent;
}
.error-page__container:after {
  top: -1px;
  right: -1px;
  border-bottom: 80px solid rgba(0, 0, 0, 0.1);
  border-right: 80px solid transparent;
}
.error-page__404 {
  font-weight: 600;
  font-size: 96px;
  font-family: 'Khand', sans-serif;
}
.error-page__heading {
  margin-bottom: 30px;
  font-size: 17px;
  font-weight: 600;
}
.error-page__desc {
  margin-bottom: 20px;
}
/**
 * Help Center
 */
.help-center__questions {
  list-style: none;
  padding-left: 0;
}
.help-center__questions > li > a {
  display: block;
  padding: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  color: #333333;
}
.help-center__questions > li > a:hover {
  background-color: rgba(0, 0, 0, 0.02);
  color: #333333;
  text-decoration: none;
}
.help-center__questions > li > a::before {
  content: "\f054";
  font-family: "FontAwesome";
  margin-right: 12px;
  color: #8cb82b;
}
.help-center__top {
  position: relative;
  padding: 20px;
  margin-bottom: 10px;
  background: rgba(0, 0, 0, 0.02);
  border: 1px solid rgba(0, 0, 0, 0.05);
}
.help-center__top:before,
.help-center__top:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
}
.help-center__top:before {
  top: -1px;
  right: -1px;
  border-top: 20px solid white;
  border-left: 20px solid transparent;
}
.help-center__top:after {
  top: -1px;
  right: -1px;
  border-bottom: 20px solid rgba(0, 0, 0, 0.1);
  border-right: 20px solid transparent;
}
.help-center__top > a {
  color: #333333;
}
.help-item__article {
  line-height: 1.7;
}
/**
 * Home: Version 1
 */
/* Home: Carousel */
.home-carousel__container {
  width: 100%;
  overflow-x: hidden;
  margin-top: -18px;
  margin-bottom: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.home-carousel {
  position: relative;
  display: table;
  width: 100%;
  height: 500px;
}
.home-carousel .btn-link {
  color: rgba(51, 51, 51, 0.8);
}
.home-carousel .btn-link:hover,
.home-carousel .btn-link:focus {
  color: #333333;
}
.home-carousel .btn-link.inverse {
  color: rgba(255, 255, 255, 0.8);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}
.home-carousel .btn-link.inverse:hover,
.home-carousel .btn-link.inverse:focus {
  color: white;
}
.home-carousel__slide {
  vertical-align: middle;
  width: 100%;
  display: none;
}
.home-carousel__slide.active {
  display: table-cell;
}
.home-carousel__img {
  position: absolute;
  top: 0;
  left: 50%;
  height: 500px;
  width: auto;
}
.home-carousel__title,
.home-parallax__title {
  margin-top: 0;
  margin-bottom: 30px;
  line-height: 1.4;
  color: rgba(0, 0, 0, 0.7);
}
.home-carousel__title.inverse,
.home-parallax__title.inverse {
  font-weight: 300;
  color: white;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}
.home-carousel__subtitle,
.home-parallax__subtitle {
  margin-bottom: 30px;
  color: rgba(51, 51, 51, 0.8);
}
.home-carousel__subtitle.inverse,
.home-parallax__subtitle.inverse {
  color: rgba(255, 255, 255, 0.8);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}
.home-carousel__arrow {
  position: absolute;
  width: 55px;
  height: 55px;
  top: 50%;
  left: 0;
  margin-top: -30px;
  color: rgba(0, 0, 0, 0.7);
  font-size: 32px;
  line-height: 55px;
  text-align: center;
  cursor: pointer;
  opacity: 0;
  -webkit-transition: all .1s linear;
       -o-transition: all .1s linear;
          transition: all .1s linear;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 0 2px 2px 0;
  -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.15);
          box-shadow: 0 0 1px rgba(0, 0, 0, 0.15);
}
.home-carousel__arrow:hover {
  background-color: rgba(255, 255, 255, 0.95);
}
.home-carousel__arrow_right {
  left: auto;
  right: 0;
  border-radius: 2px 0 0 2px;
}
.home-carousel:hover .home-carousel__arrow {
  opacity: 1;
}
.home-carousel__indicators {
  position: absolute;
  list-style: none;
  padding-left: 0;
  left: 0;
  right: 0;
  bottom: 20px;
  text-align: center;
}
.home-carousel__indicators > li {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin: 0 2px;
  border: 1px solid white;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
}
.home-carousel__indicators > li.active {
  background-color: white;
}
/* Home: Parallax */
.home-parallax__container {
  margin-top: -18px;
  margin-bottom: 0px;
  overflow: hidden;
}
.home-parallax {
  height: 500px;
  width: 100%;
  display: table;
  /* background-image: url(../img/sr/header/header_hintergrund.jpg); */
  text-align: center;
}
.home-parallax .btn-link {
  color: rgba(51, 51, 51, 0.8);
}
.home-parallax .btn-link:hover,
.home-parallax .btn-link:focus {
  color: #333333;
}
.home-parallax .btn-link.inverse {
  color: rgba(255, 255, 255, 0.8);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}
.home-parallax .btn-link.inverse:hover,
.home-parallax .btn-link.inverse:focus {
  color: white;
}
.home-parallax__inner {
  display: table-cell;
  vertical-align: middle;
}
/* Home: Call to action */
.call-to-action {
  padding: 40px;
  margin-bottom: 60px;
  border-top: 2px solid #0f70b7;
  background-color: #f5f5f5;
}
.call-to-action__heading {
  margin-top: 0;
  font-size: 18px;
}
.call-to-action__btn {
  text-align: center;
  margin-top: 10px;
}
.call-to-action__btn > .btn {
  padding: 12px 25px;
}
@media (max-width: 767px) {
  .call-to-action__heading {
    line-height: 1.5;
  }
  .call-to-action__content {
    line-height: 1.7;
    text-align: center;
    margin-bottom: 20px;
  }
}
/* Home: Features 2 */
.home-features_2 {
  margin-bottom: 40px;
}
.home-features_2__item {
  margin-bottom: 20px;
}
.home-features_2__icon {
  float: left;
  margin-right: 20px;
  color: #0f70b7;
  font-size: 22px;
}
.home-features_2__body {
  overflow: hidden;
}
.home-features_2__heading {
  margin-top: 0;
  font-size: 18px;
}
.home-features_2__content {
  line-height: 1.7;
}
/* Home: Portfolio */
.home-portfolio {
  margin-bottom: 40px;
}
/* Home: About */
.home-about__content {
  line-height: 1.7;
  margin-bottom: 20px;
}
/**
 * Home: Version 2
 */
/* Home: Slider */
.home-slider {
  margin-top: -18px;
}
.home-slider .carousel-inner > .item {
  height: 500px;
  overflow: hidden;
  background-color: #f5f5f5;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
          background-size: cover;
}
.home-slider #item-1 {
  /* background-image: url(../img/home_1.png); */
  background-position: bottom center;
}
.home-slider #item-1 h1 {
  margin-top: 0;
  font-size: 30px;
  text-transform: capitalize;
}
.home-slider #item-1 h3 {
  font-size: 16px;
  color: #0f70b7;
  text-transform: capitalize;
}
.home-slider #item-1 p {
  color: #777777;
  margin: 35px 0;
  line-height: 1.5;
}
.home-slider #item-2 {
  /* background-image: url(../img/home_2.png); */
  background-position: top right;
}
.home-slider #item-2 ul {
  list-style: none;
  text-align: right;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 20px;
}
.home-slider #item-2 ul > li > span {
  display: inline-block;
  margin-bottom: 3px;
  padding: 5px 15px;
  background: rgba(237, 62, 73, 0.9);
  color: white;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.175);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.175);
}
.home-slider #item-2 ul:last-child > li > span {
  background: rgba(43, 171, 207, 0.9);
}
.home-slider #item-3 {
  /* background-image: url(../img/home_3.png); */
}
.home-slider #item-3 h1 {
  margin-top: 0;
  font-size: 30px;
  text-transform: capitalize;
}
.home-slider #item-3 h3 {
  font-size: 16px;
  color: #0f70b7;
}
.home-slider #item-3 ul {
  margin: 30px 0;
  padding-left: 0;
  list-style: none;
}
.home-slider #item-3 ul > li {
  font-size: 16px;
  padding: 5px 0;
}
.home-slider #item-3 ul > li > .fa {
  color: #8cb82b;
}
.home-slider__img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 50%;
  overflow: hidden;
  text-align: center;
}
.home-slider__img > img {
  height: 100%;
  width: auto;
  margin: 0 auto;
}
.home-slider__table {
  display: table;
  width: 100%;
  height: 100%;
}
.home-slider-table__inner {
  display: table-cell;
  vertical-align: middle;
}
/* Home: Fullpage image */
.home-fullscreen {
  position: relative;
  height: 100vh;
  margin-top: -93px;
  padding-top: 75px;
  /* background: #f5f5f5 url(../img/home_5.jpg) no-repeat center bottom / cover; */
}
.home-fullscreen h3 {
  margin-top: 0;
  color: #0f70b7;
}
.home-fullscreen p {
  margin: 35px 0;
  line-height: 1.5;
}
.home-fullscreen .home-slider__img > img {
  position: absolute;
  height: 80%;
  top: 10%;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
      -ms-transform: translate(-50%, 0);
       -o-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}
/* Home: Services  */
.home-services {
  margin-bottom: 40px;
  background: #0f70b7;
  color: white;
}
.home-services .lead {
  margin: 0;
  line-height: 93px;
}
.home-services ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.home-services li {
  float: left;
  width: 24%;
  padding: 20px 3px;
  border-right: 1px solid #dc2d38;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  -webkit-transition: width 300ms;
  -o-transition: width 300ms;
  transition: width 300ms;
}
.home-services li:first-child {
  border-left: 1px solid #dc2d38;
}
.home-services ul:hover li {
  width: 20%;
}
.home-services ul:hover li:hover {
  width: 36%;
}
.home-services li i {
  font-size: 22px;
}
.home-services li p {
  margin: 10px 0 0;
  text-transform: uppercase;
}
.home-services li span {
  display: none;
}
.home-services li:hover span {
  display: inline-block;
}
@media (max-width: 991px) {
  .home-services ul {
    text-align: center;
  }
}
@media (max-width: 767px) {
  .home-services li {
    display: block;
    float: none;
    width: 100%;
    border-right: 0;
    border-bottom: 1px solid #dc2d38;
  }
  .home-services li:first-child {
    border-left: 0;
  }
  .home-services ul:hover li {
    width: 100%;
  }
  .home-services ul:hover li:hover {
    width: 100%;
  }
}
/* Home: Features */
.home-features {
  padding: 50px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.home-features__item {
  margin: 30px 0;
}
.home-features__item .fa {
  display: block;
  margin-right: 15px;
  background: #0f70b7;
  color: white;
  float: left;
  height: 30px;
  width: 30px;
  border-radius: 30px;
  line-height: 30px;
  text-align: center;
}
.home-features-item__content {
  overflow: hidden;
}
.home-features-item__content > h4 {
  margin-top: 0;
}
.home-features-item__content p {
  color: #777777;
}
/* Home: Responsive Showcase */
.responsive-showcase {
  padding: 50px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  height: 450px;
  overflow: hidden;
  background-image: -webkit-linear-gradient(#f5f5f5, white 150px, white);
  background-image: -o-linear-gradient(#f5f5f5, white 150px, white);
  background-image: linear-gradient(#f5f5f5, white 150px, white);
  background-repeat: no-repeat;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#ffffffff', GradientType=0);
}
.responsive-showcase img {
  margin: 0 auto;
}
.responsive-showcase img#imac {
  max-width: 80%;
}
.responsive-showcase img#iphone {
  max-width: 60%;
}
.responsive-showcase__desc {
  margin-top: -20px;
  margin-bottom: 40px;
  color: #777777;
  font-size: 14px;
}
.responsive-showcase__devices {
  list-style: none;
  padding-left: 0;
}
.responsive-showcase__devices > li {
  display: inline-block;
  margin-right: 5px;
  -webkit-transition: all .1s linear;
       -o-transition: all .1s linear;
          transition: all .1s linear;
}
.responsive-showcase__devices > li > .fa {
  display: block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 22px;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
}
.responsive-showcase__devices > li:hover .fa,
.responsive-showcase__devices > li.active .fa {
  border-color: #8cb82b;
  color: #8cb82b;
}
.responsive-showcase__images {
  text-align: center;
}
.responsive-showcase__images > img {
  display: none;
}
@media (max-width: 767px) {
  .responsive-showcase {
    height: auto;
  }
}
/**
 * Portfolio
 */
/* Portfolio navigation */
.portfolio__nav {
  list-style: none;
  padding-left: 0;
  margin-bottom: 40px;
  margin-top: -20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.portfolio__nav > li {
  display: inline-block;
}
.portfolio__nav > li > a {
  display: block;
  padding: 20px 10px;
  margin-bottom: -1px;
  border-bottom: 2px solid transparent;
  color: #757575;
  -webkit-transition: all .05s linear;
       -o-transition: all .05s linear;
          transition: all .05s linear;
}
.portfolio__nav > li > a:hover,
.portfolio__nav > li > a:focus {
  color: #333333;
  text-decoration: none;
}
.portfolio__nav > li.active > a {
  color: #0f70b7;
  border-bottom-color: #0f70b7;
}
@media (max-width: 767px) {
  .portfolio__nav {
    border-bottom: 0;
  }
  .portfolio__nav > li {
    display: block;
  }
  .portfolio__nav > li > a {
    padding: 10px 15px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .portfolio__nav > li.active > a {
    font-weight: 600;
  }
}
/* Portfolio thumbnails */
.isotope-item {
  padding-left: 10px;
  padding-right: 10px;
}
/* Firefox bug fix */
@media (min-width: 1200px) {
  .col-lg-4.isotope-item {
    width: 33%;
  }
}
.portfolio__item {
  margin-bottom: 20px;
}
.portfolio-item__img {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.portfolio-item__img:hover .portfolio-item__mask {
  background: rgba(255, 255, 255, 0.9);
}
.portfolio-item__img:hover .portfolio-item-mask__content {
  top: 0;
}
.portfolio-item__mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0);
  -webkit-transition: background .3s;
       -o-transition: background .3s;
          transition: background .3s;
}
@media (max-width: 767px) {
  .portfolio-item__mask {
    visibility: hidden;
  }
}
.portfolio-item-mask__content {
  position: absolute;
  display: block;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 40px 20px;
  overflow: hidden;
  color: #333333;
  text-decoration: none;
  -webkit-transition: top .3s;
       -o-transition: top .3s;
          transition: top .3s;
}
.portfolio-item-mask__title {
  margin-bottom: 20px;
  font-weight: 600;
  text-transform: uppercase;
}
.portfolio-item-mask__summary {
  font-size: 12px;
}
.portfolio-item__recent > [class*="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}
/**
 * Pricing Options
 */
.pricing-option {
  margin-bottom: 20px;
  border: 15px solid rgba(0, 0, 0, 0.05);
  text-align: center;
}
.pricing-option__inner {
  background-color: white;
}
.pricing-option_popular {
  position: relative;
}
.pricing-option_popular::before {
  content: "";
  position: absolute;
  top: -15px;
  left: -15px;
  height: 0;
  width: 0;
  border-left: 60px solid #0f70b7;
  border-bottom: 60px solid transparent;
}
.pricing-option_popular::after {
  content: "\f0e7";
  font-family: "FontAwesome";
  font-size: 16px;
  position: absolute;
  display: block;
  top: -5px;
  left: 0;
  color: white;
}
.pricing-option__inner {
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-transition: all .1s;
       -o-transition: all .1s;
          transition: all .1s;
}
.pricing-option__inner:hover {
  border-color: rgba(0, 0, 0, 0.14);
  -webkit-box-shadow: 0 3px 25px rgba(0, 0, 0, 0.3);
          box-shadow: 0 3px 25px rgba(0, 0, 0, 0.3);
}
.pricing-option__header {
  padding: 15px 10px;
}
.pricing-option__header h5,
.pricing-option__header .h5 {
  font-weight: 600;
  font-size: 14px;
}
.pricing-option__price {
  padding: 10px;
  background: rgba(0, 0, 0, 0.03);
  color: #0f70b7;
}
.pricing-option-price__currency {
  vertical-align: 90%;
}
.pricing-option-price__number {
  font-size: 36px;
  font-weight: 300;
}
.pricing-option__body {
  padding: 10px 0 15px;
}
.pricing-option__body ul > li {
 padding: 10px 15px 0 15px;
 border-bottom: 1px solid rgba(0, 0, 0, 0.1);
 text-transform: ;
 font-size: 14px;
 font-weight: normal;
 color: #666;
}
.pricing-option__body ul > li:last-child {
  border-bottom: 0;
}
/* Pricing Options - Joint */
.pricing-options_joint {
  padding: 10px;
  padding-bottom: 40px;
  background-color: rgba(0, 0, 0, 0.05);
}
.pricing-options_joint .row {
  margin-left: 0;
  margin-right: 0;
}
.pricing-options_joint [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}
.pricing-options_joint .pricing-option {
  margin-bottom: 0;
  border: 5px solid transparent;
}
.pricing-options_joint .pricing-option_popular:before {
  top: 0;
  left: 0;
}
.pricing-options_joint .pricing-option_popular:after {
  top: 10px;
  left: 15px;
}
/* Pricing Options - Table */
.pricing-options_table {
  border: 15px solid rgba(0, 0, 0, 0.05);
}
.pricing-options-table__table {
  margin-bottom: 0;
  text-align: center;
}
.pricing-options-table__table > thead > tr > th {
  text-align: center;
  padding: 0;
}
.pricing-options-table__table .pricing-option__price {
  margin: -8px;
}
.pricing-options-table__table .pricing-option_popular:before {
  border-bottom-width: 40px;
  border-left-width: 40px;
  top: 0;
  left: 0;
}
.pricing-options-table__table .pricing-option_popular:after {
  font-size: 12px;
  top: 5px;
  left: 11px;
}
.pricing-options-table__table .fa-check {
  color: rgba(0, 0, 0, 0.4);
}
.pricing-options-table__table .fa-times {
  color: rgba(0, 0, 0, 0.2);
}
.pricing-options-table__title {
  vertical-align: middle !important;
  padding-left: 20px !important;
  text-align: left;
}
/**
 * Search Results
 */
.search-results__form {
  margin-bottom: 40px;
  border: 15px solid rgba(0, 0, 0, 0.05);
}
.search-results__filter {
  margin-bottom: 40px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.search-results-filter__sort {
  list-style: none;
  padding-left: 0;
  text-align: right;
}
.search-results-filter__sort > li {
  display: inline-block;
  margin: 0 2px;
}
.search-results-filter__sort > li > a {
  display: block;
  padding: 2px 7px;
}
.search-results-filter__sort > li > a:hover,
.search-results-filter__sort > li > a:focus {
  text-decoration: none;
}
.search-results-filter__sort > li.active > a {
  background-color: #8cb82b;
  border-radius: 2px;
  color: white;
}
@media (max-width: 767px) {
  .search-results-filter__sort {
    text-align: left;
    margin-top: 20px;
  }
}
.search-results__links {
  list-style-type: none;
  padding-left: 0;
}
.search-results__links li {
  margin-bottom: 30px;
}
.search-results-links__title {
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
}
.search-results-links__snippet {
  margin-bottom: 5px;
}
.search-results-links__url {
  display: block;
  color: #999;
}
.search-results-links__url:hover {
  color: #8cb82b;
}
/**
 * Sign In & Sign Up
 */
.sign__form {
  padding: 30px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  outline: 15px solid rgba(0, 0, 0, 0.05);
}
.sign__form .popover {
  min-width: 200px;
}
.sign-form__title {
  margin: 0 0 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
/**
 * User Profile
 */
.user-profile__avatar,
.user-avatar {
  padding: 10px;
  margin-bottom: 30px;
  border: 1px solid #eee;
  border-radius: 3px;
}
.user-profile__avatar > img,
.user-avatar > img {
  margin-bottom: 10px;
  border-radius: 2px;
}
.user-profile__title {
  font-weight: 700;
}
.user-profile__desc {
  color: #777777;
}
.user-profile__url {
  margin-bottom: 20px;
}
.user-profile__info {
  margin: 20px 0;
  padding-left: 0;
  list-style: none;
}
.user-profile__info > li {
  padding: 5px 0;
}
.user-profile__info > li .fa {
  margin-right: 10px;
}
.user-profile__tabs {
  margin-top: 40px;
}
/**
 * Timeline
 */
.timeline {
  position: relative;
  list-style-type: none;
  padding: 10px 0;
  height: auto;
  overflow: hidden;
}
.timeline:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  content: "";
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  z-index: -1;
}
.timeline > .year {
  text-align: center;
}
.timeline > .year > span {
  display: inline-block;
  padding: 8px 18px;
  margin: 20px 0;
  border-radius: 2px;
  background-color: #505050;
  font-weight: 700;
  color: white;
  z-index: 1;
}
.timeline > .event {
  position: relative;
  width: 50%;
  margin-top: 0;
  margin-bottom: 40px;
  padding-right: 30px;
  padding-left: 0;
  text-align: right;
  float: left;
  clear: left;
}
.timeline > .event:nth-child(2n) {
  margin-bottom: 0;
  margin-top: 40px;
  padding-left: 30px;
  padding-right: 0;
  text-align: left;
  float: right;
  clear: right;
}
.timeline > .event:before {
  position: absolute;
  content: ".";
  top: 20px;
  right: -11px;
  font-size: 72px;
  line-height: 0;
  color: #ccc;
}
.timeline > .event:nth-child(2n):before {
  right: auto;
  left: -8px;
}
.timeline > .event:hover {
  cursor: pointer;
  /* &:after {
        content: "\f111";
      } */
}
.timeline > .event:hover h3 {
  color: #0f70b7;
}
.timeline > .event .event__title {
  position: relative;
  padding: 15px 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
}
.timeline > .event .event__title > h3 {
  font-size: 16px;
  font-weight: 400;
  margin: 0 0 5px 0;
  -webkit-transition: color 300ms;
  -o-transition: color 300ms;
  transition: color 300ms;
}
.timeline > .event .event__title > time {
  display: block;
  color: #777777;
}
.timeline > .event .event__title > time:before {
  content: "\f073";
  font-family: FontAwesome;
  color: #8cb82b;
  margin-right: 5px;
}
.timeline > .event .event__title:before,
.timeline > .event .event__title:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
}
.timeline > .event .event__title:before {
  top: 25px;
  right: -15px;
  border-top: 15px solid transparent;
  border-left: 15px solid rgba(0, 0, 0, 0.15);
  border-bottom: 15px solid transparent;
}
.timeline > .event .event__title:after {
  top: 26px;
  right: -14px;
  border-top: 14px solid transparent;
  border-left: 14px solid #f5f5f5;
  border-bottom: 14px solid transparent;
}
.timeline > .event:nth-child(2n) .event__title:before {
  right: auto;
  left: -15px;
  border-right: 15px solid rgba(0, 0, 0, 0.15);
  border-left: 0;
}
.timeline > .event:nth-child(2n) .event__title:after {
  right: auto;
  left: -14px;
  border-right: 14px solid #f5f5f5;
  border-left: 0;
}
.timeline > .event .event__content {
  padding: 15px 20px;
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.1);
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}
.timeline > .event .event__content > p {
  color: #777777;
  margin-bottom: 0;
}
.timeline > .event .event__content > p > img {
  margin-bottom: 10px;
}
.timeline > .event .event__content > p + .btn {
  margin-top: 10px;
}
@media (max-width: 767px) {
  .timeline:before {
    left: 6px;
  }
  .timeline > .year {
    margin-left: 6px;
    text-align: left;
  }
  .timeline > .year > span {
    border-radius: 0 2px 2px 0;
    margin: 0 0 30px;
  }
  .timeline > .event,
  .timeline > .event:nth-child(2n) {
    width: 100%;
    padding-left: 30px;
    padding-right: 0;
    margin: 0 0 40px 0;
    text-align: left;
    float: none;
    clear: none;
  }
  .timeline > .event:before,
  .timeline > .event:nth-child(2n):before {
    top: 20px;
    left: -2px;
    right: auto;
  }
  .timeline .event .event__title:before,
  .timeline .event:nth-child(2n) .event__title:before {
    right: auto;
    left: -15px;
    border-right: 15px solid rgba(0, 0, 0, 0.15);
    border-left: 0;
  }
  .timeline .event .event__title:after,
  .timeline .event:nth-child(2n) .event__title:after {
    right: auto;
    left: -14px;
    border-right: 14px solid #f5f5f5;
    border-left: 0;
  }
}
.timeline_left:before {
  left: 6px;
}
.timeline_left > .year {
  margin-left: 6px;
  text-align: left;
}
.timeline_left > .year > span {
  border-radius: 0 2px 2px 0;
  margin: 0 0 30px;
}
.timeline_left > .event,
.timeline_left > .event:nth-child(2n) {
  width: 100%;
  padding-left: 30px;
  padding-right: 0;
  margin: 0 0 40px 0;
  text-align: left;
  float: none;
  clear: none;
}
.timeline_left > .event:before,
.timeline_left > .event:nth-child(2n):before {
  top: 20px;
  left: -2px;
  right: auto;
}
.timeline_left .event .event__title:before,
.timeline_left .event:nth-child(2n) .event__title:before {
  right: auto;
  left: -15px;
  border-right: 15px solid rgba(0, 0, 0, 0.15);
  border-left: 0;
}
.timeline_left .event .event__title:after,
.timeline_left .event:nth-child(2n) .event__title:after {
  right: auto;
  left: -14px;
  border-right: 14px solid #f5f5f5;
  border-left: 0;
}
.timeline_right:before {
  right: 6px;
  left: auto;
}
.timeline_right > .year {
  margin-right: 6px;
  margin-left: auto;
  text-align: right;
}
.timeline_right > .year > span {
  border-radius: 2px 0 0 2px;
  margin: 0 0 30px;
}
.timeline_right > .event,
.timeline_right > .event:nth-child(2n) {
  width: 100%;
  padding-right: 30px;
  padding-left: 0;
  margin: 0 0 40px 0;
  text-align: right;
  float: none;
  clear: none;
}
.timeline_right > .event:before,
.timeline_right > .event:nth-child(2n):before {
  top: 20px;
  right: -2px;
  left: auto;
}
.timeline_right .event .event__title:before,
.timeline_right .event:nth-child(2n) .event__title:before {
  left: auto;
  right: -15px;
  border-left: 15px solid rgba(0, 0, 0, 0.15);
  border-right: 0;
}
.timeline_right .event .event__title:after,
.timeline_right .event:nth-child(2n) .event__title:after {
  left: auto;
  right: -14px;
  border-left: 14px solid #f5f5f5;
  border-right: 0;
}
/**
 * UI Elements
 */
.tooltip-test button,
.popover-test button {
  margin-bottom: 10px;
}
/* Pagination */
.pagination-example ul {
  margin: 10px 0;
}
/**
 * Resume
 */
.resume__profile {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 20px;
  margin-bottom: 20px;
}
.resume-profile__title {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding-bottom: 10px;
  margin-bottom: 15px;
}
.resume-profile__section {
  padding-bottom: 10px;
}
.resume-profile__section a {
  color: #777777;
}
.resume-profile__section a:hover,
.resume-profile__section a:focus {
  color: #333333;
}
.resume__table td {
  border-top-color: transparent !important;
}
.resume-table__header {
  color: #777777;
  width: 110px;
}
.resume__reference {
  padding-bottom: 20px;
}
/* Content: Responsive Showcase */
.content-grey {
  padding: 50px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  height: auto;
  overflow: hidden;
  background-color: #f5f5f5;
}
