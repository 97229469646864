html {
	font-family: sans-serif;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	height: 100%;
	//font-size: 62.5%;
	font-size: 16px;
	scroll-behavior: smooth;
	&:root {
		-webkit-font-smoothing: antialiased !important;
		-moz-osx-font-smoothing: grayscale !important; 
	}
	* {
		-webkit-font-smoothing: antialiased !important;
		-moz-osx-font-smoothing: grayscale !important;
	}
	&:after {
		-webkit-font-smoothing: antialiased !important;
		-moz-osx-font-smoothing: grayscale !important;
	}
	&:before {
		-webkit-font-smoothing: antialiased !important;
		-moz-osx-font-smoothing: grayscale !important;
	}
}
button {
	-webkit-font-smoothing: antialiased !important;
	-moz-osx-font-smoothing: grayscale !important;
}
input {
	-webkit-font-smoothing: antialiased !important;
	-moz-osx-font-smoothing: grayscale !important;
}
textarea {
	-webkit-font-smoothing: antialiased !important;
	-moz-osx-font-smoothing: grayscale !important;
}
body {
	position: relative;
	min-height: 100%;
	padding: 0;
}
// .backtotop {
// 	background: rgba(0, 0, 0, 0.2);
// 	padding: 18px;
// 	font-size: 18px;
// 	color: #FFF;
// 	border-radius: 30px;
// 	-webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2);
// 	box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2);
// 	text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
// 	-webkit-transition: background .1s;
// 	-o-transition: background .1s;
// 	transition: background .1s;
// }
@media (max-width: 767px) {
	.shop-slideshow__heading {
		margin: 0 0 29px 0;
		font-family: 'Roboto', sans-serif;
		font-size: 28px;
		line-height: 33px;
		color: white;
		text-align: left;
	}
	.shop-slideshow__subheading {
		margin-bottom: 30px;
		font-family: 'Roboto', sans-serif;
		font-size: 14px;
		line-height: 20px;
		color: white;
		text-align: left;
	}
	.carousel-indicators {
		bottom: 0px;
	}
	.form-control-searchbox {
		padding: 10px 30px 10px 30px;
		border-radius: 40px;
		font-size: 14px;
		height: 100%;
	}
}
@media (min-width: 768px) {
	.carousel-indicators {
		bottom: 40px;
	}
	.btn-secondary {
		padding: 9px 18px;
		font-size: 14px;
		line-height: 20px;
		border-radius: 30px;
	}
}
@media (min-width: 992px) {
	.carousel-indicators {
		bottom: 120px;
	}
	.btn-secondary {
		background-color: transparent;
		border: 1px solid #454545;
		color: #454545;
		-webkit-transition: all .05s linear;
		-o-transition: all .05s linear;
		transition: all .05s linear;
	}
	.shop-index__section.blue {
		padding-top: 60px;
		padding-bottom: 60px;
	}
	.shop-index__section.middleblue {
		padding-top: 60px;
		padding-bottom: 60px;
	}
	.shop-index__section.grey {
		padding-top: 60px;
		padding-bottom: 60px;
	}
}
@media (min-width: 1023px) and (max-width: 1025px) {
	.carousel-indicators {
		bottom: 20px;
	}
}
@media (min-width: 1200px) {
	.carousel-indicators {
		bottom: 120px;
	}
}
