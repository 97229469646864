.nutzerverwaltung {
    .status {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        // background: #052836;
        margin: 0 auto;
    }
    .status.status-green {
        background-color: #0086C5;
    }
    .status.status-yellow {
        background-color: #7DC6E8;
    }
    .status.status-orange {
        background-color: #FFFFFF;
    }
    .status.status-red {
        background-color: #21507B;
    }
    .status-small {
        &:before {
            content: "";
            display: block;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            float: left;
            margin: 3px 6px 0 0;
        }
    }
    .status-small.status-green {
        &:before {
            background-color: #0086C5;
        }
    }
    .status-small.status-yellow {
        &:before {
            background-color: #7DC6E8;
        }
    }
    .status-small.status-orange {
        &:before {
            background-color: #FFFFFF;
        }
    }
    .status-small.status-red {
        &:before {
            background-color: #21507B;
        }
    }
    .link-back {
        display: block;
        margin-bottom: 90px;
        color: #fff;
        font-size: 14px;
        line-height: 16px;
        svg {
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
        }
    }
    th.hide {
        width: 0 !important;
        overflow: hidden;
    }
    .spinner img {
    	margin-top: 25%;
      -webkit-animation: rotation 1s linear 0s infinite;
         -moz-animation: rotation 1s linear 0s infinite;
           -o-animation: rotation 1s linear 0s infinite;
              animation: rotation 1s linear 0s infinite;
    }

    @-webkit-keyframes rotation { from { transform: rotate(0deg); } to { transform: rotate(359deg); }  }
       @-moz-keyframes rotation { from { transform: rotate(0deg); } to { transform: rotate(359deg); }  }
         @-o-keyframes rotation { from { transform: rotate(0deg); } to { transform: rotate(359deg); }  }
            @keyframes rotation { from { transform: rotate(0deg); } to { transform: rotate(359deg); }  }

    .dataTables_filter {
        display: none;
    }

    .rk-compat-2017 {
        table.dataTable {
            color: #FFF;
        }
    }
    #tableSortXs {
        .btn {
            padding-right: inherit;
            padding-left: inherit;
            padding-left: inherit;
            padding-right: inherit;
            padding-top: 4px;
            padding-bottom: 4px;
            padding-left: inherit;
            padding-right: inherit;
            padding-top: 5px;
            padding-bottom: 5px;
            border: 1px solid rgba(0,134,197,0.5);
            font-size: 11px;
        }
        .btn.btn-default.active {
            background-color: rgba(2,27,38,0.8) !important;
            border: 1px solid rgba(0, 134, 197, 0.4) !important;
        }
    }
    .icongrid-table {
        position: relative;
        width: 100%;
        height: 20px;
        margin: 0 auto;
        i {
            font-size: 12px;
            opacity: 0.5;
            //position: absolute;
            &:before {
                height: 15px;
                width: 15px;
                display: inline-block;
            }
        }
    }

    .team-of-subteam {
        font-size: 11px;
        line-height: 18px;
        color: rgba(255,255,255,0.7);
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .col-status {
        svg {
            background-color: #fff;
            border-radius: 50%;
        }
    }

    th {
        &.action {
            text-align: right;
        }
    }

    .table-dropdown {
        &.open {
            .dropdown-menu {
                background-color: rgba(6, 53, 72, 0.9);
                border-radius: 4px;
                border: 1px solid rgba(0,134,197,0.58);
                padding: 0;
                min-width: unset;
                margin-top: 10px;
                li {
                    border-bottom: 1px solid rgba(0,134,197,0.58);
                    &:last-child {
                        border-bottom: 0;
                    }
                    a {
                        font-size: 11px;
                        line-height: 13px;
                        letter-spacing: 0.2px;
                        font-weight: 500;
                        color: rgba(255,255,255,0.4);
                        text-align: center;
                        padding: 10px;
                        &:hover, &:focus {
                            background-color: transparent;
                            color: rgba(255,255,255,0.8);
                        }
                    }
                }
            }
            &.action {
                .dropdown-menu {
                    left: -22px;
                    width: 125px;
                    &::before {
                        font-family: sr-icons;
                        content: "\e5c7";
                        position: absolute;
                        font-size: 40px;
                        color: rgba(0, 134, 197, 0.58);
                        top: -32px;
                        left: 42px;
                        pointer-events: none
                    }
                    &::after {
                        font-family: sr-icons;
                        content: "\e5c7";
                        position: absolute;
                        font-size: 42px;
                        color: rgb(6, 53, 72);
                        top: -32px;
                        left: 41px;
                        pointer-events: none
                    }
                }
            }
            &.status {
                .dropdown-menu {
                    left: -80px;
                    width: 175px;
                    &::before {
                        font-family: sr-icons;
                        content: "\e5c7";
                        position: absolute;
                        font-size: 40px;
                        color: rgba(0, 134, 197, 0.58);
                        top: -32px;
                        left: 67px;
                        pointer-events: none
                    }
                    &::after {
                        font-family: sr-icons;
                        content: "\e5c7";
                        position: absolute;
                        font-size: 42px;
                        color: rgb(6, 53, 72);
                        top: -32px;
                        left: 66px;
                        pointer-events: none
                    }
                    li {
                        font-size: 11px;
                        line-height: 13px;
                        letter-spacing: 0.2px;
                        font-weight: 500;
                        color: rgba(255,255,255,0.4);
                        text-align: center;
                        padding: 6px 10px 10px;
                        cursor: pointer;
                        &:hover {
                            color: rgba(255,255,255,0.8);
                        }
                    }
                }
            }
            .action-dots {
                svg {
                    g {
                        fill: #fff;
                    }
                }
            }
        }
        &.action {
            text-align: right;
            top: -3px;
            right: 9px;
        }
        &.status {
            svg {
                background-color: #fff;
                border-radius: 50%;
                margin-right: 7px;
                position: relative;
                top: 3px;
            }
            .status-item {
                &::after {
                    font-family: sr-icons;
                    content: "\e5c5";
                    font-size: 25px;
                    color: rgba(255,255,255,0.5);
                    position: absolute;
                    top: -7px;
                    left: 15px;
                }
            }
            &.open {
                .status-item {
                    &::after {
                        font-family: sr-icons;
                        content: "\e5c7";
                        color: rgb(255,255,255);
                    }
                }
            }
        }

        .dropdown-toggle {
            cursor: pointer;
        }

        .action-dots {
            &:hover, &:focus {
                svg {
                    g {
                        fill: #fff;
                    }
                }
            }
        }
    }

    .dataTables_length {
        display: none;
    }

    div.dataTables_paginate {
        text-align: center !important;
        padding-top: 80px;
        padding-bottom: 50px;
    }
    .table-link {
        color: #0086C5 !important;
        white-space: nowrap;
        display: block;
    }
    #searchTeams {
        border: 1px solid rgba(255,255,255,1) !important;
        border-radius: 4px;
        color: rgba(255,255,255,0.39);
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.33px;
        line-height: 14px;
        height: 35px;
    }
    .exact-match {
        &::before {
            display: inline-block;
        }
    }
    label {
        font-weight: 500;
    }
    .ui-widget-content {
        border: 0 !important;
        background: rgba(5,40,54,0.88) !important;
        color: #fff;
        color: rgba(255,255,255,1);
        font-family: Roboto;
        font-size: 13px;
        font-weight: 300;
        letter-spacing: 0.56px;
        line-height: 18px;
        text-shadow: 0 2px 31px 0 rgba(0,0,0,0.79);
    }
    .paginate_button.current {
        color: #0086C5 !important;
        font-weight: 600;
    }
    .paginate_button {
        position: relative;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
    .pagination-icon {
        height: 15px;
        fill: white;
        position: absolute;
        top: 12px;
        left: 0px;
    }

    ul.select2-results__options.open {
        box-shadow: 0 0 black !important;
        margin-top: 0px !important;
        li {
            background-color: rgba(14,71,97,1);
            border-left: 0px !important;
            border-right: 0px !important;
            color: rgba(255,255,255,0.5);
            font-size: 12px;
            font-weight: 500;
        }
    }
    .th-search {
        position: relative;
        label {
            margin: 0 10px 0 0;
        }
        .dropdown {
            .btn {
                padding: 10px 40px;
            }
        }
    }

    div.dataTables_wrapper {
        position: relative;
        div.dataTables_processing {
            z-index: 5000;
            position: absolute;
            top: 50px;
            left: 50%;
            width: 200px;
            margin-left: -100px;
            margin-top: -26px;
            text-align: center;
            padding: 1em 0;
        }
    }
    .form-group {
        .checkbox {
            label {
                &:before {
                    position: absolute;
                    left: -30px;
                    top: 2px;
                    content: "";
                    color: #fff;
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 15px;
                    border-radius: 2px;
                    width: 16px;
                    height: 16px;
                    line-height: 14px;
                    margin-top: -2px;
                    background-color: transparent;
                    border: 1px solid #fff;
                    text-align: center;
                    font-family: fontawesome;
                }
            }
        }
    }
    .sr-icon-grid {
        .info_small {
            &::before {
                font-size: 17px !important;
            }
        }
    }
    .alert-warning {
        height: 24px;
        margin-right: 24px;
        margin-top: 5px;
        float: left;
    }
    .alert {
        font-size: 12px;
        line-height: 17px;
        padding: 12px 25px;
        b {
            font-weight: 500;
        }
    }
    #errorModal {
        .modal-dialog {
            .modal-content {
                .modal-body {
                    min-height: 10px;
                }
                border-radius: 4px;
            }
        }
    }
    #errorMessage {
        display: flex;
        color: #454545;
    }
    .search-page-wrapper {
        text-align: center;
        * {
            display: inline-block;
        }
    }
    #page_number_filter {
        width: 50px;
        padding: 0;
        margin: 0 10px;
    }
    .page-search-icon {
        font-size: 25px;
        vertical-align: middle;
        cursor: pointer;
    }

    .dropdown {
        &.custom-dropdown {
            min-width: 120px;
            button {
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    // uc override special case
    &.uc.dark-bg {
        .form-group {
            .dropdown.custom-dropdown {
                input[type="checkbox"] ~ label::before {
                    display: inline-table;
                    margin-right: 5px;
                }
                input[type="checkbox"]:checked + label::before {
                    display: inline-table;
                    position: relative;
                    top: -3px;
                }
            }
        }
    }

    #userliste_filter {
        &::placeholder {
            color: transparent;
            visibility: hidden;
        }
    }

    #search-in-label {
        min-width: 48px;
    }
    .search-in-wrapper {
        align-items: start;
    }
    .search-in-wrapper,
    .search_max_km_dropdown-wrapper {
        float: right;
        .dropdown {
            width: 190px;
        }
    }
    .search-list-wrapper {
        float: right;
        input {
            width: 190px;
        }
    }

    input[type="checkbox"] + label::before,
    input[type="checkbox"] + span::before {
        width: 16px;
        height: 16px;
        background-color: #FFFFFF;
        display: table;
        content:"";
        border-radius: 2px;
        border: 1.5px solid rgba(69,69,69, 0.38);
        font-size:13px;
        line-height: 0.8;
        border-collapse:separate;
        display: inline-block;
    }
    input[type="checkbox"]:checked + label::before,
    input[type="checkbox"]:checked + span::before {
        font-family: 'sr-icons', 'FontAwesome';
        font-size:13px;
        background-color: #8cb82b;
        content: "\f00c";
        color: #FFFFFF;
        width: 16px;
        height: 16px;
        border: 1.5px solid #8cb82b;
        display: table;
        line-height: 0.8;
        display: inline-block;
    }


    #nutzerverwaltungTable {
        > tbody > tr > td {
            border-top: 0;
        }
        td {
            &.col-name {
                display: inherit;
                height: 85px;
                .name-mail-icons {
                    padding: 13px 0;
                }
            }
        }
        &.loading {
            opacity: 0.3;
        }
        .btn.btn-default.dropdown-toggle {
            &::after {
                right: 0px;
            }
        }
        .custom-dropdown {
            .btn {
                border-color: #0d4058 !important;
                background-color: #0d4058 !important;
                color: rgba(254,254,254,0.8) !important;
                transition: background-color 500ms;
                display: inline-block;
                text-align: center;
                vertical-align: middle;
                touch-action: manipulation;
                cursor: pointer;
                background-image: none;
                padding: 3px 9px;
                padding-right: 40px;
                padding-left: 40px;
            }
            li {
                a {
                    white-space: nowrap;
                    overflow: hidden;
                    width: 100%;
                    text-overflow: ellipsis;
                }
            }
        }
        .btn.btn-default.dropdown-toggle {
            min-width: 120px;
            padding-left: 5px;
            padding-right: 5px;
            text-align: left;
        }
        .status-open {
            height: 15px;
            width: 15px;
            background-color: #B4B4B4;
            border-radius: 50%;
            display: inline-block;
            position: relative;
            top: -2px;
            margin-right: 10px;
            vertical-align: middle;
        }
        .status-approved {
            height: 15px;
            width: 15px;
            background-color: #7eab2d;
            border-radius: 50%;
            display: inline-block;
            position: relative;
            top: -2px;
            margin-right: 10px;
            vertical-align: middle;
        }
        .status-pending {
            height: 15px;
            width: 15px;
            background-color: #F7931D;
            border-radius: 50%;
            display: inline-block;
            position: relative;
            top: -2px;
            margin-right: 10px;
            vertical-align: middle;
        }
        .dropdown.custom-dropdown {
            .dropdown-menu {
                li {
                    a {
                        text-align: left;
                    }
                }
            }
        }
    }

    .table-legend {
        color: rgba(255,255,255,0.5);
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.3px;
        div {
            margin-bottom: 8px;
        }
        i {
            min-width: 20px;
            display: inline-block;
        }
    }



    /* desktop */
    @media (min-width: 992px) {
        // quick dirty refactor overrite
        &.uc main .dark-bg .btn.btn-default.dropdown-toggle {
            min-width: unset;
        }
    }

    /* tablet + desktop */
    @media (min-width: 768px) {
        .sr-icon-grid {
            >li {
                flex-basis: 19% !important;
            }
        }
    }

    /* tablet */
    @media (min-width: 768px) and (max-width: 992px) {
        .dropdown {
            .custom-dropdown {
                &.export {
                    max-width: 315px;
                }
            }
        }
        .dropdown-toggle {
            min-width: 160px!important;
        }
        #search-in-label {
            min-width: 55px;
        }
        .search-in-wrapper {
            align-items: start;
        }
        .search-in-wrapper,
        .search_max_km_dropdown-wrapper {
            float: right;
            .dropdown {
                width: 190px;
            }
        }
        .search-list-wrapper {
            float: right;
            input {
                width: 190px;
            }
        }

        /*
        .search-control input,
        .search-control .custom-dropdown {
            width: 190px;
        }
        */
        /*
        #nutzerverwaltungTable_wrapper {
            margin: 0!important;
        }*/
        #nutzerverwaltungTable {
            td {
                &.col-name {
                    .name-mail-icons {
                        padding-left: 24px;
                    }
                }
            }
        }
        .table-dropdown {
            &.open {
                &.action {
                    text-align: center;
                    .dropdown-menu {
                        left: -80px;
                        &::before {
                            left: 82px;
                        }
                        &::after {
                            left: 81px;
                        }
                    }
                }
            }
            &.action {
                text-align: center;
            }
        }

    }

    /* mobile */
    @media (max-width: 767px) {

        #lk-cities-dropdown .select2-selection.select2-selection--single {
            padding-top: 5px;
        }

        #userliste_filter {
            &::placeholder {
                color: rgba(255,255,255,0.4);
                visibility: visible;
            }
        }

        .search-list-wrapper,
        .search-in-wrapper,
        .search_max_km_dropdown-wrapper {
            width: 100%;
            float: left;
            .dropdown {
                width: 100%;
            }
            input {
                width: 100%;
            }
        }
        .dropdown {
            &.custom-dropdown {
                width: 100%;
            }
        }

        .th-search {
            label {
                margin: 0 10px 5px 0;
            }
        }

        .search-control {
            .th-search {
                display: block;
            }
        }

        .label-sorting {
            font-size: 11px;
            line-height: 13px;
            letter-spacing: 0.3px;
            font-weight: 500;
        }

        #nutzerverwaltungTable_wrapper {
            margin: 0 -30px!important;
        }


        #nutzerverwaltungTable {
            td {
                &.col-name {
                    font-size: 13px;
                    line-height: 17px;
                    letter-spacing: 0.3px;
                    font-weight: 400;
                    color: rgba(255,255,255,0.9);
                    height: 75px;
                    .name-mail-icons {
                        padding: 15px 0 5px 30px;
                    }
                }
                .name {
                    max-width: 200px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .table-link {
                    font-size: 11px;
                    line-height: 18px;
                    letter-spacing: 0.4px;
                    font-weight: 500;
                    color: rgba(0,134,197,0.9);
                    max-width: 185px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .teamname {
                    font-size: 11px;
                    line-height: 18px;
                    letter-spacing: 0.4px;
                    font-weight: 300;
                    color: rgba(255,255,255,0.8);
                    max-width: 185px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .icongrid-table {
                    display: none;
                }
                .km-value {
                    font-size: 18px;
                    line-height: 18px;
                    font-weight: 400;
                    color: rgba(255,255,255,0.9);
                }
                .km-unit {
                    font-size: 13px;
                    line-height: 18px;
                    letter-spacing: 0.4px;
                    font-weight: 300;
                    color: rgba(255,255,255,0.8);
                }
                &.col-km {
                    width: 20%;
                    padding: 28px 20px;
                }
                &.col-tracks {
                    padding: 23px 20px;
                }
                &.col-action {
                    width: 20%;
                    padding: 20px;
                    overflow: visible;
                }
                .table-dropdown {
                    &.open {
                        &.action {
                            .dropdown-menu {
                                left: -60px;
                                &::before {
                                    left: unset;
                                    right: -5px;
                                }
                                &::after {
                                    left: unset;
                                    right: -6px;
                                }
                            }
                        }
                    }
                    &.action {
                        top: 0;
                        right: 0;
                    }
                }
            }
        }

        .dashboard {
            .numberOfResults {
                margin-top: -80px;
            }
            .dataTables_wrapper {
                div.dataTables_paginate {
                    padding-top: 70px;
                    padding-bottom: 0;
                }
            }
        }

        /*
        label {
            font-size: 11px;
        }

        .noOverflowHidden {
            overflow: visible !important;
        }
        .xs-font-11 {
            font-size: 11px !important;
            color: rgba(255,255,255,0.5);
        }
        .data-table {
            tbody {
                >tr {
                    td {
                        font-size: 13px;
                    }
                }
            }
        }
        */

    }

}
