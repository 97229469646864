h1 {
	font-family: inherit;
	font-weight: 400;
	line-height: 1.1;
	color: inherit;
	margin-top: 20px;
	margin-bottom: 10px;
	font-size: 36px;
	small {
		font-weight: normal;
		line-height: 1;
		color: #898989;
		font-size: 65%;
	}
	.small {
		font-weight: normal;
		line-height: 1;
		color: #898989;
		font-size: 65%;
	}
}
h2 {
	font-family: inherit;
	font-weight: 400;
	line-height: 1.1;
	color: inherit;
	margin-top: 20px;
	margin-bottom: 10px;
	font-size: 32px;
	small {
		font-weight: normal;
		line-height: 1;
		color: #898989;
		font-size: 65%;
	}
	.small {
		font-weight: normal;
		line-height: 1;
		color: #898989;
		font-size: 65%;
	}
}
h3 {
	font-family: inherit;
	font-weight: 400;
	line-height: 1.1;
	color: inherit;
	margin-top: 20px;
	margin-bottom: 10px;
	font-size: 21px;
	small {
		font-weight: normal;
		line-height: 1;
		color: #898989;
		font-size: 65%;
	}
	.small {
		font-weight: normal;
		line-height: 1;
		color: #898989;
		font-size: 65%;
	}
}
h4 {
	font-family: inherit;
	font-weight: 400;
	line-height: 1.1;
	color: inherit;
	margin-top: 10px;
	margin-bottom: 10px;
	font-size: 18px;
	small {
		font-weight: normal;
		line-height: 1;
		color: #898989;
		font-size: 75%;
	}
	.small {
		font-weight: normal;
		line-height: 1;
		color: #898989;
		font-size: 75%;
	}
}
h5 {
	font-family: inherit;
	font-weight: 400;
	line-height: 1.1;
	color: inherit;
	margin-top: 10px;
	margin-bottom: 10px;
	font-size: 14px;
	small {
		font-weight: normal;
		line-height: 1;
		color: #898989;
		font-size: 75%;
	}
	.small {
		font-weight: normal;
		line-height: 1;
		color: #898989;
		font-size: 75%;
	}
}
h6 {
	font-family: inherit;
	font-weight: 400;
	line-height: 1.1;
	color: inherit;
	margin-top: 10px;
	margin-bottom: 10px;
	font-size: 12px;
	small {
		font-weight: normal;
		line-height: 1;
		color: #898989;
		font-size: 75%;
	}
	.small {
		font-weight: normal;
		line-height: 1;
		color: #898989;
		font-size: 75%;
	}
}
.h1 {
	font-family: inherit;
	font-weight: 400;
	line-height: 1.1;
	color: inherit;
	margin-top: 20px;
	margin-bottom: 10px;
	font-size: 36px;
	small {
		font-weight: normal;
		line-height: 1;
		color: #898989;
		font-size: 65%;
	}
	.small {
		font-weight: normal;
		line-height: 1;
		color: #898989;
		font-size: 65%;
	}
}
.h2 {
	font-family: inherit;
	font-weight: 400;
	line-height: 1.1;
	color: inherit;
	margin-top: 20px;
	margin-bottom: 10px;
	font-size: 32px;
	small {
		font-weight: normal;
		line-height: 1;
		color: #898989;
		font-size: 65%;
	}
	.small {
		font-weight: normal;
		line-height: 1;
		color: #898989;
		font-size: 65%;
	}
}
.h3 {
	font-family: inherit;
	font-weight: 400;
	line-height: 1.1;
	color: inherit;
	margin-top: 20px;
	margin-bottom: 10px;
	font-size: 21px;
	small {
		font-weight: normal;
		line-height: 1;
		color: #898989;
		font-size: 65%;
	}
	.small {
		font-weight: normal;
		line-height: 1;
		color: #898989;
		font-size: 65%;
	}
}
.h4 {
	font-family: inherit;
	font-weight: 400;
	line-height: 1.1;
	color: inherit;
	margin-top: 10px;
	margin-bottom: 10px;
	font-size: 18px;
	small {
		font-weight: normal;
		line-height: 1;
		color: #898989;
		font-size: 75%;
	}
	.small {
		font-weight: normal;
		line-height: 1;
		color: #898989;
		font-size: 75%;
	}
}
.h5 {
	font-family: inherit;
	font-weight: 400;
	line-height: 1.1;
	color: inherit;
	margin-top: 10px;
	margin-bottom: 10px;
	font-size: 14px;
	small {
		font-weight: normal;
		line-height: 1;
		color: #898989;
		font-size: 75%;
	}
	.small {
		font-weight: normal;
		line-height: 1;
		color: #898989;
		font-size: 75%;
	}
}
.h6 {
	font-family: inherit;
	font-weight: 400;
	line-height: 1.1;
	color: inherit;
	margin-top: 10px;
	margin-bottom: 10px;
	font-size: 12px;
	small {
		font-weight: normal;
		line-height: 1;
		color: #898989;
		font-size: 75%;
	}
	.small {
		font-weight: normal;
		line-height: 1;
		color: #898989;
		font-size: 75%;
	}
}
p {
	margin: 0 0 10px;
}
.lead {
	margin-bottom: 20px;
	font-size: 16px;
	font-weight: 300;
	line-height: 1.4;
}
small {
	font-size: 85%;
}
.small {
	font-size: 85%;
}
mark {
	background-color: #fcf8e3;
	padding: .2em;
}
.mark {
	background-color: #fcf8e3;
	padding: .2em;
}
.text-left {
	text-align: left;
}
.text-right {
	text-align: right;
}
.text-center {
	text-align: center;
}
.text-justify {
	text-align: justify;
}
.text-nowrap {
	white-space: nowrap;
}
.text-lowercase {
	text-transform: lowercase;
}
.text-uppercase {
	text-transform: uppercase;
}
.initialism {
	text-transform: uppercase;
	font-size: 90%;
}
.text-capitalize {
	text-transform: capitalize;
}
.text-muted {
	color: #898989;
}
.bg-primary {
	color: #fff;
}
.page-header {
	padding-bottom: 9px;
	margin: 40px 0 20px;
	border-bottom: 1px solid #e7e7e7;
}
ul {
	margin-top: 0;
	margin-bottom: 10px;
	ul {
		margin-bottom: 0;
	}
	ol {
		margin-bottom: 0;
	}
}
ol {
	margin-top: 0;
	margin-bottom: 10px;
	ul {
		margin-bottom: 0;
	}
	ol {
		margin-bottom: 0;
	}
}
.list-unstyled {
	padding-left: 0;
	list-style: none;
}
.list-inline {
	padding-left: 0;
	list-style: none;
	margin-left: -5px;
	>li {
		display: inline-block;
		padding-left: 5px;
		padding-right: 5px;
	}
}
dl {
	margin-top: 0;
	margin-bottom: 20px;
}
dt {
	line-height: 1.428571429;
	font-weight: bold;
}
dd {
	line-height: 1.428571429;
	margin-left: 0;
}
abbr[title] {
	cursor: help;
	border-bottom: 1px dotted #898989;
}
abbr[data-original-title] {
	cursor: help;
	border-bottom: 1px dotted #898989;
}
blockquote {
	padding: 10px 20px;
	margin: 0 0 20px;
	font-size: 17.5px;
	border-left: 5px solid #e7e7e7;
	p {
		&:last-child {
			margin-bottom: 0;
		}
	}
	ul {
		&:last-child {
			margin-bottom: 0;
		}
	}
	ol {
		&:last-child {
			margin-bottom: 0;
		}
	}
	footer {
		display: block;
		font-size: 80%;
		line-height: 1.428571429;
		color: #898989;
		&:before {
			content: '\2014 \00A0';
		}
	}
	small {
		display: block;
		font-size: 80%;
		line-height: 1.428571429;
		color: #898989;
		&:before {
			content: '\2014 \00A0';
		}
	}
	.small {
		display: block;
		font-size: 80%;
		line-height: 1.428571429;
		color: #898989;
		&:before {
			content: '\2014 \00A0';
		}
	}
}
.blockquote-reverse {
	padding-right: 15px;
	padding-left: 0;
	border-right: 5px solid #e7e7e7;
	border-left: 0;
	text-align: right;
	footer {
		&:before {
			content: '';
		}
		&:after {
			content: '\00A0 \2014';
		}
	}
	small {
		&:before {
			content: '';
		}
		&:after {
			content: '\00A0 \2014';
		}
	}
	.small {
		&:before {
			content: '';
		}
		&:after {
			content: '\00A0 \2014';
		}
	}
}
blockquote.pull-right {
	padding-right: 15px;
	padding-left: 0;
	border-right: 5px solid #e7e7e7;
	border-left: 0;
	text-align: right;
	footer {
		&:before {
			content: '';
		}
		&:after {
			content: '\00A0 \2014';
		}
	}
	small {
		&:before {
			content: '';
		}
		&:after {
			content: '\00A0 \2014';
		}
	}
	.small {
		&:before {
			content: '';
		}
		&:after {
			content: '\00A0 \2014';
		}
	}
}
address {
	margin-bottom: 20px;
	font-style: normal;
	line-height: 1.428571429;
}
@media (min-width: 768px) {
	.lead {
		font-size: 21px;
	}
	.dl-horizontal {
		dt {
			float: left;
			width: 160px;
			clear: left;
			text-align: right;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		dd {
			margin-left: 180px;
		}
	}
}
