// general new base styling rules
body {
    &.uc {
        font-size: 16px;
        font-size: 1rem;
    }
}
.uc {
    &.dark-bg, .dark-bg {
        color: #fefefe;
        background-color: #063547;
    }
}