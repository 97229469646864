.auswertung-gesamt-ranking-table {
    .mobile-newcomer-toggle {
        z-index: 10;
    }
    .results-wrapper {
        margin-top: 20px;
    }
    @media (max-width: 767px) {
        .results-wrapper {
            margin-top: -50px;
        }
    }
}