.chosen-container-single {
	.chosen-single {
		border: 0;
		border-bottom: 1px solid #fff;
		border-radius: 0;
		background: none;
		color: #fff;
		line-height: 1.42857143;
		box-shadow: none;
	}
}
.chosen-container-active.chosen-with-drop {
	.chosen-single {
		border: 0;
		border-radius: 0;
		background: none;
		box-shadow: none;
	}
}
.chosen-container {
	.chosen-drop {
		border: 0;
		box-shadow: none;
		background-color: #063548;
	}
	.chosen-results {
		color: #444;
		margin: 0;
		padding: 0;
		li {
			border: 1px solid #0086c5;
			color: #fff;
			line-height: 24px;
			margin-top: -1px;
			&:first-child {
				margin-top: 0px;
			}
		}
	}
}
