.carousel {
	position: relative;
}
.carousel-inner {
	position: relative;
	overflow: hidden;
	width: 100%;
	>.item {
		display: none;
		position: relative;
		-webkit-transition: 0.6s ease-in-out left;
		-o-transition: 0.6s ease-in-out left;
		transition: 0.6s ease-in-out left;
		>img {
			display: block;
			max-width: 100%;
			height: auto;
			line-height: 1;
		}
		>a {
			>img {
				display: block;
				max-width: 100%;
				height: auto;
				line-height: 1;
			}
		}
	}
	>.active {
		display: block;
		left: 0;
	}
	>.next {
		display: block;
		position: absolute;
		top: 0;
		width: 100%;
		left: 100%;
	}
	>.prev {
		display: block;
		position: absolute;
		top: 0;
		width: 100%;
		left: -100%;
	}
	>.next.left {
		left: 0;
	}
	>.prev.right {
		left: 0;
	}
	>.active.left {
		left: -100%;
	}
	>.active.right {
		left: 100%;
	}
}
.carousel-control {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 15%;
	opacity: 0.5;
	filter: alpha(opacity=50);
	font-size: 20px;
	color: #fff;
	text-align: center;
	text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
	background-color: rgba(0, 0, 0, 0);
	&:hover {
		outline: 0;
		color: #fff;
		text-decoration: none;
		opacity: 0.9;
		filter: alpha(opacity=90);
	}
	&:focus {
		outline: 0;
		color: #fff;
		text-decoration: none;
		opacity: 0.9;
		filter: alpha(opacity=90);
	}
	.icon-prev {
		position: absolute;
		top: 50%;
		margin-top: -10px;
		z-index: 5;
		display: inline-block;
		left: 50%;
		margin-left: -10px;
		width: 20px;
		height: 20px;
		line-height: 1;
		font-family: serif;
		&:before {
			content: '\2039';
		}
	}
	.icon-next {
		position: absolute;
		top: 50%;
		margin-top: -10px;
		z-index: 5;
		display: inline-block;
		right: 50%;
		margin-right: -10px;
		width: 20px;
		height: 20px;
		line-height: 1;
		font-family: serif;
		&:before {
			content: '\203a';
		}
	}
	.glyphicon-chevron-left {
		position: absolute;
		top: 50%;
		margin-top: -10px;
		z-index: 5;
		display: inline-block;
		left: 50%;
		margin-left: -10px;
	}
	.glyphicon-chevron-right {
		position: absolute;
		top: 50%;
		margin-top: -10px;
		z-index: 5;
		display: inline-block;
		right: 50%;
		margin-right: -10px;
	}
}
.carousel-control.left {
	background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
	background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
	background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1);
}
.carousel-control.right {
	left: auto;
	right: 0;
	background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
	background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
	background-image: linear-gradient(to right, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);
}
.carousel-indicators {
	position: absolute;
	bottom: 10px;
	left: 50%;
	z-index: 15;
	width: 60%;
	margin-left: -30%;
	padding-left: 0;
	list-style: none;
	text-align: center;
	li {
		display: inline-block;
		width: 10px;
		height: 10px;
		margin: 1px;
		text-indent: -999px;
		border: 1px solid #fff;
		border-radius: 10px;
		cursor: pointer;
		background-color: #000 \9;
		background-color: rgba(0, 0, 0, 0);
	}
	.active {
		margin: 0;
		width: 12px;
		height: 12px;
		background-color: #fff;
	}
}
.carousel-caption {
	position: absolute;
	left: 15%;
	right: 15%;
	bottom: 20px;
	z-index: 10;
	padding-top: 20px;
	padding-bottom: 20px;
	color: #fff;
	text-align: center;
	text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
	.btn {
		text-shadow: none;
	}
}
@media all and (transform-3d) {
	.carousel-inner {
		>.item {
			-webkit-transition: -webkit-transform 0.6s ease-in-out;
			-moz-transition: -moz-transform 0.6s ease-in-out;
			-o-transition: -o-transform 0.6s ease-in-out;
			transition: transform 0.6s ease-in-out;
			-webkit-backface-visibility: hidden;
			-moz-backface-visibility: hidden;
			backface-visibility: hidden;
			-webkit-perspective: 1000px;
			-moz-perspective: 1000px;
			perspective: 1000px;
		}
		>.item.next {
			-webkit-transform: translate3d(100%, 0, 0);
			transform: translate3d(100%, 0, 0);
			left: 0;
		}
		>.item.active.right {
			-webkit-transform: translate3d(100%, 0, 0);
			transform: translate3d(100%, 0, 0);
			left: 0;
		}
		>.item.prev {
			-webkit-transform: translate3d(-100%, 0, 0);
			transform: translate3d(-100%, 0, 0);
			left: 0;
		}
		>.item.active.left {
			-webkit-transform: translate3d(-100%, 0, 0);
			transform: translate3d(-100%, 0, 0);
			left: 0;
		}
		>.item.next.left {
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
			left: 0;
		}
		>.item.prev.right {
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
			left: 0;
		}
		>.item.active {
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
			left: 0;
		}
	}
}
@media (-webkit-transform-3d) {
	.carousel-inner {
		>.item {
			-webkit-transition: -webkit-transform 0.6s ease-in-out;
			-moz-transition: -moz-transform 0.6s ease-in-out;
			-o-transition: -o-transform 0.6s ease-in-out;
			transition: transform 0.6s ease-in-out;
			-webkit-backface-visibility: hidden;
			-moz-backface-visibility: hidden;
			backface-visibility: hidden;
			-webkit-perspective: 1000px;
			-moz-perspective: 1000px;
			perspective: 1000px;
		}
		>.item.next {
			-webkit-transform: translate3d(100%, 0, 0);
			transform: translate3d(100%, 0, 0);
			left: 0;
		}
		>.item.active.right {
			-webkit-transform: translate3d(100%, 0, 0);
			transform: translate3d(100%, 0, 0);
			left: 0;
		}
		>.item.prev {
			-webkit-transform: translate3d(-100%, 0, 0);
			transform: translate3d(-100%, 0, 0);
			left: 0;
		}
		>.item.active.left {
			-webkit-transform: translate3d(-100%, 0, 0);
			transform: translate3d(-100%, 0, 0);
			left: 0;
		}
		>.item.next.left {
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
			left: 0;
		}
		>.item.prev.right {
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
			left: 0;
		}
		>.item.active {
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
			left: 0;
		}
	}
}
@media screen and (min-width: 768px) {
	.carousel-control {
		.glyphicon-chevron-left {
			width: 30px;
			height: 30px;
			margin-top: -10px;
			font-size: 30px;
			margin-left: -10px;
		}
		.glyphicon-chevron-right {
			width: 30px;
			height: 30px;
			margin-top: -10px;
			font-size: 30px;
			margin-right: -10px;
		}
		.icon-prev {
			width: 30px;
			height: 30px;
			margin-top: -10px;
			font-size: 30px;
			margin-left: -10px;
		}
		.icon-next {
			width: 30px;
			height: 30px;
			margin-top: -10px;
			font-size: 30px;
			margin-right: -10px;
		}
	}
	.carousel-caption {
		left: 20%;
		right: 20%;
		padding-bottom: 30px;
	}
}
