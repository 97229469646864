.uc {

    .ck-editor__editable_inline {
        min-height: 250px;
    }

    .ck-reset_all {
        background: #0086C5;
    }

    .cke_notifications_area {
        display:none;
    }
    .cke_wysiwyg_frame, .cke_wysiwyg_div {
        background: #063547;
    }

    .cke_top {
        background: #0086C5;
        border-bottom: 0;
    }

    .cke_inner {
        background: #0086C5;
    }

    .cke_reset {
        border-radius: 8px 8px 0 0;
    }

    .cke_chrome {
        border: 2px solid #0086C5;
        border-radius: 7px;
        background: #063547;
    }

    .cke_bottom {
        background: #063547;
        border-top: 0;
        display: none;
    }

    .cke_contents {
        margin-bottom: 5px;
    }

    .cke_wysiwyg_frame html {
        background: #063547;
    }

    .cke_wysiwyg_frame body {
        color: #fff;
        background: #063547;
    }

    .cke_button_icon {
        filter: invert(100%) brightness(200%) contrast(100%);
    }

    a.cke_button_off:hover, a.cke_button_off:focus, a.cke_button_off:active {
        background: transparent;
        opacity: 0.7;
    }

    a.cke_button_on {
        background: transparent;
    }
} // .uc end

@media (max-width: 767px) {
    .uc {
        .cke {
            .cke_contents {
                // override inline height
                height: 143px!important;
            }
        }
    }
}
