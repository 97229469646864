$cropper-image-path: '../build/node_modules/cropperjs/src/images';

$text: #454545;
$color-text: #454545;
$color-default: #454545;
$active: #0086C5;
$color-text-info: rgba(69,69,69, 0.5);
$color-green: #8CB82B;
$color-blue: #0086C5;
$color-gray: #979797;

$status-new: #B4B4B4;
$status-progress: #DBBC00;
$status-ok: #8CB82B;
$status-no: #e30613;

$color-warning: #F8AF1C;

$green: #8CB82B;
$bg-gray: #FBFBFB;
$blue: #0086C5;
$bg-blue: #0086C5;
$dark: #063548;
$bg-dark: #063548;

// media query breakpoints
$breakpoint-desktop: 1024px;
$breakpoint-tablet-min: 768px;
$breakpoint-tablet-max: 992px;
$breakpoint-mobile: 767px;

// redesign 2024

// --- typography
$h1-size: 2.25rem;
$h2-size: 2rem;
$h3-size: 1.5rem;
$h4-size: 1.25rem;
$h5-size: 1.125rem;
$h6-size: 1rem;
// desktop & up
$h1-lg-size: 3.75rem;
$h2-lg-size: 3rem;
$h3-lg-size: 2.625rem;
$h4-lg-size: 2rem;
$h5-lg-size: 1.5rem;
$h6-lg-size: 1.25rem;

$lh-default: 110%;
$fw-default: 500;

// text paragraph
$text-large: 1.125rem;
$text-medium: 1rem;
$text-small: 0.75rem;

$text-lh-default: 150%;

// links
$link-large: 1.125rem;
$link-medium: 1rem;
$link-small: 0.75rem;

// --- colors
// brand
$sr-green: #8CB82B;
$sr-blue: #0083C2;
$sr-black: #000000;
$sr-white: #FFFFFF;

// special bg image svg icon color encodes (%23 for #)
// sr-green
$svg-green: '%238CB82B';
// blue98
$svg-blue: '%2307293E';
// white
$svg-white: '%23FFFFFF';

// green 
$green99: #1A2009;
$green98: #2B390B;
$green95: #3D5409;
$green90: #4F6E06;
$green80: #628808;
$green70: #76A014;
$green60: #8CB82B;
$green50: #9CC73F;
$green40: #AFD65A;
$green35: #C1E276;
$green30: #D1EB94;
$green25: #E0F2B4;
$green20: #EEF8D4;
$green10: #FBFEF5;
// blue
$blue99: #0B1822;
$blue98: #07293E; 
$blue95: #003A5B;
$blue90: #004C77;
$blue80: #005F93;
$blue70: #0071AC;
$blue60: #0083C2;
$blue50: #3095D4;
$blue40: #55A7E2;
$blue35: #77B8ED;
$blue30: #98C9F4;
$blue25: #B8D9F9;
$blue20: #D7EAFC;
$blue10: #F6FAFE;
// grey
$grey99: #1B1C1C;
$grey98: #303132;
$grey95: #454748;
$grey90: #5B5D5F;
$grey80: #707375;
$grey70: #85898A;
$grey60: #989C9E;
$grey50: #AAAEB0;
$grey40: #BABEC0;
$grey35: #C9CDCE;
$grey30: #D7DADB;
$grey25: #E4E6E7;
$grey20: #F0F1F2;
$grey10: #FCFCFC;

// --- spacing 
$space2: 2px;
$space4: 4px;
$space8: 8px;
$space12: 12px;
$space16: 16px;
$space20: 20px;
$space24: 24px;
$space32: 32px;
$space40: 40px;
$space48: 48px;
$space64: 64px;
$space80: 80px;
$space120: 120px;
$space160: 160px;

// --- border
$border-radius: 8px; //24
$border-radius-4: 0 0 4px 0;
$border-radius-8: 0 0 8px 0;
$border-radius-16: 0 0 16px 0;
$border-radius-32: 0 0 32px 0;
$border-radius-64: 0 0 64px 0;
$border-radius-80: 0 0 80px 0;
$border-radius-96: 8px 8px 96px 8px;

$--bs-border-width: 1px; 
$--bs-border-style: solid;
$--bs-border-color: $grey25;

// --- shadows
$box-shadow-1: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
$box-shadow-2: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
$box-shadow-3: 0px 8px 16px 0px rgba(0, 0, 0, 0.12);


:root {
  --sm: 480px;
  --md: 768px;
  --lg: 1024px;
  --xl: 1440px;

  --sr-space2: 2px;
  --sr-space4: 4px;
  --sr-space8: 8px;
  --sr-space12: 12px;
  --sr-space16: 16px;
  --sr-space20: 20px;
  --sr-space24: 24px;
  --sr-space32: 32px;
  --sr-space40: 40px;
  --sr-space48: 48px;
  --sr-space64: 64px;
  --sr-space80: 80px;
  --sr-space120: 120px;
  --sr-space160: 160px;  
  --sr-space200: 200px;  

  // green
  --SR-Green: #89B528;
  --green-60-base: #89B528;
  --Green-99: #1A2009;
  --Green-98: #2B390B;
  --Green-95: #3D5409;
  --Green-90: #4F6E06;
  --Green-80: #628808;
  --Green-70: #76A014;
  --Green-60: #8CB82B;
  --Green-50: #9CC73F;
  --Green-40: #AFD65A;
  --Green-35: #C1E276;
  --Green-30: #D1EB94;
  --Green-25: #E0F2B4;
  --Green-20: #EEF8D4;
  --Green-10: #FBFEF5;
  // blue
  --SR-Blue: #0083C2;
  --blue-60-base: #0083C2;
  --Blue-99: #0B1822;
  --Blue-98: #07293E;
  --Blue-95: #003A5B;
  --Blue-90: #004C77;
  --Blue-80: #005F93;
  --Blue-70: #0071AC;
  --Blue-60: #0083C2;
  --Blue-50: #3095D4;
  --Blue-40: #55A7E2;
  --Blue-35: #77B8ED;
  --Blue-30: #98C9F4;
  --Blue-25: #B8D9F9;
  --Blue-20: #D7EAFC;
  --Blue-10: #F6FAFE;
  // grey
  --grey-60-base: #989C9E;
  --Grey-99: #1B1C1C;
  --Grey-98: #303132;
  --Grey-95: #454748;
  --Grey-90: #5B5D5F;
  --Grey-80: #707375;
  --Grey-70: #85898A;
  --Grey-60: #989C9E;
  --Grey-50: #AAAEB0;
  --Grey-40: #BABEC0;
  --Grey-35: #C9CDCE;
  --Grey-30: #D7DADB;
  --Grey-25: #E4E6E7;
  --Grey-20: #F0F1F2;
  --Grey-10: #FCFCFC;

}