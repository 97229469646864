.list-group {
	margin-bottom: 20px;
	padding-left: 0;
}
.list-group-item {
	position: relative;
	display: block;
	padding: 10px 15px;
	margin-bottom: -1px;
	background-color: #073B50;
	border: 1px solid #052836;
	&:first-child {
		border-top-right-radius: 7px;
		border-top-left-radius: 7px;
	}
	&:last-child {
		margin-bottom: 0;
		border-bottom-right-radius: 7px;
		border-bottom-left-radius: 7px;
	}
}
a.list-group-item {
	color: #555;
	.list-group-item-heading {
		color: #333;
	}
	&:hover {
		text-decoration: none;
		color: #555;
		background-color: #0E4761;
	}
	&:focus {
		text-decoration: none;
		color: #555;
		background-color: #0E4761;
	}
}
button.list-group-item {
	color: #555;
	width: 100%;
	text-align: left;
	.list-group-item-heading {
		color: #333;
	}
	&:hover {
		text-decoration: none;
		color: #555;
		background-color: #0E4761;
	}
	&:focus {
		text-decoration: none;
		color: #555;
		background-color: #0E4761;
	}
}
.list-group-item.disabled {
	background-color: #e7e7e7;
	color: #898989;
	cursor: not-allowed;
	&:hover {
		background-color: #e7e7e7;
		color: #898989;
		cursor: not-allowed;
		.list-group-item-heading {
			color: inherit;
		}
		.list-group-item-text {
			color: #898989;
		}
	}
	&:focus {
		background-color: #e7e7e7;
		color: #898989;
		cursor: not-allowed;
		.list-group-item-heading {
			color: inherit;
		}
		.list-group-item-text {
			color: #898989;
		}
	}
	.list-group-item-heading {
		color: inherit;
	}
	.list-group-item-text {
		color: #898989;
	}
}
.list-group-item.active {
	z-index: 2;
	color: #fff;
	background-color: #0086C5;
	border-color: #052836;
	&:hover {
		z-index: 2;
		color: #fff;
		background-color: #0086C5;
		border-color: #052836;
		.list-group-item-heading {
			color: inherit;
			>small {
				color: inherit;
			}
			>.small {
				color: inherit;
			}
		}
		.list-group-item-text {
			color: #92dcff;
		}
	}
	&:focus {
		z-index: 2;
		color: #fff;
		background-color: #0086C5;
		border-color: #052836;
		.list-group-item-heading {
			color: inherit;
			>small {
				color: inherit;
			}
			>.small {
				color: inherit;
			}
		}
		.list-group-item-text {
			color: #92dcff;
		}
	}
	.list-group-item-heading {
		color: inherit;
		>small {
			color: inherit;
		}
		>.small {
			color: inherit;
		}
	}
	.list-group-item-text {
		color: #92dcff;
	}
}
.list-group-item-heading {
	margin-top: 0;
	margin-bottom: 5px;
}
.list-group-item-text {
	margin-bottom: 0;
	line-height: 1.3;
}
