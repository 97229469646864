h1 {
	font-size: 32px;
	font-weight: 200;
}
h2 {
	font-size: 32px;
	font-weight: normal;
	line-height: 40px;
}
h3 {
	font-size: 20px;
	font-weight: normal;
	line-height: 17px;
}
h4 {
	font-size: 16px;
	font-weight: normal;
	line-height: 36px;
}
.xlg_text {
	font-size: 36px;
	line-height: 36px;
}
.lg_text {
	font-size: 26px;
	line-height: 36px;
}
.md_text {
	font-size: 19px;
	line-height: 36px;
}
.sm_text {
	font-size: 12px;
	line-height: 20px;
}
.xs_text {
	font-size: 11px;
	line-height: 14px;
}
.underline {
	padding-bottom: 0;
	border-bottom: 1px solid #454545;
	display: inline-block;
}
.white {
	color: #fff;
}
.grey {
	color: #454545;
}
.blue {
	color: #1D71BA;
}
.greyblue {
	color: #AAD0D2;
}
.lightblue {
	color: #2481B5;
	font-weight: 400;
}
.light-text {
	font-weight: 300;
}
.thin-text {
	font-weight: 100;
}
.justify-text {
	text-align: justify;
}
.bg_middleblue {
	background-color: #0E4761;
}
.md-opacity {
	opacity: 0.87;
}
.low-opacity {
	opacity: 0.7;
}
.shop-index__section.blue {
	background-color: #063548;
	color: #fff;
	padding-top: 30px;
	padding-bottom: 30px;
}
.shop-index__section.middleblue {
	background-color: #0E4761;
	color: #fff;
	padding-top: 30px;
	padding-bottom: 30px;
}
.shop-index__section.grey,
.prices-slider.grey {
	background-color: #FBFBFB;
	padding-top: 30px;
	padding-bottom: 30px;
}
.mt--5 {
	margin-top: -5px !important;
}
.mt-4 {
	margin-top: 4px !important;
}
.mt-5 {
	margin-top: 5px !important;
}
.mt-8 {
	margin-top: 8px !important;
}
.mt-10 {
	margin-top: 10px !important;
}
.mt-15 {
	margin-top: 15px !important;
}
.mt-20 {
	margin-top: 20px !important;
}
.mt-30 {
	margin-top: 30px !important;
}
.mt-40 {
	margin-top: 40px !important;
}
.mt-45 {
	margin-top: 45px !important;
}
.mt-50 {
	margin-top: 50px !important;
}
.mt-60 {
	margin-top: 60px !important;
}
.mt-80 {
	margin-top: 80px !important;
}
.mb-4 {
	margin-bottom: 4px !important;
}
.mb-5 {
	margin-bottom: 5px !important;
}
.mb-10 {
	margin-bottom: 10px !important;
}
.mb-15 {
	margin-bottom: 15px !important;
}
.mb-20 {
	margin-bottom: 20px !important;
}
.mb-30 {
	margin-bottom: 30px !important;
}
.mb-45 {
	margin-bottom: 45px !important;
}
.mlr-15 {
	margin-left: 15px;
	margin-right: 15px;
}
.mlr-30 {
	margin-left: 30px;
	margin-right: 30px;
}
.mlr-60 {
	margin-left: 60px;
	margin-right: 60px;
}
.mlr-80 {
	margin-left: 80px;
	margin-right: 80px;
}
.pt-5 {
	padding-top: 5px !important;
}
.pt-10 {
	padding-top: 10px !important;
}
.pt-13 {
	padding-top: 13px !important;
}
.pt-15 {
	padding-top: 15px !important;
}
.pt-20 {
	padding-top: 20px !important;
}
.pt-25 {
	padding-top: 25px !important;
}
.pt-30 {
	padding-top: 30px !important;
}
.pt-40 {
	padding-top: 40px !important;
}
.pt-45 {
	padding-top: 45px !important;
}
.pb-5 {
	padding-bottom: 5px !important;
}
.pb-10 {
	padding-bottom: 10px !important;
}
.pb-15 {
	padding-bottom: 15px !important;
}
.pb-20 {
	padding-bottom: 20px !important;
}
.pb-25 {
	padding-bottom: 25px !important;
}
.pb-30 {
	padding-bottom: 30px !important;
}
.pb-40 {
	padding-bottom: 40px !important;
}
.pb-45 {
	padding-bottom: 45px !important;
}
.p-5-0 {
	padding: 5px 0 5px 5px;
}
.p-0-5 {
	padding: 5px 5px 5px 0;
}
.p-5 {
	padding: 5px;
}
.p-15 {
	padding: 15px;
}
.p-30 {
	padding: 30px;
}
.plr-15 {
	padding: 0 15px 0 15px;
}
.plr-20 {
	padding: 0 20px 0 20px;
}
.plr-25 {
	padding: 0 25px 0 25px;
}
.plr-30 {
	padding: 0 30px 0 30px;
}
.plr-60 {
	padding: 0 60px 0 60px;
}
.plr-80 {
	padding: 0 80px 0 80px;
}
.font-size-11 {
	font-size: 11px !important;
}
.font-size-12 {
	font-size: 12px !important;
}
.font-weight-300 {
	font-weight: 300 !important;
}
.shop-index__section {
	position: relative;
	margin-bottom: 0px;
}
.shop-index__section.alt {
	background-color: #f5f5f5;
	padding-top: 60px;
	padding-bottom: 60px;
	border-width: 1px 0 1px 0;
	border-style: solid;
	border-color: rgba(0, 0, 0, 0.05);
}
.shop-index__heading {
	margin-top: 0;
	margin-bottom: 60px;
	font-family: 'Roboto', sans-serif;
}
.shop-index__heading+p {
	margin-top: -50px;
	margin-bottom: 60px;
	color: #777777;
}
.shop__slideshow {
	margin-bottom: 0px;
	.carousel-inner {
		.item__container {
			display: table;
			width: 100%;
			height: 100%;
		}
		.item-container__inner {
			display: table-cell;
			vertical-align: middle;
			padding-top: 45px;
			padding-left: 30px;
			padding-right: 30px;
		}
		.item {
			width: 100%;
			height: 666px;
		}
	}
}
.shop-slideshow__heading {
	margin: 0 0 29px 0;
	font-family: 'Roboto', sans-serif;
	font-size: 56px;
	line-height: 61px;
	color: white;
}
.shop-slideshow__subheading {
	margin-bottom: 30px;
	font-family: 'Roboto', sans-serif;
	font-size: 15px;
	line-height: 22px;
	color: white;
}
.shop-slideshow__heading_dark {
	margin: 0 0 20px 0;
	font-family: 'Roboto', sans-serif;
	font-size: 46px;
	line-height: 1.2;
	color: #454545;
}
.shop-slideshow__subheading_dark {
	margin-bottom: 30px;
	font-family: 'Roboto', sans-serif;
	font-size: 20px;
	line-height: 1.5;
	color: #454545;
}
.shop-slideshow__btn {
	border: 2px solid white;
	border-radius: 0;
	color: white;
	font-weight: 600;
	&:hover {
		color: #333333;
		background-color: white;
	}
	&:focus {
		color: #333333;
		background-color: white;
	}
}
.shop-slideshow__btn_dark {
	border: 2px solid #454545;
	border-radius: 0;
	color: #454545;
	font-weight: 600;
	&:hover {
		color: #333333;
		background-color: white;
	}
	&:focus {
		color: #333333;
		background-color: white;
	}
}
.carousel-indicators {
	li {
		display: inline-block;
		width: 6px;
		height: 6px;
		margin: 2px 6px 2px 6px;
		text-indent: -999px;
		cursor: pointer;
		background-color: #fff;
		border-radius: 12px;
	}
	.active {
		width: 10px;
		height: 10px;
		margin: 2px 6px 0 6px;
		background-color: #fff;
	}
}
.gewinne-slideshow__control {
	display: block;
	position: absolute;
	top: 50%;
	padding-top: 30px;
	left: 15px;
	width: 30px;
	height: 70px;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	-webkit-transition: opacity .3s;
	-o-transition: opacity .3s;
	transition: opacity .3s;
	&:hover {
		opacity: 1 !important;
	}
}
.gewinne-slideshow__control[data-slide="next"] {
	left: auto;
	right: 10px;
}
.gewinne__slideshow {
	&:hover {
		.gewinne-slideshow__control {
			opacity: .3;
		}
	}
}
.partner-slider {
	.slide {
		>.csc-default {
			height: 100%;
			width: 100%;
			text-align: center;
			display: flex;
			justify-content: space-around;
			align-items: center;
			margin: 0 auto;
		}
	}
}
footer {
	.container {
		.row {
			>.csc-default {
				width: 100%;
			}
		}
	}
}
@media (max-width: 479px) {
	h1 {
		font-size: 24px;
	}
}
@media (max-width: 767px) {
	.shop__slideshow {
		.carousel-inner {
			.item {
				height: 400px;
				padding: 0;
				text-align: center;
			}
		}
	}
	.shop-slideshow__heading {
		font-size: 32px;
	}
	.shop-slideshow__subheading {
		font-size: 16px;
	}
}

 span.sr_form_feedback {
	display: inline-block;
	padding-left: 10px;
	color: #c00000;
	font-weight: 600;
}
textarea {
	font-family: 'Roboto', sans-serif;
	font-size: 13px;
	font-style: normal;
	font-weight: 300 !important;
	letter-spacing: 0px;
	color: #333333;
}
.normal_text {
	font-family: 'Roboto', sans-serif;
	font-size: 13px;
}
#navbar {
	background: #86bae3;
	width: 100%;
	height: 36px;
	text-align: left;
}
#subnavbar {
	background: #f0f0f0;
	width: 100%;
	height: 18px;
	text-align: left;
}
a.navlink {
	display: inline-block;
	text-align: center;
	vertical-align: middle;
	padding: 8px 6px;
	padding-bottom: 0;
	height: 28px;
	color: #fff;
	&:hover {
		text-decoration: none;
		color: #666;
	}
	&:active {
		text-decoration: none;
		color: #fff;
	}
}
a.navlink_logout {
	display: inline-block;
	text-align: center;
	vertical-align: middle;
	padding: 8px 6px;
	padding-bottom: 0;
	height: 28px;
	color: #fff;
	text-align: right;
	float: right;
	padding-left: 0;
	padding-right: 0;
	margin-right: 42px;
}
span.navlink_spacer {
	display: inline-block;
	text-align: center;
	vertical-align: middle;
	padding: 8px 6px;
	padding-bottom: 0;
	height: 28px;
	color: #fff;
	width: 24px;
}
span.subnavlink_spacer {
	display: inline-block;
	text-align: center;
	vertical-align: middle;
	padding: 8px 6px;
	padding-bottom: 0;
	height: 28px;
	color: #fff;
	height: 10px;
	width: 20px;
}
span.subnavlink_spacer_30 {
	display: inline-block;
	text-align: center;
	vertical-align: middle;
	padding: 8px 6px;
	padding-bottom: 0;
	height: 28px;
	color: #fff;
	height: 10px;
	width: 30px;
}
a.navlink_admin {
	background: #98caf0;
}
a.navlink_active {
	background: #b3cde1;
	color: #333;
}
#sr_success {
	color: #73a425;
}
.sr_success {
	color: #73a425;
}
#sr_error {
	color: #c00000;
	font-weight: 700;
}
.sr_error {
	color: #c00000;
	font-weight: 700;
}
.sr_graphbar {
	background: rgb(15, 112, 183) !important;
	border: rgb(15, 112, 183) solid 1px;
	height: 8px;
	min-height: 8px;
}
#sr_graphbar {
	background: rgb(15, 112, 183) !important;
	border: rgb(15, 112, 183) solid 1px;
	height: 8px;
	min-height: 8px;
}
b {
	font-weight: 600;
}
.standard_row {
	background: #ffffff;
}
.alternate_row {
	background: #f0f0f0;
}
label.multiline_label {
	display: inline-block;
	text-align: justify;
	width: 680px;
	width: 680px;
	vertical-align: top;
}
.nobreaks {
	white-space: nowrap;
}
.sr_team_comment {
	width: 100%;
	margin: 0;
	padding: 10px;
	padding-left: 0px;
	padding-right: 0px;
	border-top: #86bae3 dashed 1px;
}
.sr_team_comment_name {
	font-weight: bold;
}
.sr_team_comment_timestamp {
	font-weight: lighter;
}
textarea.sr_team_comment_input {
	width: 500px;
}
.sr_team_comment_counter {
	float: left;
	position: relative;
	top: -7px;
}
input.sr_team_comment_submit {
	margin-left: 240px;
}
.sr_team_comment_text {
	width: 90%;
}
a.sr_comment_link {
	opacity: 0.5;
	&:hover {
		opacity: 1.0;
	}
	&:active {
		opacity: 1.0;
	}
}
div.city_contact_box {
	background: #f0f0f0;
	float: right;
	width: 300px;
	padding: 20px;
	margin-left: 20px;
}
table.history_result_table {
	width: 673px;
}
.ptcounter-table {
	width: 100%;
	th {
		border-bottom: #c0c0c0 solid 2px;
		text-align: left;
	}
	td {
		border-bottom: #c0c0c0 solid 1px;
		text-align: left;
	}
}

.home-parallax {
    display: none;
}

.csc-space-before-67 {
    margin-top: 0;
}