.nav-pills {
	display: flex !important;
	justify-content: stretch;
	>li {
		flex: 1;
		&:first-child {
			>.btn {
				margin-right: -1px;
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
		&:last-child {
			>.btn {
				margin-left: -1px;
				border-left: 0;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
	}
	>li.active {
		>a {
			background-color: #8CB82B !important;
			&:hover {
				background-color: #8CB82B !important;
			}
			&:focus {
				background-color: #8CB82B !important;
			}
		}
	}
}
