ul.downloads {
	margin-left: -30px;
	margin-right: -30px;
	list-style: none;
	padding: 0;
	&::after {
		clear: both;
		content: "";
		display: table;
	}
	li {
		min-height: 1px;
		padding-left: 15px;
		padding-right: 15px;
        padding-top: 20px;
		position: relative;
		margin: 9%;
		text-align: center;
		border: 1px solid #454545;
        border-radius: 8px;
        height: 170px;
        display: flex;
        flex-direction: column;
		.download-link {
            display: flex;
            position: absolute;
            align-self: center;
            bottom: 20px;
			a {
				text-decoration: none !important;
				&::before {
					content: "\e902";
					display: inline-block;
					text-align: center;
					vertical-align: middle;
					font-family: sr-icons;
					font-size: 16px;
					line-height: 1;
					font-weight: normal;
					text-rendering: auto;
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
					margin: 3px 10px 5px;
				}
			}
		}
		.file-extension {
			font-weight: 300;
		}
		.filename {
			font-size: 15px;
			height: 45px;
			overflow-wrap: anywhere;
		}
	}
}
@media (min-width: 768px) {
	ul.downloads {
		li {
			float: left;
			width: 44%;
			margin: 3%;
		}
	}
}
@media (min-width: 992px) {
	ul.downloads {
		li {
			float: left;
			width: 29%;
			margin: 2%;
		}
	}
}
