.icon-edit {
    margin-left: 10px;
}

#successMessage {
	color: #454545;
}
#eventCreatedMessage {
	color: #454545;
}
#eventDeleteMessage {
	color: #454545;
}
#sr-missing-field-message {
	color: #454545;
}
#rteMessage {
	color: #454545;
}
#sr-modal-success-message {
	color: #454545;
}

@media (min-width: 768px)
{
    .kommune-verwalten {
        .sr-icon-grid > li {
            flex-basis: 0%;
        }
    }
}

@media (max-width: 991px)
{
    .kommune-verwalten {
        .sr-icon-grid li {
            min-width: 0%;
        }
    }
}

.kommune-verwalten {
    .rk-compat-2017 .checkbox label:before {
        background-color: transparent;
    }
}

.kommune-verwalten {

    .star-image-wrapper {
        img {
            border-radius: 50%;
            width: 200px;
            height: 200px;
            object-fit: cover;
        }
    }

    .preview-container {
        padding-left:30px;
    }

    .preview-title {
        padding-left:5px;
        color: #454545;
    }

    .preview {
        height: 200px;
        width: 200px;
        border: 1px solid black;
        overflow: hidden;
        float:right;
    }

    .preview-circle {
        border-radius: 50%;
    }

    #successMessage,
    #eventCreatedMessage,
    #eventDeleteMessage,
    #sr-missing-field-message,
    #rteMessage,

    #sr-extra-date-infos {
        max-height: 300px;
        overflow-y: scroll;
        margin-left: -15px;
        margin-right: -15px;
    }

    .sortable-block-downarrow, .sortable-block-uparrow {
        cursor: pointer;
    }

    .code {
        background-color: #0B3C4F;
        border-radius: 7px;
        padding: 5px;
    }

    .iframe-preview-image {
        max-height: 112px;
    }
}

@media (max-width: 767px) {
    .kommune-verwalten {
        #sr-add-newsfeed-entry {
            width: 100%;
        }
        .iframe-preview-image {
            max-height: max-content;
            width: 100%;
        }
    }
    .uc {
        .accordion-template [class^="col-"],
        .accordion-template [class*=" col-"] {
            padding-left: 15px;
            padding-right: 15px;
        }
        // hide order/sort arrows in mobile
        .sortable-block-uparrow,
        .sortable-block-downarrow {
            display: none;
        }
    }
}
