.uc {
    .datepicker {
        padding: 0px;
    }
    // rule to not override bootstrap col combined with datepicker 
    div[class^='col-'], div[class*='col-'] {
        &.datepicker {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    .datepicker.datepicker-dropdown.dropdown-menu {
        margin: 2px 0 0 30px;
    }
    /* datepicker arrow position */
    .datepicker-dropdown.datepicker-orient-left::before {
        left: 119px;
    }
    .datepicker-dropdown.datepicker-orient-left::after {
        left: 120px;
    }

    .event-button {
        padding:0px;
        display: flex;
        flex-direction: row;
    }

    .date-entry {
        margin-bottom: 20px;
    }

    .date-header {
        color: rgba(255,255,255,0.5);
        font-size: 14px;
        margin-bottom: 10px;
    }

    .date-body {
        color: rgba(255,255,255,0.5);
        font-size: 14px;
    }

    .delete-date, .delete-news, .delete-event, .update-news, .update-event {
        font-size: 14px;
        color: rgba(255,255,255,0.5);
        cursor: pointer;
    }

    .delete-news, .delete-event {
        display: flex;
        justify-content: flex-end;
    }

    #picture-privacy {
        min-height: 300px;
        font-size: 14px;
    }

    #picture-privacy .control-label {
        font-size: 14px;
        line-height: 22px;
    }

    .datepicker-dropdown::before {
        display: none;
    }

    .datepicker-dropdown::after {
        display: none;
    }

    .datepicker .table-condensed {
        width: 100%;
        > tbody > tr > td {
            padding:6px 10px;
        }
    }

    #sr_date_arrow {
        position: relative;
        float:right;
        top:-13px;
        right:5px;
    }

    // set arrow behind the input field so the icon doesn't block the input
    .sr_date_arrow {
        z-index: 0;
    }
    .datepicker {
        .form-control {
            position: relative;
            z-index: 1;
        }
    }

    #sr-add-extra-date{
        margin-top: 5px;
    }

    // light background specific (typo3 sites)
    &.light-bg {
        .datepicker table tr td.active, .datepicker table tr td.active:hover,
        .datepicker table tr td.active.disabled,
        .datepicker table tr td.active.disabled:hover {
            color:#000;
            background-image:none;
        }
        .datepicker table tr td.active.active, .datepicker table tr td.active:hover,
        .datepicker table tr td.active:hover:hover,
        .datepicker table tr td.active.active, .datepicker table tr td.active:hover.active {
            background-color:rgba(255,219,153,1);
        }
    
        .datepicker.dropdown-menu {
            box-shadow:0px 0px 5px #ccc;
        }
    } // .light-bg end

    // dark background specific (ORK sites)
    &.dark-bg {
        .datepicker-days {
            background: #052836;
            color: #ffffff;
        }
        .datepicker table tr td.highlighted {
            background: #073B50;
        }
        .datepicker .datepicker-switch:hover,
        .datepicker .prev:hover,
        .datepicker .next:hover,
        .datepicker tfoot tr th:hover,
        .datepicker table tr td.day:hover,
        .datepicker table tr td.day.focused {
            background: transparent;
        }
        #sr_date_arrow b {
            border-color: rgb(255, 255, 255) transparent transparent transparent;
            border-width: 7px 5px 0 5px;
            border-style: solid;
        }
    } // .dark-bg end
}  // .uc end