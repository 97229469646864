.auswertung-gesamt {

    .status {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #052836;
    }
    .status.status-green {
        background-color: #0086C5;
    }
    .status.status-yellow {
        background-color: #7DC6E8;
    }
    .status.status-orange {
        background-color: #FFFFFF;
    }
    .status.status-red {
        background-color: #21507B;
    }
    .status-small {
        &:before {
            content: "";
            display: block;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            float: left;
            margin: 3px 6px 0 0;
        }
    }
    table.sr-ranking {
        tbody {
            >tr {
                &:last-child {
                    border-bottom: 0px !important;
                }
                >td {
                    &:first-of-type {
                        span {
                            height: 35px;
                            width: 35px;
                            display: block;
                            padding: 12px 0 15px 0;
                            border-radius: 50% 0px 50% 50%;
                            margin-left: 2px;
                            background: #052836;
                            font-size: 10px;
                            font-weight: 500;
                            line-height: 12px;
                            text-align: center;
                        }
                    }
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    &.parlamentarierinnen {
                        text-overflow: initial;
                        overflow: visible;
                    }
                }
                td {
                    font-size: 20px;
                    color: rgba(255, 255, 255, 0.87) !important;
                }
                border-bottom: 1px solid #052836;
            }
        }
        table-layout: fixed;
        overflow: hidden;
        span.aktiv {
            &::after {
                content: none;
            }
        }
    }
    .status-small.status-green {
        &:before {
            background-color: #0086C5;
        }
    }
    .status-small.status-yellow {
        &:before {
            background-color: #7DC6E8;
        }
    }
    .status-small.status-orange {
        &:before {
            background-color: #FFFFFF;
        }
    }
    .status-small.status-red {
        &:before {
            background-color: #21507B;
        }
    }
    .ml-10 {
        margin-left: 10px;
    }
    .dataTables_filter {
        display: none;
    }
    .arrow-invert {
        .arrow-links {
            li {
                a {
                    &::before {
                        float: left;
                        margin-right: 3px;
                        transform: rotate(-135deg);
                    }
                    &:hover {
                        &::before {
                            transform: rotate(-90deg);
                        }
                    }
                }
            }
        }
    }
    .text-15 {
        font-size: 15px !important;
    }
    #tableSortXs {
        .btn {
            padding-left: inherit;
            padding-right: inherit;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: inherit;
            padding-right: inherit;
            padding-top: 4px;
            padding-bottom: 4px;
            font-size: 11px;
        }
        .btn.btn-default.active {
            background-color: rgba(2, 27, 38, 0.8) !important;
            border: 1px solid rgba(0, 134, 197, 0.4) !important;
            background-color: rgba(2, 27, 38, 0.8) !important;
            border: 1px solid rgba(0, 134, 197, 0.4) !important;
        }
    }
    // .th-search {
    //     label {
    //         font-weight: 500;
    //         opacity: 0.39;
    //         font-weight: 500;
    //         opacity: 0.39;
    //     }
    //     input {
    //         border-color: #fff !important;
    //         border: 2px solid rgba(255, 255, 255, 0.4) !important;
    //     }
    // }
    .data-unit {
        font-size: 13px;
    }
    td {
        i[class*='-dude'] {
            padding-left: 7px;
            font-size: 30px;
            color: #0086C5;
            vertical-align: middle;
        }
    }
    .results-filter-wrapper {
        display: flex;
        flex-wrap: wrap;
    }
    .text-md {
        font-size: 14px !important;
    }
    .data-table {
        tbody {
            >tr {
                .bar_wrapper {
                    .bar_bg {
                        width: 100%;
                    }
                }
            }
        }
    }
    .results-filter {
        display: flex;
        flex-direction: row;
        width: 100%;
        >div {
            flex: 1;
            text-align: center;
            margin: 0px 50px;
            &:first-child {
                margin-left: 0px;
            }
            &:last-child {
                margin-right: 0px;
            }
        }
    }
    .nav-pills {
        display: flex !important;
        justify-content: stretch;
        >li {
            >a {
                border-left-width: 0px;
                border-radius: 0;
            }
            &:first-child {
                >a {
                    border-radius: 2px 0 0 2px;
                    border-left-width: 1px;
                }
            }
            &:last-child {
                >a {
                    border-radius: 0 2px 2px;
                }
            }
        }
        >li+li {
            margin-left: -1px;
        }
        >li.active {
            >a {
                color: #fefefe;
                background-color: #fefefe;
                border-color: #8CB82B !important;
                font-weight: 500 !important;
                &:hover {
                    color: #fefefe;
                    background-color: #fefefe;
                    border-color: #8CB82B !important;
                    font-weight: 500 !important;
                }
                &:focus {
                    color: #fefefe;
                    background-color: #fefefe;
                    border-color: #8CB82B !important;
                    font-weight: 500 !important;
                }
            }
        }
    }
    #legend-wrapper {
        p {
            line-height: 17px;
        }
        font-size: 12px;
        font-weight: 300;
        letter-spacing: 0.33px;
        line-height: 17px;
        ul {
            max-width: 670px;
            padding-left: 30px;
            li {
                margin-bottom: 3px;
            }
        }
        div.row.legend-button {
            margin-top: -70px;
        }
    }
    #results-table-ranking_length {
        display: none;
    }
    #results-table-winning_length {
        display: none;
    }
    #results-table-stars_length {
        display: none;
    }
    #results-table-sonder_length {
        display: none;
    }
    #results-table-sonder_radpendler_length {
        display: none;
    }
    #results-table-ranking_wrapper {
        div.dataTables_paginate {
            text-align: center !important;
            padding-top: 70px;
            padding-bottom: 50px;
        }
        min-height: 600px;
    }
    #results-table-winning_wrapper {
        div.dataTables_paginate {
            text-align: center !important;
            padding-top: 70px;
            padding-bottom: 50px;
        }
    }
    #results-table-stars_wrapper {
        div.dataTables_paginate {
            text-align: center !important;
            padding-top: 70px;
            padding-bottom: 50px;
        }
    }
    #results-table-sonder_wrapper {
        div.dataTables_paginate {
            text-align: center !important;
            padding-top: 70px;
            padding-bottom: 50px;
        }
    }
    #results-table-sonder_radpendler_wrapper {
        div.dataTables_paginate {
            text-align: center !important;
            padding-top: 70px;
            padding-bottom: 50px;
        }
    }
    .nav {
        >li {
            >a {
                position: relative;
                display: block;
                padding: 7px 15px;
                font-size: 11px;
                font-weight: 500;
            }
        }
    }
    .btn.btn-default.dropdown-toggle.light {
        &:hover {
            border-color: #125b7c;
            background-color: #fff;
            color: #4f4f4f;
        }
    }
    .sr-ranking {
        th.w-20 {
            width: 20%;
        }
    }
    .paginate_button.current {
        color: #0086C5 !important;
        font-weight: 600;
    }
    .paginate_button {
        font-size: 18px;
        position: relative;
    }
    .pagination-icon {
        height: 25px;
        fill: white;
        position: absolute;
        top: 8px;
        left: 0px;
    }
    .countrySelect {
        .dropdown {
            .btn {
                font-size: 12px;
                font-weight: 500;
                border-color: #0d4058 !important;
                background-color: #0d4058 !important;
                color: rgba(254,254,254,0.8) !important;
                transition: background-color 500ms;
                display: inline-block;
                text-align: center;
                vertical-align: middle;
                touch-action: manipulation;
                cursor: pointer;
                background-image: none;
                padding: 3px 9px;
                padding-right: 40px;
                padding-left: 40px;
            }
            li {
                a {
                    white-space: nowrap;
                    overflow: hidden;
                    width: 100%;
                    text-overflow: ellipsis;
                }
            }
        }
    }
    .select2-container--default {
        .select2-results__group {
            cursor: default;
            display: block;
            padding: 0px;
        }
    }
    .select2-results__group {
        position: relative;
        cursor: pointer !important;
        &:after {
            position: absolute;
            content: "\e941" !important;
            transform: rotate(-90deg);
            right: 22px;
            top: 14px;
            font-size: 6px;
            height: 10px;
            width: 10px;
            border: none;
            background: none;
            text-align: center;
            line-height: 32px;
            font-family: sr-icons;
            color: white;
        }
    }
    .select2-results__group.open {
        &:after {
            content: "\e942" !important;
            transform: rotate(180deg);
            right: 12px;
            top: 25px;
        }
        color: #fff;
        padding-bottom: 10px !important;
        cursor: pointer !important;
    }
    .select2-results__option {
        cursor: pointer !important;
    }
    .select-wrapper-top {
        padding: 0px 0px;
        margin-top: -69px;
        z-index: 99;
    }
    #legend-hide {
        position: relative;
        border: 0;
        &:after {
            position: absolute;
            content: "\e941" !important;
            transform: rotate(-90deg);
            right: 22px;
            top: 14px;
            font-size: 10px;
            height: 10px;
            width: 10px;
            border: none;
            background: none;
            text-align: center;
            line-height: 32px;
            font-family: sr-icons;
            color: white;
            font-weight: 100;
            content: "\e942" !important;
            transform: rotate(0deg);
            right: 30px;
            top: 4px;
        }
        &:hover {
            background-color: transparent;
        }
        &:focus {
            background-color: transparent;
        }
    }
    #legend-show {
        position: relative;
        border: 0;
        &:after {
            position: absolute;
            content: "\e941" !important;
            transform: rotate(-90deg);
            right: 22px;
            top: 14px;
            font-size: 10px;
            height: 10px;
            width: 10px;
            border: none;
            background: none;
            text-align: center;
            line-height: 32px;
            font-family: sr-icons;
            color: white;
            font-weight: 100;
            content: "\e942" !important;
            transform: rotate(180deg);
            right: 9px;
            top: 26px;
        }
        &:hover {
            background-color: transparent;
        }
        &:focus {
            background-color: transparent;
        }
    }
    #legend-wrapper-outer.open {
        padding-top: 50px;
    }
    .content-spinner-wrapper {
        width: 100%;
        min-height: 200px;
        text-align: center;
    }
    .parl-num {
        min-width: 25px;
        display: inline-block;
    }
    .content-spinner {
        z-index: 5000;
        width: 200px;
        margin: 0 auto;
        padding: 20px;
        img {
            margin-top: 25%;
            -webkit-animation: rotation 1s linear 0s infinite;
            -moz-animation: rotation 1s linear 0s infinite;
            -o-animation: rotation 1s linear 0s infinite;
            animation: rotation 1s linear 0s infinite;
        }
    }
    @media (min-width: 768px) {
        table.sr-ranking {
            tbody {
                >tr {
                    >td {
                        &:first-of-type {
                            span {
                                height: 45px;
                                width: 45px;
                                display: block;
                                padding: 17px 0 15px 0;
                                border-radius: 50%;
                                margin-left: 2px;
                                background: #052836;
                                font-size: 13px;
                                font-weight: 500;
                                line-height: 12px;
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
        .col-min-130 {
            min-width: 30px;
        }
        .col-min-200 {
            min-width: 20px;
        }
        .filter-search {
            max-width: 355px;
        }
        .results-filter {
            >div {
                margin: 0px 30px;
            }
            margin-right: 0px;
        }
        #legend-hide {
            margin: 30px 0 30px auto;
            display: block;
            width: 250px;
        }
        .th-search {
            input {
                &::placeholder {
                    visibility: hidden !important;
                    color: transparent !important;
                }
            }
        }
    }


    @media (max-width: 767px) {

        .table-controls {
            padding: 0 15px;
        }
        #results-table-ranking_wrapper {
            margin-top: 50px !important;
        }
        .xs-text-13 {
            font-size: 13px !important;
        }

        table.sr-ranking {
            thead {
                >tr {
                    >th:nth-child(2) {
                        width: 120px !important;
                    }
                }
            }
        }

        table.sr-ranking {
            tbody {
                >tr {
                    >td.radelnde {
                        position: absolute;
                        left: 0;
                        margin-top: 22px;
                        font-size: 11px;
                        opacity: 0.7;
                        padding-left: 10px;
                        margin-left: 5px;
                    }
                    >td.tracks {
                        position: absolute;
                        left: 0;
                        margin-top: 36px;
                        font-size: 11px;
                        opacity: 0.7;
                        padding-left: 15px;
                        margin-left: 15px;
                    }
                    >td.merge-left {
                        position: absolute;
                        left: 0;
                        margin-top: 22px;
                        font-size: 11px;
                        opacity: 0.7;
                        padding-left: 10px;
                        margin-left: 5px;
                    }
                    >td.schulradelnde {
                        position: absolute;
                        left: 0;
                        margin-top: 37px;
                        font-size: 11px;
                        opacity: 0.7;
                        padding-left: 10px;
                    }
                    >td {
                        border: 0;
                        vertical-align: top;
                        overflow: hidden;
                        -ms-text-overflow: ellipsis;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    >td.parlamentarierinnen {
                        position: absolute;
                        left: 0;
                        margin-top: 28px;
                        font-size: 11px;
                        opacity: 0.7;
                    }
                    td.align-middle {
                        overflow: visible;
                    }
                }
            }
        }
        .col-min-130 {
            min-width: 30px;
        }
        .col-min-200 {
            min-width: 20px;
        }
        .results-filter {
            flex-direction: column;
            margin-right: 0px;
            >div {
                margin: 10px 0px;
            }
        }
        .nav-pills {
            display: none !important;
            justify-content: stretch;
        }
        .hidden-xs {
            display: none !important;
        }
        .sr-icon-grid {
            li {
                flex-basis: 100px !important;
            }
            margin: 10px 15px 30px;
        }
        .data-table {
            tbody {
                >tr {
                    .bar_wrapper {
                        .bar_text {
                            font-size: 15px;
                            font-size: 20px;
                            position: relative;
                            left: 0;
                        }
                    }
                }
            }
        }
        .bar_text {
            text-align: right;
            padding-right: 40px;
            padding-right: 20px;
        }
        .km_unit {
            font-size: 11px;
            color: rgba(255, 255, 255, 0.5);
            margin-left: 2.5px;
        }
        .data-unit {
            font-size: 11px;
            color: rgba(255, 255, 255, 0.5);
            margin-left: 2.5px;
        }
        #results-table-ranking_wrapper {
            div.dataTables_paginate {
                padding-top: 80px;
            }
        }
        .sort-buttons {
            label {
                font-weight: 500;
                font-size: 11px;
                letter-spacing: 0.3px;
                line-height: 13px;
                opacity: 0.5;
            }
            &.sort-buttons-min-height {
                min-height: 45px;
            }
        }
        .dataTables_empty {
            width: 100%;
        }
        .numberOfResults {
            position: absolute;
            top: -130px;
            margin-top: 0px;
        }
        h1 {
            font-size: 24px;
            line-height: 30px;
            padding-top: 20px !important;
        }
        h2 {
            font-size: 24px;
            line-height: 30px;
            padding-top: 20px !important;
        }
        .select-wrapper-top .select2-container .select2-selection--single .select2-selection__rendered {
            line-height: 27px !important;
        }
    }
    @media (min-width: 1440px) {
        .col-min-130 {
            min-width: 130px;
        }
        .col-min-200 {
            min-width: 180px;
        }
        .results-filter {
            >div {
                margin: 0px 50px;
            }
            margin-right: 0px;
        }
    }
    @media (min-width: 992px) {
        .filter-search {
            max-width: 425px;
        }
    }
    @media (max-width: 991px) {
        table.sr-ranking {
            thead {
                >tr {
                    >th.hide-on-tablet {
                        width: 0px !important;
                        overflow: hidden !important;
                        display: none;
                    }
                }
            }
        }
        #legend-wrapper {
            p {
                padding-left: 100px;
                padding-right: 100px;
            }
            div.row.legend-button {
                margin-top: 0px;
            }
        }
    }
    @media (max-width: 479px) {
        h1 {
            font-size: 24px !important;
            line-height: 30px !important;
            text-align: left !important;
        }
        h2 {
            font-size: 24px !important;
            line-height: 30px !important;
            text-align: left !important;
        }
        .xs-mb-10 {
            margin-bottom: 10px !important;
        }
        .xs-mt-25 {
            margin-top: 25px !important;
        }
        .xs-w-60 {
            min-width: 60% !important;
        }
        table {
            tbody {
                >tr {
                    >td {
                        &:nth-child(2) {
                            min-width: 150px;
                        }
                    }
                }
            }
        }
        table#results-table-ranking-noncompete {
            tbody {
                >tr {
                    >td {
                        &.cityname {
                            padding-left: 10px;
                            right: -7px !important;
                        }
                    }
                }
            }
        }
        .paginate_button.first {
            display: inline;
        }
        .paginate_button.previous {
            display: inline;
        }
        .paginate_button.next {
            display: inline;
        }
        .paginate_button.last {
            display: inline;
        }
        .paginate_button {
            font-size: 18px;
        }
        .select-wrapper-top {
            padding: 0px 15px;
            margin: 10px 0px 20px 0px;
        }

        @-moz-document url-prefix() {
            #results-table-ranking-noncompete .xs-text-13.text-15.xs-ff-fix {
                padding-left:5px !important;
            }

            table.sr-ranking thead > tr > th:first-of-type {
                padding-left: 0px !important;
            }

            .xs-ff-fix-ml-15 {
                margin-left: 15px;
            }

            .xs-ff-fix-min-width {
                min-width: 100px !important;

            }
        }
        #results-table-winning-noncompete tbody > tr > td.parlamentarierinnen {
            padding-left: 5px;
            left: 0;
            right: 0!important;
        }
    }

/* tablet */
@media (min-width: 768px) and (max-width: 992px) {
    td.cityname {
        max-width: 150px;
    }
}

/* browser specific styles Ranking Kommunen */

/* chrome */
@media all and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) {
@media (max-width: 767px) {
    table.sr-ranking tbody > tr > td.cityname {
        padding-left: 7px;
    }
    table.sr-ranking tbody > tr > td.radelnde {
        padding-left: 30px;
        margin-left: 0;
    }
    table.sr-ranking tbody > tr > td.parlamentarierinnen {
        padding-left: 14px;
        margin-left: 0;
    }
    .data-table tbody > tr .bar_wrapper .bar_text {
        left: 0px !important;
    }

    table#results-table-ranking-noncompete tbody > tr > td.cityname {
        right: 20px !important;
    }
}
}
/* firefox */
@-moz-document url-prefix() {
@media (max-width: 767px) {
    table.sr-ranking tbody > tr > td.cityname {
        padding-left: 0;
        padding-right: 0;
        right: -32px;
    }
    table#results-table-winning-noncompete {
        tbody > tr > {
            td{
                &:first-of-type {
                    right: -15px;
                }
                &.cityname {
                    padding-left: 5px;
                    right: 0;
                }
                &.parlamentarierinnen {
                    padding-left: 15px;
                }
            }
        }
    }
    table.sr-ranking tbody > tr > td.radelnde {
        padding-left: 30px;
        margin-left:0;
    }
    table.sr-ranking tbody > tr > td.parlamentarierinnen {
        padding-left: 0;
    }
    .data-table tbody > tr .bar_wrapper .bar_text {
        left: -20px;
    }
    .xs-ff-fix-ml-15 {
        margin-left:0;
    }
}
}
/* safari */
@supports (background: -webkit-named-image(i)) {
@media (max-width: 767px) {
    table.sr-ranking tbody > tr > td.cityname {
        padding-left: 40px;
        left: 10px;
    }
    table.sr-ranking tbody > tr > td.radelnde {
        padding-left:30px;
        margin-left:0;
    }
    table.sr-ranking tbody > tr > td.parlamentarierinnen {
        padding-left: 0;
        margin-left: 0;
    }
    .data-table tbody > tr .bar_wrapper .bar_text {
        left: -35px !important;
    }
    table#results-table-ranking-noncompete tbody>tr>td:nth-child(2) {
        left: 20px !important;
    }
}
}
}
