.uc {

	.form-control {
		padding: 4px 50px 4px 0;
		border-color: rgba(151,151,151,0.8);		
		border-width: 0 0 1px;
		border-radius: 0;
		height: 28px;
		font-size: 14px;
		line-height: 22px;
	}
	.row-form {
		display: flex;
		align-items: flex-start;
		.col-label {
			width: 200px;
			min-width: 200px;
		}
	}
	label {
		font-size: 14px;
		font-weight: 300;
		color: rgba(69,69,69,0.8);
	}

	.control-label {
		font-size: 14px;
		font-weight: 300;
		color: rgba(69,69,69,0.8);
		//float: left;
		width: 200px;
		//width: 20%;
		display: flex;
		align-items: center;
	}

	.link-arrow {
		font-size: 14px;
		color: #0086C5;
		font-weight: 500;
		&::after {
			font-family: 'sr-icons', 'FontAwesome';
			content: " \e935";
			font-size: 10px;
			margin-left: 8px;
		}
	}
	legend {
		font-size: 15px;
		font-weight: 400;
	}
	textarea {
		&.form-control {
			background-color: transparent;
			resize: none;
			padding: 15px;
			border-width: 1px;
			height: auto;
			border-radius: 7px;
		}
	}
	.control-label.newsfeed-item-label {
		padding-top: 0px;
	}

	.form-col {
		float: left;
		width: 290px;
		//width: 41.6666666667%;
		padding-left: 15px;
		padding-right: 15px;
	}
	.form-col-flex {
		display: flex;
		width: auto;
		min-width: 290px;
		padding-left: 15px;
		padding-right: 15px;
	}
	.form-col-offset {
		//margin-left: 20%;
		margin-left: 200px;
		padding-left: 15px;
		padding-right: 15px;
	}
	.form-col-md {
		float: left;
		width: 410px;
		position: relative;
		min-height: 1px;
		padding-left: 15px;
		padding-right: 15px;
	}
	.form-col-lg {
		float: left;
		width: 490px;
		padding-left: 15px;
		padding-right: 15px;
	}
	.form-col-full {
		display: flex;
		width: 100%;
		padding-left: 15px;
		padding-right: 15px;
	}

	.form-group-sm .form-control {
		font-size: 14px;
		line-height: 22px;
	}

	.form-group {
		margin-bottom: 25px;
		&::before {
			content: " ";
			display: table;
		}
		.control-label {
			height: 28px;
			/*
			display: flex;
			justify-content: center;
			align-content: center;
			flex-direction: column;
			*/
			&.control-label-info {
				display: flex;
				flex-wrap: wrap;
				align-content: flex-start;
				.icon-info {
					font-size: 20px;
					color: #b6b6b6;
					margin-left: 8px;
				}
			}
		}
		&.form-radio, 
		&.form-checkbox {
			label {
				margin-bottom: 18px;
			}
		}
		small {
			font-size: 11px;
		}
	}

	.team-tags {
		.twocol {
			.form-group {
				margin-bottom: 0px;
			}
		}
	}
	
    @media (min-width: 768px) {
        div.twocol {
            column-count: 2;
            column-gap: 20px;
            column-rule: 0;
        }
    }

	.form-horizontal {
		.control-label {
			padding-top: 0;
		}
		.form-control {
			padding: 4px 0 6px 0 !important;
		}
		textarea {
			&.form-control {
				padding: 10px 15px !important;
			}
		}
		.link-arrow {
			padding: 0 15px;
		}
		
		.checkbox {
			padding-top: 0px;
			label {
				font-size: 12px;
				font-weight: 300;
				letter-spacing: 0.33px;
				line-height: 20px;
				&:before {
					top: 7px;
				}
			}
		}
	}

	.section-headline {
        font-size: 22px;
        line-height: 36px;
        font-weight: 600;
        color: #0086C5;
		margin-bottom: 28px;
    }

	.info {
		position: relative;
		padding-left: 28px;
		color: rgba(69,69,69,0.5);
		font-size: 14px;
		letter-spacing: 0.55px;
		line-height: 18px;
		font-weight: normal;
		&::before {
			opacity: 0.3;
			top: 4px;
			color: $color-text;
		}
	}
/*
	.info-icon {
		color: #b6b6b6;
		font-size: 20px;
		position: relative;
		top: 3px;
	}
*/ 
	.input-small {
		width: 50px;
		min-width: 50px;
	}

	.input-date {
		width: 150px;
	}

	.dropdown-arrow {
		position: relative;
		float: right;
		bottom: 10px;
		&.dropdown-short {
			bottom: unset;
			top: 15px;
  			right: 10px;
		}
		b {
			border-color: #888 transparent transparent transparent;
            border-width: 7px 5px 0 5px;
            border-style: solid;
		}
    }

	.input-medium {
		width: 184px;
	}

	.label-inline {
		margin: 0 30px;
		font-weight: 300;
		font-size: 14px;
		line-height: 22px;
	}

	.pflichtfeld {
		opacity: 0.5;
		color: #fff;
		font-family: Roboto;
		font-size: 12px;
		line-height: 17px;
		text-align: left;
		margin-top: -10px;
	}

	.flex-checkboxes {
		.form-column-wide {
			.d-flex {
				flex-direction: row;
				flex-wrap: wrap;
				.flex-checkbox-item {
					flex-basis: 38%;
					margin-bottom: 20px;
				}
			}
		}
	}

	.form-group {
		// checkbox
		input[type="checkbox"], 
		input[type="radio"] {
			display: none;
		}
		
		input[type="checkbox"] ~ label, 
		input[type="checkbox"] ~ span {
			font-size: 14px;
		}
		
		input[type="checkbox"] ~ label::before, 
		input[type="checkbox"] ~ span::before {
			width: 16px;
			height: 16px;
			background-color: #FFFFFF;
			display: table;
			content:"";
			border-radius: 2px;
			border: 1.5px solid rgba(69,69,69, 0.38);
			font-size:13px;
			line-height: 0.8;
			border-collapse:separate;
		}
		
		input[type="checkbox"]:checked ~ label::before, 
		input[type="checkbox"]:checked ~ span::before {
			font-family: 'sr-icons', 'FontAwesome';
			font-size:12px;
			background-color: #8cb82b;
			content: "\f00c";
			color: #FFFFFF;
			width: 16px;
			height: 16px;
			border: 1.5px solid #8cb82b;
			display: table;
			line-height: 0.8;
			padding-left: 1px;
		}

		input[type="radio"] ~ label::before {
			width:16px;
			height:16px;
			background-color: #FFFFFF;
			content:"";
			border-radius: 10px;
			border: 1.5px solid #b7b7b7;
			display: inline-block;
			margin-right: 14px;
			vertical-align: middle;
		}

		input[type="radio"]:checked ~ label::before {
			background-color: #8cb82b;
			box-shadow: inset 0px 0px 0px 2px #FFFFFF;
		}

		.table-checkbox {
			tr {
				height: 40px;
				vertical-align: top;
			}
			td {
				min-width: 270px;
				&.check {
					min-width: auto;
				}
			}
			.check {
				label {
					margin-right: 14px;
					margin-top: 3px;
					margin-bottom: 0;
				}
			}
			label {
				font-weight: 300;
				font-size: 14px;
				line-height: 22px;
			}
		}
	}

	span.sr_form_feedback {
		display: inline-block;
		color: #E30613;
		font-weight: 400;
		font-size: 11px;
		line-height: 16px;
		padding-left: 0;
	}
	input[type="text"].feedback-form, 
	select.feedback-form {
        border-bottom: 1px solid #E30613!important;
    }

	// light background specific (typo3 sites)
	/*
    &.light-bg, .light-bg {
    
    }
	*/ 
	// .light-bg end

    // dark background specific (ORK sites)
    &.dark-bg, .dark-bg {
		label {
			color: rgba(255,255,255,0.8);
		}
		.modal {
			label {
				color: rgba(0, 0, 0, 0.8)
			}
		}
		.form-control {
			border-color: rgba(255,255,255,0.8);
			color: #fff;
		}
		.control-label, p.col-label {
			color: rgba(255, 255, 255, 0.8);
		}
		.link-arrow {
			color: #0086C5;
		}
		.info {
			color: rgba(255,255,255,0.4);
			font-weight: 500;
			&::before {
				color: rgba(255,255,255,0.3);
				opacity: 1;
			}
		}

		// fix ork select on win10: white select options text on white background
		select {
			&.form-control {
				option {
					color: initial;
				}
			}
		}

		.form-group {
			// checkbox
			input[type="checkbox"] + label::before, 
			input[type="checkbox"] + span::before,
			input[type="checkbox"] ~ label::before, 
			input[type="checkbox"] ~ span::before {
				background-color: transparent;
				border: 1px solid #fff;
			}

			input[type="checkbox"] ~ label::before, 
			input[type="checkbox"] ~ span::before {
				background-color: transparent;
				border: 1px solid #fff;
			}

			input[type="checkbox"]:checked + label::before, 
			input[type="checkbox"]:checked + span::before,
			input[type="checkbox"]:checked ~ label::before, 
			input[type="checkbox"]:checked ~ span::before {
				background-color: #8cb82b;
				border: 1.5px solid #8cb82b;
			}
				
			input[type="radio"] + label::before {
				background-color: transparent;
			}
	
			input[type="radio"]:checked + label::before {
				background-color: #8cb82b;
				box-shadow: inset 0px 0px 0px 2px #063547;
				//border: 1.5px solid #fff;
			}
		}

		span.sr_form_feedback {
			color: #F5A623;
		}
		input[type="text"].feedback-form, 
		select.feedback-form {
			border-bottom: 1px solid #F5A623!important;
		}

		.dropdown-arrow {
			b {
				border-color: rgba(255, 255, 255, 0.8) transparent transparent transparent;
			}
		}
		
    } // .dark-bg end


	/* mobile */
	@media (max-width: $breakpoint-mobile) {
		.btn {
			width: 100%;
		}
		.control-label {
			width: 100%;
		}
		.form-control {
			font-size: 16px;
		}
		.form-group {
			margin-bottom: 30px;
		}
		.row-form {
			display: block;
		}

		label {
			line-height: 22px;
		}

		.row-form {
			.d-flex {
				flex-direction: column;	
			}
			label {
				margin-bottom: 15px;
			}
		}

		.flex-checkboxes {
			.form-column-wide {
				.d-flex {
					flex-direction: column;
					.flex-checkbox-item {
						flex-basis: 100%;
						margin-bottom: 20px;
					}
				}
			}
		}

		.form-col {
			float: none;
			width: 100%;
		}
		.form-col-flex {
			display: flex;
			width: auto;
			min-width: auto;
		}
		.form-col-offset {
			margin-left: 0;
		}
		.form-col-md {
			float: none;
			width: 100%;
		}
		.form-col-lg {
			float: none;
			width: 100%;
		}
		.input-medium {
			width: 100%;
		}
		.table-checkbox {
			td {
				min-width: 140px;
			}
		}
		.link-arrow {
			display: block;
			width: 100%;
			margin-bottom: 15px;
		}

		.section-headline {
			font-size: 16px;
		}

		&.light-bg {
		
		} // .light-bg end
		&.dark-bg {
	
		} // .dark-bg end
	}

	@media (min-width: 768px) {
		.form-horizontal {
			.form-column-wide {
				float: left;
				width: 73%;
			}
		}
		.modal-content{
			.control-label {
				width: 25%;
			}
		}
	}


} // .uc end

/* old
	@media (min-width: 768px)
	{
		.form-column.form-width-75 {
			width: 75% !important;
		}
		.control-label
		{
			padding-top: 0px;
		}
	} 

	@media (max-width: 767px)
	{
		.control-label {
			font-size: 11px;
		}
	}

	

	@media (max-width: 767px) {
		.form-group .control-label {
			font-size: 14px;
			line-height: 22px;
		}
		.form-control {
			font-size: 16px;
			line-height: 20px;
		}
		.checkbox label {
			font-size: 14px;
		}
		.form-group > label.control-label {
			height: auto;
			display: block;
		}
		label {
			margin-bottom: 0;
		}
		.form-group-sm .form-control {
			font-size: 16px;
			line-height: 20px;
		}
	}
	*/