#testcases {
    margin-top: 20px;

    .testobject {
        margin-top: 20px;
    }

    .testobject-header {
        display: flex;
        justify-content: space-between;
    }

    .testobjectRunner {
        padding: 5px 10px 5px 10px;
        font-size: 12px;
        margin-left: 20px;
    }

    .testobject-title {
        font-size: 16px;
        font-weight: 600;
        align-self: center;
    }

    .testobject-table-header {
        display: flex;
        font-weight: 600;
    }

    .testcase-entry {
        display: flex;
    }

    .testcase-id {
        width: 5%;
    }

    .testcase-description {
        width:65%;
        margin-right: 10px;
    }

    .testcase-run {
        width: 10%;
        margin-right: 10px;
    }

    .run-icon:before {
        content: "\e037" !important;
        font-family: sr-icons;
        font-size: 16px;
        color: #8CB82B;
        cursor: pointer;
    }

    .testcase-duration {
        width: 10%;
        margin-right: 10px;
    }

    .testcase-status {
        width: 10%;
        margin-right: 10px;
        text-align: center;
    }

    .testcase-success img {
        height: 15px;
    }

    .testcase-warning img {
        height: 15px;
    }

    .testcase-result {
        margin-top: 5px;
        margin-bottom: 10px;

        .w-5 {
            width: 5%;
        }

        .error-header {
            font-weight: 600;
            display: flex;
        }

        .error-msg {
            display: flex;
        }
    }

    .pending:before {
        content: "\e90c" !important;
        font-family: sr-icons;
        font-size: 16px;
        color: #8CB82B;
    }

    .spinner img {
        height: 15px;
        -webkit-animation: rotation 1s linear 0s infinite;
        -moz-animation: rotation 1s linear 0s infinite;
        -o-animation: rotation 1s linear 0s infinite;
        animation: rotation 1s linear 0s infinite;
    }

    @-webkit-keyframes rotation { from { transform: rotate(0deg); } to { transform: rotate(359deg); }  }
    @-moz-keyframes rotation { from { transform: rotate(0deg); } to { transform: rotate(359deg); }  }
    @-o-keyframes rotation { from { transform: rotate(0deg); } to { transform: rotate(359deg); }  }
    @keyframes rotation { from { transform: rotate(0deg); } to { transform: rotate(359deg); }  }
}

.addTestcase, .addCategories {
    .addNewTestcaseHeader {
        display: flex;
        justify-content: space-between;
        .addTestcaseButton {
            display: flex;
            align-items: center;
        }
    }
    .select2-selection.select2-selection--single {
        margin-top:6px;
    }
    .form-group {
        display: flex;
        justify-content: space-between;

        label {
            display: flex;
            align-self: center;
        }
        .form-column {
            width: 60%;
            input {
                border-width: 0 0 2px 0;
                color: #fff;
            }
            .form-control {
                padding: 9px 5px;
            }
        }
    }

    .testcase-steps-header {
        .header-first, .header-secondary, .header-third, .header-fourth {
            display:flex;
            justify-content: space-between;
        }
        .header-item-small {
            width: 30%;
        }

        .header-item-wide {
            width: 70%;
            margin-left: 30px;
        }
        .form-group {
            flex-direction: column;
            .form-column {
                width: 100%;
            }
            .form-label, .form-column {
                align-self: flex-start;
                min-height: 40px;
            }
        }
    }
}

.testcaseSteps-list {
    .step-item {
        display: flex;
        margin-bottom: 20px;
        .step-order {
            margin-left: 15px;
            margin-right: 15px;
            display: flex;
            align-self: center;
            font-size: 16px;
        }
        .step-information {
            width:100%;
            .step-section-one,
            .step-section-two {
                display: flex;
                justify-content: space-between;
                margin-bottom:10px;
                .step-detail {
                    .step-item-header {
                        font-weight: bold;
                    }
                }
            }
        }
        .step-buttons {
            align-self: center;
            margin-left: 20px;
            .icon-delete {
                font-size: 20px;
                opacity: .5;
                cursor: pointer;
            }
        }
    }
}

